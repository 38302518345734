import { RevisionId, PoapTask, ViewId, ViewGroup } from 'tp-traqplan-core/dist/data-structs';

export interface DataStore<T> {
  value: T;
  pending: boolean;
  updated?: Date;
}

export interface PoapUpdate {
  revisionId: RevisionId;
  viewId: ViewId;
  action: string;
  // the tasks/groups to update:
  update: {
    tasks: PoapTask[];
    groups: ViewGroup[];
  }
  // the new datasets:
  tasks: PoapTask[];
  groups: {
    [key: string]: ViewGroup;
  },
  batchId?: string
}

export enum StateModal {
  sessionTimeout,
  createProject,
  renameProject,
  renameProgramme,
  deleteProject,
  labelRevision,
  editAccess,
  shareAccess,
  createView,
  copyView,
  editMilestones,
  editViewlines,
  publishConflict,
  workspaceSettings,
  createWorkspace,
  createAccount,
  inactivityWarning,
  resumeSession,
  moveProject,
  copyProject,
  moveProgramme,
  copyProgramme,
  showMessage,
  requestAccess,
  requestLicense,
  reviewLicenseRequest,
  reviewAccessRequest,
  accessGranted,
  editLicense
}
