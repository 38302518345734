import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { defaultViewName } from 'tp-common/revisions';
import { State } from '../';
import { StateModal } from '../structs';
import { ViewApiService } from '../../api/view-api.service';
import { RevisionsApiService } from '../../api/revisions-api.service';
import { MilestonesApiService } from '../../api/milestones-api.service';
import { ViewLinesApiService } from '../../api/view-lines-api.service';
import { BaselinesApiService } from '../../api/baselines-api.service';
import { RequestError } from '../../api/structs';
import { HierarchyNodeId, RevisionConflict } from 'tp-traqplan-core/dist/data-structs';
import * as appActions from '../app/actions';
import * as projectsActions from '../projects/actions';
import * as poapActions from './actions';
import * as modalActions from '../modal/actions';
import { nullAction } from '../helpers';

const ApiError = {
  DraftExistsInView: 'Draft exists in view',
  DraftNotChanged: 'No changes made to the draft',
  DraftNotCreated: 'Draft is not created for this view'
}

@Injectable()
export class PoapEffects {

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private viewApi: ViewApiService,
    private revisionsApi: RevisionsApiService,
    private milestonesApi: MilestonesApiService,
    private viewLinesApi: ViewLinesApiService,
    private baselinesApi: BaselinesApiService,
    private router: Router
  ) { }

  poapSelectionChanged$ = createEffect(() => this.actions$.pipe(
    ofType(
      projectsActions.selectProjectTreeRevisions,
      projectsActions.requestProjectTreeSuccess,
      projectsActions.updateNodeSuccess,
      poapActions.selectView,
      poapActions.selectBaseline,
      poapActions.requestViewLinesSuccess
    ),
    withLatestFrom(this.store),
    switchMap(([, state]) => {
      if (!state.poap.viewSelected) {
        return of(nullAction());
      }

      const required = [];

      for (const [revId, selected] of Object.entries(state.projects.tree.selected)) {
        if (selected) {
          required.push(revId);
        }
      }

      if (!required.length) {
        return of(nullAction());
      }

      return [
        poapActions.requestRevisions({
          view: state.poap.viewSelected.id,
          baseline: state.poap.baselines.selected,
          ids: required
        })
      ];
    })
  ));

  selectView$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.selectView),
    switchMap(({ view }) => [
      poapActions.requestMilestones({ view: view.id }),
      poapActions.requestViewLines()
    ])
  ));

  requestMilestones$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.requestMilestones),
    switchMap(({ view }) => {
      return this.milestonesApi.get(view).pipe(
        switchMap(({ milestones }) => [
          poapActions.requestMilestonesSuccess({ milestones })
        ])
      );
    })
  ));

  requestViewLines$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.requestViewLines),
    withLatestFrom(this.store),
    switchMap(([, state]) => {
      const viewId = state.poap.viewSelected?.id;
      return this.viewLinesApi.get(viewId).pipe(
        switchMap(({ viewLines }) => [
          poapActions.requestViewLinesSuccess({
            view: viewId,
            viewLines: viewLines
          })
        ])
      );
    })
  ))

  requestRevisions$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.requestRevisions),
    switchMap(({ view, baseline, ids }) => {
      return forkJoin(
        ...ids.map(id => this.revisionsApi.getData({ view, baseline, id }))
      ).pipe(
        switchMap(revisions => {
          const drafts = revisions.filter(rev => !rev.published);
          const actions: Action[] = [
            poapActions.requestRevisionsSuccess({ revisions, baseline, view })
          ];
          if (drafts.length) {
            actions.push(
              poapActions.requestUndoStack({
                revisionIds: drafts.map(d => d.id),
                viewId: view
              })
            );
          }
          return actions;
        }),
        catchError((error: RequestError) => {
          return of(poapActions.requestRevisionsFailure({ error }))
        })
      );
    })
  ));

  requestViews$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.requestViews),
    switchMap(() => {
      return this.viewApi.getViews().pipe(
        switchMap(({ views }) => [
          poapActions.requestViewsSuccess({ views })
        ]),
        catchError((error: RequestError) => {
          return of(poapActions.requestViewsFailure({ error }))
        })
      )
    })
  ));

  requestViewsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.requestViewsSuccess),
    withLatestFrom(this.store),
    switchMap(([{ views }, state]) => {

      const view = state.poap.viewSelected ? views.find(v => v.id === state.poap.viewSelected.id) : views.find(v => v.name === defaultViewName);

      if (!view) {
        return of(nullAction());
      }

      return of(poapActions.selectView({ view }));

    })
  ));

  updateDraft$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.updateDraft),
    switchMap((props) => {
      return this.revisionsApi.updateDraft({
        revisionId: props.revisionId,
        viewId: props.viewId,
        action: props.action,
        tasks: props.update.tasks,
        groups: props.update.groups,
        batchId: props.batchId
      }).pipe(
        switchMap((response: any) => {
          if (response?.actions?.valid === false && response?.actions?.error === ApiError.DraftNotCreated) {
            return [
              props.groups && Array.isArray(Object.values(props.groups)) && Object.values(props.groups)[0]?.project
                ? projectsActions.requestProjectTree({ selectProject: <HierarchyNodeId>Object.values(props.groups)[0].project })
                : projectsActions.requestProjectTree({}),
              poapActions.showUpdateDraftError(response.actions.params)
            ];
          }

          return [
            poapActions.updateDraftSuccess(),
            poapActions.requestUndoStack({ revisionIds: [props.revisionId], viewId: props.viewId })
          ];
        }),
        catchError((error: RequestError) => {
          return of(poapActions.updateDraftFailure({ error }));
        })
      );
    })
  ));

  showUpdateDraftError$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.showUpdateDraftError),
    switchMap(({ view }) => {
      return [
        modalActions.generateModal({
          modal: StateModal.showMessage,
          params: {
            header: 'Draft is read-only',
            message: `You already have a draft for this project created in view${view?.name ? ` (${view.name})` : ''}. You may only edit one draft at a time. Please publish or discard your draft in view${view?.name ? ` (${view.name})` : ''} before continuing.`
          }
        })
      ];
    })
  ));

  /*bulkUpdateDraft$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.bulkUpdateDraft),
    switchMap((props) => {
      return this.revisionsApi.bulkUpdateDraft({
        revisionId: props.revisionId, viewId: props.viewId, poapUpdates: props.poapUpdates
      }).pipe(
        switchMap((response: any) => {
          if (response?.actions?.valid === false && response?.actions?.error === ApiError.DraftNotCreated) {
            return [
              projectsActions.requestProjectTree({ selectProject: props.projectId }),
              poapActions.showBulkUpdateDraftError(response.actions.params)
            ];
          }

          return [
            poapActions.bulkUpdateDraftSuccess(),
            poapActions.requestUndoStack({ revisionIds: [props.revisionId], viewId: props.viewId })
          ];
        }),
        catchError((error: RequestError) => {
          return of(poapActions.bulkUpdateDraftFailure({ error }));
        })
      );
    })
  ));*/

  /*showBulkUpdateDraftError$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.showBulkUpdateDraftError),
    switchMap(({ view }) => {
      return [
        modalActions.generateModal({
          modal: StateModal.showMessage,
          params: {
            header: 'Draft is read-only',
            message: `You already have a draft for this project created in view${view?.name ? ` (${view.name})` : ''}. You may only edit one draft at a time. Please publish or discard your draft in view${view?.name ? ` (${view.name})` : ''} before continuing.`
          }
        })
      ];
    })
  ));*/

  createDraft$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.createDraft),
    switchMap(({ from, project, view }) => {
      return this.revisionsApi.createDraft({ from, project, view }).pipe(
        switchMap((response: any) => {
          if (response?.data?.valid === false && response?.data?.error === ApiError.DraftExistsInView) {
            return [poapActions.showCreateDraftError(response.data.params)];
          }

          return [
            appActions.showLoading(),
            poapActions.createDraftSuccess(),
            projectsActions.requestProjectTree({ selectProject: project })
          ];
        }),
        catchError((error: RequestError) => {
          return of(poapActions.createDraftFailure({ error }));
        })
      );
    })
  ));

  createDraftSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.createDraftSuccess),
    switchMap(() => {
      return [
        appActions.isDraftCreated({ isDraftCreated: true }),
        appActions.isDraftCreating({ isDraftCreating: false }),
      ];
    })
  ));

  showCreateDraftError$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.showCreateDraftError),
    switchMap(({ view }) => {
      return [
        appActions.isDraftCreating({ isDraftCreating: false }),
        appActions.hideLoading(),
        modalActions.generateModal({
          modal: StateModal.showMessage,
          params: {
            header: 'Unable to create draft',
            message: `You have a draft opened in view${view?.name ? ` (${view.name})` : ''}. First publish the draft before editing it in another view.`
          }
        })
      ];
    })
  ));

  discardDraft$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.discardDraft),
    switchMap(({ projectId, revisionId, viewId }) => {
      return this.revisionsApi.discardDraft({ id: revisionId, view: viewId }).pipe(
        switchMap((response: any) => {
          if (response?.valid === false && response?.error === ApiError.DraftNotCreated) {
            return [poapActions.showDiscardDraftError(response.params)];
          }

          return [
            poapActions.discardDraftSuccess(),
            projectsActions.requestProjectTree({ selectProject: projectId, unselectRevision: revisionId, selectView: viewId })
          ];
        }),
        catchError((error: RequestError) => {
          return of(poapActions.discardDraftFailure({ error }));
        })
      );
    })
  ));

  showDiscardDraftError$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.showDiscardDraftError),
    switchMap(({ view }) => {
      return [
        appActions.hideLoading(),
        modalActions.generateModal({
          modal: StateModal.showMessage,
          params: {
            header: 'Draft is read-only',
            message: `You discarding a read-only draft opened in view${view?.name ? ` (${view.name})` : ''}. Change to view${view?.name ? ` (${view.name})` : ''} to continue discarding.`
          }
        })
      ];
    })
  ));

  requestUndoStack$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.requestUndoStack),
    switchMap(({ revisionIds, viewId }) => {
      return forkJoin(
        ...revisionIds.map(revisionId => {
          return this.revisionsApi.getUndoStack({ revisionId, viewId }).pipe(
            map(({ actions }) => ({
              [revisionId]: actions
            }))
          );
        })
      ).pipe(
        switchMap((responses) => {
          return of(poapActions.requestUndoStackSuccess({ history: Object.assign({}, ...responses) }));
        }),
        catchError((error: RequestError) => {
          return of(poapActions.requestUndoStackFailure({ error }));
        })
      );
    })
  ));

  /**
   * Make the request to the server to move the revision pointer.
   * Assuming it's successful, get the latest revision data
   */
  moveRevisionPointer$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.moveRevisionPointer),
    switchMap(({ revisionId, viewId, stamp }) => {
      return this.revisionsApi.moveRevisionPointer({ revisionId, viewId, stamp }).pipe(
        withLatestFrom(this.store),
        switchMap(([, { poap }]) => [
          poapActions.requestRevisions({
            view: poap.viewSelected.id,
            baseline: poap.baselines.selected,
            ids: [revisionId]
          }),
          poapActions.requestUndoStack({ revisionIds: [revisionId], viewId: viewId })
        ])
      );
    })
  ));

  clearRevisionPointer$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.clearRevisionPointer),
    switchMap(({ revisionId, viewId }) => {
      return this.revisionsApi.clearRevisionPointer({ revisionId, viewId }).pipe(
        withLatestFrom(this.store),
        switchMap(([, { poap }]) => [
          poapActions.requestRevisions({
            view: poap.viewSelected.id,
            baseline: poap.baselines.selected,
            ids: [revisionId]
          }),
          poapActions.requestUndoStack({ revisionIds: [revisionId], viewId })
        ])
      );
    })
  ));

  publishDraft$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.publishDraft),
    switchMap(({ revisionId, label, mergeConflicts, workspaceId, viewId, resolution }) => {
      return this.revisionsApi.publishDraft({ id: revisionId, label, merge: mergeConflicts, view: viewId, resolution }).pipe(
        switchMap((response: any) => {
          if (response?.valid === false && Object.values(ApiError).indexOf(response?.error) > -1) {
            return [
              projectsActions.requestProjectTree({}),
              poapActions.showPublishDraftError(response),
              appActions.isRevisionCreated({ isRevisionCreated: false }),
              appActions.isRevisionCreating({ isRevisionCreating: false })
            ];
          }

          // const isConflict = Object.keys(response).length && !response.id && !response.published;
          const isConflict = Object.keys(response).length && (!Array.isArray(response.revisions) || response.revisions.length < 1);

          // if a non empty object is returned then it is a conflict object:
          if (isConflict) {
            const conflict = <RevisionConflict>response;
            return [
              projectsActions.requestProjectTree({}),
              poapActions.publishDraftConflict({ revisionId, conflict }),
              appActions.isRevisionCreated({ isRevisionCreated: false }),
              appActions.isRevisionCreating({ isRevisionCreating: false })
            ];
          }

          const newRevision = response.revisions.find(({ view }) => view === viewId) || response.revisions[0];
          // no conflict - publish was a success:
          return [
            poapActions.publishDraftSuccess({ revisionId: newRevision.id || revisionId }),
            projectsActions.requestProjectTree({}),
            projectsActions.requestRevisionViews({ workspace: workspaceId, view: viewId }),
            appActions.isRevisionCreated({ isRevisionCreated: true }),
            appActions.isRevisionCreating({ isRevisionCreating: false }),
            appActions.setNewRevision({ newRevision }),
            projectsActions.selectProjectTreeRevisions({ select: true, revisions: [newRevision] })
          ];
        })//,
        //catchError((error) => {
        //  return of(poapActions.publishDraftFailure(error));
        //}),
      );
    })
  ));

  publishDraftConflict$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.publishDraftConflict),
    switchMap(({ revisionId }) => [
      modalActions.generateModal({ modal: StateModal.publishConflict, params: { revisionId } })
    ])
  ));

  showPublishDraftError$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.showPublishDraftError),
    switchMap(({ error, params }) => [
      appActions.hideLoading(),
      modalActions.generateModal({
        modal: StateModal.showMessage,
        params: error === ApiError.DraftNotCreated
          ? {
            header: 'Draft is read-only',
            message: `You publishing a read-only draft opened in view${params?.view?.name ? ` (${params?.view.name})` : ''}. Change to view${params?.view?.name ? ` (${params?.view.name})` : ''} to continue publishing.`
          }
          : {
            header: 'Unable to publish draft',
            message: 'There are no changes to publish (Your draft is the same as the most recently published version)'
          }
      })
    ])
  ));

  labelRevision$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.labelRevision),
    switchMap(({ revisionId, label }) => {
      return this.revisionsApi.labelRevision({ revisionId, label }).pipe(
        switchMap(() => [poapActions.labelRevisionSuccess()]),
        catchError((error: RequestError) => {
          return of(poapActions.labelRevisionFailure({ error }));
        })
      );
    })
  ));

  labelRevisionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.labelRevisionSuccess),
    switchMap(() => of(projectsActions.requestProjectTree({})))
  ));

  /*requestBaselines$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.requestBaselines),
    withLatestFrom(this.store),
    switchMap(([, state]) => {

      return this.baselinesApi.get(state.account.root.selected?.id).pipe(
        switchMap(({ baselines }) => {

          const selected = baselines.find(b => b.id === state.poap.baselines.selected) || null;
          const actions: any[] = [poapActions.requestBaselinesSuccess({ baselines })];

          if (selected) {
            actions.push(poapActions.selectBaseline({ baselineId: selected.id }));
          }
          return actions;
        })
      );

    })
  ));

  selectBaseline$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.selectBaseline),
    switchMap(({ baselineId }) => {
      return this.baselinesApi.getRevisions(baselineId).pipe(
        switchMap(({ baselineRevisions }) => [
          poapActions.selectBaselineSuccess({ baselineId, baselineRevisions })
        ])
      );
    })
  ));

  createBaseline$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.createBaseline),
    withLatestFrom(this.store),
    switchMap(([{ label, entries }, state]) => {
      return this.baselinesApi.create(state.account.root.selected?.id, label, entries).pipe(
        switchMap(({ baselines }) => {
          const newBaseline = baselines.find(b => b.label === label);
          const actions: any[] = [poapActions.requestBaselinesSuccess({ baselines })];
          if (!newBaseline) {
            console.error('New baseline could not be located after creation!');
          } else {
            actions.push(poapActions.selectBaseline({ baselineId: newBaseline.id }));
          }
          return actions;
        })
      );
    })
  ));

  updateBaselineRevisions$ = createEffect(() => this.actions$.pipe(
    ofType(poapActions.updateBaselineRevisions),
    switchMap(({ baselineId, entries }) => {
      return this.baselinesApi.updateRevisions(baselineId, entries).pipe(
        switchMap(() => [
          poapActions.updateBaselineRevisionsSuccess()
        ])
      );
    })
  ));*/

}
