<app-modal-window
  header="Label revision"
  [(visible)]="visible"
  (actionSelected)="onActionSelected($event)"
  (closed)="onClose()"
  [actions]="actions"
>
  <form
    class="tp-form"
    [formGroup]="revisionForm"
  >

    <div class="tp-form__field">
      <label for="name">Label Revision?</label>
      <input
        pInputText
        name="name"
        formControlName="name"
        placeholder="optional"
      />
    </div>

  </form>

</app-modal-window>
