import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap, withLatestFrom, filter } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { State } from '../';
import { AuthApiService } from '../../api/auth-api.service';
import { RequestError } from '../../api/structs'
import { StateModal } from '../structs';
import * as modalActions from '../modal/actions';
import * as poapActions from '../poap/actions';
import * as projectsActions from '../projects/actions';
import * as accountActions from '../account/actions';
import * as authActions from './actions';
import * as globalActions from '../actions';

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private authApi: AuthApiService,
    private router: Router
  ) { }

  attemptLogin$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.attemptLogin),
    switchMap(({ email, password }): Observable<Action> => {
      return this.authApi.requestLogin(email, password).pipe(
        switchMap(({ token, user }) => [
          authActions.attemptLoginSuccess({ token, user })
        ]),
        catchError((error: RequestError) => {
          return of(authActions.attemptLoginFailure({ error }))
        })
      );
    })
  ));

  attemptLoginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.attemptLoginSuccess),
    filter(({ account }) => !!account),
    switchMap((props: any) => {
      return of(accountActions.launchAccount({ account: props.account, navigate: ['/accounts/overview'] }));
    })
  ))

  /*attemptLoginSso$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.attemptLoginSso),
    switchMap(({ d }): Observable<Action> => {
      return this.authApi.requestLoginSso(d).pipe(
        switchMap(({ token, user, ssoToken, origin }) => [
          authActions.attemptLoginSuccess({ token, user }),
          authActions.attemptLoginSsoSuccess({ ssoToken, origin })
        ]),
        catchError((error: RequestError) => {
          return of(authActions.attemptLoginFailure({ error }))
        })
      );
    })
  ));*/

  /*attemptLoginSsoSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.attemptLoginSsoSuccess),
    switchMap(() => {
      this.router.navigate(['']);
      return of(authActions.attemptLoginSsoComplete());
    })
  ));*/

  verifyUserCredentials$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.verifyUserCredentials),
    withLatestFrom(this.store),
    switchMap((values) => {
      if (Array.isArray(values) && values.length > 1) {
        const state = values[1];
        if (state.auth && state.auth.token) {
          return this.authApi.verifyToken().pipe(
            switchMap(({ token, user }) => [
              authActions.attemptLoginSuccess({ token, user })
            ]),
            catchError(() => {
              return of(authActions.verifyUserCredentialsFailure());
            })
          );
        }
      }
      return of(authActions.verifyUserCredentialsFailure());
    })
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.logout),
    tap((props) => {
      this.router.navigate(props?.navigate || ['login']);
    }),
    switchMap(() => {
      return [
        modalActions.reset({}),
        poapActions.reset({}),
        projectsActions.reset({}),
        accountActions.reset({}),
        globalActions.unload()
      ];
    }),
    tap(() => {
      location.reload();
    })
  ));

  sessionTimeout$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.sessionTimeout),
    //tap(() => this.router.navigate(['login'])),
    switchMap(() => [
      modalActions.generateModal({ modal: StateModal.sessionTimeout }),
      authActions.logout({ navigate: ['login'] })
    ])
  ));

}
