<app-modal-window
  header="Review license upgrade request"
  [actions]="actions"
  [(visible)]="visible"
  (actionSelected)="complete($event)"
  (closed)="complete()"
>

  <ng-container *ngIf="message">

    <div class="title" *ngIf="message.meta">
      {{message.meta.authorAlias}} ({{message.meta.authorEmail}}) has requested that their license be upgraded
    </div>

    <div class="message-title">
      Message:
    </div>

    <div class="message-content" [class.no-message]="!message.messageContent">
      "{{message.messageContent || 'No message provided'}}"
    </div>

  </ng-container>

</app-modal-window>
