import { createAction, props } from '@ngrx/store';
import { RequestError } from '../../../api/structs';
import * as PowerBi from '../../../powerbi-data-structs';

import Subscription = PowerBi.Subscription;
import SubscriptionId = PowerBi.SubscriptionId;

export const requestSubscriptions = createAction(
  '[powerbi.subscriptions] requestSubscriptions',
  props<any>()
);

export const requestSubscriptionsSuccess = createAction(
  '[powerbi.subscriptions] requestSubscriptionsSuccess',
  props<{ subscriptions: Subscription[] }>()
);

export const requestSubscriptionsFailure = createAction(
  '[powerbi.subscriptions] requestSubscriptionsFailure',
  props<{ error: RequestError }>()
);

export const requestSubscriptionById = createAction(
  '[powerbi.subscriptions] requestSubscriptionById',
  props<{ id: SubscriptionId }>()
);

export const requestSubscriptionByIdSuccess = createAction(
  '[powerbi.subscriptions] requestSubscriptionByIdSuccess',
  props<{ subscription: Subscription }>()
);

export const requestSubscriptionByIdFailure = createAction(
  '[powerbi.subscriptions] requestSubscriptionByIdFailure',
  props<{ error: RequestError }>()
);
