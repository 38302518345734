import { Component, AfterViewInit, OnDestroy, ViewChild, ElementRef, Input, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged, map } from 'rxjs/operators';
import { GroupNode, TaskNode, ViewportDimensions, GroupFitting, ClickAction } from 'tp-traqplan-core/dist/timeline/tl-structs';
import { mkNode, removeChildren } from 'tp-traqplan-core/dist/svg-utils';
import { LegendPosition } from 'tp-traqplan-core/dist/data-structs';
import { TimelineLayout } from 'tp-traqplan-core/dist/timeline/timeline-layout';
import { CORE_STYLE, CLASS_SCREEN_ONLY } from 'tp-traqplan-core/dist/timeline/tl-style';
import { ViewLineComponent } from 'tp-traqplan-core/dist/timeline/timeline-core/core-viewline';
import { uuid } from 'tp-common/uuid.js';
import * as timelineRender from 'tp-traqplan-core/dist/timeline/timeline-render';
import * as tlStyle from 'tp-traqplan-core/dist/timeline/tl-style';
import { renderAxis } from 'tp-traqplan-core/dist/timeline/timeline-core/core-axis';
import { renderMarkers } from 'tp-traqplan-core/dist/timeline/timeline-core/core-markers';
import { renderPrintLines } from 'tp-traqplan-core/dist/timeline/timeline-core/core-print-lines';
import { TimelineInteractions } from '../tl-interactions';
import { createTaskNode, updateTaskNode } from 'tp-traqplan-core/dist/timeline/timeline-core/core-tasks';
import { createGroupNode } from 'tp-traqplan-core/dist/timeline/timeline-core/core-groups';
import * as groupHandles from 'tp-traqplan-core/dist/timeline/timeline-core/core-group-handles';

const GROUP_STROKE_ALLOWANCE = 0.5;

@Component({
  selector: 'app-timeline-core',
  templateUrl: './timeline-core.component.html',
  styleUrls: ['./timeline-core.component.css']
})
export class TimelineCoreComponent implements AfterViewInit, OnDestroy {

  @ViewChild('svgContainer', { static: true }) scrollArea: ElementRef;

  @HostBinding('class.slide-active') get valid() { return this.slideActive; }

  @HostListener('document:pointerup', ['$event'])
  onPointerUp(event) {
    this.interactions?.pointerup(event);
  }

  @Input() readOnly = false;
  @Input() width = '100%';
  @Input() height = '100%';
  @Input() active: boolean = true;
  @Input() interactions: TimelineInteractions;
  @Input() layout: TimelineLayout;
  @Output() viewportChange = new EventEmitter<ViewportDimensions>();

  get svgContainer() {
    return this.scrollArea.nativeElement;
  }

  public scopeUID: string;

  public style = tlStyle;

  private _viewBox: string;

  private destroy$ = new Subject<void>();

  public yScrollGroup: any;
  public legendContainer: any;
  public axisContainer: any;
  public axisBorder: any;
  public xAxisOffset: any;
  public viewportVClip: any;
  //public viewportHClip: any;
  public printLines: any;
  public viewLines: any;
  public stylesheet: any;
  public fontStylesheet: any;
  public coloursStylesheet: any;
  public groupDrag: any;
  public tickMarkers: any;

  private groupNodes: any = {}; //flat reference to group nodes by group id
  private taskNodes: any = {}; //flat reference to task nodes by timeline task id
  private viewLineNodes: any = {}; //flat reference to view line fittings by id

  private xAxis: any;

  private yScrollTranslation: number = 0;
  private viewportDims: ViewportDimensions;
  private viewportRange: number[];
  private slideActive: boolean = false;

  private rowHeight: number; //height of task row (excluding outer padding)
  private rowPadding: number;

  dragTarget: Element|null;

  private coloursAvailable: Map<string, string>;

  //public hClipId = `viewport-h-clip-${uuid()}`;
  public vClipId = `viewport-v-clip-${uuid()}`;

  //public get hClipUrl() { return `url(#${this.hClipId})` }
  public get vClipUrl() { return `url(#${this.vClipId})` }

  private plotMaps = {
    axis: new Map,
    markers: new Map,
    printLines: new Map
  };
  //private markerMap:

  constructor(public host: ElementRef) {
    this.scopeUID = 'uid-' + uuid().toString();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {

    this.interactions?.connected(this.svgContainer);

    this.layout.layout$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.active && this.render();
    });

    this.interactions?.state$.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged((a,b) => a.readOnly === b.readOnly)
    ).subscribe(( {readOnly }) => {
      setTimeout(() => (this.readOnly = readOnly));
    });

    this.interactions?.state$.pipe(
      takeUntil(this.destroy$),
      map(s => s.viewportScrollTranslation),
      distinctUntilChanged()
    ).subscribe((translation: number) => {
      this.yScrollTranslation = translation;
      this.yScrollGroup?.setAttribute('transform', `translate(0,${translation})`);
      this.render();
    });

    this.interactions?.state$.pipe(
      takeUntil(this.destroy$),
      map(s => !!(s.viewportSlideActive || s.viewportScrollActive)),
      distinctUntilChanged()
    ).subscribe((scrolling: boolean) => {
      if (scrolling) {
        this.slideActive = true;
      } else {
        setTimeout(() => (this.slideActive = false), 90);
      }
    });

    this.svgContainer.setAttribute(tlStyle.standardAttribute.dataScope, this.scopeUID);
    this.svgContainer.classList.add(tlStyle.standardClass.viewport);

    const host = this.host.nativeElement;

    this.yScrollGroup = host.querySelector('#yScrollGroup');
    //this.svgContainer = host.querySelector('#svgContainer');
    this.legendContainer = host.querySelector('#legend');
    this.axisContainer = host.querySelector('#xAxis');
    this.axisBorder = host.querySelector('#xAxisBorder');
    this.xAxisOffset = host.querySelector('#xAxisOffset');
    this.viewportVClip = host.querySelector('#viewportVClip');
    //this.viewportHClip = host.querySelector('#viewportHClip');
    this.printLines = host.querySelector('#printLines');
    this.viewLines = host.querySelector('#viewLines');
    this.groupDrag = host.querySelector('#group-drag');
    this.tickMarkers = host.querySelector('#tick-markers');

    this.fontStylesheet = this.svgContainer.appendChild(document.createElement('style'));
    this.coloursStylesheet = this.svgContainer.appendChild(document.createElement('style'));
    this.stylesheet = this.svgContainer.appendChild(document.createElement('style'));

    this.groupDrag.classList.add(CLASS_SCREEN_ONLY);

    if (this._viewBox) {
      this.svgContainer.setAttribute('viewBox', this._viewBox);
    }

    let styleContent = tlStyle.standardStyle(this.scopeUID);

    styleContent += tlStyle.screenOnlyStyle(this.scopeUID);

    /** @deprecated TO BE REMOVED:*/
    // add dynamic style rules to component:
    [...CORE_STYLE].forEach(([selector, rules]) => {
      styleContent += `.${this.scopeUID} ${selector} {
          ${Object.keys(rules).map(k => `${k}:${rules[k]};`).join('')}
        }`
    });

    this.stylesheet.textContent = styleContent;

    this.setFontSize();

  }

  getViewportDims(): { height: number; width: number; } {
    //this.updateViewportDims();
    const { height, width } = this.svgContainer.getBoundingClientRect();
    return { height, width };

  }

  setFontSize(): void {
    this.fontStylesheet.textContent = `
      [${tlStyle.standardAttribute.dataScope}='${this.scopeUID}'] .tl-axis-offset text,
      [${tlStyle.standardAttribute.dataScope}='${this.scopeUID}'] #xAxis text {
        font-size: ${this.layout.settings?.fontSize || 12}px 
      }
    `;
  }

  cloneContent(): SVGElement {
    return this.svgContainer.cloneNode(true);
  }

  render(): void {

    if (!this.layout.settings || !this.layout.fittingMap) {
      return;
    }

    this.coloursStylesheet.textContent = tlStyle.colourStyles([...this.layout.settings.coloursAvailable.entries()], this.scopeUID);

    this.svgContainer.dataset.viewMode = this.layout.settings.viewMode.toString();

    this.yScrollGroup.setAttribute('transform', `translate(0,${this.yScrollTranslation})`);
    this.groupDrag.setAttribute('transform', `translate(${this.layout.settings.groupsWidth},0)`);

    this.updateClippingMasks();
    this.setFontSize();
    this.renderLegend();

    this.updateViewportDims();

    this.svgContainer.style.setProperty('display', 'none');

    this.plotMaps.axis = renderAxis(this.layout, this.axisContainer, this.plotMaps.axis);
    this.plotMaps.markers = renderMarkers(this.layout, this.tickMarkers, this.plotMaps.markers);
    this.plotMaps.printLines = renderPrintLines(this.layout, this.printLines, this.plotMaps.printLines);

    this.updateNodeMap();
    this.updateBaseDims();
    this.updateViewLines();
    this.renderGroups(this.layout.fittingMap, false, 0);

    const legendOffset = (this.layout.settings.displayLegend && this.layout.settings.legendPosition === LegendPosition.top && this.layout.legend?.totalHeight || 0);
    //this.renderPrintLines();
    const axisOffset = legendOffset + this.layout.axis.totalHeight;

    this.axisContainer.setAttribute('transform', `translate(0,${legendOffset})`);
    // update axiscontainer position:
    this.axisBorder.setAttribute('transform', `translate(0,${axisOffset})`);
    this.xAxisOffset.setAttribute('transform', `translate(0,${axisOffset})`);

    this.svgContainer.style.setProperty('display', 'block');

  }

  private updateClippingMasks(): void {
    //this.viewportHClip.setAttribute('x', this.layout.settings.groupsWidth);
    this.viewportVClip.setAttribute('height', this.layout.fittingMeta.totalHeight);
  }

  private updateNodeMap() {

    for (const groupUid in this.groupNodes) {
      const group = this.layout.getGroup(groupUid);
      if (!group) {
        this.removeGroupNode(groupUid, true);
      } else {
        this.groupNodes[groupUid].fitting = group;
      }
    }

    for (const taskId in this.taskNodes) {
      const task = this.layout.getTask(taskId);
      if (!task) {
        this.removeTaskNode(taskId, true);
      } else {
        this.taskNodes[taskId].fittingData = task;
      }
    }

    for (const group of this.layout.getGroups()) {
      if (!this.groupNodes[group.groupUid]) {
        this.groupNodes[group.groupUid] = createGroupNode(group);
      }
    }

    for (const task of this.layout.getTasks()) {
      if (!this.taskNodes[task.ID]) {
        this.taskNodes[task.ID] = createTaskNode(task);
        //this.createTaskNode(task);
      }
    }

  }

  private updateBaseDims() {
    this.rowHeight = this.layout.settings.fontSize + this.layout.settings.textPadding * 2;
    this.rowPadding = this.layout.settings.taskPadding;
  }

  private updateViewportDims(): void {

    const { height, width } = this.svgContainer.getBoundingClientRect();

    this.viewportDims = {
      height: height,
      width: width,
      axisHeight: this.layout.axis.totalHeight
    };

    const vpStart = -1 * this.yScrollTranslation;
    const vpEnd = vpStart + this.viewportDims.height - this.viewportDims.axisHeight;

    this.viewportRange = [vpStart, vpEnd];
    this.viewportChange.emit({ ...this.viewportDims });

  }

  private renderLegend(): void {

    removeChildren(this.legendContainer);

    if (!this.layout.settings.displayLegend) {
      return;
    }

    timelineRender.renderLegend(this.legendContainer, this.layout);

  }

  private renderGroups(groups: GroupFitting[], groupEven: boolean, groupStart: number): void {

    for (const group of groups) {

      groupEven = !groupEven;
      //find group node, or create:
      if (!this.groupNodes[group.groupUid]) {
        this.groupNodes[group.groupUid] = createGroupNode(group);
      }
      const groupNode = this.groupNodes[group.groupUid];

      // update group properties:
      this.updateGroupNode(groupNode, groupEven, groupStart);

      groupStart += groupNode.fitting.groupHeight;

    }

  }

  private updateGroupNode(groupNode: GroupNode, groupEven: boolean, rowIndex: number): void {

    const fitting: GroupFitting = groupNode.fitting;

    const backgroundHeight = fitting.groupHeight * (this.rowHeight + this.rowPadding);
    const backgroundY = this.rowPadding / 2;
    const yPosition = rowIndex * (this.rowHeight + this.rowPadding);
    const borderY = backgroundHeight + backgroundY;
    const rectHeight = backgroundHeight - this.rowPadding;

    // center position group text:
    // const textHeight = fitting.textWrapped.length * this.rowHeight;
    // const textY = (backgroundHeight - textHeight) / 2;

    // always center position group text in visible area:
    const groupStart = yPosition;
    const groupEnd = yPosition + rectHeight;
    const isGroupVisible = this.isVisible(groupStart, groupEnd);
    const textHeight = fitting.textWrapped.length * this.rowHeight;
    let textY = (backgroundHeight - textHeight) / 2;

    if (isGroupVisible) {
      const viewportStart = this.viewportRange[0];
      const viewportEnd = this.viewportRange[1];
      const maxEnd = Math.min(viewportEnd, groupEnd);
      let availableHeight = 0;

      if (groupStart >= viewportStart && groupStart <= viewportEnd) {
        availableHeight = groupStart + maxEnd - textHeight;
      } else if (groupEnd >= viewportStart && groupEnd <= viewportEnd) {
        availableHeight = viewportStart + maxEnd - textHeight;
      } else {
        availableHeight = viewportEnd + viewportStart - textHeight;
      }

      textY = availableHeight / 2 - groupStart;
    }

    if (!this.isVisible(yPosition, yPosition + rectHeight)) {
      if (groupNode.isPlaced) {
        this.removeGroupNode(fitting.groupUid, false);
      }
      return;
    }

    const readOnly = fitting?.tasks?.some(({ readOnly }) => readOnly);

    groupNode.container.setAttribute(tlStyle.standardAttribute.dataGroupUid, fitting.groupUid);

    groupNode.container.setAttribute('transform', `translate(0,${yPosition})`);
    groupNode.rect.setAttribute('height', `${Math.abs(rectHeight)}`);
    groupNode.rect.setAttribute('y', `${this.rowPadding}`);
    groupNode.rect.setAttribute('x', `${fitting.groupStart}`);
    groupNode.rect.setAttribute('width', `${Math.abs(fitting.groupWidth)}`);
    groupNode.background.setAttribute('class', `group-background${readOnly ? ' readOnly' : ''}`);
    groupNode.background.setAttribute('width', '100%');
    groupNode.background.setAttribute('y', `${backgroundY + GROUP_STROKE_ALLOWANCE}`);
    groupNode.background.setAttribute('x', `${fitting.groupStart}`);
    groupNode.background.setAttribute('height', `${Math.abs(backgroundHeight - GROUP_STROKE_ALLOWANCE * 2)}`);
    groupNode.background.setAttribute('data-even', `${groupEven}`);
    groupNode.background.setAttribute(tlStyle.standardAttribute.dataClick, ClickAction.Swimlane);

    groupNode.border.setAttribute('y1', `${borderY}`);
    groupNode.border.setAttribute('y2', `${borderY}`);
    groupNode.border.setAttribute('x1', `${fitting.groupStart}`);
    groupNode.text.setAttribute('transform', `translate(${fitting.groupStart + this.layout.settings.textPadding},0)`);
    groupNode.text.setAttribute('y', `${textY}`);

    groupHandles.updateSiblingClickHandles(groupNode.container);
    groupHandles.updateChildClickHandle(groupNode.container);

    if (fitting.pageBreak) {
      groupNode.border.setAttribute('x1', '0');
      groupNode.border.classList.add('page-break');
    }

    if (!fitting.parent) {
      groupNode.container.dataset.rootGroup = 'true';
    }

    this.updateTextNode(groupNode.text, fitting.textWrapped);

    if (fitting.children.length) {
      this.renderGroups(fitting.children, !groupEven, rowIndex);
    }

    //if tasks then update:
    if (fitting.taskRows.length) {
      this.updateTaskRows(groupNode, rowIndex);
    }

    if (!groupNode.isPlaced) {

      this.placeGroupNode(groupNode);
    }

    

  }

  private placeGroupNode(groupNode: GroupNode): void {

    //identify group that this group to preced this one in node order:
    const fitting = groupNode.fitting;
    if (fitting.parent) {
      const childIndex = fitting.parent.children.findIndex(v => v.groupUid === fitting.groupUid);
      let beforeNode;
      // if first child then append after parent:
      if (childIndex === 0) {
        beforeNode = this.groupNodes[fitting.parent.groupUid].container;
        // else append after previous child:
      } else {
        beforeNode = this.groupNodes[fitting.parent.children[childIndex - 1].groupUid].container;
      }
      beforeNode.insertAdjacentElement('afterend', groupNode.container);
    } else {
      // Ammend this to insert groupNode in correct order relative to parent group and siblings:
      this.yScrollGroup.appendChild(groupNode.container);
    }

    groupNode.container.appendChild(groupNode.background);
    groupNode.container.appendChild(groupNode.border);
    groupNode.container.appendChild(groupNode.rect);
    groupNode.container.appendChild(groupNode.text);
    groupNode.container.appendChild(groupNode.taskGroup);

    groupNode.isPlaced = true;

    if (groupNode.fitting?.children?.length) {
      groupNode.fitting.children.forEach(({ groupUid }) => {
        this.placeGroupNode(this.groupNodes[groupUid]);
      });
    }

    groupHandles.updateSiblingClickHandles(groupNode.container);
    groupHandles.updateChildClickHandle(groupNode.container);


  }

  private placeTaskNode(groupNode: GroupNode, taskNode: TaskNode): void {

    const { fittingData, wrapper, background, text, symbol, baseline } = taskNode;
    const { displayBaseline } = this.layout.settings;

    //const group = groupNode.fitting

    groupNode.taskGroup.appendChild(wrapper);

    wrapper.appendChild(background);
    wrapper.appendChild(text);

    appendOrRemove(fittingData.Milestone, wrapper, symbol);
    appendOrRemove(displayBaseline, wrapper, baseline);

    taskNode.isPlaced = true;

  }

  private updateViewLines(): void {
    // first remove or update existing viewLines:
    for (const viewLineId in this.viewLineNodes) {
      const viewLine = this.viewLineNodes[viewLineId];
      const fitting = this.layout.fittingMeta.viewLines.get(viewLineId);
      if (!fitting) {
        viewLine.remove();
        this.viewLineNodes = Object.entries(this.viewLineNodes)
          .filter(([k]) => k !== viewLineId)
          .reduce((a, [k, v]) => ({ ...a, [k]: v }), {});
        continue;
      }
      viewLine.update(fitting);
      if (!viewLine.placed) {
        viewLine.append(this.viewLines);
      }
    }

    // create new viewLines:
    for (const [id, fitting] of this.layout.fittingMeta.viewLines) {
      if (!this.viewLineNodes[id]) {
        this.viewLineNodes[id] = new ViewLineComponent(fitting.id);
        this.viewLineNodes[id].update(fitting);
        this.viewLineNodes[id].append(this.viewLines);
      }
    }

  }

  private updateTaskRows(groupNode: GroupNode, groupStart: number): void {

    const fitting = groupNode.fitting;

    let rowIndex = fitting.childGroupHeight || 0; //task rows rendered below child group tasks

    for (const row of fitting.taskRows) {

      const height = (row.rowHeight * this.rowHeight) + ((row.rowHeight - 1) * this.rowPadding);
      //get size relative to group container:
      const yBase = (rowIndex * this.rowHeight) + ((rowIndex + 1) * this.rowPadding);
      //get position relative to scroll container:
      const yTotal = yBase + ((groupStart * this.rowHeight) + ((groupStart + 1) * this.rowPadding));

      for (const task of row.tasks) {
        //if no node exists for task then create:
        if (!this.taskNodes[task.ID]) {
          //this.createTaskNode(task);
          this.taskNodes[task.ID] = createTaskNode(task);
        }
        const taskNode = this.taskNodes[task.ID];

        if (!this.isVisible(yTotal, yTotal + height)) {
          if (taskNode.isPlaced) {
            this.removeTaskNode(task.ID, false);
          }
          continue;
        }
        // WBN: this calculation should be moved to timeline layout:
        taskNode.fittingData.VerticalOffset = yBase;
        taskNode.positionData.verticalOffset = yBase;

        taskNode.positionData.nominalHeight = height;

        updateTaskNode(this.layout, task, taskNode.wrapper);
        //timelineRender.updateTaskNode(this.layout, taskNode);

        //if taskNode not placed or placed in a different group then append:
        if (!taskNode.isPlaced || taskNode.wrapper.parentElement !== groupNode.taskGroup) {
          this.placeTaskNode(groupNode, taskNode);
        }
      }
      //add row height to index accumulator:
      rowIndex += row.rowHeight;
    }

  }

  private removeTaskNode(id: string, deleteNode: boolean): void {

    const node = this.taskNodes[id];
    if (node) {
      node.wrapper.remove();
      node.isPlaced = false;
      if (deleteNode) {
        Reflect.deleteProperty(this.taskNodes, id);
      }
    }

  }

  private removeGroupNode(id: string, deleteNode?: boolean): void {

    const node = this.groupNodes[id];

    if (!node) return;

    ['rect', 'text', 'background', 'border', 'taskGroup']
      .forEach(v => node[v] && node[v].remove());

    // remove child nodes if present:
    if (node.fitting.children.length) {
      node.fitting.children.forEach(({ groupUid }) => {
        this.removeGroupNode(groupUid, deleteNode);
      });
    }
    if (node.fitting.tasks) {
      for (const row of node.fitting.taskRows) {
        for (const task of row.tasks) {
          this.removeTaskNode(task.ID, deleteNode);
        }
      }
    }
    if (deleteNode) {
      Reflect.deleteProperty(this.groupNodes, id);
    } else {
      node.isPlaced = false;
    }
  }

  private updateTextNode(node, text: string[]): void {

    const lines = text.slice(0);
    const l = this.layout.settings;

    removeChildren(node);

    node.appendChild(
      tspan(l.textPadding, l.fontSize + l.textPadding, lines.shift())
    );

    lines.forEach(line => {
      node.appendChild(
        tspan(l.textPadding, l.fontSize + l.textPadding + this.rowPadding, line)
      )
    });

  }

  private updateScrollTranslation(t: number): void {
    this.yScrollTranslation = t;
    this.yScrollGroup.setAttribute('transform', `translate(0,${-1 * this.yScrollTranslation})`);
  }

  private isVisible(yTop: number, yBottom: number): boolean {
    return !(yBottom < this.viewportRange[0] || yTop > this.viewportRange[1]);
  }

  private sizeByRowCount(index: number, includePadding: boolean): number {
    const rowHeight = this.layout.settings.fontSize + this.layout.settings.textPadding * 2;
    return (index * rowHeight) + ((index + (includePadding ? 1 : 0)) * this.layout.settings.taskPadding);
  }

  private getRowHeight() {
    return this.layout.settings.fontSize + this.layout.settings.textPadding * 2;
  }

  private getPaddedRowHeight() {
    return this.getRowHeight() + this.layout.settings.taskPadding;
  }

}
/** @deprecated */
function tspan(x, dy, text) {
  const tspan = mkNode('tspan', {
    x: x,
    dy: dy
  });
  tspan.textContent = text;
  return tspan;
}

/** @deprecated */
function appendOrRemove(append: boolean, parent, child): void {
  append ? parent.appendChild(child) : child.remove();
}
