import { HierarchyNode, TreeProjectNode, TreeRevisionNode, User, View } from 'tp-traqplan-core/dist/data-structs';
import { shortFormat } from 'tp-traqplan-core/dist/date-format';



export function formatRevisionLabel({ node, revision, user, view, views, nodes }: {
  node: TreeProjectNode, revision: TreeRevisionNode, user?: User, view?: View, views?: View[], nodes?: HierarchyNode[]
}): string {
  const { createdFrom, label, projectVersion, published, viewVersion } = revision;

  if (published) {
    return `v${projectVersion}.${viewVersion} ${shortFormat(published)} ${label || ''} ${user?.name || ''}`;
  } else {

    let matchingView;

    if (Array.isArray(views)) {
      matchingView = views.find(({ id }) => id === revision.view);
    }
    // let from = node.revisions?.find(({ id }) => id === createdFrom);


    let from = node.revisions?.reduce((latest:any, current) => {
        if (!latest || (current.published && current.published > latest.published)) {
          return current;
        }
        return latest;
      }, null);

    if (!(from && from.id)) {


      const currentNode = nodes?.find(({ id }) => id === node.id) || node;
      const revisions = Array.from(<TreeRevisionNode[]>currentNode.revisions);
      from = revisions.find(({ id }) => id === createdFrom);

      if (from && from.id) {
        const matchingRevisions = revisions.filter(
          ({ id, projectVersion, view: v }) => id !== from?.id && projectVersion === from?.projectVersion && v === view?.id
        );
        from = matchingRevisions[matchingRevisions.length - 1];
      }
    }

    let latestRevision;

    if(!from && node.revisions && !published){
      latestRevision = node.revisions.reduce((latest:any, current) => {
        if (!latest || (current.published && current.published > latest.published)) {
          return current;
        }
        return latest;
      }, null);

    }
    return `Draft
    ${from ? from.label || `v${from.projectVersion}.${from.viewVersion}` : `v${latestRevision.projectVersion}.${latestRevision.viewVersion}`}
    ${revision.view === view?.id ? `(${view?.name})` : matchingView?.name ? `(From ${matchingView?.name})` : ''}`;
  }
}
