import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'revisionLabel'
})
export class RevisionLabelPipe implements PipeTransform {

  transform(): unknown {
    return null;
  }

}
