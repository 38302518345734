import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { shareReplay, map, catchError } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { unwrapDatesRecursive } from 'tp-common/json';
import statusCodes from 'tp-common/status-codes';
import { RequestError } from './structs';
import { User } from 'tp-traqplan-core/dist/data-structs';

export function applyCache(callback: (...args: any[]) => any): (...args: any[]) => any {
  let timeout, cache$, lastArgs;
  return ({
    [callback.name]: function(...args) {
      if (!isEqual(args, lastArgs) || !cache$) {
        clearTimeout(timeout);
        timeout = setTimeout(() => { cache$ = null; }, 60);
        cache$ = callback(...args).pipe(
          shareReplay(1)
        );
      }
      return cache$;
    }
  })[callback.name];
}

export function defaultPipe() {
  return $ => $.pipe(
    map(unwrapDatesRecursive),
    //retry(1),
    catchError((error: HttpErrorResponse) => {
      throw <RequestError>{
        code: error.status,
        message: error.error?.error || statusCodes[error.status],
        account: error.headers.get('X-Account')
      };
    })
  )
}

export const noAccountError = () => throwError(<RequestError>{
  code: null,
  message: 'No Account Selected',
  account: null
});

export function parseUser(value: any): User {
  const user = typeof value === 'string' ? JSON.parse(value) : value;
  for (const prop of [ 'lastLoginTime', 'created', 'deleted' ]) {
    user[prop] = user[prop] ? new Date(user[prop]) : null;
  }
  return user;
}
