import { Component } from '@angular/core';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';

@Component({
  selector: 'app-resume-session',
  templateUrl: './resume-session.component.html',
  styleUrls: ['./resume-session.component.scss']
})
export class ResumeSessionComponent {

  visible = true;

  actions: ModalWindowAction[] = [
    {
      label: 'Resume',
      type: 'positive',
      enterKey: true
    }
  ];

  onClose() {
    //this.store.dispatch(
    //  authActions.sessionTimeout()
    //);
    window.location.reload();
  }

}
