import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileUploadModule } from 'ng2-file-upload';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// PRIMENG COMPONENT LIBRARY
import { SidebarModule } from 'primeng/sidebar';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { MenuModule } from 'primeng/menu';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { TreeModule } from 'primeng/tree';
import { TooltipModule } from 'primeng/tooltip';
import { OrderListModule } from 'primeng/orderlist';

import { DateInterceptor } from './api/date.interceptor';
import { AuthInterceptor } from './api/auth.interceptor';

// PARTIALS:
import { HeaderComponent }  from './header/header.component';

// COMPONENTS
import { LoginPageComponent } from './login-page/login-page.component';

// CORE (STATELESS) COMPONENTS:
import { CoreModule } from './core/core.module';
import { ModalsModule } from 'src/app/modals/modals.module';

import { StoreModule } from '@ngrx/store';
import { reducers } from './state';
import { metaReducers } from './state/meta-reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';

import locale from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';

import { AuthEffects } from './state/auth/effects';
import { AccountEffects } from './state/account/effects';
import { ProjectsEffects } from './state/projects/effects';
import { PoapEffects } from './state/poap/effects';
import { TpsEffects } from './state/tps/effects';
import { PricingEffects } from './state/pricing/effects';
import { PricingPlansEffects } from './state/pricingPlans/effects';
import { SubscriptionsEffects } from './state/subscriptions/effects';
import { UserTncAcceptanceEffects } from './state/userTncAcceptance/effects';
import { CustomerEffects as PowerBiCustomerEffects } from './state/power-bi/customer/effects';
import { PricingEffects as PowerBiPricingEffects } from './state/power-bi/pricing/effects';
import { PricingPlansEffects as PowerBiPricingPlansEffects } from './state/power-bi/pricingPlans/effects';
import { SubscriptionsEffects as PowerBiSubscriptionsEffects } from './state/power-bi/subscriptions/effects';
import { UserTncAcceptanceEffects as PowerBiUserTncAcceptanceEffects } from './state/power-bi/userTncAcceptance/effects';

registerLocaleData(locale, 'en-GB');

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    // PAGES
    LoginPageComponent,
    // PARTIALS
    HeaderComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        //don't change this:
        strictStateImmutability: false,
        strictActionImmutability: false,
      }
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([
      AuthEffects,
      AccountEffects,
      ProjectsEffects,
      PoapEffects,
      TpsEffects,
      PricingEffects,
      PricingPlansEffects,
      SubscriptionsEffects,
      UserTncAcceptanceEffects,
      PowerBiCustomerEffects,
      PowerBiPricingEffects,
      PowerBiPricingPlansEffects,
      PowerBiSubscriptionsEffects,
      PowerBiUserTncAcceptanceEffects
    ]),
    FileUploadModule,
    CoreModule,
    ModalsModule,
    // primeng:
    SidebarModule,
    TieredMenuModule,
    MenuModule,
    ContextMenuModule,
    DialogModule,
    NgSelectModule,
    ToastModule,
    CalendarModule,
    NgSelectModule,
    DialogModule,
    ProgressBarModule,
    DropdownModule,
    RadioButtonModule,
    ButtonModule,
    CheckboxModule,
    InputTextModule,
    TreeModule,
    SharedModule,
    TooltipModule,
    ToggleButtonModule,
    OrderListModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
