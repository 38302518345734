import { Component, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { take, map, tap, takeUntil } from 'rxjs/operators';
import access from 'tp-common/access';
import { taskConstants } from 'tp-common/revisions';
import { uuid } from 'tp-common/uuid';
import { State } from 'src/app/state';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { MilestonesApiService } from 'src/app/api/milestones-api.service';
import { MilestoneCategory, User, License, View } from 'tp-traqplan-core/dist/data-structs';
import { AgGridDropdownColourRendererComponent } from 'src/app/ag-grid-components/ag-grid-dropdown-colour-renderer/ag-grid-dropdown-colour-renderer.component';
import { AgGridDropdownRendererComponent } from 'src/app/ag-grid-components/ag-grid-dropdown-renderer/ag-grid-dropdown-renderer.component';
import * as modalActions from 'src/app/state/modal/actions';
import * as poapActions from 'src/app/state/poap/actions';
import { filterDSReady } from 'src/app/state/helpers';


@Component({
  selector: 'app-edit-milestones',
  templateUrl: './edit-milestones.component.html',
  styleUrls: ['./edit-milestones.component.scss']
})
export class EditMilestonesComponent implements OnDestroy {

  visible = true;

  actions: ModalWindowAction[] = [
    {
      label: 'Save',
      type: 'positive',
      confirm: true
    },
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true
    }
  ];

  user: User;
  license: License;
  view: View;
  userCanCreate = false;
  userCanCreateGlobal = false;
  selectionDeleteable = false;
  errorMessage:boolean = false;

  colours: { value: string; }[] = [...taskConstants.colours.keys()].map(value => ({ value }));
  symbols: { value: string; }[] = Object.keys(taskConstants.symbolFactories).map(value => ({ value }));

  coloursAvailable: { value: string; }[] = this.colours;
  symbolsAvailable: { value: string; }[] = this.symbols;

  changes$ = new BehaviorSubject<MilestoneCategory[]>([]);

  private destroy$ = new Subject<void>();

  milestones$: Observable<MilestoneCategory[]> = combineLatest(
    this.store.pipe(
      takeUntil(this.destroy$),
      map(state => state.poap.milestones),
      filterDSReady()
    ),
    this.changes$
  ).pipe(
    takeUntil(this.destroy$),
    map(([{ value: saved }, changes]) => {
      const changeMap = new Map<string,MilestoneCategory>(changes.map(c => [c.id, c]));
      const merged: MilestoneCategory[] = [];

      for (const s of saved) {
        const change = changeMap.get(s.id);
        if (change) {
          if (change.deleted) {
            continue;
          }
          merged.push(change);
          changeMap.delete(s.id);
        } else {
          merged.push(s);
        }
      }
      for (const change of changeMap.values()) {
        merged.push(change);
      }
      return [
        ...merged.filter(m => !m.view),
        ...merged.filter(m => m.view)
      ];
    }),
    tap(milestones => {
      this.milestones = milestones;
    })
  );

  milestones: MilestoneCategory[];

  modules = AllModules;

  gridOptions = {
    frameworkComponents: {
      dropdown: AgGridDropdownRendererComponent,
      colourDropdown: AgGridDropdownColourRendererComponent
    }
  };

  columnDefs = [
    {
      width: 45,
      cellRenderer: params => {
        return this.isFieldEditable(params.data, 'deleted') ? `<span class="cell_icon fas cell_delete">trash</span>` : '';
      },
      onCellClicked: params => this.isFieldEditable(params.data, 'deleted') && this.onDelete(params)
    },
    // global milestone indicator column:
    {
      headerName: 'Global',
      width: 75,
      cellRenderer: params => {
        return params.data.view ? '' : `<span class="cell_icon cell_global fas">globe</span>`;
      },
      onCellClicked: params => this.isFieldEditable(params.data, 'view') && this.onGlobal(params)
    },
    {
      headerName: '',
      field: 'locked',
      width: 45,
      editable: params => this.isFieldEditable(params.data, 'locked'),
      cellRenderer: params => {
        const [className, icon] = params.data.locked ? ['cell_locked', 'lock'] : ['cell_unlocked', 'unlock'];
        return `<span class="cell_icon fas ${className}">${icon}</span>`;
      },
      onCellClicked: params => {
        this.isFieldEditable(params.data, 'locked') && this.toggleLocked(params.data);
      }
    },
    {
      headerName: '',
      editable: false,
      width: 40,
      cellRenderer: params => {
        if (params.data.symbol && params.data.colour) {
          return `
            <div
              class="preview-cell"
              style="position: absolute;display: flex;align-items: center;height: 100%;"
            >
              ${ taskConstants.generateSymbolMarkup(params.data.symbol, 17, params.data.colour) }
            </div>
          `;
        }
        return '';
      }
    },
    {
      headerName: 'Symbol',
      field: 'symbol',
      editable: params => this.isFieldEditable(params.data, 'symbol'),
      cellEditor: 'agRichSelectCellEditor',
      cellRenderer: 'dropdown',
      cellEditorParams: params => {
        if (!params.data.colour) {
          return [...this.symbols]
        }
        const symbols = new Set(this.symbols.map(v => v.value));
        for (const milestone of this.milestones) {
          if (milestone.colour === params.data.colour) {
            symbols.delete(milestone.symbol);
          }
        }
        const values = [...symbols];
        params.data.symbol && values.unshift(params.data.symbol);
        return { values };
      },
    },
    {
      headerName: 'Colour',
      field: 'colour',
      editable: params => this.isFieldEditable(params.data, 'colour'),
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: params => {
        if (!params.data.symbol) {
          return [...this.colours]
        }
        const colours = new Set(this.colours.map(v => v.value));
        for (const milestone of this.milestones) {
          if (milestone.symbol === params.data.symbol) {
            colours.delete(milestone.colour);
          }
        }
        const values = [...colours];
        params.data.colour && values.unshift(params.data.colour);
        return { values };
      },
      cellRenderer: 'dropdown'
    },
    {
      headerName: 'Name',
      field: 'name',
      flex:2,
      editable: params => this.isFieldEditable(params.data, 'name')
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 3,
      editable: params => this.isFieldEditable(params.data, 'description')
    }
  ];

  form = new FormGroup({
    name: new FormControl('',
      [
        Validators.required,
        Validators.minLength(1),
      ]/*,
      [
        (control: AbstractControl) => {
          const name = control.value;
          return this.milestonesApi.checkAvailable(name).pipe(
            map(({ available }) => {
              if (available) {
                return null;
              }
              return { namingConflict: true };
            })
          );
        }
      ]*/
    ),
    description: new FormControl(''),
    global: new FormControl(false),
    locked: new FormControl(false),
    colour: new FormControl(null,
      [
        Validators.required
      ]
    ),
    symbol: new FormControl(null,
      [
        Validators.required
      ]
    )
  });

  constructor(
    private store: Store<State>,
    private milestonesApi: MilestonesApiService
  ) {
    this.store.pipe(
      take(1),
      tap(state => {
        const account = state.account.selected;

        this.user = state.auth.user;
        this.license = state.account.licenses.find(lic => lic.assignee === this.user.id);
        this.view = state.poap.viewSelected;
        this.userCanCreate = access.permissionIncludes(account.access, access.actionType.editViews);
        this.userCanCreateGlobal = this.license.type === access.licenseType.globalAdmin;

        this.store.dispatch(
          poapActions.requestMilestones({
            view: this.view.id
          })
        );
      })
    ).subscribe();

    // this.form.valueChanges.subscribe(() => {
    //   this.coloursAvailable = this.getColoursAvailable();
    //   this.symbolsAvailable = this.getSymbolsAvailable();
    // })
  }

  

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }

  onCloseError():void {
    this.errorMessage = false;
  }


  onAdd(): void {
    if (!this.form.valid) {
      return;
    }

    const exists = this.milestones.some((item) => {
      return item.symbol === this.form.controls.symbol.value?.value && item.colour === this.form.controls.colour.value?.value;
    });

    if(exists){

      this.errorMessage = true;

    }else{
      const changes = this.changes$.getValue();

      changes.push({
        id: uuid(),
        view: this.form.controls.global.value ? null : this.view.id,
        author: this.user.id,
        version: new Date(),
        action: 'Create Milestone',
        name: this.form.controls.name.value,
        description: this.form.controls.description.value,
        colour: this.form.controls.colour.value?.value,
        symbol: this.form.controls.symbol.value?.value,
        locked: this.form.controls.locked.value,
        deleted: false
      });

      this.form.reset();

      this.changes$.next(changes);
      this.errorMessage = false;
    }
  }

  onDelete(params): void {
    const changes = this.changes$.getValue();

    const index = changes.findIndex(c => c.id === params.data.id);

    if (~index) {
      changes.splice(index,1);
    } else {
      changes.push({
        ...params.data,
        deleted: true
      });
    }

    this.changes$.next(changes);
  }

  onGlobal(params): void {
    const changes = this.changes$.getValue();

    const index = changes.findIndex(c => c.id === params.data.id);

    const current = {
      ...params.data,
      ...(changes[index] || {})
    };

    if (~index) {
      changes.splice(index,1);
    } else {
      changes.push({
        ...current,
        view: current.view ? null : this.view.id
      });
    }

    this.changes$.next(changes);
  }

  onActionSelected(action: ModalWindowAction): void {
    if (!action.confirm) {
      return;
    }

    const changes = this.changes$.getValue();

    if (!changes.length) {
      return;
    }

    this.milestonesApi.update({
      view: this.view.id,
      milestones: [...changes]
    }).pipe(
      tap(() => {
        this.store.dispatch(
          poapActions.requestMilestones({
            view: this.view.id
          })
        );
      })
    ).subscribe();
  }

  getSymbolMarkup(item) {
    this.errorMessage=null;
    return taskConstants.generateSymbolMarkup(item.value, 17, 'Black');
  }

  getRowNodeId(data: MilestoneCategory): string {
    return data.id;
  }



  onCellValueChanged(event): void {
    const changes = this.changes$.getValue();
    const index = changes.findIndex(c => c.id === event.data.id);

    if (~index) {
      changes[index] = event.data;
    } else {
      changes.push(event.data);
    }

    this.changes$.next(changes);
  }

  getSymbolsAvailable(): { value: string}[] {
    if (!this.milestones) {
      return [];
    }
    const colour = this.form.controls.colour.value?.value;
    if (!colour) {
      return [...this.symbols];
    }
    const symbols = new Set(this.symbols.map(v => v.value));
    for (const milestone of this.milestones) {
      if (milestone.colour === colour) {
        symbols.delete(milestone.symbol);
      }
    }
    return [...symbols].map(v => ({ value: v }));
  }

  getColoursAvailable(): { value: string}[] {
    if (!this.milestones) {
      return [];
    }
    const symbol = this.form.controls.symbol.value?.value;
    if (!symbol) {
      return [...this.colours]
    }
    const colours = new Set(this.colours.map(v => v.value));
    for (const milestone of this.milestones) {
      if (milestone.symbol === symbol) {
        colours.delete(milestone.colour);
      }
    }
    return [...colours].map(v => ({ value: v }));
  }

  private toggleLocked(milestone: MilestoneCategory) {
    const changes = this.changes$.getValue();
    const index = changes.findIndex(c => c.id === milestone.id);

    const change = {
      ...milestone,
      locked: !milestone.locked
    };

    if (~index) {
      changes[index] = change;
    } else {
      changes.push(change);
    }

    this.changes$.next(changes);
  }

  private isFieldEditable(milestone: MilestoneCategory, field: string): boolean {
    // if user doesn't have editView permission then always false:
    if (!this.userCanCreate || !this.view) {
      return false;
    }

    if (this.view.defaultMilestone === milestone.id && ['deleted', 'view'].includes(field)) {
      return false;
    }

    // if license is global admin then they can edit any of them:
    if (this.license.type === access.licenseType.globalAdmin) {
      return true;
    } else if (!milestone.view) {
      // only global admins can edit global milestones:
      return false;
    }

    // else must either be author or view is unlocked:
    if (!milestone.locked || milestone.author === this.user.id) {
      return true;
    }

    return false;
  }
}
