<app-modal-window
  header="Create Project"
  [(visible)]="visible"
  (actionSelected)="onActionSelected($event)"
  (closed)="onClose()"
  [actions]="actions"
>
  <form
    class="tp-form"
    [formGroup]="projectForm"
  >

    <div class="tp-form__field">
      <label for="name">Project Name</label>
      <input
        pInputText
        name="name"
        formControlName="name"
      />
    </div>

    <div
      class="tp-form__error"
      *ngIf="projectForm.errors?.projectNamingConflict"
    >
      A project with this name already exists
    </div>

    <div class="tp-form__field">
      <label for="programme">Programme</label>
      <p-autoComplete
        formControlName="programme"
        (completeMethod)="searchProgrammes($event)"
        (onSelect)="selectProgramme($event)"
        [dropdown]="true"
        field="name"
        [suggestions]="suggestions"
        appendTo="body"
      >
      </p-autoComplete>
    </div>

    <div
      class="tp-form__error"
      *ngIf="projectForm.errors?.programmeNamingConflict"
    >
      A programme with this name already exists
    </div>

  </form>

</app-modal-window>
