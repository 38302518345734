import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { timeDay, timeWeek, timeMonth, timeYear, timeFormat } from 'd3';
import { TextWrapping } from './tl-wrapping';
import { AxisOptions } from './tl-structs';

const WIDTH_TOLERANCE = 1.4;
const TICK_MIN_WIDTH = 48;
const TICK_PADDING = 3;
const STROKE_WIDTH = 1;

const V_PADDING = 1.5;

const axisCombinations = [
  ['day', 'monthYear'],
  ['week', 'monthYear'],
  ['month', 'year'],
  ['quarter', 'year'],
  ['year']
];

const formats = {
  day: timeFormat('%d'),
  week: timeFormat('%d'),
  month: timeFormat('%b'),
  quarter: timeFormat('Q%q'),
  monthYear: timeFormat('%b %Y'),
  year: timeFormat('%Y')
};

const intervals = {
  day: timeDay,
  week: timeWeek,
  month: timeMonth,
  quarter: timeMonth,
  monthYear: timeMonth,
  year: timeYear
};

interface TickLabel {
  date: Date;
  label: string;
  start: number;
  finish: number;
  center: number;
  floatLeft: boolean;
  floatRight: boolean;
}

dayjs.extend(quarterOfYear);
export class TimelineAxis {

  public ticks: TickLabel[][];
  public levelHeight: number;
  public totalHeight: number;
  public levelPadding = V_PADDING;
  public tickPadding = TICK_PADDING;
  public intervals: string[];

  private intervalWidths: any;

  private fontSize: number;
  private scale: any;
  private start: Date;
  private finish: Date;
  private width: number;

  private activeIntervals: number;

  private textWrapping = new TextWrapping();

  update(options: AxisOptions): void {

    if (options.fontSize !== this.fontSize) {
      this.updateNominalTickWidths(options.fontSize);
    }

    this.fontSize = options.fontSize;
    this.scale = options.scale;
    this.width = this.scale.range()[1] - this.scale.range()[0];
    this.start = this.scale.domain()[0];
    this.finish = this.scale.domain()[1];

    this.computeTickIntervals();
    this.computeAxisHeight();

  }

  private updateNominalTickWidths(fontSize: number): void {

    this.textWrapping.setFontSize(fontSize);

    this.intervalWidths = {
      day: this.textWrapping.measureText('00') * WIDTH_TOLERANCE,
      week: this.textWrapping.measureText('00') * WIDTH_TOLERANCE,
      month: this.textWrapping.measureText('mmm') * WIDTH_TOLERANCE,
      quarter: this.textWrapping.measureText('QQ') * WIDTH_TOLERANCE,
      monthYear: this.textWrapping.measureText('mmm-yyyy') * WIDTH_TOLERANCE,
      year: this.textWrapping.measureText('yyyy') * WIDTH_TOLERANCE
    };

  }

  private computeAxisHeight(): void {
    this.levelHeight = this.fontSize + V_PADDING * 2;
    this.totalHeight = this.levelHeight * this.ticks.length;
  }

  private computeTickIntervals(): void {

    for (const combination of axisCombinations) {
      const [axis1, axis2] = combination;
      const isQuarter = axis1 === axisCombinations[3][0];
      // create and measure the date intervals for the lower axis range:
      const interval = intervals[axis1];
      const range = isQuarter ? this.getRangeQuarter(interval, 3) : this.getRange(interval);

      if (this.rangeFits(axis1, range)) {
        this.ticks = [this.createTicks(axis1, range)];
        // in practice the second axis will alwats have substantially fewer tick intervals so don't need to check fit:
        if (axis2) {
          this.ticks.push(this.createTicks(axis2, this.getRange(intervals[axis2])));
        }
        this.intervals = [axis1, axis2];

        return;
      }
    }

    // Increment by one year until time scale can fit
    const axis = axisCombinations[4][0];
    const interval = intervals[axis];

    for (let i = 2; i < 101; i++) {
      const range = this.getRangeQuarter(interval, i);

      if (this.rangeFits(axis, range)) {
        this.ticks = [this.createTicks(axis, range, i)];
        this.intervals = [axis];
        return;
      }
    }

    this.ticks = [];
    this.intervals = [];

  }

  private createTicks(level: string, range: Date[], step?: number): TickLabel[] {

    const format = formats[level];
    const interval = intervals[level];

    const ticks = range.map((date, idx) => {

      const tickLabel = <Partial<TickLabel>>{};

      tickLabel.date = date;
      tickLabel.label = format(date);

      if (level === axisCombinations[3][0]) {
        const nextDate = range[idx + 1];

        if (nextDate && nextDate.getTime() > 0) {
          step = dayjs(nextDate).diff(date, 'month');
        } else {
          step = 3;
        }
      }

      const rangeStart = interval.floor(date);
      const rangeFinish = interval.offset(date, step || 1);

      if (level === axisCombinations[0][0] || level === axisCombinations[1][0]) {
        tickLabel.floatLeft = true;
      }

      if (this.start < rangeStart) {
        tickLabel.start = this.scale(rangeStart);
      } else {
        tickLabel.start = this.scale(this.start);
      }

      if (this.finish > rangeFinish) {
        tickLabel.finish = this.scale(rangeFinish);
      } else {
        tickLabel.finish = this.scale(this.finish);
      }

      const textWidth = tickLabel.finish - tickLabel.start - TICK_PADDING * 2;

      if (this.textWrapping.measureText(tickLabel.label) > textWidth) {
        tickLabel.label = '';
      }

      tickLabel.center = tickLabel.start + ((tickLabel.finish - tickLabel.start) / 2);

      // const start = this.scale( dateMax( this.start, interval.floor( date ) ) );
      // const finish = this.scale( dateMin( this.finish, interval.offset( date, 1) ) );

      return <TickLabel>{ ...tickLabel };
      //return { date, label, start, finish, center };
    });

    return ticks;

  }

  private getRange(interval): Date[] {
    return interval.range(interval.floor(this.start), interval.ceil(this.finish));
  }

  private getRangeQuarter(interval, step: number): Date[] {
    return interval.range(interval.floor(dayjs(this.start).startOf('quarter').toDate()), interval.ceil(this.finish), step);
  }

  private rangeFits(level: string, range: Date[]): boolean {
    const tickWidth = Math.max(this.intervalWidths[level] + TICK_PADDING * 2 + STROKE_WIDTH, TICK_MIN_WIDTH);
    return (range.length * tickWidth) <= this.width;
  }

}

//function dateMin(...args: Date[]): Date {
//  return new Date( Math.min(...args.filter(a => a !== null).map(d => d.getTime() ) ) );
//}

//function dateMax(...args: Date[]): Date {
//  return new Date( Math.max(...args.filter(a => a !== null).map(d => d.getTime() ) ) );
//}
