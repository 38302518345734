import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {  tap, map } from 'rxjs/operators';
import { State } from 'src/app/state';
import { take } from 'rxjs';
import * as poapActions from 'src/app/state/poap/actions';
import * as modalActions from 'src/app/state/modal/actions';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { ViewApiService } from 'src/app/api/view-api.service';

@Component({
  selector: 'app-create-view',
  templateUrl: './create-view.component.html',
  styleUrls: ['./create-view.component.scss']
})
export class CreateViewComponent {

  nameExists: boolean = false;
  workspace: any;
  returnedViews: any;

  form = new FormGroup({
    name: new FormControl('',
      [
        Validators.required,
        Validators.minLength(1),
      ],
      [
        (control: AbstractControl) => {
          const name = control.value;
          return this.viewApi.checkAvailable(name).pipe(
            map(({ available }) => {
              // const createButton = document.querySelector('.view-modal-window p-footer app-button[ng-reflect-text="Create View"] span')
              if (available) {
                return null;
              }

              // createButton?.innerHTML = 'Add View'
              // return { namingConflict: true };
            })
          );
        }
      ]
    )
  });

  actions: ModalWindowAction[] = [
    {
      label: 'Create View',
      type: 'positive',
      confirm: true,
      valid: () => this.form.valid,
      enterKey: true,
    },
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true,
      escKey: true
    }
  ];

  visible = true;
  constructor(
    private store: Store<State>,
    private viewApi: ViewApiService
  ) {

    this.visible = true;
  }

  onActionSelected(action: ModalWindowAction): void {

    if (!action.confirm) {
      return;
    }

    this.store.pipe(take(1)).subscribe((state) => {
      this.workspace = state.projects.hierarchy.value.find(p => p.parent === null)
     });

     const name: string = this.form.controls.name.value + '/@-@/' + this.workspace.id;

    this.viewApi.create(name).pipe(
      tap((view:any) => {

        this.store.dispatch(
          poapActions.requestViews()
        );
        setTimeout(() =>
        this.store.dispatch(poapActions.selectView({view})),1000);
      })
    ).subscribe();

  }
  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }

}
