import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SubscriptionApiService } from '../../../api/power-bi/subscription-api.service';
import { RequestError } from '../../../api/structs';
import * as subscriptionsActions from './actions';

@Injectable()
export class SubscriptionsEffects {

  constructor(
    private actions$: Actions,
    private subscriptionApi: SubscriptionApiService
  ) {}

  requestSubscriptions$ = createEffect(() => this.actions$.pipe(
    ofType(subscriptionsActions.requestSubscriptions),
    switchMap((): Observable<Action> => {
      return this.subscriptionApi.requestSubscriptions().pipe(
        switchMap(({ subscriptions }: any) => [
          subscriptionsActions.requestSubscriptionsSuccess(<any>{ subscriptions })
        ]),
        catchError((error: RequestError) => {
          return of(subscriptionsActions.requestSubscriptionsFailure({ error }))
        })
      );
    })
  ));

  requestSubscriptionById$ = createEffect(() => this.actions$.pipe(
    ofType(subscriptionsActions.requestSubscriptionById),
    switchMap(({ id }): Observable<Action> => {
      return this.subscriptionApi.requestSubscriptionById(id).pipe(
        switchMap((subscription) => [
          subscriptionsActions.requestSubscriptionByIdSuccess(<any>{ subscription })
        ]),
        catchError((error: RequestError) => {
          return of(subscriptionsActions.requestSubscriptionByIdFailure({ error }))
        })
      );
    })
  ));

}
