import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, throwError, of } from 'rxjs';
import { take, switchMap, filter } from 'rxjs/operators';
import { defaultPipe } from './common';
import { State } from 'src/app/state';
import { User, UserId, AccountInfo, AccountId, Staff } from 'tp-traqplan-core/dist/data-structs';
import { RequestError } from './structs';

const noTokenError = () => throwError(<RequestError>{
  code: null,
  message: 'Not staff authenticated',
  account: null
});

@Injectable({
  providedIn: 'root'
})
export class StaffApiService {

  constructor(
    private store: Store<State>,
    private http: HttpClient
  ) { }

  getAuth(): Observable<HttpHeaders> {
    return this.store.pipe(
      filter(state => !state.tps.pending),
      take(1),
      switchMap(state => {
        if (!state.tps.token) {
          return noTokenError()
        }
        return of(
          new HttpHeaders({
            'Authorization': `Bearer ${state.tps.token}`
          })
        );
      })
    );
  }

  requestLogin({ email, password }): Observable<{ tps: Staff; token: string }> {
    return this.http.post<{ tps: Staff; token: string }>(
      '/api/staff/auth',
      { email, password }
    ).pipe(
      defaultPipe()
    );
  }

  requestLoginSso(d: string): Observable<{ tps: Staff; token: string, ssoToken: string, origin: string }> {
    return this.http.post<{ tps: Staff; token: string, ssoToken: string, origin: string }>(
      '/api/staff/auth/sso',
      { d }
    ).pipe(
      defaultPipe()
    )
  }

  sendPasswordResetToken(email) {
    return this.http.post<void>(
      '/api/staff/auth/reset-password',
      { email }
    ).pipe(
      defaultPipe()
    );
  }

  verifyPasswordToken({ email, token }) {
    return this.http.get<void>(
      '/api/staff/auth/verify-password-token',
      { params: { email, token } }
    );
  }

  setPassword({ token, email, password }) {
    return this.http.post<void>(
      '/api/staff/auth/set-password',
      { token, email, password }
    ).pipe(
      defaultPipe()
    );
  }

  verifyToken(token: string): Observable<{ tps: Staff; token: string }> {
    return this.http.post<{ tps: Staff; token: string }>(
      '/api/staff/auth/verify',
      { token }
    ).pipe(
      defaultPipe()
    );
  }

  getPendingUsers(): Observable<User[]> {
    return this.getAuth().pipe(
      switchMap((headers): Observable<User[]> => {
        return this.http.get<User[]>(
          '/api/staff/users-pending',
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  getPendingAccounts(): Observable<AccountInfo[]> {
    return this.getAuth().pipe(
      switchMap((headers): Observable<AccountInfo[]> => {
        return this.http.get<AccountInfo[]>(
          '/api/staff/accounts-pending',
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  activateUsers(ids: UserId[]) {
    return this.getAuth().pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/staff/activate-users',
          { ids },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  activateAccounts(ids: AccountId[]) {
    return this.getAuth().pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/staff/activate-accounts',
          { ids },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  searchAccounts(search: string): Observable<AccountInfo[]> {
    return this.getAuth().pipe(
      switchMap((headers): Observable<AccountInfo[]> => {
        return this.http.get<AccountInfo[]>(
          '/api/staff/search-accounts',
          { headers, params: { search } }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  searchUsers(search: string): Observable<User[]> {
    return this.getAuth().pipe(
      switchMap((headers): Observable<User[]> => {
        return this.http.get<User[]>(
          '/api/staff/search-users',
          { headers, params: { search } }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  getTempAccess(account: AccountId): Observable<{ user: User; token: string; account: AccountId }> {
    return this.getAuth().pipe(
      switchMap((headers): Observable<{ user: User; token: string; account: AccountId }> => {
        return this.http.get<{ user: User; token: string; account: AccountId }>(
          '/api/staff/temp-access',
          { headers, params: { account } }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  getUserToken(user: UserId): Observable<{ user: User; token: string }> {
    return this.getAuth().pipe(
      switchMap((headers): Observable<{ user: User; token: string }> => {
        return this.http.get<{ user: User; token: string }>(
          '/api/staff/user-token',
          { headers, params: { user } }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  deactivateUser(user: UserId) {
    return this.getAuth().pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/staff/deactivate-user',
          { user },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  deactivateAccount(account: AccountId) {
    return this.getAuth().pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/staff/deactivate-account',
          { account },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  deleteAccount(account: AccountId) {
    return this.getAuth().pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/staff/delete-account',
          { account },
          { headers }
        )
      })
    )
  }

  deleteUser(user: UserId) {
    return this.getAuth().pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/staff/delete-user',
          { user },
          { headers }
        )
      })
    )
  }

}
