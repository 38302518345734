const { invert } = require('lodash');

// these actions cannot be undone
// it's possible that there could be other behavior associated with them
exports.reservedActions = (a => ({ ...a, ...invert(a) }))({
  createDraft: '@CREATE_DRAFT',
  createRootTask: '@CREATE_ROOT_TASK',
  createRootGroup: '@CREATE_ROOT_GROUP',
  createDefaultView: '@CREATE_DEFAULT_VIEW',
  resolveMergeConflict: '@RESOLVE_MERGE_CONFLICT',
  createDefaultMilestone: '@CREATE_DEFAULT_MILESTONE'
});

exports.defaultViews = [
  {
    action: exports.reservedActions.createDefaultView,
    name: 'Custom View',
    projectOrder: [],
    type: 'default'
  },
  {
    action: exports.reservedActions.createDefaultView,
    name: 'Detailed View',
    projectOrder: [],
    type: 'default'
  },
  {
    action: exports.reservedActions.createDefaultView,
    name: 'Summary View',
    projectOrder: [],
    type: 'default'
  }
];

exports.defaultViewName = exports.defaultViews[0].name;
