import * as PowerBi from '../../../powerbi-data-structs';

import UserTncAcceptance = PowerBi.UserTncAcceptance;

export const key = 'powerbi.userTncAcceptance';

export interface State {
  userTncAcceptance: UserTncAcceptance,
  userTncAcceptances: UserTncAcceptance[]
}

export const initialState: State = {
  userTncAcceptance: null,
  userTncAcceptances: []
};

export function beforeSave(state) {
  return {
    ...state
  };
}

export function beforeLoad(state) {
  return state;
}
