import * as PowerBi from '../../../powerbi-data-structs';

import Pricing = PowerBi.Pricing;

export const key = 'powerbi.pricing';

export interface State {
  pricing: Pricing,
  pricings: Pricing[]
}

export const initialState: State = {
  pricing: null,
  pricings: []
};

export function beforeSave(state) {
  return {
    ...state
  };
}

export function beforeLoad(state) {
  return state;
}
