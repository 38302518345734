import { Pricing } from 'tp-traqplan-core/dist/data-structs';

export const key = 'pricing';

export interface State {
  pricing: Pricing,
  pricings: Pricing[]
}

export const initialState: State = {
  pricing: null,
  pricings: []
};

export function beforeSave(state) {
  return {
    ...state
  };
}

export function beforeLoad(state) {
  return state;
}
