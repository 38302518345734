<mat-chip-list
  #chipList
  class="group-select"
  cdkDropList
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="drop($event)"
>
  <!--(removed)="remove(fruit)"-->
  <mat-chip
    *ngFor="let grouping of selected; let i = index"
    [removable]="true"
    (removed)="remove(grouping)"
    cdkDrag
  >
    {{grouping.label}}
    <mat-icon  matChipRemove>cancel</mat-icon>
  </mat-chip>
</mat-chip-list>

<button mat-icon-button [matMenuTriggerFor]="menu" >
  <mat-icon fontSet="fas">chevron-down</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    *ngFor="let grouping of available"
    (click)="select(grouping)"
  >
    <span>{{grouping.label}}</span>
  </button>
</mat-menu>
