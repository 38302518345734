import { Component, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil, map, distinctUntilChanged, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from 'src/app/state';
import { TreeGroupable } from 'src/app/state/projects/state';
import * as projectsActions from 'src/app/state/projects/actions';

@Component({
  selector: 'app-group-select',
  templateUrl: './group-select.component.html',
  styleUrls: ['./group-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupSelectComponent implements OnDestroy {

  private destroy$ = new Subject<void>();
  groupCtrl = new FormControl();
  selected: TreeGroupable[] = [];
  available: TreeGroupable[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private store: Store<State>
  ) {

    const selected$ = this.store.pipe(
      takeUntil(this.destroy$),
      map(state => state.projects.tree.groups),
      distinctUntilChanged(),
      tap(groups => {
        this.selected = groups;
      })
    );

    selected$.subscribe();

    combineLatest(
      selected$,
      this.store.pipe(
        takeUntil(this.destroy$),
        map(state => state.projects.tree.groupsAvailable),
        distinctUntilChanged()
      )
    ).pipe(
      takeUntil(this.destroy$),
      map(([selected, available]) => available.filter(a => !selected.some(s => s.label === a.label))),
      tap(available => {
        this.available = available;
      })
    ).subscribe();

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  remove(group): void {
    this.store.dispatch(
      projectsActions.setProjectTreeGroups({
        groups: this.selected.filter(s => s.label !== group.label)
      })
    );
  }

  select(group): void {
    this.store.dispatch(
      projectsActions.setProjectTreeGroups({
        groups: [...this.selected, group]
      })
    );
  }

  drop(event) {
    const selected = this.selected.slice(0);
    selected.splice(event.currentIndex, 0, selected.splice(event.previousIndex, 1)[0]);

    this.store.dispatch(
      projectsActions.setProjectTreeGroups({
        groups: selected
      })
    )
  }

}
