import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { namingConventionValidator } from 'src/app/shared/validators/naming-convention.validator';
import { AccountApiService } from 'src/app/api/account-api.service';
import { State } from 'src/app/state';
import * as accountActions from 'src/app/state/account/actions';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import * as modalActions from 'src/app/state/modal/actions';

@Component({
  selector: 'app-create-account-modal',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent {

  actions: ModalWindowAction[] = [
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true,
      escKey: true
    },
    {
      label: 'Create Account',
      type: 'positive',
      confirm: true,
      valid: () => this.form.valid,
      enterKey: true
    }
  ];

  visible = true;

  public form: FormGroup = this.fb.group({
    account: this.fb.control('', [
      Validators.required,
      namingConventionValidator()
    ], [
      (control: AbstractControl) => {

        if (!control.value?.length) {
          return of(null);
        }
        this.loading = true;

        return this.accountApi.checkAvailable(control.value).pipe(
          map(({ available }) => {
            this.loading = false;
            return available ? null : { notAvailable: true };
          })
        );
      }
    ])
  });

  public loading: boolean = false;

  get account() {
    return this.form.get('account');
  }

  constructor(
    private fb: FormBuilder, private accountApi: AccountApiService, private store: Store<State>
  ) { }

  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }

  onActionSelected(action): void {

    if (action.confirm && this.form.valid && !this.loading) {

      const name = this.account.value.trim();

      this.accountApi.create(name).pipe(
        tap(() => {
          this.store.dispatch(accountActions.requestAvailable());
        })
      ).subscribe();

    }
  }

}
