import { createAction, props } from '@ngrx/store';
import { StateModal } from '../structs';
import { State } from './state';

export const reset = createAction(
  '[modal] reset',
  props<{ state?: Partial<State> }>()
);

export const generateModal = createAction(
  '[modal] generateModal',
  props<{ modal: StateModal; inputs?: { [key: string]: any }; params?: { [key: string]: any } }>()
);

export const closeModal = createAction(
  '[modal] closeModal'
);
