import { timeDay, scaleTime } from 'd3';
import dayjs from 'dayjs';
import { Subject } from 'rxjs';

import { GroupTreeNode, PoapTask, TimelineGroup, DrawingLayout, SYM_GROUP_ROOT_ORDER } from '../data-structs';
import * as groupUtils from '../task-group-utils';
import { TimelineAxis } from './tl-axis';
import { TimelineGroupHeaders } from './tl-group-headers';
import { dateMax, dateMin, getGroupFittingDepth, prependMilestoneDate, safeMax, safeMin } from './tl-common';
import { TimelineLegend } from './tl-legend';
import { FittingMeta, GroupFitting, LayoutOptions, TextLayout, TimelineTask, TimelineRow } from './tl-structs';
import { TextWrapping } from './tl-wrapping';

const SYM_GROUPUID = Symbol();
const SYM_TASKID = Symbol();

interface CroppingMap {
  incGroups: Set<string>;
  exlGroups: Set<string>;
  incTasks: Set<string>;
  exlTasks: Set<string>;
}

export class TimelineLayout {

  public textWrapping = new TextWrapping();

  public layout$ = new Subject<{ fittingMap: GroupFitting[], fittingMeta: FittingMeta, settings: LayoutOptions, unfitted: any }>();

  public fittingMap: GroupFitting[];
  public fittingMeta: FittingMeta;
  public timeScale: any;
  public settings: LayoutOptions;
  public groupMap: any;
  public unfitted: any; // group map of remaining unfitted groups. In normal operation this will be empty.

  public axis = new TimelineAxis();
  public groupHeaders = new TimelineGroupHeaders();
  public legend = new TimelineLegend();

  /**
   * @returns the number of rows traversed by this value, unrounded
   */
  static getRowTraversal(height: number, settings: LayoutOptions): number {
    return height / (this.getRowHeight(settings) + settings.taskPadding);
  }

  /*static getMinimumGroupsWidth(settings: LayoutOptions): number {
    return 
    const { minimumGroupsWidth } = getMinimumGroupsWidth(settings);
    return minimumGroupsWidth;
  }*/

  /**
   * @returns the exact row count for a given space, could be decimal (i.e 1.7 rows!)
   */
  static getExactRowCount(height: number, settings: LayoutOptions, includeAxis: boolean = false, includeLegend: boolean = false): number {

    const validateSettings = { ...settings };

    const textWrapping = new TextWrapping();
    textWrapping.setFontSize(settings.fontSize);

    const nominalDepth = getNominalGroupDepth(settings.groupMap);

    const minimumGroupHeaderWidth = getMinimumGroupHeaderWidth(settings, nominalDepth, textWrapping);
    const minimumGroupWidth = getMinimumGroupWidth(settings, nominalDepth, textWrapping);

    validateSettings.groupsWidth = Math.min(0.5 * settings.viewportWidth, Math.max(minimumGroupWidth, settings.groupsWidth, minimumGroupHeaderWidth));

    const scale = timeScaleFactory(validateSettings);

    let axisHeight = 0;
    let legendHeight = 0;

    if (includeAxis) {
      const axis = new TimelineAxis();
      axis.update({ scale: scale, fontSize: settings.fontSize });
      axisHeight = axis.totalHeight;
    }

    if (includeLegend) {
      const legend = new TimelineLegend();
      legend.update({
        fontSize: settings.legendFontSize,
        rowPadding: settings.legendRowPadding,
        columnPadding: settings.legendColumnPadding,
        textPadding: settings.textPadding,
        width: scale.range()[1],
        columnMinWidth: settings.legendColumnMinWidth,
        cellMaxLines: 3,
        categories: settings.milestoneCategories,
        fittingMode: settings.legendFittingMode,
        position: settings.legendPosition
      });
      legendHeight = legend.totalHeight;
    }

    return (height - settings.taskPadding - axisHeight - legendHeight) / (this.getRowHeight(settings) + settings.taskPadding);
  }

  /**
   * @returns the maximum number of rows which will fit in a given height.
   */
  static getMaximumRowCount(height: number, settings: LayoutOptions, includeAxis: boolean = false, includeLegend: boolean = false): number {
    return Math.floor(TimelineLayout.getExactRowCount(height, settings, includeAxis, includeLegend));
  }

  /**
   * @returns the height (in pixels) of a single timeline row based on the current settings:
   */
  static getRowHeight(settings: LayoutOptions): number {
    return settings.fontSize + settings.textPadding * 2;
  }

  /**
   * @returns the height of a specified number of rows, including top and bottom padding.
   */
  static getNominalHeight(rowCount: number, settings: LayoutOptions): number {
    return rowCount === 0 ? 0 : this.getRowHeight(settings) * rowCount + settings.taskPadding * (rowCount + 1);
  }

  getGroup(id: string): GroupFitting {
    return this.fittingMap[SYM_GROUPUID][id] as GroupFitting;
  }

  getTimelineGroup(id: string): TimelineGroup {
    return this.groupMap[id];
  }

  getTimelineGroups(ids: string[]): TimelineGroup[] {
    return ids.map(id => this.getTimelineGroup(id));
  }

  getRootGroup(id): TimelineGroup {
    let current = this.getTimelineGroup(id);
    while (current?.parent) {
      current = this.getTimelineGroup(current.parent);
    }
    return current;
  }

  getRootGroups(): TimelineGroup[] {
    return this.groupMap[SYM_GROUP_ROOT_ORDER].map(id => this.groupMap[id]).filter(g => g);
  }

  getTask(id: string): TimelineTask {
    return this.fittingMap[SYM_TASKID][id] as TimelineTask;
  }

  getGroups(): GroupFitting[] {
    return Object.values(this.fittingMap[SYM_GROUPUID]) as GroupFitting[];
  }

  getTasks(): TimelineTask[] {
    return Object.values(this.fittingMap[SYM_TASKID]) as TimelineTask[];
  }

  * taskIterator() {

    const idList = Object.keys(this.fittingMap[SYM_TASKID]);

    for (const id of idList) {
      const timelineTask = this.fittingMap[SYM_TASKID][id];
      for (const poapTask of timelineTask.ProjectTasks) {
        yield poapTask;
      }
    }

  }

  /**
   * use this to independently evaluate the positioning of a task without updating the fitting map:
   */
  evaluateTaskPosition(fitting: TimelineTask): TimelineTask {

    const tasks = [{ ...fitting }];
    tasks[0].Milestone = (timeDay.round(fitting.StartDate).getTime() === timeDay.round(fitting.FinishDate).getTime());

    this.evaluatePositions(tasks);

    return tasks[0];
  }

  /**
   * offsets a date by a specified number of pixels, according to the current scale
   */
  offsetDate(date: Date, offset: number): Date {
    return this.timeScale.invert(this.timeScale(date) + offset);
  }
  
  layout(settings: LayoutOptions, skipCountCheck?: boolean): { fittingMap: GroupFitting[], fittingMeta: FittingMeta, settings: LayoutOptions, unfitted: any } {

    this.settings = {
      defaultDrawingLayout: DrawingLayout.MILESTONES_AND_BARS,
      textLayout: TextLayout.AUTOFIT_TEXT_INSIDE_BARS,
      ...settings
    };

    this.textWrapping.setFontSize(this.settings.fontSize);

    this.resetFittingData();

    if (this.settings.displayEmptyGroups) {
      this.groupMap = this.settings.groupMap;
    } else {
      this.groupMap = groupUtils.filterGroupMap(this.settings.groupMap, group => {
        return group.isProjectRoot || !groupUtils.isGroupEmpty(this.settings.groupMap, group);
      });
    }

    const groupRoots = groupUtils.getRootGroups(this.groupMap);

    this.fittingMeta.nominalGroupDepth = getNominalGroupDepth(this.settings.groupMap);

    const minimumGroupHeaderWidth = getMinimumGroupHeaderWidth(this.settings, this.fittingMeta.nominalGroupDepth, this.textWrapping);
    const minimumGroupWidth = getMinimumGroupWidth(this.settings, this.fittingMeta.nominalGroupDepth, this.textWrapping);

    this.fittingMeta.minimumGroupWidth = Math.min(minimumGroupWidth, minimumGroupHeaderWidth);
    this.fittingMeta.maximumGroupWidth = 0.5 * this.settings.viewportWidth;
    //const minimumGroupWidthInfo = getMinimumGroupsWidth(this.settings);
    this.settings.groupsWidth = Math.min(0.5 * this.settings.viewportWidth, Math.max(settings.groupsWidth, minimumGroupWidth, minimumGroupHeaderWidth));

    this.timeScale = timeScaleFactory(this.settings);

    this.updateGroupHeaders();
    this.updateAxis();
    this.updateLegend();

    
    this.fittingMeta.rowHeight = this.settings.fontSize + this.settings.textPadding * 2;
    this.fittingMeta.axisHeight = Math.max(this.groupHeaders.totalHeight, this.axis.totalHeight);

    //calculate group widths, text wrapping and group minimum heights:
    this.buildGroupsRecursive(this.groupMap, this.fittingMap, groupRoots, null);

    this.positionViewLines();

    if (this.fittingMap.length) {
      this.positionGroupsRecursive(this.fittingMap);
      //group widths determined - wrap text for each group and determine total height (as row count) for each group:
      this.getGroupHeightsRecursive(this.fittingMap);
    }

    this.fittingMeta.totalRows = this.getTotalRowCount();
    this.fittingMeta.totalHeight = TimelineLayout.getNominalHeight(this.fittingMeta.totalRows, this.settings);

    this.positionPrintLines();

    if (!skipCountCheck && this.settings.maximumRowCount && this.fittingMeta.totalRows > this.settings.maximumRowCount) {
      const [include, exclude] = this.cropFitting();
      const layout = this.layout({
        ...this.settings,
        groupMap: include
      }, true);
      this.unfitted = exclude;
      return layout;
    }
    const response = {
      fittingMap: this.fittingMap,
      fittingMeta: this.fittingMeta,
      settings: this.settings,
      unfitted: this.unfitted
    };

    this.layout$.next(response);

    return response;

  }

  private resetFittingData(): void {

    this.fittingMap = fitMapFactory();
    this.fittingMeta = fitMetaFactory({
      milestoneMap: new Map(
        this.settings.milestoneCategories.map(i => ([i.id, i]))
      )
    });
    this.unfitted = [];
  }

  updateGroupHeaders(): void {
    this.groupHeaders.update({
      columnCount: this.fittingMeta.nominalGroupDepth,
      labels: this.settings.groupColumnHeaders || [],
      fontSize: this.settings.fontSize,
      width: this.settings.groupsWidth
    });
  }

  private updateAxis(): void {
    this.axis.update({
      scale: this.timeScale,
      fontSize: this.settings.fontSize
    });
  }

  private updateLegend(): void {
    if (this.settings.displayLegend) {
      this.legend.update({
        fontSize: this.settings.legendFontSize,
        rowPadding: this.settings.legendRowPadding,
        columnPadding: this.settings.legendColumnPadding,
        textPadding: this.settings.textPadding,
        width: this.timeScale.range()[1],
        columnMinWidth: this.settings.legendColumnMinWidth,
        cellMaxLines: 3,
        categories: this.settings.milestoneCategories,
        fittingMode: this.settings.legendFittingMode,
        position: this.settings.legendPosition
      });
    }
  }

  private cropFitting(): any[] {

    const croppingMap: CroppingMap = {
      incGroups: new Set(),
      exlGroups: new Set(),
      incTasks: new Set(),
      exlTasks: new Set()
    };

    this.cropFittingsRecursive(this.fittingMap, croppingMap);
    // get array of included timeline groups and remove excluded children & tasks:
    const incGroups = Array.from(croppingMap.incGroups.values())
      .map(uid => {
        const group = { ...groupUtils.getGroup(this.groupMap, uid) };
        group.tasks = group.tasks.filter(task => croppingMap.incTasks.has(task.id));
        group.children = group.children.filter(child => croppingMap.incGroups.has(child));
        return group;
      });
    // do same for exluded groups/tasks:
    const exlGroups = Array.from(croppingMap.exlGroups.values())
      .map(uid => {
        const group = { ...groupUtils.getGroup(this.groupMap, uid) };
        group.tasks = group?.tasks?.filter(task => croppingMap.exlTasks.has(task.id));
        group.children = group?.children?.filter(child => croppingMap.exlGroups.has(child));
        return group;
      });

    const rootOrder = groupUtils.getRootOrder(this.settings.groupMap);

    return [
      groupUtils.groupMapFactory(incGroups, rootOrder),
      groupUtils.groupMapFactory(exlGroups, rootOrder)
    ];

  }

  private cropFittingsRecursive(fittings: GroupFitting[], cropMap: CroppingMap, rowCountAcc: number = 0): void {

    const maxRows = this.settings.maximumRowCount;

    for (const f of fittings) {
      // if starting position of group exceeds max rows then exclude all sub groups and tasks:
      // OR if group label is too big for the page then exclude this group (and all it's rows / children):
      if (rowCountAcc > maxRows) {// || f.textWrapped.length + rowCountAcc > maxRows) {
        [f.groupUid, ...this.getSubGroupUIDs(f)].forEach(uid => cropMap.exlGroups.add(uid));
        [...f.tasks.map(t => t.id), ...this.getSubTaskUIDs(f)].forEach(uid => cropMap.exlTasks.add(uid));
        rowCountAcc += f.groupHeight;
        continue;
      }
      // if entire group fits within space then include everything:
      if (rowCountAcc + f.groupHeight <= maxRows) {
        [f.groupUid, ...this.getSubGroupUIDs(f)].forEach(g => cropMap.incGroups.add(g));
        [...f.tasks.map(t => t.id), ...this.getSubTaskUIDs(f)].forEach(uid => cropMap.incTasks.add(uid));
        rowCountAcc += f.groupHeight;
        continue;
      }
      // label text of this group and all it's parents will fit, check if any children fit:
      if (f.children.length) {
        this.cropFittingsRecursive(f.children, cropMap, rowCountAcc);

        // if any child groups where included in the layout then include group in layout:
        for (const child of f.children) {
          if (cropMap.incGroups.has(child.groupUid)) {
            cropMap.incGroups.add(f.groupUid);
          }
          // if any children were included then include this group in the exclusions list as well:
          if (cropMap.exlGroups.has(child.groupUid)) {
            cropMap.exlGroups.add(f.groupUid);
          }
        }

      }
      
      //iterate direct tasks and include / exclude:
      let positionAcc = rowCountAcc + f.childGroupHeight;
      for (const taskRow of f.taskRows) {
        positionAcc += taskRow.rowHeight;
        if (positionAcc <= maxRows) {
          // group can fit at least one task, so include it:
          cropMap.incGroups.add(f.groupUid);
          // include tasks in row:
          taskRow.tasks.map(t => t.ProjectTasks).flat(1).forEach(t => cropMap.incTasks.add(t.id));
        } else {
          //group should be duplicated in exluded:
          cropMap.exlGroups.add(f.groupUid);
          // doesn't fit, exclude all tasks in row:
          taskRow.tasks.map(t => t.ProjectTasks).flat(1).forEach(t => cropMap.exlTasks.add(t.id));
        }
      }
      rowCountAcc += f.groupHeight;
    }
  }
  // used to adjust maxRowCount argument to account for the height of the legend
  // actual legend rows differ in height to timeline
  private getLegendRowCount(): number {
    return !this.settings.displayLegend ? 0
      : Math.ceil((this.legend.totalHeight - this.settings.taskPadding) / (TimelineLayout.getRowHeight(this.settings) + this.settings.taskPadding));
  }

  private getSubGroupUIDs(fitting: GroupFitting): string[] {

    const recurse = (children, fittings) => {
      for (const child of children) {
        fittings.push(child.groupUid);
        child.children.length && recurse(child.children, fittings);
      }
      return fittings;
    };

    return recurse(fitting.children, []);

  }

  private getSubTaskUIDs(fitting: GroupFitting): string[] {

    const recurse = (children, fittings) => {
      for (const child of children) {
        fittings.push(...child.tasks.map(t => t.id));
        child.children.length && recurse(child.children, fittings);
      }
      return fittings;
    };

    return recurse(fitting.children, []);

  }

  private positionPrintLines() {
    this.fittingMeta.printStart = this.timeScale(this.settings.printStart);
    this.fittingMeta.printFinish = this.timeScale(this.settings.printFinish);
  }

  private positionViewLines(): void {
    this.settings.viewLines?.forEach(viewLine => {
      if (viewLine.visible && this.withinExtents(viewLine.date)) {
        this.fittingMeta.viewLines.set(viewLine.id, {
          ...viewLine,
          position: this.timeScale(viewLine.date)
        });
      }
    });

  }

  private buildGroupsRecursive(groupMap, siblings: GroupFitting[], groups: TimelineGroup[], parent: GroupFitting | null): void {

    for (const group of groups) {
      if (!group || !group.id) continue;

      const fitting = <GroupFitting>{
        groupUid: group.id,
        parent: parent || null,
        children: [],
        groupHeight: 1, //default value
        text: group.name,
        tasks: group.tasks,
        // drawing layout is inherited if not set explicitly:
        drawingLayout: typeof group.drawingLayout === 'number' ? group.drawingLayout
                      : typeof parent?.drawingLayout === 'number' ? parent.drawingLayout
                      : this.settings.defaultDrawingLayout
      };

      siblings.push(fitting);

      this.fittingMap[SYM_GROUPUID][group.id] = fitting;

      // if children then recurse:
      if (group.children.length) {
        this.buildGroupsRecursive(groupMap, fitting.children, groupUtils.getGroups(groupMap, group.children), fitting);
      } else {
        this.updateGroupNominalDepth(fitting);
      }

      if (group.tasks) {

        this.fittingMeta.poapTasks.push(...group.tasks);
        // merge tasks according to current merge logic:
        const merged: TimelineTask[] = this.mergeTasks(group.tasks);
        // remove tasks which are not within plot dates (must occur after merge!):
        const filtered: TimelineTask[] = this.filterTasks(merged, fitting.drawingLayout);
        // evaluate text wrapping, text position (whether inside bar) and width of milestone text:
        const wrapped: TimelineTask[] = this.evaluatePositions(filtered);

        const sorted = this.sortTasks(wrapped);

        fitting.taskRows = this.updateFittingTaskRows(sorted, fitting.drawingLayout);

        for (const task of wrapped) {
          this.fittingMap[SYM_TASKID][task.ID] = task;
        }
      }

      if (this.settings.evaluateNarrative) {
        fitting.narrativeWrapped = this.wrapNarrative(group.narrative);
        fitting.narrativeHeight = fitting.narrativeWrapped.length;
      } else {
        fitting.narrativeHeight = 0;
      }

    }

  }

  private updateGroupNominalDepth(fitting: GroupFitting): void {
    // count the number of parent groups, then apply this value all he way back up the group tree:
    const depth = getGroupFittingDepth(fitting);
    let current = fitting;
    while (current) {
      current.nominalDepth = safeMax(depth, current.nominalDepth || 0);
      current = current.parent;
    }
  }

  private getTotalRowCount(): number {
    let rowCount = 0;
    for (const rootGroup of this.fittingMap) {
      rowCount += rootGroup.groupHeight;
    }
    return rowCount;
  }

  private getGroupHeightsRecursive(fittingMap: GroupFitting[]): GroupFitting[] {

    for (const groupFitting of fittingMap) {

      let sumChildHeight = 0;
      let sumTaskHeight = 0;

      if (groupFitting.children.length) {
        this.getGroupHeightsRecursive(groupFitting.children);
        sumChildHeight = groupFitting.children.reduce((acc, cur) => acc + cur.groupHeight, 0);
      }
      if (groupFitting.taskRows.length) {
        sumTaskHeight = groupFitting.taskRows.reduce((acc, cur) => acc + cur.rowHeight, 0);
      }
      groupFitting.childGroupHeight = sumChildHeight;
      groupFitting.groupHeight = safeMax(sumTaskHeight + sumChildHeight, groupFitting.textWrapped.length);
    }
    return fittingMap;

  }

  private mergeTasks(tasks: PoapTask[]): TimelineTask[] {

    const dupe: any = {};
    const timelineTasks: TimelineTask[] = [];

    for (const task of tasks) {
      if (task.rename) {
        // if duplicate rename values then merge:
        if (dupe[task.rename]) {
          mergeTasks(dupe[task.rename], task);
          continue;
        }
        dupe[task.rename] = this.timelineTaskFactory(task);
        timelineTasks.push(dupe[task.rename]);
        continue;
      }
      timelineTasks.push(this.timelineTaskFactory(task));
    }
    return timelineTasks;

  }

  private filterTasks(tasks: TimelineTask[], drawingLayout: DrawingLayout): TimelineTask[] {

    const { plotStart, plotFinish } = this.settings;

    return tasks.filter(({ StartDate, FinishDate, BaselineFinishDate, BaselineStartDate, Milestone }) => {
      if (drawingLayout === DrawingLayout.MILESTONES_ONLY && !Milestone) {
        return false;
      }
      return !(
        (FinishDate <= plotStart && StartDate <= plotStart) ||
        (FinishDate >= plotFinish && StartDate >= plotFinish)
      ) && (!BaselineStartDate || !(
        (BaselineFinishDate <= plotStart && BaselineStartDate <= plotStart) ||
        (BaselineFinishDate >= plotFinish && BaselineStartDate >= plotFinish)
      ));
    });

  }

  private evaluatePositions(tasks: TimelineTask[]): TimelineTask[] {
    const tolerance = 0.995;

    for (const task of tasks) {

      if (task.Milestone) {

        const text = this.settings.prependMilestoneDates ? prependMilestoneDate(task.StartDate, task.Text) : task.Text;

        task.BarStart = this.timeScale(task.StartDate) - this.settings.milestoneSymbolSize / 2;
        task.BarFinish = task.BarStart + this.settings.milestoneSymbolSize;
        task.wrapTextOutside = true;
        task.TextStart = task.BarFinish + this.settings.textPadding;

        // ensure text is within maximum space AND within viewport boundary:
        const maxTextFinish = (Math.min(task.TextStart + this.settings.textMaxWidth, this.timeScale(this.settings.wrapFinish)) - this.settings.textPadding) * tolerance;

        task.TextWrapped = this.textWrapping.wrapText(text, maxTextFinish - task.TextStart, this.settings.taskMaxLines);

        const textWidth = Math.max(...task.TextWrapped.map(line => this.textWrapping.measureText(line)));

        task.TextFinish = task.TextStart + (textWidth * 1.01);
        task.BarWidth = task.TextFinish - task.BarStart + this.settings.textPadding * 2;
        task.NominalWidth = task.BarWidth;

      } else {

        const wrapLeftMargin = this.timeScale(dateMax(task.StartDate, this.settings.wrapStart));
        const wrapRightMargin = this.timeScale(dateMin(task.FinishDate, this.settings.wrapFinish));

        const barTextStart = wrapLeftMargin + this.settings.textPadding;
        const barTextFinish = wrapRightMargin - this.settings.textPadding;

        task.BarStart = wrapLeftMargin;
        task.BarFinish = wrapRightMargin;
        task.BarWidth = task.BarFinish - task.BarStart;
        task.wrapTextOutside = this.shouldWrapOutside(task, barTextFinish - barTextStart);

        if (task.wrapTextOutside) {
          task.TextStart = task.BarFinish + this.settings.textPadding;

          const maxTextFinish = (Math.min(task.TextStart + this.settings.textMaxWidth, this.timeScale(this.settings.wrapFinish)) - this.settings.textPadding) * tolerance;

          task.TextWrapped = this.textWrapping.wrapText(task.Text, maxTextFinish - task.TextStart, this.settings.taskMaxLines);

          const textWidth = Math.max(...task.TextWrapped.map(line => this.textWrapping.measureText(line)));

          task.TextFinish = task.TextStart + (textWidth * 1.01);
          task.NominalWidth = task.TextFinish - task.BarStart;
        } else {
          task.TextStart = barTextStart;
          task.TextFinish = barTextFinish;
          task.TextWrapped = this.textWrapping.wrapText(task.Text, task.TextFinish - task.TextStart, this.settings.taskMaxLines);
          task.NominalWidth = task.BarWidth;
        }
      }

      this.evaluateBaseline(task);

    }

    return tasks;

  }

  private evaluateBaseline(task: TimelineTask): void {

    if (this.settings.displayBaseline && task.BaselineFinishDate) {

      const baselineDiff = Math.abs(task.BaselineFinishDate.getTime() - task.FinishDate.getTime());
      if (baselineDiff > 0 && baselineDiff >= (this.settings.baselineThreshold * 86400000)) {
        task.BaselineStart = this.timeScale(task.FinishDate);
        task.BaselineFinish = this.timeScale(task.BaselineFinishDate);
      }
    }

  }

  private sortTasks(tasks: TimelineTask[]): TimelineTask[] {
    return tasks.sort((a, b) => {
      let diff = a.StartDate?.getTime() - b.StartDate?.getTime();
      if (diff === 0) {
        diff = a.FinishDate?.getTime() - b.FinishDate?.getTime();
        if (diff === 0) {
          return `${a.Text}`.localeCompare(`${b.Text}`);
        }
        return diff;
      }
      return diff;
    });
  }

  private updateFittingTaskRows(tasks: TimelineTask[], drawingLayout: DrawingLayout): TimelineRow[] {

    if (drawingLayout === DrawingLayout.MILESTONES_ABOVE_BARS 
      || drawingLayout === DrawingLayout.BARS_ABOVE_MILESTONES
      || drawingLayout === DrawingLayout.MILESTONES_ONLY) {

      const milestoneRows: TimelineRow[] = [];
      const taskRows: TimelineRow[] = [];

      for (const task of tasks.filter(t => t.Milestone)) {
        this.placeTask(milestoneRows, task, drawingLayout);
      }
      // this will be empty if MILESTONES_ONLY:
      for (const task of tasks.filter(t => !t.Milestone)) {
        this.placeTask(taskRows, task, drawingLayout);
      }

      return drawingLayout === DrawingLayout.MILESTONES_ABOVE_BARS ? [...milestoneRows, ...taskRows] : [...taskRows, ...milestoneRows];

    } else {
      const rows: TimelineRow[] = [];

      for (const task of tasks) {
        this.placeTask(rows, task, drawingLayout);
      }
      return rows;
    }
  }

  private checkFit(row: TimelineRow, task: TimelineTask): boolean {
    const taskStart = safeMin(task.BarStart, task.TextStart, task.BaselineStart, task.BaselineFinish);
    const taskFinish = safeMax(task.BarFinish, task.TextFinish, task.BaselineFinish, task.BaselineStart);

    for (const placed of row.tasks) {
      const placedStart = safeMin(placed.BarStart, placed.TextStart, placed.BaselineStart, placed.BaselineFinish);
      const placedFinish = safeMax(placed.BarFinish, placed.TextFinish, placed.BaselineFinish, placed.BaselineStart);

      if (!(taskStart > placedFinish || taskFinish < placedStart)) {
        return false;
      }
    }
    // task did not intersect any placed tasks:
    return true;
  }

  private placeTask(rows: TimelineRow[], task: TimelineTask, drawingLayout: DrawingLayout): TimelineRow[] {

    const nominalHeight = (task.TextWrapped.length * this.fittingMeta.rowHeight) + ((task.TextWrapped.length - 1) * this.settings.taskPadding);

    // with Gant, every task starts on a new row:
    if (drawingLayout === DrawingLayout.GANTT) {
      const row = <TimelineRow>{
        nominalHeight: nominalHeight,
        rowHeight: task.TextWrapped.length,
        tasks: [task]
      };
      task.Row = row;
      rows.push(row);
    // with Waterfall, task may be placed in the current (last) row or below:
    } else if (drawingLayout === DrawingLayout.WATERFALL) {
      const row = rows[rows.length - 1];
      if (row && this.checkFit(row, task)) {
        row.tasks.push(task);
        row.rowHeight = safeMax(task.TextWrapped.length, row.rowHeight);
        row.nominalHeight = safeMax(nominalHeight, row.nominalHeight);
        task.Row = row;
      } else {
        const row = <TimelineRow>{
          nominalHeight: nominalHeight,
          rowHeight: task.TextWrapped.length,
          tasks: [task]
        };
        task.Row = row;
        rows.push(row);
      }
    // for all other layout themes, the task is placed in the top-most row it fits in:
    } else {
      for (const row of rows) {
        if (this.checkFit(row, task)) {
          row.tasks.push(task);
          row.rowHeight = safeMax(task.TextWrapped.length, row.rowHeight);
          row.nominalHeight = safeMax(nominalHeight, row.nominalHeight);
          task.Row = row;
          return rows;
        }
      }
      const row = <TimelineRow>{
        nominalHeight: nominalHeight,
        rowHeight: task.TextWrapped.length,
        tasks: [task]
      };
      task.Row = row;
      rows.push(row);
    }
    return rows;
  }

  private positionGroupsRecursive(fittings: GroupFitting[]): void {
    const maxDepth = this.fittingMeta.nominalGroupDepth;
    // all fittings array are siblings so only calculate once:
    const parentWidth = this.getGroupParentOffset(fittings[0]);
    const parentCount = this.getGroupParentCount(fittings[0]);

    for (const fitting of fittings) {
      fitting.groupStart = parentWidth + this.settings.taskPadding;
      const remainingWidth = this.settings.groupsWidth - parentWidth;
      // count max depth of children for current group:
      const depth = maxDepth - parentCount;

      if (depth > 1 && fitting.children.length) {
        fitting.groupWidth = (remainingWidth - this.settings.taskPadding * (depth + 1)) / depth;
      } else {
        fitting.groupWidth = Math.max(remainingWidth - this.settings.taskPadding, 0);
      }

      if (fitting.children.length) {
        this.positionGroupsRecursive(fitting.children);
      }

      const textWidth = fitting.groupWidth - this.settings.groupTextPadding * 2;
      fitting.textWrapped = this.textWrapping.wrapText(fitting.text, textWidth, Infinity);
    }
  }

  private removeFitting(uid: string): void {
    const fitting = this.fittingMap[SYM_GROUPUID][uid];

    if (!fitting.parent) {
      this.fittingMap.splice(this.fittingMap.indexOf(fitting), 1);
    } else {
      const parent = this.fittingMap[SYM_GROUPUID][fitting.parent.groupUid];
      if (parent) {
        parent.children = parent.children.filter(f => f.groupUid !== fitting.groupUid);
      }
    }
    this.fittingMap[SYM_GROUPUID] = Object.entries(this.fittingMap[SYM_GROUPUID])
      .filter(([k]) => k !== uid)
      .reduce((a, [k, v]) => ({ ...a, [k]: v }), {});

  }

  private shouldWrapOutside(task: TimelineTask, widthAvailable: number): boolean {
    if (this.settings.textLayout === TextLayout.TEXT_ALWAYS_OUTSIDE_BARS) {
      return true;
    }

    if (this.settings.textLayout === TextLayout.TEXT_ALWAYS_INSIDE_BARS) {
      return false;
    }

    return !this.textWrapping.textFits(task.Text, widthAvailable, this.settings.taskMaxLines);
  }

  private getRowsTotalHeight(rows: TimelineRow[]): number {
    return rows.reduce((acc, cur) => acc + cur.rowHeight, 0);
  }

  private wrapNarrative(text: string): string[] {
    return this.textWrapping.wrapText(text, this.settings.narrativeColumnWidth, this.settings.groupMaxLines);
  }

  private timelineTaskFactory(task: PoapTask): TimelineTask {
    return <TimelineTask>{
      ID: task.id,
      ProjectTasks: [task],
      Milestone: task.milestone || dayjs(task.start).startOf('day').isSame(dayjs(task.finish).startOf('day')),
      MilestoneType: task.milestoneCategory,
      Colour: task.colour,
      TextColour: task.textColour,
      Text: task.rename || task.name,
      StartDate: task.start,
      FinishDate: task.finish,
      BaselineStartDate: task.baselineStart || null,
      BaselineFinishDate: task.baselineFinish || null
    };
  }

  private getGroupParentCount(fitting: GroupFitting): number {

    let count = 0;
    let parent = fitting.parent;
    while (parent) {
      count++;
      parent = parent.parent;
    }
    return count;

  }

  private getGroupParentOffset(fitting: GroupFitting): number {

    // if parent group present, should already have groupStart & groupWidth to sum & return:
    if (!fitting.parent) return 0;

    return fitting.parent.groupStart + fitting.parent.groupWidth;

  }

  private withinExtents(date: Date): boolean {
    const [start, end] = this.timeScale.domain();
    return date <= end && date >= start;
  }

  private getGroupTreeNode(groupTree, groupId): GroupTreeNode | undefined {
    if (Array.isArray(groupTree)) {
      for (let i = 0; i < groupTree.length; i++) {
        const group = groupTree[i];

        if (group.id === groupId) {
          return group;
        } else {
          return this.getGroupTreeNode(group.children, groupId);
        }
      }
    }
  }
}

function mergeTasks(tlTask: TimelineTask, poTask: PoapTask): void {
  tlTask.ProjectTasks.push(poTask);
  tlTask.Milestone = false;
  // check if nominal start/finish dates need to be updated:
  tlTask.StartDate = dateMin(poTask.start, tlTask.StartDate);
  tlTask.FinishDate = dateMax(poTask.finish, tlTask.FinishDate);
  tlTask.BaselineStartDate = dateMin(poTask.baselineStart, tlTask.BaselineStartDate);
  tlTask.BaselineFinishDate = dateMax(poTask.baselineFinish, tlTask.BaselineFinishDate);

  if (!tlTask.MilestoneType) {
    tlTask.MilestoneType = poTask.milestoneCategory || null;
  }
}

function getNominalGroupDepth(groupMap: { [key: string]: TimelineGroup }): number {

  let depth = 1;
  for (const group of Object.values(groupMap)) {

    // if (!isGroupVisible(groupMap, group)) {
    //   continue;
    // }

    const hasChildren = group.children?.length;//group.children?.some(id => isGroupVisible(groupMap, groupUtils.getGroup(groupMap, id)));

    if (!hasChildren) {
      depth = Math.max(depth, groupUtils.getGroupDepth(groupMap, group));
    }
  }
  return depth;

}

function getMinimumGroupHeaderWidth(settings: LayoutOptions, nominalDepth: number, textWrapping: TextWrapping): number {

  const padding = (settings.groupTextPadding * 2 + settings.taskPadding) * 1.1;

  let minWidth = 0;

  if (settings.groupColumnHeaders) {
    for (const label of settings.groupColumnHeaders) {
      minWidth = Math.max(minWidth, ...(label?.split(/\s+/).map(w => textWrapping.measureText(w) + padding) || []));
    }
  }

  return minWidth * nominalDepth;
  
}

function getMinimumGroupWidth(settings: LayoutOptions, nominalDepth: number, textWrapping: TextWrapping, groups?: TimelineGroup[]): number {

  groups = groups || groupUtils.getRootGroups(settings.groupMap);

  let width = 0;
  // small tolerance added to padding value:
  const padding = (settings.groupTextPadding * 2 + settings.taskPadding) * 1.1;
  // group 'depth' the same for all sibling groups:
  const depth = groupUtils.getGroupDepth(settings.groupMap, groups[0]);

  for (const group of groups) { 

    //if (!isGroupVisible(settings.groupMap, group)) {
    //  continue;
    //}

    //const hasChildren = group.children?.some(id => isGroupVisible(settings.groupMap, groupUtils.getGroup(settings.groupMap, id)));
    const hasChildren = group.children?.length;
    // if no children then group width will stretch across any remaining columns:
    const span = !hasChildren ? nominalDepth - depth + 1 : 1

    const minWidth = ((Math.max(...(group.name?.split(/\s+/).map(w => textWrapping.measureText(w)) || [])) + padding) / span) * nominalDepth;

    width = Math.max(width, minWidth);

    if (hasChildren) {
      width = Math.max(width, getMinimumGroupWidth(settings, nominalDepth, textWrapping, groupUtils.getGroups(settings.groupMap, group.children)));
    }

  }

  return width;

}

function timeScaleFactory(settings: LayoutOptions) {
  return scaleTime()
    .domain([
      settings.viewportStart,
      settings.viewportFinish
    ])
    .range([
      settings.groupsWidth,
      settings.viewportWidth
    ]);
}

function fitMetaFactory(meta: Partial<FittingMeta>): FittingMeta {
  return {
    totalHeight: 0,
    axisHeight: 0,
    rowHeight: 0,
    totalRows: 0,
    printStart: 0,
    printFinish: 0,
    maximumGroupWidth: 0,
    minimumGroupWidth: 0,
    nominalGroupDepth: 0,
    milestoneMap: new Map(),
    viewLines: new Map(),
    poapTasks: [],
    ...meta
  };
}

function fitMapFactory() {
  return Object.assign([], {
    [SYM_GROUPUID]: {},
    [SYM_TASKID]: {}
  });
}
