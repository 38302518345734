import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { wrapDatesRecursive } from 'tp-common/json.js';
import { Observable } from 'rxjs';

@Injectable()
export class DateInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (httpRequest.body instanceof FormData) {
      return next.handle(httpRequest);
    }

    return next.handle(httpRequest.clone({
      body: wrapDatesRecursive(httpRequest.body)
    }));
  }
}
