import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { namingConventionValidator } from '../../shared/validators/naming-convention.validator';
import { State } from '../../state';
import { HierarchyApiService } from '../../api/hierarchy-api.service';
import { defaultWorkspaceOptions } from '../../workspace/constants';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import * as modalActions from '../../state/modal/actions';
import * as accountActions from '../../state/account/actions';

@Component({
  selector: 'app-create-workspace',
  templateUrl: './create-workspace.component.html',
  styleUrls: ['./create-workspace.component.scss']
})
export class CreateWorkspaceComponent {

  actions: ModalWindowAction[] = [
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true,
      escKey: true
    },
    {
      label: 'Create',
      type: 'positive',
      confirm: true,
      valid: () => this.form.valid,
      enterKey: true,
    }
  ];

  visible = true;
  summaryViewId:string='';

  public form: FormGroup = this.fb.group({
    workspace: this.fb.control('', [
      Validators.required,
      namingConventionValidator()
    ], [
      (control: AbstractControl) => {
        this.loading = true;

        if (!control.value?.length) {
          return of(null);
        }

        return this.hierarchyApi.checkAvailable(control.value, 0).pipe(
          map(({ available }) => {
            this.loading = false;
            return available ? null : { notAvailable: true };
          })
        );
      }
    ])
  });

  public loading: boolean = false;

  get workspace() {
    return this.form.get('workspace');
  }

  constructor(
    private fb: FormBuilder, private hierarchyApi: HierarchyApiService, private store: Store<State>
  ) { }

  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }

  onActionSelected(action): void {

    if (action.confirm && this.form.valid && !this.loading) {

      const name = this.workspace?.value.trim();
      this.hierarchyApi.create({ ...defaultWorkspaceOptions, name }).pipe(
        take(1),
        tap(() => {
          this.store.dispatch(accountActions.requestAvailableRoots());
        })
      ).subscribe();

    }
  }

}
