import { Component, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { take, map, tap, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import access from 'tp-common/access';
import { viewConstants } from 'tp-common/revisions';
import { uuid } from 'tp-common/uuid';
import { AgGridCheckboxComponent } from 'src/app/ag-grid-components/ag-grid-checkbox/ag-grid-checkbox.component';
import { AgGridDatepickerEditorComponent } from 'src/app/ag-grid-components/ag-grid-datepicker-editor/ag-grid-datepicker-editor.component';
import { State } from 'src/app/state';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { ViewLinesApiService } from 'src/app/api/view-lines-api.service';
import { ViewLine, User, License, ViewId } from 'tp-traqplan-core/dist/data-structs';
import * as modalActions from 'src/app/state/modal/actions';
import * as poapActions from 'src/app/state/poap/actions';
import { filterDSReady } from 'src/app/state/helpers';
import { standardFormat } from 'tp-traqplan-core/dist/date-format';

@Component({
  selector: 'app-edit-viewlines',
  templateUrl: './edit-viewlines.component.html',
  styleUrls: ['./edit-viewlines.component.scss']
})
export class EditViewlinesComponent implements OnDestroy {

  visible = true;

  actions: ModalWindowAction[] = [
    {
      label: 'Save',
      type: 'positive',
      confirm: true
    },
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true
    }
  ];

  user: User;
  license: License;
  viewId: ViewId;
  userCanEdit = false;

  colours: { value: string; }[] = [...viewConstants.viewLineColours.keys()].map(value => ({ value }));

  symbols: { value: string; }[] = Object.keys(viewConstants.viewLineStyles).map(value => ({ value }));

  modules = AllModules;

  gridOptions = {
    frameworkComponents: {
      checkbox: AgGridCheckboxComponent,
      datePicker: AgGridDatepickerEditorComponent
    }
  };

  columnDefs = [
    {
      width: 45,
      cellRenderer: () => {
        return this.isEditable() ? `<span class="cell_icon fas cell_delete">trash</span>` : '';
      },
      onCellClicked: params => this.onDelete(params)
    },
    {
      headerName: 'Name',
      field: 'title',
      flex: 2,
      editable: () => this.isEditable()
    },
    {
      headerName: 'Position',
      field: 'date',
      flex: 1,
      cellEditor: 'datePicker',
      editable: () => this.isEditable(),
      valueGetter: params => params.data?.date?.getTime() || '',
      valueFormatter: params => params.data?.date ? standardFormat(params.data.date) : '',
    },
    {
      headerName: 'Colour',
      field: 'colour',
      editable: () => this.isEditable(),
      cellRenderer: params => {
        return params.data.colour ? `<span class="cell_colour fas" style="color:${viewConstants.viewLineColours.get(params.data.colour)}">circle</span>${params.data.colour}` : '';
      },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: [...viewConstants.viewLineColours.keys()]
      }
    },
    {
      headerName: 'Style',
      field: 'style',
      editable: () => this.isEditable(),
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: Object.keys(viewConstants.viewLineStyles)
      }
    },
    {
      headerName: 'Visible',
      field: 'visible',
      editable: () => this.isEditable(),
      cellRenderer: 'checkbox'
    }
  ];

  form = new FormGroup({
    title: new FormControl('',
      [
        Validators.required,
        Validators.minLength(1),
      ]/*,
      [
        (control: AbstractControl) => {
          const name = control.value;
          return this.viewLinesApi.checkAvailable(name).pipe(
            map(({ available }) => {
              if (available) {
                return null;
              }
              return { namingConflict: true };
            })
          );
        }
      ]*/
    ),
    date: new FormControl(),
    colour: new FormControl({ value: viewConstants.defaultViewLineColour },
      [
        Validators.required
      ]
    ),
    style: new FormControl({ value: viewConstants.defaultViewLineStyle },
      [
        Validators.required
      ]
    ),
    visible: new FormControl(true)
  });

  private destroy$ = new Subject<void>();

  changes$ = new BehaviorSubject<ViewLine[]>([]);

  viewLines$: Observable<ViewLine[]> = combineLatest([
    this.store.pipe(
      takeUntil(this.destroy$),
      map(state => state.poap.viewLines),
      filterDSReady()
    ),
    this.changes$
  ]).pipe(
    takeUntil(this.destroy$),
    map(([{ value: saved }, changes]) => {
      const changeMap = new Map<string, ViewLine>(changes.map(c => [c.id, c]));
      const merged: ViewLine[] = [];

      for (const s of saved) {
        const change = changeMap.get(s.id);
        if (change) {
          changeMap.delete(s.id);
          if (change.deleted) {
            continue;
          }
          merged.push(change);
        } else {
          merged.push(s);
        }
      }

      for (const change of changeMap.values()) {
        if (!change.deleted) {
          merged.push(change);
        }
      }

      return [
        ...merged.filter(m => !m.view),
        ...merged.filter(m => m.view)
      ];
    }),
    distinctUntilChanged(this.isViewLinesUnchanged)
  );

  constructor(private store: Store<State>, private viewLinesApi: ViewLinesApiService) {
    this.store.dispatch(poapActions.requestViewLines());
    this.store.pipe(
      take(1),
      tap(state => {
        const account = state.account.selected;
        this.user = state.auth.user;
        this.license = state.account.licenses.find((lic) => lic.assignee === this.user.id);
        this.viewId = state.poap.viewSelected.id;
        this.userCanEdit = access.permissionIncludes(account.access, access.actionType.editViews);
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }

  onAdd(): void {
    if (!this.form.valid) {
      return;
    }

    let colourValue = this.form.controls.colour.value;
    let styleValue = this.form.controls.style.value;

    if(typeof this.form.controls.colour.value === 'object'){
      colourValue = this.form.controls.colour.value.value;
    }

    if(typeof this.form.controls.style.value === 'object'){
      styleValue = this.form.controls.style.value.value;
    }

    const changes = this.changes$.getValue();
    changes.push({
      id: uuid(),
      view: this.viewId,
      author: this.user.id,
      version: new Date(),
      action: 'Create ViewLine',
      title: this.form.controls.title.value,
      date: this.form.controls.date.value || new Date(),
      colour: colourValue || viewConstants.defaultViewLineColour,
      style: styleValue || viewConstants.defaultViewLineStyle,
      visible: this.form.controls.visible.value,
      deleted: false
    });

    this.form.reset();
    this.changes$.next(changes);
  }

  onDelete(params): void {
    const changes = this.changes$.getValue();
    const index = changes.findIndex(c => c.id === params.data.id);

    if (~index) {
      changes.splice(index, 1);
    }

    changes.push({
      ...params.data,
      deleted: true
    });
    this.changes$.next(changes);
  }

  onActionSelected(action: ModalWindowAction): void {
    if (!action.confirm) {
      return;
    }

    const changes = this.changes$.getValue();
    this.store.pipe(
      takeUntil(this.destroy$),
      map(state => state.poap.viewLines),
      filterDSReady(),
      take(1),
      tap(({ value: saved }) => {
        // this is a catch for viewlines which have been added but subsequently deleted before clicking save:
        const preserve = changes.filter(c => !c.deleted || saved.some(s => s.id === c.id));

        if (!preserve.length) {
          return;
        }

        this.viewLinesApi.update({
          view: this.viewId,
          viewLines: [...preserve]
        }).pipe(
          tap(() => {
            this.store.dispatch(
              poapActions.requestViewLines()
            );
          })
        ).subscribe();
      })
    ).subscribe()
  }

  getRowNodeId(data: ViewLine): string {
    return data.id;
  }

  onCellValueChanged(event): void {
    const changes = this.changes$.getValue();
    const index = changes.findIndex(c => c.id === event.data.id);

    if (~index) {
      changes[index] = event.data;
    } else {
      changes.push(event.data);
    }

    this.changes$.next(changes);
  }

  private isEditable(): boolean {
    return this.userCanEdit;
  }

  private isViewLinesUnchanged(a, b): boolean {
    let isUnchanged: boolean = a === b;

    if (!isUnchanged) {
      if (Array.isArray(a) && Array.isArray(b)) {
        isUnchanged = a.length - b.length === 0;

        if (isUnchanged) {
          const aLookup = new Map(a.sort(sortViewLine).map((viewLine) => [viewLine.id, viewLine]));
          const bLookup = new Map(b.sort(sortViewLine).map((viewLine) => [viewLine.id, viewLine]));
          const aObject = Object.fromEntries(aLookup.entries());
          const bObject = Object.fromEntries(bLookup.entries());
          return JSON.stringify(Object.keys(aObject).sort((x, y) => x.localeCompare(y))) === JSON.stringify(Object.keys(bObject).sort((x, y) => x.localeCompare(y)));
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    return isUnchanged;
  }
}

function sortViewLine(x, y): number {
  const idDiff = `${x.id}`.localeCompare(`${y.id}`);

  if (idDiff === 0) {
    const versionDiff = x.version.getTime() - y.version.getTime();

    if (versionDiff === 0) {
      const titleDiff = `${x.title}`.localeCompare(`${y.title}`);

      if (titleDiff === 0) {
        const dateDiff = x.date.getTime() - y.date.getTime();

        if (dateDiff === 0) {
          const colourDiff = `${x.colour}`.localeCompare(`${y.colour}`);

          if (colourDiff === 0) {
            return `${x.style}`.localeCompare(`${y.style}`);
          }

          return colourDiff;
        }

        return dateDiff;
      }

      return titleDiff;
    }

    return versionDiff;
  }

  return idDiff;
}
