<div class="subscription-box" *ngIf="!subscription?.is_active && extension && !extension.approved_at && !extension.rejected_at">
  <div class="title">
    Trial extension requested
  </div>
  <div class="description">
    Your request to extend your trial license has been sent and will be reviewed by a member of the traqplan team. Please contact <a href="mailto:support@traqplan.com">support@traqplan.com</a> for support if needed.
    <ng-container *ngIf="canManageSubscription">
      Alternatively you can  <a [routerLink]="" (click)="onPurchaseSubscription()">purchase a subscription</a> to continue using the application.
    </ng-container>
  </div>
</div>

<div class="subscription-box" *ngIf="!subscription?.is_active && extension && extension.rejected_at">
  <div class="title">
    Trial extension rejected
  </div>
  <div class="description">
    You request to extend your trial has been rejected. <ng-container *ngIf="canManageSubscription"> <a [routerLink]="" (click)="onPurchaseSubscription()">purchase a subscription to continue.</a></ng-container>
  </div>
</div>

<div class="subscription-box" *ngIf="!subscription?.is_active && !extension">

  <ng-container *ngIf="subscription?.trial; else inactive">
    <div class="title">
      Trial subscription expired
    </div>
    <div class="description" *ngIf="canManageSubscription">
      Your trial subscription expired on <b>{{subscription?.expired_at?.toLocaleDateString()}}</b>.
      Please <a [routerLink]="" (click)="onPurchaseSubscription()">purchase a subscription to continue.</a>
      Alternatively you can <a [routerLink]="" (click)="onRequestExtension($event, subscription)">request an extension</a>.
    </div>
    <div class="description" *ngIf="!canManageSubscription">
      The trial subscription for this account expired on <b>{{subscription?.expired_at?.toLocaleDateString()}}</b>. If you require access to this account please contact the account owner.
    </div>
  </ng-container>

  <ng-template #inactive>
    <ng-container *ngIf="!canManageSubscription; else subscriptionWarning">
      <div class="title">
        Account inactive
      </div>
      <div class="description">
        There is no active subscription for this account. If you require access to this account please contact the account owner.
      </div>
    </ng-container>
  </ng-template>

  <ng-template #subscriptionWarning>

    <ng-container *ngIf="!subscription; else subscriptionStatus">
      <div class="title">
        Account inactive
      </div>
      <div class="description">
        There is no active subscription for this account. Please <a [routerLink]="" (click)="onPurchaseSubscription()">purchase a new subscription</a> if you wish to regain access.
      </div>
    </ng-container>

    <ng-template #subscriptionStatus [ngSwitch]="subscription?.stripe_subscription_status">
      <ng-container *ngSwitchCase="'incomplete'">
        <div class="title">
          Payment pending
        </div>
        <div class="description">
          Your subscription payment is being processed. Please check again later. You can review your payment details <a [routerLink]="" (click)="onManagePaymentDetails()">here</a>.
        </div>
      </ng-container>
  
      <ng-container *ngSwitchCase="'canceled'">
        <div class="title">
          Subscription inactive
        </div>
        <div class="description">
          The subscription for this account has been cancelled. Please <a [routerLink]="" (click)="onPurchaseSubscription()">purchase a new subscription</a> if you wish to regain access.
        </div>
      </ng-container>
  
      <ng-container *ngSwitchDefault>
        <div class="title">
          Payment failed
        </div>
        <div class="description">
          There's a problem with your subscription payment. Please <a [routerLink]="" (click)="onManagePaymentDetails()">review your payment details</a>.
        </div>
      </ng-container>
    </ng-template>
  </ng-template>

</div>

<div class="subscription-box" *ngIf="subscription && subscription.is_active && !subscription.trial && subscription.expired_at && canManageSubscription">
    <div class="title">
      Renew your subscription
    </div>
    <div class="description">
      Your subscription will end on <b>{{subscription?.expired_at?.toLocaleDateString()}}</b>.
      Please <a [routerLink]="" (click)="onManagePaymentDetails()">renew your subscription</a> if you wish to retain access to your account beyond this date.
    </div>
</div>

<div class="subscription-box" *ngIf="subscription && subscription.is_active && subscription.trial && subscription.expired_at">
  <div class="title">
    {{trialMessage}}
  </div>
  <div class="description">
    Your trial period allows you and up to 9 other users to access all features of the Traqplan application until <b>{{subscription?.expired_at?.toLocaleDateString()}}</b>.
    <ng-container *ngIf="canManageSubscription">
      <a [routerLink]="" (click)="onPurchaseSubscription()">Purchase a subscription</a>.
    </ng-container>
  </div>
</div>