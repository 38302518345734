import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs'
import { NotificationsService } from '../../api/notifications.service';
import { HierarchyNode } from 'tp-traqplan-core/dist/data-structs';
import * as modalActions from '../../state/modal/actions';
import { Store } from '@ngrx/store';
import { State } from '../../state';

@Component({
  selector: 'app-request-access',
  templateUrl: './request-access.component.html',
  styleUrls: ['./request-access.component.scss']
})
export class RequestAccessComponent implements OnChanges, OnInit {

  @Input() hierarchyNode: HierarchyNode;

  actions: ModalWindowAction[] = [
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true,
      escKey: true
    },
    {
      label: 'Request permissions',
      type: 'positive',
      confirm: true,
      enterKey: true,
    }
  ];

  visible = true;

  nodeLabel: string;

  customMessage: string;

  constructor(
    private store: Store<State>,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    if (this.hierarchyNode) {
      this.ngOnChanges();
    }
  }

  ngOnChanges(): void {
    if (this.hierarchyNode) {
      if (this.hierarchyNode.revisions?.length) {
        this.nodeLabel = 'project';
      } else if (!this.hierarchyNode.children?.length) {
        this.nodeLabel = 'workspace';
      } else {
        this.nodeLabel = 'programme';
      }
    }
  }

  complete(action?: ModalWindowAction) {
    if (action?.confirm) {

      this.notificationsService.sendAccessRequest({
        id: this.hierarchyNode?.id,
        message: this.customMessage
      }).subscribe(() => {
        console.log('access requested');
      });

    }

    this.store.dispatch(
      modalActions.closeModal()
    );
  }

}
