import { createAction, props } from '@ngrx/store';
import { AccountInfo, AccountId, HierarchyNode, HierarchyNodeId, HierarchyAccessListNode, License, User, UserId, Subscription, RevisionId, ViewId } from 'tp-traqplan-core/dist/data-structs';
import { RequestError } from '../../api/structs';
import { State } from './state';

export const reset = createAction(
  '[account] reset',
  props<{ state?: Partial<State> }>()
);

export const requestAvailable = createAction(
  '[account] requestAvailable'
);

export const requestAvailableSuccess = createAction(
  '[account] requestAvailableSuccess',
  props<{ accounts: AccountInfo[] }>()
);

export const requestAvailableFailure = createAction(
  '[account] requestAvailableFailure',
  props<{ error: RequestError }>()
);

export const launchAccount = createAction(
  '[account] launchAccount',
  props<{ account: AccountId; navigate?: any }>()
);

export const launchAccountSuccess = createAction(
  '[account] launchAccountSuccess',
  props<{ account: AccountId; navigate?: any  }>()
);

export const launchAccountFailure = createAction(
  '[account] launchAccountFailure',
  props<{ account: AccountId }>()
);

export const accountCreated = createAction('[account] accountCreated');

export const requestAvailableRoots = createAction(
  '[account] requestAvailableRoots'
);

export const requestAvailableRootsSuccess = createAction(
  '[account] requestAvailableRootsSuccess',
  props<{ account: AccountId; roots: HierarchyNode[] }>()
);

export const requestAvailableRootsFailure = createAction(
  '[account] requestAvailableRootsFailure',
  props<{ account: AccountId }>()
);

export const requestLicenses = createAction(
  '[account] requestLicenses'
);

export const requestLicensesSuccess = createAction(
  '[account] requestLicensesSuccess',
  props<{ licenses: License[] }>()
);

export const requestLicensesFailure = createAction(
  '[account] requestLicensesFailure',
  props<{ error: RequestError }>()
);

export const requestAdmins = createAction(
  '[account] requestAdmins'
);

export const requestAdminsSuccess = createAction(
  '[account] requestAdminsSuccess',
  props<{ admins: { [key: string]: User } }>()
);

export const requestAdminsFailure = createAction(
  '[account] requestAdminsFailure',
  props<{ error: RequestError }>()
);

export const requestUsers = createAction(
  '[account] requestUsers'
);

export const requestUsersSuccess = createAction(
  '[account] requestUsersSuccess',
  props<{ users: { [key: string]: User } }>()
);

export const requestUsersFailure = createAction(
  '[account] requestUsersFailure',
  props<{ error: RequestError }>()
);

export const assignLicense = createAction(
  '[account] assignLicense',
  props<{ licenseId: string; userId: string; }>()
);

export const unassignLicense = createAction(
  '[account] unassignLicense',
  props<{ id: string; }>()
);

export const unassignLicenseSuccess = createAction(
  '[account] unassignLicenseSuccess'
);

export const selectRoot = createAction(
  '[account] selectRoot',
  props<{ root: HierarchyNode }>()
);

export const assignAccess = createAction(
  '[account] assignAccess',
  props<{ path: HierarchyNodeId[]; userId: UserId; permission: number }>()
);

export const assignAccessSuccess = createAction(
  '[account] assignAccessSuccess',
  props<{ selectProject?: HierarchyNodeId; unselectRevision?: RevisionId; selectView?: ViewId }>()
);

export const requestHierarchyPermissions = createAction(
  '[account] requestHierarchyPermissions'
);

export const requestHierarchyPermissionsSuccess = createAction(
  '[account] requestHierarchyPermissionsSuccess',
  props<{ hierarchyPermissions: HierarchyAccessListNode[] }>()
);

export const requestHierarchyPermissionsFailure = createAction(
  '[account] requestHierarchyPermissionsFailure',
  props<{ error: RequestError }>()
);

export const activeSubscription = createAction(
  '[account] activeSubscription',
  props<{ subscription: Subscription | undefined }>()
);

export const subscriptionExpired = createAction(
  '[account] subscriptionExpired',
  props<{ subscription: Subscription | undefined }>()
);

export const trialSubscriptionExpired = createAction(
  '[account] trialSubscriptionExpired',
  props<{ subscription: Subscription | undefined }>()
);
