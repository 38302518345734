import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SubscriptionExtension } from 'tp-traqplan-core/dist/data-structs';
import { AuthApiService } from './auth-api.service';

import { defaultPipe } from './common';
import { ReqHeader } from './structs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionExtensionApiService {

  constructor(
    private http: HttpClient,
    private authApi: AuthApiService
  ) { }

  create({ subscriptionId }) {
    return this.authApi.headers({ requires: ReqHeader.account & ReqHeader.user }).pipe(
      switchMap((headers): Observable<SubscriptionExtension> => {
        return this.http.post<{ subscriptionExtension: SubscriptionExtension }>(
          '/api/subscription-extensions',
          { subscriptionId },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  getLatest() {
    return this.authApi.headers({ requires: ReqHeader.account & ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ subscriptionExtension: SubscriptionExtension }> => {
        return this.http.get<{ subscriptionExtension: SubscriptionExtension }>(
          '/api/subscription-extensions',
          { params: {}, headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

}
