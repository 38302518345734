import { User } from 'tp-traqplan-core/dist/data-structs';
import { RequestError } from '../../api/structs';

export const key = 'auth';

export interface State {
  token: string | null;
  user: User | null;
  //isGoogleUser: boolean | null;
  loginError: RequestError | null;
  loginAttempts: number;
  pending: boolean;
  //origin: string | null;
  //ssoToken: string | null;
}

export const initialState: State = {
  token: null,
  user: null,
  //isGoogleUser: null,
  loginError: null,
  loginAttempts: 0,
  pending: false
  //origin: null,
  //ssoToken: null
};

export function beforeSave(state) {
  return {
    ...state,
    pending: false
  };
}

export function beforeLoad(state) {
  return state;
}
