import { Component, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { forkJoin, of, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, tap, takeUntil, take } from 'rxjs/operators';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { HierarchyNode, TreeProjectNode } from 'tp-traqplan-core/dist/data-structs';
import { HierarchyApiService } from '../../api/hierarchy-api.service';
import { namingConventionValidator } from '../../shared/validators/naming-convention.validator';
import { State } from '../../state';
import * as appActions from '../../state/app/actions';
import * as projectsActions from '../../state/projects/actions';
import * as modalActions from '../../state/modal/actions';
import { ProjectsService } from '../../state/selectors/projects.service';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss']
})
export class CreateProjectComponent implements OnDestroy {

  private destroy$ = new Subject<void>();

  projectForm = new FormGroup({
    name: new FormControl('',
      [
        Validators.required,
        Validators.minLength(1),
        namingConventionValidator()
      ],
    ),
    programme: new FormControl('',
      [
        Validators.required,
        // if programme exists and has been selected then value will be object, else string:
        (control: AbstractControl) => {
          return typeof control?.value === 'object' ? null : namingConventionValidator()(control);
        }
      ]
    )
  }, {
    asyncValidators: [
      () => this.validateForm()
    ],
    updateOn: 'change'
  });

  actions: ModalWindowAction[] = [
    {
      label: 'Create Project',
      type: 'positive',
      confirm: true,
      valid: () => this.projectForm.valid,
      enterKey: true,
    },
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true,
      escKey: true
    }
  ];

  visible = true;

  programmes: HierarchyNode[];
  suggestions: HierarchyNode[];
  //selectedProgramme: HierarchyNode;

  constructor(
    private store: Store<State>,
    private projectsService: ProjectsService,
    private hierarchyApi: HierarchyApiService
  ) {

    this.visible = true;

    this.projectsService.programmes$.pipe(
      takeUntil(this.destroy$),
      tap(v => {
        this.programmes = [...v.values()];
      })
    ).subscribe();

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isInputValid(): boolean {
    return true;
  }

  onActionSelected(action: ModalWindowAction): void {

    if (!action.confirm) {
      return;
    }

    const project = this.projectForm.controls.name.value;
    const parent = this.projectForm.controls.programme.value;
    const programme = (typeof parent === 'object') ? parent
      : this.programmes.find(p => p.name === parent) || { name: parent };

    this.store.pipe(
      takeUntil(this.destroy$),
      take(1),
      // create programme if new, or emit existing programme:
      tap(state => {
        const path: (HierarchyNode | { name: string; allowImportedEdits: boolean })[] = [
          state.account.root.selected,
          programme,
          { name: project }
        ];
        this.store.dispatch(projectsActions.createNode({ path }));
        this.store.dispatch(appActions.setNewProject({ newProject: <TreeProjectNode>{ name: project, parentName: programme.name } }));
        this.store.dispatch(appActions.isProjectCreating({ isProjectCreating: true }));
        this.store.dispatch(appActions.isProjectCreated({ isProjectCreated: false }));
      })
    ).subscribe();

  }

  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }

  searchProgrammes({ query }): void {
    this.store.pipe(
      takeUntil(this.destroy$),
      take(1),
      switchMap(state => this.hierarchyApi.searchChildren({
        id: state.account.root.selected.id,
        query: query
      })),
      tap((response) => {
        if (response) {
          const { matches } = response;
          this.suggestions = matches;
        } else {
          this.suggestions = [];
        }
      })
    ).subscribe();
  }

  selectProgramme(programme): void {
    this.store.pipe(
      takeUntil(this.destroy$),
      take(1),
      filter(state => !!state.account.root.available?.value)
    ).subscribe((state) => {
      const workspace = state.account.root.available.value.find((w) => w.id === programme.parent);

      if (workspace?.id) {
        this.projectForm.patchValue({ workspace });
      }
    });
  }

  private validateForm(): Observable<ValidationErrors> {
    const projectName = this.projectForm.controls.name.value;
    const selection = this.projectForm.controls.programme.value;

    if (!projectName || !selection) {
      return of(null)
    }
    const programme = (typeof selection === 'object') ? selection : this.programmes.find(p => p.name === selection) || selection;

    const programmeExists = (typeof programme === 'object');

    return forkJoin(
      !projectName ? of(true) : this.hierarchyApi.checkAvailable(projectName, 2).pipe(map((v: { available: boolean }) => v.available)),
      programmeExists || !selection ? of(true) : this.hierarchyApi.checkAvailable(programme as string, 1).pipe(map((v: { available: boolean }) => v.available))
    ).pipe(
      map(([projectAvailable, programmeAvailable]) => {

        if (projectAvailable && programmeAvailable) {
          return null;
        }
        return {
          programmeNamingConflict: !programmeAvailable,
          projectNamingConflict: !projectAvailable
        };

      })
    );
  }

}
