import { Component, OnInit, Input, HostBinding, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { timeDay } from 'd3';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { GroupTreeNode, HierarchyNodeId, RevisionId, TaskId, TimelineGroup, ViewGroupId, DrawingLayout } from 'tp-traqplan-core/dist/data-structs';
import { TimelineLayout } from 'tp-traqplan-core/dist/timeline/timeline-layout';
import { GroupBoundElement, ViewGroupChange } from 'tp-traqplan-core/dist/timeline/tl-structs';
import { MetaGroupField } from 'tp-traqplan-core/dist/workspace-structs';
import * as groupUtils from 'tp-traqplan-core/dist/task-group-utils';
import { State } from '../../../state';
import { TimelineInteractions } from '../tl-interactions';
import { GroupChangeEvent, OpenTaskModalEvent, ProjectReorderEvent, ShowFloatingInputEvent } from '../tl-events';
import { EventID, ShowGroupContextMenuEvent } from '../tl-events';
import { ContextMenu } from 'primeng/contextmenu';
import { MenuItem } from 'primeng/api';

const defaultConfirmAction = <ModalWindowAction>{
  label: 'Confirm',
  type: 'positive',
  confirm: true,
  enterKey: true
};

const defaultCancelAction = <ModalWindowAction>{
  label: 'Cancel',
  type: 'neutral',
  cancel: true,
  escKey: true
};

enum GroupModal {
  rename = 1,
  delete,
  create,
  createTask,
  merge,
  drawingLayoutTheme,
  sort,
  //showGroupOutlineLevel,
  //showTaskOutlineLevel,
  hide,
  show,
  selectOrCreate,
  selectTask
}

@Component({
  selector: 'app-group-menu',
  templateUrl: './group-menu.component.html',
  styleUrls: ['./group-menu.component.css']
})
export class GroupMenuComponent implements OnInit {

  @Input() interactions: TimelineInteractions;
  @Input() layout: TimelineLayout;
  @Input() editCheck: (projectId: HierarchyNodeId, revisionId: RevisionId, taskOrGroup: string, itemId: TaskId | ViewGroupId, taskFields?: string[], displayMessage?: boolean) => boolean = () => true;

  @ViewChild('contextMenu', { static: true }) menu: ContextMenu;

  @HostBinding('class.active') private active: boolean = false;

  target: GroupBoundElement;
  group: TimelineGroup;
  rootGroup: TimelineGroup;
  
  xPosition: string;
  yPosition: string;

  menuItems: MenuItem[];

  // modal inputs:
  modals = GroupModal;
  modalActive: GroupModal | null;

  modalSortActions: ModalWindowAction[] = [{ ...defaultCancelAction }, { ...defaultConfirmAction }];

  modalDeleteActions: ModalWindowAction[] = [
    { ...defaultCancelAction },
    {
      ...defaultConfirmAction,
      label: 'Delete',
      type: 'negative'
    },
  ];

  modalDrawingLayoutThemeActions: ModalWindowAction[] = [{
    label: 'Close',
    type: 'neutral',
    cancel: true,
    escKey: true
  }];
  
  //modalShowGroupOutlineLevelActions: ModalWindowAction[];
  //modalShowTaskOutlineLevelActions: ModalWindowAction[];
  modalMergeActions: ModalWindowAction[] = [
    { ...defaultCancelAction },
    {
      ...defaultConfirmAction,
      valid: () => this.modalMergeSelection
    }
  ];

  modalMergeBranches: string[];
  modalMergeExclude: string[];
  modalMergeSelection: Partial<TimelineGroup>;

  parent: TimelineGroup;
  siblings: TimelineGroup[];

  get isProjectRoot() {
    return this.group?.isProjectRoot;
  }

  drawingLayouts = [
    { name: 'Inherit', value: void 0 },
    { name: 'Gantt', value: DrawingLayout.GANTT },
    { name: 'Milestones and bars', value: DrawingLayout.MILESTONES_AND_BARS },
    { name: 'Milestones above bars', value: DrawingLayout.MILESTONES_ABOVE_BARS },
    { name: 'Bars above milestones', value: DrawingLayout.BARS_ABOVE_MILESTONES },
    { name: 'Milestones only', value: DrawingLayout.MILESTONES_ONLY },
    // { name: 'Milestones as phases', value: DrawingLayout.MILESTONES_AS_PHASES },
    { name: 'Waterfall', value: DrawingLayout.WATERFALL }
  ];

  groupTreeNode: GroupTreeNode;

  private groupTree: GroupTreeNode[];

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {

    this.interactions?.events$.pipe(
      takeUntil(this.destroy$),
      filter(e => e.event === EventID.ShowGroupContextMenu)
    ).subscribe(({ data }: ShowGroupContextMenuEvent) => {
      this.target = data.target as GroupBoundElement;
      this.group = this.layout.getTimelineGroup(this.target.__fitting__.groupUid);

      this.xPosition = `${data.event.clientX}px`;
      this.yPosition = `${data.event.clientY}px`;

      this.parent = this.layout.getTimelineGroup(this.group.parent);
      this.siblings = !this.parent ? this.layout.getRootGroups() : this.layout.getTimelineGroups(this.parent.children);

      this.modalMergeBranches = [this.layout.getRootGroup(this.group.id).id];
      this.modalMergeExclude = [this.group?.id];

      // if root group then rename 'inherit' option of drawing themes to 'default':
      this.drawingLayouts[0] = { ...this.drawingLayouts[0], name: this.group.isProjectRoot ? 'Default' : 'Inherit' };
      this.drawingLayouts = [...this.drawingLayouts];

      this.updateMenuItems();
      setTimeout(() => this.menu.show(), 50);
    });

  }

  updateMenuItems() {

    this.menuItems = [];

    !this.group.isProjectRoot && this.menuItems.push(
      { label: 'Rename', command: this.onClickRename.bind(this) }
    );

    this.menuItems.push(
      { label: 'Create task', command: this.onClickCreateTask.bind(this) },
      { label: 'Layout theme', command: () => (this.modalActive = GroupModal.drawingLayoutTheme) }
    );

    // sort only applicable if group has siblings:
    this.siblings.length > 1 && this.menuItems.push(
      { label: 'Sort', command: () => (this.modalActive = GroupModal.sort) }
    );

    this.menuItems.push(
      //{ label: 'Show subgroups',  command: () => (this.modalActive = GroupModal.show) },
      { label: 'Create subgroup', command: this.onClickCreateSubgroup.bind(this) }
    );

    !this.group.isProjectRoot && this.menuItems.push(
      { label: 'Merge', command: () => (this.modalActive = GroupModal.merge) },
      //{ label: 'Hide', command: () => (this.modalActive = GroupModal.hide) },
      { label: 'Delete', command: () => (this.modalActive = GroupModal.delete) }
    );

  }

  onClickRename() {
    this.interactions.events$.next(<ShowFloatingInputEvent>{
      event: EventID.ShowFloatingInput,
      data: {
        label: 'Enter group name...',
        value: this.group.name,
        validator: value => {
          if (!value?.length) {
            return 'Enter a new group name';
          }
          if (this.siblings.some(g => g.id !== this.group.id && g.name === value)) {
            return 'Sibling groups cannot have the same name';
          }
          return null;
        },
        complete: value => {
          if (this.group.name !== value) {
            this.interactions.events$.next(<GroupChangeEvent>{
              event: EventID.GroupChange,
              data: <ViewGroupChange>{
                revisionId: this.group.revision,
                action: `Rename group '${this.group.name}' to '${value}'`,
                changes: [{
                  group: this.group,
                  field: 'name',
                  value: value
                }]
              }
            });
          }
        }
      }
    });
  }

  onClickCreateTask(): void {

    const groupPath = groupUtils.getGroupPath(this.layout.settings.groupMap, this.group.id);
    const sibling = this.group.tasks?.slice(-1)[0] || void 0;
    const start = timeDay.round(new Date());
    const finish = timeDay.offset(start, 7);
    const revision = this.group.revision;

    this.interactions.events$.next(<OpenTaskModalEvent>{
      event: 'taskModalOpen',
      data: { start, finish, sibling, groupPath, revision }
    });
  }

  onClickCreateSubgroup() {

    const children = this.interactions.layout.getTimelineGroups(this.group.children || []);

    this.interactions.events$.next(<ShowFloatingInputEvent>{
      event: EventID.ShowFloatingInput,
      data: {
        label: 'Enter group name...',
        validator: value => {
          if (!value?.length) {
            return 'Enter a new group name';
          }
          if (children.some(g => g.name === value)) {
            return 'Sibling groups cannot have the same name'
          }
          return null;
        },
        complete: value => {
          this.interactions.events$.next({
            event: 'groupChange',
            data: <ViewGroupChange>{
              revisionId: this.group.revision,
              action: `Create group '${value}'`,
              changes: [{
                // the parent group:
                group: this.group,
                field: MetaGroupField.create,
                value: {
                  name: value
                }
              }]
            }
          });
        }
      }
    });
  }

  onDeleteAction(action: ModalWindowAction): void {
    if (action.label.toLowerCase() === 'delete') {
      this.interactions.events$.next({
        event: 'groupChange',
        data: <ViewGroupChange>{
          revisionId: this.group.revision,
          action: `Delete group '${this.group.name}'`,
          changes: [{
            group: this.group,
            field: 'deleted',
            value: true
          }]
        }
      });
    }
  }

  /**onMenuAction(action: GroupModal): void {
    
    this.drawingLayouts[0] = { ...this.drawingLayouts[0], name: this.group.isProjectRoot ? 'Default' : 'Inherit' };
    this.drawingLayouts = [...this.drawingLayouts];

    this.rootGroup = groupUtils.getRootGroup(this.layout.groupMap, this.group.project);

    if (!this.editCheck(this.group.project, this.group.revision, 'group', this.group.id, void 0, true)
    || !this.modals[action] || this.modalActive) return;

    this.store.pipe(take(1)).subscribe((state) => {
      const revisions = state.poap?.revisions?.value;
      const revision = revisions ? revisions[this.group?.revision] : <RevisionData>{};

      if (revision && revision.id) {
        this.store.dispatch(projectsActions.selectProjectTreeRevisions({ select: true, revisions: [revision] }));
        this.store.dispatch(poapActions.removeSelectedRevisions({ selectedRevisions: [revision] }));
      }

      this.modalActive = action;

      if (action === this.modals.showGroupOutlineLevel || action === this.modals.showTaskOutlineLevel) {
        if (revisions) {
          if (Array.isArray(revision?.tasks)) {
            const { tasks } = revision;
            this.groupOutlineLevels = [];
            this.taskOutlineLevels = [];

            for (let i = 0; i < tasks.length; i++) {
              const { outlineNumber } = tasks[i];
              const groupOutlineLevel = outlineNumber.length;

              if (!this.groupOutlineLevels.some(({ value }) => value === groupOutlineLevel)) {
                this.groupOutlineLevels.push({ name: groupOutlineLevel.toString(10), value: groupOutlineLevel });
                this.taskOutlineLevels.push({ name: groupOutlineLevel.toString(10), value: groupOutlineLevel });
              }
            }

            if (this.groupOutlineLevels.length > 1) {
              this.groupOutlineLevels.pop();
            }

            if (this.taskOutlineLevels.length > 0) {
              this.taskOutlineLevels.shift();
            }
          }

          const timer = setTimeout(() => {
            this.selectedGroupOutlineLevel = this.groupOutlineLevels[this.groupOutlineLevels.length - 1].value;
            this.selectedTaskOutlineLevels = (<number[]>[]).concat(this.taskOutlineLevels.map(({ value }) => value));
            clearTimeout(timer);
          }, 100);
        }
      }
    });
  } */

  onChangeDrawingLayoutTheme({ value }): void {
    this.group.drawingLayout = value;
    this.interactions.events$.next(<GroupChangeEvent>{
      event: 'groupChange',
      data: {
        revisionId: this.group.revision,
        action: `Change drawing theme for group '${this.group.name}'`,
        changes: [{
          group: this.group,
          field: 'drawingLayout',
          value: value
        }]
      }
    })
  }

  onSortAction(action: ModalWindowAction): void {

    if (action.confirm) {
      if (this.group.isProjectRoot) {
        this.interactions.events$.next(<ProjectReorderEvent>{
          event: 'projectReorder',
          data: {
            order: this.siblings.map(group => group.id)
          }
        })
      } else {
        this.interactions.events$.next(<GroupChangeEvent>{
          event: 'groupChange',
          data: {
            revisionId: this.parent.revision,
            action: `Reorder child groups of '${this.parent.name}'`,
            changes: [{
              group: this.parent,
              field: 'children',
              value: this.siblings.map(g => g.id)
            }]
          }
        });
      }
    }
  }

  /*onShowGroupOutlineLevel(action: ModalWindowAction): void {
    if (action.confirm) {
      this.store.dispatch(poapActions.setSelectedGroupOutlineLevel({ project: this.group?.project, outlineLevel: this.selectedGroupOutlineLevel }));
    }
  }

  onShowTaskOutlineLevel(action: ModalWindowAction): void {
    if (action.confirm) {
      this.store.dispatch(poapActions.setSelectedTaskOutlineLevels({ project: this.group?.project, outlineLevels: this.selectedTaskOutlineLevels }));
    }
  }*/

  onMergeAction(action: ModalWindowAction): void {
    if (action.confirm) {
      this.interactions.events$.next(<GroupChangeEvent>{
        event: 'groupChange',
        data: {
          revisionId: this.group.revision,
          action: `Merge group '${this.group.name}' into '${this.modalMergeSelection.name}'`,
          changes: [{
            group: this.group,
            field: MetaGroupField.merge,
            value: this.modalMergeSelection.id
          }]
        }
      });
    }
  }

  /*onShowAction(action: ModalWindowAction): void {
    if (action.confirm) {
      this.interactions.events$.next(<GroupChangeEvent>{
        event: 'groupChange',
        data: {
          revisionId: this.group.revision,
          action: `Show all tasks in group '${this.group.name}'`,
          changes: [{
            group: this.group,
            field: MetaGroupField.showTasks,
            value: null
          }]
        }
      });
    }
  }*/

  /*onHideAction(action: ModalWindowAction): void {
    if (action.confirm) {
      this.interactions.events$.next(<GroupChangeEvent>{
        event: 'groupChange',
        data: {
          revisionId: this.group.revision,
          action: `Hide all tasks in group '${this.group.name}'`,
          changes: [{
            group: this.group,
            field: MetaGroupField.hideTasks,
            value: null
          }]
        }
      });
    }
  }*/

  onModalMergeSelectionChange(group: Partial<TimelineGroup>): void {
    if (group && group.id) {
      this.modalMergeSelection = group;
    }
  }

  onModalVisibleChange(visible: boolean): void {
    if (!visible) {
      this.modalActive = null;
    }
  }

}
