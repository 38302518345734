import { createReducer, on } from '@ngrx/store';
import { initialState } from './state';
import * as tpsActions from './actions';

export const reducer = createReducer(
  initialState,

  on(tpsActions.reset, (state, props) => {
    return { ...initialState, ...props };
  }),

  on(tpsActions.attemptLogin, (state) => {
    return {
      ...state,
      loginError: null,
      pending: true
    }
  }),

  on(tpsActions.attemptLoginFailure, (state, props) => {
    return {
      ...state,
      token: null,
      staff: null,
      loginError: props.error,
      pending: false
    };
  }),

  on(tpsActions.verifyStaffCredentials, (state) => {
    return {
      ...state,
      pending: true
    };
  }),

  on(tpsActions.verifyStaffCredentialsFailure, (state) => {
    return {
      ...state,
      token: null,
      staff: null,
      pending: false
    };
  }),

  on(tpsActions.attemptLoginSuccess, (state, props) => {
    return {
      ...state,
      token: props.token,
      staff: props.tps,
      loginError: null,
      pending: false
    };
  }),

  on(tpsActions.logout, (state) => {
    return {
      ...state,
      token: null,
      staff: null,
      loginError: null,
    };
  })
);
