import { Component,ChangeDetectorRef, OnInit, HostBinding, OnDestroy, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { map, takeUntil, tap, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RevisionInfo, TreeProjectNode, User } from 'tp-traqplan-core/dist/data-structs';
import { State } from '../../state';
import { TreeFilterType } from '../../state/projects/state';
import * as accountActions from '../../state/account/actions';
import * as appActions from '../../state/app/actions';
import * as modalActions from '../../state/modal/actions';
import * as poapActions from '../../state/poap/actions';
import * as projectsActions from '../../state/projects/actions';
import { StateModal } from '../../state/structs';
import { ProjectsService } from '../../state/selectors/projects.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { debounce } from 'lodash';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-project-tree',
  templateUrl: './project-tree.component.html',
  styleUrls: ['./project-tree.component.scss', './style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectTreeComponent implements OnInit, OnDestroy {

  @HostBinding('class.expanded') get expanded() {
    return this.paneExpanded;
  }

  @Output() expansionStateChange = new EventEmitter<boolean>();

  isModalVisibleDraftCreating: boolean = false;
  isModalVisibleDraftCreated: boolean = false;
  isModalVisibleProjectCreating: boolean = false;
  isModalVisibleRevisionCreating: boolean = false;
  isModalVisibleRevisionCreated: boolean = false;

  destroy$ = new Subject<void>();

  imports$ = this.projectsService.tree$.pipe(
    takeUntil(this.destroy$),
    map((state) => {
      const { groupings, imports, selectedRevisions }: any = state;

      if (Array.isArray(selectedRevisions)) {
        this.store.dispatch(poapActions.resetSelectedRevisions());
        const selectedRevisionsLookup: { [key: string]: RevisionInfo } = {};
        const newSelectedRevisions: RevisionInfo[] = [];
        groupings.forEach(({ nodes }) => {
          if (Array.isArray(nodes)) {
            for (let i = 0; i < nodes.length; i++) {
              const { id, revisions } = nodes[i];
              const revision = selectedRevisionsLookup[id] || selectedRevisions.find(({ project }) => project === id);

              if (revision) {
                newSelectedRevisions.push(revisions[0]);
              }
            }
          }
        });

        this.store.dispatch(projectsActions.selectProjectTreeRevisions({ select: true, revisions: newSelectedRevisions }));
      }

      return imports;
    })
  );

  createProjectsDisabled: boolean = false;
  importProjectsDisabled: boolean = false;
  draftProject?: TreeProjectNode;
  newProject?: TreeProjectNode;
  newRevision?: RevisionInfo;
  currentUser: User | null;

  paneExpanded: boolean = true;
  workspace: string;
  onImportPage: boolean = false;

  projectCounter: number = 0;
  draftCounter: number = 0;
  draftCreateCounter: number = 0;
  revisionCounter: number = 0;
  revCreatingCounter: number = 0;

  searchDescription: string = null;

  updateSearchFilter = debounce((search: string) => {
    this.store.dispatch(
      projectsActions.setTreeFilter({ search: search?.length ? search : null })
    );
  }, 200);

  constructor( private messageService: MessageService,
    private store: Store<State>, private location: Location, private projectsService: ProjectsService,
    private _router: Router, private _activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef
  ) {
    _router.events.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.onImportPage = this.location.path().includes('import');
      }
    });
  }

  ngOnInit(): void {
    this._activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.workspace = params.workspace;
      this.onImportPage = this.location.path().includes('import');
    });

    this.store.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      this.draftProject = state?.app?.draftProject;
      this.newProject = state?.app?.newProject;
      this.newRevision = state?.app?.newRevision;
      this.currentUser = state?.auth?.user;
      this.isModalVisibleDraftCreating = state?.app?.isDraftCreating || false;
      if(this.isModalVisibleDraftCreating && this.draftCreateCounter ===0){
        this.draftCreateCounter++;
        this.showDraftCreating();
      }

      this.isModalVisibleDraftCreated = state?.app?.isDraftCreated || false;
      if(this.isModalVisibleDraftCreated && this.draftCounter === 0){
        this.draftCounter++;
        this.showDraftCreated();
      }
      this.isModalVisibleProjectCreating = state?.app?.isProjectCreating && !state?.app?.isProjectCreated;
      if(this.isModalVisibleProjectCreating && this.projectCounter === 0){
        this.projectCounter++;
        this.showProjectCreating();
      }

      this.isModalVisibleRevisionCreating = state?.app?.isRevisionCreating || false;
      if(this.revCreatingCounter===0 && this.isModalVisibleRevisionCreating ){
        this.revCreatingCounter++;
        this.showDraftPublishing();
      }

      this.isModalVisibleRevisionCreated = state?.app?.isRevisionCreated || false;
      if(this.isModalVisibleRevisionCreated && this.revisionCounter === 0 && this.newRevision){
        this.revisionCounter++;
        this.showDraftPublished();
      }
    });

    this.store.pipe(
      takeUntil(this.destroy$),
      map(state => state.projects.tree.filter),
      distinctUntilChanged(),
      tap(filter => {
        this.searchDescription = filter.filterType === TreeFilterType.favourites ? 'Showing only favourites'
          : filter.filterType === TreeFilterType.drafts ? 'Showing only drafts'
            : filter.filterType === TreeFilterType.selected ? 'Showing only selected'
              : null;
      })
    ).subscribe();
    this.store.dispatch(projectsActions.requestProjectTree({}));
    this.store.dispatch(projectsActions.requestFavourites());
    this.store.dispatch(accountActions.requestUsers());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onToggle(): void {
    this.paneExpanded = !this.paneExpanded;
    this.cdr.detectChanges();
    this.expansionStateChange.emit(this.paneExpanded);
  }

  onCreateProject(): void {
    this.store.dispatch(
      modalActions.generateModal({ modal: StateModal.createProject })
    );
  }

  onImportProject(): void {
    this._router.navigate([['workspace', this.workspace, 'import'].join('/')]);
  }

  filterView(filterType: TreeFilterType): void {
    if (TreeFilterType[filterType]) {
      this.store.dispatch(
        projectsActions.setTreeFilter({ filterType: filterType })
      );
    }
  }

  clearFilterTypes(): void {
    this.store.dispatch(
      projectsActions.setTreeFilter({ filterType: null })
    );
  }

  onCloseDraftCreating(): void {
    this.store.dispatch(appActions.isDraftCreating({ isDraftCreating: false }));
  }

  onCloseDraftCreated(): void {
    this.store.dispatch(appActions.isDraftCreated({ isDraftCreated: false }));
  }

  onCloseProjectCreating(): void {
    this.store.dispatch(appActions.isProjectCreating({ isProjectCreating: false }));
  }

  onCloseRevisionCreating(): void {
    this.store.dispatch(appActions.isRevisionCreating({ isRevisionCreating: false }));
  }

  onCloseRevisionCreated(): void {
    this.store.dispatch(appActions.isRevisionCreated({ isRevisionCreated: false }));
  }

  showProjectCreating(){
    this.messageService.add({
      severity: 'success',
      summary: 'Creating Project',
      detail: `We are creating a new project ${this.newProject?.name} for user ${this.currentUser?.name} in programme ${this.newProject?.parentName}.`,
    });
    setTimeout(() => {
      this.projectCounter = 0;
    }, 5000);
    this.store.dispatch(appActions.isProjectCreating({ isProjectCreating: false }));
  }

  showDraftPublishing(){
    this.messageService.add({
      severity: 'warn',
      summary: 'Publishing Draft',
      detail: `We are publishing your draft of project ${this.draftProject?.name}. Please do not refresh the page while we are publishing.`,
    });
    setTimeout(() => {
      this.revCreatingCounter = 0;
    }, 5000);
    this.store.dispatch(appActions.isRevisionCreating({ isRevisionCreating: false }));
  }

  showDraftPublished(){
    this.messageService.clear();
    this.messageService.add({
      severity: 'success',
      summary: 'Draft published',
      detail: `Your draft of project ${this.draftProject?.name} has been
      published as version v${this.newRevision?.projectVersion}.${this.newRevision?.viewVersion} ${this.newRevision?.label}. It will now be visible to other users with access
      to that project.`,
      life: 5000,
    });
    setTimeout(() => {
      this.revisionCounter = 0;
    }, 5000);
    this.store.dispatch(appActions.isRevisionCreated({ isRevisionCreated: false }));
  }

  showDraftCreating(){
    this.messageService.add({
      severity: 'warn',
      summary: 'Creating draft',
      detail: `We are creating a draft of project ${this.draftProject?.name} for user ${this.currentUser?.name} to modify. Please do not refresh the page and wait for the draft to be created. `,
      life: 5000,
    });
    setTimeout(() => {
      this.draftCreateCounter = 0;
    }, 3000);
    this.store.dispatch(appActions.isDraftCreating({ isDraftCreating: false }));
  }

  showDraftCreated(){
    this.messageService.clear();
    this.messageService.add({
      severity: 'success',
      summary: 'Draft created',
      detail: `A draft of project ${this.draftProject?.name} has been created. Any changes made by you ${this.currentUser?.name} are not visible to other users unless the change are published. You have the option to publish changes, or discard a draft. You can undo changes made to your draft. When a draft is published you cannot undo it.`,
      life: 5000,
    });
    setTimeout(() => {
      this.draftCounter = 0;
    }, 5000);
    this.store.dispatch(appActions.isDraftCreated({ isDraftCreated: false }));
  }
}
