
import { Subject, BehaviorSubject } from "rxjs";
import { timeDay, timeWeek } from "d3";
import { 
  TimelineState,
  BOUNDARY_MODE,
  DragAction,
  ClickAction,
  DataBoundElement,
  GroupFitting,
  TimelineTask,
  EditCheckParams,
  GroupBoundElement,
  MouseoverAction,
  TaskBoundElement,
  TaskDragType,
  ViewGroupChange,
  TaskChange,
  ContextMenuAction,
  DblClickAction,
  TimelineOptions,
  ViewLineFitting
} from "tp-traqplan-core/dist/timeline/tl-structs";
import { standardAttribute, standardClass } from "tp-traqplan-core/dist/timeline/tl-style";
import { MetaGroupField, MetaTaskField, PoapChange, PoapGroupFieldChange, PoapTaskFieldChange } from "tp-traqplan-core/dist/workspace-structs";
import { TimelineLayout } from "tp-traqplan-core/dist/timeline/timeline-layout";
import { getGroupNamePath } from "tp-common/revisions/view-groups";
import * as taskRender from 'tp-traqplan-core/dist/timeline/timeline-core/core-tasks';
import * as taskDragHandles from 'tp-traqplan-core/dist/timeline/timeline-core/core-task-drag-handles';
import { EventID, GroupChangeEvent, ShowContextMenuEvent, ShowFloatingInputEvent, ShowGroupContextMenuEvent, TaskChangeEvent, TimelineEvent } from './tl-events';
import { debounce } from 'lodash';
import { ViewLineComponent } from "tp-traqplan-core/dist/timeline/timeline-core/core-viewline";

export class TimelineInteractions {

  private disconnected$ = new Subject<void>();
  private dragTarget: any;
  private dragAction: DragAction | null;
  private dragActive: boolean;
  private dragState: any;

  public layout: TimelineLayout;

  checkEditable: (a: EditCheckParams) => boolean;

  events$ = new Subject<TimelineEvent<any,any>>();

  state$ = new BehaviorSubject<TimelineState>({
    readOnly: false,

    selectedTasks: [],
    selectionLimit: 1,

    groupInteractionTarget: null,
    // target for active taskNode manipulation:
    taskInteractionTarget: null,

    exportActive: false,
    viewportHeight: 0,
    viewportScrollActive: false,
    viewportScrollHeight: 0,
    viewportScrollTranslation: 0,

    viewportSlideActive: false,

    axisBoundaryMode: BOUNDARY_MODE.Auto,
    axisBoundaryRange: [],
    axisViewRange: []
  });

  // this is frequently required for positioning overlaid components
  viewport: SVGElement;

  get state() {
    return this.state$.getValue();
  }

  constructor(layout: TimelineLayout, checkEditable: (p: EditCheckParams) => boolean = () => true) {
    this.layout = layout;
    this.checkEditable = checkEditable;
  }

  disconnect() {
    this.disconnected$.next();
  }

  connected(viewport: SVGElement) {
    this.viewport = viewport;
  }

  getScrollableViewportRect() {
    // in this context we can alway be confident of finding the axis container:
    const axis = this.viewport.querySelector(`.${standardClass.axis}`) as SVGElement;
    const vRect = this.viewport.getBoundingClientRect();
    const aRect = axis.getBoundingClientRect();
    return {
      x: vRect.x,
      y: vRect.y + aRect.height,
      height: vRect.height - aRect.height,
      width: vRect.width
    };
  }

  setReadOnly(readOnly: boolean){
    this.state$.next({ ...this.state, readOnly });
  }

  viewportScroll(translation: number) {
    this.state$.next({
      ...this.state,
      viewportScrollActive: true,
      viewportScrollTranslation: translation
    });
  }
  
  viewportScrollComplete() {
    this.state$.next({
      ...this.state,
      viewportScrollActive: false
    })
  }

  viewportSlide() {
    this.state$.next({
      ...this.state,
      viewportSlideActive: true
    })
  }

  viewportSlideComplete() {
    this.state$.next({
      ...this.state,
      viewportSlideActive: false
    })
  }
  

  clicked(event: MouseEvent) {
    // else check if a click action associated:
    const target = this.getTarget(event.target, `[${standardAttribute.dataClick}]`);
    if (target) {
      switch(target.dataset.tlClick) {
        case ClickAction.Swimlane:
          return this.swimlaneClicked(event, target);
        case ClickAction.Task:
          return this.taskClicked(target);
        case MouseoverAction.CreateGroupSibling:
        case MouseoverAction.CreateChildGroup:
          return this.groupHandleClick(event, target);
      }
    }
  }

  mouseover(event: MouseEvent) {
    const target = this.getTarget(event.target, `[${standardAttribute.dataMouseover}]`);
    const relTarget = this.getTarget(event.relatedTarget, `[${standardAttribute.dataMouseover}]`);

    if (target && target !== relTarget) {
      switch(target?.dataset.tlMouseover) {
        case MouseoverAction.GroupLabel:
          return this.groupLabelMouseover(event, target);
        case MouseoverAction.Task:
          return this.taskMouseover(event, target);
        case MouseoverAction.Swimlane:
          return this.swimlaneMouseover(event, target);
        case MouseoverAction.CreateGroupSibling:
        case MouseoverAction.CreateChildGroup:
          return this.groupHandleMouseover(event, target);
      }
    }
  }

  mouseout(event: MouseEvent) {
    // only execute mouseout behaviours is the pointer has 'truly' left the target area:
    const target = this.getTarget(event.target, `[${standardAttribute.dataMouseover}]`);
    const relTarget = this.getTarget(event.relatedTarget, `[${standardAttribute.dataMouseover}]`);

    if (target && target !== relTarget) {
      switch(target.dataset.tlMouseover) {
        case MouseoverAction.GroupLabel:
          return this.groupLabelMouseout(event, target);
        case MouseoverAction.Task:
          return this.taskMouseout(event, target);
        case MouseoverAction.Swimlane:
          return this.swimlaneMouseout(event, target);
          case MouseoverAction.CreateGroupSibling:
          case MouseoverAction.CreateChildGroup:
            return this.groupHandleMouseout();
      }
    }
  }

  dblClicked(event: MouseEvent) {

    const target = this.getTarget(event.target, `[${standardAttribute.dataDblclick}]`);
    if (target) {
      switch(target.dataset.tlDblclick) {
        case DblClickAction.GroupLabel:
          return this.groupLabelDblclick(event, target);
        case DblClickAction.Task:
          return this.taskDblclick(event, target);
      }
    }

  }

  pointerdown(event: MouseEvent) {

    if (event.ctrlKey || event.shiftKey || event['which'] === 3 || event.button == 2) {
      return;
    }

    const target = this.getTarget(event.target, `[${standardAttribute.dataDrag}]`);
    if (target?.dataset?.tlDrag) {
      switch(target.dataset.tlDrag) {
        case DragAction.Task:
        case DragAction.TaskResize:
          return this.taskPointerDown(event, target);
          case DragAction.GroupWidth:
            return this.groupWidthPointerDown(event, target);
          case DragAction.ViewLine:
            return this.viewLinePointerDown(event, target);
      }
    }
  }

  pointermove = debounce((event: MouseEvent) => {
    return this._pointermove(event);
  }, 10);

  private _pointermove(event: MouseEvent) {
    if (this.dragActive) {
      switch (this.dragAction) {
        case DragAction.Task:
          return this.taskPointerMove(event);
        case DragAction.TaskResize:
          return this.taskResizePointerMove(event);
        case DragAction.GroupWidth:
          return this.groupWidthPointerMove(event);
        case DragAction.ViewLine:
          return this.viewLinePointerMove(event);
      }
    }
  }

  pointerup(event: MouseEvent) {
    if (this.dragActive) {
      switch (this.dragAction) {
        case DragAction.Task:
          this.taskPointerUp(event);
          break;
        case DragAction.TaskResize:
          this.taskResizePointerUp(event);
          break;
        case DragAction.ViewLine:
          this.viewLinePointerUp();
          break;
      }
      this.dragTarget = null;
      this.dragActive = false;
      this.dragAction = null;
    }
  }

  change(changes: PoapChange[], action?: string) {

    const groupChanges = changes.filter(c => (c as PoapGroupFieldChange).group) as PoapGroupFieldChange[];
    const taskChanges = changes.filter(c => (c as PoapTaskFieldChange).task) as PoapTaskFieldChange[];

    if (groupChanges.length) {
      this.events$.next(<GroupChangeEvent>{
        event: 'groupChange',
        data: <ViewGroupChange>{
          revisionId: groupChanges[0].group.revision,
          action: action || '',
          changes: groupChanges
        }
      })
    }

    if (taskChanges.length) {
      this.events$.next(<TaskChangeEvent>{
        event: 'taskChange',
        data: <TaskChange>{
          revisionId: taskChanges[0].task.revision,
          changes: taskChanges
        }
      });
    }
  }

  contextmenu(event: MouseEvent) {

    event.preventDefault();

    const target = this.getTarget(event.target, `[${standardAttribute.dataContextmenu}]`);

    if (target) {
      switch(target?.dataset?.tlContextmenu) {
        case ContextMenuAction.GroupLabel:
          return this.groupContextmenu(event, target);
        case ContextMenuAction.Task:
          return this.taskContextmenu(event, target);
      }
    }
  }

  private groupLabelMouseover(event: MouseEvent, target: SVGElement) {
    if (this.dragActive) return;
    const container = target.closest(`[${standardAttribute.dataGroupUid}]`) as GroupBoundElement;
    if (this.checkEditable({ group: this.layout.getTimelineGroup(container.__fitting__.groupUid) })) {

      this.setTooltip('Dbl click to rename or right click for more options');

      this.events$.next({
        event: 'groupLabelMouseover',
        data: {
          target: container,
          group: container.__fitting__
        }
      });
    }
  }

  private groupLabelMouseout(event: MouseEvent, target: SVGElement) {
    if (this.dragActive) return;
    const container = target.closest(`[${standardAttribute.dataGroupUid}]`) as GroupBoundElement;
    if (this.checkEditable({ group: this.layout.getTimelineGroup(container.__fitting__.groupUid) })) {
      this.setTooltip(null);
      this.events$.next({
        event: 'groupLabelMouseout',
        data: {
          target: container,
          group: container.__fitting__
        }
      });
    }
  }

  private groupLabelDblclick(event: MouseEvent, target: SVGElement) {
    const container = target.closest(`[${standardAttribute.dataGroupUid}]`) as GroupBoundElement;
    if (container && this.checkEditable({ group: this.layout.getTimelineGroup(container.__fitting__.groupUid), alertOnFail: true })) {

      const groupFitting = container.__fitting__;
      const group = this.layout.getTimelineGroup(groupFitting.groupUid);

      this.events$.next(<ShowFloatingInputEvent>{
        event: EventID.ShowFloatingInput,
        data: {
          label: 'Enter group name...',
          validator: value => {
            if (!value?.length) {
              return 'Enter a new group name';
            }
            if (groupFitting.parent?.children.some(g => g.groupUid !== group.id && g.text === value)) {
              return 'Sibling groups cannot have the same name'
            }
            return null;
          },
          complete: value => {
            this.events$.next(<GroupChangeEvent>{
              event: EventID.GroupChange,
              data: <ViewGroupChange>{
                revisionId: group.revision,
                action: `Rename group '${group.name}' to '${value}'`,
                changes: [{
                  group: group,
                  field: 'name',
                  value: value
                }]
              }
            });
          }
        }
      });
    }
  }

  private groupHandleMouseover(event: MouseEvent, target: SVGElement) {
    if (!this.dragActive) {
      const container = target.closest(`[${standardAttribute.dataGroupUid}]`) as GroupBoundElement;
      if (container) {
        const group = this.layout.getTimelineGroup(container.__fitting__.groupUid);
        if (this.checkEditable({ group })) {

          const position = container.__handles__.b === target ? 'sibling' : 'child';

          if (position === 'sibling') {
            this.setTooltip('Click to create sibling group');
          } else {
            this.setTooltip('Click to create child group');
          }

        }
      }
    }
  }

  private groupHandleMouseout() {
    this.setTooltip(null);
  }

  private groupHandleClick(event: MouseEvent, target: SVGElement) {
    if (!this.dragActive) {
      const container = target.closest(`[${standardAttribute.dataGroupUid}]`) as GroupBoundElement;
      if (container) {
        const groupFitting = container.__fitting__;
        const group = this.layout.getTimelineGroup(groupFitting.groupUid);

        if (this.checkEditable({ group, alertOnFail: true })) {

          event.stopPropagation();

          const parent = this.layout.getTimelineGroup(group.parent);
          const isSibling = container.__handles__.b === target;
          const siblings = this.layout.getTimelineGroups(isSibling ? parent.children : group.children);

          this.events$.next(<ShowFloatingInputEvent>{
            event: EventID.ShowFloatingInput,
            data: {
              label: 'Enter group name...',
              validator: value => {
                if (!value?.length) {
                  return 'Enter a new group name';
                }
                if (siblings.some(g => g.id !== group.id && g.name === value)) {
                  return 'Sibling groups cannot have the same name'
                }
                return null;
              },
              complete: value => {
                this.events$.next({
                  event: 'groupChange',
                  data: <ViewGroupChange>{
                    revisionId: group.revision,
                    action: `Create group '${value}'`,
                    changes: [{
                      // the parent group:
                      group: isSibling ? parent : group,
                      field: MetaGroupField.create,
                      value: {
                        name: value,
                        sibling: isSibling ? group : null
                      }
                    }]
                  }
                });
              }
            }
          });
        }
      }
    }
  }

  private groupContextmenu(event: MouseEvent, target: SVGElement) {
    const container = target.closest(`[${standardAttribute.dataGroupUid}]`) as GroupBoundElement;

    if (container?.__fitting__ && this.checkEditable({ group: this.layout.getTimelineGroup(container.__fitting__.groupUid), alertOnFail: true })) {

      this.events$.next(<ShowGroupContextMenuEvent>{
        event: EventID.ShowGroupContextMenu,
        data: {
          target: container,
          event: event
        }
      });

    }
  }

  private taskClicked(target: SVGElement) {
    const container = target.closest(`[${standardAttribute.dataTaskUid}]`) as TaskBoundElement;

    if (container?.__fitting__ && this.checkEditable({ task: container.__fitting__ })) {

      // make it easier for diffing:
      const state = { ...this.state, selectedTasks: [...this.state.selectedTasks] };

      const selected = state.selectedTasks.find(t => t.dataset.taskUid === container.dataset.taskUid);

      if (selected) {
        taskRender.setTaskSelected(container, false);
        state.selectedTasks.splice(state.selectedTasks.indexOf(selected), 1);
      } else {
        if (this.state.selectedTasks.length >= this.state.selectionLimit) {
          taskRender.setTaskSelected(this.state.selectedTasks[0], false);
        }
        taskRender.setTaskSelected(container, true);
        state.selectedTasks.push(container);
      }
      this.state$.next(state);

    }
  }

  private taskMouseover(event, target: SVGElement) {
    
    if (this.dragActive) {
      return;
    }

    const container = target.closest(`[${standardAttribute.dataTaskUid}]`) as TaskBoundElement;

    if (this.dragTarget !== container) {

      // if cannot edit at all:
      if (!this.checkEditable({ task: container.__fitting__ })) {
        return
      }

      // if cannot change boundaries:
      if (!this.checkEditable({ task: container.__fitting__, fields: ['start', 'finish'] })) {
        this.setTooltip('Right click for more options');
        return;
      } 

      this.setTooltip('Drag task to move, or right click for more options');

      const task = container.__fitting__ as TimelineTask;
      if (task) {
        this.state$.next({
          ...this.state,
          taskInteractionTarget: container
        });
        this.dragTarget = container;
        taskDragHandles.displayDragHandles(this.layout, container);
        container.parentNode?.appendChild(container);
      }
    }
  }

  private taskMouseout(event, target: SVGElement) {
    if (this.dragActive) return;
    this.setTooltip(null);
    const container = target.closest(`[${standardAttribute.dataTaskUid}]`) as TaskBoundElement;
    taskDragHandles.removeDragHandles(container);
    this.dragTarget = null;
  }

  private taskPointerDown(event, target: SVGElement) {
    const container = target.closest(`[${standardAttribute.dataTaskUid}]`) as TaskBoundElement;
    const task = container.__fitting__ as TimelineTask;

    if (!this.checkEditable({ task, fields: ['start', 'finish'] })) {
      return;
    }

    const dragType = this.getTarget(event.target, `.${standardClass.taskDragHandleLeft}`) ? TaskDragType.Start 
    : this.getTarget(event.target, `.${standardClass.taskDragHandleRight}`) ? TaskDragType.Finish
    : TaskDragType.Move

    if (task) {
      this.dragActive = true;
      this.dragState = {
        initClientX: event.clientX,
        initClientY: event.clientY,
        vRect: this.viewport.getBoundingClientRect(),
        type: dragType,
        start: task.StartDate,
        finish: task.FinishDate,
        initPosition: null,
        time: Date.now()
      };
      this.dragTarget = container;
      this.dragAction = dragType === TaskDragType.Move ? DragAction.Task : DragAction.TaskResize;

      taskDragHandles.setDragging(container, true);
      // in order to ensure the dragged task is always visible, we hide the original
      // and position a clone above all sibling group elements:
      if (dragType === TaskDragType.Move) {
        // we put the task clone in this element to ensure all styles applied correctly:
        const axisOffsetArea = this.viewport.querySelector(`.${standardClass.axisOffsetArea}`) as SVGGElement;

        const [ posX, posY ] = taskRender.getRelativePosition(this.dragState.vRect, container);
        // as we are putting the task in the 'scrollable area', we need to account for the axis height:
        this.dragState.initPosition = [ posX, posY - this.layout.fittingMeta.axisHeight];

        taskRender.setRelativePosition(container, this.dragState.initPosition[0], this.dragState.initPosition[1]);
        axisOffsetArea?.appendChild(container);
      } 
    }
  }

  private taskPointerMove(event) {
    if (this.dragTarget) {

      this.setTooltip(null);

      const task = this.dragTarget.__fitting__ as TimelineTask;

      const deltaX = event.clientX - this.dragState.initClientX;
      const deltaY = event.clientY - this.dragState.initClientY;

      const xPosition = this.dragState.initPosition[0] + deltaX;
      const yPosition = this.dragState.initPosition[1] + deltaY;

      task.StartDate = this.layout.offsetDate(this.dragState.start, deltaX);
      task.FinishDate = this.layout.offsetDate(this.dragState.finish, deltaX);

      taskDragHandles.updateDragHandles(this.layout, this.dragTarget);
      taskRender.setRelativePosition(this.dragTarget, xPosition, yPosition);
    }
  }

  private taskPointerUp(event) {
    if (this.dragTarget) {
      taskDragHandles.setDragging(this.dragTarget, false);
      taskDragHandles.removeDragHandles(this.dragTarget);

      const task = this.dragTarget.__fitting__;
      const deltaX = event.clientX - this.dragState.initClientX;
      const deltaY = event.clientY - this.dragState.initClientY;
      const delta = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2))

      if (delta < 2 || (Date.now()  - this.dragState.time) < 300) {
        this.taskClicked(this.dragTarget);
        return;
      }

      const changes = task.ProjectTasks.map(t => ({
        task: t,
        field: MetaTaskField.boundaries,
        value: [
          task.StartDate,
          task.FinishDate
        ]
      }));

      if (this.dragState.groupTarget) {

        const groupPath = getGroupNamePath(this.layout.groupMap, this.dragState.groupTarget.groupUid);

        changes.push(...task.ProjectTasks.map(t => ({
          task: t,
          field: 'groupPath',
          value: groupPath
        })));
      }

      this.events$.next({
        event: 'taskChange',
        data: {
          revisionId: task.ProjectTasks[0].revision,
          changes: changes
        }
      });

    }
  }

  private taskResizePointerMove(event) {
    if (this.dragTarget) {

      this.setTooltip(null);

      const task = this.dragTarget.__fitting__ as TimelineTask;
      const deltaX = event.clientX - this.dragState.initClientX;
        
      const startDelta = this.dragState.type & TaskDragType.Start ? deltaX : 0;
      const finishDelta = this.dragState.type & TaskDragType.Finish ? deltaX : 0;

      let startDate = this.layout.offsetDate(this.dragState.start, startDelta);
      let finishDate = this.layout.offsetDate(this.dragState.finish, finishDelta);

      // clamp start date before finish:
      if (startDelta && startDate.getTime() > finishDate.getTime()) {
        startDate = finishDate;
      }
      // clamp finish date after start:
      if (finishDelta && startDate.getTime() > finishDate.getTime()) {
        finishDate = startDate;
      }

      const updatedTask = this.layout.evaluateTaskPosition({
        ...task,
        StartDate: startDate,
        FinishDate: finishDate
      });

      taskRender.updateTaskNode(this.layout, updatedTask, this.dragTarget);
      taskDragHandles.updateDragHandles(this.layout, this.dragTarget);
    }
  }

  private taskResizePointerUp(event) {
    if (this.dragTarget) {

      const container = this.dragTarget as TaskBoundElement;
      const task = container.__fitting__ as TimelineTask;
      const deltaX = event.clientX - this.dragState.initClientX;

      taskDragHandles.setDragging(this.dragTarget, false);
      taskDragHandles.removeDragHandles(this.dragTarget);

      if (Math.abs(deltaX) > 1) {
        this.events$.next(<TaskChangeEvent>{
          event: 'taskChange',
          data: {
            revisionId: task.ProjectTasks[0].revision,
            changes: task.ProjectTasks.map(t => ({
              task: t,
              field: MetaTaskField.boundaries,
              value: [
                task.StartDate,
                task.FinishDate
              ]
            }))
          }
        });
      }
    }
  }

  private taskDblclick(event: MouseEvent, target: SVGElement) {
    const container = target.closest(`[${standardAttribute.dataTaskUid}]`) as TaskBoundElement;
    if (container && this.checkEditable({ task: container.__fitting__ })) {

      const task = container.__fitting__;

      this.events$.next(<ShowFloatingInputEvent>{
        event: EventID.ShowFloatingInput,
        data: {
          label: 'Enter task name...',
          validator: value => value?.length ? null : 'Enter task name',
          complete: value => {
            this.events$.next(<TaskChangeEvent>{
              event: EventID.TaskChange,
              data: <TaskChange>{
                revisionId: task.ProjectTasks[0].name,
                changes: task.ProjectTasks.map(t => (<PoapTaskFieldChange>{
                  task: t,
                  field: task.ProjectTasks[0].rename ? 'rename' : 'name',
                  value: value
                }))
              }
            });
          }
        }
      });
    }
  }

  private taskContextmenu(event: MouseEvent, target: SVGElement) {
    const container = target.closest(`[${standardAttribute.dataTaskUid}]`) as TaskBoundElement;

    if (container?.__fitting__ && this.checkEditable({ task: container.__fitting__, alertOnFail: true })) {

      this.events$.next(<ShowContextMenuEvent>{
        event: 'showContextMenu',
        data: {
          target: container,
          event: event
        }
      });
    }
  }

  private swimlaneMouseover(event: MouseEvent, target: SVGElement) {

    const container = target.closest(`[${standardAttribute.dataGroupUid}]`) as GroupBoundElement;
    
    if (container) {
      // if drag action in progress then register this group as the target to drop task into:
      if (this.dragAction === DragAction.Task) {
        if (container) {
          this.dragState.groupTarget = container.__fitting__ as GroupFitting;
        }
      } else {
        if (this.checkEditable({ group: this.layout.groupMap[container.__fitting__.groupUid] })) {
          this.setTooltip('Click to create a task or alt-click for more options');
        }
      }
    }

  }

  private swimlaneMouseout(event: MouseEvent, target: SVGElement) {
    this.setTooltip(null);
    const container = target.closest(`[${standardAttribute.dataGroupUid}]`) as GroupBoundElement;
    if (this.dragActive && this.dragState?.groupTarget === container.__fitting__) {
      this.dragState.groupTarget = null;
    }
  }

  private swimlaneClicked(event: MouseEvent, target: SVGElement) {
    const container = target.closest(`[${standardAttribute.dataGroupUid}]`) as GroupBoundElement;
    const fitting = container.__fitting__ as GroupFitting;
    const group = this.layout.groupMap[fitting?.groupUid];
    if (group) {
      const editable = this.checkEditable({ group, alertOnFail: true });
      if (editable) {
        const start = timeDay.floor(this.layout.timeScale.invert(event.offsetX));
        const finish = timeWeek.offset(start, 1);
        const groupPath = getGroupNamePath(this.layout.settings.groupMap, group.id);
        // click to create task, ctrl click to open task creation modal:
        if (event.altKey) {
          event.preventDefault();
          this.events$.next({
            event: 'taskModalOpen',
            data: {
              start, finish, groupPath,
              revisionId: group.revision,
              sibling: group.tasks[group.tasks.length - 1] || this.layout.fittingMeta.poapTasks[this.layout.fittingMeta.poapTasks.length-1]
            }
          })
        } else {
          this.events$.next({
            event: 'taskChange',
            data: {
              revisionId: group.revision,
              changes: [{
                task: group.tasks[group.tasks.length - 1] || this.layout.fittingMeta.poapTasks[this.layout.fittingMeta.poapTasks.length-1],
                field: MetaTaskField.add,
                value: {
                  start, finish, groupPath,
                  group: group.id,
                  selected: true
                }
              }]
            }
          });
        }
      }
    }
  }

  private viewLinePointerDown(event, target) {
    const container = target.closest('[data-view-line-id]') as DataBoundElement;

    if (container?.__fitting__) {

      this.dragTarget = container;
      this.dragAction = DragAction.ViewLine;
      this.dragActive = true;

      this.dragState = {
        initClientX: event.clientX,
        initX: container.__fitting__.position,
        viewLine: container.__fitting__.fitting as ViewLineFitting
      };
    }

  }

  private viewLinePointerMove(event) {
    if (this.dragTarget) {

      const viewline = this.dragTarget.__fitting__ as ViewLineComponent;
      const delta = event.clientX - this.dragState.initClientX;
      const position = this.dragState.initX + delta;

      const [min, max] = this.layout.timeScale.range()

      const clamped = Math.min(Math.max(min, position), max);

      viewline.setPosition(clamped);
    }
  }

  private viewLinePointerUp() {
    if (this.dragTarget) {

      const position = this.dragTarget.__fitting__.position;
      const date = this.layout.timeScale.invert(position);
      const viewLines = [...this.layout.settings.viewLines];
      const index = viewLines.findIndex(v => v.id === this.dragState.viewLine.id);

      if (~index) {

        viewLines[index] = {
          ...viewLines[index],
          date: date
        };

        this.events$.next({
          event: 'settingsChange',
          data: <Partial<TimelineOptions>>{ viewLines }
        });
      }

    }
  }

  private groupWidthPointerDown(event, target) {
    const container = target.closest('#group-drag');

    if (container) {
      this.dragTarget = container;
      this.dragAction = DragAction.GroupWidth;
      this.dragActive = true;

      this.dragState = {
        initClientX: event.clientX,
        initX: this.layout.settings.groupsWidth
      };
    }
  }

  private groupWidthPointerMove(event) {
    if (this.dragTarget) {

      const delta = event.clientX - this.dragState.initClientX;
      const position = this.dragState.initX + delta;
      const clamped = Math.min(Math.max(this.layout.fittingMeta.minimumGroupWidth, position), this.layout.fittingMeta.maximumGroupWidth);

      this.events$.next({
        event: 'settingsChange',
        data: <Partial<TimelineOptions>>{ groupsWidth: clamped }
      });
    }
  }

  private getTarget(target: any, selector: string, deep = true): DataBoundElement|null {
    if (target?.matches(deep  ? `${selector}, ${selector} *` : selector)) {
      return target.closest(selector) as DataBoundElement;
    }
    return null;
  }

  private setTooltip(message: string|null) {
    this.events$.next({
      event: 'setTooltipMessage',
      data: {
        tooltip: message || null
      }
    });
  }

}