import { TimelineLayout } from '../timeline-layout';
import * as tlStyle from '../tl-style';
import { createSVGElement} from '../../svg-utils';

const { tickMarker } = tlStyle.standardClass;

export type MarkerMap = Map<string,SVGLineElement>;

export function renderMarkers( layout: TimelineLayout, root:SVGGElement, preMap: MarkerMap = new Map() ): MarkerMap {

  if (!layout.axis) {
    return preMap;
  }

  const nMap: MarkerMap = new Map();

  if (!layout.settings.viewTickLines) {
    for (const [, nLine] of preMap) {
      nLine.remove();
    }
    return nMap;
  }
  const [ ticks ] = layout.axis.ticks;

  const divs = !ticks?.length ? [] : [ [ ticks[0].start, `s_${ticks[0].date}` ], ...ticks.map(tick => [ tick.finish, `f__${tick.date}` ]) ];

  for (let i = 0; i < divs.length; i++) {

    const [ position, lineID ] = divs[i];

    const nLine = updateLine(preMap.get(lineID as string) || lineFactory(), { position });

    nMap.set(lineID as string, nLine);

    (nLine.parentNode !== root) && root.appendChild(nLine);

  }

  // remove discarded elements:
  for (const e of preMap) {
    if (!nMap.has(e[0])) {
      e[1].remove();
    }
  }

  return nMap;

}

function lineFactory(): SVGLineElement {
  const nLine = createSVGElement('line') as SVGLineElement;
  nLine.setAttribute('class', tickMarker);
  nLine.setAttribute('y1', '0');
  nLine.setAttribute('y2', '100%');
  return nLine;
}

function updateLine(nLine: SVGLineElement, { position }): SVGLineElement {
  nLine.setAttribute('transform', `translate(${ position }, 0)`);
  return nLine;
}
