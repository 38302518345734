import { TextWrapping } from './tl-wrapping';
import { MilestoneCategory, LegendFittingMode } from '../data-structs';
import { LegendOptions, LegendItem } from './tl-structs';

export class TimelineLegend {

  public settings: LegendOptions;
  public totalHeight: number;
  public columnWidth: number;
  public columnCount: number;
  public iconWidth: number;
  public iconHeight: number;
  public textWidth: number;
  public minWidth: number;
  public legendItems: LegendItem[];

  private textWrapping: TextWrapping;
  private categories: MilestoneCategory[];

  constructor() {

    this.textWrapping = new TextWrapping();

  }

  update(options: LegendOptions): void {

    this.settings = Object.assign(this.settings || {}, options);

    this.settings.fittingMode = this.settings.fittingMode || LegendFittingMode.compressed;

    /** check then remove - this filtering is done on front end, shouldn't be required here */
    this.categories = this.settings.categories.filter(cat => !cat.deleted);

    this.textWrapping.setFontSize(this.settings.fontSize);

    this.updateBaseDimensions();

    if (this.settings.fittingMode !== LegendFittingMode.grid) {
      this.createCompressedLayout();
    } else {
      this.createFixedGridLayout();
    }

  }

  private updateBaseDimensions(): void {

    this.columnCount = Math.min(this.categories.length, Math.floor(this.settings.width / this.settings.columnMinWidth));
    this.columnWidth = this.settings.width / this.columnCount;

    this.iconWidth = this.settings.fontSize * 1.15;
    this.iconHeight = this.iconWidth;
    // used for compressed layout:
    this.minWidth = this.iconWidth * 3;
    // used for grid layout:
    this.textWidth = Math.max(0, this.columnWidth - this.iconWidth - this.settings.columnPadding - this.settings.textPadding);

  }

  /**
   * Squeezes as many legend items into each row as possible regardless of column alignment, minimising unused space.
   */
  private createCompressedLayout(): void {

    const relTextStart = this.iconWidth + this.settings.columnPadding + this.settings.textPadding;
    const relIconStart = this.settings.columnPadding;
    const rowHeight = this.getRowHeight(1);
    const legendItems: LegendItem[] = [];

    let rowIndex = 0;
    let rowWidth = 0;

    for (let i = 0; i < this.categories.length; i++) {
      const category = this.categories[i];
      let text: string[];
      let xPos: number;
      let yPos: number;


      const itemWidth = relTextStart + this.textWrapping.measureText(category.name) + this.settings.textPadding;
      // if within the viewport then no wrapping necessary:
      if (itemWidth + rowWidth <= this.settings.width) {
        text = [category.name];
        xPos = rowWidth;
        rowWidth += itemWidth;
        yPos = rowHeight * rowIndex;
      // wrap text to the next row:
      } else {
        // if the remaining width less than minWidth then wrap entire legend item:
        const availableWidth = this.settings.width - rowWidth - relTextStart;
        if (availableWidth < this.minWidth) {
          text = [category.name];
          xPos = 0;
          rowWidth = itemWidth;
          rowIndex++;
          yPos = rowHeight * rowIndex;
        // else wrap remaining text onto next line:
        } else {
          text = this.textWrapping.wrapText(category.name, availableWidth, 1);
          xPos = rowWidth;
          yPos = rowHeight * rowIndex;
          // add all overflowing text as a single line:
          text.push(category.name.slice(text[0].length));

          rowWidth = this.textWrapping.measureText(text[1]) + this.settings.textPadding;
          rowIndex++;
        }
      }

      legendItems.push(<LegendItem>{
        relTextStart,
        relIconStart,
        text: text,
        rowStart: yPos,
        colStart: xPos,
        rowHeight: rowHeight,
        symbol: category.symbol,
        colour: category.colour
      });

    }

    this.legendItems = legendItems;
    this.totalHeight = rowHeight * (rowIndex + 1) + this.settings.rowPadding;

  }

  /**
   * Fits legend items to a preset grid - neater but less space efficient:
   */
  private createFixedGridLayout(): void {

    const rowHeights: number[] = [];
    const legendItems: LegendItem[] = [];

    const relTextStart = this.iconWidth + this.settings.columnPadding + this.settings.textPadding;
    const relIconStart = this.settings.columnPadding;

    for(let i = 0; i < this.categories.length; i++) {
      const category = this.categories[i];
      const row = Math.floor(i / this.columnCount);
      const text = this.wrapCellText(category.name);
      const cellHeight = this.getRowHeight(text.length);

      rowHeights[row] = Math.max(rowHeights[row] || 0, cellHeight);

      legendItems.push(<LegendItem>{
        relTextStart,
        relIconStart,
        text: text,
        rowStart: rowHeights.slice(0, Math.max(row,0)).reduce((a,c) => a + c,0),
        rowHeight: rowHeights[row],
        colStart: (i % this.columnCount) * this.columnWidth + this.settings.columnPadding,
        symbol: category.symbol,
        colour: category.colour
      });
    }

    this.legendItems = legendItems;
    this.totalHeight = rowHeights.reduce((a,c) => a + c,0) + this.settings.rowPadding;
  }

  private wrapCellText(text: string): string[] {
    return this.textWrapping.wrapText(text, this.textWidth, this.settings.cellMaxLines);
  }

  private getRowHeight(lineCount: number): number {
    return (lineCount * this.settings.fontSize) + this.settings.rowPadding;
  }

}
