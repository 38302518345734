import { Staff } from 'tp-traqplan-core/dist/data-structs';
import { RequestError } from 'src/app/api/structs';

export const key = 'tps';

export interface State {
  token: string;
  staff: Staff;
  loginError: RequestError;
  pending: boolean;
}

export const initialState = {
  token: null,
  staff: null,
  loginError: null,
  pending: false
};

export function beforeSync(state) {
  return {
    ...state,
    pending: false
  };
}

export function beforeRehydrate(state) {
  return state;
}
