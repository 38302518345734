import { Component, OnInit, OnDestroy, HostListener, ComponentFactoryResolver, Type, ViewContainerRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, take, tap, map, filter, pluck, distinctUntilChanged } from 'rxjs/operators';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { modalComponents } from './modals';
import { ModalService } from './modals/modal.service';
import { InfoBoxInstance } from './modals/structs';
import { State } from './state';
import * as globalActions from './state/actions';
import * as authActions from './state/auth/actions';
import { State as AuthState } from './state/auth/state';
import * as modalActions from './state/modal/actions';
import { StateModal } from './state/structs';
import { NotificationsService } from './api/notifications.service';

// maximum milliseconds of inactivity before timeout:
const maxInactivity = 1000 * 60 * 12 * 10;
// const maxInactivity = 30000;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild('modalContainer', { read: ViewContainerRef }) modalContainer: ViewContainerRef;

  infoBoxInstance: InfoBoxInstance;
  infoBoxVisible: boolean = false;

  modalComponent = null;

  auth$: Observable<AuthState>;
  showLoading: boolean = false;

  private lastActive: number = Date.now();
  private inactivityTimer: number;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store<State>,
    private componentFactoryResolver: ComponentFactoryResolver,
    private modalService: ModalService,
    private notificationsService: NotificationsService
  ) {}

  @HostListener('document:visibilitychange', ['$event'])
  onVisibilityChange() {
    const remaining = maxInactivity - (Date.now() - this.lastActive);
    // if max inactive time has passed then logout immediately:
    if (remaining < 0) {
      this.store.dispatch(authActions.logout({}));
    } else if (remaining <= 60000) {
    // if less than 60 seconds left before max inactvitiy then show warning:
      this.displayInactivityWarning(remaining);
    }
  }

  @HostListener('document:click', ['$event'])
  onMouseMove() {
    this.resetInactivity();
  }

  @HostListener('window:beforeunload')
  private beforeUnload() {
    this.store.dispatch(
      globalActions.unload()
    );
  }

  @HostListener('mouseup')
  private onMouseup() {
    const poapResize = document.getElementById('poap-resize');

    if (poapResize) {
      poapResize.dispatchEvent(new MouseEvent('mouseup'));
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit() {

    this.auth$ = this.store.pipe(
      map(s => s.auth),
      distinctUntilChanged()
    );

    this.auth$.pipe(
      takeUntil(this.destroy$),
      map(s => !!(s.token && s.user)),
      distinctUntilChanged(),
      tap(isAuthenticated => {
        if (isAuthenticated) {
          this.resetInactivity();
          this.notificationsService.startPollingNotifications();
        } else {
          this.notificationsService.stopPollingNoticiations();
        }
      })
    ).subscribe();

    this.modalService.info$.pipe(
      takeUntil(this.destroy$),
      tap((instance: InfoBoxInstance) => {
        this.infoBoxInstance = instance;
        this.infoBoxVisible = true;
      })
    ).subscribe();

    this.store.pipe(
      take(1),
      filter(({ auth }) => !auth.pending),
      tap(({ auth }) => {
        if (auth.token) {
          this.store.dispatch(authActions.verifyUserCredentials())
        }
      })
    ).subscribe();

    this.store.pipe(
      takeUntil(this.destroy$),
      pluck('modal'),
      distinctUntilChanged((a, b) => a.active === b.active),
      tap(({ active, inputs }) => modalComponents[active] ? this.generateModal(modalComponents[active], inputs) : this.closeModal())
    ).subscribe();

    this.store.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      this.showLoading = state?.app?.loading || false;
    });

  }

  infoBoxClose(action?: ModalWindowAction) {
    this.infoBoxInstance.resolve(action);
    this.infoBoxVisible = false;
  }

  private resetInactivity() {

    this.inactivityTimer && clearTimeout(this.inactivityTimer);

    this.inactivityTimer = window.setTimeout(() => {
      this.store.pipe(
        take(1),
        pluck('auth'),
        distinctUntilChanged(),
        tap(auth => {
          if (auth.token && auth.user) {
            this.displayInactivityWarning();
          }
        })
      ).subscribe();

    }, maxInactivity - 60000);
  }

  displayInactivityWarning(duration = 60000) {
    this.store.dispatch(
      modalActions.generateModal({
        modal: StateModal.inactivityWarning,
        inputs: { duration }
      })
    );
  }

  private generateModal(component: Type<any>, inputs) {
    this.modalComponent && this.closeModal();
    this.modalComponent = this.modalContainer.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(component)
    );
    if (inputs !== null && typeof inputs === 'object') {
      Object.entries(inputs).forEach(([k, v]) => {
        this.modalComponent.instance[k] = v;
      });
    }
  }

  private closeModal() {
    if (this.modalComponent) {
      this.modalContainer.remove(0);
      this.modalComponent = null;
    }
  }

  refresh() {
    window.location.reload();
  }
}
