import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CustomerApiService } from '../../../api/power-bi/customer-api.service';
import { RequestError } from '../../../api/structs';
import * as customersActions from './actions';

@Injectable()
export class CustomerEffects {

  constructor(
    private actions$: Actions,
    private customerApi: CustomerApiService
  ) {}

  requestCustomers$ = createEffect(() => this.actions$.pipe(
    ofType(customersActions.requestCustomers),
    switchMap((): Observable<Action> => {
      return this.customerApi.requestCustomers().pipe(
        switchMap(({ customers }: any) => [
          customersActions.requestCustomersSuccess(<any>{ customers })
        ]),
        catchError((error: RequestError) => {
          return of(customersActions.requestCustomersFailure({ error }))
        })
      );
    })
  ));

  requestCustomerById$ = createEffect(() => this.actions$.pipe(
    ofType(customersActions.requestCustomerById),
    switchMap(({ id }): Observable<Action> => {
      return this.customerApi.requestCustomerById(id).pipe(
        switchMap((customer) => [
          customersActions.requestCustomerByIdSuccess(<any>{ customer })
        ]),
        catchError((error: RequestError) => {
          return of(customersActions.requestCustomerByIdFailure({ error }))
        })
      );
    })
  ));

}
