import { createAction, props } from '@ngrx/store';
import { RequestError } from '../../../api/structs';
import * as PowerBi from '../../../powerbi-data-structs';

import Customer = PowerBi.Customer;
import CustomerId = PowerBi.CustomerId;

export const requestCustomers = createAction(
  '[powerbi.customer] requestCustomers',
  props<any>()
);

export const requestCustomersSuccess = createAction(
  '[powerbi.customer] requestCustomersSuccess',
  props<{ customers: Customer[] }>()
);

export const requestCustomersFailure = createAction(
  '[powerbi.customer] requestCustomersFailure',
  props<{ error: RequestError }>()
);

export const requestCustomerById = createAction(
  '[powerbi.customer] requestCustomerById',
  props<{ id: CustomerId }>()
);

export const requestCustomerByIdSuccess = createAction(
  '[powerbi.customer] requestCustomerByIdSuccess',
  props<{ customer: Customer }>()
);

export const requestCustomerByIdFailure = createAction(
  '[powerbi.customer] requestCustomerByIdFailure',
  props<{ error: RequestError }>()
);
