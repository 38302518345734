import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { Subscription, SubscriptionId } from 'tp-traqplan-core/dist/data-structs';
import { AuthApiService } from './auth-api.service';
import { StaffApiService } from './staff-api.service';
import { defaultPipe } from './common';
import { ReqHeader } from './structs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionApiService {

  latest$ = new ReplaySubject<Subscription>(1);

  constructor(
    private http: HttpClient,
    private authApi: AuthApiService,
    private staffApi: StaffApiService
  ) { }

  getPriceList() {
    return this.authApi.headers({ requires: ReqHeader.user }).pipe(
      switchMap(headers => this.http.get<any>('/api/subscriptions/price-list', { headers })),
      defaultPipe()
    );
  }

  verify() {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => this.http.get<any>('/api/subscriptions/verify', { headers })),
      defaultPipe()
    );
  }

  /**
   * Returns the URL to navigate to for checkout completion with stripe
   */
  createCheckoutSession(priceIds: string[], quantity: number): Observable<{ url: string }> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => this.http.post<any>('/api/subscriptions/checkout-session', {
        priceIds, quantity
      }, { headers })),
      defaultPipe()
    );
  }

  createPortalSession(): Observable<{ url: string }> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => this.http.get<any>('/api/subscriptions/portal-session', { headers })),
      defaultPipe()
    );
  }

  updateSubscription(priceIds: string[], quantity: number): Observable<Subscription> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => this.http.post<any>('/api/subscriptions/update', {
        priceIds, quantity
      }, { headers })),
      defaultPipe()
    );
  }

  /**
   * Get the latest subscription for the account:
   */
  getLatest() {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<Subscription> => {
        return this.http.get<Subscription>(
          '/api/subscriptions/latest',
          { headers }
        ).pipe(
          defaultPipe(),
          tap(subscription => {
            this.latest$.next(subscription)
          })
        );
      })
    );
  }

  /**
   * Will return 'safe' information about the saved method of payment. 
   */
  getPaymentMethod() {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<Subscription> => {
        return this.http.get<Subscription>(
          '/api/subscriptions/payment-method',
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  getAll() {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ subscriptions: Subscription[] }> => {
        return this.http.get<{ subscriptions: Subscription[] }>(
          '/api/subscriptions',
          { params: {}, headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  update({
    subscription, subscriptionDetails, userTncAcceptance, upgradeFrom, upgradePrice, paypalOrderId, paypalSubscriptionId, expiredAt
  }) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.patch<{ subscription: Subscription }>(
          '/api/subscriptions',
          {
            subscription, subscriptionDetails, userTncAcceptance, upgradeFrom, upgradePrice, paypalOrderId, paypalSubscriptionId, expiredAt
          },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  staffRequestSubscriptions() {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.get<{ subscriptions: Subscription[] }>(
          '/api/staff/subscriptions',
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  staffRequestSubscriptionById(id: SubscriptionId) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.get<{ subscription: Subscription }>(
          `/api/staff/subscriptions/${id}`,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  staffCancelSubscriptionById(id: SubscriptionId, options) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.patch<{ subscription: Subscription }>(
          `/api/staff/subscriptions/${id}/cancel`,
          options,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

}
