import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-expand-icon',
  templateUrl: './expand-icon.component.html',
  styleUrls: ['./expand-icon.component.scss', '../style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandIconComponent {

  @Input() expanded: boolean;

}
