import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { HierarchyNode, PoapTask } from 'tp-traqplan-core/dist/data-structs';
import { TimelineEventChannel } from 'tp-traqplan-core/dist/timeline/timeline-event-channel';
import * as timelineRender from 'tp-traqplan-core/dist/timeline/timeline-render';
import { GroupBoundElement, GroupFitting } from 'tp-traqplan-core/dist/timeline/tl-structs';
import { HierarchyApiService } from '../../../api/hierarchy-api.service';
import { ModalService } from '../../../modals/modal.service';
import { State } from '../../../state';
import * as appActions from '../../../state/app/actions';
import * as modalActions from '../../../state/modal/actions';
import * as poapActions from '../../../state/poap/actions';
import * as projectsActions from '../../../state/projects/actions';
import { StateModal } from '../../../state/structs';
import { sortRevision } from '../../../util/common';
import { TimelineCoreComponent } from '../timeline-core/timeline-core.component';
import { TimelineLayout } from 'tp-traqplan-core/dist/timeline/timeline-layout';

@Component({
  selector: 'app-publish-indicator',
  templateUrl: './publish-indicator.component.html',
  styleUrls: ['./publish-indicator.component.scss']
})
export class PublishIndicatorComponent implements OnInit {
  @Input() layout: TimelineLayout;
  @Input() eventChannel: TimelineEventChannel;
  @HostListener('mouseover')
  onMouseOver() {
    this.isMouseOver = true;
  }
  @HostListener('mouseout')
  onMouseOut() {
    this.isMouseOver = false;
  }

  isDraft: boolean = false;
  styleLeft: string;
  styleTop: string;
  projectContent: any= null;

  private hostElement: HTMLElement;
  private isInBound: boolean = false;
  private isMouseOver: boolean = false;
  private task?: PoapTask;
  private core?: TimelineCoreComponent;
  private rootGroupNode?: HTMLElement | null;

  constructor(
    element: ElementRef, private store: Store<State>, private hierarchyApi: HierarchyApiService, private modalService: ModalService
  ) {
    this.hostElement = element.nativeElement;
  }

  ngOnInit(): void {
    this.eventChannel.subscribe('groupContainer__mouseover', this.handleMouseover.bind(this));
    this.eventChannel.subscribe('groupContainer__mouseout', this.handleMouseout.bind(this));
    this.eventChannel.subscribe('viewport__scrolling', this.handleVerticalScrolling.bind(this));
  }

  onClick(): void {
    if (!(this.task && this.task.id)) return;
    this.store.pipe(take(1)).subscribe((state) => {
      this.hierarchyApi.getTree({ root: state.account.root.selected.id }).subscribe((nodes: HierarchyNode[]) => {
        this.store.dispatch(projectsActions.requestProjectTreeSuccess({ nodes, viewId: state.poap.viewSelected?.id }));
        const { project: projectId, revision: revisionId } = <PoapTask>this.task;
        const project = nodes.find(({ id }) => id === projectId);
        const revisions = project && Array.isArray(project?.revisions) ? [...project.revisions] : [];
        const fromRevisionIdx = revisions.findIndex(({ id }) => id === revisionId);
        const fromRevision = revisions[fromRevisionIdx];
        this.store.dispatch(projectsActions.selectProjectTreeRevisions({ select: true, revisions: [fromRevision] }));
        this.store.dispatch(poapActions.removeSelectedRevisions({ selectedRevisions: [fromRevision] }));

        if (this.isDraft) {
          this.store.dispatch(modalActions.generateModal({ modal: StateModal.labelRevision, inputs: { revision: fromRevision } }));
        } else {
          const viewRevisions = revisions.filter(({ view }) => view === null || view === state.poap.viewSelected?.id).sort(sortRevision).reverse();
          const fromRevisionIdx = viewRevisions.findIndex(({ id }) => id === revisionId);
          const fromRevision = viewRevisions[fromRevisionIdx];
          const isEarlierRevision = fromRevisionIdx > 0;
          const createDraftFunc = (confirmParams?) => {
            this.store.dispatch(appActions.isDraftCreating({ isDraftCreating: true }));
            this.store.dispatch(appActions.isDraftCreated({ isDraftCreated: false }));
            this.store.dispatch(appActions.showLoading());
            this.store.dispatch(poapActions.createDraft(
              {
                from: confirmParams?.latestRevision?.id || fromRevision?.id,
                project: project?.id || '',
                view: state.poap.viewSelected?.id
              }
            ));
          };

          if (isEarlierRevision) {
            setTimeout(() => {
              this.modalService
                .info({
                  header: 'Warning, editing older revision',
                  message: `You are about to edit an older revision (v${fromRevision?.projectVersion}.${fromRevision?.viewVersion}) of this project "${project?.name}". Are you sure you want to proceed?`,
                  actions: [
                    {
                      label: 'Cancel',
                      type: 'neutral',
                      cancel: true,
                      escKey: true
                    },
                    {
                      label: 'Proceed with latest revision',
                      type: 'positive',
                      confirm: true,
                      confirmParams: { latestRevision: revisions[0] }
                    },
                    {
                      label: `Proceed with older revision (v${fromRevision?.projectVersion}.${fromRevision?.viewVersion})`,
                      type: 'positive',
                      confirm: true
                    }
                  ]
                }).then((action: ModalWindowAction) => {
                  if (action.confirm) {
                    createDraftFunc(action.confirmParams);
                  }
                });
            });
          } else {
            createDraftFunc();
          }
        }
      });
    });
  }

  // traverse recursively until the root group is found
  private getParentRecursively(fitting: Partial<GroupFitting>): Partial<GroupFitting> {
    const { parent } = fitting;

    if (parent) {
      return this.getParentRecursively(parent);
    }

    return fitting;
  }

  // traverse recursively until any task is found
  private getTaskRecursively(fitting: Partial<GroupFitting>): PoapTask | undefined {
    if (Array.isArray(fitting.tasks)) {
      const { children, tasks } = fitting;
      const task = tasks[0];

      if (task && task.id) {
        return task;
      } else {
        if (Array.isArray(children)) {
          for (let i = 0; i < children.length; i++) {
            return this.getTaskRecursively(children[i]);
          }
        }
      }
    }
  }

  private handleMouseover({ core, target }: { core: TimelineCoreComponent, target: HTMLElement }): void {

    const groupContainer = target.closest('.group-container[data-tl-group-uid]');
    this.isInBound = (
      (
        target.tagName === 'rect' &&
        ['group-rect', 'group-background', 'tl-task__background'].some((className) => target.classList.contains(className))
      ) ||
      target.tagName === 'tspan'
    );
    // if (!this.isDraft) {
    //   this.hostElement.style.display = 'none';
    // } else {
      this.hostElement.style.display = 'none';
    // }
    this.task = undefined;
    this.core = undefined;
    this.rootGroupNode = undefined;

    if (groupContainer) {
      this.store.pipe(take(1)).subscribe((state) => {
        const targetGroupContainer = <unknown>groupContainer as GroupBoundElement;
        const targetGroupFitting = (targetGroupContainer as GroupBoundElement)?.__fitting__ as GroupFitting;

        if (targetGroupFitting) {
          const rootGroup = this.getParentRecursively(targetGroupFitting);
          const rootGroupContainer = groupContainer.parentNode?.querySelector(`.group-container[data-tl-group-uid="${rootGroup.groupUid}"]`);
          const rootGroupFitting = (rootGroupContainer as GroupBoundElement).__fitting__ as GroupFitting;
          const task = this.getTaskRecursively(rootGroupFitting);
          this.core = core;
          this.rootGroupNode = core.svgContainer.querySelector(`.group-container[data-tl-group-uid="${rootGroup.groupUid}"] .group-rect.group-hover-space`);
          this.handleReposition(core, core.svgContainer.querySelector(`.group-container[data-tl-group-uid="${rootGroup.groupUid}"] .group-rect.group-hover-space`));

          if (task) {
            const revision = state.poap.revisions.value[task.revision];
            this.isDraft = !revision.published;
            this.task = task;
          }

        }

        this.hostElement.style.display = 'block';
      });
    }
  }

  private handleMouseout(): void {
    this.isInBound = false;
    const timeout = setTimeout(() => {
      if (!this.isInBound && !this.isMouseOver) {
        // if (!this.isDraft) {
        //   this.hostElement.style.display = 'none';
        // } else {
          this.hostElement.style.display = 'none';
          // if(this.task){
          //   this.lastShown = this.task;
          // }
        // }
        this.task = undefined;
        clearTimeout(timeout);
      }
    }, 50);
  }

  private handleReposition(core: TimelineCoreComponent, rootGroupNode?: HTMLElement | null): void {
    const groupHeight = rootGroupNode?.getAttribute('height')
    let topAdjuster = 1;
    if(Number(groupHeight)>25){
      topAdjuster = 8;
    }
    if (core && rootGroupNode) {
      const bRect = rootGroupNode.getBoundingClientRect();
      const sRect = timelineRender.getScrollableViewportBRect(core);
      const left = Math.max(bRect.x, sRect.x) + 8;
      const top = (bRect.y > sRect.y ? bRect.y : sRect.y) + topAdjuster;
      this.styleLeft = `${left}px`;
      this.styleTop = `${top}px`;
    }
  }

  private handleVerticalScrolling(): void {
    if (this.core && this.rootGroupNode) {
      this.handleReposition(this.core, this.rootGroupNode);
    }
  }
}
