import { GroupHeaderOptions } from './tl-structs';
import { TextWrapping } from './tl-wrapping';

export class TimelineGroupHeaders {

  public labels: string[][];
  public totalHeight: number;
  public labelOuterWidth: number;
  public labelInnerWidth: number;

  public settings: GroupHeaderOptions;

  public hPadding: number;
  public vPadding: number;
  public lineHeight: number;

  private textWrapping = new TextWrapping();

  update(options: GroupHeaderOptions): void {

    if (options.fontSize !== this.settings?.fontSize) {
      this.textWrapping.setFontSize(options.fontSize);
    }

    this.settings = { ...options };

    // scale padding according to font size:
    this.hPadding = options.fontSize / 4;
    this.vPadding = options.fontSize / 8;
    this.lineHeight = options.fontSize + this.vPadding * 2;

    this.layoutLabels();

    console.log('group header settings', this.settings);
    console.log('hPadding', this.hPadding, 'vPadding', this.vPadding, 'lineHeight', this.lineHeight, 'labelOuterWidth', this.labelOuterWidth);
    console.log('labels', this.labels);

  }

  private layoutLabels(): void {

    this.labelOuterWidth = this.settings.width / this.settings.columnCount;
    this.labelInnerWidth = this.labelOuterWidth - (this.hPadding * 2);

    this.labels = [];

    for (let i = 0; i < this.settings.columnCount; i++) {
      this.labels.push(this.textWrapping.wrapText(this.settings.labels[i] || '', this.labelInnerWidth, 3, '..'));
    }

    this.totalHeight = Math.max(...this.labels.map(l => l.length)) * this.lineHeight;

  }

}