import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/state';
import * as modalActions from 'src/app/state/modal/actions';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent {

  actions: ModalWindowAction[] = [
    {
      label: 'Okay',
      type: 'neutral'
    }
  ];

  visible = true;

  constructor(
    private store: Store<State>
  ) {}

  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }

}
