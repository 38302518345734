/**
 * @param {PoapTask[]} poapTasks
 */
exports.parse = function (poapTasks) {
  // the parsing method requires that tasks are sorted in order of outline number:
  const sorted = poapTasks.slice(0).sort((a, b) => exports.compare(a, b));
  const path = [];
  // let prevON = [];

  for (const pT of sorted) {
    // task is either first task or 'indented' from previous:
    if (pT.outlineNumber.length > path.length) {
      path.push(pT.id);
      // task is sibling of previous:
    } else
      if (pT.outlineNumber.length === path.length) {
        path[path.length - 1] = pT.id;
        // task is 'outdented' from previous:
      } else
        if (pT.outlineNumber.length < path.length) {
          path.length = pT.outlineNumber.length - 1;
          path.push(pT.id);
        }
    pT.path = path.slice(0);
  }

  return sorted;

};

exports.compare = function (a, b) {
  const aNum = a.outlineNumber;
  const bNum = b.outlineNumber;

  const minLen = Math.min(aNum.length, bNum.length);

  for (let i = 0; i < minLen; i++) {
    if (aNum[i] !== bNum[i]) {
      return aNum[i] - bNum[i];
    }
  }
  return aNum.length - bNum.length;
}

/**
 * Corrects broken outline numbers. Tasks MUST be sorted according to outline number before use!
 * @param {PoapTask[]} poapTasks
 * @param {number} start - index at which to start
 * @param {boolean} breakOnCorrect - if true, return when a correct outline number is found (not including the first one).
 * @param {boolean} mutateTasks - if true, mutate the poap task objects rather than replace them
 */
exports.correct = function (poapTasks, start = 0, breakOnCorrect = false, mutateTasks = false) {
  const values = [];
  const shift = [];

  for (let i = start; i < poapTasks.length; i++) {
    const t = poapTasks[i];
    const current = [...t.outlineNumber];
    const shifted = [...shift, ...t.outlineNumber];
    const previous = values[0];

    let correct;

    // outline numbers always begin at [0]:
    if (i === 0) {
      correct = [0];
    } else
      // start index was supplied as non-zero, assume the starting index outline number is correct:
      if (!previous) {
        correct = shifted;
      } else
        // if sibling:
        if (previous.length === shifted.length) {
          //cannot have sibling at uppermost level (i.e [1] is not allowed) so indent:
          if (shifted.length === 1) {
            correct = [...previous, 0];
            // will now need to shift subsequent values to preserve relative indentation:
            shift.push(...previous);
          } else {
            correct = exports.adjust(previous, previous.length - 1, 1);
          }
        } else
          // if indented:
          if (previous.length < shifted.length) {
            correct = [...previous, 0];
            // if outdented:
          } else {
            // cannot outdent all the way back to root (can only one root)
            if (shifted.length === 1) {
              correct = exports.adjust(previous, previous.length - 1, 1);
            } else {
              correct = previous.slice(0, shifted.length);
              correct[correct.length - 1]++;
            }
          }

    values.unshift(correct);

    if (current.join() !== correct.join()) {
      if (mutateTasks) {
        poapTasks[i].outlineNumber = correct;
      } else {
        poapTasks[i] = { ...t, outlineNumber: correct };
      }
    } else if (i > start && breakOnCorrect) {
      break;
    }
  }

  return poapTasks;
};


exports.isRoot = function (poapTask) {
  return (poapTask.outlineNumber.length === 1 && poapTask.outlineNumber[0] === 0);
};

/**
 * relies om poapTasks being correctly ordered!
 */
exports.isParent = function (poapTask, poapTasks) {
  const index = poapTasks.indexOf(poapTask);
  const nextPoapTask = poapTasks[index + 1];
  if (nextPoapTask && nextPoapTask.outlineNumber.length > poapTask.outlineNumber.length) {
    return true;
  }
  return false;
};

exports.isIndentPossible = function (poapTasks, index) {
  const poapTask = poapTasks[index];
  const prevTask = poapTasks[index - 1];
  // cannot indent if already indented from parent:
  if (prevTask && poapTask.outlineNumber.length <= prevTask.outlineNumber.length) {
    return true;
  }
  return false;
};

exports.isOutdentPossible = function (poapTasks, index) {
  const poapTask = poapTasks[index];
  if (poapTask.outlineNumber.length > 2) {
    return true;
  }
  return false;
};

exports.isMoveUpPossible = function (poapTasks, index) {
  // if there is a sibling task above the specified index then the task can be moved up:
  return !!exports.getNext(poapTasks, index, -1);
};

exports.isMoveDownPossible = function (poapTasks, index) {
  // if there is a sibling task below the specified index then the task can be moved down:
  return !!exports.getNext(poapTasks, index, 1);
};

exports.getDescendants = function (poapTasks, index) {
  const poapTask = poapTasks[index];
  const descendants = [];
  // iterate tasks from below index until task is found which is not a descendant:
  for (let i = index + 1; i < poapTasks.length; i++) {
    const t = poapTasks[i];
    if (t.outlineNumber.length <= poapTask.outlineNumber.length) {
      break;
    }
    descendants.push(t);
  }
  return descendants;
};

exports.getParentIndex = function (poapTasks, index) {
  const poapTask = poapTasks[index];

  for (let i = index - 1; i > -1; i--) {
    const t = poapTasks[i];
    if (t.outlineNumber.length < poapTask.outlineNumber.length) {
      return i;
    }
  }
  return null;
};

exports.getParent = function (poapTasks, index) {
  const parentIndex = exports.getParentIndex(poapTasks, index);
  return poapTasks[parentIndex];
};

/**
 * Get all sibling tasks following the specified index
 * INCLUDES CHILD TASKS OF SIBLINGS
 */
exports.getSucceeding = function (poapTasks, index) {
  const poapTask = poapTasks[index];
  const succeeding = [];
  // keep going until you hit a task which is outdented from the original:
  for (let i = index + 1; i < poapTasks.length; i++) {
    const t = poapTasks[i];
    if (t.outlineNumber.length < poapTask.outlineNumber.length) {
      break;
    }
    succeeding.push(t);
  }
  return succeeding;
};

/**
 * Get all sibling tasks before the specified index
 * INCLUDES CHILD TASKS OF SIBLINGS
 */
exports.getPreceding = function (poapTasks, index) {
  const poapTask = poapTasks[index];
  const preceding = [];
  // keep going until you hit a parent task:
  for (let i = index - 1; i > -1; i--) {
    const t = poapTasks[i];
    if (t.outlineNumber.length < poapTask.outlineNumber.length) {
      break;
    }
    preceding.push(t);
  }
  return preceding;
};

/*
 * get the index of the next sibling task (at the same level) in the specified direction:
 */
exports.getNextIndex = function (poapTasks, index, direction) {
  // clamp direction - -1 == up, +1 === down
  const dir = Math.max(-1, Math.min(1, direction));
  const poapTask = poapTasks[index];
  const start = index + dir;

  if (dir === -1 && start < 0) {
    // Cannot move up if it is a project
    return null;
  }

  // iterate tasks in the direction specified until either a sibling or parent is found:
  for (let i = start; i < poapTasks.length; i += dir) {
    const t = poapTasks[i];
    // if task is sibling then return it:
    if (poapTask?.outlineNumber?.length === t?.outlineNumber?.length) {
      return i;
    }
    // if task is outdented then cannot continue:
    if (poapTask?.outlineNumber?.length > t?.outlineNumber?.length) {
      return null;
    }
  }
  return null;
};
/*
 * get the next sibling task (at the same level) in the specified direction:
 */
exports.getNext = function (poapTasks, index, direction) {
  const nextIndex = exports.getNextIndex(poapTasks, index, direction);
  return typeof nextIndex === 'number' ? poapTasks[nextIndex] : null;
};

/*
 * adjust a number in the outlineNumber array without worrying about the consequences:
 */
exports.adjust = function (outlineNumber, index, adjustment) {
  const oNum = [...outlineNumber];
  oNum[index] = (oNum[index] || 0) + adjustment;
  return oNum;
}

/**
 * insert poapTask into tasks array and adjust sibling task outline numbers as necessary
 * all mutated tasks become new copies so that references are broken
 */
exports.insert = function (poapTasks, index, poapTask, returnIndex = false) {
  const sibling = poapTasks[index];

  // if sibling task is root then the new task should simply be inserted at bottom:
  if (exports.isRoot(sibling)) {
    // get first indentation level value of current last task in project:
    const lastTask = poapTasks[poapTasks.length - 1];
    const nextVal = lastTask.outlineNumber.length === 2 ? lastTask.outlineNumber[1] + 1 : 0;
    const outlineNumber = [0, nextVal];
    poapTasks.push({ ...poapTask, outlineNumber });
    return returnIndex ? poapTasks.length - 1 : poapTasks;
  }

  // 1. task should be inserted as the next sibling of the task at the specified index:
  const outlineNumber = [...sibling.outlineNumber];
  outlineNumber[outlineNumber.length - 1]++;
  // 2. get descendants of task at specified index:
  const descendants = exports.getDescendants(poapTasks, index);

  // 3. adjust insertion index
  const insertIndex = index + 1 + descendants.length;
  const adjustIndex = outlineNumber.length - 1;

  // 4. adjust tasks following insertion:
  for (let i = insertIndex; i < poapTasks.length; i++) {
    const t = poapTasks[i];
    if (t.outlineNumber.length < outlineNumber.length) {
      break;
    }
    poapTasks[i] = { ...t, outlineNumber: [...t.outlineNumber] };
    poapTasks[i].outlineNumber[adjustIndex]++;
  }

  // 5. insert task
  poapTasks.splice(insertIndex, 0, { ...poapTask, outlineNumber });

  return returnIndex ? insertIndex : poapTasks;
}

/**
 * all mutated tasks become new copies so that references are broken
 */
exports.indent = function (poapTasks, index) {
  const poapTask = poapTasks[index];
  const adjustIndex = poapTask.outlineNumber.length;
  // get all tasks succeeding the indenting task, including children:
  const succeeding = exports.getSucceeding(poapTasks, index);
  // get the previous task which is a sibling (same indent level):
  const siblingIndex = exports.getNextIndex(poapTasks, index, -1);
  const siblingTask = poapTasks[siblingIndex];
  // get any descendants of the previous sibling:
  const siblingDescendants = exports.getDescendants(poapTasks, siblingIndex);

  const outlineNumber = [...siblingTask.outlineNumber];
  // adjust outline number, based on whether sibling has any children:
  if (siblingDescendants.length) {
    const lastDesc = siblingDescendants[siblingDescendants.length - 1];
    const lastOVal = lastDesc.outlineNumber[adjustIndex];
    outlineNumber.push(lastOVal + 1);
  } else {
    outlineNumber.push(0);
  }

  const compIndex = adjustIndex - 1;
  //adjust succeeding tasks:
  for (let i = 0; i < succeeding.length; i++) {
    const s = succeeding[i];
    const pIndex = index + i + 1;
    // if children then indent with the task:
    if (s.outlineNumber[compIndex] === poapTask.outlineNumber[compIndex]) {
      poapTasks[pIndex] = { ...s, outlineNumber: [...outlineNumber, ...s.outlineNumber.slice(adjustIndex)] };
      // else not child so just descrement value:
    } else {
      poapTasks[pIndex] = { ...s, outlineNumber: exports.adjust(s.outlineNumber, compIndex, -1) };
    }
  }
  // adjust the indenting poap task (breaking the object reference):
  poapTasks[index] = { ...poapTask, outlineNumber };

  return poapTasks;

};

exports.outdent = function (poapTasks, index) {
  // 1. get the outdenting task
  const moveTask = poapTasks[index];

  // 2. get descendants of outdenting task
  const moveDescendants = exports.getDescendants(poapTasks, index);

  // 3. get all succeeding tasks from the outdenting task:
  const succeeding = exports.getSucceeding(poapTasks, index);

  // 4. adjust outline numbers of outdenting task and its descendants
  const outlineNumber = [...moveTask.outlineNumber.slice(0, -1)];
  const adjustIndex = outlineNumber.length - 1;
  outlineNumber[adjustIndex]++;
  poapTasks[index] = { ...moveTask, outlineNumber };
  for (let i = 0; i < moveDescendants.length; i++) {
    const t = moveDescendants[i];
    poapTasks[index + 1 + i] = { ...t, outlineNumber: [...outlineNumber, ...t.outlineNumber.slice(moveTask.outlineNumber.length)] };
  }

  // 5. update outline numbers of succeeding tasks:
  const updateSucceeding = succeeding.slice(moveDescendants.length);
  for (let i = 0; i < updateSucceeding.length; i++) {
    const t = updateSucceeding[i];
    const tIndex = index + 1 + moveDescendants.length + i;
    poapTasks[tIndex] = { ...t, outlineNumber: [...t.outlineNumber] };
    poapTasks[tIndex].outlineNumber[adjustIndex + 1]--;
  }

  // 6. update outline numbers of tasks after insertion point:
  const insertionIndex = index + 1 + succeeding.length;
  for (let i = insertionIndex; i < poapTasks.length; i++) {
    const t = poapTasks[i];
    if (t.outlineNumber.length < outlineNumber.length) {
      break;
    }
    poapTasks[i] = { ...t, outlineNumber: [...t.outlineNumber] };
    poapTasks[i].outlineNumber[adjustIndex]++;
  }

  // 7. move outdenting task and its parents into new position:
  const succeedingStart = index + moveDescendants.length + 1
  const succeedingEnd = succeedingStart + updateSucceeding.length;

  return [
    // all tasks before outdenting tasks:
    ...poapTasks.slice(0, index),
    // all 'succeeding' tasks which are not descendants:
    ...poapTasks.slice(succeedingStart, succeedingEnd),
    // the outdenting task and its descendants:
    ...poapTasks.slice(index, succeedingStart),
    // the remaining tasks:
    ...poapTasks.slice(succeedingEnd)
  ];

}

/**
 * all mutated tasks become new copies so that references are broken
 */
exports.moveDown = function (poapTasks, moveIndex) {
  const moveTask = poapTasks[moveIndex];
  const moveDescendants = exports.getDescendants(poapTasks, moveIndex);
  const swapIndex = exports.getNextIndex(poapTasks, moveIndex, 1);
  const swapTask = poapTasks[swapIndex];
  const swapDescendants = exports.getDescendants(poapTasks, swapIndex);

  const moveNum = [...moveTask.outlineNumber];
  const swapNum = [...swapTask.outlineNumber];

  poapTasks[moveIndex] = { ...moveTask, outlineNumber: swapNum };
  poapTasks[swapIndex] = { ...swapTask, outlineNumber: moveNum };

  for (let i = 0; i < moveDescendants.length; i++) {
    const index = moveIndex + i + 1;
    const task = poapTasks[index];
    poapTasks[index] = { ...task, outlineNumber: [...swapNum, ...task.outlineNumber.slice(swapNum.length)] };
  }

  for (let i = 0; i < swapDescendants.length; i++) {
    const index = swapIndex + i + 1;
    const task = poapTasks[index];
    poapTasks[index] = { ...task, outlineNumber: [...moveNum, ...task.outlineNumber.slice(moveNum.length)] };
  }

  return [
    ...poapTasks.slice(0, moveIndex),
    ...poapTasks.slice(swapIndex, swapIndex + swapDescendants.length + 1),
    ...poapTasks.slice(moveIndex, moveIndex + moveDescendants.length + 1),
    ...poapTasks.slice(swapIndex + swapDescendants.length + 1)
  ];

}

/**
 * all mutated tasks become new copies so that references are broken
 */
exports.moveUp = function (poapTasks, index) {
  const swapIndex = exports.getNextIndex(poapTasks, index, -1);
  return exports.moveDown(poapTasks, swapIndex);
};

/**
 * remove task and adjust outline numbers:
 */
exports.remove = function (poapTasks, index) {

  // const removeTask = poapTasks[index];s
  const descendants = exports.getDescendants(poapTasks, index);

  //due to the cleaning process we only need to correct the length of the outline numbers:
  for (let i = 0; i < descendants.length; i++) {
    const t = descendants[i];
    const ti = index + 1 + i;
    poapTasks[ti] = { ...t, outlineNumber: t.outlineNumber.slice(0, -1) };
  }
  // remove the target task:
  poapTasks.splice(index, 1);

  return exports.correct(poapTasks, index - 1);
};
