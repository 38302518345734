import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

import { PricingPlanId, PricingPlan } from 'tp-traqplan-core/dist/data-structs';
import { defaultPipe } from './common';
import { StaffApiService } from './staff-api.service';

@Injectable({
  providedIn: 'root'
})
export class PricingPlanApiService {

  constructor(
    private http: HttpClient,
    private staffApi: StaffApiService
  ) {}

  requestPricingPlans() {
    return this.http.get<{ pricingPlans: PricingPlan[] }>(
      '/api/pricing-plans'
    );
  }

  requestPricingPlanById(id: PricingPlanId) {
    return this.http.get<{ pricingPlan: PricingPlan }>(
      `/api/pricing-plans/${id}`
    );
  }

  staffCreatePricingPlan(options) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.post<{ pricingPlan: PricingPlan }>(
          '/api/staff/pricing-plans',
          options,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  staffDeletePricingPlanById(id: PricingPlanId) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.delete<any>(
          `/api/staff/pricing-plans/${id}`,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  staffRequestPricingPlans() {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.get<{ pricingPlans: PricingPlan[] }>(
          '/api/staff/pricing-plans',
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  staffRequestPricingPlanById(id: PricingPlanId) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.get<{ pricingPlan: PricingPlan }>(
          `/api/staff/pricing-plans/${id}`,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  staffUpdatePricingPlanById(id: PricingPlanId, options) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.patch<{ pricingPlan: PricingPlan }>(
          `/api/staff/pricing-plans/${id}`,
          options,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

}
