const oNums = require('./outline-numbers.js');

/**
 * update the parent date boundaries of all ascending tasks from the specified index:
 * @param {PoapTask[]} tasks
 * @param {number} index
 * @param {boolean} breakOnEqual - if true then, as soon as a parent does not require date changes, stop iterating
 * @returns {PoapTask[]}
 */
exports.updateParentBoundaries = function(tasks, index, breakOnEqual = true) {

  const descendants = oNums.getDescendants(tasks, index);

  // if task has descendants then start with a descendant, i.e update this task as well:
  if (descendants.length) {
    index++;
  }

  while((index = oNums.getParentIndex(tasks, index)) !== null) {

    const descendants = oNums.getDescendants(tasks, index);
    const [start, finish] = getDateBoundaries(descendants);

    if (breakOnEqual && tasks[index].start && tasks[index].finish && start.getTime() === tasks[index].start.getTime() && finish.getTime() === tasks[index].finish.getTime()) {
      break;
    }

    tasks[index] = { ...tasks[index], start, finish, milestone: exports.isMilestone(start, finish) };

  }

  return tasks;

};

/**
 * Update date boundaries of the task at the given index, and any parent tasks
 * @param {PoapTask[]} tasks
 * @param {number} index
 * @param {Date[]} boundaries
 * @returns {PoapTask[]}
 */
exports.updateTaskBoundaries = function(tasks, index, [start, finish]) {

  // if task has children then cannot proceed:
  if (tasks[index+1].outlineNumber.length > tasks[index].outlineNumber.length) {
    console.error('Cannot update boundaries of parent task');
    return tasks;
  }

  tasks[index] = { ...tasks[index], start, finish, milestone: exports.isMilestone(start, finish) };

  let i = index;

  while((i = oNums.getParentIndex(tasks, i)) !== null) {

    tasks[i] = {
      ...tasks[i],
      start: tasks[i].start ? new Date(Math.min(start.getTime(), tasks[i].start.getTime())) : start,
      finish: tasks[i].finish ? new Date(Math.max(finish.getTime(), tasks[i].finish.getTime())) : finish
    };

    tasks[i].milestone = exports.isMilestone(tasks[i].start, tasks[i].finish);
  }

  return tasks;

};

exports.isMilestone = function(start, finish) {
  return Math.abs(finish.getTime() - start.getTime()) < 4.32e7;
};

function getDateBoundaries(tasks) {
  const starts = [], finishes = [];
  for (let i=0; i < tasks.length; i++) {
    tasks[i].start && starts.push(tasks[i].start.getTime());
    tasks[i].finish && finishes.push(tasks[i].finish.getTime());
  }
  return [
    new Date(Math.min(...starts)),
    new Date(Math.max(...finishes)),
  ];
}
