import { Component, OnInit, Input, OnDestroy, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/state';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import * as modalActions from 'src/app/state/modal/actions';
import { logout } from '../../state/auth/actions';
@Component({
  selector: 'app-inactivity-warning',
  templateUrl: './inactivity-warning.component.html',
  styleUrls: ['./inactivity-warning.component.scss']
})
export class InactivityWarningComponent implements OnInit, OnDestroy {

  @Input() duration: number = 60000;

  visible = true;

  actions: ModalWindowAction[] = [
    {
      label: 'Continue',
      type: 'positive',
      enterKey: true
    }
  ];

  countdown: number;

  private started: number;
  private timeout: number;
  private interval: number;

  constructor(
    private store: Store<State>
  ) {}

  // if the user navigates away from the page, interval/timeout timing becomes unreliable, so resync on return to page:
  @HostListener('document:focus')
  private onFocus() {
    const remaining = this.duration - (Date.now() - this.started);
    if (remaining > 0) {
      this.setCountdown(remaining);
    } else {
      this.close();
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeout);
    clearInterval(this.interval);
  }

  ngOnInit(): void {
    this.started = Date.now();
    this.setCountdown(this.duration);
  }

  setCountdown(duration) {

    this.countdown = duration / 1000;

    this.timeout = window.setTimeout(() => this.logout(), duration);

    this.interval = window.setInterval(() => {
      this.countdown -= 1;
    }, 1000);
  }

  logout() {
    this.store.dispatch(logout({}));
    this.close();
  }

  close() {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }

}
