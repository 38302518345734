import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {

  @Input() icon: string = '';
  @Input() type: string = 'neutral';
  @Input() disabled: boolean = false;
  @Input() tooltip: string = '';
  @Input() tooltipPosition: string = '';

  onClick(event) {
    if (this.disabled) {
      event.stopPropagation();
    }
  }

}
