import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, timer } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import access from 'tp-common/access';
import { License, User, UserId } from 'tp-traqplan-core/dist/data-structs';
import { HierarchyApiService } from '../../api/hierarchy-api.service';
import { State } from '../../state';
import * as modalActions from '../../state/modal/actions';
import { AccountSelectorService } from '../../state/selectors/account-selector.service';
import { ProjectsService } from '../../state/selectors/projects.service';
import { matchBasicSearchQuery } from '../../util/string';

interface AccessRow {
  description: string;
  access: number;
}

interface AccessEntry extends User {
  access: number;
  accessDescriptor: string;
  assignable: boolean;
  licenseDescription: string;
  restricted: boolean;
}

@Component({
  selector: 'app-edit-access',
  templateUrl: './edit-access.component.html',
  styleUrls: ['./edit-access.component.scss']
})
export class EditAccessComponent implements OnDestroy, OnInit {

  @Input()
  public node: any;
  @Input()
  public onSuccess: any;
  @Input()
  public onComplete: any;

  private destroy$ = new Subject<void>();

  public form = new FormGroup({
    user: new FormControl('',
      [Validators.required],
      [
        (control: AbstractControl) => {
          return timer(500).pipe(
            map(() => {
              if (this.users.some(u => u.id === control.value.id)) {
                return null;
              }
              return { notFound: true }
            })
          );
        }
      ]
    ),
    access: new FormControl('',
      [Validators.required]
    )
  });

  public visible = true;

  // list of all users:
  public users: User[] = [];
  licenses: License[] = [];
  public suggestions: User[] = [];

  // list of users currently assigned access:
  public accessEntries: AccessEntry[] = [];

  public accessOptions: AccessRow[] = [];

  public currentUserAccess: number;
  public currentUserLicense: License;

  constructor(
    private store: Store<State>,
    private accountSelector: AccountSelectorService,
    private projectsSelector: ProjectsService,
    private hierarchyApi: HierarchyApiService
  ) {
    this.store.pipe(
      takeUntil(this.destroy$),
      map(state => state.account),
      filter(account => !!(account.users && account.licenses?.length)),
      take(1),
      tap(account => {
        this.users = Object.values(account.users);
        this.licenses = account.licenses;
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.getAccessAssignments();
    this.updateAccessOptions();
  }

  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
    this.onComplete && this.onComplete();
  }

  addUser(): void {
    if (!this.form.valid || !this.node) {
      return;
    }
    this.hierarchyApi.setAccess({
      path: this.node.path,
      userId: this.form.controls.user.value.id,
      permission: this.form.controls.access.value.access
    }).subscribe(() => {
      this.getAccessAssignments();

      if (typeof this.onSuccess === 'function') {
        this.onSuccess();
      }
    });
    this.form.controls.user.reset();
  }

  updateUserAccess(entry: AccessEntry, event): void {
    const option = this.accessOptions.find(a => a.description === event.value);

    if (typeof option?.access !== 'number') {
      return;
    }

    this.hierarchyApi.setAccess({
      path: this.node.path,
      userId: entry.id,
      permission: option.access
    }).subscribe(() => {
      this.getAccessAssignments();

      if (typeof this.onSuccess === 'function') {
        this.onSuccess();
      }
    });
  }

  searchUsers({ query }): void {
    const entryMap = new Map<UserId, AccessEntry>(this.accessEntries.map(e => [e.id, e]));

    if (!query.length) {
      this.suggestions = Array.isArray(this.users) ? this.users.filter(() => true) : [];
    } else {
      this.suggestions = this.users.filter(u => {
        // filter out users which are already assigned:
        if (!entryMap.has(u.id)) {
          const searchable = [u.name, u.email].join(' ');
          return matchBasicSearchQuery(query, searchable);
        }

        return false;
      });
    }
  }

  private updateAccessOptions() {
    this.accessOptions = [];
    this.accessOptions = [];
    if (!this.node?.id) {
      return;
    }

    for (const group of access.groupOrder) {
      const permission = access.actionGroups[group];

      // if user possesses all permission for this group, then they can assign it:
      if ((permission & this.node.permission) === permission) {
        this.accessOptions.push({
          description: group,
          access: permission
        });

        /*if (permission !== access.actionGroups['No Access']) {
          this.accessOptions.push({
            description: group,
            access: permission
          });
        }*/
      }
    }

    if (!this.form.controls.access.value || !this.accessOptions.some(a => this.form.controls.access.value?.description === a.description)) {
      this.form.controls.access.setValue(this.accessOptions[0]);
    }
  }

  private getAccessAssignments() {
    if (this.node?.id) {
      this.hierarchyApi.getAccessAssignments({ id: this.node.id }).subscribe(({ assignments }) => {
        const userMap = new Map<UserId, User>(this.users.map(u => [u.id, u]));
        const licenseMap = new Map<UserId, License>(this.licenses.map(l => [l.assignee, l]));
        this.accessEntries = assignments.filter(a => userMap.has(a.user) && licenseMap.has(a.user)).map(a => {
            const user = userMap.get(a.user);
            const license = licenseMap.get(a.user);
            return <AccessEntry>{
              ...user,
              access: a.permission,
              accessDescriptor: this.matchAccessOption(a.permission).description,
              assignable: this.isUserAssignable(a.permission),
              licenseDescription: access.licenseDescription[license.type],
              restricted: !access.licenseAllowable(license, a.permission)
            }
        }).sort((a, b) => a.name.localeCompare(b.name));
      });
    }
  }

  private matchAccessOption(permission): AccessRow {
    let lastMatch: AccessRow = {
      description: '',
      access: 0
    };

    for (const group of access.groupOrder) {
      // if user possesses all permission for this group, then they can assign it:
      if ((access.actionGroups[group] & permission) === access.actionGroups[group]) {
        lastMatch = {
          description: group,
          access: permission
        };
      }
    }

    return lastMatch;
  }

  // the user cannot edit the assignment of someone with greater access than they themselves have:
  private isUserAssignable(permission: number): boolean {
    return (permission & this.node.permission) === permission;
  }
}
