/** @module */
const { invert } = require('lodash');

const accessType = {
  none: 0,
  write: 1,
  read: 2,
  share: 4,
  all: 7
};

const licenseType = {
  viewer: 1,
  contributor: 2,
  admin: 3
};

/** @deprecated */
exports.accessType = Object.freeze({ ...accessType, ...invert(accessType) });
exports.licenseType = Object.freeze({ ...licenseType, ...invert(licenseType) });

/**
 * objectPermission refers to the privileges a user has over a particular object
 * i.e a project, portfolio, view etc
  */
exports.access = {
  includes(access, type) {
    return !!(access & type);
  },
  combine(...args) {
    return args.reduce((a,c) => a | c, 0);
  },
  ...accessType,
  ...invert(accessType)
};

/**
 * accountPermission refers to privilges specified by the user license assignment
 * i.e viewer, contribuor, admin etc
 */
exports.account = {
  canView(license) {
    return (license.type >= licenseType.viewer);
  },
  canContribute(license) {
    return (license.type >= licenseType.contributor);
  },
  isViewer(license) {
    return (license.type === licenseType.viewer);
  },
  isContributor(license) {
    return (license.type === licenseType.contributor);
  },
  isAdmin(license) {
    // admin must also be a contributor:
    return (license.type === licenseType.admin);
  }
};
