import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { AuthApiService } from './auth-api.service';
import { defaultPipe, parseUser } from './common';
import { User, LicenseId, AccountId, Invitation } from 'tp-traqplan-core/dist/data-structs';
import { ReqHeader } from './structs';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(
    private http: HttpClient,
    private authApi: AuthApiService
  ) { }

  createUser({ email, name, alias, password = null, tncUrl }): Observable<{ user: User; token: string }> {
    return this.http.post<{ user: User; token: string }>(
      '/api/users',
      { email, name, alias, password, tncUrl }
    ).pipe(
      defaultPipe(),
      map(({ user, token }) => ({ user: parseUser(user), token }))
    );
  }

  sendInvite({ email, name, license }: { email: string; name: string; license: LicenseId }) {
    return this.authApi.headers({ requires: ReqHeader.account & ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/invites',
          { email, name, license },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  sendPasswordResetToken(email) {
    return this.authApi.headers().pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/users/reset-password',
          { email },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  verifyPasswordToken({ email, token }) {
    return this.authApi.headers().pipe(
      switchMap(headers => {
        return this.http.get<void>(
          '/api/users/verify-password-token',
          { params: { email: encodeURIComponent(email), token }, headers }
        );
      })
    );
  }

  setPassword({ token, email, password }) {
    return this.authApi.headers().pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/users/set-password',
          { token, email, password },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  acceptInvite(
    { user, token, account }: { user: any; token: string; account: AccountId }
  ): Observable<{ user: User; token: string, ssoToken: string, origin: string }> {
    return this.authApi.headers().pipe(
      switchMap((headers): Observable<{ user: User; token: string, ssoToken: string, origin: string }> => {
        return this.http.post<{ user: User; token: string, ssoToken: string, origin: string }>(
          '/api/invites/accept',
          { user, token, account },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  getLatestInvite(email: string): Observable<Invitation> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<Invitation> => {
        return this.http.get<Invitation>(
          '/api/invites/latest',
          { headers, params: { email: encodeURIComponent(email) } }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

}
