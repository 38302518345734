import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from '@ag-grid-community/angular';
import { DialogModule } from 'primeng/dialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TreeModule } from 'primeng/tree';
import { OrderListModule } from 'primeng/orderlist';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';

import { PipesModule } from 'src/app/pipes/pipes.module';
import { CoreModule } from 'src/app/core/core.module';

import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ProjectTreeComponent } from './project-tree/project-tree.component';
import { TreeListComponent } from './project-tree/tree-list/tree-list.component';
import { ExpandIconComponent } from './project-tree/expand-icon/expand-icon.component';
import { ImportPanelComponent } from './project-tree/import-panel/import-panel.component';
import { GroupSelectComponent } from './project-tree/group-select/group-select.component';

import { MaterialModule } from '../material.module';
import { ArchivePanelComponent } from './project-tree/archive-panel/archive-panel.component';

import { TimelineComponent } from './timeline/timeline.component';
import { TimelineContextMenuComponent } from './timeline/timeline-context-menu/timeline-context-menu.component';
import { TimelineCoreComponent } from './timeline/timeline-core/timeline-core.component';
import { TimelineExportComponent } from './timeline/timeline-export/timeline-export.component';
import { GroupMenuComponent } from './timeline/group-menu/group-menu.component';
import { PublishIndicatorComponent } from './timeline/publish-indicator/publish-indicator.component';
import { MenuIndicatorComponent } from './timeline/menu-indicator/menu-indicator.component';
import { HorizontalScrollerComponent } from './timeline/horizontal-scroller/horizontal-scroller.component';
import { VerticalScrollerComponent } from './timeline/vertical-scroller/vertical-scroller.component';
import { NewTaskModalComponent } from './timeline/new-task-modal/new-task-modal.component';
import { TimelineGroupTreeComponent } from './timeline/timeline-group-tree/timeline-group-tree.component';
import { RevisionLabelPipe } from './project-tree/revision-label.pipe';
import { ToastModule } from 'primeng/toast';
import { AddTaskIndicatorComponent} from './timeline/add-task-indicator/add-task-indicator.component';
import { SelectTaskComponent } from './timeline/select-task/select-task.component';
import { SubscriptionWarningComponent } from './subscription-warning/subscription-warning.component';
import { FloatingTooltipComponent } from './timeline/floating-tooltip/floating-tooltip.component';
import { FloatingInputComponent } from './timeline/floating-input/floating-input.component';
import { NotificationsMenuComponent } from './notifications-menu/notifications-menu.component'


@NgModule({
  declarations: [
    NavBarComponent,
    ProjectTreeComponent,
    TreeListComponent,
    ExpandIconComponent,
    ImportPanelComponent,
    GroupSelectComponent,
    ArchivePanelComponent,
    TimelineComponent,
    TimelineContextMenuComponent,
    TimelineCoreComponent,
    TimelineExportComponent,
    GroupMenuComponent,
    PublishIndicatorComponent,
    AddTaskIndicatorComponent,
    MenuIndicatorComponent,
    HorizontalScrollerComponent,
    VerticalScrollerComponent,
    NewTaskModalComponent,
    TimelineGroupTreeComponent,
    RevisionLabelPipe,
    SelectTaskComponent,
    SubscriptionWarningComponent,
    FloatingTooltipComponent,
    FloatingInputComponent,
    NotificationsMenuComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    DialogModule,
    ContextMenuModule,
    TreeModule,
    OrderListModule,
    TableModule,
    ProgressSpinnerModule,
    DropdownModule,
    CalendarModule,
    TooltipModule,
    ToggleButtonModule,
    AutoCompleteModule,
    MultiSelectModule,
    InputNumberModule,
    InputTextModule,
    CoreModule,
    PipesModule,
    ToastModule,
    MaterialModule,
    AgGridModule
  ],
  exports: [
    NavBarComponent,
    ProjectTreeComponent,
    TimelineComponent,
    SubscriptionWarningComponent,
    NotificationsMenuComponent
  ]
})
export class SharedModule {}
