import { ValidatorFn, AbstractControl } from '@angular/forms';
//import { NAMING_CONVENTION } from '../../constants/regex';
import { isValidName } from 'tp-common/validators/naming-convention';

export function namingConventionValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control?.value === 'object' || (control?.value?.length && isValidName(control.value))) {
      return null;
    }
    return { 'invalidName': { value: control.value } };
  };
}
