<app-modal-window
  header="title"
  [(visible)]="visible"
  (actionSelected)="onActionSelected($event)"
  (closed)="onClose()"
  [actions]="actions"
>
  <form
    class="tp-form"
    [formGroup]="projectForm"
  >

    <div class="tp-form__field">
      <label for="workspace">Workspace</label>
      <div class="tp-form__field__sticker" *ngIf="isNewWorkspace && workspaceControl?.valid">NEW</div>
      <p-autoComplete
        formControlName="workspace"
        [suggestions]="workspaceSuggestions"
        (completeMethod)="searchWorkspaces($event)"
        [dropdown]="true"
        field="name"
        appendTo="body"
      >
      </p-autoComplete>
    </div>

    <div class="tp-form__field">
      <label for="programme">Programme</label>
      <div class="tp-form__field__sticker" *ngIf="isNewProgramme && programmeControl?.valid">NEW</div>
      <p-autoComplete
        [disabled]="!workspaceControl?.value || !workspaceControl.valid"
        formControlName="programme"
        [suggestions]="programmeSuggestions"
        [dropdown]="true"
        (completeMethod)="searchProgrammes($event)"
        field="name"
        appendTo="body"
      >
      </p-autoComplete>
    </div>

    <div class="tp-form__field">
      <label for="name">Name</label>
      <input
        pInputText
        name="name"
        formControlName="name"
      />
    </div>

    <div
      class="tp-form__error"
      *ngIf="projectForm.errors?.validationError"
    >
      <ul>
        <li *ngFor="let message of validationErrors">{{message}}</li>
      </ul>
    </div>

  </form>

</app-modal-window>
