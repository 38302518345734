import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import dayjs from 'dayjs';
import { RevisionConflict, RevisionConflictDifference, RevisionConflictResult, RevisionConflictTask, RevisionConflictViewTask } from 'tp-traqplan-core/dist/data-structs';

const FieldName = Object.freeze({
  name: 'Task Name',
  start: 'Start',
  finish: 'Finish',
  percentComplete: '% Completed',
  selected: 'Show',
  rename: 'Rename on view',
  groupId: 'Group',
  colour: 'Bar Colour',
  milestoneCategory: 'Milestone Category',
  deleted: 'Deleted'
});
const FieldOrder = Object.freeze({
  name: 1,
  start: 2,
  finish: 3,
  percentComplete: 4,
  selected: 5,
  rename: 6,
  groupId: 7,
  colour: 8,
  milestoneCategory: 9,
  deleted: 10
});

@Component({
  selector: 'app-publish-conflict-table',
  templateUrl: './publish-conflict-table.component.html',
  styleUrls: ['./publish-conflict-table.component.scss']
})
export class PublishConflictTableComponent implements OnInit {

  @Input() conflict: RevisionConflict;
  @Input() resolution: any;
  @Output() selectResolvedValue = new EventEmitter<boolean>();
  @Output() toggleAdvancedOptions = new EventEmitter<boolean>();

  authorName: string;
  changeList: any[];
  showCheckboxes: boolean = false;

  ngOnInit(): void {
    this.initAuthorName();
    this.initListData();
  }

  onSelectAll(resolved): void {
    this.changeList.forEach((row) => this.selectRow(true, row, resolved));
    this.emitSelectResolvedValue();
  }

  onSelectRow({ checked }, row, resolved): void {
    this.selectRow(checked, row, resolved);
    this.emitSelectResolvedValue();
  }

  toggleCheckboxes({ checked }): void {
    this.showCheckboxes = checked;
    this.toggleAdvancedOptions.emit(checked);
  }

  private emitSelectResolvedValue(): void {
    let resolvedDraftCount = 0;
    let resolvedLatestCount = 0;
    const resolvedValues: Map<string, any> = new Map();
    this.changeList.map((change) => {
      const { taskId, field, options, draft, latest, resolved, status } = change;
      const resolvedValue = resolvedValues.get(taskId) || {};
      resolvedValue.id = taskId;
      resolvedValue[field] = { draft, latest, resolved, status };
      resolvedValues.set(taskId, resolvedValue);

      if (
        (options[0].resolved === 'draft' && options[0].checked) ||
        (options[1].resolved === 'draft' && options[1].checked)
      ) {
        resolvedDraftCount += 1;
      } else if (
        (options[0].resolved === 'latest' && options[0].checked) ||
        (options[1].resolved === 'latest' && options[1].checked)
      ) {
        resolvedLatestCount += 1;
      }
    });
    this.resolution.discard = resolvedLatestCount === this.changeList.length;
    this.resolution.overwrite = resolvedDraftCount === this.changeList.length || (resolvedDraftCount > 0 && resolvedLatestCount > 0 && (resolvedDraftCount + resolvedLatestCount) === this.changeList.length);
    this.resolution.resolvedValues = Object.fromEntries(resolvedValues.entries());
    this.selectResolvedValue.emit(this.resolution);
  }

  private initAuthorName(): void {
    const { authors, latest } = this.conflict;
    const author = authors[latest?.author];
    this.authorName = author?.name || author?.alias || author?.email;
  }

  private initListData(): void {
    const { task: taskConflict, viewTask: viewTaskConflict } = this.conflict;
    let changeList: any[] = [];

    if (taskConflict && taskConflict.differences) {
      const { differences: taskDifferences }: RevisionConflictResult<RevisionConflictTask> = taskConflict;
      Object.entries(taskDifferences).forEach(([id, taskDiff]) => {
        let viewTaskDiff = <RevisionConflictViewTask>{};
        let hasViewTaskChanges = false;

        if (viewTaskConflict && viewTaskConflict.differences) {
          const { differences: viewTaskDifferences }: RevisionConflictResult<RevisionConflictViewTask> = viewTaskConflict;
          viewTaskDiff = viewTaskDifferences[id];
          hasViewTaskChanges = viewTaskDiff && viewTaskDiff.status === 'changed';
        }

        const changes = this.getChanges(taskDiff, viewTaskDiff, hasViewTaskChanges);

        if (changes.length > 0) {
          changeList = changeList.concat(changes);
        }
      });

      this.changeList = changeList;
    }
  }

  private getChanges(taskDiff: RevisionConflictTask, viewTaskDiff: RevisionConflictViewTask, hasViewTaskChanges: boolean): any[] {
    const { authors, groups, milestones, draft: draftRevision, latest: latestRevision, latestViewData: latestViewDataRevision } = this.conflict;
    const changes: any[] = [];
    const { id: taskId, name, outlineNumber } = taskDiff;
    const taskName = name?.current?.value;
    Object.entries(taskDiff).forEach(([field, diff]: [string, RevisionConflictDifference<any>]) => {
      if (FieldOrder[field] > 0 && (outlineNumber?.current?.value?.length || 0) > 1) {
        if (diff.status !== 'unchanged') {
          const id = `${taskId}-${field}`;
          const { draft, latest } = diff;
          const option1 = { ...draft, author: authors[draftRevision.author], checked: false, resolved: 'draft' };
          const option2 = { ...latest, author: authors[latestViewDataRevision?.author || latestRevision.author], checked: false, resolved: 'latest' };
          changes.push({
            cssClass: 'tr-project-data',
            id,
            taskId,
            taskName,
            field,
            fieldName: FieldName[field],
            options: dayjs(draft?.version).isAfter(latest?.version) ? [option1, option2] : [option2, option1],
            ...diff
          });
        }
      }
    });

    if (hasViewTaskChanges) {
      Object.entries(viewTaskDiff).forEach(([field, diff]: [string, RevisionConflictDifference<any>]) => {
        const isName = field === 'name';
        const _field = isName ? 'rename' : field;

        if (FieldOrder[_field] > 0 && (outlineNumber?.current?.value?.length || 0) > 1) {
          if (diff.status !== 'unchanged') {
            const id = `${taskId}-${field}`;
            const { draft, latest } = diff;
            const option1 = { ...draft, author: authors[draftRevision.author], checked: false, resolved: 'draft' };
            const option2 = { ...latest, author: authors[latestViewDataRevision?.author || latestRevision.author], checked: false, resolved: 'latest' };

            if (_field === 'groupId') {
              this.setGroup(option1, groups, option1?.value);
              this.setGroup(option2, groups, option2?.value);
            } else if (_field === 'milestoneCategory') {
              this.setMilestone(option1, milestones, option1?.value);
              this.setMilestone(option2, milestones, option2?.value);
            }

            changes.push({
              cssClass: 'tr-view-data',
              id,
              taskId,
              taskName,
              field: _field,
              fieldName: FieldName[_field],
              options: dayjs(draft?.version).isAfter(latest?.version) ? [option1, option2] : [option2, option1],
              ...diff
            });
          }
        }
      });
    }

    return changes.sort((a: any, b: any) => FieldOrder[a.field] - FieldOrder[b.field]);
  }

  private selectRow(checked, row, resolved): void {
    const resolution = checked ? resolved : undefined;
    row.options[0].checked = row.options[0].resolved === resolution;
    row.options[1].checked = row.options[1].resolved === resolution;
    row.resolved = resolution;
  }

  private setGroup(value, groups, groupId): void {
    if (value) {
      value.group = groups[groupId];

      if (value.group) {
        value.group.displayName = this.getGroupDisplayName(value.group, groups, []);
      } else {
        value.group = { displayName: null };
      }
    }
  }

  private getGroupDisplayName(group, groups, groupNames): string {
    if (group) {
      const { isProjectRoot, name, parent } = group;

      if (!isProjectRoot) {
        groupNames.unshift(name);
      }

      if (parent) {
        return this.getGroupDisplayName(groups[parent], groups, groupNames);
      } else {
        return groupNames.join(' | ');
      }
    }

    return groupNames.join(' | ');
  }

  private setMilestone(value, milestones, milestoneCategory): void {
    if (value) {
      value.milestone = milestones[milestoneCategory];

      if (value.milestone) {
        const { description, name } = value.milestone;
        value.milestone.displayName = name || description;
      }
    }
  }
}
