<app-modal-window
  [header]="title"
  [(visible)]="visible"
  (actionSelected)="onActionSelected($event)"
  (closed)="onClose()"
  [actions]="actions"
>
  <form
    class="tp-form"
    [formGroup]="form"
  >
    <div>
      This will copy and publish the CURRENTLY DISPLAYED view configuration to the target view. This will not change the project information in the target view.
    </div>

    <div class="tp-form__field">
      <label for="copyTo">Copy To</label>

      <p-autoComplete
        formControlName="copyTo"
        (completeMethod)="searchViews($event)"
        [dropdown]="true"
        field="name"
        [suggestions]="viewSuggestions"
        appendTo="body"
      >
      </p-autoComplete>
    </div>

    <div
      class="tp-form__error"
      *ngIf="form.controls.copyTo.errors?.namingConflict"
    >
      The name '{{form.controls.copyTo.value}}' is taken
    </div>
  </form>
</app-modal-window>
