import { PricingPlan } from 'tp-traqplan-core/dist/data-structs';

export const key = 'pricingPlans';

export interface State {
  pricingPlan: PricingPlan,
  pricingPlans: PricingPlan[]
}

export const initialState: State = {
  pricingPlan: null,
  pricingPlans: []
};

export function beforeSave(state) {
  return {
    ...state
  };
}

export function beforeLoad(state) {
  return state;
}
