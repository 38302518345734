import { createSVGElement } from '../../svg-utils';
import { DataBoundElement, DragAction, ViewLineFitting } from '../tl-structs';
import { standardAttribute, standardClass } from '../tl-style';

export class ViewLineComponent {

  public id: string;

  public position: number;
  public fitting: ViewLineFitting;

  private group = createSVGElement('g') as DataBoundElement;
  private line = createSVGElement('line');
  private mask = createSVGElement('line');

  set style(value: string) {
    this.group.setAttribute(standardAttribute.dataViewlineStyle, value);
  }
  set colour(value: string) {
    this.group.setAttribute(standardAttribute.dataStroke, value);
  }

  constructor(identifier: string) {
    this.id = identifier;
    this.group.__fitting__ = this;
    this.group.appendChild(this.mask);
    this.group.appendChild(this.line);

    this.group.dataset.viewLineId = identifier;
    this.group.classList.add(standardClass.viewline);
    this.group.setAttribute(standardAttribute.dataDrag, DragAction.ViewLine);

    this.line.classList.add(standardClass.viewlineLine);
    this.line.setAttribute('y1', '0');
    this.line.setAttribute('y2', '100%');

    this.mask.classList.add(standardClass.viewlineMask);
    this.mask.setAttribute('y1', '0');
    this.mask.setAttribute('y2', '100%');

  }

  update(fitting: ViewLineFitting): void {
    this.fitting = fitting;
    this.setPosition(fitting.position);
    this.style = fitting.style;
    this.colour = fitting.colour;
  }

  setPosition(value: number): void {
    this.position = value;
    this.group.setAttribute('transform', `translate(${value},0)`);
  }

  append(parent): void {
    parent.appendChild(this.group);
  }

  remove(): void {
    this.group.remove();
  }

}
