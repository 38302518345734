import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { LicenseManager } from '@ag-grid-enterprise/core';
LicenseManager.setLicenseKey('CompanyName=Traqplan Limited,LicensedGroup=traqplan,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-011103,ExpiryDate=12_October_2021_[v2]_MTYzMzk5MzIwMDAwMA==e9a2c8a18af734a1f398f93ad7125fc9');
// the trial licence below if above doesnt work.
// LicenseManager.setLicenseKey('Evaluation_License_Not_For_Production_10_December_2019__MTU3NTkzNjAwMDAwMA==071e8e43e311e7de960b2ecb00deebe3');


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
