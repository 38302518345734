import { Component, Input, Output, OnChanges, OnDestroy, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subscription, SubscriptionExtension } from 'tp-traqplan-core/dist/data-structs';
import { State } from '../../state';
import { SubscriptionExtensionApiService } from '../../api/subscription-extension-api.service';
import { SubscriptionApiService } from '../../api/subscription-api.service';

@Component({
  selector: 'app-subscription-warning',
  templateUrl: './subscription-warning.component.html',
  styleUrls: ['./subscription-warning.component.scss']
})
export class SubscriptionWarningComponent implements OnDestroy, OnChanges {

  @Input() subscription: Subscription;
  // can be optionally used to override default routing behaviour:
  @Output() purchaseSubscription = new EventEmitter<void>();

  canManageSubscription: boolean;
  extension: SubscriptionExtension|null;
  
  trialMessage: string;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store<State>,
    private subscriptionExtensionApi: SubscriptionExtensionApiService,
    private subscriptionApiService: SubscriptionApiService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.store.pipe(
      takeUntil(this.destroy$),
      tap(state => {
        this.canManageSubscription = state.account.selected?.author === state.auth.user?.id;
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.subscription?.currentValue) {
      this.checkExtensionRequests();
    }

    if (this.subscription?.trial && this.subscription?.expired_at) {
      const remaining = Math.floor((this.subscription.expired_at.getTime() - Date.now()) / 86400000);
      this.trialMessage = `Free trial (${remaining} days remaining)`;
    } else {
      this.trialMessage = 'Free trial';
    }
  }

  checkExtensionRequests() {
    this.subscriptionExtensionApi.getLatest().subscribe(({ subscriptionExtension }) => {
      this.extension = subscriptionExtension;
      this.changeDetectorRef.detectChanges();
    });
  }

  onRequestExtension(e: Event, subscription: Subscription | undefined) {
    e.preventDefault();
    if (subscription?.id) {
      this.subscriptionExtensionApi.create({ subscriptionId: subscription.id }).subscribe((extension) => {
        this.extension = extension;
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  onPurchaseSubscription() {
    // override most likely used if component is being used ON the buy licenses page:
    if (this.purchaseSubscription.observed) {
      this.purchaseSubscription.emit();
    } else {
      this.router.navigate(['/accounts/licenses'], { queryParams: { buylicense: 1 } });
    }
  }

  onManagePaymentDetails() {
    this.subscriptionApiService.createPortalSession().subscribe(({ url }) => url && location.replace(url));
  }

}
