import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { State } from '../../state';
import * as modalActions from '../../state/modal/actions';
import { AccountApiService } from '../../api/account-api.service';
import { Notification } from '../../data-structs';
import { StateModal } from '../../state/structs';

@Component({
  selector: 'app-review-license-request',
  templateUrl: './review-license-request.component.html',
  styleUrls: ['./review-license-request.component.scss']
})
export class ReviewLicenseRequestComponent {

  @Input() message: Notification;

  actions: ModalWindowAction[] = [
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true,
      escKey: true
    },
    {
      label: 'Edit user license',
      type: 'positive',
      confirm: true,
      enterKey: true,
    }
  ];

  visible = true;

  constructor(
    private store: Store<State>,
    private accountsApi: AccountApiService
  ) { }

  complete(action?: ModalWindowAction) {

    if (action?.confirm) {

      this.accountsApi.getUserLicense(this.message.author).subscribe(({ user, license }) => {
        if (user && license) {
          this.store.dispatch(
            modalActions.generateModal({
              modal: StateModal.editLicense,
              inputs: { 
                user: user,
                existingLicense: license
              }
            })
          );
        }
      });
      
    }
    this.store.dispatch(modalActions.closeModal());
  }

}
