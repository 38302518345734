import { UserId, RevisionId, ViewId, PoapTask, ViewGroup } from 'tp-traqplan-core/dist/data-structs';

export interface RequestError {
  code: number;
  message: string;
  //body: any;
  account: string;
}

export interface LicenseRequest {
  type: number;
  assignee?: UserId;
}

// BIT FLAGS - DOUBLE WITH EACH NEW ENTRY!
export enum ReqHeader {
  user = 1 << 0,
  account = 1 << 1,
  workspace = 1 << 2
}

export interface PoapUpdateRequest {
  revisionId: RevisionId;
  viewId: ViewId;
  action: string;
  tasks: PoapTask[];
  groups: ViewGroup[];
  batchId?: string;
}

export enum StateModal {
  sessionTimeout,
  createProject
}
