
const SVG_NS = 'ht'+'tp://www.w3.org/2000/svg';

export function createSVGElement(tag): SVGElement {
  return document.createElementNS(SVG_NS, tag) as SVGElement;
}

export function removeChildren(element: Element): void {
  while(element.firstChild) {
    element.firstChild.parentNode.removeChild(element.firstChild);
  }
}

export function deepSelector(selector:string): string {
  return `${selector}, ${selector} *`
}

/**
 * returns the position of node relative to ancestor svg tag in the VIEWBOX coordinates of the svg.
 */
export function getAbsoluteBBox(node: SVGElement, ancestor: SVGSVGElement): { x:number; y:number; width:number; height:number; } {

  const viewBox = ancestor.viewBox.baseVal

  const nodeBRect = node.getBoundingClientRect();
  const ancestorBRect = ancestor.getBoundingClientRect();

  const xTransform = viewBox.width / ancestorBRect.width;
  const yTransform = viewBox.height / ancestorBRect.height;

  const xOffset = nodeBRect.x - ancestorBRect.x;
  const yOffset = nodeBRect.y - ancestorBRect.y;

  return {
    x: xOffset * xTransform,
    y: yOffset * yTransform,
    width: nodeBRect.width * xTransform,
    height: nodeBRect.height * yTransform,
  };

}

/** @deprecated */
export function mkNode(tag, attributes): any {
  const node = document.createElementNS(SVG_NS, tag);

  if(attributes){
    for(const attr in attributes){
      node.setAttribute(attr, attributes[attr]);
    }
  }

  return node;
}
/** @deprecated */
export function setAttributes(node, pairs: string|number[][]): void {
  for (const [k,v] of pairs) {
    node.setAttribute(k, v.toString());
  }
}
/** @deprecated */
export function transform(node, value: string){
  node.setAttribute('transform', value);
}
/** @deprecated */
export function match(target, selector:string) {
  if (target.matches(`${selector}, ${selector} *`)) {
    return target.closest(selector);
  }
  return null;
}

export function setSvgNodeTranslation(node: SVGElement, translation): void {

  const xT = translation.x || void 0;
  const yT = translation.y || void 0;
  let wasReplaced = false;
  const curTransform = node.getAttribute('transform');
  //attempt to replace existing transform:
  const newTransform = curTransform.replace(
    /translate\(\s*([^,\s]+)[,\s]([^,'s]+)\)/,
    (m,x,y) => (
      wasReplaced=true,
      `translate(${xT !==undefined ? xT : x},${yT !== undefined ? yT : y})`
    )
  );

  node.setAttribute(
    'transform',
    wasReplaced ? newTransform
                : curTransform + ` translate(${xT || 0},${yT || 0})`
  );

}
