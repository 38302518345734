import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { ICellEditorParams } from '@ag-grid-enterprise/all-modules';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';

@Component({
    selector: 'app-ag-grid-datepicker-editor',
    template: `
        <p-calendar
          #datePicker
          appendTo="body"
          [(ngModel)]="value"
          [minDate]="minDate"
          [dateFormat]="currentDateFormat"
          (onSelect)="onDateSelected()"
          [readonlyInput]="true"
          [monthNavigator]="true"
          [yearNavigator]="true"
          [yearRange]="offsetYearString(-10) + ':' + offsetYearString(10)"
          ngDefaultControl></p-calendar>
    `
})
export class AgGridDatepickerEditorComponent implements ICellEditorAngularComp, AfterViewInit {

  params: ICellEditorParams;
  public value: Date;
  public minDate: Date;
  public currentDateFormat: string;

  @ViewChild('datePicker') calendar: any;

  ngAfterViewInit() {
    this.calendar.toggle();

  }

  isPopup(): boolean {
    return true;
  }

  isCancelBeforeStart(): boolean {
    return false;
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.getLocalDateFormat();
    if(params.data.dateFinish === true){
      this.minDate = params.data.start;
    }

    this.params = params;
    this.value = new Date(params.value);
  }

  getValue(): Date {
    return this.value;
  }

  onDateSelected(): void {
    this.params.api.stopEditing();
  }

  offsetYearString(offset) {
    return offsetYearString(offset);
  }
  getLocalDateFormat() {
      const date = new Date();
      const parts = new Intl.DateTimeFormat(undefined, {day:'2-digit', month:'2-digit', year:'2-digit'}).formatToParts(date);

      const dayIndex = parts.findIndex(part => part.type === 'day');
      const monthIndex = parts.findIndex(part => part.type === 'month');

    let dateFormat: string;
    if (dayIndex < monthIndex) {
      dateFormat = 'dd/mm/yy';
    } else {
      dateFormat = 'mm/dd/yy';
    }

    setTimeout(() => {
      // Assign the value after the change detection cycle
      this.currentDateFormat = 'D '+dateFormat;
    }, 0);

    return this.currentDateFormat;
  }


}

function offsetYearString(offset) {
  const now = new Date();
  return (new Date(now.getTime() + offset * 31540000000)).getFullYear().toString();
}

