import { Component, OnDestroy, OnInit, Input, HostListener, EventEmitter, Output, HostBinding } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { NotificationsService } from '../../api/notifications.service';
import { Notification } from '../../data-structs';
import { StateModal } from '../../state/structs';
import * as modalActions from '../../state/modal/actions';
import * as accountActions from '../../state/account/actions';
import { Store } from '@ngrx/store';
import { State } from '../../state';
import { ModalService } from '../../modals/modal.service';

@Component({
  selector: 'app-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss']
})
export class NotificationsMenuComponent implements OnInit, OnDestroy {

  
  @Input()
  @HostBinding('class.visible')
  visible: boolean = false;

  @Output() visibleChange = new EventEmitter<boolean>();

  @HostListener('document:click', ['$event'])
  onDocumentClick() {
    if (this.visible) {
      this.visibleChange.emit(false);
    }
  }

  private destroy$ = new Subject<void>();

  unreadOnly: boolean;

  notifications: Notification[] = [];

  constructor(
    private store: Store<State>,
    private notificationsService: NotificationsService,
    private modalService: ModalService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {

    this.notificationsService.notifications$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(notifications => {
      this.notifications = notifications?.sort((a, b) => b.created.getTime() - a.created.getTime());
    });

  }

  onClickMessage(message: Notification): void {

    this.notificationsService.setReadState({ value: true, messageId: message.id }).subscribe(() => {
      message.readDate = new Date();
      this.notificationsService.startPollingNotifications();
    });

    switch(message.messageType) {
      case 'requestAccess':
        this.store.dispatch(modalActions.generateModal({
          modal: StateModal.reviewAccessRequest,
          inputs: { 
            message: message
          }
        }));
        break;
      case 'requestLicense':
        this.store.dispatch(modalActions.generateModal({
          modal: StateModal.reviewLicenseRequest,
          inputs: { message }
        }));
        break;
      case 'accessGranted':
        this.store.dispatch(modalActions.generateModal({
          modal: StateModal.accessGranted,
          inputs: { message }
        }));
        break;
      case 'licenseGranted':
        this.modalService.info({
          header: 'New message',
          message: `${message.messageContent}\nRelaunch the app to use your new license.`,
          actions: [
            {
              label: 'Cancel',
              confirm: true,
              type: 'neutral'
            },
            {
              label: 'Relaunch',
              confirm: true,
              type: 'neutral'
            }
          ]
        }).then((action) => {
          if (action?.confirm) {
            this.store.dispatch(accountActions.requestAvailable());
            setTimeout(() => window.location.reload(), 500);
          }
        });
        break;
      default:
        this.modalService.info({
          header: 'New message',
          message: message.messageContent,
          actions: [{
            label: 'Ok',
            type: 'neutral'
          }]
        })
    }

  }

}
