import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

import { PricingId, Pricing } from 'tp-traqplan-core/dist/data-structs';
import { defaultPipe } from './common';
import { StaffApiService } from './staff-api.service';

@Injectable({
  providedIn: 'root'
})
export class PricingApiService {

  constructor(
    private http: HttpClient,
    private staffApi: StaffApiService
  ) {}

  staffCreatePricing(options) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.post<{ pricing: Pricing }>(
          '/api/staff/pricings',
          options,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  staffDeletePricingById(id: PricingId) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.delete<any>(
          `/api/staff/pricings/${id}`,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  staffRequestPricings() {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.get<{ pricings: Pricing[] }>(
          '/api/staff/pricings',
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  staffRequestPricingById(id: PricingId) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.get<{ pricing: Pricing }>(
          `/api/staff/pricings/${id}`,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  staffUpdatePricingById(id: PricingId, options) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.patch<{ pricing: Pricing }>(
          `/api/staff/pricings/${id}`,
          options,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

}
