import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { InfoBoxRequest, InfoBoxInstance } from './structs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private infoQueue: InfoBoxInstance[] = [];
  private infoActive: InfoBoxInstance;

  public info$ = new Subject<InfoBoxInstance>();

  info(request: InfoBoxRequest): Promise<ModalWindowAction> {
    const promise = new Promise<ModalWindowAction>(resolve => {
      const boxInstance: InfoBoxInstance = {
        request: request,
        resolve: null
      };
      boxInstance.resolve = (action: ModalWindowAction) => {
        resolve(action);
        // shouldn't ever execute but just in case:
        if (this.infoActive !== boxInstance) {
          return console.error('Info modal has resolved, but it is not the active modal');
        }

        this.infoActive = null;
        this.evalQueue();
      };
      this.infoQueue.push(boxInstance);
    });

    const timer = setTimeout(() => {
      clearTimeout(timer);
      this.evalQueue();
    });

    return promise;
  }

  private evalQueue() {
    if (!this.infoActive && this.infoQueue.length) {
      this.infoActive = this.infoQueue.shift();
      this.info$.next(this.infoActive);
    }
  }
}
