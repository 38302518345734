import { NgModule } from '@angular/core';
import { DateFromNowPipe } from './date-from-now.pipe';
import { DateTimePipe } from './date-time.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  declarations: [
    DateFromNowPipe,
    DateTimePipe,
    SafeHtmlPipe
  ],
  imports: [],
  exports: [
    DateFromNowPipe,
    DateTimePipe,
    SafeHtmlPipe
  ]
})
export class PipesModule { }
