<div *ngIf="taskSelected" class="add-task-container" >
  <div class="timeline-add-task task-tooltip">
    <span class="tooltiptext">
      Click on the timeline below to add a new task
    </span>
  </div>

  <div class="grid-add-task task-tooltip">
    <span class="tooltiptext">
      Select the grid and click <strong class="task-plus"> "+" </strong> button to add a new task
    </span>
  </div>

  <div class="grid-show-task task-tooltip">
    <span class="tooltiptext">
      Select and click on the task box below to show it on the timeline
    </span>
  </div>
</div>
