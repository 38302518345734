import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { Store } from '@ngrx/store';
import { Subject, of } from 'rxjs';
import { map, catchError, takeUntil, withLatestFrom, debounceTime, take, tap } from 'rxjs/operators';
import { State } from '../../state';
import { namingConventionValidator } from '../../shared/validators/naming-convention.validator';
import { HierarchyNode } from 'tp-traqplan-core/dist/data-structs';
import * as modalActions from '../../state/modal/actions';
import { HierarchyApiService } from '../../api/hierarchy-api.service';
import { matchBasicSearchQuery } from '../../util/string';
import * as projectsActions from '../../state/projects/actions';

@Component({
  selector: 'app-copy-programme',
  templateUrl: './copy-programme.component.html',
  styleUrls: ['./copy-programme.component.scss']
})
export class CopyProgrammeComponent implements OnDestroy, OnInit {

  @Input() programme: HierarchyNode;

  title: string;

  actions: ModalWindowAction[] = [
    {
      label: 'Copy Project',
      type: 'positive',
      confirm: true,
      valid: () => (this.programme && this.programmeForm.valid),
      enterKey: true,
    },
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true,
      escKey: true
    }
  ];

  visible = true;

  programmeForm = new FormGroup({
    workspace: new FormControl('',
      [
        Validators.required,
        Validators.minLength(1),
        namingConventionValidator()
      ]
    ),
    name: new FormControl('',
      [
        Validators.required,
        Validators.minLength(1),
        namingConventionValidator()
      ]
    )
  },[],[
    () => {

      const workspace = this.programmeForm.controls.workspace.value;
      const name = this.programmeForm.controls.name.value;
      const programmeId = this.programme?.id;

      if (!workspace || !name || !programmeId) return of({ incomplete: true });

      return this.hierarchyApi.validateProgrammeMove({
        copy: true,
        targetName: name,
        targetId: this.programme?.id,
        workspaceName: (typeof workspace === 'string') ? workspace : workspace.name
      }).pipe(
        catchError(arg => {
          if (arg.error?.error) {
            return of({ errors: arg.error?.error ? [arg.error.error] : [] });
          }
        }),
        map(({ errors }) => {
          this.validationErrors = errors;
          return errors?.length ? { validationError: true } : null
        })
      );

    }
  ]);

  get workspaceControl(): FormControl {
    return this.programmeForm.controls.workspace;
  }

  validationErrors: string[];

  workspaceSuggestions: HierarchyNode[];

  isNewWorkspace: boolean;

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private hierarchyApi: HierarchyApiService
  ) { }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit() {

    if (this.programme) {

      this.title = `Copy Programme - ${this.programme.name}`;

      this.programmeForm.valueChanges.pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.store),
      ).subscribe(([changes, { account }]) => {
        this.isNewWorkspace = (changes.workspace?.length && !account.root.available.value.some(w => w.name === changes.workspace));
      });
    }

  }

  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }

  onActionSelected(action: ModalWindowAction): void {

    if (!action.confirm || !this.programme || !this.programmeForm.valid) {
      return;
    }

    const workspace = this.programmeForm.controls.workspace.value;
    const programmeId = this.programme?.id;
    const name = this.programmeForm.controls.name.value;

    this.hierarchyApi.moveProgramme({
      copy: true,
      targetId: programmeId,
      targetName: name,
      workspaceName: typeof workspace === 'string' ? workspace : workspace.name
    }).subscribe(() => {
      this.store.dispatch(
        projectsActions.requestProjectTree({})
      )
    });

  }

  searchWorkspaces({ query }): void {
    this.store.pipe(
      debounceTime(100),
      take(1),
      tap(state => {
        this.workspaceSuggestions = state.account.root?.available?.value?.filter(w => matchBasicSearchQuery(query, w.name)) || [];
      })
    ).subscribe();
  }

}
