<app-modal-window
  header="Request license upgrade"
  [actions]="actions"
  [(visible)]="visible"
  (actionSelected)="complete($event)"
  (closed)="complete()"
>

  <div class="message">You're license only permits you to view specific programmes and projects. Request a viewer license from your admin to create your own project timelines</div>

  <strong class="message">Send your admin a message:</strong>

  <textarea pInputTextarea [(ngModel)]="customMessage" placeholder="Add a custom message"></textarea>

</app-modal-window>
