import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../index';
import { Observable, Subject } from 'rxjs';
import { shareReplay, map, take, filter, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { HierarchyNode, HierarchyNodeId, TreeGrouping, TreeRevisionNode, TreeProjectNode } from 'tp-traqplan-core/dist/data-structs';
import { matchBasicSearchQuery } from 'src/app/util/string';
import { mapNodesById, getNodeDepth, isRevisionImportPending, getTreeGroupKey } from '../projects/helpers';
import { TreeFilterType, State as ProjectsState } from '../projects/state';
import { filterDSReady, filterDSChanged, isDataStoreReady } from '../helpers';

//const defaultGrouping = {
//  label: null,
//  value: null,
//  formatted: null
//};

@Injectable({
  providedIn: 'root'
})
export class ProjectsService implements OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(private store: Store<State>) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  programmes$: Observable<Map<HierarchyNodeId, HierarchyNode>> = this.store.pipe(
    takeUntil(this.destroy$),
    map((state: State) => state.projects.hierarchy),
    filterDSReady(),
    filterDSChanged(),
    map(({ value }) => {
      const nMap = mapNodesById(value);
      const pMap = new Map<HierarchyNodeId, HierarchyNode>();

      for (let i = 0; i < value.length; i++) {
        const n = value[i];
        if (getNodeDepth(n, nMap) === 1) {
          pMap.set(n.id, n);
        }
      }

      return pMap;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  tree$: Observable<{ archived: TreeProjectNode[]; imports: TreeRevisionNode[]; groupings: Map<string, TreeGrouping>; ungrouped: TreeProjectNode[]; selected: TreeProjectNode[]; lastSelected?: TreeProjectNode; lastSelectedProjects: TreeProjectNode[] }> = this.store.pipe(
    takeUntil(this.destroy$),
    distinctUntilChanged((a, b) => a.projects.hierarchy?.value === b.projects.hierarchy?.value && a.projects.tree === b.projects.tree && a.account === b.account && b.projects.favourites === a.projects.favourites && a.projects.revisionViews === b.projects.revisionViews),
    map(({ account, projects, poap }) => {

      const { hierarchy, tree, favourites, revisionViews, lastSelectedRevision } = projects;

      if (!isDataStoreReady(hierarchy)) return null;

      const archived: TreeProjectNode[] = [];
      const imports: TreeRevisionNode[] = [];
      const ungrouped: TreeProjectNode[] = [];
      const groupings = new Map<string, TreeGrouping>();
      const selected: TreeProjectNode[] = [];
      const starred = new Set<HierarchyNodeId>(favourites.value);
      const revisionsLookup = {};

      const sorted = filterNodes([...hierarchy.value], projects).sort((a, b) => a.name.localeCompare(b.name));

      const nodeMap = mapNodesById(sorted);

      this.processNode1({
        account, poap, tree, revisionViews, archived, imports, ungrouped, groupings, selected, starred, revisionsLookup, sorted, nodeMap
      });
      this.processNode2(groupings);

      // const selectLastestRevisionIfNonSelected = selected.map((select) => {
      //   // KIV - Do not work well with imported drafts
      //   // const { revisions } = select;

      //   // if (Array.isArray(revisions)) {
      //   //   const revisionSelected = revisions.some(({ selected }) => selected);

      //   //   if (!revisionSelected && revisions[0]) {
      //   //     revisions[0].selected = true;
      //   //     select.revisions = revisions;
      //   //   }
      //   // }

      //   return select;
      // });

      const lastSelectedProjects: TreeProjectNode[] = [];
      const isImportsSelected = imports.some(({ selected }) => selected);
      const importsMap = new Map();

      if (isImportsSelected) {
        const selectedNodesMap = new Map(selected.map((node) => [node.id, node]));

        for (let i = 0; i < imports.length; i++) {
          const treeRevisionNode = imports[i];
          const { project, selected } = treeRevisionNode;
          const node = selectedNodesMap.get(project);

          if (node && node.id) {
            lastSelectedProjects.push(<TreeProjectNode>{ ...node, revisions: [treeRevisionNode], selected });
            importsMap.set(node.id, node);
          }
        }
      }

      for (let i = 0; i < selected.length; i++) {
        const node = selected[i];

        if (node.selected && !importsMap.has(node.id)) {
          lastSelectedProjects.push(node);
        }
      }

      for (let i = 0; i < archived.length; i++) {
        const node = archived[i];

        if (node.selected) {
          lastSelectedProjects.push(node);
        }
      }

      const lastSelected = lastSelectedProjects.find(({ revisions }) => revisions?.some(({ id }) => id === lastSelectedRevision));
      return { groupings: sortGroupsRecursive(groupings), ungrouped, archived, imports, selected, selectedRevisions: poap.selectedRevisions, lastSelected, lastSelectedProjects };

    }),
    filter(v => v !== null),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private processNode1({
    account, poap, tree, revisionViews, archived, imports, ungrouped, groupings, selected, starred, revisionsLookup, sorted, nodeMap
  }) {
    for (const node of sorted) {

      const depth = getNodeDepth(node, nodeMap);

      // only include projects:
      if (depth !== 2) continue;

      const { revisions, drafts, treeNode } = this.initProcessNode1Data(account, tree, starred, nodeMap, node);

      if (node.revisions) {
        for (const revision of node.revisions) {
          (isRevisionImportPending(revision) ? imports : revision.published ? revisions : drafts).push({
            ...revision,
            parentNode: treeNode,
            projectName: node.name,
            selected: tree.selected[revision.id]
          });
        }
      }

      treeNode.revisions = [
        ...drafts.filter(({ view }) => view === null || view === poap?.viewSelected?.id).map((revision) => {
          const { published } = revision;
          revision.readOnly = !!published;
          return revision;
        }),
        ...revisions.sort((a, b) => b.published.getTime() - a.published.getTime()).filter((revision) => {
          const { id, view } = revision;
          const revisionLookup = revisionsLookup[id] || revisionViews.find(({ id: _id }) => _id === id);
          return id === revisionLookup?.id || view === null || view === poap?.viewSelected?.id;
        })
      ];

      if (!treeNode.revisionsExpanded) {
        treeNode.revisions = treeNode.revisions.slice(0, 3);
      }

      if (treeNode.archived) {
        archived.push(treeNode);
        continue;
      }

      treeNode.selected && selected.push(treeNode);

      let groupParent = null;
      let groupTier = groupings;
      const groupValues = [];
      // iterate all but last grouping tier:
      for (const groupable of tree.groups.slice(0, -1)) {

        const groupValue = groupable.value(treeNode);
        groupValues.push(groupValue);

        const groupKey = getTreeGroupKey(groupValues);

        // if no group object exists which matches then create one:
        if (!groupTier.has(groupValues[groupValues.length - 1])) {
          groupTier.set(groupValue, <TreeGrouping>{
            grouping: groupable.label,
            label: groupable.formatted(treeNode),
            value: groupValue,
            parent: groupParent,
            expanded: tree.expandedGroups[groupKey] || false,
            children: new Map<string | number | boolean, TreeGrouping>(),
            icon: groupable.icon,
          })
        }
        groupParent = groupTier.get(groupValue);
        groupTier = groupTier.get(groupValue).children;
      }

      const groupable = tree.groups[tree.groups.length - 1];

      if (!groupable) {
        ungrouped.push(treeNode);
        continue;
      }

      const groupValue = groupable.value(treeNode);
      groupValues.push(groupValue);

      const groupKey = getTreeGroupKey(groupValues);

      if (!groupTier.has(groupValue)) {
        groupTier.set(groupValue, <TreeGrouping>{
          grouping: groupable.label,
          label: groupable.formatted(treeNode),
          value: groupValue,
          parent: groupParent,
          expanded: tree.expandedGroups[groupKey] ? true : (treeNode.selected ? true : false),
          nodes: [],
          icon: groupable.icon
        });
      }

      //groupTier.get(groupValue).icon = starred.has(groupValue) ? 'star' : 'book';

      const group = groupTier.get(groupValue);

      if (group.nodes?.some(n => n.containsDraft)) {
        group.icon = 'pen';
        group.meta = { containsDraft: true };
      } else if (!group.icon && starred.has(groupValue)) {
        group.icon = 'star';
        group.meta = { favourite: true };
      }

      groupTier.get(groupValue).nodes.push(treeNode);

    }
  }

  private initProcessNode1Data(account, tree, starred, nodeMap, node) {
    const rootNode = nodeMap.get(nodeMap.get(node.parent)?.parent);
    const root = rootNode?.id;
    const revisions: TreeRevisionNode[] = [];
    const drafts: TreeRevisionNode[] = [];
    const treeNode: TreeProjectNode = {
      id: node.id,
      author: node.author,
      authorName: account.users[node.author]?.name || null,
      name: node.name,
      created: node.created,
      parent: node.parent,
      parentName: nodeMap.get(node.parent)?.name,
      root,
      revisions: [],
      revisionsExpanded: tree.revisionsExpanded[node.id] || false,
      allowImportedEdits: typeof node.allowImportedEdits === 'boolean' ? node.allowImportedEdits : rootNode.allowImportedEdits,
      archived: node.archived,
      archivedBy: node.archivedBy,
      permission: node.access,
      expanded: tree.expanded[node.id] || false,
      selected: node.revisions ? node.revisions.some(rev => tree.selected[rev.id]) : false,
      containsDraft: node.revisions ? node.revisions.some(rev => !rev.published) : false,
      favourite: starred.has(node.id),
      baselineRevision: node.baselineRevision,
      meta: {}
    };
    return { revisions, drafts, treeNode };
  }

  private processNode2(groupings) {
    for (const [key, grouping] of groupings.entries()) {
      const { icon, meta, nodes } = grouping;

      if (Array.isArray(nodes) && nodes.some((n) => n.containsDraft)) {
        if (meta) {
          grouping.meta.containsDraft = true;
        } else {
          grouping.meta = { containsDraft: true };
        }

        if (icon !== 'star') {
          grouping.icon = 'pen';
        }
      }

      groupings.set(key, grouping);
    }
  }

  getNodePath(id: HierarchyNodeId): Observable<HierarchyNodeId[]> {
    return this.store.pipe(
      takeUntil(this.destroy$),
      map(state => state.projects.hierarchy),
      filterDSReady(),
      take(1),
      map(hierarchy => {
        const idMap = new Map<HierarchyNodeId, HierarchyNode>(hierarchy.value.map(h => [h.id, h]));
        const path: HierarchyNodeId[] = [];
        let node = idMap.get(id);
        while (node) {
          path.unshift(node.id);
          node = idMap.get(node.parent);
        }
        return path;
      })
    );
  }

}

function sortGroupsRecursive(groupings: Map<string, TreeGrouping>): Map<string, TreeGrouping> {
  const sorted = new Map<string, TreeGrouping>([...groupings.entries()].sort(([, a], [, b]) => a.label.localeCompare(b.label)));
  for (const [, v] of sorted) {
    if (v.children) {
      v.children = sortGroupsRecursive(v.children);
    }
  }
  return sorted;
}

function filterNodes(hierarchyNodes: HierarchyNode[], state: ProjectsState): HierarchyNode[] {

  return hierarchyNodes.filter(node => {
    // filter only applies to projects:
    if (!node.revisions) {
      return true;
    }

    if (state.tree.filter.search?.length && !matchBasicSearchQuery(state.tree.filter.search, node.name)) {
      return false;
    }

    switch (state.tree.filter.filterType) {
      case TreeFilterType.favourites:
        if (!isProjectFavourite(node, hierarchyNodes, state.favourites.value)) {
          return false;
        }
        //if (state.favourites.value && !state.favourites.value.includes(node.id)) {
        //  return false;
        //}
        break;
      case TreeFilterType.drafts:
        if (node.revisions.every(rev => rev.published)) {
          return false;
        }
        break;
      case TreeFilterType.selected:
        if (!node.revisions.some(rev => state.tree.selected[rev.id])) {
          return false;
        }
        break;
    }

    return true;

  });

}

function isProjectFavourite(node, nodes: HierarchyNode[], favourites: HierarchyNodeId[]): boolean {

  if (favourites.includes(node.id)) {
    return true;
  }

  const parent = nodes.find(n => n.id === node.parent);

  if (parent && favourites.includes(parent.id)) {
    return true;
  }

  //if (node.children) {
  //  return node.children.some(id => {
  //    const child = nodes.find(n => n.id === id);
  //    return child ? isProjectFavourite(child, nodes, favourites) : false;
  //  });
  //}

  return false;

}
