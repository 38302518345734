import * as PowerBi from '../../../powerbi-data-structs';

import Subscription = PowerBi.Subscription;

export const key = 'powerbi.subscriptions';

export interface State {
  subscription: Subscription,
  subscriptions: Subscription[]
}

export const initialState: State = {
  subscription: null,
  subscriptions: []
};

export function beforeSave(state) {
  return {
    ...state
  };
}

export function beforeLoad(state) {
  return state;
}
