<div class="page">
  <div class="header">
    <span class="tp-header-1">Reset Password</span>
  </div>
  <form
    action=""
    class="tp-form"
    [formGroup]="form"
    (submit)="onSubmit()"
  >
    <div class="tp-form__field">
      <label for="email">Enter your email:</label>
      <input
        type="text"
        name="email"
        pInputText
        required
        autofocus
        autocomplete="email"
        formControlName="email"
      />
    </div>

    <div class="tp-form__error" *ngIf="email.errors?.email">
      <span>Please enter a valid email address</span>
    </div>

    <div class="tp-form__error" *ngIf="error">
      <span>{{error || "Server error. Please contact your system administrator"}}</span>
    </div>

    <button
      class="tp-form__button"
      type="submit"
    >
      Send Reset Link
    </button>

  </form>

</div>
