import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { State } from '../state';
import * as authActions from '../state/auth/actions';
import { State as AuthState } from '../state/auth/state';

enum Provider {
  Local,
  Google,
}

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent implements OnDestroy, OnInit {

  @ViewChild('loginForm') loginForm: NgForm;
  @ViewChild('forgotPasswordForm') forgotPasswordForm: NgForm;

  public inputEmail: string;
  public inputPwd: string;

  public auth$: Observable<AuthState>;

  //public loggedInStates = LoggedInState;
  public logIn$: Subject<Provider> = new Subject();

  public tenancy = environment.tenancy;

  private destroy$ = new Subject<void>();

  constructor(private router: Router, private store: Store<State>) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {

    this.auth$ = this.store.pipe(
      takeUntil(this.destroy$),
      map(({ auth }): AuthState => auth),
      tap((auth) => {
        if (auth.token) {
          this.router.navigate(['']);
        }
        /*if (auth.ssoToken) {
          window.location.href = auth.origin + '/login';
        }*/
      })
    );

    // IF BROWSER UNSUPPORTED:
    /*if (!/Safari|Chrome/i.test(navigator.userAgent) || /Edge/.test(navigator.userAgent)) {
      document.body.style.setProperty('pointer-events', 'none');
      setTimeout(() => $('.ui.modal.if-not-chrome').modal({ closable: false }).modal('show'), 1000);
      return;
    }*/

    /*  const queryParams = this.route.snapshot.queryParamMap;
      this.displayResetPasswordSuccess = !!queryParams.get('reset');

      if (queryParams.has('forgot-password')) {
        this.forgotPassword();
        this.router.navigate(['login']);
      }

      this.store = merge(
        this.authService.getLoggedInState().pipe(
          map(s => ({
            state: s,
            error: null,
            submitting: false,
          }))
        ),
        this.logIn$.asObservable().pipe(
          switchMap((provider) => {
            return merge(
              of({
                error: null,
                submitting: true,
                state: LoggedInState.Unknown,
              }),
              iif(
                () => provider === Provider.Local,
                this.authService.logIn(this.loginData),
                this.authService.logInWithGoogle(),
              ).pipe(
                tap(this.signInSuccess.bind(this)),
                mapTo({
                  state: LoggedInState.LoggedIn,
                  error: null,
                  submitting: false,
                }),
                catchError(err => {

                  return of({
                    state: LoggedInState.NotLoggedIn,
                    error: 'Login Failed',
                    submitting: false,
                  }).pipe(
                    delay(500),
                  );
                })
              )
            );
          })
        ),
      ).pipe(
        map(({ state, error, submitting }) => {
          return {
            lis: state,
            error: error,
            submitting,
          };
        }),
      );*/
  }

  /*googleSignIn() {
    //this.logIn$.next(Provider.Google);
  }*/

  signIn() {
    this.store.dispatch(authActions.attemptLogin({
      email: this.inputEmail,
      password: this.inputPwd
    }))
  }

  submitForgotPassword(): void {
    //  if (this.forgotPasswordForm.valid) {
    //    this.authService.resetPassword(this.forgotPasswordForm.value.email).pipe(take(1)).subscribe(() => {
    //      this.forgotPasswordSuccessful = true;
    //      this.forgotPwMessage = 'If the address you provided matches our records, you will receive an email.';
    //      this.forgotPasswordData = {};
    //      this.forgotPasswordForm.resetForm();
    //    }, err => {
    //      this.forgotPasswordSuccessful = false;
    //      this.forgotPasswordData = {};
    //      this.forgotPwMessage = 'There was an issue attempting to reset your password. Please try again.';
    //      this.forgotPasswordForm.resetForm();
    //    });

    //  } else {
    //    this.forgotPwMessage = undefined;
    //  }
  }
}
