import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthApiService } from './auth-api.service';
import { defaultPipe } from './common';
import { ReqHeader } from './structs';
import { Notification } from '../data-structs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private http: HttpClient,
    private authApi: AuthApiService
  ) { }

  notifications$ = new ReplaySubject<Notification[]>(1);

  pollIntervalId: any;

  getLatest(): Observable<Notification[]> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<Notification[]> => {
        return this.http.get<any>(
          '/api/user-messages/latest',
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  setReadState({ value, messageId }): Observable<void> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<void> => {
        return this.http.post<void>(
          '/api/user-messages/read',
          { value, messageId },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  startPollingNotifications(): void {
    this.getLatest().subscribe(n => n?.length && this.notifications$.next(n));
    if (!this.pollIntervalId) {
      this.pollIntervalId = setInterval(() => {
        this.getLatest().subscribe(n => n?.length && this.notifications$.next(n));
      }, 30000);
    }
  }

  stopPollingNoticiations(): void {
    if (this.pollIntervalId) {
      clearInterval(this.pollIntervalId);
      this.pollIntervalId = null;
    }
  }

  sendAccessRequest({ id, message }): Observable<any> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<any> => {
        return this.http.post<any>(
          '/api/user-messages/request-access',
          { id, message },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  sendLicenseRequest({ message }): Observable<any> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<any> => {
        return this.http.post<any>(
          '/api/user-messages/request-license',
          { message },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

}
