import { Subject } from 'rxjs';
import { filter, takeUntil, map } from 'rxjs/operators';

export class TimelineEventChannel {

  private complete$ = new Subject<boolean>();
  // pubsub event channel
  private events$ = new Subject<{ event:string, detail:any }>();

  complete() {
    this.complete$.next(true);
  }

  publish(event:string, detail?:any) {
    this.events$.next({ event, detail });
  }

  subscribe(event: string, callback: (...args: any[]) => void ) {
    return this.events$.pipe(
      takeUntil(this.complete$),
      filter(e => e.event === event),
      map(e => e.detail)
    ).subscribe(detail => {
      setTimeout(() => callback(detail) );
    });
  }

}
