exports.serializeWithDate = function(object) {
  return JSON.stringify(exports.wrapDatesRecursive(object));
};

exports.parseWithDate = function(object) {
  return exports.unwrapDatesRecursive(JSON.parse(object));
};

const safeDateString = '__$%SAFEDATESTRING%$__';

exports.wrapDatesRecursive = function(object) {
  if (typeof object !== 'object' || object === null) {
    return object;
  }
  const clone = Array.isArray(object) ? [] : {};
  for (const [k, v] of Object.entries(object)) {
    if (v instanceof Date) {
      clone[k] = [safeDateString, v.getTime()];
    } else
    if (typeof v === 'object' && v !== null && (v.constructor === Array || v.constructor === Object)) {
      clone[k] = exports.wrapDatesRecursive(v);
    } else {
      clone[k] = v;
    }
  }
  return clone;
}

exports.unwrapDatesRecursive = function(object) {
  if (typeof object !== 'object' || object === null) {
    return object;
  }
  const clone = Array.isArray(object) ? [] : {};
  for (const [k, v] of Object.entries(object)) {
    if (Array.isArray(v) && v.length === 2 && v[0] === safeDateString) {
      clone[k] = new Date(v[1]);
    } else
    if (typeof v === 'object' && v !== null && (v.constructor === Array || v.constructor === Object)) {
      clone[k] = exports.unwrapDatesRecursive(v);
    } else {
      clone[k] = v;
    }
  }
  return clone;
}
