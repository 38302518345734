import { createReducer, on } from '@ngrx/store';
import { initialState } from './state';
import * as pricingsActions from './actions';

export const reducer = createReducer(
  initialState,

  on(pricingsActions.staffRequestPricingsSuccess, (state, props) => {
    return {
      ...state,
      pricings: props.pricings
    };
  }),

  on(pricingsActions.staffRequestPricingByIdSuccess, (state, props) => {
    return {
      ...state,
      pricing: props.pricing
    };
  })

);
