import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../state';
import { updateToken, logout } from '../state/auth/actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private store$: Store<State>
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(httpEvent => {
        // the request should return an updated token, extending the client session time:
        if (httpEvent instanceof HttpResponse) {
          const token = httpEvent.headers.get('X-Token');
          if (token?.length) {
            this.store$.dispatch(updateToken({ token }));
          }
        }
      }),
      catchError((httpError: HttpErrorResponse) => {
        if (httpError.status === 401) {
          this.store$.dispatch(logout({}));
        }
        return throwError(httpError);
      })
    );
  }
}
