const { forbiddenError } = require('../error.js');
// const { validateUUID } = require('../uuid.js');
const structs = require('./structs.js');
const { actionDescriptions, licenseDescriptions, groupDescriptions, actionOrder, groupOrder, groupNames } = require('./descriptions.js');

exports.none = 0;

exports.actionType = structs.actionType;
exports.actionIncludes = structs.actionIncludes;
exports.licenseType = structs.licenseType;
exports.context = structs.context;
exports.leafAuthorDefault = structs.leafAuthorDefault;
exports.rootAuthorDefault = structs.rootAuthorDefault;
exports.globalAuthorDefault = structs.globalAuthorDefault;
exports.actionOrder = actionOrder;
exports.actionGroups = structs.groups;
exports.groupDescriptions = groupDescriptions;
exports.groupOrder = groupOrder;
exports.groupNames = groupNames;
exports.childInferrable = structs.childInferrable;
/**
 * Map both the actionType names and their respective values to their descriptions
 * i.e actionDescription[1] === actionDescription.readBranch === 'lorem ipsum...'
 */
const actionDescription = {};
for (let [k,v] of Object.entries(structs.actionType)) {
  if (actionDescriptions[k]) {
    actionDescription[k] = actionDescriptions[k];
    actionDescription[v] = actionDescriptions[k];
  }
}
exports.actionDescription = Object.freeze(actionDescription);

const licenseDescription = {};
for (let [k, v] of Object.entries(structs.licenseType)) {
  if (licenseDescriptions[k]) {
    licenseDescription[k] = licenseDescriptions[k];
    licenseDescription[v] = licenseDescriptions[k];
  }
}
exports.licenseDescription = Object.freeze(licenseDescription);

/**
 * If access can be revoked, returns the new permission value:
 * @param {Access} access
 * @param {AccessType} action
 * @return {AccessType}
 */
exports.revoke = function(access, type) {
  return (access.permission | type) ^ type;
};

/**
 * Recieve a list of access assignments and return the accumulated access for the lowest specified context.
 * For each access assignment above the end target, all cascading permissions are accumulated
 * @param {...AccessAssignment}
 * @returns {AccessType}
 */
exports.cascade = function(...assignments) {

  const filtered = assignments.filter(a => typeof a === 'object');
  const sorted = filtered.sort((a,b) => a.path.length - b.path.length);
  const cascade = sorted.slice(0,-1);
  const target = sorted[cascade.length];
  let acc = 0;

  for (const access of cascade) {
    const types = split(access.permission);
    for (const type of types) {
      if (includes(structs.cascadeActions, type)) {
        acc = combine(acc, type);
      }
    }
  }
  acc = combine(acc, target.permission);
  return acc;
};

/**
 * Checks whether the permissions cap for the license type includes the specified permission:
 * @param {License} license
 * @param {AccessType} type
 * @returns {boolean}
 */
exports.licenseAllowable = function(license, type) {
  return !!(license?.type && includes(structs.licenseAssignable[license.type], type));
}

/**
 * Returns true if the specified assignment is valid and assignable to the specified license
 * @param {License} license
 * @param {AccessAssignment}
 * @returns {boolean}
 */
exports.assignable = function(license, assignment) {
  if (!structs.licenseType[license.type]) {
    throw forbiddenError(`Specified license type does not exist: '${license.type}'`);
  }
  const pathContext = exports.getPathContext(assignment.path);
  // const licContext = exports.getLicenseContext(license);
  // if context of assignment is 'above' context of license then cannot assign:
  // if (pathContext < licContext) {
  //   return false;
  // }

  // if context does not permit assignment:
  if (!includes(structs.contextAssignable[pathContext], assignment.permission)) {
    return false;
  }
  // if license does not permit assignment:
  // CHANGED - permissions can be assigned beyond access limit but not used unless license is upgraded
  // if (!includes(structs.licenseAssignable[license.type], assignment.permission)) {
  //   return false;
  // }
  return true;
};

/**
 * Determines whether the combination of license and assignments grants the specifed permission type
 * @param {License} license
 * @param {AccessAssignment[]} assignments
 * @param {AccessType} type
 * @returns {boolean}
 */
exports.includes = function(license, assignments, type) {
  if (!structs.licenseType[license.type]) {
    throw forbiddenError(`Specified license type does not exist: '${license.type}'`);
  } 
  const merged = exports.merge(license, assignments);
  return includes(merged, type);
};

/**
 * Determines whether the combination of license and assignments either grants the specified permission
 * explicitly or whether the permission can be inferred from the child assignments
 */
exports.inferrable = function(license, assignments, childAssignments, type) {
  const included = exports.includes(license, assignments, type);
  if (included) {
    return true;
  }
  // else determine whether child assignments can infer the required permission:
  const inferrable = structs.childInferrable[type];
  if (inferrable) {
    // we are not interested in how the permissions cascade, simply whether they are there,
    // so do a 'blind' merging
    const combined = combine(childAssignments.map(a => a.permission));
    return exports.permissionOverlaps(combined, inferrable);
  }
  return false;
}

/**
 * returns true if any permissions in a also appear in b
 */
exports.permissionOverlaps = function(a,b) {
  return (a & b) > 0
}

exports.permissionIncludes = function(permission, type) {
  return includes(permission, type);
};

exports.merge = function(license, assignments) {
  const base = getLicenseAssignment(license);
  const merged = exports.cascade(base, ...assignments);
  // only include the permissions allowable by the license type:
  return merged & structs.licenseAssignable[license.type];
};

exports.combine = function(...a) {
  return combine(...a);
}

/**
 * create a new assignment object including the provided permissions:
 * the assignment will include all 'implied' permission types, however it will not verify the validity of the assignment
 * @param {AccessType} type
 * @param {HierarchyNodeId[]} path
 * @returns {AccessAssignment}
 */
exports.create = function(type, path) {
  const types = split(type);
  let permission = 0;
  for (let i=0; i < types.length; i++) {
    permission = permission | structs.actionIncludes[types[i]];
  }
  return { permission, path };
};

/**
 * Determine whether the path is in global, root (workspace) or leaf context
 * @param {HierarchyNodeId[]} path
 * @returns {AccessContext}
 */
exports.getPathContext = function(path) {
  return path.length === 0 ? structs.context.global: path.length === 1 ? structs.context.root : structs.context.leaf;
};

// /**
// * Determine whether the license is in global or root (workspace) context.
// * @param {License} license
// * @returns {AccessContext}
// */
//exports.getLicenseContext = function(license) {
//  return license.workspace?.length ? structs.context.root : structs.context.global;
//}

/**
 * Split the AccessType (integer) into an array of numbers corresponding to the 'on' bits.
 * @param {AccessType}
 * @returns {AccessType[]}
 */
function split(int) {
  const bits = [];
  for (let i=1; i <= int; i*=2) {
    if ((int & i) === i) {
      bits.push(i);
    }
  }
  return bits;
}

function combine(...actions) {
  return actions.reduce((a,c) => a | c, 0);
}

function includes(a,b) {
  return (a & b) === b;
}

function getLicenseAssignment(license) {
  //const context = exports.getLicenseContext(license);
  const permission = structs.licenseDefault[license.type];
  //const path = context === structs.context.global ? [] : [ license.root ];
  return { permission, path:[] };
}
