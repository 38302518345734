
exports.passwordRequires = 'Passwords must be atleast 8 characters long, include at least 1 special character and 1 Uppercase letter';


exports.passwordTooWeak = 'Password not strong enough. Avoid common words, patterns, or personal info.';

const weakPasswords = [
  "password",
  "password!",
  "Password!1",
  "123456",
  "Qwerty!",
  "123456789",
  "12345678",
  "12345",
  "1234567",
  "1234567890",
  "Abc123",
  "iloveyou",
  "Iloveyou!",
  "admin",
  "letmein",
  "welcome",
  "monkey",
  "123123",
  "sunshine",
  "princess",
  "password1",
  "qwerty123",
  "football",
  "qwertyuiop",
  "1234",
  "trustno1",
  "123456789",
  "password123",
  "superman",
  "passw0rd",
  "baseball",
  "iloveyou1",
  "qwerty1234"
];

exports.isValidPassword = function(pwd) {

  if (exports.validatePassword(pwd) === null) {
    return true;
  }
  return false;

};

exports.validatePassword = function(pwd) {

  if (pwd.length < 8 || !/[!@#$%^&*_\-+?]/.test(pwd) || !/[A-Z]/.test(pwd)) {
    return { error: exports.passwordRequires };
  }

  if (weakPasswords.includes(pwd)) {
    return { error: exports.passwordTooWeak}
  }

  return null;
};


