
//exports.RGX_VALID_NAME = /^([A-Za-z0-9_]+\s*)+$/;
exports.RGX_VALID_NAME = /^[^|\\/]+$/;

exports.isValidName = function(value) {
  const trimmed = value?.trim();
  if (!trimmed?.length) {
    return false;
  }
  if (!exports.RGX_VALID_NAME.test(trimmed)) {
    return false;
  }
  return true;
};