import { createAction, props } from '@ngrx/store';
import { RequestError } from '../../api/structs';
import { Pricing, PricingId } from 'tp-traqplan-core/dist/data-structs';

export const staffRequestPricings = createAction(
  '[pricing] staffRequestPricings',
  props<any>()
);

export const staffRequestPricingsSuccess = createAction(
  '[pricing] staffRequestPricingsSuccess',
  props<{ pricings: Pricing[] }>()
);

export const staffRequestPricingsFailure = createAction(
  '[pricing] staffRequestPricingsFailure',
  props<{ error: RequestError }>()
);

export const staffRequestPricingById = createAction(
  '[pricing] staffRequestPricingById',
  props<{ id: PricingId }>()
);

export const staffRequestPricingByIdSuccess = createAction(
  '[pricing] staffRequestPricingByIdSuccess',
  props<{ pricing: Pricing }>()
);

export const staffRequestPricingByIdFailure = createAction(
  '[pricing] staffRequestPricingByIdFailure',
  props<{ error: RequestError }>()
);
