import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UserTncAcceptanceApiService } from '../../../api/power-bi/user-tnc-acceptance-api.service';
import { RequestError } from '../../../api/structs';
import * as userTncAcceptancesActions from './actions';

@Injectable()
export class UserTncAcceptanceEffects {

  constructor(
    private actions$: Actions,
    private userTncAcceptanceApi: UserTncAcceptanceApiService
  ) {}

  requestUserTncAcceptances$ = createEffect(() => this.actions$.pipe(
    ofType(userTncAcceptancesActions.requestUserTncAcceptances),
    switchMap((): Observable<Action> => {
      return this.userTncAcceptanceApi.requestUserTncAcceptances().pipe(
        switchMap(({ userTncAcceptances }: any) => [
          userTncAcceptancesActions.requestUserTncAcceptancesSuccess(<any>{ userTncAcceptances })
        ]),
        catchError((error: RequestError) => {
          return of(userTncAcceptancesActions.requestUserTncAcceptancesFailure({ error }))
        })
      );
    })
  ));

  requestUserTncAcceptanceById$ = createEffect(() => this.actions$.pipe(
    ofType(userTncAcceptancesActions.requestUserTncAcceptanceById),
    switchMap(({ id }): Observable<Action> => {
      return this.userTncAcceptanceApi.requestUserTncAcceptanceById(id).pipe(
        switchMap((userTncAcceptance) => [
          userTncAcceptancesActions.requestUserTncAcceptanceByIdSuccess(<any>{ userTncAcceptance })
        ]),
        catchError((error: RequestError) => {
          return of(userTncAcceptancesActions.requestUserTncAcceptanceByIdFailure({ error }))
        })
      );
    })
  ));

}
