import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of, EMPTY } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { HierarchyAccessListNode, HierarchyNode } from 'tp-traqplan-core/dist/data-structs';
import { State } from '../';
import { AccountApiService } from '../../api/account-api.service';
import { HierarchyApiService } from '../../api/hierarchy-api.service';
import { RequestError } from '../../api/structs';
import * as accountActions from './actions';

@Injectable()
export class AccountEffects {

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private accountApi: AccountApiService,
    private hierarchyApi: HierarchyApiService,
    private router: Router
  ) { }

  accountCreated$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.accountCreated),
    switchMap(() => [
      accountActions.requestAvailableRoots(),
      accountActions.requestUsers()
    ])
  ));

  requestAvailable$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.requestAvailable),
    withLatestFrom(this.store),
    switchMap(([, state]): Observable<Action> => {
      return this.accountApi.requestAvailable().pipe(
        switchMap(({ accounts }) => {

          const selected = state.account.selected && accounts.find(a => a.id === state.account.selected.id);

          const actions: any[] = [
            accountActions.requestAvailableSuccess({ accounts })
          ];

          selected && actions.push(accountActions.launchAccount({ account: selected.id }));
          return actions;
        }),
        catchError((error: RequestError) => {
          return of(accountActions.requestAvailableFailure({ error }))
        })
      );
    })
  ));

  launchAccount$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.launchAccount),
    withLatestFrom(this.store),
    switchMap(([{ account, navigate }, store]): Observable<Action> => {
      if (store.account.available.value?.length) {
        return of(accountActions.launchAccountSuccess({ account, navigate }));
      }
      return this.accountApi.requestAvailable().pipe(
        switchMap(({ accounts }) => [
          accountActions.requestAvailableSuccess({ accounts }),
          accountActions.launchAccountSuccess({ account, navigate })
        ]),
        catchError(() => {
          return of(accountActions.launchAccountFailure({ account }))
        })
      );
    })
  ));

  launchAccountSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.launchAccountSuccess),
    tap(({ navigate }) => {
      if (navigate) {
        this.router.navigate(navigate);
      }
    })
  ), { dispatch: false });

  requestAvailableRoots$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.requestAvailableRoots),
    withLatestFrom(this.store),
    switchMap(([, state]): Observable<Action> => {
      if (state.account.selected) {
        const account = state.account.selected.id;
        return this.accountApi.requestRoots(account).pipe(
          switchMap((roots: HierarchyNode[]) => [
            accountActions.requestAvailableRootsSuccess({ account, roots })
          ]),
          catchError(() => {
            return of(accountActions.requestAvailableRootsFailure({ account }))
          })
        );
      }
      return of(accountActions.requestAvailableRootsFailure({ account: null }));
    })
  ));

  requestLicenses$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.requestLicenses),
    switchMap((): Observable<Action> => {
      return this.accountApi.requestLicenses().pipe(
        switchMap(({ licenses }) => [
          accountActions.requestLicensesSuccess({ licenses })
        ]),
        catchError((error: RequestError) => {
          return of(accountActions.requestLicensesFailure({ error }))
        })
      );
    })
  ));

  requestAdmins$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.requestAdmins),
    switchMap((): Observable<Action> => {
      return this.accountApi.requestAdmins().pipe(
        switchMap(({ admins }) => [
          accountActions.requestAdminsSuccess({ admins })
        ]),
        catchError((error: RequestError) => {
          return of(accountActions.requestAdminsFailure({ error }))
        })
      )
    })
  ));

  requestUsers$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.requestUsers),
    switchMap((): Observable<Action> => {
      return this.accountApi.requestUsers().pipe(
        switchMap(({ users }) => [
          accountActions.requestUsersSuccess({ users })
        ]),
        catchError((error: RequestError) => {
          return of(accountActions.requestUsersFailure({ error }))
        })
      )
    })
  ));

  unassignLicense$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.unassignLicense),
    switchMap(({ id }): Observable<Action> => {
      return this.accountApi.removeUser(id).pipe(
        catchError(() => {
          return EMPTY;
        }),
        switchMap(() => [
          accountActions.unassignLicenseSuccess()
        ]),
      );
    })
  ));

  unassignLicenseSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.unassignLicenseSuccess),
    switchMap(() => [
      accountActions.requestLicenses(),
      accountActions.requestUsers()
    ])
  ));

  assignAccess$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.assignAccess),
    switchMap(({ path, userId, permission }) => {
      return this.accountApi.setAccess({ path, userId, permission }).pipe(
        switchMap(() => {
          return of(accountActions.assignAccessSuccess({}))
        })
      );
    })
  ));

  requestHierarchyPermissions$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.requestHierarchyPermissions),
    switchMap((): Observable<Action> => {
      return this.hierarchyApi.getAccessHierarchy().pipe(
        switchMap((hierarchyPermissions: HierarchyAccessListNode[]) => [
          accountActions.requestHierarchyPermissionsSuccess({ hierarchyPermissions })
        ]),
        catchError((error: RequestError) => {
          return of(accountActions.requestHierarchyPermissionsFailure({ error }))
        })
      );
    })
  ));
}
