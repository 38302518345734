
export const key = 'userTncAcceptance';

export interface State {
  userTncAcceptance: any,
  userTncAcceptances: any[]
}

export const initialState: State = {
  userTncAcceptance: null,
  userTncAcceptances: []
};

export function beforeSave(state) {
  return {
    ...state
  };
}

export function beforeLoad(state) {
  return state;
}
