
exports.colours = new Map([
  [ 'Default', '#457fb0' ],
  [ 'Black', '#000000' ],
  [ 'Blue', '#0000ff' ],
  [ 'BlueViolet', '#8a2be2' ],
  [ 'DarkSeaGreen', '#8fbc8f'],
  [ 'Gray', '#808080' ],
  [ 'Green', '#008000' ],
  [ 'LightSteelBlue', '#b0c4de' ],
  [ 'LightSkyBlue', '#87cefa' ],
  [ 'Orange', '#ffa500' ],
  [ 'Olive', '#808000' ],
  [ 'Red', '#ff0000' ],
  [ 'SteelBlue', '#4682b4' ],
  [ 'Salmon', '#fa8072' ],
  [ 'SaddleBrown', '#8b4513' ],
  [ 'Tan', '#d2b48c' ],
  [ 'Teal', '#008080' ],
  [ 'Thistle', '#d8bfd8' ],
  [ 'Tomato', '#ff6347' ]
]);

exports.generateSymbolMarkup = function(symbol, boundarySize, fill) {

  const useSymbol = exports.symbolFactories[symbol] ? symbol : exports.milestoneDefaultSymbol;
  const useFill = exports.colours.has(fill) ? fill : exports.milestoneDefaultFill;

  return exports.symbolFactories[useSymbol](boundarySize, useFill);
}

exports.symbolFactories = {
  'diamond-thin': (s,f) => `
    <svg width="${s||10}" height="${s||10}" viewBox="0 0 10 10">
      <path
        d="M0,-4.999999858901178L2.8867512644846856,0L0,4.999999858901178L-2.8867512644846856,0Z"
        fill="${f||'black'}"
        transform="translate(5,5)">
      </path>
    </svg>
  `,
  'circle': (s,f) => `
    <svg width="${s||10}" height="${s||10}" viewBox="0 0 10 10">
      <path
        d="M4.999999944459307,0A4.999999944459307,4.999999944459307,0,1,1,-4.999999944459307,0A4.999999944459307,4.999999944459307,0,1,1,4.999999944459307,0"
        fill="${f||'black'}"
        transform="translate(5,5)">
      </path>
    </svg>
  `,
  'diamond-fat': (s,f) => `
    <svg width="${s||10}" height="${s||10}" viewBox="0 0 10 10">
      <path
        d="M-3.5355348605107526,-3.5355348605107526h7.071069721021505v7.071069721021505h-7.071069721021505Z"
        fill="${f||'black'}"
        transform="translate(5,5) rotate(45)">
      </path>
    </svg>
  `,
  'square': (s,f) => `
    <svg width="${s||10}" height="${s||10}" viewBox="0 0 10 10">
      <path
        d="M-4.999999939365751,-4.999999939365751h9.999999878731503v9.999999878731503h-9.999999878731503Z"
        fill="${f||'black'}"
        transform="translate(5,5)">
      </path>
    </svg>
  `,
  'cross': (s,f) => `
    <svg width="${s||10}" height="${s||10}" viewBox="0 0 10 10">
      <path
        d="M-4.999999393468894,-1.6666664644896314L-1.6666664644896314,-1.6666664644896314L-1.6666664644896314,-4.999999393468894L1.6666664644896314,-4.999999393468894L1.6666664644896314,-1.6666664644896314L4.999999393468894,-1.6666664644896314L4.999999393468894,1.6666664644896314L1.6666664644896314,1.6666664644896314L1.6666664644896314,4.999999393468894L-1.6666664644896314,4.999999393468894L-1.6666664644896314,1.6666664644896314L-4.999999393468894,1.6666664644896314Z"
        fill="${f||'black'}"
        transform="translate(5,5)">
      </path>
    </svg>
  `,
  'star': (s,f) => `
    <svg width="${s||10}" height="${s||10}" viewBox="0 0 10 10">
      <path
        d="M0,-5.257311414526464L1.180339953356788,-1.624598571810071L5.000000278978285,-1.6245985718100715L1.9098301628107484,0.6205414363569103L3.0901701161675375,4.253254279073303L1.1102230246251565e-16,2.0081142709063213L-3.090170116167536,4.253254279073303L-1.9098301628107484,0.6205414363569104L-5.000000278978286,-1.6245985718100704L-1.1803399533567882,-1.624598571810071Z"
        fill="${f||'black'}"
        transform="translate(5,5.5)">
      </path>
    </svg>
  `,
  'triangle-up': (s,f) => `
    <svg width="${s||10}" height="${s||10}" viewBox="0 0 10 10">
      <path
        d="M0,-5.773502883329125L5.0000001657857265,2.8867514416645625L-5.0000001657857265,2.8867514416645625Z"
        fill="${f||'black'}"
        transform="translate(5,6.4)">
      </path>
    </svg>
  `,
  'triangle-down': (s,f) => `
    <svg width="${s||10}" height="${s||10}" viewBox="0 0 10 10">
      <path
        d="M0,-5.773502430431771L4.999999773565112,2.8867512152158854L-4.999999773565112,2.8867512152158854Z"
        fill="${f||'black'}"
        transform="translate(5,3.6) rotate(180)">
      </path>
    </svg>
  `,
  'wye': (s,f) => `
    <svg width="${s||10}" height="${s||10}" viewBox="0 0 10 10">
      <path
        d="M1.8301269639511617,1.056624295288396L1.8301269639511617,4.71687822319072L-1.8301269639511617,4.71687822319072L-1.830126963951162,1.0566242952883957L-4.99999984981635,-0.7735026686627662L-3.1698728858651877,-3.9433755545279534L1.1102230246251565e-16,-2.1132485905767915L3.1698728858651877,-3.9433755545279534L4.99999984981635,-0.7735026686627662Z"
        fill="${f||'black'}"
        transform="translate(5,4.6)">
      </path>
    </svg>
  `
};

exports.milestoneDefaultSymbol = 'diamond-fat';

exports.milestoneDefaultFill = 'Black';
