<svg
  #svgContainer
  id="svgContainer"
  [attr.height]="height"
  [attr.width]="width"
  [attr.class]="scopeUID"
  (click)="interactions?.clicked($event)"
  (dblclick)="interactions?.dblClicked($event)"
  (mouseover)="interactions?.mouseover($event)"
  (mouseout)="interactions?.mouseout($event)"
  (pointerdown)="interactions?.pointerdown($event)"
  (pointermove)="interactions?.pointermove($event)"
  (contextmenu)="interactions?.contextmenu($event)"
>
  <defs>
    <!--<clipPath [attr.id]="hClipId">
      <rect id="viewportHClip" width="100%" height="10000"></rect>
    </clipPath>-->
    <clipPath [attr.id]="vClipId">
      <rect id="viewportVClip" width="100%" height="10000"></rect>
    </clipPath>
  </defs>
  <g id="legend">

  </g>
  <g id="xAxis"
    class="tl-axis">
  </g>
  <line id="xAxisBorder" x1="0" x2="100%" ></line>
  <g
    id="xAxisOffset"
    [attr.class]="style.standardClass.axisOffsetArea"
    [attr.clip-path]="vClipUrl"
  >
    <g id="tick-markers"></g>
    <g
      id="yScrollGroup"
      [attr.class]="style.standardClass.scrollOffsetArea"
    >
    </g>
    <g
      id="group-drag"
      [attr.class]="style.standardClass.groupDrag"
      data-tl-drag="groupWidth"
    >
      <line
        id="group-drag__bg"
        [attr.class]="style.standardClass.groupDragMask"
        y1="0" y2="100%"
      ></line>
      <line
        id="group-drag__c"
        [attr.class]="style.standardClass.groupDragLine"
        y1="0" y2="100%"
      ></line>
    </g>
    <g id="printLines"></g>
    <g id="viewLines"></g>
  </g>
</svg>

<ng-container *ngIf="!readOnly && interactions">
  <app-timeline-context-menu
    [interactions]="interactions"
  ></app-timeline-context-menu>
</ng-container>
