import { createAction, props } from '@ngrx/store';
import { RequestError } from '../../../api/structs';
import * as PowerBi from '../../../powerbi-data-structs';

import PricingPlan = PowerBi.PricingPlan;
import PricingPlanId = PowerBi.PricingPlanId;

export const requestPricingPlans = createAction(
  '[powerbi.pricingPlans] requestPricingPlans',
  props<any>()
);

export const requestPricingPlansSuccess = createAction(
  '[powerbi.pricingPlans] requestPricingPlansSuccess',
  props<{ pricingPlans: PricingPlan[] }>()
);

export const requestPricingPlansFailure = createAction(
  '[powerbi.pricingPlans] requestPricingPlansFailure',
  props<{ error: RequestError }>()
);

export const requestPricingPlanById = createAction(
  '[powerbi.pricingPlans] requestPricingPlanById',
  props<{ id: PricingPlanId }>()
);

export const requestPricingPlanByIdSuccess = createAction(
  '[powerbi.pricingPlans] requestPricingPlanByIdSuccess',
  props<{ pricingPlan: PricingPlan }>()
);

export const requestPricingPlanByIdFailure = createAction(
  '[powerbi.pricingPlans] requestPricingPlanByIdFailure',
  props<{ error: RequestError }>()
);
