import { createAction, props } from '@ngrx/store';
import { User } from 'tp-traqplan-core/dist/data-structs';
import { RequestError } from 'src/app/api/structs';
import { State } from './state';

export const reset = createAction(
  '[auth] reset',
  props<{ state?: Partial<State> }>()
);

export const attemptLogin = createAction(
  '[auth] AttemptLogin',
  props<{ email: string; password: string }>()
);

/*export const attemptLoginSso = createAction(
  '[auth] AttemptLoginSso',
  props<{ d: string; }>()
);*/

export const attemptLoginFailure = createAction(
  '[auth] AttemptLoginFailure',
  props<{ error: RequestError }>()
);

export const attemptLoginSuccess = createAction(
  '[auth] AttemptLoginSuccess',
  props<{ user: User; token: string; account?: string }>()
);

export const updateToken = createAction(
  '[auth] UpdateToken',
  props<{ token: string; }>()
);

/*export const attemptLoginSsoComplete = createAction('[auth] AttemptLoginSsoComplete');
export const attemptLoginSsoSuccess = createAction(
  '[auth] AttemptLoginSsoSuccess',
  props<{ ssoToken: string; origin: string }>()
);*/

export const verifyUserCredentials = createAction(
  '[auth] verifyUserCredentials'
);

export const verifyUserCredentialsFailure = createAction(
  '[auth] verifyUserCredentialsFailure'
);

export const logout = createAction(
  '[auth] logout',
  props<{ navigate?: string[]; origin?: string; ssoToken?: string | null; }>()
);

export const sessionTimeout = createAction(
  '[auth] sessionTimeout'
);
