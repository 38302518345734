import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-ag-grid-dropdown-symbol-renderer',
  styleUrls: ['./ag-grid-dropdown-symbol-renderer.component.scss'],
  template: `
    <span>{{value}}</span><span class="fas">angle-down</span>
  `
})
export class AgGridDropdownSymbolRendererComponent implements ICellRendererAngularComp {

  /**
   * Requires custom properties:
   * symbol: function (recieve params, return symbol markup)
   */
  private params: ICellRendererParams;
  public value: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = typeof params.valueFormatted === 'string' ? params.valueFormatted : params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = typeof params.valueFormatted === 'string' ? params.valueFormatted : params.value;
    return true;
  }

}
