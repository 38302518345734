import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {  takeUntil } from 'rxjs/operators';
import { State } from '../../../state';
import { TimelineLayout } from 'tp-traqplan-core/dist/timeline/timeline-layout';

@Component({
  selector: 'app-add-task-indicator',
  templateUrl: './add-task-indicator.component.html',
  styleUrls: ['./add-task-indicator.component.scss']
})
export class AddTaskIndicatorComponent implements OnInit, OnDestroy {

  @Input() layout: TimelineLayout;

  destroy$ = new Subject<void>();
  taskSelected:boolean  = false;

  constructor(
    private store: Store<State>) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.store.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      const selectedProjects = state.projects.tree.selected;
      if(selectedProjects && state.poap.revisions.value !== null){
      const addTaskResult = Object.keys(selectedProjects).some((item)=>{
        const currentRevisions = state.poap.revisions.value;
        const showAddTask = Object.values(currentRevisions).some((revision)=>{
          if(item !== revision.id){
            return false;
          }
          const result = revision.tasks.some((task)=> task.selected === true);
          return result
        })
        return showAddTask;
      })

      if(addTaskResult){
        this.taskSelected = false;
      }else{
        this.taskSelected = true;
      }
    }

    });

  }


}
