import { createSVGElement, removeChildren } from '../../svg-utils';
import * as tlStyle from '../tl-style';

const { fillPageIcon, fillPageIconMask, fillPageIconLabel } = tlStyle.standardClass;

export class FillPageIcon {

  datum: any;
  element:any;

  constructor(datum: any) {
    this.element = createSVGElement('foreignObject');
    this.element.setAttribute('class', fillPageIcon);

    removeChildren(this.element);

    const eMask = this.element.appendChild(document.createElement('div'));
    eMask.setAttribute('xmlns', 'ht'+'tp://www.w3.org/1999/xhtml');
    eMask.setAttribute('class', fillPageIconMask);

    const eLabel = eMask.appendChild(document.createElement('span'));
    eLabel.setAttribute('class', fillPageIconLabel);
    eLabel.textContent = 'Scale font to fit page';

    this.element['__datum__'] = this;
    this.datum = datum;
  }

  position({ x, y, width, height }): void {

    this.element.setAttribute('transform', `translate(${x},${y - (height / 2)})`);
    this.element.setAttribute('width', width);
    this.element.setAttribute('height', height);
    this.element.style.setProperty('font-size', `${height * 0.4}`);

  }

  appendTo(parent: SVGElement): void {
    parent.appendChild(this.element);
  }

  static retrieve(target): FillPageIcon|null {
    return target.closest(`.${fillPageIcon}`)?.__datum__ || null;
  }

}
