import { createAction, props } from '@ngrx/store';
import { RevisionInfo, HierarchyNode, HierarchyNodeId, TreeGrouping, TreeProjectNode, ViewId, RevisionId } from 'tp-traqplan-core/dist/data-structs';
import { RequestError } from 'src/app/api/structs';
import { TreeGroupable, TreeFilterState } from './state';
import { State } from './state';

export const reset = createAction(
  '[projects] reset',
  props<{ state?: Partial<State> }>()
);

export const requestProjectTree = createAction(
  '[projects] requestProjectTree',
  props<{ selectProject?: HierarchyNodeId; unselectRevision?: RevisionId; selectView?: ViewId; }>()
);

export const requestProjectTreeSuccess = createAction(
  '[projects] requestProjectTreeSuccess',
  props<{ nodes: HierarchyNode[]; availableRevisions?: RevisionInfo[]; viewId?: ViewId; }>()
);

export const requestProjectTreeFailure = createAction(
  '[projects] requestProjectTreeFailure',
  props<{ error: RequestError }>()
);

export const expandProjectTreeNode = createAction(
  '[projects] expandProjectTreeNode',
  props<{ expand: boolean; grouping?: TreeGrouping; project?: TreeProjectNode }>()
);

export const expandProjectRevisions = createAction(
  '[projects] expandProjectRevisions',
  props<{ expand: boolean; project: TreeProjectNode }>()
);

export const selectProjectTreeRevisions = createAction(
  '[projects] selectProjectTreeNode',
  props<{ select: boolean; revisions: RevisionInfo[] }>()
);

export const isReady = createAction(
  '[projects] isReady',
  props<{ isReady: boolean }>()
);

export const setProjectTreeGroups = createAction(
  '[projects] selectProjectTreeGroups',
  props<{ groups: TreeGroupable[] }>()
);

export const createNode = createAction(
  '[projects] createPath',
  props<{ path: (HierarchyNode | { name: string; allowImportedEdits?: boolean })[] }>()
);

export const createNodeSuccess = createAction(
  '[projects] createNodeSuccess',
  props<{ path: HierarchyNodeId[]; workspace: HierarchyNodeId; view?: HierarchyNodeId }>()
);

export const createNodeFailure = createAction(
  '[projects] createNodeFailure',
  props<{ path: HierarchyNodeId[]; name: string; allowImportedEdits: boolean }>()
);

export const updateNode = createAction(
  '[projects] updateNode',
  props<{ id: HierarchyNodeId; update: Partial<HierarchyNode> }>()
);

export const updateNodeSuccess = createAction(
  '[projects] updateNodeSuccess'
);

export const updateNodeFailure = createAction(
  '[projects] updateNodeFailure',
  props<{ error: RequestError }>()
);

export const archiveNode = createAction(
  '[projects] archiveNode',
  props<{ id: HierarchyNodeId }>()
);

export const deleteNode = createAction(
  '[projects] deleteNode',
  props<{ id: HierarchyNodeId }>()
);


export const restoreNode = createAction(
  '[projects] restoreNode',
  props<{ id: HierarchyNodeId }>()
);

export const moveNode = createAction(
  '[projects] moveNode',
  props<{ targetId: HierarchyNodeId; parentId: HierarchyNodeId }>()
);

export const copyNode = createAction(
  '[projects] copyNode',
  props<{ targetId: HierarchyNodeId; parentId: HierarchyNodeId; name: string }>()
);

export const setTreeFilter = createAction(
  '[projects] setTreeFilter',
  props<Partial<TreeFilterState>>()
);

export const addToFavourites = createAction(
  '[projects] addToFavourites',
  props<{ nodeId: HierarchyNodeId }>()
);

export const removeFromFavourites = createAction(
  '[projects] removeFromFavourites',
  props<{ nodeId: HierarchyNodeId }>()
);

export const requestFavourites = createAction(
  '[projects] requestFavourites'
);

export const requestFavouritesSuccess = createAction(
  '[projects] requestFavouritesSuccess',
  props<{ favourites: HierarchyNodeId[] }>()
);

export const requestRevisionViews = createAction(
  '[projects] requestRevisionViews',
  props<{ workspace: HierarchyNodeId; view?: HierarchyNodeId }>()
);

export const requestRevisionViewsSuccess = createAction(
  '[projects] requestRevisionViewsSuccess',
  props<{ revisionViews: RevisionInfo[] }>()
);

export const requestRevisionViewsFailure = createAction(
  '[projects] requestRevisionViewsFailure',
  props<{ error: RequestError }>()
);
