import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { TreeRevisionNode } from 'tp-traqplan-core/dist/data-structs';
import { standardFormat } from 'tp-traqplan-core/dist/date-format';
import { State } from '../../../state';
import * as modalActions from '../../../state/modal/actions';
import * as projectsActions from '../../../state/projects/actions';
import { ProjectsService } from '../../../state/selectors/projects.service';
import { StateModal } from '../../../state/structs';
import { ProjectTreeActionsService } from '../project-tree-actions.service';

@Component({
  selector: 'app-import-panel',
  templateUrl: './import-panel.component.html',
  styleUrls: ['./import-panel.component.scss', '../style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportPanelComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<void>();
  imports$ = this.projectsService.tree$.pipe(
    takeUntil(this.destroy$),
    map(({ imports }) => imports),
    tap((imports) => this.expanded = Array.isArray(imports) && imports.length > 0)
  );
  onImportPage: boolean = false;
  expanded: boolean = false;

  public getActions = this.actionsService.getActionsAvailable.bind(this.actionsService)

  constructor(
    private location: Location,
    private router: Router,
    private projectsService: ProjectsService,
    private store: Store<State>,
    public actionsService: ProjectTreeActionsService
  ) {
    router.events.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.onImportPage = this.location.path().includes('import');
      }
    });
  }

  ngOnInit(): void {
    this.onImportPage = this.location.path().includes('import');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getRevisionLabel(revision: TreeRevisionNode): string {
    return `${revision.projectName} (Imported on ${standardFormat(revision.created)})`;
  }

  onToggle(): void {
    this.expanded = !this.expanded;
  }

  onSelectRevision(revision: TreeRevisionNode): void {
    this.store.dispatch(
      projectsActions.selectProjectTreeRevisions({ select: !revision.selected, revisions: [revision] })
    );
  }

  onClickExtraButton(revision: TreeRevisionNode) {
    this.publishDraft(revision);
  }

  private publishDraft(revision: TreeRevisionNode): void {
    this.store.dispatch(
      modalActions.generateModal({
        modal: StateModal.labelRevision,
        inputs: {
          revision
        }
      })
    );
  }
}
