import { environment } from 'src/environments/environment';
import { ActionReducer, INIT, UPDATE } from '@ngrx/store';
import { State } from '../';
import * as auth from '../auth/state';
import * as account from '../account/state';
import * as projects from '../projects/state';
import * as poap from '../poap/state';
import * as globalActions from '../actions';
import { json } from 'tp-common';

const saveActions = new Set([
  globalActions.unload.type as string
]);

const loadActions = new Set([
  INIT as string,
  UPDATE as string
]);

const keys = [
  auth.key,
  account.key,
  projects.key,
  poap.key
];

const beforeSave = {
  [auth.key]: auth.beforeSave,
  [account.key]: account.beforeSave,
  [projects.key]: projects.beforeSave,
  [poap.key]: poap.beforeSave
};

const beforeLoad = {
  [auth.key]: auth.beforeLoad,
  [account.key]: account.beforeLoad,
  [projects.key]: projects.beforeLoad,
  [poap.key]: poap.beforeLoad
};

const prefix = '@ngstore_';

export function metaReducer(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const next = reducer(state, action);
    return loadActions.has(action.type) ? loadState(next)
      : saveActions.has(action.type) ? saveState(next)
      : next;
  };
}

function loadState(next) {
  for (const key of keys) {
    let saved;
    try {
      saved = json.parseWithDate(localStorage.getItem(prefix + key));
      if (saved['__version__'] !== environment.version) {
        saved = null;
        throw Error();
      }
    } catch {
      localStorage.removeItem(prefix + key);
    }
    if (saved) {
      next[key] = beforeLoad[key] ? beforeLoad[key](saved) : saved;
    }
  }
  return next;
}

function saveState(next) {
  for (const key of keys) {
    const state = { ...(beforeSave[key] ? beforeSave[key](next[key]) : next[key]), __version__: environment.version };
    localStorage.setItem(prefix + key, json.serializeWithDate(state));
  }
  return next;
}
