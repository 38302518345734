import { createAction, props } from '@ngrx/store';
import { HierarchyNodeId, RevisionId, RevisionData, RevisionConflict, View, ViewId, UndoAction, MilestoneCategory, ViewLine, BaselineId, Baseline, BaselineRevision, RevisionInfo } from 'tp-traqplan-core/dist/data-structs';
import { RequestError } from 'src/app/api/structs';
import { PoapUpdate } from '../structs';
import { TimelineOptions } from 'tp-traqplan-core/dist/timeline/tl-structs';
import { State } from './state';

export const reset = createAction(
  '[poap] reset',
  props<{ state?: Partial<State> }>()
);

export const requestRevisions = createAction(
  '[poap] requestRevisions',
  props<{ view: ViewId; baseline: BaselineId; ids: RevisionId[] }>()
);

export const requestRevisionsSuccess = createAction(
  '[poap] requestRevisionsSuccess',
  props<{ view: ViewId; baseline: BaselineId; revisions: RevisionData[] }>()
);

export const requestRevisionsFailure = createAction(
  '[poap] requestRevisionsFailure',
  props<{ error: RequestError }>()
);

export const removeSelectedRevisions = createAction(
  '[poap] removeSelectedRevisions',
  props<{ selectedRevisions: RevisionInfo[] }>()
);

export const resetSelectedRevisions = createAction(
  '[poap] resetSelectedRevisions'
);

export const requestViews = createAction(
  '[poap] requestViews'
);

export const requestViewsSuccess = createAction(
  '[poap] requestViewsSuccess',
  props<{ views: View[] }>()
);

export const requestViewsFailure = createAction(
  '[poap] requestViewsFailure',
  props<{ error: RequestError }>()
);

export const selectView = createAction(
  '[poap] selectView',
  props<{ view: View, selectedRevisions?: RevisionInfo[] }>()
);

export const requestMilestones = createAction(
  '[poap] requestMilestones',
  props<{ view: ViewId }>()
);

export const requestMilestonesSuccess = createAction(
  '[poap] requestMilestonesSuccess',
  props<{ milestones: MilestoneCategory[] }>()
);

export const createMilestone = createAction(
  '[poap] createMilestone',
  props<{ options: MilestoneCategory }>()
);

export const editMilestone = createAction(
  '[poap] editMilestone',
  props<{ options: MilestoneCategory }>()
);

export const requestViewLines = createAction(
  '[poap] requestViewLines'
);

export const requestViewLinesSuccess = createAction(
  '[poap] requestViewLinesSuccess',
  props<{ viewLines: ViewLine[]; view: ViewId }>()
);

export const createDraft = createAction(
  '[poap] createDraft',
  props<{ from?: RevisionId; project: HierarchyNodeId; view?: HierarchyNodeId }>()
);

export const createDraftFailure = createAction(
  '[poap] createDraftFailure',
  props<{ error: RequestError }>()
);

export const createDraftSuccess = createAction(
  '[poap] createDraftSuccess'
);

export const showCreateDraftError = createAction(
  '[poap] showCreateDraftError',
  props<{ view: View }>()
);

export const publishDraft = createAction(
  '[poap] publishDraft',
  props<{ revisionId: RevisionId; label?: string; mergeConflicts?: boolean; workspaceId?: HierarchyNodeId; viewId?: HierarchyNodeId, resolution?: any }>()
);

export const publishDraftFailure = createAction(
  '[poap] publishDraftFailure',
  props<{ error: RequestError }>()
);

export const publishDraftConflict = createAction(
  '[poap] publishDraftConflict',
  props<{ revisionId: RevisionId; conflict: RevisionConflict }>()
);

export const publishDraftSuccess = createAction(
  '[poap] publishDraftSuccess',
  props<{ revisionId: RevisionId }>()
);

export const showPublishDraftError = createAction(
  '[poap] showPublishDraftError',
  props<{ valid: boolean, error: string, params?: any }>()
);

export const updateDraft = createAction(
  '[poap] updateDraft',
  props<PoapUpdate>()
);

export const updateDraftSuccess = createAction(
  '[poap] updateDraftSuccess'
);

export const updateDraftFailure = createAction(
  '[poap] updateDraftFailure',
  props<{ error: RequestError }>()
);

export const showUpdateDraftError = createAction(
  '[poap] showUpdateDraftError',
  props<{ view: View }>()
);

/*export const bulkUpdateDraft = createAction(
  '[poap] bulkUpdateDraft',
  props<{ revisionId: RevisionId; viewId: ViewId, projectId: HierarchyNodeId; poapUpdates: Partial<PoapUpdate>[] }>()
);

export const bulkUpdateDraftSuccess = createAction(
  '[poap] bulkUpdateDraftSuccess'
);

export const bulkUpdateDraftFailure = createAction(
  '[poap] bulkUpdateDraftFailure',
  props<{ error: RequestError }>()
);

export const showBulkUpdateDraftError = createAction(
  '[poap] showBulkUpdateDraftError',
  props<{ view: View }>()
);*/

export const discardDraft = createAction(
  '[poap] discardDraft',
  props<{ projectId: HierarchyNodeId; revisionId?: RevisionId; viewId?: ViewId }>()
);

export const discardDraftSuccess = createAction(
  '[poap] discardDraftSuccess'
);

export const discardDraftFailure = createAction(
  '[poap] discardDraftFailure',
  props<{ error: RequestError }>()
);

export const showDiscardDraftError = createAction(
  '[poap] showDiscardDraftError',
  props<{ view: View }>()
);

export const rebaseDraft = createAction(
  '[poap] rebaseDraft',
  props<{ projectId: HierarchyNodeId; draftRevision: RevisionId; latestRevision: RevisionId; viewId: ViewId }>()
);

export const rebaseDraftSuccess = createAction(
  '[poap] rebaseDraftSuccess'
);

export const rebaseDraftFailure = createAction(
  '[poap] rebaseDraftFailure',
  props<{ error: RequestError }>()
);

export const showRebaseDraftError = createAction(
  '[poap] showRebaseDraftError',
  props<{ view: View }>()
);

export const requestUndoStack = createAction(
  '[poap] requestUndoStack',
  props<{ revisionIds: RevisionId[]; viewId: ViewId }>()
);

export const requestUndoStackSuccess = createAction(
  '[poap] requestUndoStackSuccess',
  props<{ history: { [key: string]: UndoAction[] } }>()
);

export const requestUndoStackFailure = createAction(
  '[poap] requestUndoStackFailure',
  props<{ error: RequestError }>()
);

export const moveRevisionPointer = createAction(
  '[poap] moveRevisionPointer',
  props<{ revisionId: RevisionId; viewId: ViewId; stamp: Date }>()
);

export const clearRevisionPointer = createAction(
  '[poap] clearRevisionPointer',
  props<{ revisionId: RevisionId; viewId: ViewId }>()
);

export const labelRevision = createAction(
  '[poap] labelRevision',
  props<{ revisionId: RevisionId; label: string }>()
);

export const labelRevisionSuccess = createAction(
  '[poap] labelRevisionSuccess'
);

export const labelRevisionFailure = createAction(
  '[poap] labelRevisionFailure',
  props<{ error: RequestError }>()
);

export const requestBaselines = createAction(
  '[poap] requestBaselines'
);

export const requestBaselinesSuccess = createAction(
  '[poap] requestBaselinesSuccess',
  props<{ baselines: Baseline[] }>()
);

export const selectBaseline = createAction(
  '[poap] selectBaseline',
  props<{ baselineId: BaselineId }>()
);

export const selectBaselineSuccess = createAction(
  '[poap] selectBaselineSuccess',
  props<{ baselineId: BaselineId; baselineRevisions: BaselineRevision[] }>()
);

export const createBaseline = createAction(
  '[poap] createBaseline',
  props<{ label: string; entries?: { project: HierarchyNodeId; revision: RevisionId }[] }>()
);

export const updateBaselineRevisions = createAction(
  '[poap] updateBaselineRevisions',
  props<{ baselineId: BaselineId; entries: { project: HierarchyNodeId; revision: RevisionId }[] }>()
);

export const updateBaselineRevisionsSuccess = createAction(
  '[poap] updateBaselineRevisionsSuccess'
);

export const updateTimelineSettings = createAction(
  '[poap] updateTimelineSettings',
  props<{ settings: Partial<TimelineOptions> }>()
);

export const showToast = createAction(
  '[poap] showToast',
  props<{ severity: string; summary: string; detail: string }>()
);

export const clearToast = createAction(
  '[poap] clearToast'
);

export const clearSelectedGroupOutlineLevel = createAction(
  '[poap] clearSelectedGroupOutlineLevel',
  props<{ project: HierarchyNodeId }>()
);

export const setSelectedGroupOutlineLevel = createAction(
  '[poap] setSelectedGroupOutlineLevel',
  props<{ project: HierarchyNodeId; outlineLevel: number }>()
);

export const clearSelectedTaskOutlineLevels = createAction(
  '[poap] clearSelectedTaskOutlineLevels',
  props<{ project: HierarchyNodeId }>()
);

export const setSelectedTaskOutlineLevels = createAction(
  '[poap] setSelectedTaskOutlineLevels',
  props<{ project: HierarchyNodeId; outlineLevels: number[] }>()
);
