import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs'

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent implements OnChanges {

  @Input() header: string;
  @Input() actions: ModalWindowAction[];
  @Input() visible: boolean;
  @Input() message: string;
  @Output() closeWindow = new EventEmitter<ModalWindowAction|null>();

  private completed = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visible && changes.visible.currentValue === true) {
      this.completed = false;
    }
  }

  complete(action?: ModalWindowAction) {
    !this.completed && this.closeWindow.emit(action);
    this.completed = true;
  }

}
