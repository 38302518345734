<p-dialog
  [header]="header"
  [visible]="visible"
  (onShow)="onVisibleChange(true)"
  (onHide)="onVisibleChange(false)"
  [ngClass]="{ fullscreen: fullscreen }"
  [maximizable]="maximizable"
  [closable]="false"
  [modal]="true"
  [focusTrap]="false"
  [focusOnShow]="false"
>
  <ng-content *ngIf="visible"></ng-content>
  <p-footer *ngIf="actions && actions.length">
    <div class="tp-modal_tools">
      <app-button
        *ngFor="let a of actions"
        [text]="a.label"
        [type]="a.type"
        [disabled]="(a.valid && !a.valid())"
        (click)="selectAction(a)"
        [matTooltip]="a.tooltip || ''"
        matTooltipPosition="below"
      ></app-button>
    </div>
  </p-footer>
</p-dialog>
