import { Component, OnChanges, AfterViewInit, Input, Output, ViewChild, HostListener, Renderer2, ElementRef, EventEmitter } from '@angular/core';
import { scaleTime, drag, select/*, event*/ } from 'd3';
import { MIN_H_RANGE } from 'tp-traqplan-core/dist/timeline/tl-structs';
import { throttle } from 'lodash';
import { TimelineInteractions } from '../tl-interactions';


interface windowChangeEvent {
  start: Date;
  finish: Date;
}

@Component({
  selector: 'app-horizontal-scroller',
  templateUrl: './horizontal-scroller.component.html',
  styleUrls: ['./horizontal-scroller.component.css']
})
export class HorizontalScrollerComponent implements OnChanges, AfterViewInit {

  @ViewChild('track', { static:true }) track: ElementRef;
  @ViewChild('thumb', { static:true }) thumb: ElementRef;
  @ViewChild('lHandle', { static:true }) lHandle: ElementRef;
  @ViewChild('rHandle', { static:true }) rHandle: ElementRef;
  //@ViewChild('windowStartOverlay', { static:true }) windowStartOverlay: ElementRef;

  @Input() boundaryMode: number;
  @Input() boundaryStart: Date;
  @Input() boundaryFinish: Date;
  @Input() windowStart: Date;
  @Input() windowFinish: Date;
  @Input() throttle: number = 60;
  @Input() interactions: TimelineInteractions;
  @Input() displayBoundaryControls: boolean = true;

  @Output() boundaryStartChange = new EventEmitter<Date>();
  @Output() boundaryFinishChange = new EventEmitter<Date>();
  @Output() windowChange = new EventEmitter<windowChangeEvent>();

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('document:click', ['$event'])

  onClick(event: Event): void {
    if(!event.target){return;}
    const target:any = event.target;
    const validTarget = ['l-handle-label', 'r-handle-label'];
    const rawClass = target.getAttribute('class');
    const trimmedClass = rawClass?.split(' ')||'';
    if(!validTarget.includes(target.getAttribute('id')) ){
      if(trimmedClass && trimmedClass[0] === 'p-datepicker-header' || trimmedClass[0] === 'p-datepicker-prev-icon' ||  trimmedClass[0] === 'p-datepicker-next-icon' || trimmedClass[0] === 'p-ripple' ){
        return;
      }
      this.showStartCalendar = false;
      this.showEndCalendar = false;
    }
  }


  public get windowStartString() {

    // return this.returnNewDate(this.windowStart)
    // return this.windowStart ? this.windowStart.toLocaleString(undefined, {weekday:'short', day:'numeric', month:'numeric', year:'numeric'}) : '';

    return this.windowStart ? this.windowStart.toLocaleString(undefined, {weekday:'short', day:'numeric', month:'numeric', year:'numeric'}) : '';
  }

  public get windowFinishString() {
    return this.windowFinish ? this.windowFinish.toLocaleString(undefined, {weekday:'short', day:'numeric', month:'numeric', year:'numeric'}) : '';
    // return this.returnNewDate(this.windowFinish);
  }

  public returnNewDate(date) {
    if(date){
      const dayOfWeek = this.daysOfWeek[date.getDay()];
      const day = date.getDate();
      const month = this.months[date.getMonth()];
      const year = date.getFullYear();
      return `${dayOfWeek}, ${day} ${month} ${year}`;
    }
  }

  public displayWindowStartOverlay: boolean = false;
  public displayWindowFinishOverlay: boolean = false;

  public boundaryStartMaxDate: Date;
  public boundaryFinishMinDate: Date;
  public windowStartMaxDate: Date;
  public windowFinishMinDate: Date;
  public showStartCalendar: boolean = false;
  public showEndCalendar: boolean = false;

  public thumbLeft: number = 0;
  public thumbWidth: number = 100;

  get pxLeft(){ return `${this.thumbLeft}px` }
  get pxWidth(){ return `${this.thumbWidth}px` }

  private scale;
  private throttled: boolean = false;

  public currentDateFormat: string;

  private daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  private months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  ngOnChanges(): void {
    this.rescale(true);
  }

  ngAfterViewInit(): void {
    let grabDiff: number;

    const getGrabDiff = event => {
      grabDiff = event.x - this.thumbLeft;
    };

    select(this.thumb.nativeElement).call(drag()
      .container(this.track.nativeElement)
      .on('start', getGrabDiff)
      .on('drag', throttle((event) => {
        const left = Math.min( Math.max(0, event.x - grabDiff), this.scale.range()[1] - this.thumbWidth );
        this.windowStart = this.scale.invert(left);
        this.windowFinish = this.scale.invert(left + this.thumbWidth);
        this.reposition();
        this.emitWindowChange();
      }),16)
      .on('end', () => {
        this.interactions.viewportSlideComplete();
      })
    );

    select(this.lHandle.nativeElement).call(drag()
      .container(this.track.nativeElement)
      .on('start', getGrabDiff)
      .on('drag', throttle(event => {
        const left = Math.min( Math.max( this.scale.range()[0] , event.x - grabDiff ), this.thumbLeft + this.thumbWidth - this.getThumbMinWidth() );
        this.windowStart = this.scale.invert(left);
        this.reposition();
        this.emitWindowChange();
      }),16)
      .on('end', () => {
        this.interactions?.viewportSlideComplete();
      })
    );

    select(this.rHandle.nativeElement).call(drag()
      .container(this.track.nativeElement)
      .on('start', event => {
        grabDiff = event.x - (this.thumbLeft + this.thumbWidth);
      })
      .on('drag', throttle(event => {
        const right = Math.min( this.scale.range()[1], Math.max( this.thumbLeft + this.getThumbMinWidth(), event.x - grabDiff ) );
        this.windowFinish = this.scale.invert(right);
        this.reposition();
        this.emitWindowChange();
      }),16)
      .on('end', () => {
        this.interactions?.viewportSlideComplete();
      })
    );


      this.getLocalDateFormat();


  }

  rescale(reposition?:boolean){

    this.showStartCalendar=false;
    this.showEndCalendar=false;

    if (!this.boundaryStart || !this.boundaryFinish || !this.windowStart || !this.windowFinish) {
      return;
    }

    const { width } = this.track.nativeElement.getBoundingClientRect();

    this.scale = scaleTime()
      .domain([
        this.boundaryStart,
        this.boundaryFinish
      ])
      .range([0, width])
      .clamp(true);

    this.boundaryStartMaxDate = new Date(this.boundaryFinish.getTime() - MIN_H_RANGE);
    this.boundaryFinishMinDate = new Date(this.boundaryStart.getTime() + MIN_H_RANGE);

    this.windowStartMaxDate = new Date(this.windowFinish.getTime() - MIN_H_RANGE);
    this.windowFinishMinDate = new Date(this.windowStart.getTime() + MIN_H_RANGE);
    reposition && this.reposition();

  }

  emitWindowChange(){
    this.windowChange.emit(<windowChangeEvent>{
      start: this.windowStart,
      finish: this.windowFinish,
    });
    this.interactions?.viewportSlide();
  }

  reposition(){
    this.thumbLeft = this.scale(this.windowStart);
    this.thumbWidth = this.scale(this.windowFinish) - this.thumbLeft;
  }

  onWindowStartChange(value: Date): void {
    this.displayWindowStartOverlay = false;
    this.windowStart = value;
    this.reposition();
    this.emitWindowChange();
  }

  onWindowFinishChange(value: Date): void {
    this.displayWindowFinishOverlay = false;
    this.windowFinish = value;
    this.reposition();
    this.emitWindowChange();
  }

  onBoundaryStartChange(value: Date): void {
    this.boundaryStartChange.emit(value);
  }

  onBoundaryFinishChange(value: Date): void {
    this.boundaryFinishChange.emit(value);
  }

  private getThumbMinWidth(){
    return this.scale( new Date(this.scale.domain()[0].getTime() + MIN_H_RANGE ) ) - this.scale.range()[0];
  }

  getLocalDateFormat() {
      const date = new Date();
      const parts = new Intl.DateTimeFormat(undefined, {day:'2-digit', month:'2-digit', year:'2-digit'}).formatToParts(date);


      const dayIndex = parts.findIndex(part => part.type === 'day');
      const monthIndex = parts.findIndex(part => part.type === 'month');

    let dateFormat: string;
    if (dayIndex < monthIndex) {
      dateFormat = 'dd/mm/yy';
    } else {
      dateFormat = 'mm/dd/yy';
    }
    // const dateFormat = 'dd M yy'

    setTimeout(() => {
      // Assign the value after the change detection cycle
      this.currentDateFormat = dateFormat;
    }, 0);

    return this.currentDateFormat;
  }


  openStart(){
    this.showStartCalendar = !this.showStartCalendar;
    this.showEndCalendar = false;
  }

  openEnd(){
    this.showEndCalendar = !this.showEndCalendar;
    this.showStartCalendar = false;
  }


  /*
  private variableThrottle(callback: (...a: any[]) => any): (...a: any[]) => any {
    return (...args) => {
      if(!this.throttled){
        this.throttled = true;
        setTimeout(() => {
          this.throttled = false;
        }, this.throttle);
        callback(...args);
      }
    }
  }
  */

}
