import { createReducer, on } from '@ngrx/store';
import { initialState } from './state';
import * as pricingPlansActions from './actions';

export const reducer = createReducer(
  initialState,

  on(pricingPlansActions.requestPricingPlansSuccess, (state, props) => {
    return {
      ...state,
      pricingPlans: props.pricingPlans
    };
  }),

  on(pricingPlansActions.requestPricingPlanByIdSuccess, (state, props) => {
    return {
      ...state,
      pricingPlan: props.pricingPlan
    };
  })

);
