import { StateModal } from '../structs';

export const key = 'modal';

export interface State {
  active: StateModal;
  inputs: { [key: string]: any };
  params: { [key: string]: any };
}

export const initialState = {
  active: null,
  inputs: null,
  params: {}
};
