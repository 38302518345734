<app-modal-window
  header="Share"
  [(visible)]="visible"
  (closed)="onClose()"
>

  <ng-container *ngIf="node">
    <div class="active__node">
      Grant user access to {{node?.name}} to a max of your own permission on this {{node.children?.length ? 'Programme' : 'Project'}}
    </div>

    <form
      class="tp-form"
      [formGroup]="form"
    >

      <div class="add-user-row p-fluid">
        <p-autoComplete
          placeholder="Add user"
          name="user"
          formControlName="user"
          [suggestions]="suggestions"
          (completeMethod)="searchUsers($event)"
          appendTo="body"
          styleClass="auto-complete"
          [dropdown]="true"
          [forceSelection]="true"
          field="email"
        >
        </p-autoComplete>

        <p-dropdown [options]="accessOptions" styleClass="drop-down" formControlName="access" optionLabel="description" appendTo="body"></p-dropdown>

        <!-- <button
          class="tp-icon-button_filled tp-icon-button_positive"
          type="submit"
          [disabled]="!form.valid"
          (click)="addUser()"
        >
          <span class="fas">plus</span>
        </button> -->
      </div>

    </form>

    <div class="table-container">
      <table
        class="tp-table_basic"
      >
        <tbody>
          <tr 
            *ngFor="let entry of accessEntries"
            class="access-row"
            [class.restricted]="entry.restricted"
            [attr.data-license]="entry.licenseDescription"
          >
            <td>
              {{entry.email}}
            </td>
            <td>
              {{entry.accessDescriptor}}
            </td>
            <td><app-icon-button *ngIf="entry.assignable"
              data-tooltip="Revoke access"
              data-position="left center"
              data-inverted="true"
              icon="times" 
              type="neutral" 
              class="rounded" 
              (click)="revokeAccess(entry)"
            ></app-icon-button></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class=tp-form__field>
      <app-button text="Close" type="neutral" (click)="onClose()">
      </app-button>

      <app-button text="Add" type="positive" [disabled]="this.form.invalid" (click)="addUser()">
      </app-button>
    </div>
  </ng-container>

</app-modal-window>
