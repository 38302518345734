import { RevisionInfo, TreeProjectNode } from "tp-traqplan-core/dist/data-structs";

export const key = 'app';

export interface State {
  draftProject?: TreeProjectNode;
  isDraftCreated: boolean;
  isDraftCreating: boolean;
  newProject?: TreeProjectNode;
  isProjectCreated: boolean;
  isProjectCreating: boolean;
  newRevision?: RevisionInfo;
  isRevisionCreated: boolean;
  isRevisionCreating: boolean;
  loading: boolean;
  dropMenuTimer?: number;
}

export const initialState: State = {
  draftProject: undefined,
  isDraftCreated: false,
  isDraftCreating: false,
  newProject: undefined,
  isProjectCreated: false,
  isProjectCreating: false,
  newRevision: undefined,
  isRevisionCreated: false,
  isRevisionCreating: false,
  loading: false,
  dropMenuTimer: undefined
};
