import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { State } from '../../state';
import * as modalActions from '../../state/modal/actions';

@Component({
  selector: 'app-show-message',
  templateUrl: './show-message.component.html',
  styleUrls: ['./show-message.component.scss']
})
export class ShowMessageComponent implements OnDestroy {

  private destroy$ = new Subject<void>();

  public actions: ModalWindowAction[] = [
    {
      label: 'Close',
      type: 'neutral',
      cancel: true
    }
  ];
  public visible = true;
  public params$: Observable<any> = this.store.pipe(
    takeUntil(this.destroy$),
    map((state) => state?.modal?.params)
  );

  constructor(private store: Store<State>) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onActionSelected(action: ModalWindowAction): void {
    if (!action.confirm) {
      return;
    }
  }

  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }
}
