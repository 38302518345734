import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { Store } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import { map, tap, takeUntil, filter, take, catchError, withLatestFrom } from 'rxjs/operators';
import { State } from 'src/app/state';
import { namingConventionValidator } from 'src/app/shared/validators/naming-convention.validator';
import { HierarchyNode } from 'tp-traqplan-core/dist/data-structs';
import * as projectsActions from 'src/app/state/projects/actions';
import * as modalActions from 'src/app/state/modal/actions';
import { HierarchyApiService } from 'src/app/api/hierarchy-api.service';
import { matchBasicSearchQuery } from 'src/app/util/string';

@Component({
  selector: 'app-copy-project',
  templateUrl: './copy-project.component.html',
  styleUrls: ['./copy-project.component.scss']
})
export class CopyProjectComponent implements OnDestroy, OnInit {

  @Input() project: HierarchyNode;

  title: string;

  actions: ModalWindowAction[] = [
    {
      label: 'Copy Project',
      type: 'positive',
      confirm: true,
      valid: () => (this.project && this.projectForm.valid),
      enterKey: true,
    },
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true,
      escKey: true
    }
  ];

  visible = true;

  projectForm = new FormGroup({
    workspace: new FormControl('',
      [
        Validators.required,
        Validators.minLength(1),
        namingConventionValidator()
      ]
    ),
    programme: new FormControl('',
      [
        Validators.required,
        Validators.minLength(1),
        namingConventionValidator()
      ]
    ),
    name: new FormControl('',
      [
        Validators.required,
        Validators.minLength(1),
        namingConventionValidator()
      ]
    )
  },[],[
    () => {

      const workspace = this.projectForm.controls.workspace.value;
      const programme = this.projectForm.controls.programme.value;
      const name = this.projectForm.controls.name.value;
      const projectId = this.project?.id;

      if (!workspace || !programme || !name || !projectId) return of({ incomplete: true });

      return this.hierarchyApi.validateProjectMove({
        copy: true,
        targetName: name,
        targetId: this.project?.id,
        workspaceName: (typeof workspace === 'string') ? workspace : workspace.name,
        programmeName: (typeof programme === 'string') ? programme : programme.name
      }).pipe(
        catchError(arg => {
          return of({ errors: arg?.error?.error ? [arg.error.error] : [] });
        }),
        map(({ errors }) => {
          this.validationErrors = errors;
          return errors?.length ? { validationError: true } : null
        })
      );

    }
  ]);

  get programmeControl(): FormControl {
    return this.projectForm.controls.programme;
  }

  get workspaceControl(): FormControl {
    return this.projectForm.controls.workspace;
  }

  validationErrors: string[];

  workspaceSuggestions: HierarchyNode[];
  programmeSuggestions: HierarchyNode[];

  isNewWorkspace: boolean;
  isNewProgramme: boolean;

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private hierarchyApi: HierarchyApiService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit() {

    if (this.project) {
      this.title = `Copy Project - ${this.project.name}`;
      this.store.pipe(
        takeUntil(this.destroy$),
        take(1)
      ).subscribe(state => {
        this.workspaceControl.setValue(state.account.root.selected);
      });
    }

    this.projectForm.valueChanges.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.store),
    ).subscribe(([changes, { account, projects }]) => {

      const workspaces = account.root.available.value;
      const hierarchy = projects.hierarchy.value;

      this.isNewWorkspace = (changes.workspace?.length && !workspaces.some(w => w.name === changes.workspace));

      this.isNewProgramme =  (changes.programme?.length && (this.isNewWorkspace || !hierarchy.some(h => {
        return h.name === changes.programme && workspaces.some(w => h.parent === w.id);
      })));

    });

  }

  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }

  onActionSelected(action: ModalWindowAction): void {

    if (!action.confirm || !this.project) {
      return;
    }
    const workspace = this.projectForm.controls.workspace.value;
    const programme = this.projectForm.controls.programme.value;
    const name = this.projectForm.controls.name.value;
    const projectId = this.project?.id;

    if (!workspace || !programme || !name || !projectId) return;

    this.hierarchyApi.moveProject({
      copy: true,
      targetName: name,
      targetId: this.project.id,
      workspaceName: (typeof workspace === 'string') ? workspace : workspace.name,
      programmeName: (typeof programme === 'string') ? programme : programme.name
    }).pipe(
      tap(() => {
        this.store.dispatch(
          projectsActions.requestProjectTree({})
        );
      })
    ).subscribe();

  }

  searchWorkspaces({ query }): void {
    this.store.pipe(
      take(1),
      filter(state => !!state.account.root.available?.value),
      tap(state => {
        this.workspaceSuggestions = state.account.root.available.value.filter(w => matchBasicSearchQuery(query, w.name));
      })
    ).subscribe();

  }

  searchProgrammes({ query }): void {

    if (!this.workspaceControl.valid) {
      this.programmeSuggestions = [];
      return;
    }

    this.hierarchyApi.searchChildren({
      id: this.projectForm.controls.workspace.value?.id,
      query: query
    }).subscribe(response => {
      this.programmeSuggestions = response?.matches?.filter(p=> p.id !== this.project.parent) || []; 
    });
  }

}
