import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MilestoneCategory, ViewId } from 'tp-traqplan-core/dist/data-structs';
import { AuthApiService } from './auth-api.service';
import { defaultPipe } from './common';
import { ReqHeader } from './structs';


@Injectable({
  providedIn: 'root'
})
export class MilestonesApiService {

  constructor(
    private http: HttpClient,
    private authApi: AuthApiService
  ) { }

  get(view: ViewId): Observable<{ milestones: MilestoneCategory[] }> {
    return this.authApi.headers({ requires: ReqHeader.account & ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ milestones: MilestoneCategory[] }> => {
        return this.http.get<{ milestones: MilestoneCategory[]; }>(
          '/api/milestones',
          { params: { view }, headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  update({ view, milestones }) {
    return this.authApi.headers({ requires: ReqHeader.account & ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/milestones/update',
          { view, milestones },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  checkAvailable(name: string): Observable<{ available: boolean }> {
    return this.authApi.headers({ requires: ReqHeader.account & ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ available: boolean }> => {
        return this.http.get<{ available: boolean; }>(
          '/api/milestones/check-available',
          { params: { name }, headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

}
