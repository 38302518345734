<p-contextMenu
  #contextMenu
  [ngStyle]="{ position: 'fixed', left: xPosition, top: yPosition, 'z-index': 10 }"
  [target]="target"
  [model]="menuItems"
></p-contextMenu>

<app-modal-window
  [visible]="modalActive === modals.delete"
  [actions]="modalDeleteActions"
  header="Delete Group"
  (actionSelected)="onDeleteAction($event)"
  (visibleChange)="onModalVisibleChange($event)"
>
  This action will delete all child groups. Tasks will not be deleted.
</app-modal-window>

<app-modal-window
  [visible]="modalActive === modals.drawingLayoutTheme"
  [actions]="modalDrawingLayoutThemeActions"
  header="Drawing Layout Theme"
  (visibleChange)="onModalVisibleChange($event)"
>
    <label class="label">
      {{group?.name}}
    </label>
    <p-dropdown
      *ngIf="group"
      [ngModel]="group.drawingLayout"
      [options]="drawingLayouts"
      optionLabel="name"
      optionValue="value"
      appendTo="body"
      (onChange)="onChangeDrawingLayoutTheme($event)"
    >
    </p-dropdown>
</app-modal-window>

<app-modal-window
  [visible]="modalActive === modals.sort"
  [actions]="modalSortActions"
  header="Reorder Groups"
  (actionSelected)="onSortAction($event)"
  (visibleChange)="onModalVisibleChange($event)"
>
  <p-orderList
    [value]="siblings"
    [dragdrop]="true"
  >
    <ng-template let-item pTemplate="item">
      <div class="sort-item">
        <div class="sort-item__text">{{item.name}}</div>
      </div>
    </ng-template>
  </p-orderList>
</app-modal-window>

<!--<app-modal-window
  header="Show Groups as Outline Levels"
  [visible]="modalActive === modals.showGroupOutlineLevel"
  [actions]="modalShowGroupOutlineLevelActions"
  (actionSelected)="onShowGroupOutlineLevel($event)"
  (visibleChange)="onModalVisibleChange($event)"
>
  This will show all groups up to selected outline level

  <div class="group-menu-form">
    <div class="form-group">
      <div class="form-label">Show all groups up to outline level:</div>

      <div class="form-item">
        <p-dropdown [options]="groupOutlineLevels" [(ngModel)]="selectedGroupOutlineLevel" placeholder="Select outline level" optionLabel="name" optionValue="value" appendTo="body"></p-dropdown>
      </div>
    </div>
  </div>
</app-modal-window>-->

<!--<app-modal-window
  header="Show Tasks as Outline Levels"
  [visible]="modalActive === modals.showTaskOutlineLevel"
  [actions]="modalShowTaskOutlineLevelActions"
  (actionSelected)="onShowTaskOutlineLevel($event)"
  (visibleChange)="onModalVisibleChange($event)"
>
  This will show all tasks with selected outline level

  <div class="group-menu-form">
    <div class="form-group">
      <div class="form-label">Show all tasks with outline level:</div>

      <div class="form-item">
        <p-multiSelect [options]="taskOutlineLevels" [(ngModel)]="selectedTaskOutlineLevels" defaultLabel="Select outline level" optionLabel="name" optionValue="value" display="chip" appendTo="body"></p-multiSelect>
      </div>
    </div>
  </div>
</app-modal-window>-->

<app-modal-window
  [visible]="modalActive === modals.merge"
  [actions]="modalMergeActions"
  header="Merge Group"
  (actionSelected)="onMergeAction($event)"
  (visibleChange)="onModalVisibleChange($event)"
>

  <app-timeline-group-tree
    [layout]="layout"
    [branches]="modalMergeBranches"
    [exclude]="modalMergeExclude"
    (selectionChanged)="onModalMergeSelectionChange($event)"
  >
  </app-timeline-group-tree>

</app-modal-window>
<!--
<app-poap-groups-modal
  [(visible)]="modalSortVisible"
  [timelineLayout]="layout"
>
</app-poap-groups-modal>
-->
<!--<app-modal-window
  header="Show Tasks"
  [visible]="modalActive === modals.show"
  [actions]="modalShowActions"
  (actionSelected)="onShowAction($event)"
  (visibleChange)="onModalVisibleChange($event)"
>
  All tasks belonging to this group and its subgroups will be shown in the timeline.
</app-modal-window>

<app-modal-window
  header="Hide Group"
  [visible]="modalActive === modals.hide"
  [actions]="modalHideActions"
  (actionSelected)="onHideAction($event)"
  (visibleChange)="onModalVisibleChange($event)"
>
  All tasks belonging to this group and its subgroups will be hidden.
</app-modal-window>
-->