<app-modal-window
  [header]="title"
  [(visible)]="visible"
  (actionSelected)="onActionSelected($event)"
  (closed)="onClose()"
  [actions]="actions"
>

  <form
    class="tp-form"
    [formGroup]="projectForm"
  >
    <div class="tp-form__field">
      <label for="workspace">Target Workspace</label>

      <div class="tp-form__field__sticker" *ngIf="isNewWorkspace && workspaceControl?.valid">NEW</div>

      <p-autoComplete
        formControlName="workspace"
        (completeMethod)="searchWorkspaces($event)"
        [dropdown]="true"
        field="name"
        [suggestions]="workspaceSuggestions"
        appendTo="body"
      >
      </p-autoComplete>

    </div>

    <div
      class="tp-form__error"
      *ngIf="workspaceControl.value && workspaceControl.errors?.invalidName"
    >
      Workspace name contains invalid characters
    </div>

    <div class="tp-form__field">
      <label for="programme">Target Programme</label>

      <div class="tp-form__field__sticker" *ngIf="isNewProgramme && programmeControl?.valid">NEW</div>

      <p-autoComplete
        [disabled]="!workspaceControl?.value || !workspaceControl.valid"
        formControlName="programme"
        (completeMethod)="searchProgrammes($event)"
        [dropdown]="true"
        field="name"
        [suggestions]="programmeSuggestions"
        appendTo="body"
      >
      </p-autoComplete>
    </div>

    <div
      class="tp-form__error"
      *ngIf="programmeControl.value && programmeControl.errors?.invalidName"
    >
      Programme name contains invalid characters
    </div>
    <!--<div class="tp-form__field flex create-programme-btn">

      <p-button (click)="showDialog()" [disabled]="disableAddButton" icon="pi pi-plus" label="Add Programme"></p-button>
    </div>-->
    <div
      class="tp-form__error"
      *ngIf="projectForm.errors?.validationError"
    >
      <p *ngFor="let message of validationErrors">{{message}}</p>
    </div>
  </form>

  <!--<div class="card flex justify-content-center">
    <p-dialog header="Header" [(visible)]="showCreatePorgramme" [style]="{ width: '50vw', textAlign: 'center' }">
        <ng-template pTemplate="header">
            <span class="text-xl font-bold">Header Content</span>
        </ng-template>
        <p class="flex justify-content-center">
         <strong> "{{projectForm.controls.programme.value}}" </strong> programme does not exist in the project tree. </p> <p>
            Do you want to create the programme <strong>
            "{{projectForm.controls.programme.value}}"
            </strong>?
        </p>
        <ng-template pTemplate="footer">
            <p-button (click)="createProgramme()" label="Create Programme" styleClass="p-button-secondary"></p-button>
            <p-button (click)="showCreatePorgramme = false" label="Cancel" styleClass="p-button-danger"></p-button>
        </ng-template>
    </p-dialog>
  </div>-->


</app-modal-window>
