import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { State } from '../';
import { StaffApiService } from '../../api/staff-api.service';
import { RequestError } from '../../api/structs';
import * as tpsActions from './actions';

@Injectable()
export class TpsEffects {

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private staffApi: StaffApiService,
    private router: Router
  ) { }

  attemptLogin$ = createEffect(() => this.actions$.pipe(
    ofType(tpsActions.attemptLogin),
    switchMap(({ email, password }): Observable<Action> => {
      return this.staffApi.requestLogin({ email, password }).pipe(
        switchMap(({ token, tps }) => [
          tpsActions.attemptLoginSuccess({ token, tps })
        ]),
        catchError((error: RequestError) => {
          return of(tpsActions.attemptLoginFailure({ error }))
        })
      );
    })
  ));

  attemptLoginSso$ = createEffect(() => this.actions$.pipe(
    ofType(tpsActions.attemptLoginSso),
    switchMap(({ d }): Observable<Action> => {
      return this.staffApi.requestLoginSso(d).pipe(
        switchMap(({ token, tps, ssoToken, origin }) => [
          tpsActions.attemptLoginSuccess({ token, tps }),
          tpsActions.attemptLoginSsoSuccess({ ssoToken, origin })
        ]),
        catchError((error: RequestError) => {
          return of(tpsActions.attemptLoginFailure({ error }))
        })
      );
    })
  ));

  attemptLoginSsoSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(tpsActions.attemptLoginSsoSuccess),
    switchMap(() => {
      this.router.navigate(['staff']);
      return of(tpsActions.attemptLoginSsoComplete());
    })
  ));

  verifyStaffCredentials$ = createEffect(() => this.actions$.pipe(
    ofType(tpsActions.verifyStaffCredentials),
    withLatestFrom(this.store),
    switchMap(([, state]) => {
      if (state.tps && state.tps.token) {
        return this.staffApi.verifyToken(state.tps.token).pipe(
          switchMap(({ token, tps }) => [
            tpsActions.attemptLoginSuccess({ token, tps })
          ]),
          catchError(() => {
            return of(tpsActions.verifyStaffCredentialsFailure())
          })
        );
      }
    })
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(tpsActions.logout),
    tap(() => this.router.navigate(['staff']))
  ), { dispatch: false });

}
