<app-modal-window
  header="Edit View Lines"
  [actions]="actions"
  (actionSelected)="onActionSelected($event)"
  [(visible)]="visible"
  (closed)="onClose()"
  width="80vw"
  maxWidth="80vw"
>
  <!--<ng-container *ngIf="this.data$ | async as data">-->

  <div class="add-viewline" *ngIf="userCanEdit">
    <div class="tp-label add-viewline__header">Create New View Line</div>

    <form
      class="tp-form add-viewline__form"
      *ngIf="userCanEdit"
      action=""
      (ngSubmit)="this.onAdd()"
      [formGroup]="this.form"
    >

      <div class="tp-form__field">
        <label for="name">Name:</label>
        <input
          id="name"
          type="text"
          pInputText
          formControlName="title"
          autocomplete="off"
        />
      </div>

      <div class="tp-form__field" style="min-width:11em">
        <label for="date">Date:</label>
        <p-calendar formControlName="date" appendTo="body"></p-calendar>
      </div>

      <div class="tp-form__field">
        <label for="colour">Colour</label>
        <p-dropdown
          [options]="colours"
          optionLabel="value"
          optionValue="value"
          formControlName="colour"
        ></p-dropdown>
      </div>

      <div class="tp-form__field">
        <label for="symbol">Style</label>
        <p-dropdown
          [options]="symbols"
          optionLabel="value"
          optionValue="value"
          formControlName="style"
        >
        </p-dropdown>
      </div>

      <div class="tp-form__field">
        <label for="global">Visible?</label>
        <input
          type="checkbox"
          formControlName="visible"
        />
      </div>

      <button
        class="tp-icon-button_filled tp-icon-button_positive"
        name="add"
        data-position="left center"
        type="submit"
        [disabled]="!form.valid"
      >
        <span class="fas">plus</span>
      </button>

    </form>

  </div>

  <ag-grid-angular
    style="width: 100%; height: 250px;"
    class="ag-theme-balham"
    [gridOptions]="gridOptions"
    [getRowNodeId]="getRowNodeId"
    [enableRangeSelection]="false"
    [modules]="modules"
    [rowData]="viewLines$ | async"
    [columnDefs]="columnDefs"
    (cellValueChanged)="onCellValueChanged($event)"
  >
  </ag-grid-angular>
  <!--
  [enableSorting]="false"
  [enableColResize]="false"
  (gridReady)="onGridReady($event)"
  (cellFocused)="onCellFocused($event, data.isAdmin)"
  (cellValueChanged)="this.onCellValueChanged($event)"
  [showToolPanel]="false"
  [toolPanelSuppressSideButtons]="true"-->

  <!--</ng-container>-->
</app-modal-window>
