import { TimelineLayout } from '../timeline-layout';
import * as tlStyle from '../tl-style';
import { createSVGElement} from '../../svg-utils';

const { axisRow, axisTickLine, axisTickText } = tlStyle.standardClass;

type AxisElement = SVGGElement|SVGLineElement|SVGTextElement;

export type AxisMap = Map<string,AxisElement>;

export function renderAxis( layout: TimelineLayout, root:SVGElement, preMap: AxisMap = new Map() ): AxisMap {

  if (!layout.axis) {
    return preMap;
  }

  const nMap: AxisMap = new Map();
  const nGList: SVGGElement[] = [];

  const { ticks: rows, intervals, levelHeight, tickPadding, levelPadding } = layout.axis;

  for (let li = rows.length -1; li >= 0; li-- ) {

    const offset = (rows.length - li - 1) * levelHeight;

    const gID = getNodeID(axisRow, intervals[li]);
    const nG = updateG(preMap.get(gID) as SVGGElement || gFactory(), { offset });

    nMap.set(gID, nG);

    nGList.push(nG);

    if (!rows[li].length) continue;

    const row = rows[li];
    // unique label required for l8er identification - use start/finish to disambiguate:
    const divs = [ [ row[0].start, `s_${row[0].date}`], ...row.map(tick => [ tick.finish, `f_${tick.date}` ]) ];

    for (let i = 0; i < divs.length; i++) {

      const [ position, identifier ] = divs[i];

      const lineID = getNodeID(axisTickLine, intervals[li], identifier as string);
      const nLine = updateLine(preMap.get(lineID) as SVGLineElement || lineFactory(), { levelHeight, position });

      nMap.set(lineID, nLine);

      (nLine.parentNode !== nG) && nG.appendChild(nLine);
    }

    for (let i = 0; i < row.length; i++) {

      const tick = row[i];
      const position = tick.floatLeft ? (tick.start + tickPadding) : tick.floatRight ? (tick.finish - tickPadding) : tick.center;
      const anchor = tick.floatLeft ? 'start' : tick.floatRight ? 'end' : 'middle';

      const textID = getNodeID(axisTickText, intervals[li], tick.date.toString());

      const nText = updateText(preMap.get(textID) as SVGTextElement || textFactory(), { label: tick.label, levelPadding, position, anchor });

      nMap.set(textID, nText);
      (nText.parentNode !== nG) && nG.appendChild(nText);
    }
  }

  // remove discarded elements:
  for (const e of preMap) {
    if (!nMap.has(e[0])) {
      e[1].remove();
    }
  }

  for (let i = 0; i < nGList.length; i++) {
    (nGList[i].parentNode !== root) && root.appendChild(nGList[i]);
  }

  return nMap;
}

function getNodeID(type:string, axisInterval: string, label: string=''): string {
  return `${type}_${axisInterval}_${label}`;
}

function gFactory(): SVGGElement {
  const nG = createSVGElement('g') as SVGGElement;
  nG.setAttribute('class', axisRow);
  return nG;
}

function lineFactory(): SVGLineElement {
  const nLine = createSVGElement('line') as SVGLineElement;
  nLine.setAttribute('class', axisTickLine);
  return nLine;
}

function textFactory(): SVGTextElement {
  const nText = createSVGElement('text') as SVGTextElement;
  nText.setAttribute('class', axisTickText);
  nText.setAttribute('dy', '1em');
  return nText;
}

function updateG(nG: SVGGElement, { offset }): SVGGElement {
  nG.setAttribute('transform', `translate(0,${offset})`);
  return nG;
}

function updateLine(nLine: SVGLineElement, { levelHeight, position }): SVGLineElement {
  nLine.setAttribute('y2', levelHeight);
  nLine.setAttribute('transform', `translate(${position},0)`);
  return nLine;
}

function updateText(nText: SVGTextElement, { label, levelPadding, position, anchor }): SVGTextElement {
  nText.setAttribute('y', levelPadding);
  nText.setAttribute('transform', `translate(${position},0)`);
  nText.setAttribute('text-anchor', anchor);
  nText.textContent = label || '';
  return nText;
}
