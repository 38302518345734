<div class="header">

  <div *ngIf="paneExpanded" class="header__text tp-header-2">Project Tree</div>

  <button class="header__button tp-button tp-button_positive" [disabled]="createProjectsDisabled"
    (click)="onCreateProject()" pTooltip="Create Project" [tooltipPosition]="paneExpanded ? 'bottom' : 'right'">
    <span>New Project</span>
  </button>

  <button class="header__button tp-button tp-button_positive" [disabled]="importProjectsDisabled"
    (click)="onImportProject()" pTooltip="Import" [tooltipPosition]="paneExpanded ? 'bottom' : 'right'">
    <span>Import Project</span>
  </button>

  <button class="tp-icon-button header__icon left-chevron" (click)="onToggle()">
    <span class="fas toggle-tree">{{paneExpanded ? 'angle-double-left' : 'angle-double-right'}}</span>
  </button>

</div>

<div class="body" *ngIf="paneExpanded">
  <app-import-panel *ngIf="(imports$ | async)?.length">
  </app-import-panel>

  <ng-container>
    <!--<app-group-select>
    </app-group-select>-->
    <div class="search-bar">
      <app-search-bar theme="light" placeholder="Search for projects..." style="flex:1"
        (search)="updateSearchFilter($event)">
      </app-search-bar>

      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon fontSet="fas">chevron-down</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="filterView(0)">
          <span>Favourites</span>
        </button>
        <button mat-menu-item (click)="filterView(1)">
          <span>Drafts</span>
        </button>
        <button mat-menu-item (click)="filterView(2)">
          <span>Selected</span>
        </button>
      </mat-menu>
    </div>

    <div *ngIf="searchDescription" class="search-desc">
      <div class="search-desc__icon" (click)="clearFilterTypes()">
        <span class="fas">times</span>
      </div>
      <div class="search-desc__text">{{searchDescription}}</div>
    </div>

    <app-tree-list class="tree-body">
    </app-tree-list>

    <app-archive-panel>
    </app-archive-panel>
  </ng-container>
</div>

<p-toast position="bottom-right" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}" [style]="{'min-width':'450px'}" ></p-toast>

<!-- <div class="card flex justify-content-center">
    <p-dialog header="Creating Draft" [(visible)]="isModalVisibleDraftCreating" [style]="{ 'width':'auto', 'max-width':'600px' }" (onHide)="onCloseDraftCreating()">

        <div>We are creating a draft of project<ng-container *ngIf="draftProject"><b>
          {{draftProject.name}}</b></ng-container>
      for user<ng-container *ngIf="currentUser"><b> {{currentUser.name}}</b></ng-container> to modify
    <br>
    <p>
      <b class="warning"> Please do not refresh the page and wait for the draft to be created.</b>
    </p>
    </div>

      <ng-template pTemplate="footer">
        <p-button (click)="onCloseDraftCreating()" label="Close"></p-button>
      </ng-template>
    </p-dialog>
</div> -->


<!-- <div class="card flex justify-content-center">
    <p-dialog header="Creating Project" [(visible)]="isModalVisibleProjectCreating" [style]="{ 'width':'auto', 'max-width':'600px' }" (onHide)="onCloseProjectCreating()">

       <div>We are creating a new project<ng-container *ngIf="newProject"><b> {{newProject.name}}</b></ng-container> for
      user<ng-container *ngIf="currentUser"><b> {{currentUser.name}}</b></ng-container><ng-container *ngIf="newProject">
        in
        programme<b> {{newProject.parentName}}</b></ng-container></div>

      <ng-template pTemplate="footer">
        <p-button (click)="onCloseProjectCreating()" label="Close"></p-button>
      </ng-template>
    </p-dialog>
</div> -->


<!-- <div class="card flex justify-content-center">
    <p-dialog header="Publishing Draft" [(visible)]="isModalVisibleRevisionCreating" [style]="{ 'width':'auto', 'max-width':'600px' }" (onHide)="onCloseRevisionCreating()">

       <div>We are publishing your draft of project<ng-container *ngIf="draftProject"><b>
          {{draftProject.name}}</b>
        </ng-container>
        <br>
        <p>
          <b class="warning">
            Please do not refresh the page while we are publishing.
          </b>
        </p>
      </div>

      <ng-template pTemplate="footer">
        <p-button (click)="onCloseRevisionCreating()" label="Close"></p-button>
      </ng-template>
    </p-dialog>
</div> -->



<!-- <div class="card flex justify-content-center">
    <p-dialog header="Draft Published" [(visible)]="isModalVisibleRevisionCreated" [style]="{ 'width':'auto', 'max-width':'600px' }" (onHide)="onCloseRevisionCreated()">

      <div>Your draft of project<ng-container *ngIf="draftProject"><b> {{draftProject.name}}</b></ng-container> has been
      published as version<ng-container *ngIf="newRevision"><b>
          v{{newRevision.projectVersion}}.{{newRevision.viewVersion}}<ng-container *ngIf="newRevision.label">
            {{newRevision.label}}</ng-container></b></ng-container>. It will now be visible to other users with access
      to that project.</div>

      <ng-template pTemplate="footer">
        <p-button (click)="onCloseRevisionCreated()" label="Close"></p-button>
      </ng-template>
    </p-dialog>
</div> -->


<!-- <div class="card flex justify-content-center">
    <p-dialog header="Draft created" [(visible)]="isModalVisibleDraftCreated" [style]="{ 'width':'auto', 'max-width':'600px' }" (onHide)="onCloseDraftCreated()">
      <div >A draft of project<ng-container *ngIf="draftProject"><b> {{draftProject.name}}</b></ng-container> has been
        created. You will see this symbol beside the project name. <span class="fas">pen</span>
      </div>
      <div>Any changes made by<ng-container *ngIf="!currentUser"> you</ng-container><ng-container *ngIf="currentUser">
          {{currentUser.name}}</ng-container> are not visible to other users unless the change are published.</div>
      <div>You have the option to publish changes, or discard a draft.</div>
      <div>You can undo changes made to your draft. When a draft is published you cannot undo it.</div>
      <ng-template pTemplate="footer">
        <p-button (click)="onCloseDraftCreated()" label="Close"></p-button>
      </ng-template>
    </p-dialog>
</div> -->
