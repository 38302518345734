import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { UserApiService } from 'src/app/api/user-api.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  public form: FormGroup;
  public error: string;

  get email() { return this.form.get('email') }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userApi: UserApiService
  ) {
    this.form = this.fb.group({
      email: this.fb.control('', [
        Validators.required,
        Validators.email
      ])
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.userApi.sendPasswordResetToken(this.email.value).pipe(
        catchError(error => {
          this.error = error.message;
          return throwError(error);
        }),
        tap(() => {
          this.router.navigate(['reset-password'], { queryParams: { email:this.email.value } });
        })
      ).subscribe();
    }
  }

}
