<div class="header" (click)="toggle()" [matTooltip]="headerTooltip">
  <div class="header-label">Collapse side-menu</div>

  <div class="header-icon">
    <mat-icon *ngIf="collapsed" aria-hidden="false" aria-label="Expand">keyboard_double_arrow_right</mat-icon>

    <mat-icon *ngIf="!collapsed" aria-hidden="false" aria-label="Collapse">keyboard_double_arrow_left</mat-icon>
  </div>
</div>

<div class="content">
  <div class="menu" *ngIf="links">
    <ng-container *ngFor="let link of links">
      <div class="menu-item" [ngClass]="link.disabled ? 'disabled' : link.label === this.activeLink? 'active': ''">
        <a class="link" [routerLink]="link.link" [queryParams]="link.queryParams" [matTooltip]="link.label"
          [matTooltipPosition]="collapsed ? 'after' : 'below'" (click)="link.onClick ? link.onClick() : null">
          <div class="icon" [ngClass]="[link.iconClass || 'fas']">
            {{link.icon}}
          </div>

          <div class="label">
            <div [ngClass]="link.subLabel ? 'has-sub' : ''">{{link.label}}</div>

            <div class="sub" *ngIf="link.subLabel">{{link.subLabel}}</div>
          </div>
        </a>

        <div class="icon" *ngIf="link.children" [matTooltip]="link.expanded ? 'Collapse Menu' : 'Expand Menu'"
          matTooltipPosition="after" (click)="toggleSubMenu(link)">
          <mat-icon *ngIf="link.expanded" aria-hidden="false" aria-label="Collapse">keyboard_double_arrow_up</mat-icon>

          <mat-icon *ngIf="!link.expanded" aria-hidden="false" aria-label="Expand">keyboard_double_arrow_down</mat-icon>
        </div>
      </div>

      <div class="menu sub" *ngIf="link.children && link.expanded">
        <ng-container *ngFor="let link of link.children">
          <div class="menu-item sub" [ngClass]="link.disabled ? 'disabled' : ''">
            <a class="link" [routerLink]="link.link" [queryParams]="link.queryParams" [matTooltip]="link.label"
              [matTooltipPosition]="collapsed ? 'after' : 'below'" (click)="link.onClick ? link.onClick() : null">
              <div class="icon" [ngClass]="[link.iconClass || 'fas']">
                {{link.icon}}
              </div>

              <div class="label">
                <div [ngClass]="link.subLabel ? 'has-sub' : ''">{{link.label}}</div>

                <div class="sub" *ngIf="link.subLabel">{{link.subLabel}}</div>
              </div>
            </a>

            <div class="icon" *ngIf="link.children" [matTooltip]="link.expanded ? 'Collapse Menu' : 'Expand Menu'"
              matTooltipPosition="after" (click)="toggleSubMenu(link)">
              <mat-icon *ngIf="link.expanded" aria-hidden="false" aria-label="Collapse">keyboard_double_arrow_up
              </mat-icon>

              <mat-icon *ngIf="!link.expanded" aria-hidden="false" aria-label="Expand">keyboard_double_arrow_down
              </mat-icon>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
