import { Component, Input } from '@angular/core';
import { Notification } from '../../data-structs';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import * as modalActions from '../../state/modal/actions';
import { Store } from '@ngrx/store';
import { State } from '../../state';
import { Router } from '@angular/router';

@Component({
  selector: 'app-access-granted',
  templateUrl: './access-granted.component.html',
  styleUrls: ['./access-granted.component.scss']
})
export class AccessGrantedComponent {

  @Input() message: Notification;

  actions: ModalWindowAction[] = [
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true,
      escKey: true
    },
    {
      label: 'Go to workspace',
      type: 'positive',
      confirm: true,
      enterKey: true,
    }
  ];

  visible = true;

  constructor(
    private store: Store<State>,
    private router: Router
  ) {}

  complete(action?: ModalWindowAction) {
    if (action?.confirm && this.message?.meta?.path) {
      this.router.navigate(['/workspace', this.message.meta.path[0], 'poap']);
    }

    this.store.dispatch(
      modalActions.closeModal()
    );
  }

}
