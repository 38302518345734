import { createAction, props } from '@ngrx/store';
import { RequestError } from '../../api/structs';
import { UserTncAcceptanceId } from 'tp-traqplan-core/dist/data-structs';

export const staffRequestUserTncAcceptances = createAction(
  '[userTncAcceptance] staffRequestUserTncAcceptances',
  props<any>()
);

export const staffRequestUserTncAcceptancesSuccess = createAction(
  '[userTncAcceptance] staffRequestUserTncAcceptancesSuccess',
  props<{ userTncAcceptances: any[] }>()
);

export const staffRequestUserTncAcceptancesFailure = createAction(
  '[userTncAcceptance] staffRequestUserTncAcceptancesFailure',
  props<{ error: RequestError }>()
);

export const staffRequestUserTncAcceptanceById = createAction(
  '[userTncAcceptance] staffRequestUserTncAcceptanceById',
  props<{ id: UserTncAcceptanceId }>()
);

export const staffRequestUserTncAcceptanceByIdSuccess = createAction(
  '[userTncAcceptance] staffRequestUserTncAcceptanceByIdSuccess',
  props<{ userTncAcceptance: any }>()
);

export const staffRequestUserTncAcceptanceByIdFailure = createAction(
  '[userTncAcceptance] staffRequestUserTncAcceptanceByIdFailure',
  props<{ error: RequestError }>()
);
