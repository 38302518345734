import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

import * as PowerBi from '../../powerbi-data-structs';
import { defaultPipe } from '../common';
import { StaffApiService } from '../staff-api.service';

import Subscription = PowerBi.Subscription;
import SubscriptionId = PowerBi.SubscriptionId;

@Injectable({
  providedIn: 'root'
})
export class SubscriptionApiService {

  constructor(
    private http: HttpClient,
    private staffApi: StaffApiService
  ) { }

  requestSubscriptions() {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.get<{ subscriptions: Subscription[] }>(
          '/api/staff/powerbi/subscriptions',
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  requestSubscriptionById(id: SubscriptionId) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.get<{ subscription: Subscription }>(
          `/api/staff/powerbi/subscriptions/${id}`,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  cancelSubscriptionById(id: SubscriptionId, options) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.patch<{ subscription: Subscription }>(
          `/api/staff/powerbi/subscriptions/${id}/cancel`,
          options,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  generateLicenseKeyById(id: SubscriptionId, options) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.patch<{ subscription: Subscription }>(
          `/api/staff/powerbi/subscriptions/${id}/generate-license-key`,
          options,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

}
