import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { State } from 'src/app/state';
import { namingConventionValidator } from 'src/app/shared/validators/naming-convention.validator';
import { HierarchyNode } from 'tp-traqplan-core/dist/data-structs';
import * as projectsActions from 'src/app/state/projects/actions';
import * as modalActions from 'src/app/state/modal/actions';
import { HierarchyApiService } from 'src/app/api/hierarchy-api.service';

@Component({
  selector: 'app-rename-programme',
  templateUrl: './rename-programme.component.html',
  styleUrls: ['./rename-programme.component.scss']
})
export class RenameProgrammeComponent {

  @Input() programme: HierarchyNode;

  actions: ModalWindowAction[] = [
    {
      label: 'Rename Programme',
      type: 'positive',
      confirm: true,
      valid: () => (this.programme && this.programmeForm.valid),
      enterKey: true,
    },
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true,
      escKey: true
    }
  ];

  visible = true;

  programmeForm = new FormGroup({
    name: new FormControl('',
      [
        Validators.required,
        Validators.minLength(1),
        namingConventionValidator()
      ],
      [
        (control: AbstractControl) => {
          const name = control.value;
          if (name === this.programme.name) {
            return of(null);
          }
          return this.hierarchyApi.checkAvailable(name, 1).pipe(
            map(({ available }) => {
              if (available) {
                return null;
              }
              return { namingConflict: true };
            })
          );
        }
      ]
    )
  });

  constructor(
    private store: Store<State>,
    private hierarchyApi: HierarchyApiService
  ) { }

  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }

  onActionSelected(action: ModalWindowAction): void {

    if (!action.confirm || !this.programme || this.programmeForm.controls.name.value === this.programme.name) {
      return;
    }

    this.store.dispatch(
      projectsActions.updateNode({
        id: this.programme.id,
        update: {
          name: this.programmeForm.controls.name.value
        }
      })
    );

  }

}
