import { Component, HostBinding, Input, OnInit } from '@angular/core';

export interface NavBarLink {
  children?: NavBarLink[];
  expanded?: boolean;
  icon: string;
  iconClass?: string;
  label: string;
  link: string[];
  queryParams?: any;
  onClick?: () => void;
  disabled?: boolean;
  subLabel?: string;
  isActive?: boolean;
}

const HeaderTooltip = {
  true: 'Expand side-menu',
  false: 'Collapse side-menu',
}



@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit{
  @HostBinding('class.collapsed') public collapsed: boolean = true;
  @Input() public links: NavBarLink[] = [];

  public activeLink: string = '';


  ngOnInit() {
    this.activeLink = (window.location.pathname.split('?')[0].split('/').pop()) as string;
    if(this.activeLink === 'poap'){
      this.activeLink = 'timeline';
    }else if(this.activeLink === 'overview'){
      this.activeLink = 'workspaces';
    }

    this.activeLink = this.activeLink.charAt(0).toUpperCase()+this.activeLink.slice(1)

  }

  public headerTooltip: string = 'Expand side-menu';

  public toggle(): void {
    this.collapsed = !this.collapsed;
    this.headerTooltip = HeaderTooltip[this.collapsed.toString()];
  }

  public toggleSubMenu(link: NavBarLink): void {
    link.expanded = !link.expanded;
  }
}
