import { HierarchyNode, HierarchyNodeId, RevisionInfo, TreeGrouping } from 'tp-traqplan-core/dist/data-structs';

const treeGroupKeyDelimiter = '##::##';

export function isProjectArchived(project: HierarchyNode): boolean {
  return !!project.archived;
}

export function isRevisionImportPending(revision: RevisionInfo): boolean {
  return !!(revision.imported && !revision.published);
}

export function getTreeGroupPath(grouping: TreeGrouping): string[] {
  const path = [];
  let g = grouping;
  do {
    path.unshift(g.value);
    g = g.parent;
  } while (g);
  return path;
}

export function getTreeGroupKey(values: string[]): string {
  return values.join(treeGroupKeyDelimiter);
}

export function mapNodesById(nodes: HierarchyNode[]): Map<HierarchyNodeId, HierarchyNode> {
  return new Map<HierarchyNodeId,HierarchyNode>(nodes.map(n => [n.id, n]));
}

/**
 * Depth in tree:
 * workspace = 0
 * programme = 1
 * project = 2
 */
export function getNodeDepth(node: HierarchyNode, nodeMap: Map<HierarchyNodeId,HierarchyNode>): number {
  let i = 0;
  let p = node;
  while(p?.parent) {
    i++;
    p = nodeMap.get(p.parent);
  }
  return i;
}

export function getNodePath(node: HierarchyNode, nodeMap: Map<HierarchyNodeId,HierarchyNode>): HierarchyNodeId[] {
  const path = [];
  while (node) {
    path.push(node.id);
    node = nodeMap.get(node.parent);
  }
  return path;
}
