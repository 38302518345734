import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take, switchMap, map } from 'rxjs/operators';
import { AuthApiService } from './auth-api.service';
import { defaultPipe } from './common';
import { AccountInfo, AccountId, HierarchyNode, License, User, UserId, LicenseId } from 'tp-traqplan-core/dist/data-structs';
import { ReqHeader, LicenseRequest } from './structs';

@Injectable({
  providedIn: 'root'
})
export class AccountApiService {

  constructor(
    private http: HttpClient,
    private authApi: AuthApiService
  ) { }

  requestAvailable(): Observable<{ accounts: AccountInfo[] }> {
    return this.authApi.headers({ requires: ReqHeader.user }).pipe(
      take(1),
      switchMap((headers): Observable<{ accounts: AccountInfo[] }> => {
        return this.http.get<{ accounts: AccountInfo[] }>(
          '/api/accounts',
          { headers }
        ).pipe(
          defaultPipe(),
          map(({ accounts }: { accounts: AccountInfo[] }) => {
            accounts.forEach(a => {
              a.created = new Date(a.created);
              a.deleted = new Date(a.deleted);
            });
            return { accounts };
          })
        )
      })
    );
  }

  requestRoots(account: AccountId): Observable<HierarchyNode[]> {
    return this.authApi.headers({
      requires: ReqHeader.user,
      set: { account }
    }).pipe(
      switchMap((headers): Observable<HierarchyNode[]> => {
        return this.http.get<{ roots: HierarchyNode[]; }>(
          `/api/accounts/roots`,
          { headers }
        ).pipe(
          defaultPipe(),
          map(({ roots }) => {
            roots.forEach(r => {
              r.created = new Date(r.created);
              r.archived = r.archived ? new Date(r.archived) : null;
            });
            return roots;
          })
        )
      })
    );
  }

  requestLicenses(): Observable<{ licenses: License[] }> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ licenses: License[] }> => {
        return this.http.get<{ licenses: License[] }>(
          '/api/accounts/licenses',
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  requestAdmins(): Observable<{ admins: { [key: string]: User } }> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ admins: { [key: string]: User } }> => {
        return this.http.get<{ admins: { [key: string]: User } }>(
          '/api/accounts/admins',
          { headers }
        ).pipe(
          defaultPipe()
        )
      })
    );
  }

  requestUsers(): Observable<{ users: { [key: string]: User } }> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ users: { [key: string]: User } }> => {
        return this.http.get<{ users: { [key: string]: User } }>(
          '/api/accounts/users',
          { headers }
        ).pipe(
          defaultPipe()
        )
      })
    );
  }

  create(name: string): Observable<{ account: AccountInfo }> {
    return this.authApi.headers({ requires: ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ account: AccountInfo }> => {
        return this.http.post<{ account: AccountInfo }>(
          '/api/accounts',
          { name },
          { headers }
        ).pipe(
          defaultPipe(),
          map(({ account }) => {
            return {
              account: {
                ...account,
                created: new Date(account.created),
                deleted: account.deleted ? new Date(account.deleted) : null
              }
            };
          })
        );
      })
    );
  }

  createTrial(name: string): Observable<{ account: AccountInfo }> {
    return this.authApi.headers({ requires: ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ account: AccountInfo }> => {
        return this.http.post<{ account: AccountInfo }>(
          '/api/accounts/trial',
          { name },
          { headers }
        ).pipe(
          defaultPipe(),
          map(({ account }) => {
            return {
              account: {
                ...account,
                created: new Date(account.created),
                deleted: account.deleted ? new Date(account.deleted) : null
              }
            };
          })
        );
      })
    );
  }

  assignLicense(userId, license) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post(
          '/api/accounts/licenses/assign',
          { userId, license },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  editLicense(id, update: Partial<License>) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post(
          '/api/accounts/licenses',
          { id, update },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  getUserLicense(user): Observable<{ license: License, user: User }> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ license: License, user: User }> => {
        return this.http.get(
          '/api/accounts/license/user',
          { params: { user }, headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  requestLicense(adminIds) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post(
          '/api/accounts/licenses/request',
          { adminIds },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  createLicenses(licenses: LicenseRequest[]) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.put(
          '/api/accounts/licenses',
          { licenses },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  removeLicenses(licenses: LicenseId[]) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post(
          '/api/accounts/licenses/delete',
          { licenses },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  removeUser(user: UserId) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post(
          '/api/accounts/remove-user',
          { user },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  checkAvailable(name: string): Observable<{ available: boolean }> {
    return this.http.get(
      '/api/accounts/check-available',
      { params: { name } }
    ).pipe(
      defaultPipe()
    );
  }

  setAccess({ userId, path, permission }) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post(
          '/api/access',
          { userId, path, permission },
          { headers }
        )
      })
    );
  }

}
