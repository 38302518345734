<app-modal-window
  header="Edit Milestones"
  [actions]="actions"
  (actionSelected)="onActionSelected($event)"
  [(visible)]="visible"
  (closed)="onClose()"
  width="75vw"
  maxWidth="75vw"

>
  <!--<ng-container *ngIf="this.data$ | async as data">-->
  <div class="add-milestone" *ngIf="userCanCreate">
    <div class="tp-label add-milestone__header">Create New Milestones</div>

    <form
      *ngIf="userCanCreate"
      action=""
      (ngSubmit)="this.onAdd()"
      [formGroup]="this.form"
    >

    <div class="tp-form add-milestone__form">
      <div class="tp-form__field">
        <label for="name">Name:</label>

        <input
          id="name"
          type="text"
          pInputText
          autocomplete="off"
          formControlName="name"
        />
      </div>

      <div class="tp-form__field">
        <label for="symbol">Symbol</label>

        <p-dropdown
          [options]="symbolsAvailable"
          optionLabel="value"
          formControlName="symbol"
          placeholder="Select symbol..."
        >
          <ng-template let-item pTemplate="item">
            <div class="symbol-select">
              <span class="symbol-select__icon" [innerHTML]="getSymbolMarkup(item) | safeHtml">
              </span>
              <span class="symbol-select__desc">{{item.value}}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="tp-form__field">
        <label for="colour">Colour</label>

        <p-dropdown
          [options]="coloursAvailable"
          optionLabel="value"
          formControlName="colour"
          placeholder="Select colour..."
        ></p-dropdown>
      </div>



      <div class="tp-form__field" *ngIf="userCanCreateGlobal" pTooltip="A Global milestone is available to all workspaces across the entire account.  This helps drive standards in milestones across the organisation.  Only your administrator can set/unset global milestones.“" tooltipPosition="top">
        <span class="fas circle-info">info-circle</span><label for="global"> Global? </label>

        <input

          type="checkbox"
          formControlName="global"
        />
      </div>

      <div class="tp-form__field" pTooltip="A locked milestone may only be modified by administrators." tooltipPosition="top">
       <span class="fas circle-info">info-circle</span> <label for="global">Locked?</label>

        <input
          type="checkbox"
          formControlName="locked"
        />
      </div>
      <div class="tp-form__field description">
        <label for="description">Description</label>

        <input
          id="description"
          type="text"
          pInputText
          autocomplete="off"
          formControlName="description"
        />


        </div>
        <div class="tp-form__field">


        <button
          class="tp-icon-button_filled tp-icon-button_positive"
          name="add"
          data-position="left center"
          type="submit"
          [disabled]="!form.valid"
          >
          <span class="fas">plus</span>
        </button>
        </div>
      </div>
      <!-- <div class="bottom-row-milestone">
        <div class="tp-form__field description">
          <label for="description">Description</label>

          <input
            id="description"
            type="text"
            pInputText
            autocomplete="off"
            formControlName="description"
          />
         </div>

          <button
            class="tp-icon-button_filled tp-icon-button_positive"
            name="add"
            data-position="left center"
            type="submit"
            [disabled]="!form.valid"
            >
            <span class="fas">plus</span>
          </button>

      </div> -->

    </form>
  </div>

  <div class="error-card">
    <p-dialog header="Milestone Already Exists" [(visible)]="errorMessage" [style]="{ 'width':'auto', 'max-width':'400px' }" (onHide)="onCloseError()">
      <div >A milestone with this color and shape already exists.  Choose a different color or shape.
      </div>
      <ng-template pTemplate="footer">
        <p-button (click)="onCloseError()" label="Close"></p-button>
      </ng-template>
    </p-dialog>
  </div>

  <ag-grid-angular
    style="width: 100%; height: 250px;"
    class="ag-theme-balham"
    [gridOptions]=gridOptions
    [getRowNodeId]="getRowNodeId"
    [enableRangeSelection]="false"
    [modules]="modules"
    [rowData]="milestones$ | async"
    [columnDefs]="columnDefs"
    (cellValueChanged)="onCellValueChanged($event)"
  >
  </ag-grid-angular>

  <!--
    [enableSorting]="false"
    [enableColResize]="false"
    (gridReady)="onGridReady($event)"
    (cellFocused)="onCellFocused($event, data.isAdmin)"
    (cellValueChanged)="this.onCellValueChanged($event)"
    [showToolPanel]="false"
    [toolPanelSuppressSideButtons]="true"-->
  <!--</ng-container>-->
</app-modal-window>
