export class TextWrapping {

  private cnv;
  private cnvContext;
  private cnvFontSize: number;

  constructor() {
    this.cnv = document.createElement('canvas');
    this.cnvContext = this.cnv.getContext('2d');
  }

  setFontSize(fontSize) {
    this.cnvFontSize = fontSize;
    this.cnvContext.font = `500 ${fontSize}px sans-serif`;
  }

  measureText(text) {
    return this.cnvContext.measureText(text).actualBoundingBoxRight;
  }

  wrapText(text: string, maxWidth: number, maxLines: number, overflow?: string): string[] {

    const words = `${text || ''}`.split(' ');
    const segments = [];
    let line = [];
    let li = 0;

    while (words.length > 0) {
      // add word to current line and get width:
      line.push(words.shift());
      const width = this.measureText(line.join(' '));

      if (width > maxWidth) {
        if (line.length === 1 || li + 1 === maxLines) {
          segments[li] = this.cropWord(line.join(' '), maxWidth);

          if (overflow && overflow.length > 0) {
            segments[li + 1] = overflow;
          }

          break;
        }
        //put last word back in words array:
        words.unshift(line.pop());
        segments[li] = line.join(' ');
        line = [];
        li++;
      } else if (words.length == 0) {
        segments[li] = line.join(' ');
      }
    }
    return segments;
  }

  textFits(text: string, maxWidth: number, maxLines: number): boolean {

    const wrapped = this.wrapText(text, maxWidth, maxLines, '');

    return (text.length <= wrapped.join(' ').length);

  }

  private cropWord(text, maxWidth, overflow = '') {

    const chars = text.split('');
    const endChars = overflow.split('');

    while (this.measureText(chars.concat(endChars).join('')) > maxWidth) {

      if (chars.length > 0) {
        chars.pop();
        continue;
      }
      if (endChars.length > 0) {
        endChars.pop();
        continue;
      }
      return '';

    }
    return chars.concat(endChars).join('');
  }

}
