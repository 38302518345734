import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { PoapTask, TimelineGroup } from 'tp-traqplan-core/dist/data-structs';
import { TimelineTask, TaskNode, TaskBoundElement } from 'tp-traqplan-core/dist/timeline/tl-structs';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import * as groupUtils from 'tp-traqplan-core/dist/task-group-utils';
import { TimelineInteractions } from '../tl-interactions';
import { PoapTaskFieldChange } from 'tp-traqplan-core/dist/workspace-structs';

const DefaultRenameTitle = 'Rename Task';

@Component({
  selector: 'app-timeline-context-menu',
  templateUrl: './timeline-context-menu.component.html',
  styleUrls: ['./timeline-context-menu.component.css']
})
export class TimelineContextMenuComponent implements OnInit, OnDestroy {

  @ViewChild('contextMenu', { static: true }) menu: ContextMenu;

  @Input() interactions: TimelineInteractions;

  @Output() taskChange = new EventEmitter<{ task: PoapTask; field: string; value: any }>();

  target: TaskBoundElement;
  task: TimelineTask;

  xPosition: string;
  yPosition: string;

  menuActive: boolean;
  menuItems: MenuItem[] = [];

  modalRenameVisible: boolean;
  modalCompletionVisible: boolean;
  modalDeleteVisible: boolean;
  modalSwimlaneVisible: boolean;

  modalRenameActions: ModalWindowAction[];
  modalRenameRenameValue: string;
  modalRenameNameValue: string;
  modalCompletionActions: ModalWindowAction[];
  modalCompletionValue: number;
  modalDeleteActions: ModalWindowAction[];
  modalSwimlaneActions: ModalWindowAction[];
  //modalSwimlaneTree: TreeNode[];
  modalSwimlaneSelected: Partial<TimelineGroup>;
  modalSwimlaneBranches: string[];
  modalSwimlaneCreate: string;

  renameTitle: string = DefaultRenameTitle;

  private destroy$ = new Subject<void>();
  private taskNode: TaskNode;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {

    this.interactions?.events$.pipe(
      takeUntil(this.destroy$),
      filter(e => e.event === 'showContextMenu')
    ).subscribe(({ data }) => {
      this.target = data.target;
      this.task = data.target.__fitting__;
      this.xPosition = `${data.event.clientX}px`;
      this.yPosition = `${data.event.clientY}px`;

      this.updateMenuItems();
      setTimeout(() => this.menu.show(), 50);
    });

    const defaultCancelAction: ModalWindowAction = {
      label: 'Cancel',
      type: 'neutral',
      escKey: true
    };

    const defaultSaveAction: ModalWindowAction = {
      label: 'Save',
      type: 'positive',
      enterKey: true
    };

    this.modalRenameActions = [
      {
        ...defaultSaveAction,
        valid: () => this.modalRenameNameValue
      },
      { ...defaultCancelAction }
    ];

    this.modalCompletionActions = [
      { ...defaultSaveAction },
      { ...defaultCancelAction }
    ];

    this.modalDeleteActions = [
      {
        ...defaultSaveAction,
        label: 'Confirm'
      },
      { ...defaultCancelAction }
    ];

    this.modalSwimlaneActions = [
      {
        ...defaultSaveAction,
        valid: () => this.modalSwimlaneSelected
      },
      { ...defaultCancelAction }
    ];

  }

  /*onMouseOver(): void {
    clearTimeout(this.menuTimeout);
  }

  onMouseLeave(): void {
    clearTimeout(this.menuTimeout);
    this.menuTimeout = window.setTimeout(() => {
      clearTimeout(this.menuTimeout);
      this.menu.hide();
    }, 200);
  }*/

  modalRenameAction(action): void {
    if (action.label === 'Save') {
      this.interactions.change(this.task.ProjectTasks.map(task => <PoapTaskFieldChange[]>[
        {
          task: task,
          field: 'rename',
          value: this.modalRenameRenameValue || ''
        },
        {
          task: task,
          field: 'name',
          value: this.modalRenameNameValue || ''
        }
      ]).flat(1));
    }
  }

  modalCompletionAction(action: ModalWindowAction): void {
    if (action.label === 'Save') {
      this.interactions.change(this.task.ProjectTasks.map(task => <PoapTaskFieldChange>({
        task: task,
        field: 'percentComplete',
        value: this.modalCompletionValue
      })));
    }
  }

  modalDeleteAction(action: ModalWindowAction): void {
    if (action.label === 'Confirm') {
      this.interactions.change(this.task.ProjectTasks.map(task => <PoapTaskFieldChange>({
        task: task,
        field: 'deleted',
        value: true
      })));
    }
  }

  modalSwimlaneAction(action: ModalWindowAction): void {
    if (action.label !== 'Save') return;

    if (this.modalSwimlaneSelected) {
      const path = groupUtils.getGroupPath(
        this.interactions.layout.settings.groupMap,
        this.modalSwimlaneSelected.id as string
      );
      if (this.modalSwimlaneCreate) {
        path.push(this.modalSwimlaneCreate);
      }
      // const groupPath = groupUtils.stringifyGroupPath(path);
      const groupPath = path;
      this.interactions.change(this.task.ProjectTasks.map(task => <PoapTaskFieldChange>({
        task: task,
        field: 'groupPath',
        value: groupPath
      })));
      this.modalSwimlaneCreate = '';
      this.modalSwimlaneSelected = undefined;
    }
  }

  modalSwimlaneSelectionChange(group: Partial<TimelineGroup>): void {
    if (group && group.id) {
      this.modalSwimlaneSelected = group;
    }
  }

  onModalSwimlaneCreateInput({ target }): void {
    const eInput = target as HTMLInputElement;
    this.modalSwimlaneCreate = eInput.value = groupUtils.cleanGroupName(eInput.value);
  }

  private updateMenuItems(): void {
    this.menuItems = [
      ...this.renameOptions(),
      ...this.styleOptions(),
      ...this.percentCompleteOptions(),
      ...this.mergeOptions(),
      ...this.unmergeOptions(),
      ...this.swimlaneOptions(),
      ...this.removalOptions()
    ];
  }

  private renameOptions(): any[] {
    if (this.interactions.checkEditable({ task: this.task, fields: ['rename'] })) {
      const { name, rename } = this.task.ProjectTasks[0];

      this.modalRenameNameValue = name;
      this.modalRenameRenameValue = rename;
      this.renameTitle = `${DefaultRenameTitle} - ${name}`;

      return [{
        label: 'Rename',
        command: () => { this.modalRenameVisible = true; }
      }];
    }
    return [];
  }

  private styleOptions(): any[] {
    const menuItems = <any>[];
    if (this.task.Milestone && this.interactions.checkEditable({ task: this.task, fields: ['milestoneCategory'] })) {
      menuItems.push({
        label: 'Milestone Category',
        items: this.interactions.layout.settings.milestoneCategories.map(item => {
          // const label = item.id.toString() + ' ' + item.description;
          return {
            label: item.description || item.name || 'Unknown',
            command: () => this.interactions.change(this.task.ProjectTasks.map(task => <PoapTaskFieldChange>({
              task: task,
              field: 'milestoneCategory',
              value: item.id
            })))
          };
        })
      });
    } else if (this.interactions.checkEditable({ task: this.task, fields: ['colour'] })) {
      menuItems.push({
        label: 'Bar Colour',
        items: Array.from(this.interactions.layout.settings.coloursAvailable.keys()).map(name => ({
          label: name,
          command: () => this.interactions.change(this.task.ProjectTasks.map(task => <PoapTaskFieldChange>({
            task: task,
            field: 'colour',
            value: name
          })))
        }))
      });
    }
    return menuItems;
  }

  private percentCompleteOptions(): any[] {
    if (this.interactions.checkEditable({ task: this.task, fields: ['percentComplete'] })) {
      this.modalCompletionValue = this.task.ProjectTasks[0].percentComplete;
      return [{
        label: '% Complete',
        command: () => { this.modalCompletionVisible = true; }
      }];
    }
    return [];
  }

  private mergeOptions(): any[] {
    if (this.interactions.checkEditable({ task: this.task, fields: ['rename'] })) {
      const { project: projectId } = this.task.ProjectTasks[0];
      const menuItems = <any>[];
      const mergeSubMenu = <any>[];
      const mergeLabels = {};

      // get list of existing names:
      for (const poapTask of this.interactions.layout.taskIterator()) {
        // exclude task names from other projects or which are within the active task:
        if (poapTask.project !== projectId || this.task.ProjectTasks.some(t => t.id === poapTask.id)) {
          continue;
        }
        const rename = poapTask.rename;
        const name = poapTask.name;
        if (!rename || !mergeLabels[rename]) {
          mergeLabels[rename] = true;
          mergeSubMenu.push({
            label: rename || name,
            command: () => this.mergeTask(this.task, poapTask)
          });
        }
      }

      if (mergeSubMenu.length) {
        menuItems.push({
          label: 'Merge into...',
          items: mergeSubMenu
        });
      }
      return menuItems;
    }
    return [];
  }

  private swimlaneOptions(): any[] {
    if (this.interactions.checkEditable({ task: this.task, fields: ['groupPath'] })) {
      return [{
        label: 'Move to swimlane...',
        command: () => { this.modalSwimlaneVisible = true; }
      }];
      //this.modalSwimlaneBranches = [groupUtils.getRootGroup(this.interactions.layout.settings.groupMap, projectId).id];
    }
    return [];
  }

  private unmergeOptions(): any[] {
    if (this.interactions.checkEditable({ task: this.task, fields: ['rename'] })) {
      if (this.task.ProjectTasks.length > 1) {
        return [{
          label: 'Unmerge',
          items: this.task.ProjectTasks.map(poapTask => ({
            label: poapTask.name,
            command: () => this.interactions.change([<PoapTaskFieldChange>{
              task: poapTask,
              field: 'rename',
              value: ''
            }])
          }))
        }];
      }
    }
    return [];
  }

  private removalOptions(): any[] {
    const menuItems = <any>[];
    if (this.interactions.checkEditable({ task: this.task, fields: ['selected'] })) {
      menuItems.push({
        label: 'Remove from view',
        command: () => this.interactions.change(this.task.ProjectTasks.map(task => <PoapTaskFieldChange>({
          task: task,
          field: 'selected',
          value: false
        })))
      });
    }
    if (this.interactions.checkEditable({ task: this.task, fields: ['deleted'] })) {
      menuItems.push({
        label: 'Delete',
        command: () => { this.modalDeleteVisible = true }
      });
    }
    return menuItems;
  }

  private mergeTask(from: TimelineTask, to: PoapTask): void {
    const changed: PoapTask[] = from.ProjectTasks;

    !to.rename && changed.push(to);

    //ensure groups match:
    const toGroup = groupUtils.stringifyGroupPath(to.groupPath);
    const fromGroup = groupUtils.stringifyGroupPath(from.ProjectTasks[0].groupPath);
    const changes = <PoapTaskFieldChange[]>[];

    if (toGroup !== fromGroup) {
      changes.push(...this.task.ProjectTasks.map(task => <PoapTaskFieldChange>({
        task: task,
        field: 'groupPath',
        value: toGroup
      })));
    }
    changes.push(...this.task.ProjectTasks.map(task => <PoapTaskFieldChange>({
      task: task,
      field: 'rename',
      value: to.rename || to.name
    })));
    this.interactions.change(changes);
  }

}
