/** @module */
/**
 * Create an Error with a 'safe' message
 * @param {string} message
 */
exports.safeError = function(message) {
  return Object.assign(new Error, { name: 'UserError', message });
};

/**
 * Create a safe error indicating an action is forbidden
 * @param {string} message
 */
exports.forbiddenError = function(message) {
  return Object.assign(new Error, { name: 'ForbiddenError', message });
};

/**
 * Create a safe error indicating there is some data related issue (corrupt or missing data for example):
 * @param {string} message
 */
exports.modelError = function(message) {
  return Object.assign(new Error, { name: 'ModelError', message });
};

/**
 * Create a safe error indicating that a required resource was not found
 * @param {string} message
 */
exports.notFoundError = function (message) {
  return Object.assign(new Error, { name: 'NotFoundError', message });
};

/**
 * Create a safe error indicating there was an issue authenticating user
 * @param {string} message
 */
exports.authenticationError = function(message) {
  return Object.assign(new Error, { name: 'AuthenticationError', message });
};

/**
 * Create a safe error indicating a data validation error
 * @param {string} message
 */
exports.validationError = function(message) {
  return Object.assign(new Error, { name: 'ValidationError', message });
};
