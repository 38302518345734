import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from '@ag-grid-community/angular';
import { CoreModule } from 'src/app/core/core.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

import { MaterialModule } from '../material.module';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';

import { CreateProjectComponent } from './create-project/create-project.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { SessionTimeoutComponent } from './session-timeout/session-timeout.component';
import { RenameProjectComponent } from './rename-project/rename-project.component';
import { RenameProgrammeComponent } from './rename-programme/rename-programme.component';
import { DeleteProjectComponent } from './delete-project/delete-project.component';
import { LabelRevisionComponent } from './label-revision/label-revision.component';
import { EditAccessComponent } from './edit-access/edit-access.component';
import { ShareAccessComponent } from './share-access/share-access.component';
import { CreateViewComponent } from './create-view/create-view.component';
import { CopyViewComponent } from './copy-view/copy-view.component';
import { EditMilestonesComponent } from './edit-milestones/edit-milestones.component';
import { EditViewlinesComponent } from './edit-viewlines/edit-viewlines.component';
import { PublishConflictComponent } from './publish-conflict/publish-conflict.component';
import { PublishConflictHeaderComponent } from './publish-conflict/components/header/publish-conflict-header.component';
import { PublishConflictTableComponent } from './publish-conflict/components/table/publish-conflict-table.component';
import { PublishDraftComponent } from './publish-draft/publish-draft.component';

import { AgGridCheckboxComponent } from 'src/app/ag-grid-components/ag-grid-checkbox/ag-grid-checkbox.component';
import { AgGridDatepickerEditorComponent } from 'src/app/ag-grid-components/ag-grid-datepicker-editor/ag-grid-datepicker-editor.component';
import { AgGridDropdownColourRendererComponent } from 'src/app/ag-grid-components/ag-grid-dropdown-colour-renderer/ag-grid-dropdown-colour-renderer.component';
import { AgGridDropdownSymbolRendererComponent } from 'src/app/ag-grid-components/ag-grid-dropdown-symbol-renderer/ag-grid-dropdown-symbol-renderer.component';
import { WorkspaceSettingsComponent } from './workspace-settings/workspace-settings.component';
import { CreateWorkspaceComponent } from './create-workspace/create-workspace.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { InactivityWarningComponent } from './inactivity-warning/inactivity-warning.component';
import { ResumeSessionComponent } from './resume-session/resume-session.component';
import { MoveProjectComponent } from './move-project/move-project.component';
import { MoveProgrammeComponent } from './move-programme/move-programme.component';
import { CopyProjectComponent } from './copy-project/copy-project.component';
import { CopyProgrammeComponent } from './copy-programme/copy-programme.component';
import { ShowMessageComponent } from './show-message/show-message.component';
import { RequestAccessComponent } from './request-access/request-access.component';
import { RequestLicenseUpgradeComponent } from './request-license-upgrade/request-license-upgrade.component';
import { ReviewAccessRequestComponent } from './review-access-request/review-access-request.component';
import { ReviewLicenseRequestComponent } from './review-license-request/review-license-request.component';
import { AccessGrantedComponent } from './access-granted/access-granted.component';
import { EditLicenseComponent } from './edit-license/edit-license.component';

@NgModule({
  declarations: [
    InfoBoxComponent,
    SessionTimeoutComponent,
    CreateProjectComponent,
    RenameProjectComponent,
    RenameProgrammeComponent,
    DeleteProjectComponent,
    LabelRevisionComponent,
    EditAccessComponent,
    ShareAccessComponent,
    CreateViewComponent,
    CopyViewComponent,
    EditMilestonesComponent,
    EditViewlinesComponent,
    PublishConflictComponent,
    PublishConflictHeaderComponent,
    PublishConflictTableComponent,
    PublishDraftComponent,
    WorkspaceSettingsComponent,
    CreateWorkspaceComponent,
    CreateAccountComponent,
    InactivityWarningComponent,
    ResumeSessionComponent,
    MoveProjectComponent,
    MoveProgrammeComponent,
    CopyProjectComponent,
    CopyProgrammeComponent,
    ShowMessageComponent,
    RequestAccessComponent,
    RequestLicenseUpgradeComponent,
    ReviewAccessRequestComponent,
    ReviewLicenseRequestComponent,
    AccessGrantedComponent,
    EditLicenseComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    CoreModule,
    MaterialModule,
    DropdownModule,
    CalendarModule,
    TooltipModule,
    DialogModule,
    AutoCompleteModule,
    InputTextModule,
    InputSwitchModule,
    AgGridModule.withComponents([
      AgGridCheckboxComponent,
      AgGridDatepickerEditorComponent,
      AgGridDropdownColourRendererComponent,
      AgGridDropdownSymbolRendererComponent
    ]),
    PipesModule
  ],
  exports: [
    InfoBoxComponent,
    SessionTimeoutComponent,
    CreateProjectComponent,
    RenameProjectComponent,
    RenameProgrammeComponent,
    DeleteProjectComponent,
    LabelRevisionComponent,
    RequestAccessComponent
  ]
})
export class ModalsModule {}
