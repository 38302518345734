const { v4: uuidv4 } = require('uuid');
const { validationError } = require('./error.js');

const uuidTest = /^[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/;

exports.uuid = function() {
  return uuidv4();
};

exports.validateUUID = function(str) {
  return uuidTest.test(str);
};

exports.assertValidUUID = function(str, message) {
  if (!exports.validateUUID(str)) {
    throw validationError(message || 'Invalid UUID');
  }
};
