import { Component, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TimelineInteractions } from '../tl-interactions';
import { Subject, takeUntil, filter } from 'rxjs';
import { EventID } from '../tl-events';

@Component({
  selector: 'app-floating-input',
  templateUrl: './floating-input.component.html',
  styleUrls: ['./floating-input.component.scss']
})
export class FloatingInputComponent implements OnInit, OnDestroy {

  @Input() interactions: TimelineInteractions;

  @HostBinding('class.visible') visible: boolean = false;

  @ViewChild('inputElement', { static: true }) input: ElementRef;

  value: string | null;
  placeholder: string | null;
  validator: ((a: string) => string|null) | null;
  complete: ((a: string) => void) | null;
  error?: string | null;

  private submitted: boolean;
  private destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {

    this.interactions.events$.pipe(
      takeUntil(this.destroy$),
      filter(e => e.event === EventID.ShowFloatingInput)
    ).subscribe(({ data }) => {
      this.value = data.value || '';
      this.placeholder = data.label;
      this.complete = data.complete;
      this.validator = data.validator;
      setTimeout(() => {
        this.visible = true;
        this.input?.nativeElement?.focus();
      }, 50);
    });

  }

  /*onInput(event) {
    if (this.validator) {
      this.error = this.validator(event.target.value);
    }
  }*/

  onClick(event) {
    event.stopPropagation();
  }

  async onKeyup(event) {
    if (this.visible) {
      if (event.code === 'Enter') {
        await this.onComplete();
      } else if (event.code === 'Escape') {
        this.reset();
      }
    }
  }

  async onInput() {
    if (this.submitted && this.validator) {
      this.error = await this.validator(this.value);
    }
  }

  async onComplete() {
    this.submitted = true;
    if (this.validator) {
      this.error = await this.validator(this.value);
    }

    if (!this.error) {
      this.complete?.(this.value);
      this.reset();
    }
  }

  @HostListener('document:click')
  onDocumentClick() {
    this.visible && this.reset();
  }

  @HostListener('document:keyup', ['$event'])
  onDocumentKeyup(event) {
    this.visible && event.code === 'Escape' && this.reset();
  }

  private reset() {
    this.visible = false;
    this.value = this.placeholder = this.error = '';
    this.validator = this.complete = null;
  }

}
