/**
 * @description
 * This module is in the process of being refactored for performance and broken up. Avoid editing if possible.
 */
import { taskConstants } from 'tp-common/revisions';
import { TimelineLayout } from './timeline-layout';
import { TaskNode } from './tl-structs';
import { LegendFittingMode, LegendPosition } from '../data-structs';
import { CORE_STYLE, BASELINE_THICKNESS, CLASS_SCREEN_ONLY } from './tl-style';
import { removeChildren, mkNode } from '../svg-utils';
import { generateSVGSymbol } from '../milestone-symbols';
import { uuid } from 'tp-common/uuid.js';
import * as tlStyle from './tl-style';

//const GROUP_STROKE_ALLOWANCE = 1.5;
//const TICK_WIDTH_MULTIPLIER = 7;

export function isTaskSelected(taskNode: TaskNode): boolean {
  return taskNode.wrapper.dataset.selected === 'true';
}

export function setTaskNodeDragState(taskNode: TaskNode, dragging: boolean): void {
  taskNode.wrapper.setAttribute(tlStyle.standardAttribute.dataDragging, dragging.toString());
}

export function setTaskNodeSelectedState(taskNode: TaskNode, selected: boolean): void {
  taskNode.wrapper.dataset.selected = selected.toString();
}

export function updateTaskNode(layout: TimelineLayout, taskNode: TaskNode): void {
  const { fittingData, wrapper, background, text, symbol, baseline, positionData: { verticalOffset, nominalHeight } } = taskNode;
  const baseX = fittingData.BarStart;
  const textX = fittingData.TextStart - baseX;

  wrapper.setAttribute('transform', `translate(${baseX},${verticalOffset})`);

  wrapper.__fitting__ = fittingData;

  background.setAttribute('width', `${Math.abs(fittingData.BarWidth)}`);
  background.setAttribute('height', `${Math.abs(nominalHeight)}`);

  text.setAttribute('transform', `translate(${textX},0)`);

  wrapper.setAttribute(tlStyle.standardAttribute.dataMilestone, Boolean(fittingData.Milestone).toString());

  if (!fittingData.Milestone) {
    if (fittingData.wrapTextOutside) {
      text.setAttribute('data-light', 'false');
      // if text outside bar then use milestone text colour to ensure contrast with background:
      if (layout.settings.theme?.milestoneTextColour) {
        text.style.fill = layout.settings.theme?.milestoneTextColour;
      }
    } else {
      text.setAttribute('data-light', 'true');
      // apply default text colour if none provided:
      text.style.fill = fittingData.TextColour || layout.settings.theme?.barTextColour;
    }

    background.classList.remove(CLASS_SCREEN_ONLY);
    /**
     * This is moving towards using the 'theme' object in layout settings + local overrides. Currently handling for legacy as well
     */
    if (fittingData.ProjectTasks.every((v => v.percentComplete === 100))) {
      background.setAttribute(tlStyle.standardAttribute.dataFill, 'Gray');

    } else if (!fittingData.Colour) {
      if (layout.settings.theme?.barColour) {
        background.setAttribute('fill', layout.settings.theme?.barColour);
      } else {
        background.setAttribute(tlStyle.standardAttribute.dataFill, 'default');
      }
    } else {
      if (layout.settings.coloursAvailable?.has(fittingData.Colour)) {
        background.setAttribute(tlStyle.standardAttribute.dataFill, fittingData.Colour);
      } else {
        background.setAttribute('fill', fittingData.Colour);
      }
    }

  } else {
    console.log('rendering milestone');
    const milestoneCat = layout.fittingMeta.milestoneMap?.get(fittingData.MilestoneType);
    const symbolType = milestoneCat?.symbol || taskConstants.milestoneDefaultSymbol;

    let symbolFill;
    
    if (fittingData.ProjectTasks.every((v) => v.percentComplete === 100)) {
      symbolFill = 'Gray'
    } else if (milestoneCat?.colour) {
      symbolFill = milestoneCat.colour;
    } else if (layout.settings.theme?.milestoneColour) {
      symbolFill = layout.settings.theme?.milestoneColour;
    } else {
      symbolFill = taskConstants.milestoneDefaultFill;
    }

    text.setAttribute('data-light', 'false');
    text.style.fill = milestoneCat?.textColour || layout.settings.theme?.milestoneTextColour;
    // 1.167 is the approx ratio of specified font size to actual height used. If font is changed
    // then value may need to be adjusted
    const symbolY = (layout.settings.textPadding  * 2 + layout.settings.fontSize * 1.167) / 2 - layout.settings.milestoneSymbolSize / 2;

    removeChildren(symbol);

    symbol.appendChild(generateSVGSymbol(symbolType, layout.settings.milestoneSymbolSize, symbolFill));
    symbol.setAttribute('transform', `translate(0,${symbolY})`);
    background.classList.add(CLASS_SCREEN_ONLY);
    //text.setAttribute('dx', `${layout.settings.milestoneSymbolSize + layout.settings.textPadding / 2}`);
  }

  //render baseline if available, else hide baseline:
  const showBaseline = !!(layout.settings.displayBaseline && fittingData.BaselineStart && fittingData.BaselineFinish);
  if (showBaseline) {
    const [bStart, bFinish] = [//
      fittingData.BaselineStart - fittingData.BarStart,
      fittingData.BaselineFinish - fittingData.BarStart
    ].sort((a, b) => a - b);
    //convert absolute x dimensions to local (within group) positions:
    baseline.setAttribute('x1', `${bStart + (BASELINE_THICKNESS / 2)}`);
    baseline.setAttribute('x2', `${bFinish - (BASELINE_THICKNESS / 2)}`);
    baseline.setAttribute('transform', `translate(0,${nominalHeight - (BASELINE_THICKNESS / 2)})`);
    baseline.setAttribute('data-baseline', `${fittingData.BaselineStart < fittingData.BaselineFinish ? 'positive' : 'negative'}`);
  }

  appendOrRemove(showBaseline, wrapper, baseline);
  appendOrRemove(fittingData.Milestone, wrapper, symbol);
  updateTextNode(layout.settings.textPadding, layout.settings.fontSize, layout.settings.taskPadding, taskNode.text, fittingData.TextWrapped);
}

export function updateTextNode(textPadding, fontSize, taskPadding, node: SVGElement, text: string[]): void {

  const lines = [...text];
  removeChildren(node);

  node.appendChild(
    tspan(textPadding, fontSize + textPadding, lines.shift())
  );

  lines.forEach(line => {
    node.appendChild(
      tspan(textPadding, fontSize + textPadding + taskPadding, line)
    )
  });

}

export function updatePageBreaks(g: any, axisHeight: number, pageBreaks: number[] = [], layout: TimelineLayout): void {

  removeChildren(g);

  if (pageBreaks.length) {
    for (const breakIndex of pageBreaks) {

      const y = axisHeight + TimelineLayout.getNominalHeight(breakIndex, layout.settings);

      g.appendChild(
        mkNode('line', {
          'stroke': 'blue',
          'stroke-dasharray': '3px 6px',
          'stroke-width': '2px',
          'x1': '0',
          'x2': '100%',
          'y1': y,
          'y2': y
        })
      );

    }
  }

}

/**
 * Use this when a copy of the same timeline instance is present to remove
 * clip-path id conflicts
 * @param {SVGElement} core
 */
export function randomizeClipIds(core: any): void {

  const clipPaths = core.querySelectorAll('clipPath');

  for (const cp of clipPaths) {
    const nodeList = core.querySelectorAll(`[clip-path='url(#${cp.id})']`);
    const nId = uuid();
    cp.id = nId.toString();
    for (const n of nodeList) {
      n.setAttribute('clip-path', `url(#${nId})`);
      n.style.setProperty('clip-path', `url(#${nId})`, 'important');
    }
  }

}
/**
 * @deprecated USING INLINE SVG STYLESHEET
 * @param {SVGElement} core
 */
export function embedCoreStyles(core: any, layout: TimelineLayout): void {

  for (const [selector, rules] of CORE_STYLE) {

    const nodeList = core.querySelectorAll(selector);

    for (const node of nodeList) {
      for (const [k, v] of Object.entries(rules)) {
        node.style.setProperty(k, v);
      }
    }
  }

  // for (const node of core.querySelectorAll('text')) {
  //   node.style.setProperty('font-size', layout.settings.fontSize.toString());
  // }
  for (const node of core.querySelectorAll(`[${tlStyle.standardAttribute.dataStroke}]`)) {
    node.setAttribute('stroke', layout.settings.coloursAvailable.get(node.getAttribute(tlStyle.standardAttribute.dataStroke)));
  }
  for (const node of core.querySelectorAll(`[${tlStyle.standardAttribute.dataFill}]`)) {
    const fillColour = node.getAttribute(tlStyle.standardAttribute.dataFill);
    node.setAttribute('fill', isHex(fillColour) ? fillColour : (layout.settings.coloursAvailable.get(node.getAttribute(tlStyle.standardAttribute.dataFill)) || tlStyle.standardColour.defaultTaskFill));
  }

}

export function removeScreenOnlyNodes(root: any): void {
  /** @deprecated */
  for (const node of root.querySelectorAll(`.${CLASS_SCREEN_ONLY}`)) {
    node.remove();
  }

  for (let i = 0; i < tlStyle.screenOnlyClasses.length; i++) {
    const nList = root.querySelectorAll(`.${tlStyle.screenOnlyClasses[i]}`);
    for (let i = 0; i < nList.length; i++) {
      nList[i].remove();
    }
  }

}

/** @deprecated */
export function renderLegend(container: any, layout: TimelineLayout): void {

  const { fontSize } = layout.legend.settings;

  for (const legendItem of layout.legend.legendItems) {

    const g = mkNode('g', {
      class: 'legend-item',
      transform: `translate(${legendItem.colStart},${legendItem.rowStart})`
    });

    const text = mkNode('text', {
      'font-size': layout.settings.legendFontSize,
      transform: `translate(${legendItem.relTextStart},0)`,
      dy: '1em'
    });

    const gSymbol = mkNode('g', {
      transform: `translate(${legendItem.relIconStart},0)`
    });

    gSymbol.appendChild(generateSVGSymbol(legendItem.symbol, layout.legend.iconHeight, legendItem.colour));
    
    if (layout.legend.settings.fittingMode === LegendFittingMode.compressed) {
      // in compressed mode, the text wraps to the start of the next row:
      updateTextNode(0, fontSize, 0, text, legendItem.text.slice(0,1));
      if (legendItem.text.length > 1) {
        const overflowText = mkNode('text', {
          'font-size': layout.settings.legendFontSize,
          transform: `translate(0, ${legendItem.rowStart + legendItem.rowHeight})`
        });
        updateTextNode(0, fontSize, 0, overflowText, legendItem.text.slice(1,2));
        container.appendChild(overflowText);
      }

    } else {
      updateTextNode(0, fontSize, 0, text, legendItem.text);
    }

    if (layout.legend.settings.position === LegendPosition.bottom) {
      container.setAttribute('transform', `translate(0, ${container.parentNode.height.baseVal.value - layout.legend.totalHeight})`);
    } else {
      container.removeAttribute('transform');
    }

    g.appendChild(gSymbol);
    g.appendChild(text);

    container.appendChild(g);

  }

}

export function getScrollableViewportBRect(core: any): { x: number; y: number; height: number; width: number; } {

  const containerRect = core.svgContainer.getBoundingClientRect();
  const axisRect = core.axisContainer.getBoundingClientRect();

  return {
    x: containerRect.x,
    y: containerRect.y + axisRect.height,
    height: containerRect.height - axisRect.height,
    width: containerRect.width
  };

}

export function tspan(x, dy, text): SVGTSpanElement {
  const tspan = mkNode('tspan', { x: x, dy: dy });
  tspan.textContent = text;
  return tspan;
}

function appendOrRemove(append: boolean, parent, child): void {
  append ? parent.appendChild(child) : child.remove();
}

function isHex(str) {
  return /^#?[a-fA-F0-9]{6,8}$/.test(str);
}
