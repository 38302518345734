import { createAction, props } from '@ngrx/store';
import { RequestError } from '../../../api/structs';
import * as PowerBi from '../../../powerbi-data-structs';

import Pricing = PowerBi.Pricing;
import PricingId = PowerBi.PricingId;

export const requestPricings = createAction(
  '[powerbi.pricing] requestPricings',
  props<any>()
);

export const requestPricingsSuccess = createAction(
  '[powerbi.pricing] requestPricingsSuccess',
  props<{ pricings: Pricing[] }>()
);

export const requestPricingsFailure = createAction(
  '[powerbi.pricing] requestPricingsFailure',
  props<{ error: RequestError }>()
);

export const requestPricingById = createAction(
  '[powerbi.pricing] requestPricingById',
  props<{ id: PricingId }>()
);

export const requestPricingByIdSuccess = createAction(
  '[powerbi.pricing] requestPricingByIdSuccess',
  props<{ pricing: Pricing }>()
);

export const requestPricingByIdFailure = createAction(
  '[powerbi.pricing] requestPricingByIdFailure',
  props<{ error: RequestError }>()
);
