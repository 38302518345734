import * as JSZip from 'jszip';
import { jsPDF } from 'jspdf';
import { TimelineLayout } from 'tp-traqplan-core/dist/timeline/timeline-layout';
import { LayoutOptions } from 'tp-traqplan-core/dist/timeline/tl-structs';
import * as timelineRender from 'tp-traqplan-core/dist/timeline/timeline-render';
import * as tlStyle from 'tp-traqplan-core/dist/timeline/tl-style';
import { createSVGElement } from 'tp-traqplan-core/dist/svg-utils';
import * as groupUtils from 'tp-traqplan-core/dist/task-group-utils';

const MAX_SCALE = 1;
const MAX_FIT_ATTEMPTS = 15;
const MAX_FIT_RATIO = 0.95;
const MIN_FIT_RATIO = 0.9;
const MIN_ITER_THRESHOLD = 0.7;
const MAX_ITER_THRESHOLD = 1.05
//const FIT_TOLERANCE = 0.1;

export interface PageFitting {
  layout: TimelineLayout;
  content?: any; //svg element
}

const doc = document;
const downloadBaseName = 'timeline';

//const legendMinColumnWidth = 2;
//const legendRowPadding = 0.1;
//const legendColumnPadding = 0.15;
//const legendItemMaxLines = 3;

export const imageFormats = {
  'PNG': 'image/png',
  'JPEG': 'image/jpeg'
}
export const scalableLayoutProps = [
  'fontSize',
  'textMaxWidth',
  'milestoneSymbolSize',
  'groupTextPadding',
  'groupsWidth',
  'taskPadding',
  'textPadding',
  'legendRowPadding',
  'legendFontSize',
  'legendColumnPadding',
  'legendColumnMinWidth'
];

export const sizes = ['16:9 (Powerpoint new)', '4:3 (Powerpoint legacy)', 'A3', 'A4', 'Letter', 'Legal'];
export const rotatable = ['A3', 'A4', 'Letter', 'Legal']
export const formats = ['JPEG', 'PNG', 'SVG', 'PDF'];
export const orientations = ['landscape', 'portrait'];
export const headerFormats = ['PDF'];
export const sizeRatios = {
  '16:9 (Powerpoint new)': { ratio: 1.7778, shortInches: 7.5 },
  '4:3 (Powerpoint legacy)': { ratio: 1.3333, shortInches: 7.5 },
  'A3': { ratio: 1.4142, shortInches: 11.69 },
  'A4': { ratio: 1.4142, shortInches: 8.27 },
  'Letter': { ratio: 1.2941, shortInches: 8.5 },
  'Legal': { ratio: 1.6471, shortInches: 8.5 }
};

// treat css pixels as 96 == 1 inch (desktop monitors will generally display close to this):
export function getPrintSize(size: number, dpi): number {
  return size * (dpi / 96);
}
// inverse of getPrintSize:
export function getScreenSize(size: number, dpi: number): number {
  return size / (dpi / 96);
}

// returns [ width, height ];
export function getPageSize(size: string, orientation: string, dpi: number): number[] {

  const { ratio, shortInches } = sizeRatios[size];
  const shortPx = shortInches * dpi;
  const longPx = shortInches * ratio * dpi;

  return orientation === 'landscape' ? [longPx, shortPx, shortPx / longPx]
    : [shortPx, longPx, longPx / shortPx];

}

export function scaleSettings(settings: LayoutOptions, scale: number): LayoutOptions {

  const s = <any>{};

  scalableLayoutProps.forEach(prop => {
    s[prop] = settings[prop] * scale;
  });

  const scaled = (s as LayoutOptions);
  const merged = {
    ...settings,
    ...scaled
  };

  //merged.groupsWidth = Math.max(merged.groupsWidth, TimelineLayout.getMinimumGroupsWidth(merged));


  /*scaled.scale = scaleTime()
    .domain([ ...settings.scale.domain() ])
    .range([ scaled.groupsWidth, settings.scale.range()[1] ]);*/

  return merged;

}

export function fitToPage(settings: LayoutOptions, availableHeight: number, maxScale: number = MAX_SCALE): TimelineLayout | null {

  const scaleFactor = 1;
  const results = [];
  const layout = new TimelineLayout();

  const success = fitToPageRecursive(layout, { ...settings }, availableHeight, maxScale, scaleFactor, 0, results);

  return success ? layout : null;

}

export function cleanSVGs(sheets: any[]): void {

  sheets.forEach(sheet => {
    tlStyle.screenOnlyClasses.forEach(className => {
      sheet.querySelectorAll(`.${className}`)
        .forEach(node => node.remove());
    });
  });

}

export function createSVGBlobs(sheets: any[], pageFittings: PageFitting[]): Blob[] {

  return sheets.map((sheet, index) => {

    const { layout } = pageFittings[index];
    const clone = sheet.cloneNode(true);

    clone.setAttribute('height', clone.viewBox.baseVal.height);
    clone.setAttribute('width', clone.viewBox.baseVal.width);
    timelineRender.embedCoreStyles(clone, layout);

    return new Blob(
      [(new XMLSerializer).serializeToString(clone)],
      { type: "image/svg+xml;charset=utf-8" }
    );
  });

}

export function generateImageCanvases(blobs: Blob[]): Promise<any> {

  return Promise.all(
    blobs.map(blob => {
      const url = URL.createObjectURL(blob);
      const cnv = doc.createElement('canvas');
      const ctx = cnv.getContext('2d');
      const img = new Image();

      return new Promise((resolve) => {
        img.onload = () => {
          cnv.width = img.width;
          cnv.height = img.height;

          ctx.drawImage(img, 0, 0);

          resolve(cnv);
        };
        img.src = url;
      });
    })
  );
}

export function generateImageBlobs(canvases: any[], format: string): Promise<any> {

  return Promise.all(
    canvases.map(canvas => {
      return new Promise((resolve) => {
        canvas.toBlob(blob => resolve(blob), imageFormats[format]);
      });
    })
  );

}

export function generateImageURLs(blobs, format): Promise<any> {

  return new Promise((resolve) => {
    generateImageCanvases(blobs)
      .then(canvases => {
        resolve(createImageURLs(canvases, format));
      });
  });

}

export function createImageURLs(canvases: any[], format: string): string[] {

  return canvases.map(canvas => {
    return canvas.toDataURL(imageFormats[format]);
  });

}

export function downloadZip(blobs: Blob[], extension: string): void {

  const zip = new JSZip();

  blobs.forEach((blob, i) => {
    zip.file(`${downloadBaseName}-${i}.${extension}`, blob);// blob, { base64: true })
  });

  zip.generateAsync({ type: 'base64' })
    .then(base64 => {
      downloadURL(
        `data:application/zip;base64,${base64}`,
        'zip'
      );
    });

}

export function downloadPDF(urls: string[], orientation, width, height): void {

  //get final ratio of fitted svg for sizing image to pdf:
  const ratio = height / width;
  const pdf = new jsPDF({
    unit: 'px',
    orientation: orientation,
    format: [width, height],
  });
  const intWidth = pdf.internal.pageSize.getWidth();

  pdf.addImage(urls[0], 'JPEG', 0, 0, intWidth, intWidth * ratio);
  urls.slice(1).forEach((url) => {
    pdf.addPage();
    pdf.addImage(url, 'JPEG', 0, 0, intWidth, intWidth * ratio);
  });

  pdf.save(`${downloadBaseName}.pdf`);

}

export function downloadURL(url: string, extension: string): void {
  const a = document.createElement('a');
  a.href = url;
  a.download = `${downloadBaseName}.${extension}`;
  a.click();
}

export function downloadBlob(blob: Blob, extension: string): void {
  const url = URL.createObjectURL(blob);
  downloadURL(url, extension);
}

export function downloadBlobs(blobs: Blob[], extension: string): void {

  if (blobs.length > 1) {
    downloadZip(blobs, extension);
  } else {
    downloadBlob(blobs[0], extension);
  }

}

export function renderDraftLabels(container: SVGElement, settings: LayoutOptions): void {

  // given current font, this is the ratio of the font size to width for the word 'Draft'
  // saves the expense of doing a text measurement
  const widthFactor = 2.33;
  const heightFactor = 1.18;
  const fontSize = settings.fontSize * 0.8;

  const draftGroupUids = groupUtils.getRootGroups(settings.groupMap).filter(group => {
    // published will be null for all tasks in a draft. Unfortunately the only way to check from here:
    return group.allTasks?.some(t => t.published === null);
  })
  .map(group => group.id);

  const eGroups = container.querySelectorAll('.group-container') as any;

  for (const eGroup of eGroups) {
    if (draftGroupUids.includes(eGroup.dataset?.tlGroupUid)) {

      const eLabel = createSVGElement('g');
      const eTooltip = eLabel.appendChild(createSVGElement('title'));
      const eBackground = eLabel.appendChild(createSVGElement('rect'));
      const eText = eLabel.appendChild(createSVGElement('text')) as any;
      
      eLabel.setAttribute('transform', `translate(${settings.textPadding + settings.groupTextPadding / 2}, ${settings.textPadding + settings.groupTextPadding / 2})`);

      eTooltip.textContent = 'This project is still in draft mode. You can cancel and go to the swimlane to publish the project';

      eBackground.setAttribute('width', `${fontSize * widthFactor + settings.textPadding * 4}`);
      eBackground.setAttribute('height', `${fontSize * heightFactor + settings.textPadding * 2}`);
      eBackground.setAttribute('fill', 'red');
      eBackground.setAttribute('rx', `${fontSize / 3}`);

      eText.textContent = 'Draft';
      eText.style.setProperty('font-size', `${fontSize}`);
      eText.setAttribute('font-weight', '600');
      eText.setAttribute('fill', 'white');
      eText.setAttribute('transform', `translate(${settings.textPadding * 2}, ${settings.textPadding})`);
      eText.setAttribute('dy', '1em');

      eGroup.appendChild(eLabel);

    }
  }

}


function fitToPageRecursive(layout: TimelineLayout, settings: LayoutOptions, availableHeight: number, maxScale: number, scaleFactor: number, attempts, results: number[][]): boolean {
  const scaledSettings = scaleSettings(settings, scaleFactor);
  // Total group width should not excedd 60% of viewport width
  const maxGroupWidth = scaledSettings.viewportWidth * 0.6;

  if (scaledSettings.groupsWidth > maxGroupWidth) {
    const scaleFactor = maxGroupWidth / (settings?.groupsWidth || 1);
    layout.layout(scaleSettings(settings, scaleFactor));
    return true;
  }

  layout.layout(scaleSettings(settings, scaleFactor));

  // get ratio of total height to available height:
  const fitRatio = (layout.fittingMeta.totalHeight + layout.fittingMeta.axisHeight + layout.legend.totalHeight) / availableHeight;

  if (attempts > MAX_FIT_ATTEMPTS + 1) {
    return false;
  }

  if (attempts === MAX_FIT_ATTEMPTS) {
    if (!results.length) {
      return false;
    }
    const bestFit = results.sort(([a], [b]) => a - b).pop();
    return fitToPageRecursive(layout, settings, availableHeight, maxScale, bestFit[1], ++attempts, results);
  }

  const propScale = 1 / fitRatio * scaleFactor * 0.97;

  if (fitRatio > MAX_FIT_RATIO) {
    const attemptScale = Math.min(maxScale, (fitRatio < MAX_ITER_THRESHOLD) ? scaleFactor * 0.95 : propScale);
    return fitToPageRecursive(layout, settings, availableHeight, maxScale, attemptScale, ++attempts, results);
  }
  if (fitRatio < MIN_FIT_RATIO) {
    if (scaleFactor === maxScale) {
      return true;
    }
    results.push([fitRatio, scaleFactor]);
    const attemptScale = Math.min(maxScale, (fitRatio > MIN_ITER_THRESHOLD) ? scaleFactor * 1.05 : propScale);
    return fitToPageRecursive(layout, settings, availableHeight, maxScale, attemptScale, ++attempts, results);
  }
  return true;

}
