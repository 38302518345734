import { Component, Input, OnInit } from '@angular/core';
import { RevisionConflict } from 'tp-traqplan-core/dist/data-structs';

@Component({
  selector: 'app-publish-conflict-header',
  templateUrl: './publish-conflict-header.component.html',
  styleUrls: ['./publish-conflict-header.component.scss']
})
export class PublishConflictHeaderComponent implements OnInit {

  @Input() conflict: RevisionConflict;

  authorName?: string;
  projectName?: string;
  revisionTitle?: string;

  ngOnInit(): void {
    const { authors, latest, project } = this.conflict;
    const hasVersion = !!latest.title;
    const author = authors[latest.author];
    this.revisionTitle = hasVersion ? latest.title : 'Other version';
    this.projectName = project?.name;
    this.authorName = author?.name || author?.alias || author?.email;
  }
}
