<div class="menu__header">

  <div class="header__title">
    Notifications
  </div>

  <!--<div class="header__toggle">
    <label for="unread">Unread</label>
    <input type="checkbox" id="unread" class="tp-form__input" [(ngModel)]="unreadOnly" />
  </div>-->

</div>

<div class="menu__list">

  <div class="list__empty" *ngIf="!notifications?.length">
    You have no notifications to display
  </div>

  <ng-container *ngFor="let message of notifications">

    <div 
      class="list__item"
      (click)="onClickMessage(message)"
    >

      <div class="item__read">
        <span class="read__icon" [class.unread]="!message.readDate"></span>
      </div>
      <div class="item__body">

        <div class="item__body__title">
          <div class="title__text" [ngSwitch]="message.messageType">

            <ng-container *ngSwitchCase="'requestAccess'">
              Access request
            </ng-container>

            <ng-container *ngSwitchCase="'requestLicense'">
              License upgrade request
            </ng-container>

            <ng-container *ngSwitchDefault>
              New notification
            </ng-container>

          </div>
          <div class="title__date">
            {{message.created | date: 'MMM d, y, h:mm a'}}
          </div>
        </div>

        <div class="item__body__message" [ngSwitch]="message.messageType">

          <ng-container *ngSwitchCase="'requestAccess'">
            {{message.meta?.authorAlias || 'A user'}} has requested access to a {{message.meta?.type || 'project'}}
          </ng-container>

          <ng-container *ngSwitchCase="'requestLicense'">
            {{message.meta?.authorAlias || 'A user'}} has requested a license upgrade
          </ng-container>

          <ng-container *ngSwitchDefault>
            {{message.messageContent}}
          </ng-container>

        </div>
      </div>

    </div>


  </ng-container>

</div>