import { Component, ElementRef, HostListener, Input, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { PoapTask } from 'tp-traqplan-core/dist/data-structs';
import { TimelineEventChannel } from 'tp-traqplan-core/dist/timeline/timeline-event-channel';
import { State } from '../../../state';
import { TimelineCoreComponent } from '../timeline-core/timeline-core.component';
import { TimelineLayout } from 'tp-traqplan-core/dist/timeline/timeline-layout';
import { TimelineInteractions } from '../tl-interactions';

@Component({
  selector: 'app-menu-indicator',
  templateUrl: './menu-indicator.component.html',
  styleUrls: ['./menu-indicator.component.scss']
})
export class MenuIndicatorComponent implements OnInit, OnDestroy {

  @Input() layout: TimelineLayout;
  @Input() interactions: TimelineInteractions;
  @Input() eventChannel: TimelineEventChannel;

  @HostListener('mouseover')
  onMouseOver() {
    this.isMouseOver = true;
  }
  @HostListener('mouseout')
  onMouseOut() {
    this.isMouseOver = false;
  }

  isDraft: boolean = false;
  styleLeft: string;
  styleTop: string;
  projectContent: any= null;
  taskEvent: any;
  target:any;
  currentTask:any;
  hasRan:boolean = false;

  private hostElement: HTMLElement;
  private isMouseOver: boolean = false;
  private task?: PoapTask;
  private core?: TimelineCoreComponent;
  private rootGroupNode?: HTMLElement | null;
  public taskSelected: boolean = false;
  private isSelected:any;

  private destroy$ = new Subject<void>();

  constructor(
    element: ElementRef, private store: Store<State>,
  ) {
    this.hostElement = element.nativeElement;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {

    /*this.interactions.events$.pipe(
      takeUntil(this.destroy$),
      tap(({ event, data }) => {
        if (event === 'displayMenuIndicator') {
          const target = 
        }
      })
    ).subscribe();*/

    this.isSelected = document.querySelector('g.tl-task');
    this.eventChannel.subscribe('showMenuButton', (currEvent)=>{
      const isSelected =  document.querySelector('g.tl-task[data-selected]');
      const els:any =  document.querySelectorAll('g.tl-task[data-selected]');
      if(isSelected){
        const taskSelected = isSelected?.getAttribute('data-selected');
        this.currentTask = isSelected?.getAttribute('data-tl-task-uid');

        if(els.length >1){
          const checkEl = document.querySelector(`g.tl-task[data-tl-task-uid="${currEvent[1].fittingData.ID}"]`)

          if(checkEl){
            if(checkEl.getAttribute('data-selected') === 'true'){
              this.taskSelected = false;
            }else{
              this.taskSelected = false;
              // this.taskSelected = true;
            }
          }
        }else{
          if(taskSelected ==='true'){
            this.taskSelected = false;
            if(this.target){
              if(this.target.fittingData.ID !== currEvent[1].fittingData.ID ){
                // this.taskSelected = true;
                this.taskSelected = false;
              }
            }
          }else{
            // this.taskSelected = true;
            this.taskSelected = false;
          }
        }

      }else{
          // this.taskSelected = !this.taskSelected;
          this.taskSelected = false;
      }
      this.target = currEvent[1];
      this.taskEvent = currEvent[0];
    });

  }
  onClick(): void {
    if(this.taskEvent){
      this.eventChannel.publish('dragMask__contextMenu__toggle',this.taskEvent);
    }
  }
}
