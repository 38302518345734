import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

import * as PowerBi from '../../powerbi-data-structs';
import { defaultPipe } from '../common';
import { StaffApiService } from '../staff-api.service';

import PricingPlan = PowerBi.PricingPlan;
import PricingPlanId = PowerBi.PricingPlanId;

@Injectable({
  providedIn: 'root'
})
export class PricingPlanApiService {

  constructor(
    private http: HttpClient,
    private staffApi: StaffApiService
  ) {}

  createPricingPlan(options) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.post<{ pricingPlan: PricingPlan }>(
          '/api/staff/powerbi/pricing-plans',
          options,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  deletePricingPlanById(id: PricingPlanId) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.delete<any>(
          `/api/staff/powerbi/pricing-plans/${id}`,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  requestPricingPlans() {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.get<{ pricingPlans: PricingPlan[] }>(
          '/api/staff/powerbi/pricing-plans',
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  requestPricingPlanById(id: PricingPlanId) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.get<{ pricingPlan: PricingPlan }>(
          `/api/staff/powerbi/pricing-plans/${id}`,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  updatePricingPlanById(id: PricingPlanId, options) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.patch<{ pricingPlan: PricingPlan }>(
          `/api/staff/powerbi/pricing-plans/${id}`,
          options,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

}
