import { createReducer, on } from '@ngrx/store';
import { HierarchyNode, HierarchyNodeId, RevisionInfo } from 'tp-traqplan-core/dist/data-structs';
import { sortRevision } from '../../util/common';
import { dsReceived, dsPending } from '../helpers';
import * as poapActions from '../poap/actions';
import * as projectsActions from './actions';
import { getTreeGroupKey, getTreeGroupPath } from './helpers';
import { State as ProjectState, initialState } from './state';
import { DataStore } from '../structs';

export const reducer = createReducer(
  initialState,

  on(projectsActions.reset, (state, props) => {
    return { ...initialState, ...props };
  }),

  on(projectsActions.requestProjectTree, (state, props) => {
    return {
      ...state,
      hierarchy: <DataStore<HierarchyNode[]>>{
        ...state.hierarchy,
        ...dsPending<HierarchyNode[]>(state.hierarchy?.value || undefined)
      },
      created: props?.selectProject || state.created
    };
  }),

  on(projectsActions.requestProjectTreeSuccess, (state, props) => {

    let next = {
      ...state,
      hierarchy: {
        ...state.hierarchy,
        ...dsReceived<HierarchyNode[]>(props.nodes)
      }
    };

    next = validateSelectedRevisions(next);

    if (state.created) {
      const created = props.nodes.find(n => n.id === state.created);

      if (created) {
        let { revisions } = created;

        if (props.availableRevisions) {
          revisions = props.availableRevisions;
        } else if (props.viewId) {
          revisions = revisions.filter(({ view }) => view === null || view === props.viewId)
        }

        revisions.sort(sortRevision);
        const revision = revisions[revisions.length - 1];
        next = selectProjectTreeRevisions(next, true, [revision]);
        next.created = null;
      }
    }

    // if first time updating then update selection so that latest available versions of projects are selected:
    if (!state.rehydrated) {
      const latest: { [key: string]: boolean } = {};

      for (const [revId, selected] of Object.entries(next.tree.selected)) {
        if (selected) {
          const project = next.hierarchy.value.find((n) => n.revisions?.some((r) => r.id === revId));

          // project could no longer be available:
          if (!project) {
            continue;
          }

          const viewRevisions = project.revisions.filter(rev => !rev.view || rev.view === props.viewId);

          const draft = viewRevisions.find(r => !r.published);

          if (draft) {
            latest[draft.id] = true;
          } else {
            // if no draft then get most recently published revision:
            viewRevisions.sort((a,b) => b.published.getTime() - a.published.getTime());
            latest[viewRevisions[0].id] = true;
          }
        }
      }
      next.rehydrated = true;
      next.tree.selected = latest;
    }

    return next;
  }),

  on(projectsActions.selectProjectTreeRevisions, (state, props) => {
    return selectProjectTreeRevisions(state, props.select, props.revisions);
  }),

  on(projectsActions.isReady, (state, props) => {
    return {
      ...state,
      isReady: props.isReady
    };
  }),

  on(projectsActions.setProjectTreeGroups, (state, props) => {
    return {
      ...state,
      tree: {
        ...state.tree,
        groups: props.groups
      }
    };
  }),

  on(projectsActions.expandProjectRevisions, (state, { expand, project }) => {

    return {
      ...state,
      tree: {
        ...state.tree,
        revisionsExpanded: {
          ...state.tree.revisionsExpanded,
          [project.id]: expand
        }
      }
    };

  }),

  on(projectsActions.expandProjectTreeNode, (state, { expand, grouping, project }) => {

    if (project) {
      return {
        ...state,
        tree: {
          ...state.tree,
          expanded: {
            ...state.tree.expanded,
            [project.id]: expand
          }
        }
      }
    }

    if (grouping) {
      return {
        ...state,
        tree: {
          ...state.tree,
          expandedGroups: {
            ...state.tree.expandedGroups,
            [getTreeGroupKey(getTreeGroupPath(grouping))]: expand
          }
        }
      }
    }

  }),

  on(projectsActions.createNodeSuccess, (state, { path }) => {
    return {
      ...state,
      created: path[path.length - 1]
    };
  }),

  on(projectsActions.deleteNode, (state, { id }) => {

    const project = state.hierarchy.value.find(v => v.id === id);

    if (!project) {
      return state;
    }

    const deselect: RevisionInfo[] = [];

    for (const revision of project.revisions) {
      if (state.tree.selected[revision.id]) {
        deselect.push(revision);
      }
    }

    if (deselect.length) {
      return selectProjectTreeRevisions(state, false, deselect);
    }

    return state;

  }),

  on(projectsActions.setTreeFilter, (state, props) => {

    return {
      ...state,
      tree: {
        ...state.tree,
        filter: {
          ...state.tree.filter,
          ...props
        }
      }
    };

  }),

  on(projectsActions.requestFavourites, (state) => {
    return {
      ...state,
      favourites: dsPending<HierarchyNodeId[]>()
    };
  }),

  on(projectsActions.requestFavouritesSuccess, (state, { favourites }) => {
    return {
      ...state,
      favourites: dsReceived<HierarchyNodeId[]>(favourites)
    };
  }),

  on(poapActions.discardDraft, (state, { revisionId }) => {

    const next = { ...state };

    next.tree.selected = Object.keys(next.tree.selected)
      .filter(k => k !== revisionId)
      .reduce((a, c) => ({ ...a, [c]: next.tree.selected[c] }), {});

    return next;

  }),

  on(projectsActions.requestRevisionViewsSuccess, (state, props) => {
    return {
      ...state,
      revisionViews: props.revisionViews
    };
  })

);

function selectProjectTreeRevisions(state: ProjectState, select: boolean, revisions: RevisionInfo[]): ProjectState {

  if (!state.hierarchy.value?.length) {
    return state;
  }

  // map projects by id to identify dupes:
  const projectMap = new Map(state.hierarchy.value.map(v => [v.id, v]));
  const selected = new Map(Object.entries(state.tree.selected));
  let lastSelectedRevision = state.lastSelectedRevision;

  for (const rev of revisions) {
    if (rev?.project) {
      const project = projectMap.get(rev?.project);
      // this really shouldn't ever throw:
      if (!project?.revisions?.length) {
        throw Error('Missing project or project has no revisions');
      }
      // remove selected revision siblings:
      if (select === true) {
        for (const sibling of project.revisions) {
          if (sibling.id !== rev.id && selected.has(sibling.id)) {
            selected.delete(sibling.id);
          }
        }
        selected.set(rev.id, select);
        lastSelectedRevision = rev.id;
      } else {
        selected.delete(rev.id);
      }
    }
  }

  if (!select && revisions.some(({ id }) => id === state.lastSelectedRevision)) {
    lastSelectedRevision = selected.keys().next().value;
  }

  return {
    ...state,
    tree: {
      ...state.tree,
      selected: [...selected.entries()].reduce((a, [k, v]) => ({ ...a, [k]: v }), {})
    },
    lastSelectedRevision
  };

}

/** ensure all selected revisions are still available after project tree update: */
function validateSelectedRevisions(state: ProjectState) {

  if (!state.hierarchy.value?.length) {
    return state;
  }

  const selected: any = {};

  for (const [k,v] of Object.entries(state.tree.selected)) {
    if (v === true) {
      selected[k] = false;
    }
  }

  for (const hierarchyNode of state.hierarchy.value) {
    if (hierarchyNode.revisions) {
      for (const revision of hierarchyNode.revisions) {
        if (selected[revision.id] === false) {
          selected[revision.id] = true;
        }
      }
    }
  }

  return {
    ...state,
    tree: {
      ...state.tree,
      selected
    }
  }


}
