<div id="upper-toolbar">
  <div
    data-tooltip="Automatically expand scroll extents"
    data-inverted
    data-position="bottom left"
    style="margin-left:0.5em"
  >
    <p-toggleButton
      styleClass="upper-toolbar-button"
      [ngClass]="{ enabled: isAutoMode }"
      onLabel="Auto"
      offLabel="Auto"
      [style]="{ width: '7em' }"
      (onChange)="onHAxisModeChange($event)"
      [(ngModel)]="togglehAxisBoundaryMode"
      [disabled]="isAutoMode"
    ></p-toggleButton>
  </div>
  <!--
  <div
    data-tooltip="Toggle print mode"
    data-inverted
    data-position="bottom left"
    style="margin-left:0.5em"
  >
    <p-toggleButton
      styleClass="upper-toolbar-button"
      onLabel=""
      offLabel=""
      onIcon="pi pi-print"
      offIcon="pi pi-print"
      (onChange)="onViewModeChange($event)"
      [(ngModel)]="toggleViewMode"
    ></p-toggleButton>
  </div>-->
  <app-horizontal-scroller
    #horizontalScroller
    [boundaryMode]="hAxisBoundaryMode"
    [boundaryStart]="hAxisBoundaryStart"
    [boundaryFinish]="hAxisBoundaryFinish"
    [windowStart]="hAxisViewStart"
    [windowFinish]="hAxisViewFinish"
    (windowChange)="onHAxisWindowChange($event)"
    (boundaryStartChange)="onHAxisBoundaryChange('start',$event)"
    (boundaryFinishChange)="onHAxisBoundaryChange('finish',$event)"
    [interactions]="interactions"
  ></app-horizontal-scroller>
</div>
<div
  id="core-container"
>
  <app-timeline-core
    #timelineCore
    [active]="!exportActive"
    (viewportChange)="onCoreViewportChange($event)"
    [interactions]="interactions"
    [layout]="layout"
  ></app-timeline-core>
  <app-vertical-scroller
    [wheelTarget]="timelineCore.scrollArea"
    [viewportHeight]="viewportHeight"
    [scrollHeight]="scrollHeight"
    [interactions]="interactions"
  ></app-vertical-scroller>
</div>
<app-timeline-export
  #timelineExport
  [layout]="layout"
  [settings]="exportSettings"
  (closeWindow)="onExportClose()"
  (settingsChange)="onExportSettingsChange($event)"
>
</app-timeline-export>

<app-group-menu
  #groupMenu
  [interactions]="interactions"
  [layout]="layout"
  [editCheck]="editCheck"
></app-group-menu>

<app-publish-indicator [layout]="layout" [eventChannel]="eventChannel"></app-publish-indicator>

<app-menu-indicator [layout]="layout" [interactions]="interactions" [eventChannel]="eventChannel"></app-menu-indicator>

<app-add-task-indicator [layout]="layout"></app-add-task-indicator>

<app-new-task-modal
  [interactions]="interactions"
  [layout]="layout"
></app-new-task-modal>

<app-floating-tooltip
  [interactions]="interactions"
></app-floating-tooltip>

<app-floating-input
  [interactions]="interactions"
></app-floating-input>