import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-display-counter',
  templateUrl: './display-counter.component.html',
  styleUrls: ['./display-counter.component.css']
})
export class DisplayCounterComponent implements OnChanges {

  @Input() value: number = 0;
  @Input() precision: number = 0;
  @Input() prefix: string = '';
  @Input() suffix: string = '';

  public displayValue: string = '';

  ngOnChanges(): void {
    const p = Math.pow(10, this.precision);
    const v = Math.round(this.value * p) / p;
    this.displayValue = ''.concat(this.prefix,v.toString(),this.suffix);
  }

}
