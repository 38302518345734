import { ActionReducerMap } from '@ngrx/store';
import * as accountState from './account/state';
import * as appState from './app/state';
import * as authState from './auth/state';
import * as modalState from './modal/state';
import * as pricingState from './pricing/state';
import * as pricingPlansState from './pricingPlans/state';
import * as subscriptionsState from './subscriptions/state';
import * as userTncAcceptanceState from './userTncAcceptance/state';
import * as projectsState from './projects/state';
import * as poapState from './poap/state';
import * as tpsState from './tps/state';
import * as powerbiCustomerState from './power-bi/customer/state';
import * as powerbiPricingState from './power-bi/pricing/state';
import * as powerbiPricingPlansState from './power-bi/pricingPlans/state';
import * as powerbiSubscriptionsState from './power-bi/subscriptions/state';
import * as powerbiUserTncAcceptanceState from './power-bi/userTncAcceptance/state';
import { reducer as accountReducer } from './account/reducers';
import { reducer as appReducer } from './app/reducers';
import { reducer as authReducer } from './auth/reducers';
import { reducer as modalReducer } from './modal/reducers';
import { reducer as pricingReducer } from './pricing/reducers';
import { reducer as pricingPlansReducer } from './pricingPlans/reducers';
import { reducer as subscriptionsReducer } from './subscriptions/reducers';
import { reducer as userTncAcceptanceReducer } from './userTncAcceptance/reducers';
import { reducer as projectsReducer } from './projects/reducers';
import { reducer as poapReducer } from './poap/reducers';
import { reducer as tpsReducer } from './tps/reducers';
import { reducer as powerbiCustomerReducer } from './power-bi/customer/reducers';
import { reducer as powerbiPricingReducer } from './power-bi/pricing/reducers';
import { reducer as powerbiPricingPlansReducer } from './power-bi/pricingPlans/reducers';
import { reducer as powerbiSubscriptionsReducer } from './power-bi/subscriptions/reducers';
import { reducer as powerbiUserTncAcceptanceReducer } from './power-bi/userTncAcceptance/reducers';

export interface State {
  [accountState.key]: accountState.State;
  [appState.key]: appState.State;
  [authState.key]: authState.State;
  [modalState.key]: modalState.State;
  [pricingState.key]: pricingState.State;
  [pricingPlansState.key]: pricingPlansState.State;
  [subscriptionsState.key]: subscriptionsState.State;
  [userTncAcceptanceState.key]: userTncAcceptanceState.State;
  [projectsState.key]: projectsState.State;
  [poapState.key]: poapState.State;
  [tpsState.key]: tpsState.State;
  [powerbiCustomerState.key]: powerbiCustomerState.State;
  [powerbiPricingState.key]: powerbiPricingState.State;
  [powerbiPricingPlansState.key]: powerbiPricingPlansState.State;
  [powerbiSubscriptionsState.key]: powerbiSubscriptionsState.State;
  [powerbiUserTncAcceptanceState.key]: powerbiUserTncAcceptanceState.State;
}

export const reducers: ActionReducerMap<State> = {
  [accountState.key]: accountReducer,
  [appState.key]: appReducer,
  [authState.key]: authReducer,
  [modalState.key]: modalReducer,
  [pricingState.key]: pricingReducer,
  [pricingPlansState.key]: pricingPlansReducer,
  [subscriptionsState.key]: subscriptionsReducer,
  [userTncAcceptanceState.key]: userTncAcceptanceReducer,
  [projectsState.key]: projectsReducer,
  [poapState.key]: poapReducer,
  [tpsState.key]: tpsReducer,
  [powerbiCustomerState.key]: powerbiCustomerReducer,
  [powerbiPricingState.key]: powerbiPricingReducer,
  [powerbiPricingPlansState.key]: powerbiPricingPlansReducer,
  [powerbiSubscriptionsState.key]: powerbiSubscriptionsReducer,
  [powerbiUserTncAcceptanceState.key]: powerbiUserTncAcceptanceReducer
};
