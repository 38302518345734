import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import * as access from 'tp-common/access';
import { TreeProjectNode, TreeRevisionNode, TreeGrouping, HierarchyNode, HierarchyNodeId } from 'tp-traqplan-core/dist/data-structs';
import { DropMenuAction, ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { HierarchyApiService } from '../../api/hierarchy-api.service';
import { State } from '../../state';
import { ModalService } from '../../modals/modal.service';
import * as appActions from '../../state/app/actions';
import * as modalActions from '../../state/modal/actions';
import * as poapActions from '../../state/poap/actions';
import * as projectsActions from '../../state/projects/actions';
import { StateModal } from '../../state/structs';
import { sortRevision } from '../../util/common';

@Injectable({
  providedIn: 'root'
})
export class ProjectTreeActionsService {

  constructor(
    private hierarchyApi: HierarchyApiService, private store: Store<State>, private modalService: ModalService
  ) { }

  actions: { [key: string]: DropMenuAction } = {
    requestAccess: {
      action: 'requestAccess',
      label: 'Request access',
      icon: 'comment'
    },
    renameProject: {
      action: 'renameProject',
      label: 'Rename project',
      icon: 'font'
    },
    publishDraft: {
      action: 'publishDraft',
      label: 'Publish Draft',
      icon: 'save'
    },
    discardDraft: {
      action: 'discardDraft',
      label: 'Discard Draft',
      icon: 'trash',
      colour: 'var(--color-negative)'
    },
    createDraft: {
      action: 'createDraft',
      label: 'Edit (Creates draft)',
      icon: 'edit'
    },
    renameRevision: {
      action: 'renameRevision',
      label: 'Rename revision',
      icon: 'font'
    },
    share: {
      action: 'share',
      label: 'Share',
      icon: 'share-alt'
    },
    useAsBaseline: {
      action: 'useAsBaseline',
      label: 'Use as baseline',
      icon: 'exchange-alt'
    },
    archive: {
      action: 'archive',
      label: 'Archive Project',
      icon: 'trash'
    },
    restore: {
      action: 'restore',
      label: 'Restore',
      icon: 'reply'
    },
    delete: {
      action: 'delete',
      label: 'Delete',
      icon: 'trash',
      colour: 'var(--color-negative)'
    },
    star: {
      action: 'star',
      label: 'Add to favourites',
      icon: 'star',
      colour: 'var(--color-positive)'
    },
    unstar: {
      action: 'unstar',
      label: 'Remove from favourites',
      icon: 'star',
    },
    move: {
      action: 'move',
      label: 'Move',
      icon: 'file-export'
    },
    copy: {
      action: 'copy',
      label: 'Copy',
      icon: 'copy'
    }
  };

  notFromRevision:boolean = false;

  groupActions: { [key: string]: DropMenuAction } = {
    // star: {
    //   action: 'star',
    //   label: 'Add to favourites',
    //   icon: 'star',
    //   colour: 'var(--color-positive)'
    // },
    // unstar: {
    //   action: 'unstar',
    //   label: 'Remove from favourites',
    //   icon: 'star',
    // },
    requestAccess: {
      action: 'requestAccess',
      label: 'Request access',
      icon: 'comment'
    },
    share: {
      action: 'share',
      label: 'Share',
      icon: 'share-alt'
    },
    renaming: {
      action: 'rename',
      label: 'Rename',
      icon: 'font'
    },
    move: {
      action: 'move',
      label: 'Move',
      icon: 'file-export'
    },
    copy: {
      action: 'copy',
      label: 'Copy',
      icon: 'copy'
    },
    archive: {
      action: 'archive',
      label: 'Archive Programme',
      icon: 'archive'
    }
  };

  handlers: { [key: string]: (project: TreeProjectNode, revision?: TreeRevisionNode, view?: HierarchyNodeId) => void } = {
    requestAccess: (project) => {
      this.store.dispatch(
        modalActions.generateModal({
          modal: StateModal.requestAccess,
          inputs: {
            hierarchyNode: project
          }
        })
      );
    },
    renameProject: (project) => {
      this.store.dispatch(
        modalActions.generateModal({
          modal: StateModal.renameProject,
          inputs: {
            project: project
          }
        })
      );
    },
    publishDraft: (project, revision) => {
      this.store.dispatch(
        modalActions.generateModal({
          modal: StateModal.labelRevision,
          inputs: {
            revision: project.revisions?.find(rev => !rev.published) || revision
          }
        })
      );
    },
    discardDraft: (project, revision, view) => {
      const result = project.revisions?.reduce((maxEntry, entry) => (
        entry.projectVersion + entry.viewVersion > maxEntry.projectVersion + maxEntry.viewVersion
          ? entry
          : maxEntry
      ), project.revisions[0]);
      const discardRevision = project.revisions?.find(rev => !rev.published) || revision

      this.modalService.info({
        header: 'Discard Draft?',
        message: 'Any changes in the draft will be deleted. This action cannot be undone',
        actions: [
          {
            label: 'Proceed',
            type: 'negative',
            confirm: true,
            enterKey: true
          },
          {
            label: 'Cancel',
            type: 'neutral',
            cancel: true,
            escKey: true
          }
        ]
      })
        .then(action => {
          if (action.confirm) {
            this.store.dispatch(appActions.showLoading());
            this.store.dispatch(
              poapActions.discardDraft({ projectId: project?.id, revisionId: discardRevision?.id, viewId: view })
            );

            if(revision && result)
            this.store.dispatch(projectsActions.selectProjectTreeRevisions({ select:true, revisions: [result] }));

          }
        });
    },
    createDraft: (project, revision, view) => {
      this.store.pipe(take(1)).subscribe((state) => {
        this.hierarchyApi.getTree({ root: state.account.root.selected.id }).subscribe((nodes: HierarchyNode[]) => {
          this.store.dispatch(projectsActions.requestProjectTreeSuccess({ nodes, viewId: state.poap.viewSelected?.id }));
          const latestProject = nodes.find(({ id }) => id === project.id);
          // find the first revision which is published:
          const fromRevision = revision || project.revisions?.find(r => !!r.published);
          const revisions = (
            latestProject
            ? (Array.isArray(latestProject?.revisions) ? latestProject.revisions : [])
            : (Array.isArray(project?.revisions) ? project.revisions : [])
          ).filter(({ view }) => view === null || view === state.poap.viewSelected?.id).sort(sortRevision);
          const lastRevision = revisions[revisions.length - 1];
          const isEarlierRevision = (lastRevision?.created?.getTime() || 0) - (fromRevision?.created?.getTime() || 0) > 0;
          const createDraftFunc = (confirmParams?) => {
            this.store.dispatch(appActions.setDraftProject({ draftProject: project }));
            this.store.dispatch(appActions.isDraftCreating({ isDraftCreating: true }));
            this.store.dispatch(appActions.isDraftCreated({ isDraftCreated: false }));
            this.store.dispatch(appActions.showLoading());
            this.store.dispatch(
              poapActions.createDraft({
                from: confirmParams?.latestRevision?.id || fromRevision?.id,
                project: project.id,
                view
              })
            );
          };

          if (isEarlierRevision) {
            setTimeout(() => {
              this.modalService
                .info({
                  header: 'Warning, editing older revision',
                  message: `You are about to edit an older revision (v${fromRevision?.projectVersion}.${fromRevision?.viewVersion}) of this project "${project.name}". Are you sure you want to proceed?`,
                  actions: [
                    {
                      label: 'Cancel',
                      type: 'neutral',
                      cancel: true,
                      escKey: true
                    },
                    {
                      label: 'Proceed with latest revision',
                      type: 'positive',
                      confirm: true,
                      confirmParams: { latestRevision: lastRevision }
                    },
                    {
                      label: `Proceed with older revision (v${fromRevision?.projectVersion}.${fromRevision?.viewVersion})`,
                      type: 'positive',
                      confirm: true
                    }
                  ]
                }).then((action: ModalWindowAction) => {
                  if (action.confirm) {
                    createDraftFunc(action.confirmParams);
                  }
                });
            });
          } else {
            createDraftFunc();
          }
        });
      });
    },
    renameRevision: (project, revision) => {
      this.store.dispatch(
        modalActions.generateModal({
          modal: StateModal.labelRevision,
          inputs: {
            revision: revision
          }
        })
      );
    },
    share: (project) => {
      this.store.dispatch(
        modalActions.generateModal({
          modal: StateModal.shareAccess,
          inputs: {
            nodeId: project.id
          }
        })
      );
    },
    archive: (project) => {

      const canRestore = access.permissionIncludes(project.permission, access.actionType.restoreArchivedBranch);
      const canDelete = access.permissionIncludes(project.permission, access.actionType.deleteArchivedBranch);

      let message;

      if (canRestore && !canDelete) {
        message = 'You can restore this project from the Archive panel. Only an Administrator may permanently delete projects';
      }

      if (canRestore && canDelete) {
        message = 'You can restore or permanently delete this project in the archive panel';
      }

      if (!canRestore && canDelete) {
        message = 'You can permanently delete this project in the archive panel';
      }

      if (!canRestore && !canDelete) {
        message = 'This project can be restored or deleted by an Administrator';
      }

      this.modalService.info({
        header: 'Archive Project',
        message,
        actions: [
          {
            label: 'Cancel',
            type: 'neutral',
            cancel: true,
            escKey: true
          },
          {
            label: 'Archive',
            type: 'negative',
            confirm: true
          }
        ]
      }).then(action => {
        if (action.confirm) {
          this.store.dispatch(
            projectsActions.archiveNode({
              id: project.id
            })
          );
        }
      });
    },
    restore: (project) => {

      this.modalService.info({
        header: 'Restore archived project',
        message: `Project '${project.name}' will be restored to portfolio '${project.parentName}'`,
        actions: [
          {
            label: 'Cancel',
            type: 'neutral',
            cancel: true,
            escKey: true
          },
          {
            label: 'Restore',
            type: 'positive',
            confirm: true
          }
        ]
      }).then(action => {
        if (action.confirm) {
          this.store.dispatch(
            projectsActions.restoreNode({
              id: project.id
            })
          );
        }
      });
    },
    delete: (project) => {

      this.modalService.info({
        header: 'Delete project',
        message: 'This action will permenently delete the project. Are you sure you wish you continue?',
        actions: [
          {
            label: 'Cancel',
            type: 'neutral',
            cancel: true,
            escKey: true
          },
          {
            label: 'Delete',
            type: 'negative',
            confirm: true
          }
        ]
      }).then(action => {
        if (action.confirm) {
          this.store.dispatch(
            projectsActions.deleteNode({
              id: project.id
            })
          );
        }
      });

    },
    star: (project: TreeProjectNode) => {
      this.store.dispatch(projectsActions.addToFavourites({ nodeId: project.id }));
    },
    unstar: (project: TreeProjectNode) => {
      this.store.dispatch(projectsActions.removeFromFavourites({ nodeId: project.id }));
    },
    move: (project: TreeProjectNode) => {
      this.store.dispatch(
        modalActions.generateModal({
          modal: StateModal.moveProject,
          inputs: {
            project
          }
        })
      );
    },
    copy: (project: TreeProjectNode) => {
      this.store.dispatch(
        modalActions.generateModal({
          modal: StateModal.copyProject,
          inputs: {
            project
          }
        })
      );
    },
    useAsBaseline: (project: TreeProjectNode, revision?: TreeRevisionNode) => {
      if (revision) {
        this.store.dispatch(
          projectsActions.updateNode({
            id: project.id,
            update: {
              baselineRevision: revision.id
            }
          })
        );
      }
    }
  };

  groupHandlers: { [key: string]: (grouping: TreeGrouping, programme: HierarchyNode) => void } = {
    requestAccess: (grouping, programme) => {
      this.store.dispatch(
        modalActions.generateModal({
          modal: StateModal.requestAccess,
          inputs: {
            hierarchyNode: programme
          }
        })
      );
    },
    rename: (grouping: TreeGrouping, programme: HierarchyNode) => {
      this.store.dispatch(
        modalActions.generateModal({
          modal: StateModal.renameProgramme,
          inputs: {
            programme: programme
          }
        })
      );
    },
    move: (grouping: TreeGrouping, programme: HierarchyNode) => {
      this.store.dispatch(
        modalActions.generateModal({
          modal: StateModal.moveProgramme,
          inputs: {
            programme
          }
        })
      );
    },
    copy: (grouping: TreeGrouping, programme: HierarchyNode) => {
      this.store.dispatch(
        modalActions.generateModal({
          modal: StateModal.copyProgramme,
          inputs: {
            programme
          }
        })
      );
    },
    share: (grouping: TreeGrouping, programme: HierarchyNode) => {
      this.store.dispatch(
        modalActions.generateModal({
          modal: StateModal.shareAccess,
          inputs: {
            nodeId: programme.id
          }
        })
      );
    },
    archive: (grouping: TreeGrouping, programme: HierarchyNode) => {
      const canRestore = access.permissionIncludes(programme.access, access.actionType.restoreArchivedBranch);
      const canDelete = access.permissionIncludes(programme.access, access.actionType.deleteArchivedBranch);

      let message = `This action will move all projects in the programme '${programme.name}' to the archive, which will remove this programme from the project pane. Please confirm you wish to continue?\n`;

      if (canRestore && !canDelete) {
        message += 'You can restore this programme from the Archive panel. Only an Administrator may permanently delete projects';
      }
      if (canRestore && canDelete) {
        message += 'You can restore or permanently delete this programme in the archive panel';
      }
      if (!canRestore && !canDelete) {
        message += 'This programme can be restored or deleted by an Administrator';
      }

      this.modalService.info({
        header: `Archive Programme - ${programme.name}`,
        message: message,
        actions: [
          {
            label: 'Cancel',
            type: 'neutral',
            cancel: true,
            escKey: true
          },
          {
            label: 'Archive programme',
            type: 'negative',
            confirm: true
          }
        ]
      }).then(action => {
        if (action.confirm) {
          const timer = setTimeout(() => {
            this.modalService.info({
              header: `Archive Programme - ${programme.name}`,
              message: `Are you really sure you want to move all projects in the programme '${programme.name}' to the archive?`,
              actions: [
                {
                  label: 'No, cancel',
                  type: 'neutral',
                  cancel: true,
                  escKey: true
                },
                {
                  label: 'Yes, archive programme',
                  type: 'negative',
                  confirm: true
                }
              ]
            }).then(action => {
              if (action.confirm) {
                this.store.dispatch(
                  projectsActions.archiveNode({
                    id: programme.id
                  })
                );
              }
            });
            clearTimeout(timer);
          }, 200);
        }
      });
    },
    star: (grouping: TreeGrouping, programme: HierarchyNode) => {
      this.store.dispatch(projectsActions.addToFavourites({ nodeId: programme.id }));
    },
    unstar: (grouping: TreeGrouping, programme: HierarchyNode) => {
      this.store.dispatch(projectsActions.removeFromFavourites({ nodeId: programme.id }));
    }
  };

  getActionsAvailable(project: TreeProjectNode, revision?: TreeRevisionNode): DropMenuAction[] {

    const canEditBranch = access.permissionIncludes(project.permission, access.actionType.editBranch);
    const canEditBranchView = access.permissionIncludes(project.permission, access.actionType.editBranchView);
    const actions: DropMenuAction[] = [];

    if (revision) {
      // if revision is a draft then it must be owned by the current user:
      if ((canEditBranch || canEditBranchView) && !revision.published) {
        this.notFromRevision = false;
        actions.push(
          this.actions.publishDraft,
          this.actions.discardDraft
        );
      } else {
        if (access.permissionIncludes(project.permission, access.actionType.editBranch)) {
          actions.push(
            this.actions.renameRevision,
            this.actions.useAsBaseline
          );
        }
        if ((canEditBranch || canEditBranchView) && !project.revisions?.some(r => !r.published)) {
          actions.push(
            this.actions.createDraft,
            this.actions.useAsBaseline
          );
        }
        if (!canEditBranch) {
          actions.push(this.actions.requestAccess);
        }
      }
    } else if (project.archived) {

      if (access.permissionIncludes(project.permission, access.actionType.restoreArchivedBranch)) {
        actions.push(this.actions.restore);
      }
      if (access.permissionIncludes(project.permission, access.actionType.deleteArchivedBranch)) {
        actions.push(this.actions.delete);
      }
    } else {

      if (!canEditBranch) {
        actions.push(this.actions.requestAccess);
      }

      actions.push(project.favourite ? this.actions.unstar : this.actions.star);

      if (access.permissionIncludes(project.permission, access.actionType.editBranchUserAccess)) {
        actions.push(this.actions.share);
      }

      if (canEditBranch) {
        actions.push(this.actions.renameProject, this.actions.move);
      }

      if (project.revisions?.some(r => r.published)) {
        actions.push(this.actions.copy);
      }
      if ((canEditBranch || canEditBranchView) && !project.revisions?.some(r => !r.published)) {
        actions.push(this.actions.createDraft);
      }
      if (project.revisions?.some(r => !r.published)) {
        this.notFromRevision =  true
        actions.push(this.actions.publishDraft,
          this.actions.discardDraft);
      }

      if (access.permissionIncludes(project.permission, access.actionType.archiveBranch)) {
        actions.push(this.actions.archive);
      }
    }

    return actions;

  }

  getGroupActionsAvailable(grouping: TreeGrouping, programme?: HierarchyNode): DropMenuAction[] {

    const actions: DropMenuAction[] = [];

    // actions can only be applied to groupings which represent programmes, so check this first:
    if (!programme) {
      return actions;
    }

    const canEditBranch = access.permissionIncludes(programme.access, access.actionType.editBranch);

    //if (canEditBranch) {
    // if (grouping.meta?.favourite) {
    //   actions.push(this.groupActions.unstar);
    // } else {
    //   actions.push(this.groupActions.star);
    // }
    //}
    if (access.permissionIncludes(programme.access, access.actionType.editBranchUserAccess)) {
      actions.push(this.groupActions.share);
    }
    if (canEditBranch) {
      actions.push(this.groupActions.renaming);
      actions.push(this.groupActions.move);
    }
    if (access.permissionIncludes(programme.access, access.actionType.archiveBranch)) {
      actions.push(this.groupActions.archive);
    }

    return actions;

  }

  dispatchAction(action: DropMenuAction, project: TreeProjectNode, revision?: TreeRevisionNode, view?: HierarchyNodeId): void {
    if (this.handlers[action.action]) {
      this.handlers[action.action].call(this, project, revision, view);
    }
  }

  dispatchGroupAction(action: DropMenuAction, grouping: TreeGrouping, programme: HierarchyNode): void {
    if (this.groupHandlers[action.action]) {
      this.groupHandlers[action.action].call(this, grouping, programme);
    }
  }

}
