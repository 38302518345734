import { ChangeDetectorRef, Component, Input, HostBinding, Output, EventEmitter, Directive, ViewContainerRef, ComponentFactoryResolver, HostListener, ComponentRef, AfterViewInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { DropMenuAction } from 'tp-traqplan-core/dist/structs';
import { uuid } from 'tp-common/uuid.js';
import { State } from '../../state';
import * as appActions from '../../state/app/actions';

@Directive({
  selector: '[appDropMenu]'
})
export class DropMenuDirective {

  @Input() appDropMenu: (...a: any[]) => DropMenuAction[];
  @Input() dropMenuDatums: any[];

  @Output() dropMenuAction = new EventEmitter<DropMenuAction>();

  private component: ComponentRef<DropMenuIconComponent>;

  private timeout: number;
  private targetEntered: boolean = false;
  private iconEntered: boolean = false;
  private menuEntered: boolean = false;

  get nativeTarget() {
    return this.viewContainerRef.element.nativeElement;
  }

  @HostListener('document:click') onDocClick() {
    if (this.menuEntered) {
      this.menuEntered = false;
      this.handleMouseleave();
    }
  }

  @HostListener('mouseleave', ['$event']) onMouseleave(event) {
    this.store.pipe(take(1)).subscribe((state) => {
      this.targetEntered = false;
      if (event.target === this.nativeTarget) {
        const { x, y } = event;
        const { left, right, top, bottom } = event.target.getBoundingClientRect();
        const isInBounds = x >= left && x <= right && y >= top && y <= bottom;
        const dropMenuTimer = state?.app?.dropMenuTimer;

        if (dropMenuTimer) {
          clearTimeout(dropMenuTimer);
          this.store.dispatch(appActions.setDropMenuTimer({ dropMenuTimer: 0 }));
        }

        if (!isInBounds) {
          this.handleMouseleave();
        }
      }
    });
  }

  @HostListener('mouseenter', ['$event.target']) onMouseenter(target) {
    this.store.pipe(take(1)).subscribe((state) => {
      const dropMenuTimer = state?.app?.dropMenuTimer;

      if (dropMenuTimer) {
        clearTimeout(dropMenuTimer);
        this.store.dispatch(appActions.setDropMenuTimer({ dropMenuTimer: 0 }));
      }

      if (Array.isArray(this.dropMenuDatums) && this.dropMenuDatums[1]?.readOnly) {
        return;
      }

      const timer = window.setTimeout(() => {
        clearTimeout(timer);
        this.store.dispatch(appActions.setDropMenuTimer({ dropMenuTimer: 0 }));
        this.targetEntered = true;
        if (!this.component) {
          this.changeDetectorRef.detectChanges();
          this.attachIcon(target);
          this.changeDetectorRef.detectChanges();
        }
      }, 500);
      this.store.dispatch(appActions.setDropMenuTimer({ dropMenuTimer: timer }));
    });
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef, private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver, private store: Store<State>
  ) { }

  private attachIcon(target) {

    const bRect = target.getBoundingClientRect();
    const position = [
      bRect.left + bRect.width,
      bRect.top + bRect.height / 2
    ];

    const actions: DropMenuAction[] = this.appDropMenu(...this.dropMenuDatums);

    const factory = this.componentFactoryResolver.resolveComponentFactory(DropMenuIconComponent);

    this.component = this.viewContainerRef.createComponent(factory);
    this.component.instance.position = position;
    this.component.instance.actions = actions;

    this.component.instance.action.subscribe((action: DropMenuAction) => this.dropMenuAction.emit(action));

    this.component.instance.menuEntered.subscribe(() => {
      this.menuEntered = true;
      clearTimeout(this.timeout);
      this.timeout = 0;
    });

    this.component.instance.menuExited.subscribe(() => {
      this.menuEntered = false;
      this.handleMouseleave();
    });

    this.component.location.nativeElement.addEventListener('mouseleave', () => {
      this.iconEntered = false;
      this.handleMouseleave();
    });
    this.component.location.nativeElement.addEventListener('mouseenter', () => {
      this.iconEntered = true;
      clearTimeout(this.timeout);
      this.timeout = 0;
    });

    //highlight the target node:
    this.nativeTarget.style.setProperty('background', 'var(--color-positive-20)');

    //this.component.location.nativeElement.addEventListener('click', ev => {
    //  ev.stopPropagation();
    //  this.menuEntered = true;
    //});

  }

  private handleMouseleave() {
    setTimeout(() => {
      if (this.component && !this.iconEntered && !this.targetEntered && !this.menuEntered) {
        this.nativeTarget.style.removeProperty('background');
        this.component.destroy();
        this.component = null;
      }
    }, 100);
  }

}


@Component({
  selector: 'app-drop-menu-icon',
  template: `
    <span class="fas drop-menu-trigger" [matMenuTriggerFor]="menu">ellipsis-h</span>

    <mat-menu
      #menu="matMenu"
      [class]="panelClass"
      [hasBackdrop]="false"
      [overlapTrigger]="true"
    >
      <button mat-menu-item
        *ngFor="let action of actions"
        (click)="onClick(action)"
      >
        <mat-icon class="fas"
          [ngStyle]="{ color: action.colour || 'var(--color-neutral)' }"
        >
          {{action.icon}}
        </mat-icon>
        <span>{{action.label}}</span>
      </button>
    </mat-menu>
  `,
  styles: [`
    :host {
      position: fixed;
      transform: translate(calc(-100% - var(--padding-fat)), -50%);
      color: var(--color-neutral);
      cursor: pointer;
      transition: color var(--trans-fast);
    }

    .drop-menu-trigger {
      opacity: 0;
    }

    :host:hover {
      color: var(--color-positive);
    }

    button {
      padding: 0 var(--padding-fat) !important;
    }

    mat-icon {
      font-size: 1.3em !important;
      margin-right: var(--padding-thin)
    }

  `]
})
export class DropMenuIconComponent implements AfterViewInit {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger

  @HostBinding('style.left') styleLeft: string;
  @HostBinding('style.top') styleTop: string;

  @Input() set position([left, top]) {
    this.styleLeft = `${left}px`;
    this.styleTop = `${top}px`;
  }
  @Input() actions: DropMenuAction[] = [];
  @Output() action = new EventEmitter<DropMenuAction>();
  @Output() menuEntered = new EventEmitter<void>();
  @Output() menuExited = new EventEmitter<void>();

  // Ensures that there is no chance of delegated listeners picking up events on the wrong panel:
  panelClass = 'drop-menu-panel__' + uuid().toString();

  private panelSelector = `.${this.panelClass}, .${this.panelClass} *`;

  @HostListener('document:mouseover', ['$event']) onDocMouseover(e: MouseEvent) {
    const target = e.target as HTMLElement;
    const relTarget = e.relatedTarget as HTMLElement;
    // checking rel target just avoids calling this repeatedly:
    if (target?.matches(this.panelSelector) && !relTarget?.matches(this.panelSelector)) {
      this.menuEntered.emit();
    }
  }

  @HostListener('document:mouseout', ['$event']) onDocMouseout(e: MouseEvent) {
    const target = e.target as HTMLElement;
    const relTarget = e.relatedTarget as HTMLElement;
    if (target?.matches(this.panelSelector) && !relTarget?.matches(this.panelSelector)) {
      this.menuExited.emit();
    }
  }


  ngAfterViewInit(): void {
    if (Array.isArray(this.actions) && this.actions.length > 0) {
      this.trigger.openMenu();
    }
  }

  onClick(action: DropMenuAction) {
    this.action.emit(action);
  }

  /*menuMouseOver(e: MouseEvent): void {
      const target = e.target as HTMLElement;
      if (target.classList.contains('menu')) {
        this.menuEntered.emit();
      }
    }

    menuMouseOut(e: MouseEvent): void {
      const target = e.target as HTMLElement;
      if (target.classList.contains('menu')) {
        this.menuExited.emit();
      }
    }*/

}
