<app-modal-window 
  header="Edit License"
  [(visible)]="visible"
  (closed)="onClosed()"
>
  <div class="modal-inner-wrapper">
    <!--<app-manage-licenses *ngIf="isModalVisible(modalFlags.managelicenses)" (completed)="onManageLicensesComplete()"
      (closed)="closeModal(modalFlags.managelicenses)" [assignee]="assignee">
    </app-manage-licenses>-->

  <form class="tp-form" [formGroup]="form">
    <div class="tp-form__field">
      <label for="name">Name</label>
  
      <div>{{user?.name}}</div>
    </div>
  
    <div class="tp-form__field">
      <label for="email">Email</label>
  
      <div>{{user?.email}}</div>
    </div>
  
    <div class="tp-form__field">
      <label for="license">Choose from available licenses</label>
  
      <p-dropdown appendTo="body" formControlName="license" name="license" optionValue="value"
        placeholder="Select a license" [options]="licenseTypes"></p-dropdown>
    </div>
  
    <div class="tp-form__field" *ngIf="invite && invite.id" style="font-weight: normal">
      Invited by {{invite.authorName}} ({{invite.authorEmail}}) at {{invite.invitedAt | date: 'medium'}}
    </div>
  
    <div class="tp-form__field">
      <ag-grid-angular
        class="ag-theme-balham"
        style="width: 100%; height: 50vh;"
        [defaultColDef]="defaultColDef"
        [enableRangeSelection]="false"
        [getRowId]="getRowId"
        [getDataPath]="getDataPath"
        [modules]="modules"
        [rowData]="hierarchyAccess"
        [treeData]="true"
        [animateRows]="true"
        [columnDefs]="hierarchyAccessColumnDefs"
        [groupDefaultExpanded]="hierarchyAccessGroupDefaultExpanded"
        [groupDisplayType]="hierarchyAccessGroupDisplayType"
        (rowClicked)="onAccessSelection($event)"
        rowSelection="single"
      >
      </ag-grid-angular>
    </div>
  
    <div class=tp-form__field>
      <app-button text="Cancel" type="neutral" (click)="onClosed()">
      </app-button>
  
      <app-button text="Assign" type="positive" [disabled]="this.form.invalid" (click)="onSubmit()">
      </app-button>
    </div>
  </form>
    
  </div>
</app-modal-window>