import * as PowerBi from '../../../powerbi-data-structs';

import Customer = PowerBi.Customer;

export const key = 'powerbi.customer';

export interface State {
  customer: Customer,
  customers: Customer[]
}

export const initialState: State = {
  customer: null,
  customers: []
};

export function beforeSave(state) {
  return {
    ...state
  };
}

export function beforeLoad(state) {
  return state;
}
