import { createAction, props } from '@ngrx/store';
import { RequestError } from '../../api/structs';
import { PricingPlan, PricingPlanId } from 'tp-traqplan-core/dist/data-structs';

export const requestPricingPlans = createAction(
  '[pricingPlans] requestPricingPlans',
  props<any>()
);

export const requestPricingPlansSuccess = createAction(
  '[pricingPlans] requestPricingPlansSuccess',
  props<{ pricingPlans: PricingPlan[] }>()
);

export const requestPricingPlansFailure = createAction(
  '[pricingPlans] requestPricingPlansFailure',
  props<{ error: RequestError }>()
);

export const requestPricingPlanById = createAction(
  '[pricingPlans] requestPricingPlanById',
  props<{ id: PricingPlanId }>()
);

export const requestPricingPlanByIdSuccess = createAction(
  '[pricingPlans] requestPricingPlanByIdSuccess',
  props<{ pricingPlan: PricingPlan }>()
);

export const requestPricingPlanByIdFailure = createAction(
  '[pricingPlans] requestPricingPlanByIdFailure',
  props<{ error: RequestError }>()
);


export const staffRequestPricingPlans = createAction(
  '[pricingPlans] staffRequestPricingPlans',
  props<any>()
);

export const staffRequestPricingPlansSuccess = createAction(
  '[pricingPlans] staffRequestPricingPlansSuccess',
  props<{ pricingPlans: PricingPlan[] }>()
);

export const staffRequestPricingPlansFailure = createAction(
  '[pricingPlans] staffRequestPricingPlansFailure',
  props<{ error: RequestError }>()
);

export const staffRequestPricingPlanById = createAction(
  '[pricingPlans] staffRequestPricingPlanById',
  props<{ id: PricingPlanId }>()
);

export const staffRequestPricingPlanByIdSuccess = createAction(
  '[pricingPlans] staffRequestPricingPlanByIdSuccess',
  props<{ pricingPlan: PricingPlan }>()
);

export const staffRequestPricingPlanByIdFailure = createAction(
  '[pricingPlans] staffRequestPricingPlanByIdFailure',
  props<{ error: RequestError }>()
);
