<ng-container *ngIf="params$ | async as params">
  <app-modal-window
    header="Publish draft in current view?"
    [(visible)]="visible"
    (actionSelected)="onActionSelected($event)"
    (closed)="onClose()"
    [actions]="params.actions"
    [fullscreen]="false"
    maxWidth="60%"
  >
    <div>You are editing a draft in this view. When you switch to another view you will not see your draft changes unless they are published. We recommend publishing frequently as this shares changes with others and lets you see your changes when you switch to other views. Do not worry if its not perfect, you can always edit again and recover previous versions.</div>

    <div>Would you like to publish this draft to a new revision?</div>
  </app-modal-window>
</ng-container>
