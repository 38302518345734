import { createReducer, on } from '@ngrx/store';
import { initialState } from './state';
import * as subscriptionsActions from './actions';

export const reducer = createReducer(
  initialState,

  on(subscriptionsActions.staffRequestSubscriptionsSuccess, (state, props) => {
    return {
      ...state,
      subscriptions: props.subscriptions
    };
  }),

  on(subscriptionsActions.staffRequestSubscriptionByIdSuccess, (state, props) => {
    return {
      ...state,
      subscription: props.subscription
    };
  })

);
