import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup, AbstractControl, ValidationErrors } from '@angular/forms';
import { Subject, throwError, of } from 'rxjs';
import { takeUntil, tap, catchError } from 'rxjs/operators';
import { UserApiService } from 'src/app/api/user-api.service';
import { validatePassword } from 'tp-common/password.js';

interface UserVerification {
  token?: string;
  email?: string;
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnDestroy {

  public verification: UserVerification = {};
  public tokenForm: FormGroup;
  public passwordForm: FormGroup;
  public tokenError: string;
  public passwordError: string;

  private destroy$ = new Subject<void>();

  get token() { return this.tokenForm?.get('token') }
  get password() { return this.passwordForm?.get('password') }
  get confirmPassword() { return this.confirmPassword?.get('confirmPassword') }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private userApi: UserApiService
  ) {

    this.route.queryParamMap.pipe(
      takeUntil(this.destroy$),
      tap((params) => {
        const token = params.get('token');
        const email = params.get('email');
        if (email) {
          this.verification.email = decodeURIComponent(email);
        } else {
          this.router.navigate(['login']);
          return;
        }
        if (token) {
          this.verification.token = token;
        }
      })
    ).subscribe();

    this.tokenForm = this.fb.group({
      token: this.fb.control('', [
        Validators.required
      ]),
    });

    this.passwordForm = this.fb.group({
      password: this.fb.control('', [
        Validators.required,
        (control: AbstractControl) => {
          const error = validatePassword(control.value || '');
          if (error?.error) {
            return <ValidationErrors>{ invald: error.error }
          }
          return null;
        }
      ]),
      confirmPassword: this.fb.control('', [
        Validators.required
      ])
    }, {
      validators: [
        (form: FormGroup) => {
          if (form.controls.password.dirty && form.controls.confirmPassword.dirty && form.controls.password.value !== form.controls.confirmPassword.value) {
            return { mismatch: 'Passwords do not match' }
          }
        }
      ]
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onTokenSubmit(): void {

    if (this.tokenForm.valid) {

      this.userApi.verifyPasswordToken({ token: this.token.value, email: this.verification.email }).pipe(
        catchError(({ error }) => {
          return of(error);
        }),
        tap((error) => {
          if (error?.error) {
            this.tokenError = error.error;
          } else {
            this.tokenError = null;
            this.verification.token = this.token.value;
          }
        })
      ).subscribe();

    }
  }

  onPasswordSubmit(): void {
    if (this.passwordForm.valid) {
      const password = this.password.value;
      const { token, email } = this.verification;
      this.userApi.setPassword({ token, email, password }).pipe(
        catchError(error => {
          this.passwordError = error.message;
          return throwError(error);
        }),
        tap(() => {
          this.router.navigate(['login']);
        })
      ).subscribe();
    }
  }

}
