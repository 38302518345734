import { Component, Input, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';

@Component({
  selector: 'app-modal-window',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.css']
})
export class ModalWindowComponent {

  @HostBinding('style')
  get style() {
    return this.sanitizer.bypassSecurityTrustStyle(
      `--width: ${ this.width || '60%' }; --max-width: ${ this.maxWidth || '40em' }`
    );
  }

  @Input() width: string;
  @Input() maxWidth: string;
  @Input() actions: ModalWindowAction[];
  @Input() header: string;
  @Input() visible: boolean = false;
  @Input() fullscreen: boolean = false;
  @Input() maximizable: boolean = false;
  @Input() focus: HTMLElement;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() actionSelected = new EventEmitter<ModalWindowAction>();
  @Output() closed = new EventEmitter<void>();

  @HostListener('document:keyup.enter')
  onEnterKey(): void {
    if (this.visible) {
      const action = this.actions?.find(a => a.enterKey);
      if (action && (!action.valid || action.valid())) {
        this.selectAction(action);
      }
    }
  }

  @HostListener('document:keyup.esc')
  onEscKey(): void {
    if (this.visible) {
      if (this.actions?.length) {
        const action = this.actions?.find(a => a.escKey);
        if (action && (!action.valid || action.valid())) {
          this.selectAction(action);
        }
      } else {
        this.setVisible(false);
      }
    }
  }

  public severityClasses = {
    'positive': '',
    'negative': 'p-button-danger',
    'neutral': 'p-button-secondary'
  };

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  onVisibleChange(visible: boolean): void {
    this.setVisible(visible);
    if (this.visible && this.focus?.focus) {
      setTimeout(() =>this.focus.focus());
    }
  }

  selectAction(action: ModalWindowAction): void {
    this.actionSelected.emit(action);
    this.setVisible(false);
  }

  private setVisible(visible: boolean): void {
    this.visible = visible;
    this.visibleChange.emit(visible);
    if (!visible) {
      this.closed.emit();
    }
  }

}
