import { createReducer, on } from '@ngrx/store';
import { initialState } from './state';
import * as accountActions from './actions';
import { dsReceived, dsPending } from '../helpers';
import { DataStore } from '../structs';
import { AccountInfo, HierarchyNode } from 'tp-traqplan-core/dist/data-structs';

export const reducer = createReducer(
  initialState,

  on(accountActions.reset, (state, props) => {
    return { ...initialState, ...props };
  }),

  on(accountActions.requestAvailable, (state) => {
    return {
      ...state,
      available: <DataStore<AccountInfo[]>>{
        ...state.available,
        ...dsPending<AccountInfo[]>()
      }
    };
  }),

  on(accountActions.requestAvailableSuccess, (state, props) => {
    return {
      ...state,
      available: dsReceived<AccountInfo[]>(props.accounts),
    };
  }),

  on(accountActions.launchAccountSuccess, (state, props) => {
    const accountInfo = state.available?.value?.find(a => a.id === props.account);
    return {
      ...state,
      selected: accountInfo
    }
  }),

  on(accountActions.requestAvailableRoots, (state) => {
    return {
      ...state,
      root: {
        ...state.root,
        available: {
          ...state.root.available,
          ...dsPending<HierarchyNode[]>()
        }
      }
    };
  }),

  on(accountActions.requestAvailableRootsSuccess, (state, props) => {
    return {
      ...state,
      root: {
        ...state.root,
        available: dsReceived<HierarchyNode[]>(props.roots),
        selected: state.root.selected && props.roots.some(r => r.id === state.root.selected.id) ? state.root.selected : null
      }
    };
  }),

  on(accountActions.requestLicensesSuccess, (state, props) => {
    return {
      ...state,
      licenses: props.licenses
    };
  }),

  on(accountActions.requestAdminsSuccess, (state, props) => {
    return {
      ...state,
      admins: props.admins
    };
  }),

  on(accountActions.requestUsersSuccess, (state, props) => {
    return {
      ...state,
      users: props.users
    };
  }),

  on(accountActions.selectRoot, (state, props) => {
    return {
      ...state,
      root: {
        ...state.root,
        selected: props.root
      }
    }
  }),

  on(accountActions.requestHierarchyPermissionsSuccess, (state, props) => {
    return {
      ...state,
      hierarchyPermissions: props.hierarchyPermissions
    };
  }),

  on(accountActions.activeSubscription, (state, props) => {
    return {
      ...state,
      subscription: props.subscription,
      subscriptionExpired: false,
      trialSubscriptionExpired: false
    };
  }),

  on(accountActions.subscriptionExpired, (state, props) => {
    return {
      ...state,
      subscription: props.subscription,
      subscriptionExpired: true,
      trialSubscriptionExpired: false
    };
  }),

  on(accountActions.trialSubscriptionExpired, (state, props) => {
    return {
      ...state,
      subscription: props.subscription,
      subscriptionExpired: false,
      trialSubscriptionExpired: true
    };
  })

);
