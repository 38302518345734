import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { debounce } from 'lodash';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {

  /**
   * See scss declaration file for specific theme values. Current values: light, default
   */
  @HostBinding('attr.data-theme') @Input() theme: string = 'default';
  @Input() value: string;
  @Input() placeholder: string = '';
  @Output() search = new EventEmitter<string>();

  emitInput = debounce(e => this.search.emit(e.target.value), 100);

  onInput(event) {
    this.emitInput(event);
  }

}
