import { Component, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { Notification } from '../data-structs';
import { State } from '../state';
import * as modalActions from '../state/modal/actions';
import * as authActions from '../state/auth/actions';
import { SubscriptionApiService } from '../api/subscription-api.service';
import { NotificationsService } from '../api/notifications.service';
import { StateModal } from '../state/structs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnDestroy, OnInit {

  private destroy$ = new Subject<void>();

  public displaySideBar: boolean = false;
  public displayTpsBar: boolean = false;

  public displayNotifications: boolean = false;
  public notifications: Notification[] = [];

  public unread: number;

  public notificationsVisible: boolean = false;

  public state$: Observable<any> = this.store.pipe(
    takeUntil(this.destroy$),
    map(({ auth, tps, account }: State) => {
      return {
        user: auth?.user || null,
        staff: !tps.pending && tps.staff || null,
        license: account.selected?.license || null
      }
    })
  );

  public trialWarning$ = this.subscriptionApiService.latest$.pipe(
    takeUntil(this.destroy$),
    map((subscription) => {
      if (subscription?.trial) {
        if (!subscription.is_active) {
          return 'Free trial (expired)';
        }
        if (subscription.expired_at) {
          const remaining = Math.floor((subscription.expired_at.getTime() - Date.now()) / 86400000);
          return `Free trial (${remaining} days remaining)`;
        } else {
          return 'Free trial';
        }
      }
    })
  );

  private intervalId: any;

  constructor(
    private store: Store<State>,
    private subscriptionApiService:  SubscriptionApiService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {

    this.store.pipe(
      takeUntil(this.destroy$),
      map(({ account }) => account.selected),
      distinctUntilChanged(),
      switchMap(() => this.subscriptionApiService.getLatest())
    ).subscribe();

    this.notificationsService.notifications$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((notifications: Notification[]) => {
      this.notifications = [...this.notifications, ...notifications.filter(n => !this.notifications.some(nn => nn.id === n.id))];
      this.unread = this.notifications.filter(n => !n.readDate).length;
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSideBar(): void {
    this.displaySideBar = !this.displaySideBar;
  }

  toggleTpsBar(): void {
    this.displayTpsBar = !this.displayTpsBar;
  }

  toggleNotifications(ev: MouseEvent): void {
    ev.stopPropagation();
    this.notificationsVisible = !this.notificationsVisible;
  }

  onClickRequestLicense() {
    this.store.dispatch(modalActions.generateModal({
      modal: StateModal.requestLicense
    }));
  }

  logout(): void {
    this.store.dispatch(
      authActions.logout({})
    );

    /*this.store.select('auth').pipe(
      take(1),
      tap(({ origin, ssoToken }) => {
        this.store.dispatch(
          authActions.logout({
            navigate: ['login'],
            origin: origin || environment.websiteURL,
            ssoToken
          })
        );
        this.displaySideBar = false;
      })
    ).subscribe();*/
  }

  exitEmulation(): void {
    this.store.dispatch(
      authActions.logout({
        navigate: ['staff']
      })
    );
    this.displayTpsBar = false;
  }

}
