import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, distinctUntilChanged, tap, map } from 'rxjs/operators';
import dayjs, { ManipulateType } from 'dayjs';
import { HierarchyNodeId, RevisionId, TaskId, ViewGroupId, PoapTask, MilestoneCategory, PoapExportSettings, PoapExportBreakMode, GroupTreeNode, LegendFittingMode, LegendPosition } from 'tp-traqplan-core/dist/data-structs';
import { BOUNDARY_MODE, VIEW_MODE, TimelineOptions, TimelineState, TaskChange, ViewGroupChange, ProjectChange, ProjectOrderChange, LayoutOptions, TextLayout, EditCheckParams, TaskBoundElement } from 'tp-traqplan-core/dist/timeline/tl-structs';
import { TimelineCoreComponent } from './timeline-core/timeline-core.component';
import { HorizontalScrollerComponent } from './horizontal-scroller/horizontal-scroller.component';
import { TimelineExportComponent } from './timeline-export/timeline-export.component';
import { TimelineLayout } from 'tp-traqplan-core/dist/timeline/timeline-layout';
import { TimelineEventChannel } from 'tp-traqplan-core/dist/timeline/timeline-event-channel';
import { State } from '../../state';
import { TimelineInteractions } from './tl-interactions';

const REQUIRED_OPTIONS = [
  'nominalStart',
  'nominalFinish',
  'groupMap',
  'coloursAvailable'
];

const defaultOptions: Partial<TimelineOptions> = {
  evaluateNarrative: false,
  groupsWidth: 3,
  textMaxWidth: 270,
  //textMinWidth: 40,
  fontSize: 12,
  milestoneSymbolSize: 18,
  taskPadding: 4,
  textPadding: 4,
  groupTextPadding: 8,
  displayBaseline: true,
  baselineThreshold: 86400000, //1 day in milliseconds
  prependMilestoneDates: false,
  taskMaxLines: 3,
  groupMaxLines: 3,
  milestoneCategories: [],
  displayEmptyGroups: true,
  viewTickLines: false,
  wrapTextOutsideBar: true,
  wrapTextOutsideThreshold: 5,
  viewLines: []
};

export interface ResizeEvent {
  height?: boolean;
  width?: boolean;
}

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css'],
})
export class TimelineComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('timelineExport', { static: true }) timelineExport: TimelineExportComponent;
  @ViewChild('timelineCore', { static: true }) timelineCore: TimelineCoreComponent;
  @ViewChild('horizontalScroller', { static: true }) horizontalScroller: HorizontalScrollerComponent;

  @Input() readOnly: boolean = false;
  // deprecated
  @Input() editCheck: (projectId: HierarchyNodeId, revisionId: RevisionId, taskOrGroup: string, itemId: TaskId | ViewGroupId, taskFields?: string[], displayMessage?: boolean) => boolean = () => true;

  @Input() checkEditable: (params: EditCheckParams) => boolean = () => true;

  @Output() settingsChange = new EventEmitter();
  @Output() selectionChange = new EventEmitter<PoapTask[]>();
  @Output() groupChange = new EventEmitter<ViewGroupChange>();
  @Output() taskChange = new EventEmitter<TaskChange>();
  @Output() projectChange = new EventEmitter<ProjectChange>();
  @Output() projectOrderChange = new EventEmitter<ProjectOrderChange>();
  @Output() exportSettingsChange = new EventEmitter<Partial<PoapExportSettings>>();
  @Output() editViewLines = new EventEmitter<void>();

  @HostListener('window:resize')
  onResize() {
    this.timelineCore && this.update();
  }

  private options: Partial<TimelineOptions> = { ...defaultOptions };
  public settings: Partial<TimelineOptions> = {};
  public eventChannel = new TimelineEventChannel();

  public layout = new TimelineLayout();

  public interactions = new TimelineInteractions(this.layout, (...args) => this.checkEditable(...args));

  public state = new BehaviorSubject<TimelineState>({
    readOnly: false,

    selectedTasks: [],
    selectionLimit: 1,

    groupInteractionTarget: null,
    taskInteractionTarget: null,

    exportActive: false,

    viewportHeight: 0,
    viewportScrollActive: false,
    viewportScrollHeight: 0,
    viewportScrollTranslation: 0,

    viewportSlideActive: false,

    axisBoundaryMode: BOUNDARY_MODE.Auto,
    axisBoundaryRange: [],
    axisViewRange: []
  });

  public exportSettings: PoapExportSettings = {
    title: 'Your page title here',
    date: new Date(),
    logo: '',
    format: 'JPEG',
    ratio: 'A3',
    orientation: 'landscape',
    resolution: 300,
    pageBreaks: [],
    fontSize: null,
    breakMode: PoapExportBreakMode.Single,
    legendFittingMode: LegendFittingMode.compressed,
    legendPosition: LegendPosition.bottom
  };

  public coloursAvailable: Map<string, string>;
  public togglehAxisBoundaryMode: boolean = true;
  public toggleViewMode: boolean = false;
  public milestoneMap: Map<string, MilestoneCategory>;

  public hAxisBoundaryMode: number = BOUNDARY_MODE.Auto;
  public hAxisBoundaryStart: Date;
  public hAxisBoundaryFinish: Date;
  public hAxisViewStart: Date;
  public hAxisViewFinish: Date;

  public viewportWidth: number;
  public viewportHeight: number;
  public scrollHeight: number;
  public scrollTranslation: number = 0;
  public exportActive: boolean = false;

  //private milestoneCategoryList: MilestoneCategory[];
  private viewMode: number = VIEW_MODE.Normal;
  private wrapStart: Date;
  private wrapFinish: Date;
  private plotFinish: Date;
  private plotStart: Date;
  private containerWidth: number;
  private destroy$ = new Subject<void>();
  private drawingLayouts: { [key: HierarchyNodeId]: GroupTreeNode[] };

  get isAutoMode() {
    return this.hAxisBoundaryMode === BOUNDARY_MODE.Auto;
  }

  constructor(private store: Store<State>) {

    // user interaction can affect (and be triggered by) targets in various sub components
    // therefore actual handling is delegated to a service shared between sub components
    this.interactions.events$.pipe(
      takeUntil(this.destroy$),
      tap(({ event, data }) => {
        switch(event) {
          case 'taskChange':
            return this.taskChange.emit(data as TaskChange);
          case 'groupChange':
            return this.groupChange.emit(data as ViewGroupChange);
          case 'projectChange':
            return this.projectChange.emit(data as ProjectChange);
          case 'projectReorder':
            return this.projectOrderChange.emit(data as ProjectOrderChange);
          case 'settingsChange':
            return this.settingsChange.emit(data as Partial<TimelineOptions>);
          case 'editViewLines':
            return this.editViewLines.emit();
        }
      })
    ).subscribe();

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.interactions.state$.pipe(
      takeUntil(this.destroy$),
      map(state => state.selectedTasks),
      distinctUntilChanged()
    ).subscribe((selectedTasks: TaskBoundElement[]) => {
      const emission = selectedTasks.reduce((a, t) => a.concat(t.__fitting__.ProjectTasks), <PoapTask[]>[]);
      this.selectionChange.emit(emission);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.readOnly) {
      this.interactions.setReadOnly(this.readOnly);
    }
  }
  updateExportSettings(settings: PoapExportSettings): void {
    this.exportSettings = { ...settings };
  }

  update(options?: Partial<TimelineOptions>): void {
    options && Object.assign(this.options, options);

    if (REQUIRED_OPTIONS.some(prop => this.options[prop] === void 0)) {
      return;
    }

    this.settings = { ...this.options };

    if (this.settings.milestoneCategories) {
      // update milestone map (core and export input)
      this.milestoneMap = new Map(
        this.settings.milestoneCategories.map(i => ([i.id, i]))
      );
    }

    this.determineHAxisBoundaries();

    this.determineViewportExtents();

    this.determinePlotExtents();

    const { width } = this.timelineCore.getViewportDims();

    if (this.containerWidth !== width) {
      this.horizontalScroller.rescale(true);
      this.containerWidth = width;
    }

    this.viewportWidth = width;

    try {
      this.layout.layout(this.buildLayoutSettings(), false);
    } catch (err) {
      console.log(err);
    }

    this.scrollHeight = this.layout.fittingMeta.totalHeight + this.layout.settings.taskPadding * 2;
  }

  clearSelection(): void {
    this.eventChannel.publish('task__deselectAll');
  }

  beginExport() {
    this.exportActive = true;
    this.timelineExport.displayWindow();
  }

  onHAxisWindowChange({ start, finish }): void {
    this.update({
      viewportStart: start,
      viewportFinish: finish
    });
  }

  onHAxisBoundaryChange(boundary: 'start' | 'finish', value: Date): void {
    this.hAxisBoundaryMode = BOUNDARY_MODE.Manual;
    this.togglehAxisBoundaryMode = false;

    this.settingsChange.emit({
      boundaryStart: boundary === 'start' ? value : this.hAxisBoundaryStart,
      boundaryFinish: boundary === 'finish' ? value : this.hAxisBoundaryFinish
    });
  }

  onHAxisModeChange({ checked }: { checked: boolean }): void {
    this.hAxisBoundaryMode = checked ? BOUNDARY_MODE.Auto : BOUNDARY_MODE.Manual;
    this.update();
  }

  onViewModeChange({ checked }: { checked: boolean }): void {
    this.viewMode = checked ? VIEW_MODE.Print : VIEW_MODE.Normal;
    this.update();
  }

  onCoreViewportChange(viewportDims) {
    this.viewportHeight = viewportDims.height - viewportDims.axisHeight;
  }

  onExportClose() {
    this.exportActive = false;
  }

  onExportSettingsChange(settings: Partial<PoapExportSettings>): void {
    this.exportSettingsChange.emit({ ...settings });
  }

  private getUnitOfTime(): { howManyStart: number; howManyFinish: number; unitOfTime: string } {
    const { nominalStart: nmStart, nominalFinish: nmFinish } = this.options;

    if (nmStart && nmFinish) {
      const unitsToFit = ['day', 'week', 'month', 'quarter', 'year'];

      for (let i = 0; i < unitsToFit.length; i++) {
        const toFit = unitsToFit[i];
        const diff = dayjs(nmFinish).startOf('day').diff(dayjs(nmStart).startOf('day'), <ManipulateType>toFit);

        if (diff > 0 && diff < 17) {
          const howMany = Math.ceil(diff / 2);
          // const howMany = Math.ceil(diff);
          const howManyFinish = Math.max(2, howMany + howMany % 2);
          return i === 3 ? { howManyStart: 3, howManyFinish: howManyFinish * 3, unitOfTime: 'month' } : { howManyStart: 1, howManyFinish, unitOfTime: toFit };
        }
      }
    }

    let howMany = 2;
    let diff = dayjs(nmFinish).startOf('day').diff(dayjs(nmStart).startOf('day'), 'year') / howMany;

    while (diff < 1 && diff > 16) {
      howMany += 1;
      diff = dayjs(nmFinish).startOf('day').diff(dayjs(nmStart).startOf('day'), 'year') / howMany;
    }

    return { howManyStart: Math.ceil(howMany * 0.25), howManyFinish: howMany, unitOfTime: 'year' };
  }

  private getStartDate(date: Date, howMany: number, unitOfTime: ManipulateType): Date {
    return dayjs(date).startOf(unitOfTime).subtract(howMany, unitOfTime).toDate();
  }

  private getFinishDate(date: Date, howMany: number, unitOfTime: ManipulateType): Date {
    return dayjs(date).endOf(unitOfTime).add(howMany, unitOfTime).toDate();
  }

  private determineHAxisBoundaries(): void {
    const { nominalStart: nmStart, nominalFinish: nmFinish, boundaryStart: bStart, boundaryFinish: bFinish } = this.options;

    const auto = (this.hAxisBoundaryMode === BOUNDARY_MODE.Auto);

    // the default boundary if nothing is selected and we are auto mode:
    const { howManyStart, howManyFinish, unitOfTime } = <{ howManyStart: number; howManyFinish: number; unitOfTime: ManipulateType }>this.getUnitOfTime();
    const defStart = this.getStartDate(new Date(), howManyStart, unitOfTime);
    const defFinish = this.getFinishDate(new Date(), howManyFinish, unitOfTime);

    const autoStart = !nmStart?.getTime ? null : this.getStartDate(nmStart, howManyStart, unitOfTime);
    const autoFinish = !nmFinish?.getTime ? null : this.getFinishDate(new Date(Math.max(nmStart?.getTime() || 0, nmFinish.getTime())), howManyFinish, unitOfTime);

    this.hAxisBoundaryStart = (auto ? autoStart : bStart) || defStart;
    this.hAxisBoundaryFinish = ((!auto && bFinish) ? new Date(Math.max(this.getFinishDate(this.hAxisBoundaryStart, howManyFinish, unitOfTime).getTime(), bFinish.getTime())) : autoFinish) || defFinish;
  }

  private determineViewportExtents(): void {
    const minStart = this.hAxisBoundaryStart.getTime();
    const maxStart = this.hAxisBoundaryFinish.getTime();

    const viewportStart = Math.min(maxStart, Math.max(minStart, this.options.viewportStart?.getTime() || minStart));

    const minFinish = viewportStart;
    const maxFinish = this.hAxisBoundaryFinish.getTime();

    const viewportFinish = Math.min(maxFinish, Math.max(minFinish, this.options.viewportFinish?.getTime() || maxFinish));

    // update horizontal scroller hooks:
    this.settings.viewportStart = new Date(viewportStart);
    this.settings.viewportFinish = new Date(viewportFinish);
    this.hAxisViewStart = this.settings.viewportStart;
    this.hAxisViewFinish = this.settings.viewportFinish;
  }

  private determinePlotExtents(): void {

    // determine plot start by viewport and nominal boundaries:
    if (this.viewMode === VIEW_MODE.Normal) {
      this.wrapStart = this.plotStart = this.hAxisViewStart;
      this.wrapFinish = this.plotFinish = this.hAxisViewFinish;
      // else if print mode then plot & wrap between print lines:
    } else {
      this.plotStart = this.wrapStart = this.settings.printStart;
      this.plotFinish = this.wrapFinish = this.settings.printFinish;
    }

  }

  private buildLayoutSettings() {

    return <LayoutOptions>{
      groupMap: this.settings.groupMap,
      plotStart: this.plotStart,
      plotFinish: this.plotFinish,
      viewportStart: this.settings.viewportStart,
      viewportFinish: this.settings.viewportFinish,
      viewportWidth: this.viewportWidth,
      printStart: this.settings.printStart,
      printFinish: this.settings.printFinish,
      wrapStart: this.wrapStart,
      wrapFinish: this.wrapFinish,
      nominalStart: this.settings.nominalStart,
      nominalFinish: this.settings.nominalFinish,
      boundaryStart: this.hAxisBoundaryStart,
      boundaryFinish: this.hAxisBoundaryFinish,
      evaluateNarrative: false,
      // desired width - may increase if required:
      groupsWidth: this.settings.groupsWidth,
      textMaxWidth: this.settings.textMaxWidth, // maxium width for single line of text for milestone or when placed outside of bar
      //textMinWidth: this.settings.textMinWidth, // minimum width before overlapping text is displayed outside bar
      taskMaxLines: this.settings.taskMaxLines,
      groupMaxLines: this.settings.groupMaxLines,
      fontSize: this.settings.fontSize,
      milestoneSymbolSize: this.settings.milestoneSymbolSize,
      taskPadding: this.settings.taskPadding,
      textPadding: this.settings.textPadding,
      groupTextPadding: this.settings.groupTextPadding,
      displayBaseline: this.settings.displayBaseline,
      baselineThreshold: this.settings.baselineThreshold,
      milestoneCategories: this.settings.milestoneCategories,
      coloursAvailable: this.settings.coloursAvailable,
      viewMode: this.viewMode,
      viewLines: this.settings.viewLines,
      displayEmptyGroups: this.settings.displayEmptyGroups,
      prependMilestoneDates: this.settings.prependMilestoneDates,
      viewTickLines: this.settings.viewTickLines,
      wrapTextOutsideBar: this.settings.wrapTextOutsideBar,
      wrapTextOutsideThreshold: this.settings.wrapTextOutsideThreshold,
      textLayout: this.settings.wrapTextOutsideBar ? TextLayout.AUTOFIT_TEXT_INSIDE_BARS : TextLayout.TEXT_ALWAYS_INSIDE_BARS
    };

  }

}

//function dateMin(...dates: Date[]): Date {
//  return new Date(Math.min(...dates.filter(d => d instanceof Date).map(d => d.getTime())));
//}
//function dateMax(...dates: Date[]): Date {
//  return new Date(Math.min(...dates.filter(d => d instanceof Date).map(d => d.getTime())));
//}
