import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { HierarchyNodeId, RevisionInfo, TreeProjectNode, TreeRevisionNode } from 'tp-traqplan-core/dist/data-structs';
import { State } from '../../state';
import * as appActions from '../../state/app/actions';
import * as poapActions from '../../state/poap/actions';
import * as modalActions from '../../state/modal/actions';

@Component({
  selector: 'app-label-revision',
  templateUrl: './label-revision.component.html',
  styleUrls: ['./label-revision.component.scss']
})
export class LabelRevisionComponent implements OnDestroy, OnInit {

  @Input() revision: RevisionInfo;

  public actions: ModalWindowAction[];

  public visible = true;

  public revisionForm = new FormGroup({
    name: new FormControl('')
  });

  private workspaceId: HierarchyNodeId;
  private viewId: HierarchyNodeId;

  private destroy$ = new Subject<void>();

  constructor(private store: Store<State>) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.actions = [
      {
        label: 'Cancel',
        type: 'neutral',
        cancel: true,
        escKey: true
      },
      {
        label: this.revision?.published ? 'Confirm' : 'Publish',
        type: 'positive',
        confirm: true,
        valid: () => (this.revision && (this.revision.published || this.revisionForm.valid)),
        enterKey: true,
      }
    ];
    this.store.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged((a, b) => a.account?.root?.selected?.id === b.account?.root?.selected?.id && a.poap?.viewSelected?.id === b.poap?.viewSelected?.id)
    ).subscribe((state) => {
      const { account, poap } = state;
      this.workspaceId = account?.root?.selected?.id;
      this.viewId = poap?.viewSelected?.id;
    });
  }

  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }

  onActionSelected(action: ModalWindowAction): void {
    if (!action.confirm || !this.revision || this.revisionForm.controls.name.value === this.revision.label) {
      return;
    }

    // if revision is already published then just re-label it,
    // else publish the revision
    if (this.revision.published) {
      this.store.dispatch(
        poapActions.labelRevision({
          revisionId: this.revision.id,
          label:  this.revisionForm.controls.name.value === ''? '': this.revisionForm.controls.name.value+' - '
        })
      );
    } else {
      this.store.dispatch(
        poapActions.publishDraft({
          revisionId: this.revision.id,
          label: this.revisionForm.controls.name.value === ''? '': this.revisionForm.controls.name.value+' - ',
          workspaceId: this.workspaceId,
          viewId: this.viewId,
        })
      );

      const { project, projectName } = <TreeRevisionNode>this.revision;
      this.store.dispatch(appActions.setDraftProject({ draftProject: <TreeProjectNode>{ id: project, name: projectName } }));
      this.store.dispatch(appActions.isRevisionCreating({ isRevisionCreating: true }));
      this.store.dispatch(appActions.showLoading());
      this.store.dispatch(appActions.isRevisionCreated({ isRevisionCreated: false }));
    }
  }
}
