import * as PowerBi from '../../../powerbi-data-structs';

import PricingPlan = PowerBi.PricingPlan;

export const key = 'powerbi.pricingPlans';

export interface State {
  pricingPlan: PricingPlan,
  pricingPlans: PricingPlan[]
}

export const initialState: State = {
  pricingPlan: null,
  pricingPlans: []
};

export function beforeSave(state) {
  return {
    ...state
  };
}

export function beforeLoad(state) {
  return state;
}
