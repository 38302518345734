<div class="button-box">
  <mat-slide-toggle color="primary" (change)="toggleCheckboxes($event)">{{showCheckboxes ? 'Hide' : 'Show'}} advanced
    options</mat-slide-toggle>

  <ng-container *ngIf="showCheckboxes">
    <button mat-raised-button color="primary" (click)="onSelectAll('draft')">Keep all draft values edited by
      you</button>

    <button mat-raised-button (click)="onSelectAll('latest')">Keep all {{conflict.latest.title}} values edited by
      {{authorName}}</button>
  </ng-container>
</div>

<table class="table">
  <tr class="tr-header">
    <td>Task ID & Task Name</td>

    <td>Resolved Field Name</td>

    <td>Value / Owner / Timestamp</td>
  </tr>

  <tr *ngFor="let change of changeList" [class]="change.cssClass">
    <td>
      <div>{{change.taskName}}</div>

      <div class="small">{{change.taskId}}</div>
    </td>

    <td>{{change.fieldName}}</td>

    <td>
      <div>
        <mat-checkbox *ngIf="showCheckboxes" color="primary" [checked]="change.options[0].checked"
          (change)="onSelectRow($event, change, change.options[0].resolved)">
          "<b>{{change.field === 'groupId' ? change.options[0].group?.displayName : change.field === 'milestoneCategory'
            ? change.options[0].milestone?.displayName : change.options[0].value}}</b>" set
          <b>{{change.options[0].version | dateFromNow }}</b> by <b>{{change.options[0].author?.name}}</b>
        </mat-checkbox>

        <ng-container *ngIf="!showCheckboxes">
          "<b>{{change.field === 'groupId' ? change.options[0].group?.displayName : change.field === 'milestoneCategory'
            ? change.options[0].milestone?.displayName : change.options[0].value}}</b>" set
          <b>{{change.options[0].version | dateFromNow }}</b> by <b>{{change.options[0].author?.name}}</b>
        </ng-container>
      </div>

      <div>
        <mat-checkbox *ngIf="showCheckboxes" style="visibility: hidden"></mat-checkbox>

        overrides
      </div>

      <div>
        <mat-checkbox *ngIf="showCheckboxes" color="primary" [checked]="change.options[1].checked"
          (change)="onSelectRow($event, change, change.options[1].resolved)">
          "<b>{{change.field === 'groupId' ? change.options[1].group?.displayName : change.field === 'milestoneCategory'
            ? change.options[1].milestone?.displayName : change.options[1].value}}</b>" set
          <b>{{change.options[1].version | dateFromNow }}</b> by <b>{{change.options[1].author?.name}}</b>
        </mat-checkbox>

        <ng-container *ngIf="!showCheckboxes">
          "<b>{{change.field === 'groupId' ? change.options[1].group?.displayName : change.field === 'milestoneCategory'
            ? change.options[1].milestone?.displayName : change.options[1].value}}</b>" set
          <b>{{change.options[1].version | dateFromNow }}</b> by <b>{{change.options[1].author?.name}}</b>
        </ng-container>
      </div>
    </td>
  </tr>
</table>
