<ng-container *ngIf="archived$ | async as archived">
  <div class="header"
    (click)="onToggle()"
  >
    <span class="header__icon fas">
      archive
    </span>
    <span class="header__text">
      Archive
    </span>
    <span class="header__expand fas">
      {{expanded ? 'chevron-down' : 'chevron-up'}}
    </span>
  </div>
  <div class="projects" *ngIf="expanded">
    <div
      class="tree__node"
      *ngFor="let node of archived"
    >

      <div class="node__header"
        [appDropMenu]="getActions"
        [dropMenuDatums]="[node]"
        (dropMenuAction)="actionsService.dispatchAction($event, node)"
      >

        <app-expand-icon
          [expanded]="node.expanded"
          (click)="onExpandProject(!node.expanded, node)"
        ></app-expand-icon>

        <input
          class="node__header__checkbox"
          type="checkbox"
          [ngModel]="node.selected"
          (click)="onSelectProject(!node.selected, node)"
        />

        <div class="node__header__label">
          {{node.name}} (archived {{node.archived | date:'dd-MM-yyyy'}})
        </div>

      </div>

      <div class="node__revisions" *ngIf="node.expanded">
        <div class="revision"
          [ngClass]="{ 'draft': !revision.published }"
          *ngFor="let revision of node.revisions"
        >

          <input
            class="revision__radio"
            [name]="revision.project"
            [checked]="revision.selected"
            type="radio"
            (click)="onSelectRevision(revision)"
          />

          <div class="fas draft-icon" *ngIf="!revision.published">
            pen
          </div>

          <div class="revision__label">
            {{formatRevisionString(node, revision)}}
          </div>

        </div>

        <div
          *ngIf="node.revisions.length >= 3"
          class="node__expand-revisions"
          (click)="onExpandRevisions(node)"
        >
          <span class="fas">{{node.revisionsExpanded ? 'angle-double-up' : 'ellipsis-h'}}</span>

        </div>

      </div>

    </div>
  </div>
</ng-container>
