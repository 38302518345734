import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { DisplayCounterComponent } from './display-counter/display-counter.component';

import { MaterialModule } from 'src/app/material.module';

import { IconButtonComponent } from './icon-button/icon-button.component';
import { ModalWindowComponent } from './modal-window/modal-window.component';
// primeng components
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonComponent } from './button/button.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { DropMenuDirective, DropMenuIconComponent } from './directives/drop-menu.directive';

@NgModule({
  declarations: [
    ModalWindowComponent,
    IconButtonComponent,
    ButtonComponent,
    SearchBarComponent,
    DropMenuDirective,
    DropMenuIconComponent,
    DisplayCounterComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    TooltipModule,
    FormsModule,
    MaterialModule
  ],
  exports: [
    LoaderComponent,
    DisplayCounterComponent,
    ModalWindowComponent,
    IconButtonComponent,
    ButtonComponent,
    SearchBarComponent,
    DropMenuDirective
  ],
})
export class CoreModule { }
