<ng-container *ngIf="groupings$ | async as groupings else loading">
  <ng-template
    [ngTemplateOutlet]="groupingTier"
    [ngTemplateOutletContext]="{ $implicit: groupings }"
  >
  </ng-template>
</ng-container>
<ng-container *ngIf="ungrouped$ | async as ungrouped">
  <ng-template
    [ngTemplateOutlet]="nodeList"
    [ngTemplateOutletContext]="{ $implicit: ungrouped }"
  >
  </ng-template>
</ng-container>

<ng-template #noTree>
  <div class="no-tree"></div>
</ng-template>

<ng-template #groupingTier let-groups>
  <div
    class="tree__group"
    *ngFor="let group of groups"
  >
    <div class="group__header"
      [ngClass]="{ 'group__header_highlight': group.meta?.containsDraft }"
      [appDropMenu]="getGroupActions"
      [dropMenuDatums]="[group, getHierarchyNode(group.value)]"
      (dropMenuAction)="actionsService.dispatchGroupAction($event, group, getHierarchyNode(group.value))"
    >
      <app-expand-icon
        [expanded]="group.expanded"
        (click)="onExpandGroup(!group.expanded, group)"
      ></app-expand-icon>
      <input
        class="group__header__checkbox"
        type="checkbox"
        [ngModel]="displayGroupingSelected(group)"
        (click)="onSelectGroup(group)"
      />

      <div *ngIf="group.icon">
        <div *ngIf="group.icon === 'book'; then matIcon else faIcon"></div>
      </div>
      <ng-template #matIcon>
        <mat-icon class="group-icon" aria-hidden="false" aria-label="">topic</mat-icon>
      </ng-template>

      <ng-template #faIcon>
        <div class="programme-icon fas" matTooltip="Pencil icon indicates you are editing at least one project within this programme. Drafts are private to you and not viewable by others until you publish" matTooltipClass="pen-tooltip" matTooltipPosition="right">{{group.icon}}</div>
      </ng-template>

      <div class="group__header__label">
        {{group.label}}
      </div>

    </div>

    <ng-container *ngIf="group.children && group.expanded">
      <ng-template
        [ngTemplateOutlet]="groupingTier"
        [ngTemplateOutletContext]="{ $implicit: group.children }"
      >
      </ng-template>
    </ng-container>

    <ng-container *ngIf="group.nodes && group.expanded">
      <ng-template
        [ngTemplateOutlet]="nodeList"
        [ngTemplateOutletContext]="{ $implicit: group.nodes }"
      >
      </ng-template>
    </ng-container>

  </div>
</ng-template>

<ng-template #nodeList let-nodes>
  <div
    class="tree__node"
    *ngFor="let node of nodes"
  >

    <ng-container *ngIf="node.revisions.length">

      <div class="node__header"
        [ngClass]="{ 'node__header_highlight': node.containsDraft, 'hover': !node.containsDraft }"
        [appDropMenu]="getActions"
        [dropMenuDatums]="[node]"
        (dropMenuAction)="actionsService.dispatchAction($event, node, null, selectedViewId)"
      >
        <app-expand-icon
          [expanded]="node.expanded"
          (click)="onExpandProject(!node.expanded, node)"
        ></app-expand-icon>

        <input
          class="node__header__checkbox"
          type="checkbox"
          [ngModel]="node.selected"
          (click)="onSelectProject(!node.selected, node)"
        />

        <div *ngIf="node.favourite" class="programme-icon fas">
          star
        </div>
        <div *ngIf="!node.favourite && node.containsDraft" class="programme-icon fas" matTooltip="Pencil icon indicates you are editing a draft of this project. Drafts are private to you and not viewable by others until you publish"
        matTooltipClass="pen-tooltip"
        matTooltipPosition="right">
          pen
        </div>

        <div class="node__header__label">
          <span>{{node.name}}</span>

          <span *ngIf="node.containsDraft">&nbsp;&nbsp;</span>

          <span *ngIf="node.containsDraft" class="btn btn-publish" (click)="onClickExtraButton(node)"
            matTooltip="Your changes are private to you. Publish them to create a version which will be visible to other users"
            matTooltipPosition="above">
            Publish
          </span>

          <ng-container *ngIf="!node.containsDraft || (!node.containsDraft && node.selected)">
            <span [ngClass]="{ 'hover': !node.selected }">&nbsp;&nbsp;</span>

            <span class="btn btn-edit" [ngClass]="{ 'hover': !node.selected }" (click)="onClickExtraButton(node)">Edit</span>
          </ng-container>
        </div>

      </div>

      <div class="node__revisions" *ngIf="node.expanded">
        <div class="revision"
          [ngClass]="{ 'draft': !revision.published, 'draft-readOnly': revision.readOnly, 'imported': revision.imported, 'baseline': node.baselineRevision === revision.id }"
          *ngFor="let revision of node.revisions"
          [appDropMenu]="getActions"
          [dropMenuDatums]="[node, revision]"
          (dropMenuAction)="actionsService.dispatchAction($event, node, revision, selectedViewId)"
        >

          <div class="revision__icons">

            <span
              *ngIf="revision.imported"
              id="icon-imported"
              [matTooltip]="getRevisionTooltip(revision)"
              matTooltipClass="tp-tooltip"
              matTooltipHideDelay="0"
              matTooltipPosition="right"
            >
              cloud-download-alt
            </span>

            <span *ngIf="node.baselineRevision === revision.id"
              id="icon-baseline"
              matTooltip="Remove baseline"
              matTooltipClass="tp-tooltip"
              matTooltipHideDelay="0"
              matTooltipPosition="right"
              (click)="onRemoveBaseline(node)"
            >
              exchange-alt
            </span>

          </div>

          <input
            class="revision__radio"
            [name]="revision.project"
            [checked]="revision.selected"
            type="radio"
            (click)="onSelectRevision(revision)"
          />

          <div class="fas draft-icon" *ngIf="!revision.published">
            pen
          </div>

          <div class="revision__label">
            {{formatRevisionString(node, revision)}}
          </div>

        </div>

        <div
          *ngIf="node.revisions.length >= 3"
          class="node__expand-revisions"
          (click)="onExpandRevisions(node)"
        >
          <span class="fas">{{node.revisionsExpanded ? 'angle-double-up' : 'ellipsis-h'}}</span>

        </div>

      </div>

    </ng-container>

  </div>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <p-progressSpinner styleClass="tp-spinner"></p-progressSpinner>

    <div class="description">Loading projects...</div>
  </div>
</ng-template>
