import { viewGroups } from 'tp-common/revisions';
import { PoapTask } from 'tp-traqplan-core/dist/data-structs';
import { MetaTaskField } from 'tp-traqplan-core/dist/workspace-structs';
import { standardFormat } from 'tp-traqplan-core/dist/date-format';

export const taskActions: { [key:string]: (task: PoapTask, oldValue: any, value:any) => string } = {};

taskActions[MetaTaskField.add] = function(): string {
  return 'Create task';
};

taskActions[MetaTaskField.indent] = function(task: PoapTask): string {
  return `Indent task '${task.name}'`;
};

taskActions[MetaTaskField.outdent] = function(task: PoapTask): string {
  return `Outdent task '${task.name}'`;
};

taskActions[MetaTaskField.moveUp] = function(task: PoapTask): string {
  return `Move task '${task.name}' up`;
};

taskActions[MetaTaskField.moveDown] = function(task: PoapTask): string {
  return `Move task '${task.name}' down`;
};

taskActions.start = function(task: PoapTask, oldValue: Date, value: Date): string {
  return `Change task '${task.name}' start to '${standardFormat(value)}'`;
};

taskActions.finish = function(task: PoapTask, oldValue: Date, value: Date): string {
  return `Change task '${task.name}' finish to '${standardFormat(value)}'`
};

taskActions.percentComplete = function(task: PoapTask, oldValue: number, value: number): string {
  return `Change task '${task.name}' percent complete to '${value}%'`;
};

taskActions.name = function(task: PoapTask, oldValue: string, value: string): string {
  return `Rename task '${oldValue}' to '${value}'`;
};

taskActions.groupPath = function(task: PoapTask, oldValue: string[], value: string[]): string {
  return `Move task '${task.name}' to group '${viewGroups.stringifyGroupPath(value)}'`;
};

taskActions.milestoneCategory = function(task: PoapTask, oldValue: string, value: string): string {
  return `Change task '${task.name}' milestone category to ${value}`;
};

taskActions.rename = function(task: PoapTask, oldValue: string, value: string): string {
  return `Set view name of '${task.name}' to '${value}'`
};

taskActions.selected = function(task: PoapTask, oldValue: boolean, value: boolean): string {
  return `${value ? 'Select' : 'Unselect'} task '${task.name}'`;
};

taskActions.colour = function(task: PoapTask, oldValue: string, value: string): string {
  return `Change task '${task.name}' colour to ${value}`;
};

taskActions.deleted = function(task: PoapTask): string {
  return `Delete task '${task.name}'`;
};
