import { createSVGElement } from './svg-utils';
import { colours as fillColours } from 'tp-common/revisions/task-constants';

function baseFactory(size: number) {
  const eSVG = createSVGElement('svg');
  eSVG.setAttribute('width', size.toString());
  eSVG.setAttribute('height', size.toString());
  eSVG.setAttribute('viewBox', '0 0 10 10');
  return eSVG;
}

const symbolFactories = {
  'diamond-thin': (s, f) => {
    const eSVG = baseFactory(s);
    const ePath = eSVG.appendChild(createSVGElement('path'));
    ePath.setAttribute('d', 'M0,-4.999999858901178L2.8867512644846856,0L0,4.999999858901178L-2.8867512644846856,0Z');
    ePath.setAttribute('fill', f || milestoneDefaultFill);
    ePath.setAttribute('transform', 'translate(5,5)');
    return eSVG;
  },
  'circle': (s, f) => {
    const eSVG = baseFactory(s);
    const ePath = eSVG.appendChild(createSVGElement('path'));
    ePath.setAttribute('d', 'M4.999999944459307,0A4.999999944459307,4.999999944459307,0,1,1,-4.999999944459307,0A4.999999944459307,4.999999944459307,0,1,1,4.999999944459307,0');
    ePath.setAttribute('fill', f || milestoneDefaultFill);
    ePath.setAttribute('transform', 'translate(5,5)');
    return eSVG;
  },
  'diamond-fat': (s, f) => {
    const eSVG = baseFactory(s);
    const ePath = eSVG.appendChild(createSVGElement('path'));
    ePath.setAttribute('d', 'M-3.5355348605107526,-3.5355348605107526h7.071069721021505v7.071069721021505h-7.071069721021505Z');
    ePath.setAttribute('fill', f || milestoneDefaultFill);
    ePath.setAttribute('transform', 'translate(5,5) rotate(45)');
    return eSVG;
  },
  'square': (s, f) => {
    const eSVG = baseFactory(s);
    const ePath = eSVG.appendChild(createSVGElement('path'));
    ePath.setAttribute('d', 'M-4.999999939365751,-4.999999939365751h9.999999878731503v9.999999878731503h-9.999999878731503Z');
    ePath.setAttribute('fill', f || milestoneDefaultFill);
    ePath.setAttribute('transform', 'translate(5,5)');
    return eSVG;
  },
  'cross': (s, f) => {
    const eSVG = baseFactory(s);
    const ePath = eSVG.appendChild(createSVGElement('path'));
    ePath.setAttribute('d', 'M-4.999999393468894,-1.6666664644896314L-1.6666664644896314,-1.6666664644896314L-1.6666664644896314,-4.999999393468894L1.6666664644896314,-4.999999393468894L1.6666664644896314,-1.6666664644896314L4.999999393468894,-1.6666664644896314L4.999999393468894,1.6666664644896314L1.6666664644896314,1.6666664644896314L1.6666664644896314,4.999999393468894L-1.6666664644896314,4.999999393468894L-1.6666664644896314,1.6666664644896314L-4.999999393468894,1.6666664644896314Z');
    ePath.setAttribute('fill', f || milestoneDefaultFill);
    ePath.setAttribute('transform', 'translate(5,5)');
    return eSVG;
  },
  'star': (s, f) => {
    const eSVG = baseFactory(s);
    const ePath = eSVG.appendChild(createSVGElement('path'));
    ePath.setAttribute('d', 'M0,-5.257311414526464L1.180339953356788,-1.624598571810071L5.000000278978285,-1.6245985718100715L1.9098301628107484,0.6205414363569103L3.0901701161675375,4.253254279073303L1.1102230246251565e-16,2.0081142709063213L-3.090170116167536,4.253254279073303L-1.9098301628107484,0.6205414363569104L-5.000000278978286,-1.6245985718100704L-1.1803399533567882,-1.624598571810071Z');
    ePath.setAttribute('fill', f || milestoneDefaultFill);
    ePath.setAttribute('transform', 'translate(5,5)');
    return eSVG;
  },
  'triangle-up': (s, f) => {
    const eSVG = baseFactory(s);
    const ePath = eSVG.appendChild(createSVGElement('path'));
    ePath.setAttribute('d', 'M0,-5.773502883329125L5.0000001657857265,2.8867514416645625L-5.0000001657857265,2.8867514416645625Z');
    ePath.setAttribute('fill', f || milestoneDefaultFill);
    ePath.setAttribute('transform', 'translate(5,5)');
    return eSVG;
  },
  'triangle-down': (s, f) => {
    const eSVG = baseFactory(s);
    const ePath = eSVG.appendChild(createSVGElement('path'));
    ePath.setAttribute('d', 'M0,-5.773502430431771L4.999999773565112,2.8867512152158854L-4.999999773565112,2.8867512152158854Z');
    ePath.setAttribute('fill', f || milestoneDefaultFill);
    ePath.setAttribute('transform', 'translate(5,5) rotate(180)');
    return eSVG;
  },
  'wye': (s, f) => {
    const eSVG = baseFactory(s);
    const ePath = eSVG.appendChild(createSVGElement('path'));
    ePath.setAttribute('d', 'M1.8301269639511617,1.056624295288396L1.8301269639511617,4.71687822319072L-1.8301269639511617,4.71687822319072L-1.830126963951162,1.0566242952883957L-4.99999984981635,-0.7735026686627662L-3.1698728858651877,-3.9433755545279534L1.1102230246251565e-16,-2.1132485905767915L3.1698728858651877,-3.9433755545279534L4.99999984981635,-0.7735026686627662Z');
    ePath.setAttribute('fill', f || milestoneDefaultFill);
    ePath.setAttribute('transform', 'translate(5,5)');
    return eSVG;
  }
};

export const milestoneDefaultSymbol = 'diamond-fat';

export const milestoneDefaultFill = 'Black';

export function generateSVGSymbol(symbol, boundarySize, fill) {
  const useSymbol = symbolFactories[symbol] ? symbol : milestoneDefaultSymbol;
  const useFill = isHex(fill) ? fill : (fillColours.has(fill) ? fillColours.get(fill) : milestoneDefaultFill);
  return symbolFactories[useSymbol](boundarySize, useFill);
}

function isHex(str) {
  return /^#?[a-fA-F0-9]{6,8}$/.test(str);
}
