<app-modal-window
  header="Create A Workspace"
  [actions]="actions"
  (actionSelected)="onActionSelected($event)"
  [(visible)]="visible"
  (closed)="onClose()"
>

  <form
    action=""
    class="tp-form"
    [formGroup]="form"
  >

    <div class="tp-form__message">
      <span>
        A workspace is a secure and separate area where you can create multiple projects and plans.
        <br/>
        The workspace name can be anything, however we recommend the name of your workspace
        reflects the set of projects or people who will be working in that area. For example you might call
        it “Major Projects” or “My Personal Space”
      </span>
    </div>

    <div class="tp-form__field">
      <label for="workspace">Name:</label>
      <input
        pInputText
        type="text"
        name="workspace"
        formControlName="workspace"
        autocomplete="traqplan-workspace"
        required
      />
    </div>

    <div class="tp-form__error" *ngIf="workspace.touched && workspace.errors?.required" >
      <span *ngIf="workspace.errors?.required">Workspace name is required</span>
    </div>

    <div
      [ngClass]="{ 'tp-form__error': workspace.errors?.notAvailable, 'tp-form__message': !workspace.errors?.notAvailable }"
      *ngIf="workspace.value"
    >
      <span>This name is {{ workspace.errors?.notAvailable ? 'taken' : 'available' }}</span>
    </div>

  </form>

</app-modal-window>
