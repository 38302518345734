<app-modal-window
  header="Workspace Settings"
  [actions]="actions"
  (actionSelected)="onActionSelected($event)"
  [(visible)]="visible"
  (closed)="onClose()"
>
  <form
    *ngIf="root"
    class="tp-form"
    action=""
    [formGroup]="form"
  >
    <div class="tp-form__field">
      <div>Name</div>
      <input
        type="text"
        pInputText
        formControlName="name"
        [ngModel]="root.name"
      />
    </div>

    <div
      class="tp-form__error"
      *ngIf="name.touched && name.invalid"
    >
      <span *ngIf="name.errors?.required">Workspace name is required</span>

      <span *ngIf="name.errors?.invalidName">Name can contain only a-z, A-Z, 0-9 and _</span>

      <span *ngIf="name.errors?.namingConflict">This name is taken</span>
    </div>

    <div
      class="tp-form__message"
      *ngIf="!name.invalid && root.name !== name.value"
    >
      <span>This name is {{ loading ? '...' : nameAvailable ? 'available' : 'taken' }}</span>
    </div>

    <div class="tp-form__field">
      <div>Allow Imported Project Edits</div>
      <p-inputSwitch
        formControlName="allowImportedEdits"
        [ngModel]="root.allowImportedEdits"
      ></p-inputSwitch>
    </div>
  </form>
</app-modal-window>
