import { createReducer, on } from '@ngrx/store';
import { initialState } from './state';
import * as poapActions from './actions';
import { dsReceived, dsPending } from '../helpers';

export const reducer = createReducer(
  initialState,

  on(poapActions.reset, (state, props) => {
    return { ...initialState, ...props };
  }),

  on(poapActions.requestRevisions, (state) => {
    return {
      ...state,
      revisions: dsPending(state.revisions.value)
    }
  }),

  on(poapActions.resetSelectedRevisions, (state) => {
    return {
      ...state,
      selectedRevisions: undefined
    }
  }),

  on(poapActions.requestRevisionsSuccess, (state, props) => {
    // if view selection were to somehow change before reponse loaded then don't update the state:
    if (props.view !== state.viewSelected?.id) {
      return state;
    }
    const dict = {};
    for (const rev of props.revisions) {
      dict[rev.id] = rev;
    }
    return {
      ...state,
      revisions: dsReceived({
        ...state.revisions.value,
        ...dict
      })
    }
  }),

  on(poapActions.requestViews, (state) => {
    return {
      ...state,
      views: dsPending(state.views.value)
    }
  }),

  on(poapActions.requestViewsSuccess, (state, props) => {
    return {
      ...state,
      views: dsReceived(props.views),
    };
  }),

  on(poapActions.selectView, (state, props) => {
    return {
      ...state,
      milestones: dsPending(state.milestones.value),
      viewLines: dsPending(state.viewLines.value),
      viewSelected: props.view,
      selectedRevisions: props.selectedRevisions
    };
  }),

  on(poapActions.requestMilestones, (state) => {
    return {
      ...state,
      milestones: dsPending(state.milestones.value)
    };
  }),

  on(poapActions.requestMilestonesSuccess, (state, props) => {
    return {
      ...state,
      milestones: dsReceived(props.milestones)
    };
  }),

  on(poapActions.requestViewLinesSuccess, (state, props) => {
    return {
      ...state,
      viewLines: dsReceived(props.viewLines)
    };
  }),

  on(poapActions.updateDraft, (state, props) => {
    const revision = state.revisions.value[props.revisionId];
    const tasks = props.tasks.filter(t => !t.deleted);
    const groups = new Map(Object.entries(props.groups));

    for (const group of Object.values(props.groups)) {
      if (group.deleted) {
        groups.delete(group.id);
      }
    }

    return {
      ...state,
      revisions: dsReceived({
        ...state.revisions.value,
        [props.revisionId]: {
          ...revision,
          pointer: null,
          tasks: tasks,
          groups: [...groups.entries()].reduce((a, [k, v]) => ({ ...a, [k]: v }), {})
        }
      }),
      conflicts: dsPending({
        ...state.conflicts.value
      })
    };
  }),

  /*on(poapActions.bulkUpdateDraft, (state, props) => {
    const { revisionId, poapUpdates } = props;
    const revision = state.revisions.value[revisionId];
    const updatedTasks: any = poapUpdates.map(({ update }) => {
      if (update && Array.isArray(update.tasks)) {
        const task = update.tasks[0];
        return [task.id, task];
      }

      return [];
    }).filter(([key, value]) => key && value);
    const updatedTaskMap: Map<string, PoapTask> = new Map(updatedTasks);
    const tasks = revision.tasks.map((task) => {
      return updatedTaskMap.get(task.id) || task;
    });

    return {
      ...state,
      revisions: dsReceived({
        ...state.revisions.value,
        [revisionId]: {
          ...revision,
          pointer: null,
          tasks
        }
      }),
      conflicts: dsPending({
        ...state.conflicts.value
      })
    };
  }),*/

  on(poapActions.publishDraftConflict, (state, props) => {
    return {
      ...state,
      conflicts: dsReceived({
        ...state.conflicts.value,
        [props.revisionId]: props.conflict
      })
    };
  }),

  on(poapActions.publishDraftSuccess, (state, { revisionId }) => {

    const conflicts = Object.entries(state.conflicts?.value || {})
      .filter(([k]) => k !== revisionId)
      .reduce((a, [k, v]) => ({ ...a, [k]: v }), {});

    return {
      ...state,
      conflicts: dsReceived(conflicts)
    };

  }),

  on(poapActions.requestUndoStack, (state) => {
    return {
      ...state,
      history: dsPending(state.history.value)
    };
  }),

  on(poapActions.requestUndoStackSuccess, (state, { history }) => {
    return {
      ...state,
      history: dsReceived({
        ...state.history.value,
        ...history
      })
    };
  }),

  on(poapActions.requestBaselines, (state) => {
    return {
      ...state,
      baselines: {
        ...state.baselines,
        available: dsPending(state.baselines.available.value),
        revisions: dsPending(state.baselines.revisions.value)
      }
    };
  }),

  on(poapActions.requestBaselinesSuccess, (state, { baselines }) => {
    // if baseline selected then update it (and ensure it still exists):
    const selected = baselines.find(b => b.id === state.baselines.selected)?.id || null;
    return {
      ...state,
      baselines: {
        ...state.baselines,
        available: dsReceived(baselines),
        selected: selected
      }
    };
  }),

  on(poapActions.selectBaseline, (state, { baselineId }) => {
    return {
      ...state,
      baselines: {
        ...state.baselines,
        selected: baselineId,
        revisions: dsPending(state.baselines.revisions.value)
      }
    };
  }),

  on(poapActions.selectBaselineSuccess, (state, { baselineId, baselineRevisions }) => {
    const selected = state.baselines.selected;
    if (selected !== baselineId) {
      return state;
    }
    return {
      ...state,
      baselines: {
        ...state.baselines,
        revisions: dsReceived(baselineRevisions)
      }
    };
  }),

  on(poapActions.createBaseline, (state) => {
    return {
      ...state,
      baselines: {
        ...state.baselines,
        available: dsPending(null),
      }
    };
  }),

  on(poapActions.updateTimelineSettings, (state, { settings }) => {
    return {
      ...state,
      timelineSettings: {
        ...state.timelineSettings,
        ...settings
      }
    };
  }),

  on(poapActions.clearSelectedGroupOutlineLevel, (state, { project }) => {
    const { groupOutlineLevelByProject } = state;

    if (!Array.isArray(groupOutlineLevelByProject) && typeof groupOutlineLevelByProject === 'object') {
      const golbp = {};
      Object.entries(groupOutlineLevelByProject).forEach(([k, v]) => {
        if (k !== project) {
          golbp[k] = v;
        }
      });
      state.groupOutlineLevelByProject = golbp;
    }

    return {
      ...state
    }
  }),

  on(poapActions.setSelectedGroupOutlineLevel, (state, { project, outlineLevel }) => {
    const { groupOutlineLevelByProject } = state;
    groupOutlineLevelByProject[project] = outlineLevel;
    return {
      ...state,
      groupOutlineLevelByProject: { ...groupOutlineLevelByProject }
    }
  }),

  on(poapActions.clearSelectedTaskOutlineLevels, (state, { project }) => {
    const { taskOutlineLevelsByProject } = state;

    if (!Array.isArray(taskOutlineLevelsByProject) && typeof taskOutlineLevelsByProject === 'object') {
      const tolbp = {};
      Object.entries(taskOutlineLevelsByProject).forEach(([k, v]) => {
        if (k !== project) {
          tolbp[k] = v;
        }
      });
      state.taskOutlineLevelsByProject = tolbp;
    }

    return {
      ...state
    }
  }),

  on(poapActions.setSelectedTaskOutlineLevels, (state, { project, outlineLevels }) => {
    const { taskOutlineLevelsByProject } = state;
    taskOutlineLevelsByProject[project] = outlineLevels;
    return {
      ...state,
      taskOutlineLevelsByProject: { ...taskOutlineLevelsByProject }
    }
  })

);
