
type uuidV4 = string;

export type AccountId = uuidV4;
export type UserId = uuidV4;
export type LicenseId = uuidV4;
export type HierarchyNodeId = uuidV4;
export type RevisionId = uuidV4;
export type BaselineId = uuidV4;
export type TaskId = uuidV4;
export type ViewGroupId = uuidV4;
export type MilestoneId = uuidV4;
export type ViewId = uuidV4;
export type StaffId = uuidV4;
export type PricingId = uuidV4;
export type PricingPlanId = uuidV4;
export type SubscriptionId = uuidV4;
export type SubscriptionExtensionId = uuidV4;
export type TncDocumentId = uuidV4;
export type UserTncAcceptanceId = uuidV4;

export type PermissionType = number;

export interface AccountInfo {
  id: AccountId;
  author: UserId;
  name: string;
  hierarchyLabels: string[];
  created: Date;
  deleted: Date | null;
  approved: boolean;
  userCount: number;
  license: License;
  // any global access value that the user has been assigned:
  access: number;
}

export interface User {
  id: UserId;
  email: string;
  emailVerified: boolean;
  name: string;
  lastLoginTime: Date;
  created: Date;
  deleted: Date;
  activated: Date;
  access?: number;
  pending: boolean;
}

export interface License {
  id: LicenseId;
  assignee: UserId;
  type: number;
  created: Date;
  deactivated: Date;
}

export interface LicenseInfo {
  license: License;
  user: User;
}

export interface HierarchyNode {
  id: HierarchyNodeId;
  author: UserId;
  name: string;
  created: Date;
  children: HierarchyNodeId[];
  parent: HierarchyNodeId;
  revisions: RevisionInfo[];
  allowImportedEdits: boolean;
  archived: Date;
  archivedBy: UserId | null;
  meta: any;
  access: PermissionType;
  favourite?: boolean;
  baselineRevision?: RevisionId|null;
  
}

export interface HierarchyAccessListNode {
  id: HierarchyNodeId;
  author: UserId;
  name: string;
  created: Date;
  children: HierarchyNodeId[];
  parent?: HierarchyNodeId;
  parentName?: string;
  grandParent?: HierarchyNodeId;
  grandParentName?: string;
  allowImportedEdits?: boolean;
  archived?: Date;
  archivedBy?: UserId;
  meta: any;
  nodeType: string;
  path: HierarchyNodeId[];
  userId: UserId;
  userEmail: string;
  userName: string;
  userLicense: number;
  permission: PermissionType;
  baselineRevision?: RevisionId|null;
}

export interface RevisionInfo {
  id: RevisionId;
  project: HierarchyNodeId;
  view: ViewId;
  createdView: ViewId;
  author: UserId;
  published: Date;
  created: Date;
  createdFrom: RevisionId;
  pointer: Date;
  imported: boolean;
  startDate: Date;
  finishDate: Date;
  label: string;
  projectVersion: number;
  viewVersion: number;
}

export interface ViewGroupMap {
  [key: string]: ViewGroup;
}

export interface RevisionData extends RevisionInfo {
  tasks: PoapTask[];
  groupRoot: ViewGroupId;
  groups: ViewGroupMap;
  viewPointer: Date;
}

export interface TreeRevisionNode extends RevisionInfo {
  parentNode: TreeProjectNode;
  projectName: string;
  selected: boolean;
  readOnly?: boolean;
}

export interface TreeProjectNode {
  id: HierarchyNodeId;
  author: UserId;
  authorName: string;
  name: string;
  created: Date;
  parent: HierarchyNodeId;
  parentName: string;
  // these will actually all be the same root (also known as a workspace):
  root: HierarchyNodeId;
  revisions: TreeRevisionNode[] | null;
  // this will always be the same value within a given workspace:
  allowImportedEdits: boolean;
  archived: Date;
  archivedBy: UserId;
  permission: PermissionType;
  expanded: boolean;
  revisionsExpanded: boolean;
  containsDraft: boolean;
  selected: boolean;
  favourite: boolean;
  meta: any;
  baselineRevision?: RevisionId|null;
}

export interface TreeGrouping {
  grouping: string;
  label: string;
  value: string;
  nodes?: TreeProjectNode[];
  parent?: TreeGrouping;
  children?: Map<string, TreeGrouping>;
  expanded: boolean;
  icon?: string;
  meta?: { [key: string]: any };
}

export interface PoapTask {
  id: TaskId;
  importedId: string;
  project: HierarchyNodeId;
  revision: RevisionId;
  view: ViewId;
  published: Date;
  author: UserId;
  outlineNumber: number[];
  path: HierarchyNodeId[];
  milestone: boolean;
  start: Date;
  finish: Date;
  percentComplete: number;
  name: string;
  group: ViewGroupId;
  // array of group names (not ids!):
  groupPath: string[];
  milestoneCategory: MilestoneId;
  rename: string;
  selected: boolean;
  colour: string;
  textColour?: string;
  deleted: boolean;
  baselineStart?: Date;
  baselineFinish?: Date;
  readOnly?: boolean;
}

export interface ViewTask {
  id: TaskId;
  project: HierarchyNodeId;
  revision: RevisionId;
  groupId: ViewGroupId;
  milestoneCategory: MilestoneId;
  view: ViewId;
  version: Date;
  hash: string;
  action: string;
  name: string;
  selected: boolean;
  colour: string;
}

export interface ProjTask {
  id: TaskId;
  importedId: string;
  project: HierarchyNodeId;
  revision: RevisionId;
  version: Date;
  action: string;
  hash: string;
  start: Date;
  finish: Date;
  percentComplete: number;
  name: string;
  outlineNumber: number[];
  milestone: boolean;
  deleted: boolean;
}

export interface ViewGroup {
  id: ViewGroupId;
  project: HierarchyNodeId;
  revision: RevisionId;
  version: Date;
  view: ViewId;
  action: string;
  isProjectRoot: boolean;
  children: ViewGroupId[];
  parent: ViewGroupId;
  name: string;
  narrative: string;
  drawingLayout?: DrawingLayout;
  deleted: boolean;
}

export interface ViewGroup2 extends ViewGroup {
  outlineNumberName: string;
}

export interface TimelineGroup extends ViewGroup {
  // selected tasks only:
  tasks?: PoapTask[];
  // all tasks, selected or unselected:
  allTasks?: PoapTask[];
}

export interface GroupTreeNode extends Omit<TimelineGroup, 'children'> {
  children: GroupTreeNode[];
  drawingLayout: DrawingLayout;
}

export const SYM_GROUP_ROOTS = Symbol('array of root groups');
export const SYM_GROUP_ROOT_ORDER = Symbol('Group map root group order');

// map of timeline swimlanes (groups)
export interface TimelineGroupMap {
  // key is ViewGroupId
  [key: string]: TimelineGroup;
  [SYM_GROUP_ROOTS]: TimelineGroup[];
  [SYM_GROUP_ROOT_ORDER]: string[];
}

export interface Baseline {
  id: BaselineId;
  author: UserId;
  label: string;
}

export interface BaselineRevision {
  baseline: BaselineId;
  project: HierarchyNodeId;
  revision: RevisionId;
}

export interface UndoAction {
  action: string;
  version: Date;
  valid?: boolean;
  error?: any;
  params?: any;
  batchId?: any;
  cssClass?: any;
}

export interface Conflict {
  field: string;
  newValue: any;
  oldValue: any;
}

export interface RevisionConflict {
  authors: { [key: string]: SafeUser };
  groups: { [key: string]: RevisionConflictViewGroup };
  milestones: { [key: string]: MilestoneCategory };
  draft: RevisionConflictInfo;
  latest: RevisionConflictInfo;
  latestViewData?: RevisionConflictInfo;
  project: RevisionConflictHierarchyNode;
  task: RevisionConflictResult<RevisionConflictTask>;
  isProjectDataChanged: boolean;
  viewTask: RevisionConflictResult<RevisionConflictViewTask>;
  isViewDataChanged: boolean;
}
export interface RevisionConflictViewGroup extends ViewGroup {
  hash: string;
  title?: string;
}
export interface RevisionConflictInfo extends Omit<RevisionInfo, 'createdView'> {
  title?: string;
}
export declare type RevisionConflictHierarchyNode = Omit<HierarchyNode, 'access' | 'favourite' | 'revisions'>;
export interface RevisionConflictResult<T> {
  differences: { [key: string]: T };
  count: RevisionConflictResultCount;
  isChanged: boolean;
}
export interface RevisionConflictTask {
  id: TaskId;
  start: RevisionConflictDifference<Date>;
  finish: RevisionConflictDifference<Date>;
  percentComplete: RevisionConflictDifference<number>;
  name: RevisionConflictDifference<string>;
  outlineNumber: RevisionConflictDifference<number[]>;
  deleted: RevisionConflictDifference<boolean>;
  count: RevisionConflictResultCount;
  status: string;
}
export interface RevisionConflictViewTask {
  id: TaskId;
  groupId: RevisionConflictDifference<ViewGroupId>;
  milestoneCategory: RevisionConflictDifference<MilestoneId>;
  name: RevisionConflictDifference<string>;
  selected: RevisionConflictDifference<boolean>;
  colour: RevisionConflictDifference<string>;
  count: RevisionConflictResultCount;
  status: string;
}
export interface RevisionConflictResultCount {
  added: number;
  changed: number;
  removed: number;
  unchanged: number;
}
export interface RevisionConflictDifference<T> {
  current?: RevisionConflictValue<T>;
  draft?: RevisionConflictValue<T>;
  latest?: RevisionConflictValue<T>;
  resolved: string;
  status: string;
}
export interface RevisionConflictValue<T> {
  value: T;
  version: Date;
}

export interface SafeUser {
  id: UserId;
  email: string;
  name: string;
  alias?: string;
  image?: string;
}

export interface View {
  id: ViewId;
  author: UserId;
  version: Date;
  action: string;
  name: string;
  type: string;
  projectOrder: HierarchyNodeId[];
  exportTitle: string;
  defaultMilestone: MilestoneId;
  deleted: boolean;
  workspace_ids: string[];
}

export interface ViewLine {
  id: string;
  view: ViewId;
  author: UserId;
  version: Date;
  action: string;
  title: string;
  date: Date;
  style: string;
  colour: string;
  visible: boolean;
  deleted: boolean;
}

export interface MilestoneCategory {
  id: MilestoneId;
  view: ViewId;
  author: UserId;
  version: Date;
  action: string;
  name: string;
  description: string;
  colour: string;
  textColour?: string;
  symbol: string;
  locked: boolean;
  deleted: boolean;
}

export interface PoapRowSelection {
  id: string;
  task: PoapTask;
  project: HierarchyNode;
  revision: RevisionData;
  fields: string[];
}

export enum PoapExportBreakMode {
  Single,
  Multi
}

export interface PoapExportSettings {
  title: string;
  date: Date;
  logo: string;
  format: string;
  ratio: string;
  orientation: string;
  resolution: number;
  fontSize: number;
  /* group UIDs: */
  pageBreaks: string[];
  breakMode: PoapExportBreakMode;
  legendFittingMode: LegendFittingMode;
  legendPosition: LegendPosition;
}

export interface Staff {
  id: StaffId;
  name: string;
  email: string;
}

export interface ImportJob {
  id: string;
  userId: string;
  status: string;
  projectName: string;
  // this is the imported id, not the traqplan id:
  projectId: uuidV4;
  programmeId: uuidV4;
  workspaceId: uuidV4;
  previouslyImported: boolean;
  filename: string;
  workspace: any;
  programme: any;
  tasks: string;
  account: string;
  created: Date;
}

export interface AppConstant {
  id: string;
  type: string;
  name: string;
  int_value: number;
  str_value: string;
  sort: number;
}

export interface Pricing {
  id: PricingPlanId;
  sku: string;
  license_type: number;
  license_type_name: string;
  license_type_code: string;
  min_quantity: number;
  unit_price_month: string;
  unit_price_year: string;
  price_per_month: string;
  price_per_year: string;
  currency: string;
  currency_symbol: string;
  billing_interval: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

/**
 * The PricingPlan interface combines the pricing plan information with data obout each of the individual
 * services which can be included in the plan i.e timeline, risk register etc
 */
export interface PricingPlan {
  id: PricingPlanId;
  plan_name: string;
  plan_description: string;
  plan_type: number;
  plan_type_name: string;
  plan_type_code: string;
  paypal_plan_id: string;
  currency: string;
  currency_symbol: string;
  billing_interval: string;
  discount_per_month: string;
  discount_per_year: string;
  // the individual service plans (timeline, risk register etc) that can be added/removed to the pricing plan:
  pricing_ids: string[];
  // following array values contain information about each service (index corresponds to index of id):
  skus: string[];
  license_types: number[];
  license_type_names: string[];
  license_type_codes: string[];
  min_quantities: number[];
  unit_price_months: string[];
  unit_price_years: string[];
  price_per_months: string[];
  price_per_years: string[];
  net_price_per_months: string[];
  net_price_per_years: string[];
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export interface Subscription {
  id: SubscriptionId;
  user_id: UserId;
  // user_email: string;
  // user_name: string;
  // user_alias: string;
  organization_id: AccountId;
  // organization_name: string;
  // pricing_plan_id: PricingPlanId;
  // paypal_plan_id: string;
  // paypal_order_id: string;
  // paypal_subscription_id: string;
  // plan_name: string;
  // plan_description: string;
  // plan_type: number;
  // plan_type_name: string;
  // plan_type_code: string;
  quantity: number;
  // price: string;
  // discount: string;
  // net_price: string;
  // currency: string;
  // currency_symbol: string;
  // billing_interval: string;
  // pricing_ids: string[];
  // skus: string[];
  // license_types: number[];
  // license_type_names: string[];
  // license_type_codes: string[];
  // min_quantities: number[];
  // unit_price_months: string[];
  // unit_price_years: string[];
  // price_per_months: string[];
  // price_per_years: string[];
  // upgraded_to: SubscriptionId;
  is_active: boolean;
  subscribed_at: Date;
  expired_at: Date;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  trial: boolean;
  services: string[];
  verified_at: Date;
  stripe_customer_id: string;
  stripe_subscription_id: string;
  stripe_subscription_status: string;
}

export interface SubscriptionExtension {
  id: SubscriptionExtensionId;
  subscription_id: SubscriptionId;
  user_id: UserId;
  organization_id: AccountId;
  pricing_plan_id: PricingPlanId;
  paypal_plan_id: string;
  paypal_order_id: string;
  paypal_subscription_id: string;
  plan_name: string;
  plan_description: string;
  plan_type: number;
  plan_type_name: string;
  plan_type_code: string;
  price_per_month: string;
  price_per_year: string;
  currency: string;
  currency_symbol: string;
  billing_interval: string;
  pricing_ids: string[];
  skus: string[];
  license_types: number[];
  license_type_names: string[];
  license_type_codes: string[];
  min_quantities: number[];
  unit_price_months: string[];
  unit_price_years: string[];
  price_per_months: string[];
  price_per_years: string[];
  is_active: boolean;
  subscribed_at: Date;
  expired_at: Date;
  approved_at: Date;
  rejected_at: Date;
  created_at: Date;
  deleted_at: Date;
}

export interface TncDocument {
  id: TncDocumentId;
  description: string;
  version: string;
  file_url: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export interface UserTncAcceptance {
  id: UserTncAcceptanceId;
  tnc_document_id: TncDocumentId;
  user_id: UserId;
  user_name: string;
  user_email: string;
  acceptance: number;
  tnc_version: string;
  tnc_file_url: string;
  created_at: Date;
  updated_at: Date;
}

export enum DrawingLayout {
  GANTT,
  MILESTONES_AND_BARS,
  MILESTONES_ABOVE_BARS,
  BARS_ABOVE_MILESTONES,
  MILESTONES_ONLY,
  MILESTONES_AS_PHASES,
  WATERFALL
}

export enum TextLayout {
  AUTOFIT_TEXT_INSIDE_BARS,
  TEXT_ALWAYS_OUTSIDE_BARS,
  TEXT_ALWAYS_INSIDE_BARS
}

export enum LegendPosition {
  top = 'top',
  bottom = 'bottom'
}

export enum LegendFittingMode {
  compressed = 'compressed',
  grid = 'grid'
}

export interface Invitation {
  id: string;
  author: string;
  authorEmail: string;
  authorName: string;
  account: string;
  inviteeEmail: string;
  inviteeName: string;
  invitedAt: Date;
  license?: string;
  expiry: Date;
}
