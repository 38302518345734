import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UserTncAcceptanceApiService } from '../../api/user-tnc-acceptance-api.service';
import { RequestError } from '../../api/structs';
import * as userTncAcceptancesActions from './actions';

@Injectable()
export class UserTncAcceptanceEffects {

  constructor(
    private actions$: Actions,
    private userTncAcceptanceApi: UserTncAcceptanceApiService
  ) {}

  staffRequestUserTncAcceptances$ = createEffect(() => this.actions$.pipe(
    ofType(userTncAcceptancesActions.staffRequestUserTncAcceptances),
    switchMap((): Observable<Action> => {
      return this.userTncAcceptanceApi.staffRequestUserTncAcceptances().pipe(
        switchMap(({ userTncAcceptances }: any) => [
          userTncAcceptancesActions.staffRequestUserTncAcceptancesSuccess(<any>{ userTncAcceptances })
        ]),
        catchError((error: RequestError) => {
          return of(userTncAcceptancesActions.staffRequestUserTncAcceptancesFailure({ error }))
        })
      );
    })
  ));

  staffRequestUserTncAcceptanceById$ = createEffect(() => this.actions$.pipe(
    ofType(userTncAcceptancesActions.staffRequestUserTncAcceptanceById),
    switchMap(({ id }): Observable<Action> => {
      return this.userTncAcceptanceApi.staffRequestUserTncAcceptanceById(id).pipe(
        switchMap((userTncAcceptance) => [
          userTncAcceptancesActions.staffRequestUserTncAcceptanceByIdSuccess(<any>{ userTncAcceptance })
        ]),
        catchError((error: RequestError) => {
          return of(userTncAcceptancesActions.staffRequestUserTncAcceptanceByIdFailure({ error }))
        })
      );
    })
  ));

}
