import { Component, ChangeDetectionStrategy, HostBinding, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { HierarchyNode, TreeProjectNode, TreeRevisionNode, User, View } from 'tp-traqplan-core/dist/data-structs';
import { formatRevisionLabel } from '../../../label-format';
import { State } from '../../../state';
import * as projectsActions from '../../../state/projects/actions';
import { ProjectsService } from '../../../state/selectors/projects.service';
import { ProjectTreeActionsService } from '../project-tree-actions.service';

@Component({
  selector: 'app-archive-panel',
  templateUrl: './archive-panel.component.html',
  styleUrls: ['./archive-panel.component.scss', '../style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArchivePanelComponent implements OnDestroy {

  @HostBinding('class.expanded') expanded: boolean = false;

  destroy$ = new Subject<void>();
  archived$: Observable<TreeProjectNode[]> = this.projectsService.tree$.pipe(
    takeUntil(this.destroy$),
    map(({ archived }) => {
      const arch: any = archived.sort((a, b): any => {
        const dateB = new Date(b.archived);
        const dateA = new Date(a.archived);
        return dateB.getTime() - dateA.getTime();
      })
      return arch
    })
  );

  getActions = this.actionsService.getActionsAvailable.bind(this.actionsService);
  hierarchyNodes: HierarchyNode[];
  userList: { [key: string]: User } = {};
  views: View[];
  selectedView: View;

  constructor(
    private store: Store<State>, private projectsService: ProjectsService, public actionsService: ProjectTreeActionsService
  ) {
    this.store.pipe(
      takeUntil(this.destroy$),
      tap(state => {
        this.hierarchyNodes = state.projects.hierarchy.value || [];
        this.userList = state.account.users;
        this.views = state?.poap?.views?.value;
        this.selectedView = state?.poap?.viewSelected;
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onToggle(): void {
    this.expanded = !this.expanded;
  }

  onExpandProject(expand: boolean, project: TreeProjectNode): void {
    this.store.dispatch(
      projectsActions.expandProjectTreeNode({ expand, project })
    );
  }

  onExpandRevisions(project: TreeProjectNode): void {
    this.store.dispatch(
      projectsActions.expandProjectRevisions({ expand: !project.revisionsExpanded, project })
    );
  }

  onSelectProject(select: boolean, project: TreeProjectNode): void {
    // if deselecting then get selected revisions, else get latest:
    const revisions = select ? [project?.revisions[0]] : project?.revisions.filter(rev => rev.selected);

    this.store.dispatch(
      projectsActions.selectProjectTreeRevisions({ select, revisions })
    );
  }

  onSelectRevision(revision: TreeRevisionNode): void {
    this.store.dispatch(
      projectsActions.selectProjectTreeRevisions({ select: true, revisions: [revision] })
    );
  }

  formatRevisionString(node: TreeProjectNode, revision: TreeRevisionNode): string {
    return formatRevisionLabel({
      node, revision, user: this.userList[revision?.author], view: this.selectedView, views: this.views, nodes: this.hierarchyNodes
    });
  }
}
