import { createAction, props } from '@ngrx/store';
import { RevisionInfo, TreeProjectNode } from 'tp-traqplan-core/dist/data-structs';

export const isDraftCreated = createAction(
  '[app] isDraftCreated',
  props<{ isDraftCreated: boolean }>()
);

export const isDraftCreating = createAction(
  '[app] isDraftCreating',
  props<{ isDraftCreating: boolean }>()
);

export const setDraftProject = createAction(
  '[app] setDraftProject',
  props<{ draftProject?: TreeProjectNode; }>()
);

export const isProjectCreated = createAction(
  '[app] isProjectCreated',
  props<{ isProjectCreated: boolean }>()
);

export const isProjectCreating = createAction(
  '[app] isProjectCreating',
  props<{ isProjectCreating: boolean }>()
);

export const setNewProject = createAction(
  '[app] setNewProject',
  props<{ newProject?: TreeProjectNode; }>()
);

export const isRevisionCreated = createAction(
  '[app] isRevisionCreated',
  props<{ isRevisionCreated: boolean }>()
);

export const isRevisionCreating = createAction(
  '[app] isRevisionCreating',
  props<{ isRevisionCreating: boolean }>()
);

export const setNewRevision = createAction(
  '[app] setNewRevision',
  props<{ newRevision?: RevisionInfo; }>()
);

export const hideLoading = createAction(
  '[app] hideLoading'
);

export const showLoading = createAction(
  '[app] showLoading'
);

export const setDropMenuTimer = createAction(
  '[app] setDropMenuTimer',
  props<{ dropMenuTimer: number }>()
);
