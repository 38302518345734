// this module is deprecated. Switch to tp-common/viewgroups
import { PoapTask, ViewGroup, ViewGroupId, ViewGroupMap, TimelineGroup, TimelineGroupMap, SYM_GROUP_ROOTS, SYM_GROUP_ROOT_ORDER } from './data-structs';
import { uuid } from 'tp-common/uuid.js';

const GROUP_DELIMITER = '|';
const PATH_SPLIT = /\/|\|/;

type GenericGroup = ViewGroup | TimelineGroup;
type GenericGroupMap = ViewGroupMap | TimelineGroupMap;

/**
 * creates a new object where group id : group
 * SYM_GROUP_ROOTS references the original group tree upper level
 * @param {string[]} rootOrder - array of root group IDs (not UID!)
 */
export function groupMapFactory(viewGroups: ViewGroup[], rootOrder: ViewGroupId[] = []): TimelineGroupMap {

  const order = new Map<string, number>(rootOrder.map((id,i) => [id, i]));
  const roots: TimelineGroup[] = [];
  const groupMap = <TimelineGroupMap>{ [SYM_GROUP_ROOTS]: [] };

  for (const vgGroup of viewGroups) {
    const tlGroup = convertToTimelineGroup(vgGroup);

    groupMap[tlGroup.id] = tlGroup;

    if (tlGroup.isProjectRoot) {
      roots.push(tlGroup);
    }
  }

  groupMap[SYM_GROUP_ROOTS] = [
    ...roots.filter(r => order.has(r.id)).sort((a,b) => order.get(a.id) - order.get(b.id)),
    ...roots.filter(r => !order.has(r.id))
  ];

  groupMap[SYM_GROUP_ROOT_ORDER] = [ ...rootOrder ];

  return groupMap;
}

export function getRootGroups(groupMap: any): TimelineGroup[] {
  return groupMap[SYM_GROUP_ROOTS];
}

export function getRootGroup(groupMap: any, projectId: string): TimelineGroup {
  return groupMap[SYM_GROUP_ROOTS].find(group => group.project === projectId);
}

export function getRootOrder(groupMap: TimelineGroupMap): ViewGroupId[] {
  return groupMap[SYM_GROUP_ROOT_ORDER];
}

export function viewGroupFactory(groupMap: GenericGroupMap, options: Partial<ViewGroup> = {}): ViewGroup {

  const requireProps = [
    'project',
    'revision',
    'viewId'
  ];

  for (const prop of requireProps) {
    if (!Object.prototype.hasOwnProperty.call(options,prop)) {
      throw Error(`viewGroupFactoryError: Missing property '${prop}'`);
    }
  }

  if (!options.isProjectRoot) {
    if (!options.name?.length) {
      throw Error('viewGroupFactoryError: Non-root-group must have a name');
    }
    if (!options.parent?.length) {
      throw Error('viewGroupFactoryError: Non-root-group must have a parent');
    }
    if (!groupMap[options.parent]) {
      throw Error('viewGroupFactoryError: group parent does not exist');
    }
  }

  const group: ViewGroup = {
    id: uuid(),
    project: options.project,
    revision: options.revision,
    version: options.version || new Date(),
    view: options.view,
    action: options.action || 'Create Group',
    isProjectRoot: options.isProjectRoot || false,
    children: options.children || [],
    parent: options.parent || null,
    name: options.name,
    narrative: options.narrative || null,
    deleted: false
  };

  if (group.isProjectRoot) {
    // if groupMap is timelineGroupMap:
    if (Object.prototype.hasOwnProperty.call(groupMap, SYM_GROUP_ROOTS)) {
      (groupMap as TimelineGroupMap)[SYM_GROUP_ROOTS].push(group);
    }
  } else {
    groupMap[group.parent].children.push(group.id);
  }

  groupMap[group.id] = group;

  return group;

}

/**
 * @deprecated - moved to tp-common/revisions/view-groups
 */
export function parseGroupString(groupString: string): string[] {
  // split, remove trailing/leading whitespace
  return groupString.split(PATH_SPLIT)
    .map(g => g.trim())
    .filter(g => g);
}

/**
 * @deprecated - moved to tp-common/revisions/view-groups
 */
export function stringifyGroupPath(groupPath: string[]): string {
  return groupPath.join(` ${ GROUP_DELIMITER } `);
}

/**
 * @deprecated - moved to tp-common/revisions/view-groups
 */
export function cleanGroupString(groupString: string): string {
  return stringifyGroupPath(parseGroupString(groupString));
}

/**
 * returns an array of groups names (not IDs)
 */
export function getGroupPath(groupMap: GenericGroupMap, groupId: ViewGroupId): string[] {
  if (!groupMap[groupId]) {
    console.error(`Group id provided did not match an existing group: '${ groupId }'`);
    return [];
  }
  const groupPath: string[] = [];
  let activeLevel = groupMap[groupId];

  while (!activeLevel.isProjectRoot) {
    groupPath.unshift(activeLevel.name);
    activeLevel = groupMap[activeLevel.parent];
  }
  return groupPath;
}

// find group by path array (create missing groups!)
export function locateGroup(
  groupMap: TimelineGroupMap,
  groupPath: string[],
  projectId: string
): { group: TimelineGroup; created: TimelineGroup[]; modified: TimelineGroup[]; } {

  const modified = [];
  const created = [];
  const projectRoot: TimelineGroup = groupMap[SYM_GROUP_ROOTS].find(g => g.project === projectId);

  if (!projectRoot) {
    console.error(`Unable to locate root group for project '${ projectId }' while handling group: ${ groupPath.join(` ${ GROUP_DELIMITER } `) }`);
    return null;
  }

  let activeLevel: TimelineGroup = projectRoot;
  for (const groupName of groupPath) {
    // try to get child group:
    let group: TimelineGroup = groupMap[activeLevel.children.find(id => groupMap[id].name.trim() === groupName.trim())];
    // if no child group definition then create one:
    if (!group) {
      group = convertToTimelineGroup(
        viewGroupFactory(groupMap, {
          project: projectId,
          revision: projectRoot.revision,
          view: activeLevel.view,
          isProjectRoot: false,
          name: groupName,
          parent: activeLevel.id,
          children: []
        })
      );
      // add to created list:
      created.push(group);
      // if active level not in created list then record as modified:
      if (!created.includes(activeLevel)) {
        modified.push(activeLevel);
      }
    }
    activeLevel = group;
  }
  return { group: activeLevel, created, modified };
}

export function getGroups(groupMap: any, uids: string[]): TimelineGroup[] {
  return uids.map(uid => groupMap[uid]);
}

export function getGroup(groupMap: any, uid: string): TimelineGroup | null {
  return groupMap[uid] || null;
}

export function getGroupParent(groupMap: any, uid: string): TimelineGroup | null {
  return groupMap[groupMap[uid].parent];
}

/**
 * returns the root group of the branch of the current group
 */
export function getSuperParent(groupMap: any, uid: string): TimelineGroup {
  let current = getGroup(groupMap, uid);
  while (current?.parent && !current.isProjectRoot) {
    current = getGroup(groupMap, current.parent);
  }
  return current;
}

export function getGroupDepth(groupMap: any, group: TimelineGroup): number {
  let count = 0;
  let currentGroup = group;
  while (currentGroup) {
    count++;
    currentGroup = groupMap[currentGroup.parent];
  }
  return count;
}

/**
 * get array of group UID and all subgroup UIDs
 */
export function getBranchUIDArray(groupMap: any, group: Partial<TimelineGroup>): string[] {
  return [ group.id ].concat( ...group.children.map(uid => getBranchUIDArray(groupMap, getGroup(groupMap, uid))));
}

/**
 * returns the selected AND unselected tasks belonging to group and all its subgroups
 */
export function getGroupTasks(groupMap: any, group: Partial<TimelineGroup>): PoapTask[] {
  return (group.allTasks || []).concat( ...group.children.map(uid => getGroupTasks(groupMap, getGroup(groupMap, uid))));
}

/*
 * returns true if there are no tasks in this group or in any of it's descendents
 */
export function isGroupEmpty(groupMap: any, group: TimelineGroup): boolean {
  if (group?.tasks?.length) {
    return false;
  }
  if (group?.children?.length) {
    return !getGroups(groupMap, group.children).some(g => !isGroupEmpty(groupMap, g));
  }
  return true;
}

/**
 * This action mutates groupMap
 * remove all groups (and their children) which do not pass the filter function
 */
export function filterGroupMap(groupMap: any, callback: (g: any) => boolean): any {
  const cloneMap = cloneGroupMap(groupMap);
  const uidList = Object.keys(groupMap);
  //cloneMap[SYM_GROUP_ROOTS] = groupMap[SYM_GROUP_ROOTS].map(g => cloneMap[g.uid]);
  for (const uid of uidList) {
    if (cloneMap[uid]) {
      const group = getGroup(cloneMap, uid);
      if (!callback(group)) {
        removeGroup(cloneMap, group);
      }
    }
  }
  return cloneMap;
}

export function cloneGroupMap(groupMap: TimelineGroupMap): TimelineGroupMap {

  const cloneMap = {};

  for (const [uid,g] of Object.entries(groupMap)) {
    cloneMap[uid] = { ...g };
  }

  cloneMap[SYM_GROUP_ROOTS] = groupMap[SYM_GROUP_ROOTS].map(g => cloneMap[g.id]);
  cloneMap[SYM_GROUP_ROOT_ORDER] = [ ...groupMap[SYM_GROUP_ROOT_ORDER] ];

  return cloneMap as TimelineGroupMap;

}

/**
 * This action mutates groupMap
 * recursively remove group and all its subgroups
 * returns an array of the removed groups
*/
export function removeGroup(groupMap: any, group: TimelineGroup): TimelineGroup[] {
  const removed = [];
  const parent = getGroup(groupMap, group.parent);
  groupMap[group.id] = null;
  removed.push(group);
  if (group.isProjectRoot) {
    groupMap[SYM_GROUP_ROOTS] = groupMap[SYM_GROUP_ROOTS].filter(g => g.id !== group.id);
  }
  if (parent) {
    parent.children = parent.children?.filter(uid => uid !== group.id);
  }
  if (group.children) {
    for (const cUid of group.children) {
      const child = getGroup(groupMap, cUid);
      if (child) {
        removed.push( ...removeGroup(groupMap, child) );
      }
    }
  }
  return removed;
}

export function forEachParent(groupMap, group: TimelineGroup, callback): void {
  let current = group;
  while (current) {
    callback(current);
    current = current.parent ? groupMap[current.parent] : null;
  }

}

export function convertToTimelineGroup(genericGroup: GenericGroup): TimelineGroup {
  return <TimelineGroup>{
    ...genericGroup,
    tasks: (genericGroup as TimelineGroup).tasks || [],
    allTasks: (genericGroup as TimelineGroup).allTasks || []
  };
}

export function convertToViewGroup(timelineGroup: TimelineGroup): ViewGroup {
  const group = { ...timelineGroup };
  Reflect.deleteProperty(group, 'tasks');
  Reflect.deleteProperty(group, 'allTasks');
  return group as ViewGroup;
}

/**
 * @deprecated - moved to tp-common/revisions/view-groups
 */
export function cleanGroupName(name: string): string {
  return name.replace(/[|\\/]/g,'');
}

/**
 * orders the provided uid list to the display order of the groups in the groupMap
 * filters out uids which do not occur in the groupMap
 * does NOT mutate uids
 */
export function matchTreeOrder(groupMap: TimelineGroupMap, uids: string[]): string[] {

  const uidSet = new Set(uids);
  const ordered = [];

  recurseTree(groupMap, true, (group: TimelineGroup) => {
    uidSet.has(group.id) && ordered.push(group.id);
  });

  return ordered;

}
/**
 * return a new group map where all groups AFTER the uid provided are removed
 * if uid not found, return null
 */
export function breakGroupMap(groupMap: TimelineGroupMap, uid: string): { groupMap: TimelineGroupMap; removed: TimelineGroup[]; } {

  //create cloned groupMap:
  const map = groupMapFactory(
    Object.values(groupMap),
    [ ...groupMap[SYM_GROUP_ROOT_ORDER] ]
  );
  const group = getGroup(map, uid);
  const root = getSuperParent(map, uid);
  const roots = getRootGroups(map);
  const removed: TimelineGroup[] = [];

  if (!group || !root) {
    return { groupMap: map, removed };
  }
  const rootIndex = roots.indexOf(root);
  // remove all groups belonging to roots past root group of break:
  for (let i = rootIndex + 1; i < roots.length; i++) {
    removed.push( ...removeGroup(map, roots[i]) );
  }
  // remove branches of group super parent beyond break:
  let child = group;
  let preserveBranch = false;
  while(child.parent) {

    const parent = getGroupParent(map, child.id);
    const siblings: string[] = [ ...parent.children ];
    const posteriorSiblings = [];

    for (let i = siblings.indexOf(child.id) + 1; i < siblings.length; i++) {
      posteriorSiblings.push(siblings[i]);
      removed.push( ...removeGroup(map, getGroup(map,siblings[i])) );
    }
    // if siblings were removed then create duplicate parent, update it's child array to include removed elements and add to removed:
    if (posteriorSiblings.length) {
      if (preserveBranch) {
        posteriorSiblings.unshift(child.id);
      }
      removed.push({
        ...parent,
        children: posteriorSiblings
      });
      preserveBranch = true;
    } else if (preserveBranch) {
      removed.push({ ...parent })
    } else if (parent.tasks.length) {
      preserveBranch = true;
      removed.push({
        ...parent,
        children: []
      });
    }
    // IMPORTANT - THIS IMPLEMENTATION ASSUMES TASKS OF A GROUP ARE DISPLAYED BELOW ITS CHILDREN:
    child = { ...parent };
    parent.tasks = [];
  }
  return { groupMap: map, removed };

}

/**
 * traverse the groupMap in order. If invert, execute callback on descendent nodes before parents.
 */
export function recurseTree(groupMap:TimelineGroupMap, invert:boolean, callback:(g: TimelineGroup) => void): void {

  const stages = [
    (group: TimelineGroup) => callback(group),
    (group: TimelineGroup) => group.children.length && recurse(groupMap, group.children)
  ];

  invert && stages.reverse();

  function recurse(groupMap:TimelineGroupMap, uids:string[]): void {
    for (const uid of uids) {
      const group = getGroup(groupMap, uid);
      if (group) {
        stages[0](group);
        stages[1](group);
      }
    }
  }

  recurse(groupMap, getRootGroups(groupMap).map(g => g.id));

}
