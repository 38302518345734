import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay, filter, map, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UndoAction, RevisionConflict } from 'tp-traqplan-core/dist/data-structs';
import { State } from '../index';
import { isDataStoreReady } from '../helpers';
import { getHistoryKey } from '../poap/helpers';

@Injectable({
  providedIn: 'root'
})
export class PoapSelectorService implements OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(private store: Store<State>) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * @deprecated
   * Emits key value pairs where key is revisionId and value is undoAction[]
   * Values are filtered by the current view
   */
  viewHistory$: Observable<{ [key: string]: UndoAction[] }> = this.store.pipe(
    takeUntil(this.destroy$),
    filter(state => (isDataStoreReady(state.poap.history) && !!state.poap.viewSelected)),
    map(state => {
      const viewId = state.poap.viewSelected.id;
      const revisionIds = Object.keys(state.projects.tree.selected);
      const history: { [key: string]: UndoAction[] } = {};
      for (const revId of revisionIds) {
        const key = getHistoryKey(revId, viewId);
        const undoActions = state.poap.history.value[key];
        if (undoActions) {
          history[revId] = undoActions;
        }
      }
      return history;
    }),
    shareReplay(1)
  );

  publishConflicts$: Observable<{ [key: string]: RevisionConflict }> = this.store.pipe(
    takeUntil(this.destroy$),
    filter(state => isDataStoreReady(state.poap.conflicts)),
    map(state => state.poap.conflicts.value),
    distinctUntilChanged()
  );

}
