import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { StaffApiService } from './staff-api.service';
import { defaultPipe } from './common';

@Injectable({
  providedIn: 'root'
})
export class UserTncAcceptanceApiService {

  constructor(
    private http: HttpClient,
    private staffApi: StaffApiService
  ) {}

  staffRequestUserTncAcceptances() {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.get<{ userTncAcceptances: any[] }>(
          '/api/staff/user-tnc-acceptances',
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  staffRequestUserTncAcceptanceById(id: string) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.get<{ userTncAcceptance: any }>(
          `/api/staff/user-tnc-acceptances/${id}`,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  staffUpdateUserTncAcceptanceById(id: string, options) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.patch<{ userTncAcceptance: any }>(
          `/api/staff/user-tnc-acceptances/${id}`,
          options,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

}
