import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { filter, map, take } from 'rxjs/operators';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { View } from 'tp-traqplan-core/dist/data-structs';
import { State } from '../../state';
import { ViewApiService } from '../../api/view-api.service';
import * as modalActions from '../../state/modal/actions';
import * as poapActions from '../../state/poap/actions';
import * as projectsActions from '../../state/projects/actions';
import { matchBasicSearchQuery } from '../../util/string';


const defaultTitle = 'Copy View'

@Component({
  selector: 'app-copy-view',
  templateUrl: './copy-view.component.html',
  styleUrls: ['./copy-view.component.scss']
})
export class CopyViewComponent implements OnInit {
  viewSuggestions: View[];
  isDraft: boolean = false;

  form = new FormGroup({
    copyTo: new FormControl('',
      [
        Validators.required,
        Validators.minLength(1),
      ],
      [
        (control: AbstractControl) => {
          const name = control.value;
          return this.viewApi.checkAvailable(name).pipe(
            map(({ available }) => {
              if (available) {
                return null;
              }

              return { namingConflict: true };
            })
          );
        }
      ]
    )
  });

  actions: ModalWindowAction[] = [
    {
      label: 'Copy and Publish View',
      type: 'positive',
      confirm: true,
      valid: () => this.form.valid,
      enterKey: true,
      tooltip: "When you copy a view it will immediately copy the current view settings to another view and will publish that view creating a new version. This effectively automates the creation of a draft and also the publishing of that draft."
    },
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true,
      escKey: true
    }
  ];

  title = defaultTitle;
  visible = true;

  constructor(private store: Store<State>, private viewApi: ViewApiService) { }

  ngOnInit(): void {
    this.store.pipe(take(1)).subscribe((state) => {
      const view = state?.poap?.viewSelected;

      if (view?.name) {
        this.title = `${defaultTitle} - ${view.name}`;
      }
    });
  }

  onActionSelected(action: ModalWindowAction): void {
    if (!action.confirm) {
      return;
    }

    this.store.pipe(take(1)).subscribe((state) => {
      const workspace = state?.account?.root?.selected?.id;
      const revisions = state?.projects?.tree?.selected ? Object.keys(state?.projects?.tree?.selected) : [];
      const checkRevision = state.poap.revisions.value[revisions[0]];
      this.isDraft = !checkRevision.published;

      const projects = state?.projects?.hierarchy?.value
        .filter(({ revisions: pRevisions }) => pRevisions?.some(({ id }) => revisions.indexOf(id) > -1))
        .map(({ id }) => id);
      const from = state?.poap?.viewSelected;
      const to = this.form.controls.copyTo.value;
      const isNewView = typeof to === 'string';
      // const previousRevision = [state.projects?.revisionViews.filter(p => p.published).sort((a:any, b:any) => b.published - a.published)[0].id];

      this.viewApi
        .copy(workspace, projects, revisions, from?.id, isNewView ? to : to?.id)
        .subscribe((response: any) => {
          this.store.dispatch(poapActions.requestViews());
          this.store.dispatch(projectsActions.requestProjectTree({}));
          this.store.dispatch(poapActions.showToast({
            severity: 'success',
            summary: 'The view has been copied successfully',
            detail: `The view${from?.name ? ` (${from.name})` : ''} has been successfully copied to view${response?.name ? ` (${response.name})` : ''} for the selected projects.`
          }));
        });

    });
  }

  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }

  searchViews({ query }): void {
    this.store.pipe(
      take(1),
      filter(state => !!state.account.root.available?.value)
    ).subscribe((state) => {

      const view = state?.poap?.viewSelected?.id;
      const workspaceId = state?.account?.root?.selected?.id;
      // this.viewSuggestions = state?.poap?.views?.value.filter((v) => v.id !== view && v.workspace_ids?.includes(workspaceId) && matchBasicSearchQuery(query, v.name));
      this.viewSuggestions = [
        ...state.poap.views.value.filter((v) => v.id !== view && v.type === 'default' && matchBasicSearchQuery(query, v.name)),
        ...state.poap.views.value.filter((v) => v.id !== view && v.type !== 'default' && v.workspace_ids.includes(workspaceId) && matchBasicSearchQuery(query, v.name)),
      ]

    });
  }
}
