const taskConstants = require('./task-constants.js');

exports.viewLineColours = taskConstants.colours;

exports.viewLineStyles = {
  solid: 'solid',
  dashed: 'dashed'
};

exports.defaultViewLineColour = 'Black';
exports.defaultViewLineStyle = exports.viewLineStyles.solid;
