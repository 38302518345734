<app-modal-window
  header="Export Timeline"
  [(visible)]="modalVisible"
  appendTo="body"
  [fullscreen]="true"
  [actions]="modalActions"
  (actionSelected)="onModalActionSelected($event)"
  (closed)="onModalClose()"
  class="export-modal"
>
  <div class="wpr">

    <div #preview class="wpr__preview">

      <div class="preview__area">
        <div #previewAreaWindow class="preview__area__window">
          <ng-container *ngFor="let pageFitting of pageFittings; let i = index">

            <svg #sheet
              class="sheet"
              [attr.data-index]="i"
              [attr.viewBox]="'0 0 '+ sheetWidth + ' ' + sheetHeight"
              [attr.height]="previewHeightPx"
              [attr.width]="previewWidthPx"
              (mouseover)="onSheetMouseover($event)"
              (mouseout)="onSheetMouseout()"
              (mousedown)="onSheetMousedown($event)"
              (mouseup)="onSheetMouseup()"
              (click)="onSheetClick($event)"
            >
              <rect fill="white" height="100%" width="100%"></rect>
              <svg
                class="sheet__header"
                [attr.width]="sheetTimelineWidth"
                [attr.height]="sheetHeaderHeight"
                [attr.y]="sheetMargins[0]"
                [attr.x]="sheetMargins[3]"
              >
                <text
                  class="sheet__header__date"
                  x="100%"
                  dy="1em"
                  text-anchor="end"
                  [attr.font-size]="sheetHeaderDateSize"
                >
                  {{headerDateString}}
                </text>
                <text
                  class="sheet__header__title"
                  text-anchor="middle"
                  x="50%"
                  dy="1em"
                  [attr.font-size]="sheetHeaderTitleSize"
                  font-weight="bold"
                >
                  {{userSettings.title}}
                </text>
                <image
                  class="sheet__header__logo"
                  x="0"
                  y="0"
                  preserveAspectRatio="xMinYMid meet"
                  [attr.height]="headerLogoHeight"
                  [attr.width]="headerLogoWidth"
                  [attr.href]="userSettings.logo"
                >
                </image>
              </svg>
              <svg
                class="sheet__timeline"
                [attr.y]="sheetMargins[0] + sheetHeaderHeight"
                [attr.x]="sheetMargins[3]"
                [attr.width]="sheetTimelineWidth"
                [attr.height]="sheetTimelineHeight"
              >
                <g class="sheet__timeline__core export-timeline" data-core-container></g>
              </svg>

            </svg>
            <app-timeline-core
              [attr.data-index]="i"
              [readOnly]="true"
              [width]="sheetTimelineWidth.toString()"
              [height]="sheetTimelineHeight.toString()"
              [layout]="pageFitting.layout"
            >
            </app-timeline-core>
          </ng-container>
        </div>

      </div>

      <div class="preview__toolbar">

        <div (click)="onClickFitToOnePage()">
          <button class="ui button"
            [disabled]="singlePageDisabled || !singlePagePossible">
            Fit to one page
          </button>
        </div>

        <div (click)="onClickResetFontSize()">
          <button class="ui button">
            Reset to minimum reccomended font size ({{recomendedFontSize}}px)
          </button>
        </div>

        <div
          (click)="onClickDecreaseFontSize()"
          data-tooltip="Decrease font size"
          data-inverted
          data-position="top center"
        >
          <button class="ui icon button">
            <i class="icon zoom-out"></i>
          </button>
        </div>

        <app-display-counter
          [value]="userSettings.fontSize"
          [precision]="2"
          suffix="px"
        >
        </app-display-counter>

        <div
          (click)="onClickIncreaseFontSize()"
          data-tooltip="Increase font size"
          data-inverted
          data-position="top center"
        >
          <button class="ui icon button">
            <i class="icon zoom-in"></i>
          </button>
        </div>

        <div
          *ngIf="userSettings.fontSize < recomendedFontSize"
          class="preview__toolbar__warning"
        >
          This font size may be too small to read at the current page size
        </div>

        <div style="flex:1"></div>

        <app-display-counter
          [value]="pageFittings.length"
          [precision]="0"
          [suffix]="pageFittings.length > 1 ? ' pages' : ' page'"
        >
        </app-display-counter>

      </div>

    </div>

    <div class="wpr__inputs">

      <div class="wpr__inputs__scrollable">

        <div class="inputs__block">
          <label>Start Date</label>
          <p-calendar
            [(ngModel)]="timelinePrintStart"
            [readonlyInput]="true"
            (onSelect)="onSettingsChange()"
            [dateFormat]="currentDateFormat"
            appendTo="body"
            ngDefaultControl>
          </p-calendar>

        </div>

        <div class="inputs__block">
          <label>Finish Date</label>
          <p-calendar
            [(ngModel)]="timelinePrintFinish"
            [readonlyInput]="true"
            (onSelect)="onSettingsChange()"
            [dateFormat]="currentDateFormat"
            appendTo="body"
            ngDefaultControl>
          </p-calendar>
        </div>

        <div class="inputs__block">
          <label>Format</label>
          <p-dropdown
            ngDefaultControl
            [options]="formatOptions"
            [(ngModel)]="userSettings.format"
            (onChange)="onSettingsChange()"
            appendTo="body"
          >
          </p-dropdown>
        </div>

        <div class="inputs__block" *ngIf="displayHeader">
          <label>Header Title</label>
          <input type="text" pInputText [(ngModel)]="userSettings.title" />
        </div>

        <div class="inputs__block" *ngIf="displayHeader">
          <label>Header Logo</label>
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input type="file" placeholder="Search" (change)="onLogoChange($event)" />
          </span>
        </div>

        <div class="inputs__block" *ngIf="displayHeader">
          <label>Date Stamp</label>
          <p-calendar
            [(ngModel)]="userSettings.date"
            [readonlyInput]="true"
            [monthNavigator]="true"
            [yearNavigator]="true"
            ngDefaultControl>
          </p-calendar>
          <!--[yearRange]="offsetYearString(-10) + ':' + offsetYearString(10)"-->
        </div>

        <div class="inputs__row">
          <label>Fit legend to grid</label>
          <input #fittingMode type="checkbox" [checked]="userSettings.legendFittingMode === 'grid'" (change)="onSettingsChange('legendFittingMode', fittingMode.checked ? 'grid' : 'compressed')" />
        </div>

        <div class="inputs__row">
          <label>Legend Position:</label>
          <label for="top">Top</label>
          <input type="radio" id="top" name="legend-position" value="top" [(ngModel)]="userSettings.legendPosition" (change)="onSettingsChange('legendPosition', 'top')" />
          <label for="bottom">Bottom</label>
          <input type="radio" id="bottom" name="legend-position" value="bottom" [(ngModel)]="userSettings.legendPosition" (change)="onSettingsChange('legendPosition', 'bottom')" />
        </div>

        <div class="inputs__block">
          <label>Ratio</label>
          <p-dropdown
            ngDefaultControl
            [options]="ratioOptions"
            [(ngModel)]="userSettings.ratio"
            (onChange)="onSettingsChange()"
            appendTo="body"
          >
          </p-dropdown>
        </div>

        <div class="inputs__block">
          <label>Orientation</label>
          <p-dropdown
            ngDefaultControl
            [options]="orientationOptions"
            [(ngModel)]="userSettings.orientation"
            (onChange)="onSettingsChange()"
            appendTo="body"
          >
          </p-dropdown>
        </div>

        <div class="inputs__block">
          <label>Resolution</label>
          <p-dropdown
            ngDefaultControl
            [options]="resolutionOptions"
            [(ngModel)]="userSettings.resolution"
            (onChange)="onSettingsChange()"
            appendTo="body"
          >
          </p-dropdown>
        </div>

      </div>
    </div>

  </div>

</app-modal-window>

<app-modal-window
  header="Add page break"
  [(visible)]="modalAddVisible"
  appendTo="body"
  [actions]="modalConfirmActions"
  (actionSelected)="onConfirmAdd($event)"
>
  This will add a page break. Please confirm
</app-modal-window>

<app-modal-window
  header="Reset to single page"
  [(visible)]="modalResetVisible"
  appendTo="body"
  [actions]="modalConfirmActions"
  (actionSelected)="onConfirmReset($event)"
>
  This will remove all page breaks and cannot be undone. Please confirm
</app-modal-window>

<app-modal-window
  header="Move page break"
  [(visible)]="modalMoveVisible"
  appendTo="body"
  [actions]="modalConfirmActions"
  (actionSelected)="onConfirmMove($event)"
>
  This will move the page break. Please confirm
</app-modal-window>

<app-modal-window
  header="Change font size"
  [(visible)]="modalFillVisible"
  appendTo="body"
  [actions]="modalConfirmActions"
  (actionSelected)="onConfirmFill($event)"
>
  This will change the font size across ALL pages.  Are you sure you wish to continue?
</app-modal-window>

<!--<app-tooltip
  label="Click to remove page break"
  [visible]="displayPageBreakTooltip"
>
</app-tooltip>-->
<!--
<app-timeline-core
  #timelineCore
  [readOnly]="true"
  [width]="sheetTimelineWidth"
  [height]="sheetTimelineHeight"
>
<!--[eventChannel]="activeChannel"--
</app-timeline-core>
-->
