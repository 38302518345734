<p-calendar
  *ngIf="displayBoundaryControls"
  id="boundary-label-left"
  name="boundaryStart"
  ngDefaultControl
  appendTo="body"
  [ngModel]="boundaryStart"
  [readonlyInput]="true"
  (onSelect)="onBoundaryStartChange($event)"
  [dateFormat]="currentDateFormat"
  [maxDate]="boundaryStartMaxDate"
></p-calendar>
<p-calendar
  *ngIf="showStartCalendar"
  #windowStartCalendar
  id="window-label-left"
  name="windowStart"
  ngDefaultControl
  appendTo="body"
  class="leftCalendar"
  [inline]="true"
  [minDate]="boundaryStart"
  [maxDate]="windowStartMaxDate"
  [ngModel]="windowStart"
  [readonlyInput]="true"
  (onSelect)="onWindowStartChange($event)"
  [dateFormat]="currentDateFormat"

>
</p-calendar>
<div #track id="track">
  <div #thumb id="thumb" [ngStyle]="{ left:pxLeft, width: pxWidth }">
    <div #lHandle
      id="l-handle"
      [attr.data-label]="windowStartString"
    >
      <svg height="62%" width="62%" viewBox="0 0 30 30" style="margin:20% 0 0 20%;">
        <polygon
          fill="#FFFFFF"
          points="10.187,4.945 0,15.13 10.187,25.32 12.573,22.931 4.773,15.13 12.573,7.33"
        ></polygon>
        <polygon
          fill="#FFFFFF"
          points="19.811,4.945 17.422,7.33 25.221,15.13 17.422,22.931 19.811,25.32 30,15.13"
        ></polygon>
      </svg>

      <div
        id="l-handle-label"
        (click)="openStart()"
      >
        {{windowStartString}}
        <!-- <div >
          <p-calendar
            *ngIf="showStartCalendar"
            #windowStartCalendar
            id="window-label-left"
            name="windowStart"
            ngDefaultControl
            appendTo="body"
            class="leftCalendar"
            [inline]="true"
            [minDate]="boundaryStart"
            [maxDate]="windowStartMaxDate"
            [ngModel]="windowStart"
            [readonlyInput]="true"
            (onSelect)="onWindowStartChange($event)"
            [dateFormat]="currentDateFormat"

          >
          </p-calendar>
        </div> -->

      </div>


    </div>
    <div #rHandle
      id="r-handle"
      [attr.data-label]="windowFinishString"
    >
      <svg height="62%" width="62%" viewBox="0 0 30 30" style="margin:18% 0 0 18%;">
        <polygon
          fill="#FFFFFF"
          points="10.187,4.945 0,15.13 10.187,25.32 12.573,22.931 4.773,15.13 12.573,7.33"
        ></polygon>
        <polygon
          fill="#FFFFFF"
          points="19.811,4.945 17.422,7.33 25.221,15.13 17.422,22.931 19.811,25.32 30,15.13"
        ></polygon>
      </svg>

      <div
        id="r-handle-label"
        (click)="openEnd()"
      >
        {{windowFinishString}}


      </div>

    </div>
  </div>
</div>
<p-calendar
  #windowFinishCalendar
  id="window-label-right"
  name="windowFinish"
  ngDefaultControl
  class="rightCalendar"
  [inline]="showEndCalendar"
  [minDate]="windowFinishMinDate"
  [maxDate]="boundaryFinish"
  [ngModel]="windowFinish"
  [readonlyInput]="true"
  (onSelect)="onWindowFinishChange($event)"
  [dateFormat]="currentDateFormat"
  appendTo="body"
>
</p-calendar>
<p-calendar
  *ngIf="displayBoundaryControls"
  id="boundary-label-right"
  name="boundaryFinish"
  ngDefaultControl
  [ngModel]="boundaryFinish"
  [readonlyInput]="true"
  (onSelect)="onBoundaryFinishChange($event)"
  [dateFormat]="currentDateFormat"
  [minDate]="boundaryFinishMinDate"
  appendTo="body"
></p-calendar>
