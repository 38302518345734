import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { TimelineGroup } from 'tp-traqplan-core/dist/data-structs';
import { TimelineLayout } from 'tp-traqplan-core/dist/timeline/timeline-layout';
import * as groupUtils from 'tp-traqplan-core/dist/task-group-utils';

@Component({
  selector: 'app-timeline-group-tree',
  templateUrl: './timeline-group-tree.component.html',
  styleUrls: ['./timeline-group-tree.component.css']
})
export class TimelineGroupTreeComponent implements OnChanges {

  @Input() layout: TimelineLayout;
  /* list of group UIDs */
  @Input() branches: string[];
  /* list of group UIDs */
  @Input() exclude: string[] = [];

  @Output() selectionChanged = new EventEmitter<Partial<TimelineGroup>>();

  public groupTree: TreeNode[];
  public selection: TreeNode;

  ngOnChanges(): void {
    if (this.branches?.length && this.layout) {
      this.groupTree = this.createTreeNodes(
        groupUtils.getGroups(this.layout.settings.groupMap, this.branches)
      );
    }
  }

  onNodeSelect({ node }: { node: TreeNode }): void {
    if (node && node.data) {
      this.selection = node;
      this.selectionChanged.emit(node.data);
    }
  }

  private createTreeNodes(children: Partial<TimelineGroup>[]): TreeNode[] {
    return children.filter(group => group && group.id ? !this.exclude.includes(group.id) : false)
      .map(group => {
        const child = <TreeNode>{
          data: group,
          label: group.name,
          expanded: true
          /*collapsedIcon: 'pi pi-folder',
          expandedIcon: 'pi pi-folder-open',*/
        };
        if (group && Array.isArray(group.children) && group.children.length) {
          child.children = this.createTreeNodes(
            groupUtils.getGroups(this.layout.settings.groupMap, group.children)
          );
        }
        return child;
      });
  }

}
