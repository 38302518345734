import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { TimelineInteractions } from '../tl-interactions';
import { throttle } from 'lodash';

@Component({
  selector: 'app-floating-tooltip',
  templateUrl: './floating-tooltip.component.html',
  styleUrls: ['./floating-tooltip.component.scss']
})
export class FloatingTooltipComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  @Input() interactions: TimelineInteractions;

  display: boolean;
  content: string;

  pxTop: string;
  pxLeft: string;

  private _mousemove: any;

  constructor() {
    this._mousemove = throttle((event) => {
      this.pxLeft = `${event.clientX + 6}px`;
      this.pxTop = `${event.clientY + 9}px`;
    }, 18);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {

    this.interactions?.events$.pipe(
      takeUntil(this.destroy$),
      filter(e => e.event === 'setTooltipMessage')
    ).subscribe(({ data }) => {
      if (data.tooltip) {
        this.display = true;
        this.content = data.tooltip;
      } else {
        this.display = false;
      }
    });

  }

  @HostListener('document:mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    this._mousemove(event);
  }

}
