import { RevisionInfo } from "tp-traqplan-core/dist/data-structs";

export function sortRevision(a: RevisionInfo, b: RevisionInfo): number {
  const diffCreated = a.published && b.published
    ? a.published.getTime() - b.published.getTime()
    : a.created.getTime() - b.created.getTime();

  if (diffCreated === 0) {
    const diffProjectVersion = a.projectVersion - b.projectVersion;

    if (diffProjectVersion === 0) {
      return a.viewVersion - b.viewVersion;
    }

    return diffProjectVersion;
  }

  return diffCreated;
}
