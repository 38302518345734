import {
  HierarchyNode,
  MilestoneCategory,
  PoapTask,
  RevisionId,
  TimelineGroup,
  ViewLine,
  LegendFittingMode,
  LegendPosition,
  DrawingLayout
} from '../data-structs';
import { PoapTaskFieldChange, PoapGroupFieldChange } from '../workspace-structs';

// DO NOT CHANGE OR REORDER THESE - THE VALUES ARE PERSISTED SERVER SIDE!!!!
export enum TextLayout {
  AUTOFIT_TEXT_INSIDE_BARS,
  TEXT_ALWAYS_OUTSIDE_BARS,
  TEXT_ALWAYS_INSIDE_BARS
}

export const MIN_H_RANGE = 86400000 * 1;

export enum BOUNDARY_MODE {
  Auto = 0,
  Manual = 1
}

export enum VIEW_MODE {
  Normal = 0,
  Print = 1,
  Export = 2
}

//public api:
export interface TimelineOptions {
  milestoneCategories: MilestoneCategory[];
  editable: boolean;
  coloursAvailable: Map<string, string>;
  groupMap: any;
  // nominal start and finish dates of the task selection:
  nominalStart: Date;
  nominalFinish: Date;
  // boundary start and finish of the horizontally scrollable area
  boundaryStart: Date;
  boundaryFinish: Date;
  // visible section of the timeline:
  viewportStart: Date;
  viewportFinish: Date;
  // locations of the print lines / export boundaries:
  printStart: Date;
  printFinish: Date;
  evaluateNarrative: boolean;
  groupsWidth: number;
  textMaxWidth: number;
  //textMinWidth: number;
  fontSize: number;
  milestoneSymbolSize: number;
  taskPadding: number;
  textPadding: number;
  groupTextPadding: number;
  displayBaseline: boolean;
  taskMaxLines: number;
  groupMaxLines: number;
  baselineThreshold: number;
  prependMilestoneDates: boolean;
  viewLines: ViewLine[];
  displayEmptyGroups: boolean;
  viewTickLines: boolean;
  wrapTextOutsideBar: boolean;
  wrapTextOutsideThreshold: number;
}

export interface TimelineState {
  readOnly: boolean;

  selectedTasks: TaskBoundElement[];
  selectionLimit: number;

  groupInteractionTarget: GroupBoundElement | null;

  taskInteractionTarget: TaskBoundElement | null;

  exportActive: boolean;

  viewportHeight: number;
  viewportScrollActive: boolean;
  viewportScrollHeight: number;
  viewportScrollTranslation: number;

  viewportSlideActive: boolean;

  axisBoundaryMode: number;
  axisBoundaryRange: Date[];
  axisViewRange: Date[];
}

/**
 * Not fully implemented yet:
 */
export interface TimelineColourTheme {
  groupColour?: string;
  groupTextColour?: string;
  barColour?: string;
  barTextColour?: string;
  milestoneSymbol?: string;
  milestoneColour?: string;
  milestoneTextColour?: string;
  swimlaneColourOdd?: string;
  swimlaneColourEven?: string;
}

//internal settings:
export interface LayoutOptions {
  groupMap: any;
  plotStart: Date;
  plotFinish: Date;
  viewportStart: Date;
  viewportFinish: Date;
  // total viewport width including group area & excluding scrollbars:
  viewportWidth: number;
  viewportHeight?: number;
  printStart: Date;
  printFinish: Date;
  wrapStart: Date;
  wrapFinish: Date;
  nominalStart: Date;
  nominalFinish: Date;
  // boundary start and finish of the horizontally scrollable area
  boundaryStart: Date;
  boundaryFinish: Date;
  //whether to include narrative column text in height of rows
  evaluateNarrative: boolean;
  narrativeColumnWidth?: number;
  groupColumnWidth?: number;
  groupsWidth: number;
  // maxium width for single line of text for milestone or when placed outside of bar
  textMaxWidth: number;
  //textMinWidth: number; // minimum width before overlapping text is displayed outside bar
  taskMaxLines: number;
  groupMaxLines: number;
  fontSize: number;
  milestoneSymbolSize: number;
  taskPadding: number;
  textPadding: number;
  groupTextPadding: number;
  displayBaseline: boolean;
  baselineThreshold: number;//
  milestoneCategories: MilestoneCategory[];
  coloursAvailable: Map<string, string>;
  theme?: TimelineColourTheme;
  viewMode: number;
  viewLines: ViewLine[];
  displayEmptyGroups: boolean;
  prependMilestoneDates: boolean;
  viewTickLines: boolean;
  wrapTextOutsideBar: boolean;
  wrapTextOutsideThreshold: number;
  //stop placing rows when this value reached:
  maximumRowCount?: number;
  displayLegend?: boolean;
  legendRowPadding?: number;
  legendFontSize?: number;
  legendColumnPadding?: number;
  legendColumnMinWidth?: number;
  legendFittingMode?: LegendFittingMode;
  legendPosition?: LegendPosition;
  // if true, display 'DRAFT' on the corner of each draft project in the view:
  displayDraftLabels?: boolean;
  defaultDrawingLayout?: DrawingLayout;
  textLayout?: TextLayout;
  groupColumnHeaders: string[];
}

// implemented by TimelineLayout.fittingMeta property:
export interface FittingMeta {
  totalHeight: number;
  axisHeight: number;
  totalRows: number;
  rowHeight: number;
  printStart: number;
  printFinish: number;
  minimumGroupWidth: number;
  maximumGroupWidth: number;
  nominalGroupDepth: number;
  milestoneMap: Map<string, MilestoneCategory>;
  viewLines: Map<string, ViewLineFitting>;
  poapTasks: PoapTask[];
}

export interface GroupHeaderOptions {
  // there might not be labels for every group, so input total number group 'columns':
  columnCount: number;
  labels: string[];
  fontSize: number;
  width: number;
}

export interface AxisOptions {
  fontSize: number;
  scale: any;
}

export interface DataBoundElement extends SVGElement {
  __fitting__: any;
  __note: any;
  __handles__?: any;
}

export interface GroupBoundElement extends SVGElement {
  __fitting__: GroupFitting;
  __node__: GroupNode;
  __handles__?: any;
}

export interface TaskBoundElement extends SVGGElement {
  __fitting__: TimelineTask;
  __node__: TaskNode;
  __handles__?: SVGElement[]
}

export interface GroupNode {
  container: GroupBoundElement;
  background: SVGElement;
  border: SVGElement;
  rect: SVGElement;
  text: SVGElement;
  taskGroup: SVGElement;
  fitting: GroupFitting;
  isPlaced: boolean;
}

export interface TaskNode {
  wrapper: TaskBoundElement;
  background: SVGElement;
  text: SVGElement;
  baseline: SVGElement;
  symbol: SVGElement;
  isPlaced: boolean;
  fittingData: TimelineTask;
  positionData: TaskNodePosition;
}

export interface TaskNodePosition {
  verticalOffset: number;
  nominalHeight: number;
}

export interface ViewLineFitting extends ViewLine {
  position: number;
}

export interface ViewportDimensions {
  height: number;
  width: number;
  axisHeight: number;
}

/*----------  Layout ----------*/

export interface TimelineTask {
  ID: string; //temporary id only assigned for rendering and not persisted to server
  PrevState: Partial<TimelineTask>; //this is used to interpolate transtioning values during the render phase
  RowIndex: number; //row within group
  StartDate: Date;
  FinishDate: Date;
  BarStart: number | null; // for milestone, BarStart & BarFinish gives the physical position of the symbol.
  BarFinish: number | null;
  BarWidth: number | null;
  TextStart: number | null;
  TextFinish: number | null;
  ProjectTasks: PoapTask[];
  BaselineStart: number | null;
  BaselineFinish: number | null;
  BaselineStartDate: Date | null;
  BaselineFinishDate: Date | null;
  wrapTextOutside?: boolean;
  Milestone: boolean;
  MilestoneType: string | null; //an identifier string which is linked to a generator function in the timeline core options
  Colour: string;
  TextColour?: string;
  Text: string;
  TextWrapped: string[];
  TextWidth: number;
  // width of bar + text (excluding baseline):
  NominalWidth: number;
  Row: TimelineRow;
  // currently set in rendering code WBN: this should be moved to layout code:
  VerticalOffset?: number;
}

export interface TimelineRow {
  rowHeight: number; //number of text rows (not actual height)
  nominalHeight: number;
  tasks: TimelineTask[];
}

export interface GroupFitting {
  groupUid: string;
  groupHeight: number;
  childGroupHeight: number;
  groupWidth: number;
  groupStart: number;
  nominalDepth: number; //the depth of parents + the depth of children + 1, so group1 => group2 => group3 would each have val==3
  children: GroupFitting[];
  parent: GroupFitting | null;
  taskRows: TimelineRow[];
  text: string;
  textWrapped: string[];
  narrativeWrapped?: string[];
  narrativeHeight?: number;
  tasks: PoapTask[];
  pageBreak?: boolean;
  drawingLayout: DrawingLayout;
}

/*----------  Legend export ----------*/

export interface LegendOptions {
  rowPadding: number;
  columnPadding: number;
  textPadding: number;
  fontSize: number;
  width: number;
  columnMinWidth: number;
  cellMaxLines: number;
  categories: MilestoneCategory[];
  fittingMode: LegendFittingMode;
  position: LegendPosition;
}

export interface LegendItem {
  colStart: number;
  rowStart: number;
  rowHeight: number;
  text: string[];
  symbol: string;
  colour: string;
  relTextStart: number;
  relIconStart: number;
}

/*---------- Change Emissions ----------*/
// duplicate: remove
export interface TaskChange {
  revisionId: RevisionId;
  changes: PoapTaskFieldChange[];
}

/** @deprecated */
export interface GroupChange {
  group: Partial<TimelineGroup>;
  change: string;
}

export interface ViewGroupChange {
  revisionId: RevisionId;
  action: string;
  changes: PoapGroupFieldChange[];
}

export interface ProjectChange {
  id: string;
  projectInfo: Partial<HierarchyNode>;
}

export interface ProjectOrderChange {
  commit: string;
  order: string[];
}

export enum DragAction {
  PrintLine = 'printLine',
  GroupWidth = 'groupWidth',
  ViewLine = 'viewLine',
  Task = 'task',
  TaskResize = 'taskResize'
}

export enum TaskDragType {
  Start = 1,
  Finish = 2,
  Move = 1 | 2
}

export enum ClickAction {
  Swimlane = 'swimlane',
  GroupLabel = 'groupLabel',
  Task = 'task',
  CreateGroupSibling = 'createGroupSibling',
  CreateChildGroup = 'createChildGroup'
}

export enum DblClickAction {
  GroupLabel = 'groupLabel',
  Task = 'task'
}

export enum MouseoverAction {
  GroupLabel = 'groupLabel',
  Task = 'task',
  Swimlane = 'swimlane',
  CreateGroupSibling = 'createGroupSibling',
  CreateChildGroup = 'createChildGroup'
}

export enum ContextMenuAction {
  GroupLabel = 'groupLabel',
  Task = 'task'
}

export interface EditCheckParams {
  task?: TimelineTask;
  group?: TimelineGroup;
  fields?: string[];
  alertOnFail?: boolean;
}