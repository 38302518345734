<app-modal-window
  header="Rename Programme"
  [(visible)]="visible"
  (actionSelected)="onActionSelected($event)"
  (closed)="onClose()"
  [actions]="actions"
>
  <form
    class="tp-form"
    [formGroup]="programmeForm"
  >

    <div class="tp-form__field">
      <label for="name">Rename Programme</label>
      <input
        pInputText
        name="name"
        formControlName="name"
      />
    </div>

    <div
      class="tp-form__error"
      *ngIf="programmeForm.controls.name.errors?.namingConflict"
    >
      A programme with this name already exists
    </div>

  </form>

</app-modal-window>
