import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() text: string = '';
  @Input() type: string = 'neutral';
  @Input() disabled: boolean = false;
  @Input() tooltip: string = '';
  @Input() tooltipPosition: string = '';

  onClick(event) {
    if (this.disabled) {
      event.stopPropagation();
    }
  }

}
