import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import * as access from 'tp-common/access';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { TreeProjectNode, TreeRevisionNode, TreeGrouping, HierarchyNode, HierarchyNodeId, User, View, ViewId } from 'tp-traqplan-core/dist/data-structs';
import { HierarchyApiService } from '../../../api/hierarchy-api.service';
import { formatRevisionLabel } from '../../../label-format';
import { ModalService } from '../../../modals/modal.service';
import { State } from '../../../state';
import * as appActions from '../../../state/app/actions';
import * as modalActions from '../../../state/modal/actions';
import * as poapActions from '../../../state/poap/actions';
import * as projectsActions from '../../../state/projects/actions';
import { ProjectTreeActionsService } from '../project-tree-actions.service';
import { ProjectsService } from '../../../state/selectors/projects.service';
import { StateModal } from '../../../state/structs';
import { sortRevision } from '../../../util/common';

@Component({
  selector: 'app-tree-list',
  templateUrl: './tree-list.component.html',
  styleUrls: ['./tree-list.component.scss', '../style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TreeListComponent implements OnInit, OnDestroy {

  // The geniuses behind angular decided that, by default, Maps should be iterated in the wrong order. This is a workaround:
  // odot: this can probably be removed
  mapOrderFix() { return 1 }

  //preserveOrder

  destroy$ = new Subject<void>();
  editClicked = false;
  groupSelected = false;
  revisionSelected:TreeRevisionNode|null = null;
  selectedProject: string | null = null;
  revisionList=[];
  firstLoad: boolean=true;
  treeRan: number = 0;
  firstRevisions: any;
  revRan:boolean = false;
  groupExpandToggle: boolean|null = null;

  toggleThis:string='';


  groupings$: Observable<TreeGrouping[]> = this.projectsService.tree$.pipe(
    takeUntil(this.destroy$),
    map(({ groupings }) => {

      const revArray = [];
      let latestRevisionSelected = false;
      groupings.forEach((group) => {
        const newGroup = group.nodes?.filter(node => {
          if (node.revisions?.length === 0) {
            group.icon = 'book'
          }
          return node.revisions?.length !== 0;
        });
        group.nodes = newGroup;
      })

      groupings.forEach((group) => {
        if(this.groupExpandToggle !== null || this.groupExpandToggle === undefined){
          if(group.value === this.toggleThis){
            group.expanded = this.groupExpandToggle;
          }
        }
        group.nodes?.forEach(node=>{
          node.revisions?.forEach( (rev:any)=> {
              revArray.push(rev);
            }
          );

          if(node.selected && this.revisionSelected === null && this.firstLoad) {
            node.revisions?.map((revision)=>{
              if(revision.published === null && revision.projectVersion === 0 && revision.viewVersion === 0) {
                revision.selected = true;
                this.onSelectRevision(revision);
                latestRevisionSelected = true;
              }else{
                revision.selected = false;
              }
              return revision;
            })
          }

          const noRevisionSelected = node.revisions?.find(item => item.selected === true);

          if(!noRevisionSelected && node.selected && !latestRevisionSelected){
            const latestPublish = node.revisions?.reduce((latest, current) => {
            const latestDate = new Date(latest.published);
            const currentDate = new Date(current.published);
              return currentDate > latestDate ? current : latest;
            }, node.revisions[0]);
            const revId = latestPublish?.id;

            if(revId){
              node.revisions?.map((revision)=>{
                if(revision.id === revId){
                  return revision.selected = true;
                }
              })
            }

          }


        })
        this.firstLoad = false;

        if (group.nodes?.some(n => {
          if (n.containsDraft === true) {
            return true;
          }
          return false;
        })) {
          return group.icon = 'pen'
        }
      });

      this.groupSelected = false;
      this.groupExpandToggle = null;
      return [...groupings.values()]
    })
  );

  ungrouped$: Observable<TreeProjectNode[]> = this.projectsService.tree$.pipe(
    takeUntil(this.destroy$),
    map(({ ungrouped }) => [...ungrouped.values()])
  );

  hierarchyNodes: HierarchyNode[] = [];

  userList: { [key: string]: User } = {};

  getActions = this.actionsService.getActionsAvailable.bind(this.actionsService);
  getGroupActions = this.actionsService.getGroupActionsAvailable.bind(this.actionsService);
  views: View[];
  selectedView: View;
  selectedViewId: ViewId;

  constructor(
    private store: Store<State>, private hierarchyApi: HierarchyApiService, public actionsService: ProjectTreeActionsService,
    private modalService: ModalService, private projectsService: ProjectsService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.store.pipe(
      takeUntil(this.destroy$),
      tap(state => {
        this.hierarchyNodes = state.projects.hierarchy.value || [];
        this.userList = state.account.users;
        this.views = state?.poap?.views?.value;
        this.selectedView = state?.poap?.viewSelected;
        this.selectedViewId = state?.poap?.viewSelected?.id;

      })
    ).subscribe();

  }

  formatRevisionString(node: TreeProjectNode, revision: TreeRevisionNode): string {
    return formatRevisionLabel({
      node, revision, user: this.userList[revision?.author], view: this.selectedView, views: this.views, nodes: this.hierarchyNodes
    });
  }

  getRevisionTooltip(revision: TreeRevisionNode): string {
    if (revision?.imported) {
      return 'This indicates this version of the project was imported from a project plan as opposed to being manually created in the webapp';
    }

    return '';
  }

  displayGroupingSelected(grouping: TreeGrouping): boolean {
    if (grouping.nodes?.length) {
      return grouping.nodes.some(n => n.selected);
    }

    if (grouping.children) {
      for (const child of grouping.children.values()) {
        const selected = this.displayGroupingSelected(child);
        if (selected) {
          return selected;
        }
      }
    }

    return false;
  }

  getHierarchyNode(id: HierarchyNodeId): HierarchyNode | undefined {
    return this.hierarchyNodes.find(n => n.id === id);
  }

  onSelectGroup(grouping: TreeGrouping): void {
    const select = !this.displayGroupingSelected(grouping);
    const revisions = getGroupNodeRevisions(select, grouping, [], this.selectedViewId);
    this.store.dispatch(projectsActions.selectProjectTreeRevisions({ select, revisions }));
    this.store.dispatch(poapActions.removeSelectedRevisions({ selectedRevisions: revisions }));
  }

  async onSelectProject(select: boolean, project: TreeProjectNode): Promise<void> {
    if (!project.revisions?.length) {
      return;
    }
    const revisions = select ? [project?.revisions[0]] : project?.revisions?.filter(rev => rev.selected);
    await this.store.dispatch(projectsActions.selectProjectTreeRevisions({ select, revisions }));
    if (!select) {
      await this.store.dispatch(poapActions.removeSelectedRevisions({ selectedRevisions: revisions }));
    }
  }

  onSelectRevision(revision: TreeRevisionNode): void {
    revision.selected = true;
    for (let index = 0; index < 2; index++) {
      this.store.dispatch(projectsActions.selectProjectTreeRevisions({ select: true, revisions: [revision] }));
    }

  }

  onExpandGroup(expand: boolean, grouping: TreeGrouping): void {
    grouping.expanded = expand;
    this.groupExpandToggle = expand;
    this.toggleThis = grouping.value;

    this.store.dispatch(
      projectsActions.expandProjectTreeNode({ expand, grouping })
    );
  }

  onExpandProject(expand: boolean, project: TreeProjectNode): void {
    this.store.dispatch(
      projectsActions.expandProjectTreeNode({ expand, project })
    );
  }

  onExpandRevisions(project: TreeProjectNode): void {
    this.store.dispatch(
      projectsActions.expandProjectRevisions({ expand: !project.revisionsExpanded, project })
    );
  }

  onClickExtraButton(node: TreeProjectNode) {

    if (access.permissionIncludes(node.permission, access.actionType.editBranch)) {

      if (node.containsDraft) {
        this.publishDraft(node);
        this.editClicked = false;
      } else {
        if (!this.editClicked) {
          this.editClicked = true;
          this.createDraft(node);
  
        }
        // setTimeout(()=>{this.editClicked = true;
        // },2000, this.editClicked = false);
      }
      this.firstLoad = true;

    } else {
      this.store.dispatch(
        modalActions.generateModal({
          modal: StateModal.requestAccess,
          inputs: {
            hierarchyNode: this.getHierarchyNode(node.id)
          }
        })
      );
    }
  }

  onRemoveBaseline(node: TreeProjectNode) {
    this.store.dispatch(
      projectsActions.updateNode({
        id: node.id,
        update: {
          baselineRevision: null
        }
      })
    )
  }

  private publishDraft(node: TreeProjectNode): void {
    const selected = node.revisions?.find(({ selected }) => selected);
    const draft = selected ? undefined : node.revisions?.find(({ published }) => !published);
    this.store.dispatch(
      modalActions.generateModal({
        modal: StateModal.labelRevision,
        inputs: {
          revision: selected || draft
        }
      })
    );
  }

  private createDraft(node: TreeProjectNode): void {
    this.store.pipe(take(1)).subscribe((state) => {
      this.hierarchyApi.getTree({ root: state.account.root.selected.id }).subscribe((nodes: HierarchyNode[]) => {
        this.store.dispatch(projectsActions.requestProjectTreeSuccess({ nodes, viewId: state.poap.viewSelected?.id }));
        const latestProject = nodes.find(({ id }) => id === node.id);
        const fromRevision = node.revisions?.find(({ selected }) => selected);
        const revisions = (
          latestProject
            ? (Array.isArray(latestProject?.revisions) ? latestProject.revisions : [])
            : (Array.isArray(node?.revisions) ? node.revisions : [])
        ).filter(({ view }) => view === null || view === state.poap.viewSelected?.id).sort(sortRevision);
        const lastRevision = revisions[revisions.length - 1];
        const isEarlierRevision = fromRevision && fromRevision.created ? (lastRevision?.created?.getTime() || 0) - fromRevision.created.getTime() > 0 : false;
        const createDraftFunc = (confirmParams?) => {
          this.store.dispatch(appActions.setDraftProject({ draftProject: node }));
          this.store.dispatch(appActions.isDraftCreating({ isDraftCreating: true }));
          this.store.dispatch(appActions.isDraftCreated({ isDraftCreated: false }));
          this.store.dispatch(appActions.showLoading());
          this.store.dispatch(
            poapActions.createDraft({
              from: confirmParams?.latestRevision?.id || fromRevision?.id || lastRevision?.id,
              project: node.id,
              view: this.selectedViewId
            })
          );
        };

        if (isEarlierRevision) {
          setTimeout(() => {
            this.modalService
              .info({
                header: 'Warning, editing older revision',
                message: `You are about to edit an older revision (v${fromRevision?.projectVersion}.${fromRevision?.viewVersion}) of this project "${node.name}". Are you sure you want to proceed?`,
                actions: [
                  {
                    label: 'Cancel',
                    type: 'neutral',
                    cancel: true,
                    escKey: true
                  },
                  {
                    label: 'Proceed with latest revision',
                    type: 'positive',
                    confirm: true,
                    confirmParams: { latestRevision: lastRevision }
                  },
                  {
                    label: `Proceed with older revision (v${fromRevision?.projectVersion}.${fromRevision?.viewVersion})`,
                    type: 'positive',
                    confirm: true
                  }
                ]
              }).then((action: ModalWindowAction) => {
                if (action.confirm) {
                  createDraftFunc(action.confirmParams);
                }
              });
          });
        } else {
          createDraftFunc();
        }

        this.editClicked = false;
      });
    });
  }
}

function getGroupNodeRevisions(select: boolean, grouping: TreeGrouping, revisions: TreeRevisionNode[] = [], selectedViewId): TreeRevisionNode[] {
  if (grouping.nodes?.length) {
    for (const node of grouping.nodes) {
      if (node?.revisions?.length) {
        const alreadySelected = node?.revisions?.filter(rev => rev.selected);

        if (select) {
          if (alreadySelected.length) {
            continue;
          }

          revisions.push(node?.revisions[0]);
        } else if (alreadySelected.length) {
          revisions.push(...alreadySelected);
        }
      }
    }
  }
  if (grouping.children) {
    for (const child of grouping.children.values()) {
      getGroupNodeRevisions(select, child, revisions, selectedViewId);
    }
  }
  return revisions;
}
