import { createSVGElement } from "../../svg-utils";
import { ContextMenuAction, DblClickAction, GroupBoundElement, GroupFitting, GroupNode, MouseoverAction } from "../tl-structs";
import { standardAttribute, standardClass } from "../tl-style";

export function createGroupNode(group: GroupFitting): GroupNode {

  const background = createSVGElement('rect');
  background.classList.add('group-background', standardClass.swimlaneBackground);
  background.setAttribute(standardAttribute.dataMouseover, MouseoverAction.Swimlane);

  const border = createSVGElement('line');
  border.classList.add('group-border', standardClass.swimlaneBorder); 
  border.setAttribute('x1', '0');
  border.setAttribute('x2', '100%');

  const rect = createSVGElement('rect');
  rect.classList.add('group-rect', 'group-hover-space', standardClass.swimlaneLabel);
  rect.setAttribute(standardAttribute.dataMouseover, MouseoverAction.GroupLabel);
  rect.setAttribute(standardAttribute.dataContextmenu, ContextMenuAction.GroupLabel);
  rect.setAttribute(standardAttribute.dataDblclick, DblClickAction.GroupLabel);

  const text = createSVGElement('text');
  text.classList.add('group-text', standardClass.swimlaneText);

  const taskGroup = createSVGElement('g');
  taskGroup.classList.add('group-task-group', standardClass.swimlaneTasks);

  const container = createSVGElement('g') as GroupBoundElement;
  container.classList.add('group-container', standardClass.swimlaneGroup);

  container.append(background, border, rect, text, taskGroup);

  container.__fitting__ = group;
  container.__node__ = <GroupNode>{
    container,
    background,
    border,
    rect,
    text,
    taskGroup,
    fitting: group,
    isPlaced: false
  };

  return container.__node__;

}