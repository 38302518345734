import { createSVGElement } from "../../svg-utils";
import { standardFormat } from "../../date-format";
import { TimelineLayout } from "../timeline-layout";
import { TaskBoundElement, TimelineTask, DragAction } from "../tl-structs";
import { standardAttribute, standardClass } from "../tl-style";

export function setDragging(container: TaskBoundElement, dragging: boolean): void {
  dragging ? container.setAttribute(standardAttribute.dataDragging, '') 
           : container.removeAttribute(standardAttribute.dataDragging);
}

export function displayDragHandles(layout: TimelineLayout, element: TaskBoundElement) {

  if (!element.__handles__) {
    const task = element.__fitting__ as TimelineTask;
    const width = layout.settings.fontSize * 1.2;

    const lHandle = createSVGElement('g');
    const rHandle = createSVGElement('g');
    const lRect = lHandle.appendChild(createSVGElement('rect'));
    const rRect = rHandle.appendChild(createSVGElement('rect'));

    lHandle.appendChild(createLArrow(task.Row.nominalHeight, width));
    rHandle.appendChild(createRArrow(task.Row.nominalHeight, width));

    lHandle.appendChild(createLabel(layout));
    rHandle.appendChild(createLabel(layout));

    lHandle.classList.add(standardClass.taskDragHandleLeft);
    lHandle.setAttribute(standardAttribute.dataDrag, DragAction.TaskResize);
    rHandle.classList.add(standardClass.taskDragHandleRight);
    rHandle.setAttribute(standardAttribute.dataDrag, DragAction.TaskResize);

    lRect.setAttribute('height', `${task.Row.nominalHeight}`);
    rRect.setAttribute('height', `${task.Row.nominalHeight}`);

    lRect.setAttribute('width', `${width}`);
    rRect.setAttribute('width', `${width}`);

    element.__handles__ = [lHandle, rHandle];

    element.append(lHandle, rHandle);
  }

  element.setAttribute(standardAttribute.dataTaskHandles, '');
  
  // make element the last in the node order for the parent group:
  if (element.nextElementSibling) {
    element.parentElement.append(element);
  }

  updateDragHandles(layout, element);

}

export function updateDragHandles(layout: TimelineLayout, container: TaskBoundElement) {
  if (container.__handles__?.length) {
    const width = layout.settings.fontSize * 1.2;
    const task = container.__fitting__;
    const [lHandle, rHandle] = container.__handles__;
    
    lHandle.children[0].setAttribute('height', `${task.Row.nominalHeight}`);
    rHandle.children[0].setAttribute('height', `${task.Row.nominalHeight}`);

    const lLabel = lHandle.children[2];
    const rLabel = rHandle.children[2];

    const [lLabelRect, lLabelText] = lLabel.children;
    const [rLabelRect, rLabelText] = rLabel.children;

    lLabelText.textContent = standardFormat(task.StartDate);
    rLabelText.textContent = standardFormat(task.FinishDate);
    lLabelText.setAttribute('y', `${layout.settings.taskPadding}`);
    lLabelText.setAttribute('x', `${layout.settings.taskPadding}`);
    lLabelText.setAttribute('dominant-baseline', 'hanging');
    rLabelText.setAttribute('y', `${layout.settings.taskPadding}`);
    rLabelText.setAttribute('x', `${layout.settings.taskPadding}`);
    rLabelText.setAttribute('dominant-baseline', 'hanging');

    const lLabelWidth = layout.textWrapping.measureText(lLabelText.textContent) + layout.settings.taskPadding * 2
    const rLabelWidth = layout.textWrapping.measureText(rLabelText.textContent) + layout.settings.taskPadding * 2;

    lLabelRect.setAttribute('width', lLabelWidth);
    rLabelRect.setAttribute('width', rLabelWidth);

    const handleY = (task.Row.nominalHeight / 2) - ((layout.settings.fontSize + (layout.settings.taskPadding * 2)) / 2);

    lHandle.setAttribute('transform', `translate(${-(width - 1)})`);
    lLabel.setAttribute('transform', `translate(${-(lLabelWidth + 3)}, ${handleY})`);
    rLabel.setAttribute('transform', `translate(${width + 3}, ${handleY})`);

    if (task.Milestone || task.wrapTextOutside) {
      rHandle.setAttribute('transform', `translate(${task.TextStart - task.BarStart - 1})`);
    } else {
      rHandle.setAttribute('transform', `translate(${task.BarWidth - 1})`);
    }
  }
}

export function removeDragHandles(container: TaskBoundElement) {
  if (container.__handles__) {
    for (const handle of container.__handles__) {
      handle.remove();
    }
  }
  // make elements available for garbage collection:
  container.__handles__ = null;
  container.removeAttribute(standardAttribute.dataTaskHandles);
}

function createLArrow(height: number, width: number) {
  const svg = createSVGElement('svg');
  svg.setAttribute('viewBox', '0 0 24 24');
  svg.setAttribute('height', `${height}`);
  svg.setAttribute('width', `${width}`);

  const path = svg.appendChild(createSVGElement('path'));
  path.setAttribute('d', 'M15.5,20l-8-8l8-8V20z');

  return svg;
}

function createRArrow(height: number, width: number) {
  const svg = createSVGElement('svg');
  svg.setAttribute('viewBox', '0 0 24 24');
  svg.setAttribute('height', `${height}`);
  svg.setAttribute('width', `${width}`);

  const path = svg.appendChild(createSVGElement('path'));
  path.setAttribute('d', 'M8.5,20V4l8,8L8.5,20z');

  return svg;
}

function createLabel(layout: TimelineLayout) {

  const g = createSVGElement('g');
  g.classList.add(standardClass.taskDragHandleLabel);

  const rect = g.appendChild(createSVGElement('rect'));
  g.appendChild(createSVGElement('text'));

  rect.setAttribute('height', `${layout.settings.fontSize + (layout.settings.taskPadding * 2)}`);

  return g;
}