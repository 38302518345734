<app-modal-window
  header="Create Account"
  [actions]="actions"
  (actionSelected)="onActionSelected($event)"
  [(visible)]="visible"
  (closed)="onClose()"
>

  <form
    class="tp-form"
    [formGroup]="form"
  >

    <div class="tp-form__message">
      <div class="tp-label">
        Create Account
      </div>
      <div>
        This might be your company name, department name or family name.
        It will only be used to group multiple workspaces you create for your “group” later.
      </div>
    </div>

    <div class="tp-form__notice">
      <div class="tp-label">
        Create new billing account?
      </div>
      <div>
        You are already part of an existing Account. Are you sure you wish to set up a new billing account.
        Most organisations will only use one Account and will set up separate workspaces between different departments.
        If you do not need to set up a new billing account,
        but you want to set up separate "Work areas" then you may set up a new workspace within your existing account
        with no extra charge.
      </div>
    </div>

    <div class="tp-form__field">
      <label for="account">Account:</label>
      <input
        type="text"
        name="account"
        formControlName="account"
        autocomplete="traqplan-account"
        pInputText
      />
    </div>

    <div class="tp-form__error" *ngIf="account.errors?.invalidName">
      <span>Account name is invalid</span>
    </div>

    <div
      [ngClass]="{ 'tp-form__message': !account.errors?.notAvailable, 'tp-form__error': account.errors?.notAvailable }"
      *ngIf="!account.errors?.invalidName && account.value?.length"
    >
      <span>{{this.account.value}} is {{ account.errors?.notAvailable ? 'taken' : 'available' }}</span>
    </div>

  </form>

</app-modal-window>
