import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { PoapTask, TimelineGroup } from 'tp-traqplan-core/dist/data-structs';
import { TimelineLayout } from 'tp-traqplan-core/dist/timeline/timeline-layout';
import { parseDateString } from 'tp-traqplan-core/dist/date-format';
import { outlineNumbers, viewGroups } from 'tp-common/revisions';

@Component({
  selector: 'app-select-task',
  templateUrl: './select-task.component.html',
  styleUrls: ['./select-task.component.scss']
})
export class SelectTaskComponent {

  @Input() layout: TimelineLayout;

  private _group: TimelineGroup;
  
  @Input()
  set group(tg: TimelineGroup) {
    this._group = tg;
    if (tg) {
      this.rowData = this.getTimelineTasks(tg);
      this.rowData.sort(outlineNumbers.compare);
    }
  }
  get group() {
    return this._group;
  }

  @Output() selectionChange = new EventEmitter<PoapTask[]>();

  modules = AllModules;

  rowData: Partial<PoapTask>[];

  gridOptions = {
    enableRangeSelection: false,
    treeData: true,
    groupDefaultExpanded: -1,
    rowSelection: 'multiple',
    onSelectionChanged: this.onSelectionChanged.bind(this),
    suppressRowClickSelection: true,
    /*isExternalFilterPresent: () => {
      return this.selectionService.isTaskFilterActive();
    },
    doesExternalFilterPass: rowNode => {
      return this.selectionService.doesTaskFilterPass(rowNode.data);
    },*/
    defaultColDef: {
      suppressSizeToFit: true,
      resizable: true,
      width: 150,
      editable: false
    },
    getRowNodeId: this.getRowNodeId.bind(this),
    getDataPath: this.getDataPath.bind(this)
  };

  autoGroupColumnDef = {
    headerName: 'Task Name',
    colId: 'name',
    width: 220,
    checkboxSelection: true,
    cellRendererParams: {
      suppressCount: true
    },
    valueGetter: (params) => {
      // if root task then provide name as name of project:
      return params.data?.name;
    },
    valueSetter: params => {
      if (typeof params.newValue === 'string') {
        const trimmed = params.newValue.trim();
        if (trimmed.length) {
          params.data.name = trimmed;
          return true;
        }
      }
      return false;
    }
  };

  columnDefs = [
    {
      headerName: 'Start',
      cellClass: 'dateType',
      field: 'start',
      cellEditor: 'datePicker',
      valueGetter: params => params.data?.start || '',//params.data?.start?.getTime() || '',
      valueFormatter: params => params.data?.start ? params.data.start.toLocaleString(undefined, { weekday: 'short', month: 'numeric', day: 'numeric', year: 'numeric' }) : '',
      valueSetter: params => {
        const date = (params.newValue instanceof Date) ? params.newValue : parseDateString(params.newValue);
        if (date && date.getTime && !isNaN(date.getTime())) {
          params.data.start = date;
          return true;
        }
        return false;
      },
    },
    {
      headerName: 'Finish',
      cellClass: 'dateType',
      field: 'finish',
      cellEditor: 'datePicker',
      valueGetter: params => params.data?.finish || '',
      valueSetter: params => {
        const date = (params.newValue instanceof Date) ? params.newValue : parseDateString(params.newValue);
        if (date && date.getTime && !isNaN(date.getTime())) {
          params.data.finish = date;
          return true;
        }
        return false;
      },
      valueFormatter: params => params.data?.finish ? params.data.finish.toLocaleString(undefined, { weekday: 'short', day: 'numeric', month: 'numeric', year: 'numeric' }) : '',
    },
    {
      headerName: '% Complete',
      field: 'percentComplete',
      valueGetter: params => Number(params.data?.percentComplete),
      valueSetter: params => {
        if (typeof params.newValue === 'string') {
          const trimmed = params.newValue.trim();
          if (!trimmed.length) {
            params.data.percentComplete = null;
            return true;
          }
          const value = Number(params.newValue);
          const clamped = Math.max(0, Math.min(100, value));
          if (!isNaN(clamped)) {
            params.data.percentComplete = clamped;
            return true;
          }
        }
        return false;
      }
    },
    {
      headerName: 'Rename on view',
      field: 'rename',
      valueSetter: params => {
        if (typeof params.newValue === 'string') {
          const trimmed = params.newValue.trim();
          params.data.rename = trimmed;
          return true;
        }
        return false;
      }
    },
    {
      headerName: 'Group',
      field: 'groupPath',
      valueFormatter: params => this.valueFormatGroup(params),
      valueSetter: params => this.valueSetGroup(params),
      valueGetter: params => this.valueGetGroup(params)
    },
    {
      headerName: 'Bar Colour',
      field: 'colour'
    },
    {
      headerName: 'Outline',
      field: 'outlineNumber',
      width: 100,
      editable: false,
      valueFormatter: params => {
        return params.data?.outlineNumber?.join('.') || '';
      },
      valueGetter: params => {
        if (params.data?.outlineNumber) {
          const arr = [...params.data.outlineNumber];
          arr.toString = function () { return this.join('.') }
          return arr;
        }
        return [];
      }
    }
  ];

  getTimelineTasks(group: TimelineGroup, tasks: PoapTask[] = []): PoapTask[] {

    if (group.allTasks?.length) {
      tasks.push(...group.allTasks);
    }

    if (group.children?.length) {
      for (const id of group.children) {
        const child = this.layout.groupMap[id];
        if (child) {
          this.getTimelineTasks(child, tasks);
        }
      }
    }

    return tasks;
  }

  private onSelectionChanged(event): void {
    this.selectionChange.emit(event.api.getSelectedRows());
  }

  private joinPath(path: (number | string)[]): string {
    return path.join('.');
  }

  private getRowNodeId(task: PoapTask): string {
    return this.joinPath(this.getDataPath(task));
  }

  private getDataPath(task: PoapTask): string[] {
    // all tasks are in same project so we remove the 'root' task level:
    return task.outlineNumber.slice(1).map(o => o.toString());
  }

  private valueFormatGroup(params): string {
    return params.value;
  }

  private valueGetGroup(params): string {
    return params.data?.groupPath ? viewGroups.stringifyGroupPath(params.data.groupPath) : '';
  }

  private valueSetGroup(params): boolean {
    params.data.groupPath = viewGroups.parseGroupString(params.newValue);
    return true;
  }

}
