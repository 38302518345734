import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { PricingApiService } from '../../../api/power-bi/pricing-api.service';
import { RequestError } from '../../../api/structs';
import * as pricingsActions from './actions';

@Injectable()
export class PricingEffects {

  constructor(
    private actions$: Actions,
    private pricingApi: PricingApiService
  ) {}

  requestPricings$ = createEffect(() => this.actions$.pipe(
    ofType(pricingsActions.requestPricings),
    switchMap((): Observable<Action> => {
      return this.pricingApi.requestPricings().pipe(
        switchMap(({ pricings }: any) => [
          pricingsActions.requestPricingsSuccess(<any>{ pricings })
        ]),
        catchError((error: RequestError) => {
          return of(pricingsActions.requestPricingsFailure({ error }))
        })
      );
    })
  ));

  requestPricingById$ = createEffect(() => this.actions$.pipe(
    ofType(pricingsActions.requestPricingById),
    switchMap(({ id }): Observable<Action> => {
      return this.pricingApi.requestPricingById(id).pipe(
        switchMap((pricing) => [
          pricingsActions.requestPricingByIdSuccess(<any>{ pricing })
        ]),
        catchError((error: RequestError) => {
          return of(pricingsActions.requestPricingByIdFailure({ error }))
        })
      );
    })
  ));

}
