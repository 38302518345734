<div class="panel" *ngIf="imports$ | async as imports">
  <div
    class="panel__header"
    [ngClass]="{ 'expanded': expanded }"
  >
    <app-expand-icon
      [expanded]="expanded"
      (click)="onToggle()"
    >

    </app-expand-icon>

    <div class="panel__header__label tp-header-3">
      Imports Pending...
    </div>
  </div>

  <div
    *ngIf="expanded"
    class="panel__content"
    [class.limited_height]="!onImportPage"
  >
    <div
      *ngFor="let revision of imports"
      class="revision"
      [appDropMenu]="getActions"
      [dropMenuDatums]="[revision.parentNode, revision]"
      (dropMenuAction)="actionsService.dispatchAction($event, revision.parentNode, revision)"
    >
      <div class="revision__icon fas">
        pen
      </div>

      <input
        class="node__header__checkbox"
        type="checkbox"
        [ngModel]="revision.selected"
        (click)="onSelectRevision(revision)"
      />

      <div class="revision__label">
        <span>{{getRevisionLabel(revision)}}</span>

        <span>&nbsp;&nbsp;</span>

        <span class="btn btn-publish" (click)="onClickExtraButton(revision)"
          matTooltip="Your changes are private to you. Publish them to create a version which will be visible to other users"
          matTooltipPosition="above">
          Publish
        </span>
      </div>
    </div>
  </div>
</div>
