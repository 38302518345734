import { PoapTask, TimelineGroup } from "tp-traqplan-core/dist/data-structs";
import { ViewGroupChange, TaskChange, ProjectChange, TaskBoundElement, ProjectOrderChange, GroupBoundElement } from "tp-traqplan-core/dist/timeline/tl-structs";

export enum EventID {
  GroupChange = 'groupChange',
  TaskChange = 'taskChange',
  ProjectChange = 'projectChange',
  ProjectReorder = 'projectReorder',
  OpenTaskModal = 'taskModalOpen',
  OpenGroupModal = 'groupModalOpen',
  SetTooltipMessage = 'setTooltipMessage',
  ShowContextMenu = 'showContextMenu',
  ShowGroupContextMenu = 'showGroupContextMenu',
  ShowFloatingInput = 'showFloatingInput'
}

export interface TimelineEvent<E = any,D = any> {
  event: E;
  data: D;
}

export type GroupChangeEvent = TimelineEvent<EventID.GroupChange, ViewGroupChange>;

export type TaskChangeEvent = TimelineEvent<EventID.TaskChange, TaskChange>;

export type ProjectChangeEvent = TimelineEvent<EventID.ProjectChange, ProjectChange>;

export type ProjectReorderEvent = TimelineEvent<EventID.ProjectReorder, ProjectOrderChange>;

export type OpenTaskModalEvent = TimelineEvent<EventID.OpenTaskModal, Partial<PoapTask>>;

export type OpenGroupModalEvent = TimelineEvent<EventID.OpenGroupModal, {
  parent?: TimelineGroup;
  sibling?: TimelineGroup
}>;

export type SetTooltipMessageEvent = TimelineEvent<EventID.SetTooltipMessage, {
  tooltip: string 
}>;

export type ShowContextMenuEvent = TimelineEvent<EventID.ShowContextMenu, {
  target: TaskBoundElement,
  event: MouseEvent
}>;

export type ShowGroupContextMenuEvent = TimelineEvent<EventID.ShowGroupContextMenu, {
  target: GroupBoundElement,
  event: MouseEvent
}>;

export type ShowFloatingInputEvent = TimelineEvent<EventID.ShowFloatingInput, { 
  label: string; 
  value?: any;
  complete?: (a: string) => void;
  validator?: (a: string) => any;
}>;
