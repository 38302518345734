import { TimelineLayout } from '../timeline-layout';
import * as tlStyle from '../tl-style';
import { createSVGElement} from '../../svg-utils';

const { printlineLine, printlineMask } = tlStyle.standardClass;
const { dataPrintBoundary } = tlStyle.standardAttribute;

export type PrintLineMap = Map<string,SVGLineElement|SVGRectElement>;

export function renderPrintLines( layout: TimelineLayout, root:SVGGElement, preMap: PrintLineMap = new Map() ): PrintLineMap {

  const [ plotStart, plotFinish ] = layout.timeScale.range();
  const { printStart, printFinish } = layout.fittingMeta;

  const nMap: PrintLineMap = new Map();

  if (printStart > plotStart && printStart < plotFinish) {

    const lineID = getNodeID('start','line');
    const maskID = getNodeID('start','mask');

    const nLine = updateLine(preMap.get(lineID) as SVGLineElement || lineFactory('start'), { position: printStart });
    const nMask = updateMask(preMap.get(maskID) as SVGRectElement || maskFactory('start'), { start: '0', finish: printStart });

    (nMask.parentNode !== root) && root.appendChild(nMask);
    (nLine.parentNode !== root) && root.appendChild(nLine);

    nMap.set(lineID, nLine);
    nMap.set(maskID, nMask);

  }
  if (printFinish < plotFinish && printFinish > plotStart) {

    const lineID = getNodeID('finish','line');
    const maskID = getNodeID('finish','mask');

    const nLine = updateLine(preMap.get(lineID) as SVGLineElement || lineFactory('finish'), { position: printFinish });
    const nMask = updateMask(preMap.get(maskID) as SVGRectElement || maskFactory('finish'), { start: printFinish, finish: '100%' });

    (nMask.parentNode !== root) && root.appendChild(nMask);
    (nLine.parentNode !== root) && root.appendChild(nLine);

    nMap.set(lineID, nLine);
    nMap.set(maskID, nMask);

  }

  // remove discarded elements:
  for (const e of preMap) {
    if (!nMap.has(e[0])) {
      e[1].remove();
    }
  }

  return nMap;

}


function getNodeID(boundary,type) {
  return `${boundary}_${type}`;
}

function maskFactory(boundary): SVGRectElement {
  const nMask = createSVGElement('rect');
  nMask.setAttribute('class', printlineMask);
  nMask.setAttribute('height', '100%');
  nMask.setAttribute('y', '0');
  nMask.setAttribute(dataPrintBoundary, boundary);
  return nMask as SVGRectElement;
}

function lineFactory(boundary): SVGLineElement {
  const nLine = createSVGElement('line');
  nLine.setAttribute('class', printlineLine);
  nLine.setAttribute('y1', '0');
  nLine.setAttribute('y2', '100%');
  nLine.setAttribute('data-drag', "printLine");
  nLine.setAttribute(dataPrintBoundary, boundary);
  return nLine as SVGLineElement;
}

function updateLine(nLine: SVGLineElement, { position }): SVGLineElement {
  nLine.setAttribute('transform', `translate(${position},0)`);
  return nLine;
}

function updateMask(nMask: SVGRectElement, { start, finish }): SVGRectElement {
  nMask.setAttribute('x', start);
  nMask.setAttribute('width', finish);
  return nMask;
}
