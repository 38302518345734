import { Component, OnChanges, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import { scaleLinear/*, drag, select, event*/ } from 'd3';
import { throttle } from 'lodash';
import { TimelineInteractions } from '../tl-interactions';

@Component({
  selector: 'app-vertical-scroller',
  templateUrl: './vertical-scroller.component.html',
  styleUrls: ['./vertical-scroller.component.css']
})
export class VerticalScrollerComponent implements OnChanges {

  @ViewChild('track', {static: true}) track: ElementRef;
  @ViewChild('thumb', {static: true}) thumb: ElementRef;

  @Input() interactions: TimelineInteractions;
  @Input() scrollHeight: number;
  @Input() viewportHeight: number;
  @Input()
  set wheelTarget(target: ElementRef){
    this.setWheelTarget(target)
  }

  get pxTop(){
    return `${this.thumbTop}px`;
  }

  get pxHeight(){
    return `${this.thumbHeight}px`;
  }

  private scrollOffset: number = 0;

  private _wheelTarget: HTMLElement;
  private wheelListener: (event: any) => void;

  public thumbTop: number = 0;
  public thumbHeight: number = 0;

  private trackHeight: number;
  private scale: any;

  private scrollTimeout: number;

  private dragDiff: number = null;
  private dragBoundaries: number[] = null;
  private throttleEmission = throttle(() => this.emitScrollChange(), 20);

  @HostListener('document:mouseup', ['$event'])
  onMouseup() {
    this.dragDiff = null;
  }

  @HostListener('document:mousemove', ['$event'])
  onMousemove(ev) {
    if (this.dragDiff === null) return;
    const position = Math.max(this.dragBoundaries[0], Math.min(this.dragBoundaries[1], ev.clientY - this.dragDiff));
    this.scrollOffset = this.scale.invert(position - this.dragBoundaries[0]);
    this.reposition();
    this.emitScrollChange();
  }

  onMousedown(ev) {
    const thumbRect = this.thumb.nativeElement.getBoundingClientRect();
    const trackRect = this.track.nativeElement.getBoundingClientRect();
    this.dragDiff = ev.clientY - thumbRect.top;
    this.dragBoundaries = [
      trackRect.top,
      trackRect.bottom - thumbRect.height
    ];
  }

  ngOnChanges(): void {
    if (!isNaN(this.viewportHeight) && !isNaN(this.scrollHeight)) {
      this.rescale();
    }
  }

  rescale(): void {

    this.trackHeight = this.track.nativeElement.offsetHeight;

    this.scale = scaleLinear()
      .domain([0, this.scrollHeight])
      .range([0, this.trackHeight])
      .clamp(true);

    this.reposition();

  }

  emitScrollChange() {

    this.scrollTimeout && clearTimeout(this.scrollTimeout);

    this.interactions?.viewportScroll(-1 * this.scrollOffset);

    this.scrollTimeout = window.setTimeout(() => {
      this.interactions?.viewportScrollComplete();
    }, 100);

  }

  reposition(): void {

    this.thumbHeight = this.scale(this.viewportHeight);

    const availableTrack = this.scale.range()[1] - this.thumbHeight;

    const thumbTop = this.scale(this.scrollOffset);

    if (thumbTop <= availableTrack) {
      this.thumbTop = thumbTop
    } else {
      this.thumbTop = availableTrack;
      setTimeout(() => {
        this.scrollOffset = this.scale.invert(this.thumbTop);
        this.emitScrollChange();
      });
    }
  }

  setWheelTarget(target: ElementRef){

    if (!target) return;

    if(this._wheelTarget && this.wheelListener) {
      this._wheelTarget.removeEventListener('wheel', this.wheelListener);
    }

    this._wheelTarget = target.nativeElement;
    this.wheelListener = registerListener(this._wheelTarget, 'wheel', (event: any) => {

      event.preventDefault();

      const offset = this.scrollOffset + (event.deltaY * 0.2);
      const max = this.scale.domain()[1] - this.scale.invert(this.thumbHeight);
      const min = this.scale.domain()[0];

      this.scrollOffset = Math.max( Math.min( offset, max ), min );

      this.reposition();
      this.emitScrollChange();

    });

  }

}

function registerListener(target, type, listener: (event: any) => void): (event: any) => void {
  target.addEventListener(type, listener);
  return listener;
}
