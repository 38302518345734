import { createAction } from '@ngrx/store';
import { DataStore } from './structs';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

export const nullAction = createAction(
  'null action'
);

export function isDataStoreInitial(...stores: DataStore<any>[]): boolean {
  return stores.every(store => !!(!store.pending && !store.value));
}

export function isDataStorePending(...stores: DataStore<any>[]): boolean {
  return stores.some(store => store.pending || false);
}

export function isDataStoreReady(...stores: DataStore<any>[]): boolean {
  return stores.every(store => (store.pending === false && store.updated instanceof Date));
}

export function dsInitial<T>(initial?: T): DataStore<T> {
  return <DataStore<T>>{
    value: initial || null,
    pending: false,
    updated: null
  };
}

export function dsPending<T>(value?: T): DataStore<T> {
  return <DataStore<T>>{
    value: value || null,
    pending: true
  };
}

export function dsReceived<T>(value: T): DataStore<T> {
  return <DataStore<T>>{
    value: value,
    pending: false,
    updated: new Date()
  }
}

export function filterDSReady<T>() {
  return ($: Observable<DataStore<T>>) => $.pipe(
    filter(s => isDataStoreReady(s))
  );
}

export function filterDSChanged<T>() {
  return ($: Observable<DataStore<T>>) => $.pipe(
    filterDSReady(),
    distinctUntilChanged((a,b) => a.updated === b.updated)
  );
}
