<div class="page" *ngIf="!verification.token || !verification.email">
  <div class="header">
    <span class="tp-header-1">Verify Email</span>
  </div>
  <form
    action=""
    class="tp-form"
    [formGroup]="tokenForm"
    (submit)="onTokenSubmit()"
  >

    <div class="tp-form__message">
      <span>If you have an account with us then you will have recieved a verification email. Follow the link in the email or enter the verification token here to reset your password.</span>
    </div>

    <div class="tp-form__field">
      <label for="token">Verification Token</label>
      <input
        type="text"
        name="token"
        pInputText
        required
        autofocus
        formControlName="token"
      />
    </div>

    <div class="tp-form__error" *ngIf="tokenError">
      <span>{{tokenError}}</span>
    </div>

    <button
      class="tp-form__button"
      type="submit"
    >
      Verify
    </button>

  </form>

</div>


<div class="page" *ngIf="verification.token && verification.email">
  <div class="header">
    <span class="tp-header-1">Set Password</span>
  </div>
  <form
    action=""
    class="tp-form"
    [formGroup]="passwordForm"
    (submit)="onPasswordSubmit()"
  >

    <div class="tp-form__message">
      <span>Create a new password to sign in to your account.</span>
    </div>

    <div class="tp-form__field">
      <label for="password">Password</label>
      <input
        type="password"
        name="password"
        pInputText
        required
        autofocus
        formControlName="password"
      />
    </div>

    <div class="tp-form__field">
      <label for="confirmPassword">Confirm Password</label>
      <input
        type="password"
        name="confirmPassword"
        pInputText
        required
        autofocus
        formControlName="confirmPassword"
      />
    </div>

    <div class="tp-form__error" *ngIf="passwordError">
      <span>{{passwordError}}</span>
    </div>

    <div class="tp-form__error" *ngIf="passwordForm.controls.password.errors?.invalid">
      <span>{{passwordForm.controls.password.errors.invalid}}</span>
    </div>

    <div class="tp-form__error" *ngIf="passwordForm.errors && passwordForm.errors.mismatch">
      <span>{{passwordForm.errors.mismatch}}</span>
    </div>

    <button
      class="tp-form__button"
      type="submit"
    >
      Confirm
    </button>

  </form>

</div>
