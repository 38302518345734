import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { pluck, map, filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from './state';

export const accessRestriction = {
  authenticated: 1,
  verified: 2,
  activated: 4
};

@Injectable({
  providedIn: 'root'
})
export class AccessGuardService implements CanActivate {

  constructor(private router: Router, private store$: Store<State>) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const ar = accessRestriction;
    const { requires } = route.data;
    const queryParams = { return: state.url };

    /*if (route.queryParams.d) {
      this.store$.dispatch(authActions.attemptLogin({ d: route.queryParams.d }));
      return false;
    }*/

    if (!requires) {
      console.warn('AccessGuard is invoked for this route but the access restriction is not specified. All access will be allowed.');
      return true;
    }

    return this.store$.pipe(
      pluck('auth'),
      filter(auth => !auth.pending),
      take(1),
      map(({ user, token }) => {

        if ((requires & ar.authenticated) === ar.authenticated && !token) {
          this.router.navigate(['login'])//, { queryParams });
          return false;
        }

        if ((requires & ar.verified) === ar.verified && !user?.emailVerified) {
          this.router.navigate(['registration', 'verification'], { queryParams });
          return false;
        }

        if ((requires & ar.activated) === ar.activated && !user?.activated) {
          return false;
        }

        return true;
      })
    );
  }
}
