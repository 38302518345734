import { StateModal } from '../state/structs';
import { SessionTimeoutComponent } from './session-timeout/session-timeout.component';
import { CreateProjectComponent } from './create-project/create-project.component';
import { RenameProjectComponent } from './rename-project/rename-project.component';
import { RenameProgrammeComponent } from './rename-programme/rename-programme.component';
import { DeleteProjectComponent } from './delete-project/delete-project.component';
import { LabelRevisionComponent } from './label-revision/label-revision.component';
import { EditAccessComponent } from './edit-access/edit-access.component';
import { ShareAccessComponent } from './share-access/share-access.component';
import { CreateViewComponent } from './create-view/create-view.component';
import { CopyViewComponent } from './copy-view/copy-view.component';
import { EditMilestonesComponent } from './edit-milestones/edit-milestones.component';
import { EditViewlinesComponent } from './edit-viewlines/edit-viewlines.component';
import { PublishConflictComponent } from './publish-conflict/publish-conflict.component';
import { WorkspaceSettingsComponent } from './workspace-settings/workspace-settings.component';
import { CreateWorkspaceComponent } from './create-workspace/create-workspace.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { InactivityWarningComponent } from './inactivity-warning/inactivity-warning.component';
import { ResumeSessionComponent } from './resume-session/resume-session.component';
import { MoveProjectComponent } from './move-project/move-project.component';
import { MoveProgrammeComponent } from './move-programme/move-programme.component';
import { CopyProjectComponent } from './copy-project/copy-project.component';
import { CopyProgrammeComponent } from './copy-programme/copy-programme.component';
import { ShowMessageComponent } from './show-message/show-message.component';
import { RequestAccessComponent } from './request-access/request-access.component';
import { RequestLicenseUpgradeComponent } from './request-license-upgrade/request-license-upgrade.component';
import { ReviewAccessRequestComponent } from './review-access-request/review-access-request.component';
import { ReviewLicenseRequestComponent } from './review-license-request/review-license-request.component';
import { AccessGrantedComponent } from './access-granted/access-granted.component';
import { EditLicenseComponent } from './edit-license/edit-license.component';

export const modalComponents = {
  [StateModal.sessionTimeout]: SessionTimeoutComponent,
  [StateModal.createProject]: CreateProjectComponent,
  [StateModal.renameProject]: RenameProjectComponent,
  [StateModal.renameProgramme]: RenameProgrammeComponent,
  [StateModal.deleteProject]: DeleteProjectComponent,
  [StateModal.labelRevision]: LabelRevisionComponent,
  [StateModal.editAccess]: EditAccessComponent,
  [StateModal.shareAccess]: ShareAccessComponent,
  [StateModal.createView]: CreateViewComponent,
  [StateModal.copyView]: CopyViewComponent,
  [StateModal.editMilestones]: EditMilestonesComponent,
  [StateModal.editViewlines]: EditViewlinesComponent,
  [StateModal.publishConflict]: PublishConflictComponent,
  [StateModal.workspaceSettings]: WorkspaceSettingsComponent,
  [StateModal.createWorkspace]: CreateWorkspaceComponent,
  [StateModal.createAccount]: CreateAccountComponent,
  [StateModal.inactivityWarning]: InactivityWarningComponent,
  [StateModal.resumeSession]: ResumeSessionComponent,
  [StateModal.moveProject]: MoveProjectComponent,
  [StateModal.moveProgramme]: MoveProgrammeComponent,
  [StateModal.copyProject]: CopyProjectComponent,
  [StateModal.copyProgramme]: CopyProgrammeComponent,
  [StateModal.showMessage]: ShowMessageComponent,
  [StateModal.requestAccess]: RequestAccessComponent,
  [StateModal.requestLicense]: RequestLicenseUpgradeComponent,
  [StateModal.reviewAccessRequest]: ReviewAccessRequestComponent,
  [StateModal.reviewLicenseRequest]: ReviewLicenseRequestComponent,
  [StateModal.accessGranted]: AccessGrantedComponent,
  [StateModal.editLicense]: EditLicenseComponent
};
