<app-modal-window
  header="Request permissions"
  [actions]="actions"
  [(visible)]="visible"
  (actionSelected)="complete($event)"
  (closed)="complete()"
>

  <div class="message" *ngIf="!hierarchyNode">You have not been granted access to any projects. Send a message to your account admins to request project access:</div>


  <div class="message" *ngIf="hierarchyNode && nodeLabel">You do not have permission to edit {{hierarchyNode.name}}. You can request access from the {{nodeLabel}} owner:</div>

  <textarea pInputTextarea [(ngModel)]="customMessage" placeholder="Add a custom message"></textarea>

</app-modal-window>
