import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthApiService } from './auth-api.service';
import { defaultPipe } from './common';
import { ReqHeader } from './structs';
import { HierarchyNodeId, HierarchyNode, UserId } from 'tp-traqplan-core/dist/data-structs';

@Injectable({
  providedIn: 'root'
})
export class HierarchyApiService {

  constructor(
    private http: HttpClient,
    private authApi: AuthApiService
  ) { }

  checkAvailable(name: string, level: number): Observable<{ available: boolean }> {

    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ available: boolean }> => {
        return this.http.get<{ available: boolean; }>(
          '/api/hierarchy/check-available',
          { params: { name, level: level.toString() }, headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  create({ name, path, allowImportedEdits=null }): Observable<{ node: HierarchyNode }> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ node: HierarchyNode }> => {
        return this.http.post<{ node: HierarchyNode }>(
          '/api/hierarchy',
          { name, path, allowImportedEdits },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  update({ id, update }) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/hierarchy/update',
          { id, update },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  updateBulk({ workspace, changes }) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/hierarchy/update-bulk',
          { workspace, changes },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  validateProjectMove({ copy, targetId, targetName, workspaceName, programmeName }) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<{ errors: string[] }>(
          '/api/hierarchy/check-project-move',
          { copy, targetId, targetName, workspaceName, programmeName },
          { headers }
        );
      })
    );
  }

  moveProject({ copy, targetId, targetName, workspaceName, programmeName }) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/hierarchy/move-project',
          { copy, targetId, targetName, workspaceName, programmeName },
          { headers }
        );
      })
    );
  }

  validateProgrammeMove({ copy, targetId, targetName, workspaceName }) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<{ errors: string[] }>(
          '/api/hierarchy/check-programme-move',
          { copy, targetId, targetName, workspaceName },
          { headers }
        );
      })
    );
  }

  moveProgramme({ copy, targetId, targetName, workspaceName }) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/hierarchy/move-programme',
          { copy, targetId, targetName, workspaceName },
          { headers }
        );
      })
    );
  }

  searchChildren({ id, query }) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.get<{ matches: HierarchyNode[] }>(
          '/api/hierarchy/search-children',
          { headers, params: { id, query } }
        );
      })
    );
  }

  /** @deprecated */
  move({ targetId, parentId }) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/hierarchy/move',
          { targetId, parentId },
          { headers }
        );
      })
    );
  }

  /** @deprecated */
  copy({ targetId, parentId, name }) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/hierarchy/copy',
          { targetId, parentId, name },
          { headers }
        );
      })
    );
  }

  getTree({ root }): Observable<HierarchyNode[]> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<HierarchyNode[]> => {
        return this.http.get<HierarchyNode[]>(
          '/api/hierarchy',
          { params: { root }, headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  getAccess({ id, userId }: { id: HierarchyNodeId; userId: UserId }): Observable<{ permission: number; }> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ permission: number; }> => {
        return this.http.get<{ permission: number; }>(
          '/api/access',
          { params: { id, userId }, headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  getAccessHierarchy(): Observable<[]> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<[]> => {
        return this.http.get<[]>(
          '/api/access/hierarchy',
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  setAccess({ path, userId, permission }: { path: HierarchyNodeId[]; userId: UserId; permission: number; }) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/access',
          { path, userId, permission },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  getAccessAssignments({ id }): Observable<{ assignments: { user: UserId; permission: number; }[] }> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ assignments: { user: UserId; permission: number; }[] }> => {
        return this.http.get<{ assignments: { user: UserId; permission: number; }[] }>(
          '/api/access/assignments',
          { params: { id }, headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  permanentlyDelete(id) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/hierarchy/delete',
          { id },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  getFavourites(): Observable<{ favourites: HierarchyNodeId[] }> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ favourites: HierarchyNodeId[] }> => {
        return this.http.get<{ favourites: HierarchyNodeId[] }>(
          '/api/hierarchy/favourites',
          { headers }
        ).pipe(
          defaultPipe()
        )
      })
    );
  }

  addToFavourites(nodeId) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/hierarchy/add-favourite',
          { nodeId },
          { headers }
        ).pipe(
          defaultPipe()
        )
      })
    );
  }

  removeFromFavourites(nodeId) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/hierarchy/delete-favourite',
          { nodeId },
          { headers }
        ).pipe(
          defaultPipe()
        )
      })
    );
  }

}
