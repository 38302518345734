import { createReducer, on } from '@ngrx/store';
import { initialState } from './state';
import * as appActions from './actions';

export const reducer = createReducer(
  initialState,

  on(appActions.isDraftCreated, (state, props) => {
    return {
      ...state,
      isDraftCreated: props.isDraftCreated
    };
  }),

  on(appActions.isDraftCreating, (state, props) => {
    return {
      ...state,
      isDraftCreating: props.isDraftCreating
    };
  }),

  on(appActions.setDraftProject, (state, props) => {
    return {
      ...state,
      draftProject: props.draftProject
    };
  }),

  on(appActions.isProjectCreated, (state, props) => {
    return {
      ...state,
      isProjectCreated: props.isProjectCreated
    };
  }),

  on(appActions.isProjectCreating, (state, props) => {
    return {
      ...state,
      isProjectCreating: props.isProjectCreating
    };
  }),

  on(appActions.setNewProject, (state, props) => {
    return {
      ...state,
      newProject: props.newProject
    };
  }),

  on(appActions.isRevisionCreated, (state, props) => {
    return {
      ...state,
      isRevisionCreated: props.isRevisionCreated
    };
  }),

  on(appActions.isRevisionCreating, (state, props) => {
    return {
      ...state,
      isRevisionCreating: props.isRevisionCreating
    };
  }),

  on(appActions.setNewRevision, (state, props) => {
    return {
      ...state,
      newRevision: props.newRevision
    };
  }),

  on(appActions.hideLoading, (state) => {
    return {
      ...state,
      loading: false
    };
  }),

  on(appActions.showLoading, (state) => {
    return {
      ...state,
      loading: true
    };
  }),

  on(appActions.setDropMenuTimer, (state, props) => {
    return {
      ...state,
      dropMenuTimer: props.dropMenuTimer
    };
  })
);
