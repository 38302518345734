import { createSVGElement, removeChildren } from '../../svg-utils';
import * as tlStyle from '../tl-style';

const { pageBreak, pageBreakLine, pageBreakMask, pageBreakClose } = tlStyle.standardClass;
const { dataPageBreakPending } = tlStyle.standardAttribute;

const labelContent = 'Remove page break';
const labelContentPending = 'Click to add page break';

export interface PageBreakOptions {
  group: string;
}

export class PageBreak {

  group: string;
  element: any = createSVGElement('g');

  constructor(options: PageBreakOptions) {

    this.group = options.group;
    this.element.setAttribute(tlStyle.standardAttribute.dataGroupUid, options.group);
    this.element.setAttribute('class', tlStyle.standardClass.pageBreak);

    this.element.__datum__ = this;

    removeChildren(this.element);

    const eBreakLine = this.element.appendChild(createSVGElement('line'));
    eBreakLine.setAttribute('class', tlStyle.standardClass.pageBreakLine);
    eBreakLine.setAttribute('x1', '0');

    const eBreakMask = this.element.appendChild(createSVGElement('line'));
    eBreakMask.setAttribute('class', tlStyle.standardClass.pageBreakMask);
    eBreakMask.setAttribute('x1', '0');

    const eBreakClose = this.element.appendChild(createSVGElement('foreignObject'));
    eBreakClose.setAttribute('class', tlStyle.standardClass.pageBreakClose);

    const eCloseDiv = eBreakClose.appendChild(document.createElement('div'));
    eCloseDiv.setAttribute('xmlns', 'ht' + 'tp://www.w3.org/1999/xhtml');

    const eCloseSpan = eCloseDiv.appendChild(document.createElement('span'));
    eCloseSpan.textContent = 'Remove page break';

  }

  position({ x, y, width, icon }): void {

    const line = this.element.querySelector(`.${pageBreakLine}`);
    const mask = this.element.querySelector(`.${pageBreakMask}`);
    const close = this.element.querySelector(`.${pageBreakClose}`);

    this.element.setAttribute('transform', `translate(${x},${y})`);

    line.setAttribute('x2', width);
    mask.setAttribute('x2', width);

    close.setAttribute('y', - (icon / 2));
    close.setAttribute('width', width);
    close.setAttribute('height', icon);
    close.style.setProperty('font-size', icon * 0.61);

  }

  appendTo(parentNode): void {
    parentNode.appendChild(this.element);
  }

  lift(): boolean {
    return !!this.element.parentNode?.removeChild(this.element);
  }

  pending(pending: boolean): void {
    const closeContent = this.element.querySelector(`.${pageBreakClose} span`);
    if (pending) {
      this.element.setAttribute(dataPageBreakPending, '');
      closeContent.textContent = labelContentPending;
    } else {
      this.element.removeAttribute(dataPageBreakPending);
      closeContent.textContent = labelContent;
    }
  }

  static retrieve(target): PageBreak|null {
    return target.closest(`.${pageBreak}`)?.__datum__ || null;
  }

}
