import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthApiService } from './auth-api.service';
import { HierarchyNodeId, RevisionId, UndoAction, RevisionData, RevisionConflict, RevisionInfo, ViewId, BaselineId, ViewGroupMap } from 'tp-traqplan-core/dist/data-structs';
import { defaultPipe } from './common';
import { ReqHeader, PoapUpdateRequest } from './structs';
import { PoapUpdate } from '../state/structs';

@Injectable({
  providedIn: 'root'
})
export class RevisionsApiService {

  constructor(
    private http: HttpClient,
    private authApi: AuthApiService
  ) { }

  getData({ view, baseline, id }: { view: ViewId; baseline: BaselineId; id: RevisionId }): Observable<RevisionData> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<RevisionData> => {
        const params: any = { view, id };
        if (baseline) {
          params.baseline = baseline;
        }
        return this.http.get<RevisionData>(
          '/api/revisions',
          { params, headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  getViewData({ workspace, view }: { workspace: HierarchyNodeId; view?: ViewId; }): Observable<RevisionInfo[]> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<RevisionInfo[]> => {
        const params: any = { workspace };

        if (view) {
          params.view = view;
        }

        return this.http.get<RevisionData>(
          '/api/revisions/views',
          { params, headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  updateDraft(update: PoapUpdateRequest): Observable<{ actions: UndoAction[] }> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ actions: UndoAction[] }> => {
        return this.http.post<{ actions: UndoAction[] }>(
          '/api/revisions/update-draft',
          update,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  bulkUpdateDraft(
    { revisionId, viewId, poapUpdates, }: { revisionId: RevisionId; viewId: ViewId; poapUpdates: Partial<PoapUpdate>[] }
  ): Observable<{ actions: UndoAction[] }> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ actions: UndoAction[] }> => {
        return this.http.post<{ actions: UndoAction[] }>(
          '/api/revisions/bulk-update-draft',
          { revisionId, viewId, poapUpdates },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  createDraft({ project, from, view }: { project: HierarchyNodeId; from?: RevisionId; view?: ViewId }): Observable<{ data: RevisionData; }> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ data: RevisionData; }> => {
        return this.http.post<{ data: RevisionData; }>(
          '/api/revisions/create-draft',
          { project, from, view },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  publishDraft({ id, label, merge = false, view, resolution }: { id: RevisionId; label?: string; merge?: boolean; view?: ViewId; resolution?: any }): Observable<RevisionConflict | null> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<RevisionConflict | null> => {
        return this.http.post<RevisionConflict | null>(
          '/api/revisions/publish-draft',
          { id, label, merge, view, resolution },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  discardDraft({ id, view }: { id: RevisionId, view?: ViewId }) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/revisions/discard-draft',
          { id, view },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  getUndoStack({ revisionId, viewId }: { revisionId: RevisionId; viewId: ViewId; }): Observable<{ actions: UndoAction[]; }> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ actions: UndoAction[]; }> => {
        return this.http.get<{ actions: UndoAction[]; }>(
          '/api/revisions/undo',
          { params: { revisionId, viewId }, headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  moveRevisionPointer({ revisionId, viewId, stamp }: { revisionId: RevisionId; viewId: ViewId; stamp: Date; }): Observable<{ data: RevisionData; }> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ data: RevisionData; }> => {
        return this.http.post<{ data: RevisionData; }>(
          '/api/revisions/move-pointer',
          { revisionId, viewId, stamp },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  clearRevisionPointer({ revisionId, viewId }: { revisionId: RevisionId; viewId: ViewId; }): Observable<{ data: RevisionData; }> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ data: RevisionData; }> => {
        return this.http.post<{ data: RevisionData; }>(
          '/api/revisions/clear-pointer',
          { revisionId, viewId },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  labelRevision({ revisionId, label }: { revisionId: RevisionId; label: string }): Observable<string> {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap((headers): Observable<string> => {
        return this.http.post<string>(
          '/api/revisions/label',
          { revisionId, label },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  createGroupsFromOutlineNumbers({ revisionId, groups }) {
    return this.authApi.headers({ requires: ReqHeader.account | ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<ViewGroupMap>(
          '/api/revisions/create-groups-from-outline-numbers',
          { revisionId, groups },
          { headers }
        );
      })
    );
  }

}
