<app-modal-window
  class = "view-modal-window"
  header="Create View"
  [(visible)]="visible"
  (actionSelected)="onActionSelected($event)"
  (closed)="onClose()"
  [actions]="actions"
>
  <form
    class="tp-form"
    [formGroup]="form"
  >

    <div class="tp-form__field">
      <label for="name">Name</label>
      <input
        pInputText
        name="name"
        formControlName="name"
      />
    </div>

    <!-- <div
      class="tp-form__error"
      *ngIf="form.controls.name.errors?.namingConflict"
    >
      The name '{{form.controls.name.value}}' is taken
    </div> -->

    <div
      class="tp-form__success"
      *ngIf="nameExists"
    >
      The name '{{form.controls.name.value}}' already exists, do you want to add it into this workspace?
    </div>

  </form>

</app-modal-window>
