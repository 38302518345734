import { timeFormat } from 'd3';

export const standardDateTime = timeFormat('%H:%M %d-%b-%Y');

export const standardFormat = timeFormat('%a %d/%m/%Y');

export const shortFormat = timeFormat('%d-%b');

/**
 * <pre>
 * Adds support for 'dd/mm/yyyy' to native Date constructor
 * also accepts '-' and '.' as delimiters
 * </pre>
 */
export function parseDateString(str: string): Date {
  if (!str?.length) return;
  const match = /(\d\d?)[/\-.](\d\d?)[/-](\d\d\d\d)/g.exec(str);
  let date;
  if (match) {
    const [, dd, mm, yyyy] = match;
    date = new Date(
      +dd,
      +mm - 1,
      +yyyy
    );
  } else {
    date = new Date(str);
  }
  return (date?.getTime && date?.getTime()) ? date : null;
}
