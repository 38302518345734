// Angular imports
import { Component, OnInit, AfterViewInit, Input, Output, ViewChild, ViewChildren, QueryList, ElementRef, EventEmitter } from '@angular/core';

// Third-party imports
import { PoapExportSettings, PoapExportBreakMode } from 'tp-traqplan-core/dist/data-structs';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { VIEW_MODE, LayoutOptions } from 'tp-traqplan-core/dist/timeline/tl-structs';
import * as timelineRender from 'tp-traqplan-core/dist/timeline/timeline-render';
import { TimelineLayout } from 'tp-traqplan-core/dist/timeline/timeline-layout';
import { TimelineEventChannel } from 'tp-traqplan-core/dist/timeline/timeline-event-channel';
import { PageBreak } from 'tp-traqplan-core/dist/timeline/timeline-core/core-page-break';
import { FillPageIcon } from 'tp-traqplan-core/dist/timeline/timeline-core/core-fill-page-icon';
import { getAbsoluteBBox, deepSelector } from 'tp-traqplan-core/dist/svg-utils';
import { standardFormat } from 'tp-traqplan-core/dist/date-format';
import * as groupUtils from 'tp-traqplan-core/dist/task-group-utils';
import * as tlStyle from 'tp-traqplan-core/dist/timeline/tl-style';

// Application imports
import { TimelineCoreComponent } from '../timeline-core/timeline-core.component';
import * as timelineExport from '../timeline-export';
import { ModalService } from '../../../modals/modal.service';
import { HierarchyApiService } from '../../../api/hierarchy-api.service';

const dropDownOptions = a => a.map(v => ({ label:v, value:v }));

const BOUNDARY_START_PROPS = [
  'printStart',
  'viewportStart',
  'plotStart',
  'wrapStart'
];
const BOUNDARY_FINISH_PROPS = [
  'printFinish',
  'viewportFinish',
  'plotFinish',
  'wrapFinish'
];
// generic page dimensions specified in inches:
const PAGE_MARGINS = [ .25, .25, .25, .25 ];
const LEGEND_COL_MIN_WIDTH = 2;
const SINGLE_PAGE_ROW_CUTOFF = Infinity;
const PAGEBREAK_ICON_SIZE = 0.33;
const FILLPAGEICON_SIZE = 0.495
const DEFAULT_FONT_SIZE = 15;

@Component({
  selector: 'app-timeline-export',
  templateUrl: './timeline-export.component.html',
  styleUrls: ['./timeline-export.component.css']
})
export class TimelineExportComponent implements OnInit, AfterViewInit {

  @ViewChildren('sheet') sheets: QueryList<any>;
  @ViewChildren(TimelineCoreComponent) cores: QueryList<TimelineCoreComponent>;
  @ViewChild('preview') preview: ElementRef;
  @ViewChild('previewAreaWindow') previewAreaWindow: ElementRef;

  //@Input() milestoneCategories: MilestoneCategory[];
  @Input() layout: TimelineLayout;
  // ensure inputted settings are not mutated:
  @Input() set settings(s: PoapExportSettings) {
    this.userSettings = { ...s };
    this.userFontSize = s.fontSize;
  }

  @Output() closeWindow = new EventEmitter<boolean>();
  @Output() settingsChange = new EventEmitter<Partial<PoapExportSettings>>();

  // required for timeline-core instance. Not used.
  public eventChannel = new TimelineEventChannel();

  public modalVisible: boolean = false;
  public modalActions: ModalWindowAction[];

  public modalConfirmActions: ModalWindowAction[];
  public modalMoveVisible: boolean = false;
  public modalAddVisible: boolean = false;
  public modalResetVisible: boolean = false;
  public modalFillVisible: boolean = false;
  public isNotDraft: boolean = false;
  //screen size of preview:
  public previewWidth: number;
  public previewHeight: number;
  // page layout sizes in print pixels (adjusted for dpi):
  public sheetWidth: number = 0;
  public sheetHeight: number = 0;
  public sheetMargins: number[] = [ 0, 0, 0, 0];
  public sheetHeaderHeight: number = 0;
  public sheetHeaderDateSize: number = 0;
  public sheetHeaderTitleSize: number = 0;
  public sheetTimelineHeight: number = 0;
  public sheetTimelineWidth: number = 0;
  public sheetPageBreakIconSize: number = 0;
  public sheetFillPageIconSize: number = 0;
  public draftButtonHeight: number = 0;
  public draftButtonWidth: number = 0;
  public draftTextSize:number = 25;
  public draftTextX:number = 12;
  public draftTextY:number = 12;
  public showTooltip:any = null;

  public groupData: any = [];

  // persisted settings take from @Input settings:
  public userSettings: Partial<PoapExportSettings> = {};
  public userFontSize: number;
  // user export settings options:
  public rotatableRatios = new Set(timelineExport.sizes);
  public formatOptions = dropDownOptions(timelineExport.formats);
  public ratioOptions = dropDownOptions(timelineExport.sizes);
  public orientationOptions = dropDownOptions(timelineExport.orientations);
  public resolutionOptions = [
    { label: 'For Print (High Res)', value: 300 },
    { label: 'For Screen (Low Res)', value: 96 }
  ];

  public recomendedFontSize: number = 10;
  public timelinePrintStart: Date;
  public timelinePrintFinish: Date;
  public currentDateFormat: string;
  public textDraftFont: number = 0;
  // under some circumstances it is not possible to fit timeline within a single page:
  public singlePagePossible: boolean = true;

  private breakMode: number = PoapExportBreakMode.Single;
  // original layout settings, extended to cover export (i.e add legend config etc):
  private baseSettings: LayoutOptions;
  // settings scaled and adjusted for print sizes (adjusted for scale and dpi):
  private exportSettings: LayoutOptions;
  // attempt to duplicate user adjusted group width in main screen by getting ratio relative to timeline width:
  //private groupWidthRatio: number;

  public pageFittings: timelineExport.PageFitting[] = [];

  private pageBreakDragAction: boolean = false;
  private pageBreakDragOrigin: string;
  private pageBreakDragTarget: PageBreak;
  private pageBreakHoverTarget: PageBreak;
  private pageBreakAddTarget: string;
  private checkRan:boolean = false;

  private fillPageTarget: timelineExport.PageFitting;

  public get previewWidthPx(): string {
    return `${this.previewWidth}px`;
  }

  public get previewHeightPx(): string {
    return `${this.previewHeight}px`;
  }

  public get headerLogoHeight(): number {
    return (this.sheetHeaderHeight || 0) * 0.8;
  }

  public get headerLogoWidth(): number {
    return this.headerLogoHeight * 2
  }

  public get headerDateString(): string {
    return this.userSettings?.date ? standardFormat(this.userSettings.date) : '';
  }

  public get displayHeader(): boolean {
    return timelineExport.headerFormats.includes(this.userSettings.format);
  }

  public get singlePageDisabled(): boolean {
    return this.userSettings.breakMode === PoapExportBreakMode.Single || !this.singlePagePossible || this.layout?.fittingMeta?.totalRows > SINGLE_PAGE_ROW_CUTOFF;
  }

  constructor(
    private host: ElementRef, private modalService: ModalService, private hierarchyApi: HierarchyApiService
  ) {}

  ngOnInit(): void {
    this.modalActions = [
      {
        label: 'Export',
        type: 'positive',
        enterKey: true
      },
      {
        label: 'Cancel',
        type: 'neutral',
        escKey: true
      }
    ];

    this.modalConfirmActions = [
      {
        label: 'Confirm',
        type: 'positive',
        enterKey: true,
        confirm: true
      },
      {
        label: 'Cancel',
        type: 'neutral',
        escKey: true,
        cancel: true
      }
    ];

  }

  ngAfterViewInit(): void {

    const style = document.createElement('style');

    style.textContent = tlStyle.screenOnlyStyle();

    this.preview.nativeElement.appendChild(style);
    this.getLocalDateFormat();

  }

  getLocalDateFormat() {
      // const date = new Date();
      // const parts = new Intl.DateTimeFormat(undefined, {day:'2-digit', month:'2-digit', year:'2-digit'}).formatToParts(date);


      // const dayIndex = parts.findIndex(part => part.type === 'day');
      // const monthIndex = parts.findIndex(part => part.type === 'month');

    // let dateFormat: string;
    // if (dayIndex < monthIndex) {
    //   dateFormat = 'dd/mm/yy';
    // } else {
    //   dateFormat = 'mm/dd/yy';
    // }

    const dateFormat ='dd M yy'
    setTimeout(() => {
      // Assign the value after the change detection cycle
      this.currentDateFormat = dateFormat;
    }, 0);

    return this.currentDateFormat;
  }

  hoverDraft(el:any):void{
    this.showTooltip=el;
  }
  stopHoverDraft():void{
    this.showTooltip=null;
  }


  onModalClose(): void {
    this.pageFittings = [];
    this.settingsChange.emit({
      ...this.userSettings,
      fontSize: this.userSettings.breakMode === PoapExportBreakMode.Multi ? this.userSettings.fontSize : this.userFontSize
     });
    this.closeWindow.emit(true);
  }

  onConfirmReset(action: ModalWindowAction): void {
    if (action.confirm) {
      this.userSettings.breakMode = PoapExportBreakMode.Single;
      this.userSettings.fontSize = this.baseSettings.fontSize;
      this.userSettings.pageBreaks = [];
      this.generateExport().then(() => this.updatePreview())
    }
  }

  onConfirmAdd(action: ModalWindowAction): void {
    if (action.confirm) {
      this.userSettings.breakMode = PoapExportBreakMode.Multi;
      this.userSettings.pageBreaks.push(this.pageBreakAddTarget);
      this.generateExport().then(() => this.updatePreview());
    }
  }

  onConfirmMove(action: ModalWindowAction): void {
    if (action.confirm) {
      this.userSettings.pageBreaks.splice(this.userSettings.pageBreaks.indexOf(this.pageBreakDragOrigin),1);
      this.userSettings.pageBreaks.push(this.pageBreakDragTarget.group);
      this.generateExport().then(() => this.updatePreview());
    }
    this.pageBreakDragAction = false;
    this.pageBreakDragTarget = null;
  }

  onConfirmFill(action: ModalWindowAction): void {
    if (action.confirm) {
      const settings = {
        ...this.exportSettings,
        groupMap: this.fillPageTarget.layout.settings.groupMap
      };

      const layout = timelineExport.fitToPage(settings, this.sheetTimelineHeight, Infinity); // no maximum scale

      this.userSettings.fontSize = timelineExport.getScreenSize(layout.settings.fontSize, this.userSettings.resolution);

      this.generateExport().then(() => this.updatePreview());
    }
    this.fillPageTarget = null;
  }

  onModalActionSelected(action): void {
    if (action.label.toLowerCase() === 'export') {
      this.download();
    }
    this.onModalClose();
  }

  onSettingsChange(key?: string, value?: any): void {
    if (key) {
      this.userSettings[key] = value;
    }
    this.generateExport()
    .then(() => this.updatePreview());
  }

  onClickIncreaseFontSize(): void {
    this.userSettings.breakMode = PoapExportBreakMode.Multi;
    const ceil = Math.ceil(this.userSettings.fontSize);
    this.userSettings.fontSize = (ceil === this.userSettings.fontSize) ? this.userSettings.fontSize + 1 : ceil;
    this.generateExport().then(() => this.updatePreview());
  }

  onClickDecreaseFontSize(): void {
    this.userSettings.breakMode = PoapExportBreakMode.Multi;
    const floor = Math.floor(this.userSettings.fontSize);
    this.userSettings.fontSize = (floor === this.userSettings.fontSize) ? this.userSettings.fontSize - 1 : floor;
    this.generateExport().then(() => this.updatePreview());
  }

  onClickResetFontSize(): void {
    this.userSettings.breakMode = PoapExportBreakMode.Multi;
    this.userSettings.fontSize = this.recomendedFontSize;
    this.generateExport().then(() => this.updatePreview());
  }

  onClickFitToOnePage(): void {
    if (!this.singlePageDisabled && this.singlePagePossible) {
      this.modalResetVisible = true;
    }
  }

  onLogoChange(event): void {
    const [ blob ] = event.target.files;
    if (blob) {
      timelineExport.generateImageURLs([ blob ], 'PNG')
        .then(url => {
          this.userSettings.logo = url
        });
    }
  }

  onSheetMouseover(event): void {
    if (event.target.matches(deepSelector('.group-container'))) {

      const swimlaneGroup = event.target.closest('.group-container');
      const uid = swimlaneGroup.getAttribute(tlStyle.standardAttribute.dataGroupUid);
      const sheet = event.target.closest('.sheet');

      if (this.pageBreakDragAction) {
        // if sheet has a page break and it does not belong to the target group, move page break to target group:
        const pageBreak = sheet.querySelector(`.${tlStyle.standardClass.pageBreak}`);
        if (pageBreak) {
          const pageBreakGroup = pageBreak.getAttribute(tlStyle.standardAttribute.dataGroupUid);
          if (pageBreakGroup !== uid) {
            pageBreak.remove();
            this.pageBreakDragTarget = this.positionPageBreak(swimlaneGroup, false);
          }
        }
        return;
      }
      // no drag action - show hover:
      const pageBreak = sheet.querySelector(`.${tlStyle.standardClass.pageBreak}[${tlStyle.standardAttribute.dataGroupUid}='${uid}']`);
      if (!pageBreak) {
        this.pageBreakHoverTarget = this.positionPageBreak(swimlaneGroup, false, true);
      }

    }
  }

  onSheetMouseout(): void {
    if (!this.pageBreakDragAction && this.pageBreakHoverTarget) {
      this.pageBreakHoverTarget.lift();
      this.pageBreakHoverTarget = null;
    }
  }

  onSheetMousedown(event): void {
    if (this.userSettings.breakMode === PoapExportBreakMode.Multi && event.target.matches(deepSelector(`.${tlStyle.standardClass.pageBreak}`))) {
      const pageBreak = event.target.closest(`.${tlStyle.standardClass.pageBreak}`);
      const groupUid = pageBreak.getAttribute(tlStyle.standardAttribute.dataGroupUid);
      this.pageBreakDragOrigin = groupUid;
      this.pageBreakDragAction = true;
    }
  }

  onSheetMouseup(): void {
    this.pageBreakDragAction = false;
    if (this.userSettings.breakMode === PoapExportBreakMode.Multi && this.pageBreakDragTarget) {
      this.modalMoveVisible = true;
    }
  }

  onSheetClick(event): void {

    if (event.target.matches(deepSelector(`.${tlStyle.standardClass.pageBreakClose}`))) {
      return this.onClickPageBreakClose(event);
    }

    if (event.target.matches(deepSelector('.group-container'))) {
      return this.onClickSwimlane(event);
    }

    if (event.target.matches(deepSelector(`.${tlStyle.standardClass.fillPageIcon}`))) {
      this.onClickFillPage(event);
    }

  }

  private onClickPageBreakClose(event): void {
    const pageBreak = event.target.closest(`.${tlStyle.standardClass.pageBreak}`);
    const group = pageBreak.getAttribute(tlStyle.standardAttribute.dataGroupUid);
    if (group) {
      this.removePageBreak(group);
    }
  }

  private onClickSwimlane(event): void {
    const swimlaneGroup = event.target.closest('.group-container');
    const uid = swimlaneGroup.getAttribute(tlStyle.standardAttribute.dataGroupUid);
    if (uid && !this.userSettings.pageBreaks.includes(uid)) {
      this.pageBreakAddTarget = uid;
      this.modalAddVisible = true;
    }
  }

  private onClickFillPage(event): void {
    const fillPageIcon = FillPageIcon.retrieve(event.target);

    this.fillPageTarget = fillPageIcon.datum;

    this.modalFillVisible = true;

  }

  private removePageBreak(group: string): void {
    this.userSettings.pageBreaks.splice(this.userSettings.pageBreaks.indexOf(group),1);
    this.generateExport().then(() => this.updatePreview());
  }

  /**
   * calculate overall sheet layout dimensions in print pixels:
   */
  private updateSheetDimensions(): void {

    const [ width, height ] = timelineExport.getPageSize( this.userSettings.ratio, this.userSettings.orientation, this.userSettings.resolution );

    this.sheetWidth = width;
    this.sheetHeight = height;
    this.sheetMargins = PAGE_MARGINS.map(m => m * this.userSettings.resolution);
    this.sheetHeaderTitleSize = timelineExport.getPrintSize(this.userSettings.fontSize * 2, this.userSettings.resolution);
    this.sheetHeaderHeight = timelineExport.headerFormats.includes(this.userSettings.format) ? this.sheetHeaderTitleSize * 2.2 : 0;
    this.sheetHeaderDateSize = this.sheetHeaderTitleSize * 0.5;
    this.sheetTimelineHeight = this.sheetHeight - this.sheetMargins[0] - (this.sheetMargins[2] * 2) - this.sheetHeaderHeight;
    this.sheetTimelineWidth = this.sheetWidth - this.sheetMargins[1] - this.sheetMargins[3];
    this.draftButtonHeight = this.sheetTimelineHeight * .015;
    this.sheetPageBreakIconSize = PAGEBREAK_ICON_SIZE * this.userSettings.resolution;
    this.sheetFillPageIconSize = FILLPAGEICON_SIZE * this.userSettings.resolution;
    this.draftTextSize = this.sheetHeaderDateSize/1.1

  }

  /**
   * fit page preview to available space:
   */
  private updatePreviewDimensions(): void {

    const areaWidth = this.previewAreaWindow.nativeElement.clientWidth;
    const areaHeight = this.previewAreaWindow.nativeElement.clientHeight;
    const sheetRatio = this.sheetHeight / this.sheetWidth;
    const areaRatio = areaHeight / areaWidth;
    // preview area will either fit full width OR full height of available space:
    if (sheetRatio < areaRatio) {
      this.previewWidth = areaWidth;
      this.previewHeight = sheetRatio * areaWidth;
    } else {
      this.previewHeight = areaHeight;
      this.previewWidth = areaHeight / sheetRatio;
    }

  }

  private configureExportSettings(): void {
    const scaleFactor = this.userSettings.fontSize / DEFAULT_FONT_SIZE;
    // scale dimension settings to match font size (clamp font size to original value so avoid rounding error):
    const scaled: LayoutOptions = {
      ...timelineExport.scaleSettings({ ...this.baseSettings }, scaleFactor),
      fontSize: this.userSettings.fontSize,
      viewportHeight: this.sheetTimelineHeight,
      legendFittingMode: this.userSettings.legendFittingMode,
      legendPosition: this.userSettings.legendPosition
    };

    BOUNDARY_START_PROPS.forEach(p => { scaled[p] = this.timelinePrintStart });
    BOUNDARY_FINISH_PROPS.forEach(p => { scaled[p] = this.timelinePrintFinish });

    timelineExport.scalableLayoutProps.forEach(prop => {
      scaled[prop] = timelineExport.getPrintSize(scaled[prop], this.userSettings.resolution);
    });

    scaled.viewportWidth = this.sheetTimelineWidth;
    //scaled.groupsWidth = this.groupWidthRatio * this.sheetTimelineWidth;
    // build new scale:
    //scaled.scale = scaleTime()
    //  .domain([ scaled.viewportStart, scaled.viewportFinish ])
    //  .range([ scaled.groupsWidth, this.sheetTimelineWidth ]);

    this.exportSettings = scaled;

  }

  private generateExport(): Promise<void> {

    return new Promise(resolve => {
      this.updateSheetDimensions();
      this.updatePreviewDimensions();
      this.configureExportSettings();

      if (this.userSettings.breakMode === PoapExportBreakMode.Single) {
        this.fitSinglePage();
      } else {
        this.fitMultipage();
      }
      setTimeout(() => resolve());
    });
  }

  private fitSinglePage(): void {

    const layout = timelineExport.fitToPage(this.exportSettings, this.sheetTimelineHeight);
    if (layout) {
      this.pageFittings = [<timelineExport.PageFitting>{
        layout: layout
      }];
      this.userSettings.fontSize = timelineExport.getScreenSize(layout.settings.fontSize, this.userSettings.resolution);
      if(this.userSettings.fontSize <7){
        this.userSettings.fontSize = 7;
      }
    } else {
      this.userSettings.breakMode = PoapExportBreakMode.Multi;
      this.singlePagePossible = false;
      this.fitMultipage();
    }
  }

  private fitMultipage(): void {

    const maximumRowCount = TimelineLayout.getMaximumRowCount(this.sheetTimelineHeight, this.exportSettings, true, true);
    this.pageFittings = [];

    // create stack of group maps, divided according to page break positions:
    const baseMap = this.exportSettings.groupMap;
    const groupMaps = [ baseMap ];
    const pageBreaks = groupUtils.matchTreeOrder(baseMap, this.userSettings.pageBreaks);

    for (const pageBreak of pageBreaks) {
      const { groupMap, removed } = groupUtils.breakGroupMap(groupMaps.pop(), pageBreak);
      const nextMap = groupUtils.groupMapFactory(removed, groupUtils.getRootOrder(groupMap));
      groupMaps.push(groupMap, nextMap);
    }

    while (groupMaps.length) {

      const groupMap = groupMaps.shift();

      if (Object.keys(groupMap).length) {
        const pageFitting = { layout: new TimelineLayout() };

        pageFitting.layout.layout({ ...this.exportSettings, groupMap, maximumRowCount });

        if (Object.keys(pageFitting.layout.unfitted).length) {
          groupMaps.unshift(pageFitting.layout.unfitted);
        }

        this.pageFittings.push(pageFitting);
      }
    }
  }

  private updatePreview(): void {
    this.groupData = [];

    for (let i = 0; i < this.pageFittings.length; i++) {

      const pageFitting = this.pageFittings[i];

      const sheet = this.sheets.find(v => v.nativeElement.dataset.index == i);
      const core = this.cores.find(v => v.host.nativeElement.dataset.index == i);

      // this should never really occur:
      if (!core || !sheet) {
        continue;
      }

      core.render();

      const clone = core.cloneContent();


      timelineRender.randomizeClipIds(clone);
      timelineRender.removeScreenOnlyNodes(clone);

      const gTimeline = sheet.nativeElement.querySelector('[data-core-container]');

      [...gTimeline.children].forEach(c => c.remove());

      gTimeline.appendChild(clone);

      timelineExport.renderDraftLabels(gTimeline, pageFitting.layout.settings);

      if (this.userSettings.breakMode === PoapExportBreakMode.Multi) {
        this.positionFillPageIcon(sheet.nativeElement, pageFitting);
      }

      setTimeout(() => clone.querySelector('#viewportHClip').setAttribute('height','100%'));

    }

    if (this.userSettings.breakMode === PoapExportBreakMode.Multi) {
      this.updatePageBreaks();
    }

  }

  private updatePageBreaks(): void {

    const { dataGroupUid } = tlStyle.standardAttribute;
    const queryRoot = this.preview.nativeElement;

    for (const uid of this.userSettings.pageBreaks) {
      const swimlaneGroup = [ ...queryRoot.querySelectorAll(`.sheet .group-container[${dataGroupUid}='${uid}']`) ].pop();
      if (swimlaneGroup) {
        this.positionPageBreak(swimlaneGroup);
      }
    }

  }

  private positionPageBreak(swimlaneGroup, alignToParent:boolean = true, setPendingFlag = false): PageBreak {

    const sheet = swimlaneGroup.closest('.sheet');
    const uid = swimlaneGroup.getAttribute(tlStyle.standardAttribute.dataGroupUid);
    const pageBreak = new PageBreak({ group: uid });
    const superParent = groupUtils.getSuperParent(this.exportSettings.groupMap, uid);
    const swimlaneParent = sheet.querySelector(`.group-container[${tlStyle.standardAttribute.dataGroupUid}='${superParent.id}']`);
    const bBox = getAbsoluteBBox(alignToParent ? swimlaneParent : swimlaneGroup, sheet);

    const x = this.sheetMargins[3];
    const y = bBox.y + bBox.height;
    const width = this.sheetTimelineWidth;
    const icon = this.sheetPageBreakIconSize;

    if (setPendingFlag) {
      pageBreak.pending(true);
    }

    pageBreak.position({ x, y, width, icon });
    pageBreak.appendTo(sheet);

    return pageBreak;

  }

  private positionFillPageIcon(sheet: SVGSVGElement, pageFitting: timelineExport.PageFitting): void {
    // get any group on page to find super parent:
    const group = Array.from(sheet.querySelectorAll(`.group-container`))?.pop().getAttribute(tlStyle.standardAttribute.dataGroupUid);

    if (!group) return;

    const superParent = groupUtils.getSuperParent(this.exportSettings.groupMap, group);
    const groupContainer = sheet.querySelector(`.group-container[${tlStyle.standardAttribute.dataGroupUid}='${superParent.id}']`);
    const bBox = getAbsoluteBBox(groupContainer as SVGGElement, sheet);

    const groupY = bBox.y + bBox.height;
    const x = this.sheetMargins[3];
    const y = groupY + (this.sheetTimelineHeight - groupY) / 2;
    const width = this.sheetTimelineWidth;
    const height = this.sheetFillPageIconSize;

    if (groupY / this.sheetTimelineHeight < 0.8) {
      const fillPageIcon = new FillPageIcon(pageFitting);
      fillPageIcon.position({ x, y, width, height });
      fillPageIcon.appendTo(sheet);
    }

  }

  private download(): void {
    const sheets = this.sheets.map(s => s.nativeElement);

    timelineExport.cleanSVGs(sheets);

    const svgBlobs = timelineExport.createSVGBlobs(sheets, this.pageFittings);

    if (this.userSettings.format === 'SVG') {
      timelineExport.downloadBlobs(svgBlobs, 'svg');
      return;
    }

    timelineExport.generateImageCanvases(svgBlobs)
      .then(canvases => {
        if (this.userSettings.format === 'PDF') {
          const urls = timelineExport.createImageURLs(canvases, 'JPEG');
          timelineExport.downloadPDF(urls, this.userSettings.orientation, this.sheetWidth, this.sheetHeight);
          return;
        }
        if (timelineExport.imageFormats[this.userSettings.format]) {
          timelineExport.generateImageBlobs(canvases, this.userSettings.format)
            .then(blobs => {
              timelineExport.downloadBlobs(blobs, this.userSettings.format.toLowerCase());
            });
        }
      });
  }

  displayWindow(): void {
    this.modalVisible = true;
    // allow for modal transtion to complete:
    setTimeout(() => {
      this.baseSettings = createBaseSettings(this.layout.settings);
      /* ratio will be used to determine PREFERABLE width of timeline. Actual width will
         be clamped to minimum width dictated by layout function */
      //this.groupWidthRatio = getGroupWidthRatio(this.layout.settings);
      if (!this.userSettings.fontSize) {
        this.userSettings.fontSize = this.baseSettings.fontSize;
      }

      this.timelinePrintStart = this.baseSettings.viewMode === VIEW_MODE.Print ? this.baseSettings.printStart : this.baseSettings.viewportStart;
      this.timelinePrintFinish = this.baseSettings.viewMode === VIEW_MODE.Print ? this.baseSettings.printFinish : this.baseSettings.viewportFinish;

      if (this.layout.fittingMeta.totalRows > SINGLE_PAGE_ROW_CUTOFF) {
        this.singlePagePossible = false;
      } else {
        this.singlePagePossible = true;
      }

      this.generateExport().then(() => this.updatePreview());
    }, 400);
  }

}

/**
 * configure settings for use in export
 */
function createBaseSettings(settings: LayoutOptions): LayoutOptions {

  const baseSettings = {...settings};
  // preserve as-rendered group root order:
  baseSettings.groupMap = groupUtils.groupMapFactory(
    Object.values(baseSettings.groupMap),
    groupUtils.getRootGroups(baseSettings.groupMap).map(g => g.id)
  );
  // set legend settings based on active settings:
  Object.assign(baseSettings, {
    displayLegend: true,
    legendRowPadding: baseSettings.taskPadding * 2,
    legendFontSize: baseSettings.fontSize,
    legendColumnPadding: baseSettings.taskPadding,
    legendColumnMinWidth: LEGEND_COL_MIN_WIDTH * 96
  });

  return baseSettings;

}
