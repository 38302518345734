import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, distinctUntilChanged, shareReplay, take, tap, debounceTime, switchMap, takeUntil} from 'rxjs/operators';
import { access } from 'tp-common';
import { outlineNumbers as oNum } from 'tp-common/revisions';
import { HierarchyNode, RevisionData, RevisionId, PoapTask, View, ViewLine, User } from 'tp-traqplan-core/dist/data-structs';
import { MetaTaskField, PoapTaskFieldChange, PoapGroupFieldChange, PoapChange, PoapRevisionData, PoapData } from 'tp-traqplan-core/dist/workspace-structs';
import { RevisionsApiService } from '../../api/revisions-api.service';
import { ViewApiService } from '../../api/view-api.service';
import { ViewLinesApiService } from '../../api/view-lines-api.service';
import { writableProjectTaskFields, writableViewTaskFields } from '../constants';
import { State } from '../../state';
import * as poapActions from '../../state/poap/actions';
import { isDataStoreReady } from '../../state/helpers';
import { taskMutators, groupMutators } from './update-mutators';
import { taskActions } from './update-actions';
import { convertToViewGroup } from 'tp-traqplan-core/dist/task-group-utils';

@Injectable({
  providedIn: 'root'
})
export class PoapUpdateService implements OnDestroy {

  latest$: Observable<PoapData | null>;

  user: User;

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<State>, private revisionsApi: RevisionsApiService, private viewApi: ViewApiService, private viewLinesApi: ViewLinesApiService
  ) {
    this.latest$ = this.store.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged((a, b) => {
        return a.poap.revisions.value === b.poap.revisions.value
          && a.projects.tree.selected === b.projects.tree.selected
          && a.projects.hierarchy.value === b.projects.hierarchy.value
          && a.poap.milestones.value === b.poap.milestones.value
          && a.poap.viewLines.value === b.poap.viewLines.value
          //&& this.isGroupOutlineLevelByProjectChanged(a.poap.groupOutlineLevelByProject, b.poap.groupOutlineLevelByProject)
          //&& this.isTaskOutlineLevelsByProjectChanged(a.poap.taskOutlineLevelsByProject, b.poap.taskOutlineLevelsByProject)
      }),
      debounceTime(30),
      map((state: State): PoapData | null => {
        if ([state.projects.hierarchy, state.poap.revisions, state.poap.milestones, state.poap.viewLines].every(ds => isDataStoreReady(ds))) {
          return this.mapPoapData(state);
        }
        return null;
      }),
      shareReplay(1)
    );

    this.store.pipe(
      takeUntil(this.destroy$),
      tap(state => {
        this.user = state.auth.user;
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  mapPoapData(state: State): PoapData | null {

    const { workspace, projects, selected, order, revisions, poapData /*, groupOutlineLevelByProject, taskOutlineLevelsByProject,*/} = this.initPoapData(state);

    for (const [revId, select] of Object.entries(selected)) {
      if (!select) {
        continue;
      }

      const revision = Object.assign({}, revisions[revId]);
      // nothing should be rendered until all revisions are available:
      if (!revision) return null;

      const project = projects.find(proj => proj.id === revision.project);

      const rootGroup = revision?.groups ? revision.groups[revision.groupRoot] : null;

      if (!project || !rootGroup) return null;
      //const groupOutlineLevel = groupOutlineLevelByProject[project.id];
      //const taskOutlineLevels = taskOutlineLevelsByProject[project.id];

      // Show groups as outline levels (Swimlanes to mirror project structure)
      //this.handleShowGroupsAsOutlineLevels(revision, project, rootGroup, groupOutlineLevel);

      // Show tasks as outline levels (Selects tasks from X levels deep)
      //this.handleShowTasksAsOutlineLevels(revision, project, taskOutlineLevels);

      // root group is always labelled as the project:
      rootGroup.name = project.name;

      poapData.projects[project.id] = project;
      poapData.revisions[revId] = revision;
      poapData.tasks.push(...revision.tasks);
      poapData.groupRoots.push(rootGroup);

      poapData.tasks.forEach((task) => {
        const projectRevision = project?.revisions?.find(({ id }) => id === task.revision);

        if (projectRevision) {
          const isPublished = !!projectRevision.published;
          task.readOnly = !isPublished ? (task.importedId && !projectRevision.createdFrom ? !workspace.allowImportedEdits : false) : true;
        }
      });

      Object.assign(poapData.groups, revision.groups);
    }

    poapData.groupRoots = [
      ...poapData.groupRoots.filter(r => order.indexOf(r.id) !== -1).sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id)),
      ...poapData.groupRoots.filter(r => order.indexOf(r.id) === -1).sort((a, b) => a.name?.localeCompare(b.name || '') || 0)
    ];

    return poapData;
  }

  private initPoapData(state) {
    const workspace = state.account.root.selected;
    const projects = state.projects.hierarchy.value;
    const selected = state.projects.tree.selected;
    const order = state.poap.viewSelected.projectOrder;
    const revisions = state.poap.revisions.value;
    //const groupOutlineLevelByProject = state.poap.groupOutlineLevelByProject;
    //const taskOutlineLevelsByProject = state.poap.taskOutlineLevelsByProject;
    const poapData: PoapData = {
      projects: {},
      revisions: {},
      groupRoots: [],
      groups: {},
      tasks: [],
      milestones: state.poap.milestones.value,
      viewLines: state.poap.viewLines.value
    };
    return { workspace, projects, selected, order, revisions, /*groupOutlineLevelByProject, taskOutlineLevelsByProject, */poapData };
  }

  isFieldWritable(project: HierarchyNode, revision: RevisionData, field: string): boolean {
    if (revision.published) {
      return false;
    }

    if (writableViewTaskFields.has(field)) {
      return access.permissionIncludes(project.access, access.actionType.editBranchView);
    }
    if (writableProjectTaskFields.has(field)) {
      return access.permissionIncludes(project.access, access.actionType.editBranch);
    }
    return false;
  }

  isGroupWritable(project: HierarchyNode, revision: RevisionData) {
    if (revision.published) {
      return false;
    }
    return access.permissionIncludes(project.access, access.actionType.editBranchView);
  }

  canAddTask(project: HierarchyNode): boolean {
    return access.permissionIncludes(project.access, access.actionType.editBranch);
  }

  canDeleteTask(project: HierarchyNode, revision: RevisionData, task: PoapTask): boolean {
    if (!this.isFieldWritable(project, revision, 'deleted')) {
      return false;
    }
    return !oNum.isRoot(task);
  }

  /**
   * batch update resolves changes in the order that they are provided
   */
  batchUpdate(revisionId: RevisionId, action: string, changes: PoapChange[]): void {
    this.latest$.pipe(
      take(1),
      tap((poapData: PoapData) => {
        if (poapData && poapData.revisions && poapData.revisions[revisionId]) {
          const revision = poapData.revisions[revisionId];
          // clone arrays to break reference:
          let update: PoapRevisionData = { tasks: revision.tasks.slice(0), groups: { ...revision.groups } };
          for (const change of changes) {
            update = Object.prototype.hasOwnProperty.call(change, 'task')
              ? this.resolveTaskChange(update, change as PoapTaskFieldChange)
              : this.resolveGroupChange(update, change as PoapGroupFieldChange);
          }
          const pulled = this.pullChanges(revision, update);
          this.store.dispatch(
            poapActions.updateDraft({
              revisionId: revision.id,
              viewId: revision.view,
              action: action,
              tasks: update.tasks,
              groups: update.groups,
              update: {
                tasks: pulled.tasks,
                groups: Object.values(pulled.groups)
              },
              batchId: changes[0].batchId
            })
          );
        }
      })
    ).subscribe();
  }

  /**
   * bulk update changes in the order that they are provided
   */
  /*bulkUpdate(
    revision: RevisionData, project: HierarchyNode, toUpdate: { revision: RevisionId; action: string; change: PoapChange }[]
  ): void {
    this.latest$.pipe(take(1)).subscribe((poapData: PoapData | null) => {
      if (Array.isArray(toUpdate)) {
        const poapUpdates: Partial<PoapUpdate>[] = [];

        for (let i = 0; i < toUpdate.length; i++) {
          const { revision: revisionId, action, change } = toUpdate[i];

          if (poapData && poapData.revisions && poapData.revisions[revisionId]) {
            const revision = poapData.revisions[revisionId];
            // clone arrays to break reference:
            let update: PoapRevisionData = { tasks: revision.tasks.slice(0), groups: { ...revision.groups } };
            update = Object.prototype.hasOwnProperty.call(change, 'task')
              ? this.resolveTaskChange(update, change as PoapTaskFieldChange)
              : this.resolveGroupChange(update, change as PoapGroupFieldChange);
            const pulled = this.pullChanges(revision, update);
            poapUpdates.push({ action: action, update: { tasks: pulled.tasks, groups: [] } });
          }
        }

        if (poapUpdates.length > 0) {
          this.store.dispatch(poapActions.bulkUpdateDraft({
            revisionId: revision.id, viewId: revision.view, projectId: project.id, poapUpdates
          }));
        }
      }
    });
  }*/

  createActionDescription(task: PoapTask, field: string | MetaTaskField, oldValue?: any, value?: any): string {
    if (taskActions[field]) {
      return taskActions[field](task, oldValue, value);
    }
    return 'Edit task';
  }

  createMultiActionDescription(changes: PoapTaskFieldChange[]): string {
    const description = changes.filter(c => !!taskActions[c.field])
      .map(c => this.createActionDescription(c.task, c.field, c.oldValue, c.value))
      .join(', ');
    return description.length ? description : 'Edit task';
  }

  updateViewLines(viewLines: ViewLine[]): void {

    this.store.pipe(
      map(state => state.poap.viewSelected),
      take(1),
      switchMap(view => this.viewLinesApi.update({
        view: view.id,
        viewLines: viewLines
      })),
      tap(() => {
        this.store.dispatch(
          poapActions.requestViewLines()
        );
      })
    ).subscribe();

  }

  updateView(options: Partial<View>): void {

    this.store.pipe(
      takeUntil(this.destroy$),
      map(state => state.poap.viewSelected),
      take(1),
      switchMap(view => this.viewApi.update(view.id, options)),
      tap(() => {
        this.store.dispatch(
          poapActions.requestViews()
        );
      })
    ).subscribe();

  }

  /**
   * this function MUST create new objects for changed tasks but preserve the objects of tasks which are not changed!
   */
  private resolveTaskChange(data: PoapRevisionData, change: PoapTaskFieldChange): PoapRevisionData {
    if (!taskMutators[change.field]) {
      console.error(`Field '${change.field}' is not a writable property`);
      return data;
    }
    // previous change could have replaced the task so find index explicitly:
    const index = data.tasks.findIndex(t => t.id === change.task.id);
    if (index === -1) {
      console.error(`Task '${change.task.id}' does not exist in dataset.`);
      return data;
    }
    return taskMutators[change.field](data, change, index, this.user);
  }

  /**
   * this function MUST create new objects for changed groups but preserve the objects of tasks which are not changed!
   */
  private resolveGroupChange(data: PoapRevisionData, change: PoapGroupFieldChange): PoapRevisionData {
    if (!groupMutators[change.field]) {
      console.error(`Field '${change.field}' is not a writable property`);
      return data;
    }
    change.group = convertToViewGroup(change.group);
    return groupMutators[change.field](data, change);
  }

  private pullChanges(data: RevisionData, update: PoapRevisionData): PoapRevisionData {
    const taskSet = new Set(data.tasks);
    const groupSet = new Set(Object.values(data.groups));
    const pulled: PoapRevisionData = { tasks: [], groups: {} };

    for (const task of update.tasks) {
      if (!taskSet.has(task)) {
        pulled.tasks.push(task);
      }
    }
    for (const group of Object.values(update.groups)) {
      if (!groupSet.has(group)) {
        pulled.groups[group.id] = convertToViewGroup(group);
      }
    }
    return pulled;
  }

  /*private handleShowGroupsAsOutlineLevels(revision, project, rootGroup, groupOutlineLevel): void {
    if (!revision.published && typeof groupOutlineLevel === 'number') {
      const { groupByOutlineNumber, groupTasks } = this.getGroupHirearchyFromOutlineNumbers(
        revision, project, rootGroup, groupOutlineLevel
      );
      const toUpdate: { revision: RevisionId; action: string; change: PoapChange }[] = [];
      // Call API to create group hierarchy for all missing groups in database
      this.createGroupsFromOutlineNumbers(revision.id, groupByOutlineNumber).then((groups) => {
        Object.assign(revision.groups, groups);
        revision.tasks = groupTasks.map((groupTask) => {
          const { task, isMoveDown, isMoveUp, newGroupPath } = groupTask;
          const { groupPath, outlineNumber } = task;
          // check and exclude root task from any movements
          const isRootTask = groupPath.length === 0 && outlineNumber.length === 1;

          if (isRootTask) {
            return task;
          }

          const groupPathName = groupPath.join(' | ');

          if (isMoveUp || isMoveDown) {
            // Assign task to new group and call API to update task
            const field = 'groupPath';
            const someGroup: any = Object.values(revision.groups).find(({ name }: any) => name === newGroupPath[newGroupPath.length - 1]);
            task.group = newGroupPath.length === 0 ? rootGroup.id : (someGroup?.id || rootGroup.id);
            task.groupPath = newGroupPath;
            const action = this.createActionDescription(task, field, groupPathName, newGroupPath);
            toUpdate.push({
              revision: task.revision, action, change: { task, field, value: newGroupPath, oldValue: groupPathName }
            });
          }

          return task;
        });
        this.bulkUpdate(revision, project, toUpdate);
      });
      this.store.dispatch(poapActions.clearSelectedGroupOutlineLevel({ project: project.id }));
    }
  }*/

  /*private getGroupHirearchyFromOutlineNumbers(revision, project, rootGroup, groupOutlineLevel): {
    groupByOutlineNumber: Map<string, ViewGroup2>,
    groupTasks: { id: TaskId; task: PoapTask; isMoveDown: boolean; isMoveUp: boolean; newGroupPath: string[] }[]
  } {
    const groupByOutlineNumber: Map<string, ViewGroup2> = new Map();
    const taskByOutlineNumber: Map<string, PoapTask> = new Map();
    const groupTasks: {
      id: TaskId; task: PoapTask; isMoveDown: boolean; isMoveUp: boolean; newGroupPath: string[]
    }[] = revision.tasks.map((task, idx) => {
      const { id, groupPath, outlineNumber } = task;
      // check and exclude root task from any movements
      const isRootTask = groupPath.length === 0 && outlineNumber.length === 1;
      const version = new Date(Date.now() + idx);

      if (isRootTask) {
        const version = new Date(Date.now() + idx);
        groupByOutlineNumber.set('0', <ViewGroup2>{
          id: rootGroup.id, project: project.id, revision: revision.id, version, view: rootGroup.view, isProjectRoot: true,
          parent: '', name: '', outlineNumberName: '0'
        });
        return { id, task, isMoveDown: false, isMoveUp: false, newGroupPath: [] };
      }

      const outlineNumberKey = outlineNumber.join('.');
      // check if task is in initial group before any movements
      const isInitialGroup = outlineNumber.length - groupPath.length - 2 === 0;
      // the maximum number of levels allowed when moving task up or down
      const maxLevel = Math.abs(outlineNumber.length - 2);
      // if toMove > 0, then move task up by toMove levels (up to maxLevel)
      // if !isInitialGroup && toMove < 0, then move task down by toMove levels (up to maxLevel)
      const toMove = groupPath.length - groupOutlineLevel + 1;
      const isMoveUp = toMove > 0;
      const isMoveDown = !isInitialGroup && toMove < 0;
      let newGroupPath: string[] = [];
      let newOutlineNumberPath: string[] = [];

      if (!taskByOutlineNumber.has(outlineNumberKey)) {
        taskByOutlineNumber.set(outlineNumberKey, task);
      }

      if (isMoveUp) {
        // Move only affected task up to new parent group with selected group outline level (e.g. from Level 4 to Level 2)
        const newOutlineNumber = outlineNumber.slice(0, groupOutlineLevel);
        newOutlineNumberPath = groupPath.slice(0, newOutlineNumber.length - 1);
        newGroupPath = newOutlineNumberPath.map((gp) => taskByOutlineNumber.get(gp)?.name || gp);
      } else if (isMoveDown) {
        // Move only affected task down to new parent group with selected group outline level (e.g. from Level 2 to Level 4)
        const toLoop = groupPath.length + Math.abs(toMove);

        for (let i = 0; i < toLoop && newGroupPath.length < maxLevel; i++) {
          const gp = outlineNumber.slice(0, i + 2).join('.');
          newOutlineNumberPath.push(gp);
          newGroupPath.push(taskByOutlineNumber.get(gp)?.name || gp);
        }
      }

      if (isMoveUp || isMoveDown) {
        // Get group hierarchy for all missing groups
        const groupOutlineNumber = newOutlineNumberPath[newOutlineNumberPath.length - 1];
        const parentGroupOutlineNumber = newOutlineNumberPath[newOutlineNumberPath.length - 2];
        const groupName = newGroupPath[newGroupPath.length - 1];
        const parentGroupName = newGroupPath[newGroupPath.length - 2];
        groupByOutlineNumber.set(groupOutlineNumber, <ViewGroup2>{
          id: groupOutlineNumber, project: project.id, revision: revision.id,
          version, view: rootGroup.view, parent: parentGroupName ? parentGroupOutlineNumber : '0',
          name: groupName, outlineNumberName: groupOutlineNumber
        });
      }

      return { id, task, isMoveDown, isMoveUp, newGroupPath };
    });
    return { groupByOutlineNumber, groupTasks };
  }*/

  /*private createGroupsFromOutlineNumbers(
    revisionId: RevisionId, groupByOutlineNumber: Map<string, ViewGroup2>
  ): Promise<ViewGroupMap> {
    return new Promise((resolve) => {
      this.revisionsApi.createGroupsFromOutlineNumbers({ revisionId, groups: Array.from(groupByOutlineNumber.values()) })
        .pipe(catchError(() => {
          resolve(<ViewGroupMap>{});
          return of(null);
        }))
        .subscribe((groups) => {
          resolve(<ViewGroupMap>groups);
        });
    });
  }*/

  /*private handleShowTasksAsOutlineLevels(revision, project, taskOutlineLevels): void {
    if (!revision.published && Array.isArray(taskOutlineLevels)) {
      revision.tasks = revision.tasks.map((task) => {
        const { selected } = task;
        const newSelected = taskOutlineLevels.indexOf(task.outlineNumber.length) > -1;

        if (typeof selected === 'boolean' && newSelected !== selected) {
          const field = 'selected';
          const action = this.createActionDescription(task, field, selected, newSelected);
          this.batchUpdate(task.revision, action, [{ task, field, value: newSelected, oldValue: selected }]);
        }

        task.selected = newSelected;
        return task;
      });
      this.store.dispatch(poapActions.clearSelectedTaskOutlineLevels({ project: project.id }));
    }
  }*/

  /*private isGroupOutlineLevelByProjectChanged(a, b): boolean {
    return a !== b;
  }*/

  /*private isTaskOutlineLevelsByProjectChanged(a, b): boolean {
    return a !== b; ``
  }*/
}
