<app-modal-window
  [header]="title"
  [(visible)]="visible"
  (actionSelected)="onActionSelected($event)"
  (closed)="onClose()"
  [actions]="actions"
>
  <div>This will move the programme and all its projects to another workspace.</div>

  <form
    class="tp-form"
    [formGroup]="programmeForm"
  >
    <div class="tp-form__field">
      <label for="workspace">Target Workspace</label>

      <div class="tp-form__field__sticker" *ngIf="workspaceControl.valid && isNewWorkspace">NEW</div>

      <p-autoComplete
        formControlName="workspace"
        (completeMethod)="searchWorkspaces()"
        [dropdown]="true"
        field="name"
        [suggestions]="workspaceSuggestions"
        appendTo="body"
      >
      </p-autoComplete>
    </div>

    <div
      class="tp-form__error"
      *ngIf="workspaceControl.value && workspaceControl.errors?.invalidName"
    >
      Workspace name contains invalid characters
    </div>

    <div
      class="tp-form__error"
      *ngIf="validationErrors?.length"
    >
      <p *ngFor="let error of validationErrors">{{error}}</p>
    </div>
  
  </form>
</app-modal-window>
