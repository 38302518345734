const { uuid } = require('../uuid.js');

const GROUP_STRING_DELIMITER = '|';
const GROUP_STRING_SPLIT = /\/|\|/;

exports.getGroupNamePath = function(genericGroupDict, viewGroupId) {
  const groupNames = [];
  let g = genericGroupDict[viewGroupId];

  while (g && !g.isProjectRoot) {
    groupNames.unshift(g.name);
    g = genericGroupDict[g.parent];
  }

  return groupNames;
};

exports.parseGroupString = function(groupString) {
  // split, remove trailing/leading whitespace
  return groupString.split(GROUP_STRING_SPLIT)
    .map(g => g.trim())
    .filter(g => g);
};

exports.stringifyGroupPath = function(groupPath) {
  if (Array.isArray(groupPath)) {
    return groupPath.join(` ${ GROUP_STRING_DELIMITER } `);
  }

  return groupPath;
};

exports.cleanGroupString = function(groupString) {
  return exports.stringifyGroupPath(exports.parseGroupString(groupString));
};

exports.cleanGroupName = function(name) {
  return name.replace(/[|\\/]/g,'');
};

exports.moveDown = function(viewGroupDict, groupId) {
  const group = viewGroupDict[groupId];
  const parent = viewGroupDict[group.parent];
  const children = [...parent.children];
  const index = children.indexOf(groupId);

  children.splice(index + 1, 0, ...children.splice(index,1));

  viewGroupDict[parent.id] = { ...parent, children };

  return viewGroupDict;
};

exports.moveUp = function(viewGroupDict, groupId) {
  const group = viewGroupDict[groupId];
  const parent = viewGroupDict[group.parent];
  const children = [...parent.children];
  const index = children.indexOf(groupId);

  if (index > 0) {
    children.splice(index - 1, 0, ...children.splice(index,1));
    viewGroupDict[parent.id] = { ...parent, children };
  }

  return viewGroupDict;
};

exports.moveTo = function(viewGroupDict, groupId, targetId) {
  const group = viewGroupDict[groupId];
  const parent = viewGroupDict[group.parent];
  const target = viewGroupDict[targetId];

  if (!parent || group.isProjectRoot) {
    return viewGroupDict;
  }

  viewGroupDict[targetId] = {
    ...target,
    children: [...target.children, groupId]
  };

  viewGroupDict[parent.id] = {
    ...parent,
    children: parent.children.filter(c => c.id !== groupId)
  };

  return viewGroupDict;
};

/**
 * Checks the group path against the provided group dict
 * - modifies the group dict as required, creating groups which do not exist
 * - returns an array of groups corresponding to the groupNamePath
 * @returns {ViewGroup[]}
 */
exports.evaluateNewGroupPath = function(viewGroupDict, groupNamePath) {

  const viewGroups = Object.values(viewGroupDict);
  const rootGroup = viewGroups.find(g => g.isProjectRoot);
  const groupPath = [rootGroup];

  for (const groupName of groupNamePath) {

    const parent = groupPath[groupPath.length-1];
    const groupId = parent.children.find(id => viewGroupDict[id].name === groupName);

    if (groupId) {
      if (!viewGroupDict[groupId]) {
        return console.error(`evaluateNewGroupPathError: group '${parent.id}' specifies child which does not exist: '${groupId}'`);
      }

      groupPath.push(viewGroupDict[groupId]);
    } else {
      // create a new group:
      const group = exports.viewGroupFactory({
        projectId: parent.project,
        revisionId: parent.revision,
        viewId: parent.view,
        viewGroup: {
          name: groupName,
          parent: parent.id
        }
      });

      Object.assign(viewGroupDict, {
        [parent.id]: {
          ...parent,
          children: [...parent.children, group.id]
        },
        [group.id]: group
      });
      // replace parent group in group path with the modified (cloned) parent and push the new group.
      groupPath.splice(groupPath.length-1, 1, viewGroupDict[parent.id], group);
    }
  }

  return groupPath;
};

exports.viewGroupFactory = function({ viewGroup = {}, viewId, projectId, revisionId, action = null, version = null }) {
  return {
    id: viewGroup.id || uuid(),
    project: projectId,
    revision: revisionId,
    version: version,
    view: viewId,
    action: action,
    isProjectRoot: viewGroup.isProjectRoot || null,
    children: viewGroup.children || [],
    parent: viewGroup.parent || null,
    name: viewGroup.name || null,
    narrative: viewGroup.narrative || null
  };
};

exports.getDescendants = function(genericGroupDict, genericGroup) {
  const descendants = [];
  for (const childId of genericGroup.children) {
    const child = genericGroupDict[childId];
    descendants.push(child);
    if (child.children) {
      descendants.push(...exports.getDescendants(genericGroupDict, child));
    }
  }
  return descendants;
};
