import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { throwError, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { State } from '../../state';
import { namingConventionValidator } from '../../shared/validators/naming-convention.validator';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { HierarchyNode } from 'tp-traqplan-core/dist/data-structs';
import { HierarchyApiService } from '../../api/hierarchy-api.service';
import * as accountActions from '../../state/account/actions';
import * as modalActions from '../../state/modal/actions';

@Component({
  selector: 'app-workspace-settings',
  templateUrl: './workspace-settings.component.html',
  styleUrls: ['./workspace-settings.component.scss']
})
export class WorkspaceSettingsComponent {

  @Input() root: HierarchyNode;

  actions: ModalWindowAction[] = [
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true,
      escKey: true
    },
    {
      label: 'Save',
      type: 'positive',
      confirm: true,
      valid: () => (this.root && this.form.valid),
      enterKey: true,
    }
  ];

  visible = true;

  form: FormGroup = this.fb.group({
    name: this.fb.control('', [
      Validators.required,
      Validators.minLength(1),
      namingConventionValidator()
    ], [
      (control: AbstractControl) => {
        const name = control.value;

        if (name === this.root.name) {
          this.nameAvailable = null;
          return of(null);
        }

        return this.hierarchyApi.checkAvailable(name, 0).pipe(
          map(({ available }) => {
            if (available) {
              this.nameAvailable = true;
              return null;
            }

            this.nameAvailable = false;
            return { namingConflict: true };
          })
        );
      }
    ]),
    allowImportedEdits: this.fb.control('', [
      Validators.required
    ])
  }, {
    updateOn: 'change'
  });

  public loading: boolean = false;
  public nameAvailable: boolean;
  public error: string = null;

  get name() {
    return this.form.get('name');
  }

  get allowImportedEdits() {
    return this.form.get('allowImportedEdits')
  }

  constructor(
    private fb: FormBuilder, private store: Store<State>, private hierarchyApi: HierarchyApiService
  ) {

    /*this.store.pipe(
      takeUntil(this.destroy$),
      filter(({ account }) => !!(account.selected && account.available.value)),
      tap(({ account }) => {
        this.loading = false;
      }),
      switchMap(({ account }) => {
        return this.name.valueChanges.pipe(
          tap(() => {
            this.loading = true;
          }),
          debounceTime(300),
          switchMap(value => {
            if (value === this.root.name) {
              this.nameAvailable = true;
              this.loading = false;
              return of(null);
            }
            return this.hierarchyApi.checkAvailable(value).pipe(
              catchError(error => {
                this.loading = false;
                this.error = error.message;
                return throwError(error);
              }),
              tap(({ available }) => {
                this.loading = false;
                this.nameAvailable = available;
              })
            );
          })
        );
      })
    ).subscribe();*/

  }

  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }

  onActionSelected(action): void {

    if (!action.confirm) return;

    if (!this.loading && (this.nameAvailable === null || this.nameAvailable) && this.form.valid) {

      const name = this.name.value.trim();
      const allowImportedEdits = this.allowImportedEdits.value;

      this.loading = true;

      this.hierarchyApi.update({ id: this.root.id, update: { name, allowImportedEdits } }).pipe(
        catchError(error => {
          this.loading = false;
          this.error = error.message;
          return throwError(error);
        }),
        tap(() => {
          this.store.dispatch(accountActions.requestAvailableRoots());
        })
      ).subscribe();

    }

  }

}
