
export function matchBasicSearchQuery(query: string, value: string): boolean {
  const lQuery = query.toLowerCase();
  const vSplit = value.toLowerCase().split(' ');
  return vSplit.some((v,i) => vSplit.slice(i).join(' ').startsWith(lQuery));
}

export function c2W(value: string): string {
  return value.replace(/[A-Z]/g, m => ` ${m.toLowerCase()}`);
}
