import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(duration);
dayjs.extend(relativeTime);

@Pipe({ name: 'dateFromNow' })
export class DateFromNowPipe implements PipeTransform {
  transform(value: Date): string {
    return dayjs(value).fromNow();
  }
}
