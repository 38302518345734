import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HierarchyNodeId, RevisionId, View, ViewId } from 'tp-traqplan-core/dist/data-structs';
import { AuthApiService } from './auth-api.service';
import { defaultPipe } from './common';
import { ReqHeader } from './structs';


@Injectable({
  providedIn: 'root'
})
export class ViewApiService {

  constructor(
    private http: HttpClient,
    private authApi: AuthApiService
  ) { }

  getViews(): Observable<{ views: View[] }> {
    return this.authApi.headers({ requires: ReqHeader.account & ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ views: View[] }> => {
        return this.http.get<{ views: View[]; }>(
          '/api/views',
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  getViewsForRevisions(revisions): Observable<{ views: View[] }> {
    return this.authApi.headers({ requires: ReqHeader.account & ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ views: View[] }> => {
        return this.http.get<{ views: View[]; }>(
          '/api/views/for-revisions',
          { params: { revisions: revisions.join(',') }, headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  create(name: string): Observable<void> {
    return this.authApi.headers({ requires: ReqHeader.account & ReqHeader.user }).pipe(
      switchMap((headers): Observable<void> => {
        return this.http.post<void>(
          '/api/views/create',
          { name },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  update(id: ViewId, update: Partial<View>): Observable<void> {
    return this.authApi.headers({ requires: ReqHeader.account & ReqHeader.user }).pipe(
      switchMap((headers): Observable<void> => {
        return this.http.post<void>(
          '/api/views/update',
          { id, update },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  copy(workspace: HierarchyNodeId, projects: HierarchyNodeId[], revisions: RevisionId[], from: ViewId, to: ViewId | string): Observable<void> {
    return this.authApi.headers({ requires: ReqHeader.account & ReqHeader.user }).pipe(
      switchMap((headers): Observable<void> => {
        return this.http.post<void>(
          '/api/views/copy',
          { workspace, projects, revisions, from, to },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  checkAvailable(name: string): Observable<{ available: boolean }> {
    return this.authApi.headers({ requires: ReqHeader.account & ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ available: boolean }> => {
        return this.http.get<{ available: boolean; }>(
          '/api/views/check-available',
          { params: { name }, headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

}
