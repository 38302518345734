<ng-container *ngIf="this.auth$ | async as auth else loadingView">

  <app-header></app-header>

  <div class='page-area'>
    <router-outlet></router-outlet>
  </div>

  <app-loader *ngIf="auth.pending || showLoading" class="app__loader"></app-loader>
</ng-container>

<ng-template #loadingView>
  <app-loader class="app__loader"></app-loader>
</ng-template>

<ng-template #modalContainer>

</ng-template>

<app-info-box
  [visible]="infoBoxVisible"
  [header]="infoBoxInstance?.request?.header"
  [actions]="infoBoxInstance?.request?.actions"
  [message]="infoBoxInstance?.request?.message"
  (closeWindow)="infoBoxClose($event)"
></app-info-box>
