import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PoapTask } from 'tp-traqplan-core/dist/data-structs';
import { Subject } from 'rxjs';
import { TimelineLayout } from 'tp-traqplan-core/dist/timeline/timeline-layout';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import * as groupUtils from 'tp-traqplan-core/dist/task-group-utils';
import { parseGroupString, stringifyGroupPath } from 'tp-common/revisions/view-groups';
import { PoapUpdateService } from '../../../workspace/poap/poap-update.service';
import { takeUntil, tap } from 'rxjs/operators';
import { MetaTaskField, PoapData } from 'tp-traqplan-core/dist/workspace-structs';
import { TimelineInteractions } from '../tl-interactions';
import { TaskChangeEvent } from '../tl-events';
import { TaskChange } from 'tp-traqplan-core/dist/timeline/tl-structs';

@Component({
  selector: 'app-new-task-modal',
  templateUrl: './new-task-modal.component.html',
  styleUrls: ['./new-task-modal.component.css']
})
export class NewTaskModalComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<void>();
  data: PoapData;

  @Input() interactions: TimelineInteractions;
  @Input() layout: TimelineLayout;

  public modalVisible: boolean = false;
  public modalHeader: string = "Create Task";
  public modalActions: ModalWindowAction[];

  public taskName: string;
  public taskGroup: string;
  public taskStart: Date;
  public taskFinish: Date;
  public taskRevision: string;
  private taskSibling: PoapTask;

  constructor(
    private updateService: PoapUpdateService
  ){
     this.updateService.latest$.pipe(
      takeUntil(this.destroy$),
      tap(data => {
        if(data)
        this.data = data;
      })
    ).subscribe();
  }

   ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  ngOnInit(): void {

    this.modalActions = [
      {
        label: 'Cancel',
        type: 'neutral',
        escKey: true
      },
      {
        label: 'Create',
        type: 'positive',
        valid: () => this.taskStart?.getTime() && this.taskFinish?.getTime(),
        enterKey: true
      }
    ];

    this.interactions?.events$.pipe(
      takeUntil(this.destroy$),
      tap(({ event, data }) => {
        if (event === 'taskModalOpen') {
          this.taskGroup = stringifyGroupPath(data.groupPath);
          this.taskName = '';
          this.taskStart = data.start;
          this.taskFinish = data.finish;
          this.taskSibling = data.sibling;
          this.taskRevision = data.revision;
          this.modalVisible = true;
        }
      })
    ).subscribe();

  }

  taskGroupChange(): void {
    this.taskGroup = groupUtils.cleanGroupString(this.taskGroup);
  }

  taskStartChange(): void {
    if (this.taskFinish < this.taskStart) {
      this.taskFinish = this.taskStart
    }
  }

  taskFinishChange(): void {
    if (this.taskFinish < this.taskStart) {
      this.taskStart = this.taskFinish;
    }
  }

  modalActionSelected(action: ModalWindowAction): void {

    if (action.label.toLowerCase() === 'create') {

      const sibling = this.taskSibling ||  this.data.tasks[this.data.tasks.length - 1];

      return this.interactions.events$.next(<TaskChangeEvent>{
        event: 'taskChange',
        data: <TaskChange>{
          revisionId: this.taskRevision,
          changes: [{
            task: sibling,
            field: MetaTaskField.add,
            value: {
              name: this.taskName || undefined,
              groupPath: parseGroupString(this.taskGroup),
              start: this.taskStart,
              finish: this.taskFinish
            }
          }]
        }
      });

      /*if(!this.taskSibling) {
        const taskReplacement = this.data.tasks[this.data.tasks.length - 1]

        return this.eventChannel.publish('task__create', {
          revision: this.taskRevision,
          sibling: taskReplacement,
          name: this.taskName || undefined,
          groupPath: groupUtils.parseGroupString(this.taskGroup),
          start: this.taskStart,
          finish: this.taskFinish
        });
      }*/

    }

  }

}
