<app-modal-window
  header="Copy Programme"
  [(visible)]="visible"
  (actionSelected)="onActionSelected($event)"
  (closed)="onClose()"
  [actions]="actions"
>
  <form
    class="tp-form"
    [formGroup]="programmeForm"
  >

    <div class="tp-form__field">
      <label for="workspace">Workspace</label>
      <div class="tp-form__field__sticker" *ngIf="workspaceControl.valid && isNewWorkspace"></div>
      <p-autoComplete
        formControlName="workspace"
        [suggestions]="workspaceSuggestions"
        field="name"
        appendTo="body"
      >
      </p-autoComplete>
    </div>

    <div class="tp-form__field">
      <label for="name">Name</label>
      <input
        pInputText
        name="name"
        formControlName="name"
      />
    </div>

    <div
      class="tp-form__error"
      *ngIf="programmeForm.errors?.validationError"
    >
      <ul>
        <li *ngFor="let message of validationErrors">{{message}}</li>
      </ul>
    </div>

  </form>

</app-modal-window>
