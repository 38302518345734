import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

import * as PowerBi from '../../powerbi-data-structs';
import { defaultPipe } from '../common';
import { StaffApiService } from '../staff-api.service';

import UserTncAcceptance = PowerBi.UserTncAcceptance;
import UserTncAcceptanceId = PowerBi.UserTncAcceptanceId;

@Injectable({
  providedIn: 'root'
})
export class UserTncAcceptanceApiService {

  constructor(
    private http: HttpClient,
    private staffApi: StaffApiService
  ) {}

  requestUserTncAcceptances() {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.get<{ userTncAcceptances: UserTncAcceptance[] }>(
          '/api/staff/powerbi/user-tnc-acceptances',
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  requestUserTncAcceptanceById(id: UserTncAcceptanceId) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.get<{ userTncAcceptance: UserTncAcceptance }>(
          `/api/staff/powerbi/user-tnc-acceptances/${id}`,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  updateUserTncAcceptanceById(id: UserTncAcceptanceId, options) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.patch<{ userTncAcceptance: UserTncAcceptance }>(
          `/api/staff/powerbi/user-tnc-acceptances/${id}`,
          options,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

}
