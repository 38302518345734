import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { User } from 'tp-traqplan-core/dist/data-structs';
import { State } from '../index';

@Injectable({
  providedIn: 'root'
})
export class AccountSelectorService implements OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(private store: Store<State>) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  users$: Observable<User[]> = this.store.pipe(
    takeUntil(this.destroy$),
    map(state => state.account.users),
    distinctUntilChanged(),
    map(users => Object.values(users)),
    shareReplay(1)
  );

}
