import { createReducer, on } from '@ngrx/store';
import { initialState } from './state';
import * as authActions from './actions';

export const reducer = createReducer(
  initialState,

  on(authActions.attemptLogin, (state) => {
    return {
      ...state,
      loginError: null,
      pending: true
    }
  }),

  on(authActions.updateToken, (state, { token }) => {
    return {
      ...state,
      token: token
    }
  }),

  on(authActions.attemptLoginFailure, (state, props) => {
    return {
      ...state,
      token: null,
      user: null,
      loginError: props.error,
      loginAttempts: state.loginAttempts + 1,
      pending: false
    };
  }),

  on(authActions.verifyUserCredentials, (state) => {
    return {
      ...state,
      pending: true
    };
  }),

  on(authActions.verifyUserCredentialsFailure, (state) => {
    return {
      ...state,
      token: null,
      user: null,
      pending: false
    };
  }),

  on(authActions.attemptLoginSuccess, (state, props) => {
    return {
      ...state,
      token: props.token,
      user: props.user,
      loginError: null,
      loginAttempts: 0,
      pending: false
    };
  }),

  /*on(authActions.atte/mptLoginSsoSuccess, (state, { ssoToken, origin }) => {
    return {
      ...state,
      loginError: null,
      loginAttempts: 0,
      pending: false,
      origin,
      ssoToken
    };
  }),*/

  on(authActions.logout, () => {
    return { ...initialState };
  })
);
