import { createAction, props } from '@ngrx/store';
import { Staff } from 'tp-traqplan-core/dist/data-structs';
import { RequestError } from 'src/app/api/structs';
import { State } from './state';

export const reset = createAction(
  '[tps] reset',
  props<{ state?: Partial<State> }>()
);

export const attemptLogin = createAction(
  '[tps] AttemptLogin',
  props<{ email: string; password: string }>()
);

export const attemptLoginSso = createAction(
  '[tps] AttemptLoginSso',
  props<{ d: string; }>()
);

export const attemptLoginFailure = createAction(
  '[tps] AttemptLoginFailure',
  props<{ error: RequestError }>()
);

export const attemptLoginSuccess = createAction(
  '[tps] AttemptLoginSuccess',
  props<{ tps: Staff; token: string }>()
);

export const attemptLoginSsoComplete = createAction('[tps] AttemptLoginSsoComplete');
export const attemptLoginSsoSuccess = createAction(
  '[tps] AttemptLoginSsoSuccess',
  props<{ ssoToken: string; origin: string }>()
);

export const verifyStaffCredentials = createAction(
  '[tps] verifyStaffCredentials'
);

export const verifyStaffCredentialsFailure = createAction(
  '[tps] verifyUserCredentialsFailure'
);

export const logout = createAction(
  '[tps] logout'
);
