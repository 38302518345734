<div class="header">
  <div class="header__item">
    <img
      src="/assets/images/logo.png"
      class='logo'
    />
  </div>

  <div class="header__item trial-warning" *ngIf="trialWarning$ | async as trialWarning; else spacer">
    {{trialWarning}}
  </div>

  <ng-template #spacer>
    <div class="header__item-spacer"></div>
  </ng-template>

  <ng-container *ngIf="state$ | async as state">

    <div class="header__item upgrade-license" *ngIf="state.license?.type === 1"
      data-tooltip="You have a viewer licence - click here to request an editor licence from your admin"
      data-inverted
      data-position="bottom right"
      (click)="onClickRequestLicense()"
    >
      Create your own timelines
    </div>

    <div
      class="header__item user"
      (click)="toggleSideBar()"
      *ngIf="state.user && !state.staff"
    >
      {{ state.user?.name }}
      <i class="fas" style="margin-left: 0.5rem">user</i>
    </div>

    <div class="header__item notification" (click)="toggleNotifications($event)" *ngIf="state.user">
      <i class="fas notification__icon">bell

        <span *ngIf="unread > 0" class="notification__count"></span>

      </i>
      
    </div>

    <div
      class="header__item tps-header"
      (click)="toggleTpsBar()"
      *ngIf="state.user && state.staff"
    >
      <span class="fas">toolbox</span>
      <span class="tp-header-3">Staff area</span>
    </div>

  </ng-container>

</div>

<p-sidebar class="user-panel" [(visible)]="displaySideBar" position="right">

  <div class="user-panel__header">
    <span>Account Details</span>
  </div>

  <div class="user-panel__detail">

    <ng-container *ngIf="state$ | async as state">

      <div class="detail__header">Profile</div>

      <div class="detail__item">
        <div>Full Name</div>
        <div>{{ state.user?.name }}</div>
      </div>

      <div class="detail__item">
        <div>Email:</div>
        <div>{{ state.user?.email }}</div>
      </div>

    </ng-container>

  </div>

  <div class="logout">
    <p-button label="Log Out" (click)="logout()"></p-button>
  </div>

</p-sidebar>


<p-sidebar class="user-panel" [(visible)]="displayTpsBar" position="right">

  <ng-container *ngIf="state$ | async as state">

    <div class="user-panel__header">
      <span>Staff Options</span>
    </div>

    <div class="user-panel__detail">

      <div class="detail__header">Staff Member:</div>

      <div class="detail__item">
        <div>Full Name</div>
        <div>{{ state.staff?.name }}</div>
      </div>

      <div class="detail__item">
        <div>Email</div>
        <div>{{ state.staff?.email }}</div>
      </div>

      <div class="detail__header">Emulating:</div>

      <div class="detail__item">
        <div>Full Name</div>
        <div>{{ state.user?.name }}</div>
      </div>

      <div class="detail__item">
        <div>Email</div>
        <div>{{ state.user?.email }}</div>
      </div>

    </div>

    <button
      (click)="exitEmulation()"
      class="tp-button"
    >
      <span>Exit User Account</span>
    </button>

  </ng-container>

</p-sidebar>

<app-notifications-menu [(visible)]="notificationsVisible"></app-notifications-menu>

<!--
<ng-container *ngIf="this.data$ | async as data">
   <div class="ui left vertical inverted thin sidebar labeled large menu blue">
    <ng-container *ngFor="let menuitem of data.menu">
      <a
        *ngIf="!menuitem.hidden"
        class="item"
        routerLink="{{menuitem.route}}"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="toggleSidebar()"
      >
        <i class="{{menuitem.icon}} icon"></i>
        {{menuitem.text}}
      </a>
    </ng-container>
  </div>

  <div class="ui inverted large menu noroundborder navbar">
    <div class="left menu secondary">


      <ng-container *ngFor="let dropdownmenuitem of data.dropdownmenu">
        <div
          class="ui simple dropdown item"
          *ngIf="!dropdownmenuitem.hidden"
        >
          <i
            *ngIf="dropdownmenuitem.icon != ''"
            class="icon"
            [ngClass]="dropdownmenuitem.icon"
          ></i>
          {{dropdownmenuitem.text}}
          <i class="dropdown icon"></i>
          <div class="menu">
            <a
              *ngFor="let submenuitem of dropdownmenuitem.submenu"
              class="item"
              routerLink="{{submenuitem.route}}"
              [queryParams]="submenuitem.queryParams"
            >
              <i
                *ngIf="submenuitem.icon != ''"
                class="icon"
                [ngClass]="submenuitem.icon"
              ></i>
              {{submenuitem.text}}
            </a>
          </div>
        </div>
      </ng-container>

      <ng-container *ngFor="let menuitem of data.menu">
        <div
          class="ui simple dropdown item"
          *ngIf="!menuitem.hidden"
          [ngClass]="{ disabled: menuitem.disabled }"
        >
          <i
            *ngIf="menuitem.icon != ''"
            class="icon"
            [ngClass]="menuitem.icon"
            (click)="menuitem.disabled ? null : menuitem.action()"
          ></i>
          {{menuitem.text}}
          <ng-container *ngIf="menuitem.submenu.length">
            <i class="dropdown icon"></i>
            <div class="menu">
              <a
                *ngFor="let submenuitem of menuitem.submenu"
                class="item"
                (click)="submenuitem.disabled ? null : submenuitem.action()"
              >
                <i
                  *ngIf="submenuitem.icon != ''"
                  class="icon"
                  [ngClass]="submenuitem.icon"
                ></i>
                {{submenuitem.text}}
              </a>
            </div>
          </ng-container>
        </div>

      </ng-container>

    </div>

    <div class="tp-nav__center right menu secondary icon">
      <a
        *ngIf="data.publishTarget"
        class="tp-refresh item nopadding tp-refresh--danger"
        [ngClass]="{ 'tp-refresh--disabled': !data.publishTarget }"
        [routerLink]=""
        (click)="this.publish(data.publishTarget, data.publishProject)"
      >
        Publish
      </a>
      <a
        class="tp-refresh item nopadding"
        [ngClass]="{ 'tp-refresh--danger': !!data.projects.error }"
        [routerLink]=""
        (click)="this.refresh()"
      >
        Refresh
      </a>
    </div>

    <div
      *ngIf="data.user"
      class="right menu secondary icon"
    >
      <a
        class="item nopadding"
        routerLink="/profile"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <img
          [src]="data.user.ImageURL"
          class="ui circular inline image"
          width=25
          *ngIf="data.user.ImageURL"
        >
        {{ data.user.Alias || data.user.Email }}
      </a>
    </div>
  </div>
</ng-container>-->
