<ng-container *ngIf="params$ | async as params">
  <app-modal-window
    [header]="params.header"
    [(visible)]="visible"
    (actionSelected)="onActionSelected($event)"
    (closed)="onClose()"
    [actions]="actions"
    [fullscreen]="false"
  >
    <div>{{params.message}}</div>
  </app-modal-window>
</ng-container>
