import { createReducer, on } from '@ngrx/store';
import { initialState } from './state';
import * as customersActions from './actions';

export const reducer = createReducer(
  initialState,

  on(customersActions.requestCustomersSuccess, (state, props) => {
    return {
      ...state,
      customers: props.customers
    };
  }),

  on(customersActions.requestCustomerByIdSuccess, (state, props) => {
    return {
      ...state,
      customer: props.customer
    };
  })

);
