<p-contextMenu
  #contextMenu
  [ngStyle]="{ position: 'fixed', left: xPosition, top: yPosition, 'z-index': 10 }"
  [target]="target"
  [model]="menuItems"
></p-contextMenu>

<app-modal-window
  [header]="'Are you sure?'"
  [(visible)]="modalDeleteVisible"
  [actions]="modalDeleteActions"
  (actionSelected)="modalDeleteAction($event)"
>
  <span [ngStyle]="{ 'display': 'block' }">
    <label>Are you sure you want to delete the selected task(s)?</label>
  </span>
</app-modal-window>

<app-modal-window
  [header]="renameTitle"
  [(visible)]="modalRenameVisible"
  [actions]="modalRenameActions"
  [focus]="renameInput"
  (actionSelected)="modalRenameAction($event)"
>
  <label>TASK NAME (this will change the task across ALL views)</label>
  <div class="ui input" style="width:100%; padding-bottom:1.25em;">
    <input
      #renameInput
      pInputText
      type="text"
      [(ngModel)]="modalRenameNameValue"
    />
  </div>
  <label>RENAME ON VIEW: Override the displayed name for this view only</label>
  <div class="ui input" style="width:100%; padding-bottom:0.5em;">
    <input
      pInputText
      type="text"
      [(ngModel)]="modalRenameRenameValue"
    />
  </div>
  <label style="font-style:italic;">(This will be specific to the current view. Often used when working with imported project files when you want to change what the view displays without changing the task name)</label>
</app-modal-window>

<app-modal-window
  [header]="'Set Task Completion' +' ('+ modalCompletionValue+'-100%)'"
  [(visible)]="modalCompletionVisible"
  [actions]="modalCompletionActions"
  (actionSelected)="modalCompletionAction($event)"
>
  <div class="form-block">
    <p-inputNumber inputId="taskCompletionSpinner" inputId="taskCompletionSpinner" mode="decimal"
      [(ngModel)]="modalCompletionValue" [showButtons]="true" [min]="0" [max]="100"></p-inputNumber>
  </div>
</app-modal-window>

<app-modal-window
  [(visible)]="modalSwimlaneVisible"
  [actions]="modalSwimlaneActions"
  header="Set task swimlane"
  (actionSelected)="modalSwimlaneAction($event)"
>
  <div>
    <label class="modal-label">Select swimlane group</label>
  </div>
  <app-timeline-group-tree
    [layout]="interactions?.layout"
    [branches]="modalSwimlaneBranches"
    (selectionChanged)="modalSwimlaneSelectionChange($event)"
  >
  </app-timeline-group-tree>
  <div style="padding-top:0.5em;">
    <label class="modal-label">Create child swimlane under selected?</label>
  </div>
  <div>
    <input
      pInputText
      type="text"
      [ngModel]="modalSwimlaneCreate"
      (input)="onModalSwimlaneCreateInput($event)"
    />
  </div>
</app-modal-window>
