import { RevisionData, RevisionInfo, RevisionConflict, View, UndoAction, MilestoneCategory, ViewLine, BaselineId, Baseline, BaselineRevision, HierarchyNodeId } from 'tp-traqplan-core/dist/data-structs';
import { DataStore } from '../structs';
import { dsInitial } from '../helpers';
import { TimelineOptions } from 'tp-traqplan-core/dist/timeline/tl-structs';

interface Baselines {
  available: DataStore<Baseline[]>;
  selected: BaselineId;
  revisions: DataStore<BaselineRevision[]>;
}

export const key = 'poap';

export interface State {
  views: DataStore<View[]>;
  viewSelected: View;
  selectedRevisions?: RevisionInfo[];
  milestones: DataStore<MilestoneCategory[]>;
  viewLines: DataStore<ViewLine[]>;
  // key is revision id:
  revisions: DataStore<{ [key: string]: RevisionData }>;
  // key is revision id
  conflicts: DataStore<{ [key: string]: RevisionConflict }>;
  baselines: Baselines;
  // key is revision id:
  history: DataStore<{
    [key: string]: UndoAction[]
  }>;
  timelineSettings: Partial<TimelineOptions>;
  groupOutlineLevelByProject: { [key: HierarchyNodeId]: number };
  taskOutlineLevelsByProject: { [key: HierarchyNodeId]: number[] };
}

export const initialState: State = {
  views: dsInitial<View[]>(),
  viewSelected: null,
  milestones: dsInitial<MilestoneCategory[]>(),
  viewLines: dsInitial<ViewLine[]>(),
  revisions: dsInitial<{ [key: string]: RevisionData }>(),
  conflicts: dsInitial<{ [key: string]: RevisionConflict }>(),
  baselines: {
    available: dsInitial<Baseline[]>(),
    selected: null,
    revisions: dsInitial<BaselineRevision[]>()
  },
  history: dsInitial<{ [key:string]: UndoAction[] }>(),
  timelineSettings: {
    fontSize: 12,
    taskMaxLines: 3,
    textMaxWidth: 270,
    baselineThreshold: 1,
    wrapTextOutsideBar: true,
    prependMilestoneDates: false,
    viewTickLines: true
  },
  groupOutlineLevelByProject: {},
  taskOutlineLevelsByProject: {}
};

export function beforeSave(state) {
  return {
    ...state,
    views: dsInitial<View[]>(),
    milestones: dsInitial<MilestoneCategory[]>(),
    viewLines: dsInitial<ViewLine[]>(),
    revisions: dsInitial<{ [key: string]: RevisionData }>(),
    conflicts: dsInitial<{ [key: string]: RevisionConflict }>(),
    baselines: {
      ...state.baselines,
      available: dsInitial<Baseline[]>(),
      revisions: dsInitial<BaselineRevision[]>()
    },
    /*timelineSettings: {
      fontSize: 12,
      taskMaxLines: 3,
      baselineThreshold: 10,
      wrapTextOutsideBar: true,
      prependMilestoneDates: false,
      viewTickLines: true
    },*/
    history: dsInitial<{ [key:string]: UndoAction[] }>()
  };
}

export function beforeLoad(state) {
  return state;
}
