import { createAction, props } from '@ngrx/store';
import { RequestError } from '../../api/structs';
import { Subscription, SubscriptionId } from 'tp-traqplan-core/dist/data-structs';

export const staffRequestSubscriptions = createAction(
  '[subscriptions] staffRequestSubscriptions',
  props<any>()
);

export const staffRequestSubscriptionsSuccess = createAction(
  '[subscriptions] staffRequestSubscriptionsSuccess',
  props<{ subscriptions: Subscription[] }>()
);

export const staffRequestSubscriptionsFailure = createAction(
  '[subscriptions] staffRequestSubscriptionsFailure',
  props<{ error: RequestError }>()
);

export const staffRequestSubscriptionById = createAction(
  '[subscriptions] staffRequestSubscriptionById',
  props<{ id: SubscriptionId }>()
);

export const staffRequestSubscriptionByIdSuccess = createAction(
  '[subscriptions] staffRequestSubscriptionByIdSuccess',
  props<{ subscription: Subscription }>()
);

export const staffRequestSubscriptionByIdFailure = createAction(
  '[subscriptions] staffRequestSubscriptionByIdFailure',
  props<{ error: RequestError }>()
);
