import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, throwError, of } from 'rxjs';
import { take, switchMap, map, filter } from 'rxjs/operators';
import { State } from 'src/app/state';
import { User } from 'tp-traqplan-core/dist/data-structs';
import { defaultPipe, parseUser } from './common';
import { RequestError, ReqHeader } from './structs';

const noAccountError = () => throwError(<RequestError>{
  code: null,
  message: 'No Account Selected',
  account: null
});

const noUserError = () => throwError(<RequestError>{
  code: null,
  message: 'User not authenticated',
  account: null
});

const noWorkspaceError = () => throwError(<RequestError>{
  code: null,
  message: 'No Workspace Selected',
  account: null
});

interface SetHeaders {
  [key: string]: string;
}

interface Headers {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  private headers$: Observable<Headers>;

  constructor(
    private store$: Store<State>, private http: HttpClient
  ) {

    this.headers$ = this.store$.pipe(
      filter(({ auth }) => !auth.pending),
      map(({ account, auth }) => {
        return <Headers>{
          'Authorization': auth.token?.length ? 'Bearer ' + auth.token : '',
          'X-Account': account.selected?.id || '',
          'X-Workspace': account.root?.selected?.id || ''
        };
      })
    );

  }

  headers({ requires, set }: { requires?: number; set?: SetHeaders } = {}): Observable<HttpHeaders> {

    return this.headers$.pipe(
      take(1),
      switchMap(h => {

        if (set) {
          if (set.account) {
            h['X-Account'] = set.account;
          }
          if (set.workspace) {
            h['X-Workspace'] = set.workspace;
          }
        }

        if (requires !== void 0) {
          if ((requires & ReqHeader.account) === ReqHeader.account && !h['X-Account']) {
            return noAccountError();
          }
          if ((requires & ReqHeader.user) === ReqHeader.user && !h['Authorization']) {
            return noUserError();
          }
          if ((requires & ReqHeader.workspace) === ReqHeader.workspace && !h['X-Workspace']) {
            return noWorkspaceError();
          }
        }
        return of(new HttpHeaders(h));
      })
    );

  }

  requestLogin(email: string, password: string): Observable<{ token: string; user: User }> {
    return this.http.post<{ token: string; user: User }>(
      '/api/auth',
      { email, password }
    ).pipe(
      defaultPipe()
    )
  }

  /*requestLoginSso(d: string): Observable<{ token: string; user: User, ssoToken: string, origin: string }> {
    return this.http.post<{ token: string; user: User, ssoToken: string, origin: string }>(
      '/api/auth/sso',
      { d }
    ).pipe(
      defaultPipe()
    )
  }*/

  verifyToken(): Observable<{ token: string; user: User } > {
    return this.store$.pipe(
      filter(({ auth }) => !!auth.token),
      map(({ auth }) => new HttpHeaders({ 'Authorization': 'Bearer ' + auth.token })),
      take(1),
      switchMap((headers): Observable<{ token: string; user: User }> => {
        return this.http.get<{ token: string; user: User }>(
          '/api/auth/verify',
          {headers}
        ).pipe(
          defaultPipe()
        )
      })
    );
  }

  verifyUser(email: string, token: string): Observable<{ user: User; token: string; }> {

    return this.http.get<{ user: User; token: string; }>(
      '/api/auth/verification',
      { params: { 
        email: encodeURIComponent(email),
        token: encodeURIComponent(token) }
      }
    ).pipe(
      defaultPipe(),
      map(({ user, token }) => ({ user: parseUser(user), token }))
    );
  }

  sendVerificationToken(user: string): Observable<void> {
    return this.http.post<void>(
      '/api/auth/verification',
      { user }
    ).pipe(
      defaultPipe()
    );
  }

}
