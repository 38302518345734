<app-modal-window
  header="Request permissions"
  [actions]="actions"
  [(visible)]="visible"
  (actionSelected)="complete($event)"
  (closed)="complete()"
>

  <div class="message">{{message?.messageContent || ''}}</div>

</app-modal-window>
