import { Subscription } from 'tp-traqplan-core/dist/data-structs';

export const key = 'subscriptions';

export interface State {
  subscription: Subscription,
  subscriptions: Subscription[]
}

export const initialState: State = {
  subscription: null,
  subscriptions: []
};

export function beforeSave(state) {
  return {
    ...state
  };
}

export function beforeLoad(state) {
  return state;
}
