import {
  AccountInfo, License, User, HierarchyNode, HierarchyAccessListNode, Subscription
} from 'tp-traqplan-core/dist/data-structs';
import { DataStore } from '../structs';
import { dsInitial } from '../helpers';

export const key = 'account';

export interface State {
  selected: AccountInfo;
  available: DataStore<AccountInfo[]>;
  root: {
    selected: HierarchyNode;
    available: DataStore<HierarchyNode[]>;
  };
  admins: {
    [key: string]: User;
  };
  users: {
    [key: string]: User;
  };
  licenses: License[]
  hierarchyPermissions: HierarchyAccessListNode[];
  subscription: Subscription | undefined;
  subscriptionExpired: boolean;
  trialSubscriptionExpired: boolean;
}

export const initialState: State = {
  selected: null,
  available: dsInitial<AccountInfo[]>(null),
  root: {
    selected: null,
    available: dsInitial<HierarchyNode[]>(null)
  },
  admins: null,
  users: null,
  licenses: null,
  hierarchyPermissions: [],
  subscription: undefined,
  subscriptionExpired: false,
  trialSubscriptionExpired: false
};

export function beforeSave(state) {
  return {
    ...state,
    available: {
      ...state.available,
      pending: false
    },
    root: {
      ...state.root,
      available: {
        ...state.root.available,
        pending: false
      }
    }
  };
}

export function beforeLoad(state) {
  return state;
}
