import { Component } from '@angular/core';
import { NotificationsService } from '../../api/notifications.service';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import * as modalActions from '../../state/modal/actions';
import { Store } from '@ngrx/store';
import { State } from '../../state';

@Component({
  selector: 'app-request-license-upgrade',
  templateUrl: './request-license-upgrade.component.html',
  styleUrls: ['./request-license-upgrade.component.scss']
})
export class RequestLicenseUpgradeComponent {

  actions: ModalWindowAction[] = [
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true,
      escKey: true
    },
    {
      label: 'Request editor license',
      type: 'positive',
      confirm: true,
      enterKey: true,
    }
  ];

  visible = true;

  customMessage: string;

  constructor(
    private store: Store<State>,
    private notificationsService: NotificationsService
  ) {}

  complete(action?: ModalWindowAction) {
    if (action?.confirm) {
      this.notificationsService.sendLicenseRequest({ message: this.customMessage }).subscribe();
    }
    this.store.dispatch(
      modalActions.closeModal()
    );
  }
}
