import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

import * as PowerBi from '../../powerbi-data-structs';
import { defaultPipe } from '../common';
import { StaffApiService } from '../staff-api.service';

import Pricing = PowerBi.Pricing;
import PricingId = PowerBi.PricingId;

@Injectable({
  providedIn: 'root'
})
export class PricingApiService {

  constructor(
    private http: HttpClient,
    private staffApi: StaffApiService
  ) {}

  createPricing(options) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.post<{ pricing: Pricing }>(
          '/api/staff/powerbi/pricings',
          options,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  deletePricingById(id: PricingId) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.delete<any>(
          `/api/staff/powerbi/pricings/${id}`,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  requestPricings() {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.get<{ pricings: Pricing[] }>(
          '/api/staff/powerbi/pricings',
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  requestPricingById(id: PricingId) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.get<{ pricing: Pricing }>(
          `/api/staff/powerbi/pricings/${id}`,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  updatePricingById(id: PricingId, options) {
    return this.staffApi.getAuth().pipe(
      switchMap(headers => {
        return this.http.patch<{ pricing: Pricing }>(
          `/api/staff/powerbi/pricings/${id}`,
          options,
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

}
