import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaselineId, Baseline, BaselineRevision, HierarchyNodeId, RevisionId } from 'tp-traqplan-core/dist/data-structs';
import { AuthApiService } from './auth-api.service';
import { defaultPipe } from './common';
import { ReqHeader } from './structs';

@Injectable({
  providedIn: 'root'
})
export class BaselinesApiService {

  constructor(
    private http: HttpClient,
    private authApi: AuthApiService
  ) { }

  get(root: HierarchyNodeId): Observable<{ baselines: Baseline[] }> {
    return this.authApi.headers({ requires: ReqHeader.account & ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ baselines: Baseline[] }> => {
        return this.http.get<{ baselines: Baseline[] }>(
          '/api/baselines',
          { params: { root }, headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  getRevisions(baseline: BaselineId): Observable<{ baselineRevisions: BaselineRevision[] }> {
    return this.authApi.headers({ requires: ReqHeader.account & ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ baselineRevisions: BaselineRevision[] }> => {
        return this.http.get<{ baselineRevisions: BaselineRevision[] }>(
          '/api/baselines/revisions',
          { params: { baseline }, headers }
        );
      })
    );
  }

  create(rootId: HierarchyNodeId, label: string, entries: { project: HierarchyNodeId; revision: RevisionId }[]): Observable<{ baselines: Baseline[] }> {
    return this.authApi.headers({ requires: ReqHeader.account & ReqHeader.user }).pipe(
      switchMap((headers): Observable<{ baselines: Baseline[] }> => {
        return this.http.post<{ baselines: Baseline[] }>(
          '/api/baselines/',
          { rootId, label, entries },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

  updateRevisions(baselineId: BaselineId, entries: { project: HierarchyNodeId; revision: RevisionId }[]) {
    return this.authApi.headers({ requires: ReqHeader.account & ReqHeader.user }).pipe(
      switchMap(headers => {
        return this.http.post<void>(
          '/api/baselines/revisions',
          { baselineId, entries },
          { headers }
        ).pipe(
          defaultPipe()
        );
      })
    );
  }

}
