import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { PricingApiService } from '../../api/pricing-api.service';
import { RequestError } from '../../api/structs';
import * as pricingsActions from './actions';

@Injectable()
export class PricingEffects {

  constructor(
    private actions$: Actions,
    private pricingApi: PricingApiService
  ) {}

  staffRequestPricings$ = createEffect(() => this.actions$.pipe(
    ofType(pricingsActions.staffRequestPricings),
    switchMap((): Observable<Action> => {
      return this.pricingApi.staffRequestPricings().pipe(
        switchMap(({ pricings }: any) => [
          pricingsActions.staffRequestPricingsSuccess(<any>{ pricings })
        ]),
        catchError((error: RequestError) => {
          return of(pricingsActions.staffRequestPricingsFailure({ error }))
        })
      );
    })
  ));

  staffRequestPricingById$ = createEffect(() => this.actions$.pipe(
    ofType(pricingsActions.staffRequestPricingById),
    switchMap(({ id }): Observable<Action> => {
      return this.pricingApi.staffRequestPricingById(id).pipe(
        switchMap((pricing) => [
          pricingsActions.staffRequestPricingByIdSuccess(<any>{ pricing })
        ]),
        catchError((error: RequestError) => {
          return of(pricingsActions.staffRequestPricingByIdFailure({ error }))
        })
      );
    })
  ));

}
