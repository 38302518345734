import { createReducer, on } from '@ngrx/store';
import { initialState } from './state';
import * as modalActions from './actions';

export const reducer = createReducer(
  initialState,

  on(modalActions.reset, (state, props) => {
    return { ...initialState, ...props };
  }),

  on(modalActions.generateModal, (state, { modal, inputs, params }) => {
    return {
      ...state,
      active: modal,
      inputs: (typeof inputs === 'object') ? inputs : null,
      params: (typeof params === 'object') ? params : {}
    };
  }),

  on(modalActions.closeModal, (state) => {
    return {
      ...state,
      active: null,
      inputs: {}
    };
  })

);
