<app-modal-window
  [(visible)]="modalVisible"
  [actions]="modalActions"
  [header]="modalHeader"
  [focus]="defaultFocus"
  (actionSelected)="modalActionSelected($event)"
  width="25em"
>
  <div class="form-block">
    <label>Name</label>
    <input
      #defaultFocus
      class="full-width"
      pInputText
      type="text"
      placeholder="Optional"
      [(ngModel)]="taskName"
    >
  </div>
  <div class="form-block">
    <label>Group</label>
    <input
      class="full-width"
      pInputText
      type="text"
      placeholder="Optional"
      [(ngModel)]="taskGroup"
      (change)="taskGroupChange()"
    >
  </div>
  <div class="form-block">
    <label>Start</label>
    <p-calendar
      id="taskStart"
      name="taskStart"
      dateFormat="dd M yy"
      [(ngModel)]="taskStart"
      (onSelect)="taskStartChange()"
      appendTo="body"
      inputStyleClass="full-width"
      ngDefaultControl
    ></p-calendar>
  </div>
  <div class="form-block">
    <label>Finish</label>
    <p-calendar
      id="taskFinish"
      name="taskFinish"
      dateFormat="dd M yy"
      [(ngModel)]="taskFinish"
      (onSelect)="taskFinishChange()"
      appendTo="body"
      inputStyleClass="full-width"
      ngDefaultControl
    ></p-calendar>
  </div>
</app-modal-window>
