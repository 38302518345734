<div class="search-icon">
  <span class="fas">search</span>
</div>
<input
  class="search-input"
  type="text"
  [placeholder]="placeholder"
  [ngModel]="value"
  (input)="onInput($event)"
/>
