import { createSVGElement, removeChildren } from "../../svg-utils";
import { ContextMenuAction, DblClickAction, DragAction, MouseoverAction, TaskBoundElement, TaskNode, TimelineTask } from "../tl-structs";
import { TimelineLayout } from "../timeline-layout";
import { BASELINE_THICKNESS, CLASS_SCREEN_ONLY, standardAttribute, standardClass } from "../tl-style";
import { taskConstants } from "tp-common/revisions";
import { generateSVGSymbol } from "../../milestone-symbols";
import { updateTextNode } from "../timeline-render";

export function createTaskNode(task: TimelineTask): TaskNode {

  const wrapper = createSVGElement('g') as TaskBoundElement;
  wrapper.classList.add(standardClass.task);
  wrapper.setAttribute(standardAttribute.dataTaskUid, task.ID);
  wrapper.setAttribute(standardAttribute.dataDrag, DragAction.Task);
  wrapper.setAttribute(standardAttribute.dataMouseover, MouseoverAction.Task);
  wrapper.setAttribute(standardAttribute.dataContextmenu, ContextMenuAction.Task);
  wrapper.setAttribute(standardAttribute.dataDblclick, DblClickAction.Task);

  const background = createSVGElement('rect');
  background.classList.add(standardClass.taskBackground);

  const text = createSVGElement('text');
  text.classList.add(standardClass.taskText, 'task-text');

  const baseline = createSVGElement('line');
  baseline.classList.add(standardClass.taskBaseline, 'task-baseline');

  const symbol = createSVGElement('g');
  symbol.classList.add(standardClass.taskSymbol, 'task-symbol');

  const taskNode = <TaskNode>{
    wrapper, background, text, baseline, symbol,
    isPlaced: false,
    fittingData: task,
    positionData: { verticalOffset: 0, nominalHeight: 0 }
  };

  wrapper.__fitting__ = task;
  wrapper.__node__ = taskNode;

  return taskNode;

}

export function updateTaskNode(layout: TimelineLayout, task: TimelineTask, container: TaskBoundElement): void {

  const node = container.__node__;

  container.__fitting__ = task;
  container.setAttribute('transform', `translate(${task.BarStart},${task.VerticalOffset})`);
  container.setAttribute(standardAttribute.dataMilestone, Boolean(task.Milestone).toString());

  node.background.setAttribute('width', `${Math.abs(task.BarWidth)}`);
  node.background.setAttribute('height', `${Math.abs(task.Row?.nominalHeight)}`);

  node.text.setAttribute('transform', `translate(${task.TextStart - task.BarStart},0)`);

  if (task.Milestone) {
    updateMilestone(layout, container);
  } else {
    updateBarTask(layout, container);
    node.symbol.remove();
  }

  task.Milestone ? updateMilestone(layout, container) : updateBarTask(layout, container);
  
  const showBaseline = !!(layout.settings.displayBaseline && task.BaselineStart && task.BaselineFinish);

  if (showBaseline) {
    updateBaseline(container);
  } else {
    node.baseline.remove();
  }

  updateTextNode(layout.settings.textPadding, layout.settings.fontSize, layout.settings.taskPadding, node.text, task.TextWrapped);
}

/**
 * Returns position of element relative to the provided DOMRECT
 */
export function getRelativePosition(viewportRect: DOMRect, container: TaskBoundElement): number[] {

  const bRect = container.__node__.background.getBoundingClientRect();

  return [
    bRect.x - viewportRect.x,
    bRect.y - viewportRect.y
  ];
}

/**
 * Manually move the task's x/y position
 */
export function setRelativePosition(container: TaskBoundElement, x: number, y: number) {
  container.setAttribute('transform', `translate(${x},${y})`);
}

export function setTaskSelected(container: TaskBoundElement, selected = false) {
  selected ? container.setAttribute(standardAttribute.dataTaskSelected, '') : container.removeAttribute(standardAttribute.dataTaskSelected);
}

function updateBarTask(layout: TimelineLayout, container: TaskBoundElement) {

  const task = container.__fitting__;
  const node = container.__node__;

  if (task.wrapTextOutside) {
    node.text.setAttribute('data-light', 'false');
    if (layout.settings.theme?.milestoneTextColour) {
      node.text.style.fill = layout.settings.theme?.milestoneTextColour;
    }
  } else {
    node.text.setAttribute('data-light', 'true');
    node.text.style.fill = task.TextColour || layout.settings.theme?.barTextColour;
  }

  node.background.classList.remove(CLASS_SCREEN_ONLY);
  /**
   * This is moving towards using the 'theme' object in layout settings + local overrides. Currently handling for legacy as well
   */
  if (task.ProjectTasks.every((v => v.percentComplete === 100))) {
    node.background.setAttribute(standardAttribute.dataFill, 'Gray');

  } else if (!task.Colour) {
    if (layout.settings.theme?.barColour) {
      node.background.setAttribute('fill', layout.settings.theme?.barColour);
    } else {
      node.background.setAttribute(standardAttribute.dataFill, 'default');
    }
  } else {
    if (layout.settings.coloursAvailable?.has(task.Colour)) {
      node.background.setAttribute(standardAttribute.dataFill, task.Colour);
    } else {
      node.background.setAttribute('fill', task.Colour);
    }
  }
}

function updateMilestone(layout: TimelineLayout, container: TaskBoundElement) {

  const task = container.__fitting__;
  const node = container.__node__;
  const milestoneCat = layout.fittingMeta.milestoneMap?.get(task.MilestoneType);
  const symbolType = milestoneCat?.symbol || taskConstants.milestoneDefaultSymbol;
  let symbolFill;
  
  if (task.ProjectTasks.every((v) => v.percentComplete === 100)) {
    symbolFill = 'Gray'
  } else if (milestoneCat?.colour) {
    symbolFill = milestoneCat.colour;
  } else if (layout.settings.theme?.milestoneColour) {
    symbolFill = layout.settings.theme?.milestoneColour;
  } else {
    symbolFill = taskConstants.milestoneDefaultFill;
  }

  node.text.setAttribute('data-light', 'false');
  node.text.style.fill = milestoneCat?.textColour || layout.settings.theme?.milestoneTextColour;
  // 1.167 is the approx ratio of specified font size to actual height used. If font is changed
  // then value may need to be adjusted
  const symbolY = (layout.settings.textPadding  * 2 + layout.settings.fontSize * 1.167) / 2 - layout.settings.milestoneSymbolSize / 2;

  removeChildren(node.symbol);

  node.symbol.appendChild(generateSVGSymbol(symbolType, layout.settings.milestoneSymbolSize, symbolFill));
  node.symbol.setAttribute('transform', `translate(0,${symbolY})`);
  node.background.classList.add(CLASS_SCREEN_ONLY);

  container.appendChild(node.symbol);
}

function updateBaseline(container: TaskBoundElement) {

  const task = container.__fitting__;
  const node = container.__node__;

  const [bStart, bFinish] = [task.BaselineStart - task.BarStart, task.BaselineFinish - task.BarStart].sort((a, b) => a - b);

  node.baseline.setAttribute('x1', `${bStart + (BASELINE_THICKNESS / 2)}`);
  node.baseline.setAttribute('x2', `${bFinish - (BASELINE_THICKNESS / 2)}`);
  node.baseline.setAttribute('transform', `translate(0,${task.Row.nominalHeight - (BASELINE_THICKNESS / 2)})`);
  node.baseline.setAttribute(standardAttribute.dataBaseline, task.BaselineStart < task.BaselineFinish ? 'positive' : 'negative');
  // legacy:
  node.baseline.setAttribute('data-baseline', `${task.BaselineStart < task.BaselineFinish ? 'positive' : 'negative'}`);standardAttribute.dataBaseline

  container.appendChild(node.baseline);
}