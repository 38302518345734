const structs = require('./structs.js');

exports.actionDescriptions = {
  readBranch: 'View projects and views',
  editBranch: 'Edit the project data of project drafts',
  createBranch: 'Create projects',
  editBranchUserAccess: 'Edit the access of other users',
  editBranchView: 'Edit the view data of project drafts',
  archiveBranch: 'Archive projects',
  deleteArchivedBranch: 'Permanently delete archived projects',
  restoreArchivedBranch: 'Restore archived projects',
  readViews: 'Read views',
  editViews: 'Edit views',
  createViews: 'Create views',
  deleteViews: 'Delete views',
  editLicense: 'Assign and unassign existing licenses',
  createLicense: 'Purchase licenses',
  readUser: 'See the users and licenses which belong to an organization'
};

exports.actionOrder = [
  'readBranch',
  'editBranch',
  'createBranch',
  'editBranchUserAccess',
  'editBranchView',
  'archiveBranch',
  'deleteArchivedBranch',
  'restoreArchivedBranch',
  'readViews',
  'editViews',
  'createViews',
  'deleteViews',
  'editLicense',
  'createLicense',
  'readUser'
];

exports.licenseDescriptions = {
  viewer: 'Viewer',
  //editor: 'Editor',
  creator: 'Editor',
  admin: 'Manager',
  globalAdmin: 'Account admin'
};

exports.groupNames = {}

for (const [k,v] of Object.entries(structs.groups)) {
  exports.groupNames[v] = k;
}

exports.groupDescriptions = {
  'No Access': 'User has no access',
  'Read Only': 'User can view but not make changes',
  //'Editor': 'User can edit view and project data',
  'Creator': 'User can create new projects as well as edit view and project data',
  'Administrator': 'User can create new projects, edit view and project data and control the access of other users'
};

exports.groupOrder = [
  'No Access',
  'Read Only',
  //'Editor',
  'Creator',
  'Administrator'
];
