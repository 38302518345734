import { createAction, props } from '@ngrx/store';
import { RequestError } from '../../../api/structs';
import * as PowerBi from '../../../powerbi-data-structs';

import UserTncAcceptance = PowerBi.UserTncAcceptance;
import UserTncAcceptanceId = PowerBi.UserTncAcceptanceId;

export const requestUserTncAcceptances = createAction(
  '[powerbi.userTncAcceptance] requestUserTncAcceptances',
  props<any>()
);

export const requestUserTncAcceptancesSuccess = createAction(
  '[powerbi.userTncAcceptance] requestUserTncAcceptancesSuccess',
  props<{ userTncAcceptances: UserTncAcceptance[] }>()
);

export const requestUserTncAcceptancesFailure = createAction(
  '[powerbi.userTncAcceptance] requestUserTncAcceptancesFailure',
  props<{ error: RequestError }>()
);

export const requestUserTncAcceptanceById = createAction(
  '[powerbi.userTncAcceptance] requestUserTncAcceptanceById',
  props<{ id: UserTncAcceptanceId }>()
);

export const requestUserTncAcceptanceByIdSuccess = createAction(
  '[powerbi.userTncAcceptance] requestUserTncAcceptanceByIdSuccess',
  props<{ userTncAcceptance: UserTncAcceptance }>()
);

export const requestUserTncAcceptanceByIdFailure = createAction(
  '[powerbi.userTncAcceptance] requestUserTncAcceptanceByIdFailure',
  props<{ error: RequestError }>()
);
