import { RevisionData, HierarchyNode, PoapTask, ViewGroup, ViewGroupMap, MilestoneCategory, ViewLine } from './data-structs';

export enum MetaTaskField {
  add,
  moveUp,
  moveDown,
  indent,
  outdent,
  boundaries
}

export enum MetaGroupField {
  create,
  merge,
  showTasks,
  hideTasks,
  showChildren,
  moveUp,
  moveDown,
  moveTo
}

export interface PoapTaskFieldChange {
  task: PoapTask;
  field: string|MetaTaskField;
  value?: any;
  oldValue?: any;
  batchId?: string;
}

export interface PoapGroupFieldChange {
  group: ViewGroup;
  field: string|MetaGroupField;
  sibling?: ViewGroup;
  value?: any;
  oldValue?: any;
  batchId?: string;
}

export type PoapChange = PoapTaskFieldChange|PoapGroupFieldChange;

export interface PoapData {
  projects: {
    /* key is project id */
    [key: string]: HierarchyNode;
  }
  revisions: {
    /* key is revision id */
    [key: string]: RevisionData;
  };
  groupRoots: ViewGroup[];
  // map of all view groups from all revisions:
  groups: ViewGroupMap;
  // array of all tasks from all revisions in display order:
  tasks: PoapTask[];
  milestones: MilestoneCategory[];
  viewLines: ViewLine[];
}

export interface PoapRevisionData {
  tasks: PoapTask[];
  groups: {
    /* key is group id */
    [key: string]: ViewGroup
  };
}

export interface UpdateInvalidError {
  message: string;
}

export interface PoapRowSelection {
  id: string;
  task: PoapTask;
  project: HierarchyNode;
  revision: RevisionData;
  fields: string[];
}

export interface PoapActionFlags {
  add: boolean;
  delete: boolean;
  moveUp: boolean;
  moveDown: boolean;
  outdent: boolean;
  indent: boolean;
}

export interface PoapSelection {
  initiator: 'grid'|'timeline';
  selection: PoapRowSelection[];
  flags: PoapActionFlags;
}
