import { createSVGElement } from "../../svg-utils";
import { ClickAction, ContextMenuAction, GroupBoundElement, MouseoverAction } from "../tl-structs";
import { standardAttribute, standardClass } from "../tl-style";

export function updateChildClickHandle(container: GroupBoundElement) {

  if (!container.__handles__?.r) {
    container.__handles__ = container.__handles__ || {};
    container.__handles__.r = createSVGElement('path');
  }

  const handle = container.appendChild(container.__handles__.r);
  const br = getLabelRect(container);

  const width = Math.min(15, br.width * 0.4, br.height / 2);

  handle.setAttribute('d', `M ${br.x + br.width} ${br.y + br.height} V ${br.y} l ${-width} ${width} V ${br.height + br.y - width}z`);
  handle.classList.add(standardClass.swimlaneLabelHandle);
  handle.setAttribute(standardAttribute.dataClick, ClickAction.CreateGroupSibling)
  handle.setAttribute(standardAttribute.dataMouseover, MouseoverAction.CreateGroupSibling);
  handle.setAttribute(standardAttribute.dataContextmenu, ContextMenuAction.GroupLabel);

}

export function updateSiblingClickHandles(container: GroupBoundElement) {

  if (!container.__handles__?.b) {
    container.__handles__ = container.__handles__ || {};
    container.__handles__.b = createSVGElement('path');
    return;
  }

  const bHandle = container.appendChild(container.__handles__.b);
  const br = getLabelRect(container);

  const width = Math.min(15, br.height * 0.4, br.width / 2);

  bHandle.classList.add(standardClass.swimlaneLabelHandle);
  bHandle.setAttribute('d', `M ${br.x} ${br.y + br.height} l ${width} ${-width} l ${br.width - (width * 2)} 0 L ${br.x + br.width} ${br.y + br.height}z`);
  bHandle.setAttribute(standardAttribute.dataClick, ClickAction.CreateGroupSibling);
  bHandle.setAttribute(standardAttribute.dataMouseover, MouseoverAction.CreateGroupSibling);
  bHandle.setAttribute(standardAttribute.dataContextmenu, ContextMenuAction.GroupLabel);
  //const tHandle = container.appendChild(createSVGElement('path'));
  //tHandle.classList.add(standardClass.swimlaneLabelHandle);
  //tHandle.setAttribute('d', `M ${br.x} ${br.y} l ${width} ${width} l ${br.width - (width * 2)} 0 L ${br.x + br.width} ${br.y}z`);
  //tHandle.setAttribute(standardAttribute.dataClick, ClickAction.CreateGroupSibling);
  //tHandle.setAttribute(standardAttribute.dataMouseover, MouseoverAction.CreateGroupSibling);
  //container.__handles__.t = tHandle;

}

export function removeSiblingClickHandles(container: GroupBoundElement) {

  if (container.__handles__) {
    container.__handles__.b && container.__handles__.b.remove();
    container.__handles__.b = null;
    //container.__handles__.t && container.__handles__.t.remove();
    //container.__handles__.t = null;
  }

}

export function removeChildClickHandle(container: GroupBoundElement) {

  if (container.__handles__) {
    container.__handles__.r && container.__handles__.r.remove();
    container.__handles__.r = null;
  }

}

function getLabelRect(container: GroupBoundElement) {

  const rect = container.__node__.rect;

  const width = Number(rect.getAttribute('width'));
  const height = Number(rect.getAttribute('height'));
  const x = Number(rect.getAttribute('x')) || 0;
  const y = Number(rect.getAttribute('y')) || 0;

  return { width, height, x, y};
}