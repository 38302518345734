export const lineVectorEffect = 'non-scaling-stroke';
export const lineRenderMethod = 'crispEdges';
export const baselineThickness = '4';

export const standardColour = {
  lightFillEven: '#e8f0f1',
  lightFillOdd: '#f5f5f5',
  baselinePositive: 'rgb(13,194,13)',
  baselineNegative: 'red',
  defaultTaskFill: '#457fb0',
  primaryBlue1: '#14497d',
  primaryGrey1: '#262626',
  primaryGrey2: '#373737',
  primaryGrey3: '#5a5a5a',
  primaryGrey4: '#e0e1e2',
  fade1: 'rgba(0,0,0,0.12)',
  /* required to trigger point events on clear elements */
  clear: 'rgba(0,0,0,0)'
};

export const standardClass = {
  viewport: 'tl-viewport',
  axisOffsetArea: 'tl-axis-offset',
  scrollOffsetArea: 'tl-scroll-offset',
  legend: 'tl-legend',
  legendItem: 'tl-legend__item',
  legendItemSymbol: 'tl-legend__item__symbol',
  legendItemText: 'tl-legend__item__text',
  axis: 'tl-axis',
  axisRow: 'tl-axis__row',
  axisTickLine: 'tl-axis__row__tick-line',
  axisTickText: 'tl-axis__row__tick-text',
  groupHeader: 'tl-group-header',
  groupHeaderLine: 'tl-group-header__line',
  groupHeaderText: 'tl-group-header__text',
  swimlaneGroup: 'tl-group',
  swimlaneBackground: 'tl-group__background',
  swimlaneLabel: 'tl-group__label',
  swimlaneLabelHandle: 'tl-group__label__handle',
  swimlaneText: 'tl-group__text',
  swimlaneBorder: 'tl-group__border',
  //swimlaneBorderPageBreak: 'tl-group__border_page-break',
  swimlaneTasks: 'tl-group__tasks',
  task: 'tl-task',
  taskBackground: 'tl-task__background',
  taskBaseline: 'tl-task__baseline',
  taskText: 'tl-task__text',
  taskTextLine: 'tl-task__text__line',
  taskSymbol: 'tl-task__symbol',
  taskSymbolSvg: 'tl-task__symbol__svg',
  taskSymbolSvgPath: 'tl-task__symbol__svg__path',
  taskDragHandleLeft: 'tl-task__drag-left',
  taskDragHandleRight: 'tl-task__drag-right',
  taskDragHandleLabel: 'tl-task__drag-label',
  viewline: 'tl-viewline',
  viewlineLine: 'tl-viewline__line',
  viewlineMask: 'tl-viewline__mask',
  tickMarker: 'tl-tick-marker',
  printline: 'tl-print-lines',
  printlineLine: 'tl-print-lines__line',
  printlineMask: 'tl-print-lines__mask',
  groupDrag: 'tl-group-drag',
  groupDragLine: 'tl-group-drag__line',
  groupDragMask: 'tl-group-drag__mask',
  pageBreak: 'tl-page-break',
  pageBreakLine: 'tl-page-break__line',
  pageBreakMask: 'tl-page-break__mask',
  pageBreakClose: 'tl-page-break__close',
  fillPageIcon: 'tl-fill-page-icon',
  fillPageIconMask: 'tl-fill-page-icon__mask',
  fillPageIconLabel: 'tl-fill-page-icon__label'
};

export const standardAttribute = {
  dataScope: 'data-tl-scope',
  dataEven: 'data-tl-even',
  dataOdd: 'data-tl-odd',
  dataRootGroup: 'data-tl-root-group',
  //dataPageBreak: 'data-tl-page-break',
  dataPageBreakPending: 'data-tl-page-break-pending',
  dataTaskActive: 'data-tl-task-active',
  dataTaskHandles: 'data-tl-task-handles',
  dataTaskSelected: 'data-tl-task-selected',
  /* 'negative' | 'positive' */
  dataBaseline: 'data-tl-baseline',
  /* user defined colour name OR 'default' */
  dataFill: 'data-tl-fill',
  dataStroke: 'data-tl-stroke',
  /* 'dashed' | 'solid' */
  dataViewlineStyle: 'data-tl-stroke-style',
  dataGroupUid: 'data-tl-group-uid',
  dataTaskUid: 'data-tl-task-uid',

  dataClick: 'data-tl-click',
  dataDblclick: 'data-tl-dblclick',
  dataDrag: 'data-tl-drag',
  dataMouseover: 'data-tl-mouseover',
  dataContextmenu: 'data-tl-contextmenu',
  /* 'true' | 'false' */
  dataDragging: 'data-tl-dragging',
  /* 'start' | 'finish' */
  dataPrintBoundary: 'data-tl-boundary',
  /* 'true' | 'false' */
  dataMilestone: 'data-tl-milestone',
  /** 'true' | 'false' */
  dataScrollActive: 'data-tl-scroll-active'
};

export const transitionClasses = [
  standardClass.axisTickLine,
  standardClass.axisTickText,
  standardClass.swimlaneGroup,
  standardClass.swimlaneBackground,
  standardClass.swimlaneLabel,
  standardClass.swimlaneBorder,
  standardClass.task,
  standardClass.taskBackground,
  standardClass.taskBaseline,
  standardClass.viewline,
  standardClass.tickMarker,
  standardClass.printlineLine,
  standardClass.printlineMask
];

export const screenOnlyClasses = [
  standardClass.printline,
  standardClass.groupDrag,
  standardClass.pageBreak,
  standardClass.fillPageIcon
];

export const colourStyles = (colours: string[][], uid?:string) => {

  const scope = uid ? `[${standardAttribute.dataScope}='${uid}']` : '';

  return colours.map(([ k,v ]) => `

    ${scope} [${standardAttribute.dataFill}='${k}'] {
      fill: ${v};
    }

    ${scope} [${standardAttribute.dataStroke}='${k}'] {
      stroke: ${v} !important;
    }

  `).join('');

};

export const standardStyle = (uid?:string) => {

  const scope = uid ? `[${standardAttribute.dataScope}='${uid}']` : '';

  return `

    ${scope} line {
      vector-effect: ${lineVectorEffect};
    }

    /*--------------- SWIMLANE ---------------*/

    ${scope} .${standardClass.swimlaneLabel} {
      fill: ${standardColour.primaryBlue1};
      rx: 2;
    }

    ${scope} .${standardClass.swimlaneBorder} {
      stroke: ${standardColour.primaryGrey3};
      stroke-width: 0.5;
      shape-rendering: ${lineRenderMethod};
    }

    ${scope} .${standardClass.swimlaneGroup}[${standardAttribute.dataRootGroup}]
      .${standardClass.swimlaneBorder} {
        stroke-width: 2.5;
      }

    ${scope} .${standardClass.swimlaneBackground}[${standardAttribute.dataEven}] {
      fill: ${standardColour.lightFillEven};
    }

    ${scope} .${standardClass.swimlaneBackground}[${standardAttribute.dataOdd}] {
      fill: ${standardColour.lightFillOdd};
    }

    /*--------------- TASK ---------------*/

    ${scope} .${standardClass.task} {
      filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0));
    }

    ${scope} .${standardClass.taskBackground} {
      rx: 2;
    }

    ${scope} .${standardClass.taskBackground}[${standardAttribute.dataFill}='default'] {
      fill: ${standardColour.defaultTaskFill};
    }

    ${scope} .${standardClass.task}[${standardAttribute.dataMilestone}='true']
      .${standardClass.taskBackground} {
        fill: none !important;
      }

    ${scope} .${standardClass.taskBaseline} {
      stroke-width: ${baselineThickness};
      stroke-linecap: round;
    }

    ${scope} .${standardClass.taskBaseline}[${standardAttribute.dataBaseline}='positive'] {
      stroke: ${standardColour.baselinePositive};
    }

    ${scope} .${standardClass.taskBaseline}[${standardAttribute.dataBaseline}='positive'] {
      stroke: ${standardColour.baselineNegative};
    }

    /*--------------- VIEWLINE ---------------*/

    ${scope} .${standardClass.viewline},
    ${scope} .${standardClass.viewline} * {
      cursor: col-resize;
    }

    ${scope} .${standardClass.viewlineLine} {
      stroke-width: 2;
      shape-rendering: ${lineRenderMethod};
    }

    ${scope} .${standardClass.viewlineMask} {
      stroke-width: 7;
      stroke: rgba(0,0,0,0.0001);
    }

    ${scope} .${standardClass.tickMarker} {
      stroke: ${standardColour.fade1};
    }

    ${scope} .${standardClass.viewline}[${standardAttribute.dataViewlineStyle}='dashed']
      .${standardClass.viewlineLine} {
        stroke-dasharray: 6 3;
      }

    /*--------------- AXIS ---------------*/
    ${scope} .${standardClass.axisTickLine} {
      stroke: ${standardColour.primaryGrey2};
      shape-rendering: ${lineRenderMethod};
    }

    ${scope} .${standardClass.axisTickText} {
      fill: ${standardColour.primaryGrey1};
    }

    /*-------------- COLUMN HEADERS ---------------*/
    ${scope} .${standardClass.groupHeaderLine} {
      stroke: ${standardColour.primaryGrey2};
      shape-rendering: ${lineRenderMethod};
    }
  `;
};

export const screenOnlyStyle = (uid?:string) => {

  const scope = uid ? `[${standardAttribute.dataScope}='${uid}']` : '';
  return `

    ${scope} [${standardAttribute.dataTaskSelected}] .${standardClass.taskBackground} {
      /*fill: rgb(255,255,155) !important;*/
      stroke: var(--color-theme-secondary);
      stroke-width:2;
    }

    ${scope} [${standardAttribute.dataTaskHandles}] {
      filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.13));
    }

    text {
      -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
    }

    ${transitionStyle(scope)}
    ${dragHandleStyle(scope)}
    ${pageBreakStyle(scope)}
    ${printLineStyle(scope)}
    ${groupHandleStyle(scope)}
    
    /*--------------- FILL PAGE ICON ---------------*/
    ${scope} .${standardClass.fillPageIconMask} {
      position: absolute;
      pointer-events: none;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
    }

    ${scope} .${standardClass.fillPageIconLabel} {
      pointer-events: all;
      cursor: pointer;
      background: ${standardColour.primaryGrey4};
      padding: 1em 1.2em;
      border-radius: 0.5em;
      font-size: 0.9em;
      font-weight: bold;
      color: ${standardColour.primaryGrey2};
    }

    ${scope} .${standardClass.fillPageIconLabel}:hover {
      background:#cacbcd;
    }
  `;
};

const transitionStyle = scope => `
  /*--------------- TRANSITIONS ---------------*/
  ${transitionClasses.map(tc => `${scope} .${tc}:not([${standardAttribute.dataDragging}])`).join(', ')} {
    transition-property: all;
    transition-duration:0.9s;
    transition-timing-function: ease;
  }

  ${transitionClasses.map(tc => `${scope} .${tc}`).join(', ')} {
    transition-property: opacity, fill, stroke;
    transition-duration:0.9s;
    transition-timing-function: ease;
  }

  ${scope}[${standardAttribute.dataScrollActive}='true']
  ${transitionClasses.map(tc => `${scope} .${tc}:not([${standardAttribute.dataDragging}])`).join(', ')} {
    transition-duration:0.6s;
  }

  [${standardAttribute.dataDragging}],
  [${standardAttribute.dataDragging}] * {
    transition: none !important;
  }

  [${standardAttribute.dataDragging}].${standardClass.task},
  [${standardAttribute.dataDragging}].${standardClass.task} * {
    pointer-events: none;
  }
`;

const dragHandleStyle = scope => `
/*--------------- DRAG HANDLES --------------*/

  ${scope} .${standardClass.taskDragHandleLeft} rect,
  ${scope} .${standardClass.taskDragHandleRight} rect {
    opacity: 0.0001;
    rx: 2;
  }

  ${scope} .${standardClass.taskDragHandleLeft},
  ${scope} .${standardClass.taskDragHandleLeft} *,
  ${scope} .${standardClass.taskDragHandleRight},
  ${scope} .${standardClass.taskDragHandleRight} * {
    cursor: col-resize;
    /*filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.07));*/
  }
  ${scope} .${standardClass.taskDragHandleLabel} rect {
    fill: #3e3f3f;
    stroke: #3e3f3f;
    opacity: 1;
    rx: 2;
  }
  ${scope} .${standardClass.taskDragHandleLeft} path,
  ${scope} .${standardClass.taskDragHandleRight} path {
    fill: #3e3f3f;
    stroke: #3e3f3f;
    stroke-width: 3;
    stroke-linejoin: round;
    shape-rendering: geometricprecision;
  }
  ${scope} .${standardClass.taskDragHandleLabel} text {
    fill: white;
  }

`;

const pageBreakStyle = scope => `
  /*--------------- PAGEBREAK ---------------*/
  ${scope} .${standardClass.pageBreakLine} {
    stroke: blue;
    stroke-width: 2.2;
    stroke-dasharray: 8 2;
    vector-effect: ${lineVectorEffect};
    cursor: row-resize;
  }

  ${scope} .${standardClass.pageBreak}[${standardAttribute.dataPageBreakPending}] * {
    pointer-events:none !important;
  }

  ${scope} .${standardClass.pageBreakMask} {
    stroke: ${standardColour.clear};
    stroke-width: 5;
    vector-effect: ${lineVectorEffect};
    cursor: row-resize;
  }

  ${scope} .${standardClass.pageBreakClose} {
    pointer-events:none;
  }

  ${scope} .${standardClass.pageBreakClose} div {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  ${scope} .${standardClass.pageBreakClose} span {
    pointer-events: all;
    cursor: pointer;
    padding: 0.7em;
    background: blue;
    border-radius: 0.5em;
    font-size: 0.85em;
    font-weight: bold;
    color: white;
    text-align: center;
    transition: background 0.3s ease;
  }

  ${scope} .${standardClass.pageBreakClose} span:hover {
    background:#0000d6;
  }
`

const printLineStyle = scope => `
  /*--------------- PRINTLINES ---------------*/
  ${scope}[data-view-mode='1'] .${standardClass.printlineLine} {
    cursor:col-resize;
    stroke: blue;
    stroke-width: 3;
    stroke-dasharray: 6 3;
  }

  ${scope}[data-view-mode='1'] .${standardClass.printlineMask} {
    fill: rgba(0,0,0,0.15);
  }

  ${scope}[data-view-mode='0'] .${standardClass.printlineMask} {
    display: none !important;
  }
`;

const groupHandleStyle = scope => `

  ${scope} .${standardClass.swimlaneLabelHandle} {
    transition: var(--trans-fast);
    fill: white;
    opacity: 0.0001;
    stroke: white;
    stroke-width: 6;
    stroke-linejoin: round;
    cursor: copy;
  }

  ${scope} .${standardClass.swimlaneLabelHandle}:hover {
    opacity: 0.3;
  }

`;

/** below is @deprecated */
export const DEFAULT_MILESTONE = {
  Symbol: 'diamond-thin',
  Colour:'black'
}

export const CLASS_SCREEN_ONLY = 'screen-only';

export const BASELINE_THICKNESS = 4;

export const CORE_STYLE = [
  [ 'text', {
    'font-family':'sans-serif'
  }],
  [ `.group-background[data-even='false']`, {
    fill: 'rgb(228 243 253)'
  }],
  /*[ `.group-background.readOnly[data-even='false']`, {
    fill: 'rgb(192,192,192)',
    'fill-opacity': '0.7'
  }],*/
  [ `.group-background[data-even='true']`, {
    fill: 'rgb(250 253 255);'
  }],
  /*[ `.group-background.readOnly[data-even='true']`, {
    fill: 'rgb(220,220,220)',
    'fill-opacity': '0.7'
  }],*/
  [ `.group-rect`, {
    fill: '#14497d',
    rx: '2'
  }],
  [ `.task-rect`, {
    rx: '2'
  }],
  [ `.group-border`, {
    'stroke': 'rgb(90,90,90)',
    'stroke-width':'0.5',
    'shape-rendering':'crispEdges'
  }],
  [ `[data-root-group='true'] .group-border`, {
    'stroke-width': '2.5'
  }],
  [ `.task-rect[data-fill='default']`, {
    fill: '#457fb0'
  }],
  [ `text[data-light='true']`, {
    fill:'white'
  }],
  [ `.group-container > text`, {
    fill:'white'
  }],
  [ '.task-baseline', {
    'stroke-width': BASELINE_THICKNESS,
    'stroke-linecap':'round'
  }],
  [ `.task-baseline[data-baseline='positive']`, {
    stroke: standardColour.baselinePositive
  }],
  [ `.task-baseline[data-baseline='negative']`, {
    stroke: 'red'
  }],
  [ '.print-line', {
    'stroke': 'blue',
    'stroke-width': '2',
    'stroke-dasharray': '6 3',
    'shape-rendering':'crispEdges'
  }],
  [ `task-group[data-active='true'] rect`, {
    'stroke': 'red',
    'stroke-width':'2',
    'fill': 'yellow'
  }],
  [ `.viewline-group[data-style='dashed'] .viewline-line`, {
    'stroke-dasharray':'6 3'
  }],
  [ '.viewline-line', {
    'stroke-width':'2',
    'shape-rendering':'crispEdges'
  }],
  [ '.viewline-mask', {
    'stroke-width':'4',
    'stroke':'rgba(0,0,0,0)'
  }],
  [ '#tick-markers line', {
    'stroke': 'rgba(0,0,0,0.12)'
  }],
  [ 'line, path' , {
    'vector-effect': 'non-scaling-stroke',
    'shape-rendering':'crispEdges'
  }],
  [ '.axis-level__line', {
    'stroke': 'rgb(55,55,55)',
    'shape-rendering':'crispEdges'
  }]
];

export const CLIP_STYLE = (hClipId) => [
  [ '.group-task-group', {
    'clip-path': `url(#${hClipId})`,
    '-webkit-clip-path': `url(#${hClipId})`
  }]
];
