import { GroupFitting } from './tl-structs';

export function dateMin(...args: Date[]): Date {
  const dates = args.filter(a => a instanceof Date);
  return dates.length ? new Date(Math.min(...dates.map(d => d.getTime()))) : null;
}

export function dateMax(...args: Date[]): Date {
  const dates = args.filter(a => a instanceof Date);
  return dates.length ? new Date(Math.max(...dates.map(d => d.getTime()))) : null;
}

export function safeMin(...args: number[]): number {
  return Math.min( ...args.filter(v => v || v === 0) );
}

export function safeMax(...args: number[]): number {
  return Math.max( ...args.filter(v => v || v === 0) );
}

export function getGroupFittingDepth(group: Partial<GroupFitting>): number {
  let count = 0;
  let currentGroup = group;
  while ( currentGroup ) {
    count++;
    currentGroup = currentGroup.parent;
  }
  return count;
}

export function prependMilestoneDate( date: Date, text: string ): string {
  return `[ ${ date.getDate() }/${ date.getMonth() + 1 } ] ${ text }`;
}
