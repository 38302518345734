import { HierarchyNode, HierarchyNodeId, RevisionId, RevisionInfo, TreeProjectNode } from 'tp-traqplan-core/dist/data-structs';
import { DataStore } from '../structs';
import { dsInitial, dsPending } from '../helpers';

export interface TreeGroupable {
  label: string;
  value: (h: TreeProjectNode) => string;
  formatted: (h: TreeProjectNode) => string;
  icon: string;
}

export enum TreeFilterType {
  favourites = 0,
  drafts = 1,
  selected = 2
}

export interface TreeFilterState {
  search: string | null;
  filterType: TreeFilterType | null;
}

export interface TreeState {
  groups: TreeGroupable[];
  groupsAvailable: TreeGroupable[];
  expandedGroups: { [key: string]: boolean };
  // key by HierarchyNodeId
  expanded: { [key: string]: boolean; };
  // key by RevisionId
  selected: { [key: string]: boolean; };
  // key by RevisionId
  revisionsExpanded: { [key: string]: boolean; };
  filter: TreeFilterState;
}

export const key = 'projects';

export interface State {
  hierarchy: DataStore<HierarchyNode[]>;
  favourites: DataStore<HierarchyNodeId[]>;
  tree: TreeState;
  created: HierarchyNodeId | null;
  rehydrated: boolean;
  revisionViews: RevisionInfo[];
  lastSelectedRevision?: RevisionId;
  isReady: boolean;
}

export const treeGroupings: TreeGroupable[] = [
  {
    label: 'Programme',
    value: h => h.parent,
    formatted: h => h.parentName,
    icon: 'book'
  },
  {
    label: 'Manager',
    value: h => h.author,
    formatted: h => h.authorName,
    icon: null
  }
];

export const initialState: State = {
  hierarchy: dsInitial<HierarchyNode[]>(),
  favourites: dsInitial<HierarchyNodeId[]>(),
  tree: {
    groups: [treeGroupings[0]],
    groupsAvailable: treeGroupings,
    expandedGroups: {},
    expanded: {},
    selected: {},
    revisionsExpanded: {},
    filter: {
      search: null,
      filterType: null
    }
  },
  created: null,
  rehydrated: false,
  revisionViews: [],
  lastSelectedRevision: undefined,
  isReady: false
};

export function beforeSave(state) {
  return {
    ...state,
    hierarchy: Object.keys(state.tree.selected).length ? dsPending<HierarchyNode>() : dsInitial<HierarchyNode[]>()
  }
}

export function beforeLoad(state) {
  return {
    ...state,
    tree: {
      ...state.tree,
      groups: state.tree.groups?.map(g => {
        return treeGroupings.find(t => t.label === g.label)
      }) || [],
      groupsAvailable: treeGroupings,
      filter: {
        ...initialState.tree.filter
      }
    },
    rehydrated: false
  }
}
