import { createReducer, on } from '@ngrx/store';
import { initialState } from './state';
import * as userTncAcceptancesActions from './actions';

export const reducer = createReducer(
  initialState,

  on(userTncAcceptancesActions.staffRequestUserTncAcceptancesSuccess, (state, props) => {
    return {
      ...state,
      userTncAcceptances: props.userTncAcceptances
    };
  }),

  on(userTncAcceptancesActions.staffRequestUserTncAcceptanceByIdSuccess, (state, props) => {
    return {
      ...state,
      userTncAcceptance: props.userTncAcceptance
    };
  })

);
