import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { Store } from '@ngrx/store';
import { Subject, of } from 'rxjs';
import { map, tap, filter, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { State } from '../../state';
import { namingConventionValidator } from '../../shared/validators/naming-convention.validator';
import { HierarchyNode } from 'tp-traqplan-core/dist/data-structs';
import * as projectsActions from '../../state/projects/actions';
import * as modalActions from '../../state/modal/actions';
// import { HierarchyApiService } from 'src/app/api/hierarchy-api.service';
import { HierarchyApiService } from '../../api/hierarchy-api.service';
// import { matchBasicSearchQuery } from 'src/app/util/string';

const defaultTitle = 'Move Programme';

@Component({
  selector: 'app-move-programme',
  templateUrl: './move-programme.component.html',
  styleUrls: ['./move-programme.component.scss']
})
export class MoveProgrammeComponent implements OnDestroy, OnInit {

  @Input() programme: HierarchyNode;

  actions: ModalWindowAction[] = [
    {
      label: 'Move Programme',
      type: 'positive',
      confirm: true,
      valid: () => (this.programme && this.programmeForm.valid),
      enterKey: true,
    },
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true,
      escKey: true
    }
  ];

  title = defaultTitle;
  visible = true;

  programmeForm = new FormGroup({
    workspace: new FormControl('',
      [
        Validators.required,
        Validators.minLength(1),
        namingConventionValidator()
      ]
    )
  }, [], [
    () => {

      const workspace = this.programmeForm.controls.workspace.value;
      const programmeId = this.programme?.id;

      if (!workspace || !programmeId) return of({ incomplete: true });

      return this.hierarchyApi.validateProgrammeMove({
        copy: false,
        targetName: null,
        targetId: this.programme?.id,
        workspaceName: (typeof workspace === 'string') ? workspace : workspace.name
      }).pipe(
        map(({ errors }) => {
          this.validationErrors = errors;
          return errors?.length ? { validationError: true } : null
        }),
      );

    }
  ]);

  get workspaceControl() {
    return this.programmeForm.controls.workspace;
  }

  validationErrors: string[];
  
  workspaceSuggestions: HierarchyNode[];

  isNewWorkspace: boolean;

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private hierarchyApi: HierarchyApiService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {
   
    if (this.programme) {

      this.title = `Move Programme - ${this.programme.name}`;

      this.programmeForm.valueChanges.pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.store),
      ).subscribe(([changes, { account }]) => {
        this.isNewWorkspace = (changes.workspace?.length && !account.root.available.value.some(w => w.name === changes.workspace));
      });
    }

  }

  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }

  onActionSelected(action: ModalWindowAction): void {

    if (!action.confirm || !this.programme || !this.programmeForm.valid) {
      return;
    }

    const workspace = this.programmeForm.controls.workspace.value;
    const programmeId = this.programme?.id;

    if (!workspace || !programmeId) return;

    this.hierarchyApi.moveProgramme({
      copy: false,
      targetName: null,
      targetId: this.programme.id,
      workspaceName: (typeof workspace === 'string') ? workspace : workspace.name
    }).pipe(
      tap(() => {
        this.store.dispatch(
          projectsActions.requestProjectTree({})
        );
      })
    ).subscribe();
  }

  searchWorkspaces(): void {
    this.store.pipe(
      takeUntil(this.destroy$),
      filter(state => !!state.account.root.available?.value),
      take(1),
      tap(state => {
        const selectedWorkspace = state.account.root.selected.id;
        this.workspaceSuggestions = state.account.root.available.value.filter(w => w.id !== selectedWorkspace);
      })
    ).subscribe();
  }



}
