import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../';
import { HierarchyApiService } from '../../api/hierarchy-api.service';
import { RevisionsApiService } from '../../api/revisions-api.service';
import { ProjectsService } from '../selectors/projects.service';
import { RequestError } from '../../api/structs';
import { HierarchyNode } from 'tp-traqplan-core/dist/data-structs';
import * as projectsActions from './actions';
import * as appActions from '../app/actions';
import * as accountActions from '../account/actions';
import { nullAction } from '../helpers';

@Injectable()
export class ProjectsEffects {

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private hierarchyApi: HierarchyApiService,
    private revisionsApi: RevisionsApiService,
    private router: Router,
    private projectsService: ProjectsService
  ) { }

  requestProjectTree$ = createEffect(() => this.actions$.pipe(
    ofType(
      projectsActions.requestProjectTree,
      accountActions.assignAccessSuccess
    ),
    withLatestFrom(this.store),
    switchMap(([{ selectProject, unselectRevision, selectView }, { account, poap, projects }]) => {
      const revisionViews = projects?.revisionViews;
      let availableRevisions;

      if (revisionViews && selectProject && unselectRevision && selectView) {
        availableRevisions = revisionViews.filter(({ id, createdView, project, published, view }) => (
          id !== unselectRevision && project === selectProject && (published ? (view === null || view === selectView) : createdView === selectView)
        ));
      }

      if (account.root.selected) {
        return this.hierarchyApi.getTree({ root: account.root.selected.id }).pipe(
          switchMap((nodes: HierarchyNode[]) => {
            const viewId = selectView || poap?.viewSelected?.id;
            return [
              projectsActions.requestProjectTreeSuccess({ nodes, availableRevisions, viewId }),
              appActions.hideLoading()
            ]
          }),
          catchError((error: RequestError) => {
            return of(projectsActions.requestProjectTreeFailure({ error }))
          })
        );
      } else {
        return of(nullAction())
      }
    })
  ));

  createNodeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(projectsActions.createNodeSuccess),
    switchMap(({ workspace, view }) => {
      return [
        projectsActions.requestProjectTree({}),
        projectsActions.requestRevisionViews({ workspace, view }),
        appActions.isProjectCreated({ isProjectCreated: true })
      ]
    })
  ));

  createNode$ = createEffect(() => this.actions$.pipe(
    ofType(projectsActions.createNode),
    withLatestFrom(this.store),
    switchMap(([{ path }, state]) => {

      // always cascade this setting down the path regardless of other settings:
      const allowImportedEdits = path[0].allowImportedEdits;

      let $: Observable<HierarchyNode[]> = of([]);

      // iterate through path - as needed create node before proceeding
      for (let i = 0; i < path.length; i++) {
        const p: any = path[i];
        // if node already created then it will just be added to acc path
        if (p.id) {
          $ = $.pipe(
            map(acc => [...acc, p])
          );
          continue;
        }
        // else create node then add to path:
        $ = $.pipe(
          switchMap(acc => {
            return this.hierarchyApi.create({
              path: acc.map(v => v.id),
              name: p.name,
              allowImportedEdits
            }).pipe(
              map(({ node }) => [...acc, node] as HierarchyNode[])
            );
          })
        );
      }
      // once project created create draft:
      $ = $.pipe(
        switchMap(acc => {
          return this.revisionsApi.createDraft({
            project: acc[acc.length - 1].id,
            view: state.poap.viewSelected.id
          }).pipe(
            map(() => acc)
          );
        })
      );

      return $.pipe(
        switchMap(acc => of(projectsActions.createNodeSuccess({
          path: acc.map(v => v.id),
          workspace: state.account.selected.id,
          view: state.poap.viewSelected.id
        })))
      );
    })
  ))

  updateNode$ = createEffect(() => this.actions$.pipe(
    ofType(projectsActions.updateNode),
    switchMap(({ id, update }) => {
      return this.hierarchyApi.update({ id, update }).pipe(
        switchMap(() => [
          projectsActions.requestProjectTree({}),
          projectsActions.updateNodeSuccess()
        ]),
        catchError((error: RequestError) => {
          return of(projectsActions.updateNodeFailure({ error }));
        })
      );
    })
  ));

  moveNode$ = createEffect(() => this.actions$.pipe(
    ofType(projectsActions.moveNode),
    switchMap(({ targetId, parentId }) => {
      return this.hierarchyApi.move({ targetId, parentId }).pipe(
        switchMap(() => [
          projectsActions.requestProjectTree({})
        ])
      );
    })
  ));

  archiveNode$ = createEffect(() => this.actions$.pipe(
    ofType(projectsActions.archiveNode),
    switchMap(({ id }) => {
      return of(projectsActions.updateNode({
        id: id,
        update: {
          archived: new Date()
        }
      }));
    })
  ));

  deleteNode$ = createEffect(() => this.actions$.pipe(
    ofType(projectsActions.deleteNode),
    switchMap(({ id }) => {
      return this.hierarchyApi.permanentlyDelete(id).pipe(
        switchMap(() => [
          projectsActions.requestProjectTree({})
        ])
      );
    })
  ));

  restoreNode$ = createEffect(() => this.actions$.pipe(
    ofType(projectsActions.restoreNode),
    switchMap(({ id }) => {
      return of(projectsActions.updateNode({
        id: id,
        update: {
          archived: null,
          archivedBy: null
        }
      }));
    })
  ));

  requestFavourites$ = createEffect(() => this.actions$.pipe(
    ofType(projectsActions.requestFavourites),
    switchMap(() => {
      return this.hierarchyApi.getFavourites().pipe(
        switchMap(({ favourites }) => [
          projectsActions.requestFavouritesSuccess({ favourites })
        ])
      );
    })
  ));

  addToFavourites$ = createEffect(() => this.actions$.pipe(
    ofType(projectsActions.addToFavourites),
    switchMap(({ nodeId }) => {
      return this.hierarchyApi.addToFavourites(nodeId).pipe(
        switchMap(() => [
          projectsActions.requestFavourites()
        ])
      )
    })
  ));

  removeFromFavourites$ = createEffect(() => this.actions$.pipe(
    ofType(projectsActions.removeFromFavourites),
    switchMap(({ nodeId }) => {
      return this.hierarchyApi.removeFromFavourites(nodeId).pipe(
        switchMap(() => [
          projectsActions.requestFavourites()
        ])
      )
    })
  ));

  requestRevisionViews$ = createEffect(() => this.actions$.pipe(
    ofType(projectsActions.requestRevisionViews),
    switchMap(({ workspace, view }) => {
      return this.revisionsApi.getViewData({ workspace, view }).pipe(
        switchMap((revisionViews) => [
          projectsActions.requestRevisionViewsSuccess({ revisionViews })
        ]),
        catchError((error: RequestError) => {
          return of(projectsActions.requestRevisionViewsFailure({ error }))
        })
      );
    })
  ));

  selectProjectTreeRevisions$ = createEffect(() => this.actions$.pipe(
    ofType(projectsActions.selectProjectTreeRevisions),
    switchMap(() => {
      return [projectsActions.isReady({ isReady: false })];
    })
  ));
}
