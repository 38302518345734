import { Pipe, PipeTransform } from '@angular/core';
import { standardDateTime } from 'tp-traqplan-core/dist/date-format';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  transform(value: Date|number|string): unknown {
    const d = new Date(value);
    return standardDateTime(d);
  }

}
