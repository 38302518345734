import { uuid } from 'tp-common/uuid.js';
import { outlineNumbers as oNum, viewGroups, taskMutations } from 'tp-common/revisions';
import { User, ViewGroup } from 'tp-traqplan-core/dist/data-structs';
import { MetaTaskField, MetaGroupField, PoapTaskFieldChange, PoapGroupFieldChange, PoapRevisionData } from 'tp-traqplan-core/dist/workspace-structs';
import { timeDay } from 'd3';

export const taskMutators: { [key: string]: (data: PoapRevisionData, change: PoapTaskFieldChange, index: number, user: User) => PoapRevisionData } = {};

export const groupMutators: { [key: string]: (data: PoapRevisionData, change: PoapGroupFieldChange) => PoapRevisionData } = {};

taskMutators[MetaTaskField.add] = function(data: PoapRevisionData, change: PoapTaskFieldChange, index: number, user: User): PoapRevisionData {
  const options = change.value || {};
  const start = timeDay.floor(new Date());
  const finish = timeDay.offset(start, 7);

  // if groupPath specified (and not group) then infer the group id, creating any required groups:
  if (options.groupPath?.length) {

    const groups: ViewGroup[] = viewGroups.evaluateNewGroupPath(data.groups, options.groupPath);

    options.group = groups[groups.length - 1].id;

  }

  const poapTask = {
    id: uuid(),
    importedId: options.importedId || null,
    project: change.task.project,
    revision: change.task.revision,
    view: change.task.view,
    published: null,
    author: user.id,
    outlineNumber: null,
    milestone: options.milestone,
    start: options.start || start,
    finish: options.finish || finish,
    percentComplete: null,
    name: options.name || 'New Task',
    group: options.group || null,
    groupPath: options.group ? viewGroups.getGroupNamePath(data.groups, options.group) : [],
    milestoneCategory: options.milestoneCategory || null,
    rename: options.rename || null,
    selected: Object.prototype.hasOwnProperty.call(options, 'selected') ? options.selected : true,
    colour: options.colour || null,
    deleted: null
  };

  const insertionIndex = oNum.insert(data.tasks, index, poapTask, true);

  data.tasks = taskMutations.updateParentBoundaries(data.tasks, insertionIndex);

  return data;
};

taskMutators[MetaTaskField.indent] = function(data: PoapRevisionData, change: PoapTaskFieldChange, index: number): PoapRevisionData {
  data.tasks = oNum.indent(data.tasks, index);
  data.tasks = taskMutations.updateParentBoundaries(data.tasks, index);
  return data;
};

taskMutators[MetaTaskField.outdent] = function(data: PoapRevisionData, change: PoapTaskFieldChange, index: number): PoapRevisionData {

  const parent = oNum.getParent(data.tasks, index);

  data.tasks = oNum.outdent(data.tasks, index);

  const newIndex = data.tasks.findIndex(t => t.id === change.task.id);
  const parentIndex = data.tasks.findIndex(t => t.id === parent.id);

  data.tasks = taskMutations.updateParentBoundaries(data.tasks, newIndex);
  data.tasks = taskMutations.updateParentBoundaries(data.tasks, parentIndex);

  return data;
};

taskMutators[MetaTaskField.moveUp] = function(data: PoapRevisionData, change: PoapTaskFieldChange, index: number): PoapRevisionData {
  data.tasks = oNum.moveUp(data.tasks, index);
  return data;
};

taskMutators[MetaTaskField.moveDown] = function(data: PoapRevisionData, change: PoapTaskFieldChange, index: number): PoapRevisionData {
  data.tasks = oNum.moveDown(data.tasks, index);
  return data;
};

taskMutators[MetaTaskField.boundaries] = function(data: PoapRevisionData, change: PoapTaskFieldChange, index: number): PoapRevisionData {
  const rounded = change.value.map(c => timeDay.round(c));

  data.tasks[index] = {
    ...change.task,
    start: rounded[0],
    finish: rounded[1],
    milestone: isMilestone(rounded[0], rounded[1])
  };

  data.tasks = taskMutations.updateParentBoundaries(data.tasks, index);

  return data;
};

taskMutators.start = function(data: PoapRevisionData, change: PoapTaskFieldChange, index: number): PoapRevisionData {
  // when start changes, move finish date with it:
  const value = timeDay.round(new Date(change.value));
  const timeDiff = value.getTime() - change.oldValue.getTime();
  // if no finish date supplied - set it to one week from start date:
  const finish = change.task.finish?.getTime ? timeDay.round(new Date(change.task.finish.getTime() + timeDiff)) : timeDay.offset(value, 7);

  data.tasks[index] = {
    ...data.tasks[index],
    start: value,
    finish: finish,
    milestone: isMilestone(value, finish)
  };

  data.tasks = taskMutations.updateParentBoundaries(data.tasks, index);

  return data;
};

taskMutators.finish = function(data: PoapRevisionData, change: PoapTaskFieldChange, index: number): PoapRevisionData {
  // clamp finish date to be before or equal to start date:
  const value = timeDay.round(new Date(change.value));
  // if start date present then do not allow finish to be before start:
  const clamped = (change.task.start?.getTime && change.task.start.getTime() > value.getTime()) ? new Date(change.task.start) : value;
  const finish = timeDay.round(clamped);

  data.tasks[index] = {
    ...data.tasks[index],
    finish: finish,
    milestone: isMilestone(change.task.start, finish)
  };

  data.tasks = taskMutations.updateParentBoundaries(data.tasks, index);

  return data;
};

taskMutators.percentComplete = function(data: PoapRevisionData, change: PoapTaskFieldChange, index: number): PoapRevisionData {
  // clamp to be between 0 - 100:
  const clamped = (typeof change.value === 'number') ? Math.max(0, Math.min(100, change.value)) : void 0;
  data.tasks[index] = {
    ...data.tasks[index],
    percentComplete: clamped
  };
  return data;
};

taskMutators.name = function(data: PoapRevisionData, change: PoapTaskFieldChange, index: number): PoapRevisionData {
  data.tasks[index] = {
    ...data.tasks[index],
    name: change.value || void 0
  };
  return data;
};

taskMutators.groupPath = function(data: PoapRevisionData, change: PoapTaskFieldChange, index: number): PoapRevisionData {
  const groups = viewGroups.evaluateNewGroupPath(data.groups, change.value);
  data.tasks[index] = {
    ...data.tasks[index],
    groupPath: change.value,
    group: groups[groups.length-1].id
  };
  return data;
};

taskMutators.milestoneCategory = function(data: PoapRevisionData, change: PoapTaskFieldChange, index: number): PoapRevisionData {
  data.tasks[index] = {
    ...data.tasks[index],
    milestoneCategory: change.value || void 0
  };
  return data;
};

taskMutators.rename = function(data: PoapRevisionData, change: PoapTaskFieldChange, index: number): PoapRevisionData {
  data.tasks[index] = {
    ...data.tasks[index],
    rename: change.value || void 0
  };
  return data;
};

taskMutators.selected = function(data: PoapRevisionData, change: PoapTaskFieldChange, index: number): PoapRevisionData {
  data.tasks[index] = {
    ...data.tasks[index],
    selected: change.value || false
  };
  return data;
};

taskMutators.colour = function(data: PoapRevisionData, change: PoapTaskFieldChange, index: number): PoapRevisionData {
  data.tasks[index] = {
    ...data.tasks[index],
    colour: change.value || void 0
  };
  return data;
};

taskMutators.deleted = function(data: PoapRevisionData, change: PoapTaskFieldChange, index: number): PoapRevisionData {
  const deletedTask = data.tasks[index];
  data.tasks = oNum.remove(data.tasks, index);
  // bit wierd - we need to push the removed task back to the data:
  data.tasks.push({
    ...deletedTask,
    deleted: true
  });
  return data;
};

groupMutators[MetaGroupField.create] = function(data: PoapRevisionData, change: PoapGroupFieldChange): PoapRevisionData {
  const parent = change.group;
  const newGroup = viewGroups.viewGroupFactory({
    projectId: parent.project,
    revisionId: parent.revision,
    viewId: parent.view,
    action: null,
    version: null,
    viewGroup: {
      ...change.value,
      parent: parent.id
    }
  });

  data.groups[newGroup.id] = newGroup;

  const index = change.sibling ? parent.children.indexOf(change.sibling.id) : parent.children.length;
  const children = [...parent.children];
  children.splice(index, 0, newGroup.id);

  data.groups[parent.id] = { ...parent , children };
  
  return data;
};

groupMutators[MetaGroupField.merge] = function(data: PoapRevisionData, change: PoapGroupFieldChange): PoapRevisionData {

  const source = change.group;
  const target = data.groups[change.value];
  const parent = data.groups[source.parent];

  if (!target) {
    console.error(`Cannot merge: the provided target id does not exist: '${change.value}'`);
    return data;
  }

  // delete the merging group:
  data.groups[source.id] = {
    ...data.groups[source.id],
    deleted: true
  };

  data.groups[parent.id] = {
    ...data.groups[parent.id],
    children: data.groups[parent.id].children.filter(id => id !== source.id)
  };

  // reassign all tasks from source group:
  for (let i=0; i < data.tasks.length; i++) {
    const task = data.tasks[i];
    if (task.group === source.id) {
      data.tasks[i] = {
        ...task,
        group: target.id
      };
    }
  }

  // delete obsolete group:
  data = groupMutators.deleted(data, <PoapGroupFieldChange>{ group: source, field: 'deleted' });

  return data;

};

groupMutators[MetaGroupField.showTasks] = function(data: PoapRevisionData, change: PoapGroupFieldChange): PoapRevisionData {

  const descendants = viewGroups.getDescendants(data.groups, change.group);
  const groupIds = new Set([...descendants, change.group].map(d => d.id));

  // unassign group ids from tasks belonging to this group or any descendants:
  for (let i=0; i < data.tasks.length; i++) {
    const task = data.tasks[i];
    if (task.group && groupIds.has(task.group)) {
      data.tasks[i] = {
        ...task,
        selected: true
      };
    }
  }

  return data;

};

groupMutators[MetaGroupField.hideTasks] = function(data: PoapRevisionData, change: PoapGroupFieldChange): PoapRevisionData {

  const descendants = viewGroups.getDescendants(data.groups, change.group);
  const groupIds = new Set([...descendants, change.group].map(d => d.id));

  // unassign group ids from tasks belonging to this group or any descendants:
  for (let i=0; i < data.tasks.length; i++) {
    const task = data.tasks[i];
    if (task.group && groupIds.has(task.group)) {
      data.tasks[i] = {
        ...task,
        selected: false
      };
    }
  }

  return data;

};

groupMutators[MetaGroupField.moveUp] = function(data: PoapRevisionData, change: PoapGroupFieldChange): PoapRevisionData {
  data.groups = viewGroups.moveUp(data.groups, change.group.id);
  return data;
};

groupMutators[MetaGroupField.moveDown] = function(data: PoapRevisionData, change: PoapGroupFieldChange): PoapRevisionData {
  data.groups = viewGroups.moveDown(data.groups, change.group.id);
  return data;
};

groupMutators[MetaGroupField.moveTo] = function(data: PoapRevisionData, change: PoapGroupFieldChange): PoapRevisionData {
  data.groups = viewGroups.moveTo(data.groups, change.group.id, change.value.id);
  return data;
};

groupMutators.name = function(data: PoapRevisionData, change: PoapGroupFieldChange): PoapRevisionData {
  data.groups[change.group.id] = {
    ...data.groups[change.group.id],
    name: change.value
  };
  return data;
};

groupMutators.drawingLayout = function(data: PoapRevisionData, change: PoapGroupFieldChange): PoapRevisionData {
  data.groups[change.group.id] = {
    ...data.groups[change.group.id],
    drawingLayout: change.value
  };
  return data;
};

groupMutators.deleted = function(data: PoapRevisionData, change: PoapGroupFieldChange): PoapRevisionData {

  const parent = data.groups[change.group.parent];
  const descendants = viewGroups.getDescendants(data.groups, change.group);
  const groupIds = new Set([...descendants, change.group].map(d => d.id));

  for (const group of [...descendants, change.group]) {
    data.groups[group.id] = {
      ...group,
      deleted: true
    };
  }

  // update parent group:
  data.groups[parent.id] = {
    ...parent,
    children: parent.children.filter(id => id !== change.group.id)
  };

  // assign tasks to parent group unless parent group is the root group:
  const parentGroup = data.groups[change.group.parent];
  const newGroupId = parentGroup.parent ? parent.id : null;

  // unassign group ids from tasks belonging to this group or any descendants:
  for (let i=0; i < data.tasks.length; i++) {
    const task = data.tasks[i];
    if (task.group && groupIds.has(task.group)) {
      data.tasks[i] = {
        ...task,
        group: newGroupId,
        groupPath: []
      };
    }
  }

  // mark group as deleted:
  
  return data;
};

groupMutators.children = function(data: PoapRevisionData, change: PoapGroupFieldChange): PoapRevisionData {
  data.groups[change.group.id] = {
    ...data.groups[change.group.id],
    children: change.value
  };
  return data;
};

function isMilestone(start: Date, finish: Date): boolean {
  return Math.abs(finish.getTime() - start.getTime()) < 4.32e7;
}
