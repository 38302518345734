import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { viewGroups } from 'tp-common/revisions';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';
import { RevisionConflict, RevisionConflictInfo, RevisionConflictResult, RevisionConflictTask, RevisionConflictViewTask } from 'tp-traqplan-core/dist/data-structs';
import { standardFormat } from 'tp-traqplan-core/dist/date-format';
import { State } from '../../state';
import * as appActions from '../../state/app/actions';
import * as modalActions from '../../state/modal/actions';
import * as poapActions from '../../state/poap/actions';
import { PoapSelectorService } from '../../state/selectors/poap-selector.service';

@Component({
  selector: 'app-publish-conflict',
  templateUrl: './publish-conflict.component.html',
  styleUrls: ['./publish-conflict.component.scss']
})
export class PublishConflictComponent implements OnDestroy {

  actions: ModalWindowAction[] = [
    {
      label: 'Abort',
      type: 'neutral',
      cancel: true
    },
    {
      label: 'Proceed, accept all auto-resolved values',
      type: 'positive',
      confirm: true
    }
  ];

  visible = true;

  conflict: RevisionConflict;
  resolution: any = {};

  private destroy$ = new Subject<void>();
  private showAdvancedOptions: boolean = false;

  constructor(
    private store: Store<State>,
    private poapSelector: PoapSelectorService
  ) {
    this.visible = true;
    this.poapSelector.publishConflicts$.pipe(takeUntil(this.destroy$)).subscribe((conflicts) => {
      this.store.pipe(take(1)).subscribe((state) => {
        this.initData(conflicts[state.modal?.params?.revisionId]);
      });
    });
  }

  private initData(conflict: RevisionConflict): void {
    if (conflict) {
      this.conflict = conflict;
      this.setRevisionTtile(conflict?.draft);
      this.setRevisionTtile(conflict?.latest);
      this.setRevisionTtile(conflict?.latestViewData);
    }
  }

  private setRevisionTtile(revision?: RevisionConflictInfo): void {
    if (revision && revision.id) {
      const { label, projectVersion, published, viewVersion } = revision;
      const hasVersion = projectVersion >= 0 && viewVersion >= 0;
      const hasPublished = !!published;
      const hasLabel = !!label;

      if (hasVersion) {
        revision.title = `v${projectVersion}.${viewVersion}${hasPublished ? ` ${dayjs(published).format('DD-MMM-YYYY')}` : ''}${hasLabel ? ` (${label})` : ''}`;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isInputValid(): boolean {
    return true;
  }

  onActionSelected(action: ModalWindowAction): void {
    if (!action.confirm) {
      this.store.dispatch(appActions.hideLoading());
      return;
    }

    const { draft, latest, latestViewData } = this.conflict;
    const resolution = this.resolution || {};
    const { discard, overwrite } = resolution;
    const isDefaultResolution = !(discard || overwrite);

    if (discard) {
      this.store.dispatch(appActions.showLoading());
      this.store.dispatch(poapActions.discardDraft({ projectId: draft.project, revisionId: draft.id, viewId: draft.view }));
    } if (isDefaultResolution || overwrite) {
      this.store.pipe(take(1)).subscribe((state) => {
        const resolvedValues = isDefaultResolution ? this.getDefaultResolvedValues() : this.resolution.resolvedValues;
        const workspaceId = state?.account?.root?.selected?.id;
        this.store.dispatch(
          poapActions.publishDraft({ revisionId: draft.id, mergeConflicts: true, workspaceId, viewId: draft.view, resolution: { latest, latestViewData, resolvedValues } })
        );
      });
    }
  }

  onClose(): void {
    this.store.dispatch(
      modalActions.closeModal()
    );
  }

  onSelect(resolution): void {
    const { discard, overwrite } = resolution;

    if (this.actions.length > 1) {
      this.actions.pop();
    }

    if (this.showAdvancedOptions) {
      if (discard || overwrite) {
        this.actions.push({
          label: 'Proceed, accept all checked values',
          type: 'positive',
          confirm: true
        });
      }
    } else {
      this.actions.push({
        label: 'Proceed, accept all auto-resolved values',
        type: 'positive',
        confirm: true
      });
    }
  }

  onToggleAdvancedOptions(showAdvancedOptions): void {
    this.showAdvancedOptions = showAdvancedOptions;
    this.onSelect(this.resolution);
  }

  formatFieldValue(field: string, value: any): string {
    switch (field) {
      case 'published':
      case 'start':
      case 'finish':
        return value ? standardFormat(value) : '';
      case 'outlineNumber':
        return value ? value.join('.') : value;
      //case percentComplete: number;
      case 'groupPath':
        return viewGroups.stringifyGroupPath(value);
      case 'milestoneCategory':
        return value?.toString() || '';
      default:
        return value?.toString() || '';
    }
  }

  private getDefaultResolvedValues(): { [key: string]: any } {
    const { task: taskConflict, viewTask: viewTaskConflict } = this.conflict;
    const resolvedValues: Map<string, any> = new Map();

    if (taskConflict && taskConflict.differences) {
      const { differences: taskDifferences }: RevisionConflictResult<RevisionConflictTask> = taskConflict;
      Object.entries(taskDifferences).forEach(([id, taskDiff]) => this.setDefaultResolvedValue(viewTaskConflict, resolvedValues, id, taskDiff));
    }

    return Object.fromEntries(resolvedValues.entries());
  }

  private setDefaultResolvedValue(viewTaskConflict, resolvedValues, id, taskDiff): void {
    const { name, start, finish, percentComplete, outlineNumber, deleted } = taskDiff;

    if ((outlineNumber?.current?.value?.length || 0) > 1) {
      const resolvedValue: any = { id };

      if (name && name.status !== 'unchanged') {
        const { draft, latest, resolved, status } = name;
        resolvedValue.name = { draft, latest, resolved, status };
      }

      if (start && start.status !== 'unchanged') {
        const { draft, latest, resolved, status } = start;
        resolvedValue.start = { draft, latest, resolved, status };
      }

      if (finish && finish.status !== 'unchanged') {
        const { draft, latest, resolved, status } = finish;
        resolvedValue.finish = { draft, latest, resolved, status };
      }

      if (percentComplete && percentComplete.status !== 'unchanged') {
        const { draft, latest, resolved, status } = percentComplete;
        resolvedValue.percentComplete = { draft, latest, resolved, status };
      }

      if (viewTaskConflict && viewTaskConflict.differences) {
        const { differences: viewTaskDifferences }: RevisionConflictResult<RevisionConflictViewTask> = viewTaskConflict;
        const viewTaskDiff = viewTaskDifferences[id];

        if (viewTaskDiff) {
          const { selected, name: rename, groupId, colour, milestoneCategory } = viewTaskDiff;

          if (selected && selected.status !== 'unchanged') {
            const { draft, latest, resolved, status } = selected;
            resolvedValue.selected = { draft, latest, resolved, status };
          }

          if (rename && rename.status !== 'unchanged') {
            const { draft, latest, resolved, status } = rename;
            resolvedValue.rename = { draft, latest, resolved, status };
          }

          if (groupId && groupId.status !== 'unchanged') {
            const { draft, latest, resolved, status } = groupId;
            resolvedValue.groupId = { draft, latest, resolved, status };
          }

          if (colour && colour.status !== 'unchanged') {
            const { draft, latest, resolved, status } = colour;
            resolvedValue.colour = { draft, latest, resolved, status };
          }

          if (milestoneCategory && milestoneCategory.status !== 'unchanged') {
            const { draft, latest, resolved, status } = milestoneCategory;
            resolvedValue.milestoneCategory = { draft, latest, resolved, status };
          }
        }
      }

      if (deleted && deleted.status !== 'unchanged') {
        const { draft, latest, resolved, status } = deleted;
        resolvedValue.deleted = { draft, latest, resolved, status };
      }

      resolvedValues.set(id, resolvedValue);
    }
  }
}
