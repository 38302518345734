import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

interface CheckboxParams extends ICellRendererParams {
  display?: boolean;
  editable?: boolean;
}

@Component({
  selector: 'app-ag-grid-checkbox',
  styleUrls: ['./ag-grid-checkbox.component.scss'],
  template: `
    <input
      class="ag-checkbox"
      *ngIf="display"
      type="checkbox"
      [checked]="checked"
      (change)="onChange($event)"
      (click)="onClick($event)"
    />
  `,

})
export class AgGridCheckboxComponent implements ICellRendererAngularComp {

  private params: CheckboxParams;
  public editable: boolean;
  public display: boolean = false;
  public checked: boolean;

  agInit(params: CheckboxParams): void {
    this.update(params);
  }

  refresh(params: CheckboxParams): boolean {
    this.update(params);
    return true;
  }

  onChange(event): void {
    if (this.editable) {
      this.params.setValue(event.target.checked);
    }
  }

  onClick(e): void {
    if (!this.editable) {
      this.params.setValue(this.checked);
      e.preventDefault();
    }
  }

  private update(params: CheckboxParams): void {
    this.params = params;
    this.display = Object.prototype.hasOwnProperty.call(params, 'display') ? !!params.display : true;
    this.editable = Object.prototype.hasOwnProperty.call(params, 'editable') ? params.editable : true;
    this.checked = params.value;
  }

}
