export const defaultWorkspaceOptions = {
  path: [],
  allowImportedEdits: false
};

export const writableProjectTaskFields = new Set([
  'path',
  'start',
  'finish',
  'percentComplete',
  'outlineNumber',
  'name',
  'deleted'
]);

export const writableViewTaskFields = new Set([
  'groupPath',
  'milestoneCategory',
  'rename',
  'selected',
  'colour'
]);
