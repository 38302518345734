<div class="page">
  <div
    class="ui grid centered"
    *ngIf="this.auth$ | async as auth else loadingView"
  >
    <div class="one row">
      <h2 class="title">Welcome to Traqplan</h2>
    </div>

    <div
      class="six wide column"
      *ngIf="auth.token === null else loggedInView"
    >
      <form
        action=""
        class="ui form"
        (ngSubmit)="signIn()"
        #loginForm="ngForm"
      >
        <div
          class="field"
          [class.error]="emailInput.invalid && loginForm.submitted"
        >
          <label for="email">Email address</label>
          <input
            type="text"
            name="email"
            [(ngModel)]="inputEmail"
            #emailInput="ngModel"
            email
            required
            autofocus
            autocomplete="email"
          />
        </div>
        <div
          class="field"
          [class.error]="passwordInput.invalid && loginForm.submitted"
        >
          <label for="password">Password</label>
          <input
            type="password"
            name="password"
            [(ngModel)]="inputPwd"
            #passwordInput="ngModel"
            autocomplete="current-password"
            required
          />
        </div>

        <div
          class="ui negative message"
          *ngIf="loginForm.submitted && loginForm.invalid"
        >
          <p *ngIf="emailInput.invalid && emailInput.errors?.required">Email address is required</p>
          <p *ngIf="emailInput.invalid && emailInput.errors?.email">This is not a valid email</p>
          <p *ngIf="passwordInput.invalid && passwordInput.errors?.required">Password is required</p>
        </div>
        <div
          class="ui negative message"
          *ngIf="auth.loginError && auth.loginError.message"
        >
          <p>{{ auth.loginError.message }}</p>
        </div>

        <button
          class="fluid ui primary button"
          type="submit"
          [ngClass]="{ 'loading': auth.pending }"
        >
          Log in
        </button>
      </form>

      <div class="one row">
        <div class="forgot-password">
          <a
            class="primary"
            [routerLink]="['/forgot-password']"
          >Forgotten Password</a>
        </div>
      </div>

      <div
        *ngIf="tenancy === 'multi'"
        class="one row"
      >
        <div class="create-account">
          <a
            class="primary"
            [routerLink]="['/registration']"
          >Create Account</a>
        </div>
      </div>

    </div>
  </div>


  <div class="ui tiny modal hidden if-not-chrome">
    <div class="header">
      Browser unsupported
    </div>
    <div class="content">
      Please use either Safari or Chrome to access this site
    </div>
  </div>

  <ng-template #loggedInView>
    <a [routerLink]="['/']">Go to Dashboard</a>
  </ng-template>


  <ng-template #loadingView>
    <app-loader></app-loader>
  </ng-template>
