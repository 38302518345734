import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

interface ColourRendererParams extends ICellRendererParams {
  colour: string;
}

@Component({
  selector: 'app-ag-grid-dropdown-colour-renderer',
  styleUrls: ['./ag-grid-dropdown-colour-renderer.component.scss'],
  template: `
    <span *ngIf="colour" class="cell_colour fas" [style.color]="colour">circle</span>
    <span class="cell_value">{{value}}</span>
    <span class="fas">angle-down</span>
  `
})
export class AgGridDropdownColourRendererComponent implements ICellRendererAngularComp {

  private params: ColourRendererParams;
  public colour: string;
  public value: string;

  agInit(params: ColourRendererParams): void {
    this.params = params;
    this.value = typeof params.valueFormatted === 'string' ? params.valueFormatted : params.value;
    this.colour = params.colour;
  }

  refresh(params: ColourRendererParams): boolean {
    this.value = typeof params.valueFormatted === 'string' ? params.valueFormatted : params.value;
    this.colour = params.colour;
    return true;
  }

}
