const { invert } = require('lodash');
/*
 * actions which are assigned at a point in the organization hierarchy or globally
 * stored as bit flags
 */
const actions = {
  // no access
  noAccess: 0,

  // read a hierarchy node:
  readBranch: 1,

  // edit a hierarchy node
  // if hierarchy node is a project, create revisions and edit private drafts
  editBranch: 1 << 1,

  // create new child hierarchy nodes
  // a project cannot have child hierarchy nodes
  createBranch: 1 << 2,

  // edit the access level of other users to the hierarchy node
  // permissions can only be assigned which the assigning user has themselves
  editBranchUserAccess: 1 << 3,

  // same as editBranch except user can only edit view-task and view-group data
  editBranchView: 1 << 4,

  // archive the given hierarchy node
  archiveBranch: 1 << 5,

  // permanently delete an archived branch
  deleteArchivedBranch: 1 << 6,

  // restore an archived branch
  restoreArchivedBranch: 1 << 7,

  // see all view configurations
  readViews: 1 << 8,

  // edit all view configurations
  editViews: 1 << 9,

  // create new view configurations
  createViews: 1 << 10,

  // delete view configurations
  deleteViews: 1 << 11,

  // assign and unassign licenses
  editLicense: 1 << 12,

  // create (purchase) new licenses
  createLicense: 1 << 13,

  // see the organizations users
  // see the organizations licenses
  readUser: 1 << 14
};

/**
 * license types stored as integer
 */
const licenses = {
  viewer: 1,
  //editor: 2,
  creator: 3,
  admin: 4,
  globalAdmin: 5
};

const context = {
  global: 1,
  root: 2,
  leaf: 3
};

/** @enum {number} */
exports.actionType = Object.freeze({ ...actions, ...invert(actions) });

/** @enum {number} */
exports.licenseType = Object.freeze({ ...licenses, ...invert(licenses) });

exports.context = Object.freeze({ ...context, ...invert(context) });

exports.groups = {
  ['No Access']: actions.noAccess,
  ['Read Only']: actions.readBranch,
  //  | actions.editBranchUserAccess,
  ['Editor']: actions.readBranch
    | actions.editBranch
    | actions.editBranchUserAccess
    | actions.editBranchView
    | actions.archiveBranch
    | actions.restoreArchivedBranch,
  ['Creator']: actions.readBranch
    | actions.editBranch
    | actions.editBranchUserAccess
    | actions.editBranchView
    | actions.createBranch
    | actions.archiveBranch
    | actions.restoreArchivedBranch,
  ['Administrator']: actions.readBranch
    | actions.editBranch
    | actions.createBranch
    | actions.editBranchUserAccess
    | actions.editBranchView
    | actions.archiveBranch
    | actions.deleteArchivedBranch
    | actions.restoreArchivedBranch
};

// some actions 'include' others from a user perspective, so when a permission is assigned those which it 'includes' are also assigned:
exports.actionIncludes = {
  [actions.readBranch]: actions.readBranch,

  [actions.editBranch]: actions.editBranch
    | actions.editBranchView
    | actions.readBranch,

  [actions.createBranch]: actions.createBranch
    | actions.editBranch
    | actions.readBranch,

  [actions.editBranchUserAccess]: actions.editBranchUserAccess
    | actions.readBranch,

  [actions.archiveBranch]: actions.archiveBranch
    | actions.readBranch,

  [actions.deleteArchivedBranch]: actions.deleteArchivedBranch,

  [actions.restoreArchivedBranch]: actions.restoreArchivedBranch,

  [actions.editBranchView]: actions.editBranchView
    | actions.readBranch,

  [actions.readViews]: actions.readViews,

  [actions.editViews]: actions.readViews
    | actions.editViews,

  [actions.createViews]: actions.readViews
    | actions.editViews
    | actions.createViews,

  [actions.deleteViews]: actions.readViews
    | actions.deleteViews,

  [actions.editLicense]: actions.editLicense
    | actions.readUser,

  [actions.createLicense]: actions.createLicense
    | actions.editLicense
    | actions.readUser,

  [actions.readUser]: actions.readUser
};

/**
 * some actions cascade/accumulate down the hierarchy:
 */
exports.cascadeActions = actions.readBranch
  | actions.editBranch
  | actions.createBranch
  | actions.editBranchUserAccess
  | actions.editBranchView
  | actions.archiveBranch
  | actions.deleteArchivedBranch
  | actions.restoreArchivedBranch;

/**
 * some actions may be infered if a descendant has an associated permission
 * for example, given tree path ['a','b','c'], if user has editBranch permission on 'c', then
 * they must by inference have readBranch permission on 'a' & 'b', otherwise they couldn't see 'c' either!
 */
exports.childInferrable = {
  // action readBranch can be inferred if a descendant in the hierarchy has one of the associated permissions:
  [actions.readBranch]: actions.readBranch
    | actions.editBranch
    | actions.createBranch
    | actions.editBranchUserAccess
    | actions.editBranchView
    | actions.archiveBranch
    | actions.deleteArchivedBranch
    | actions.restoreArchivedBranch
};

/**
 * actions are assignable to 1 or more contexts:
 */
exports.contextAssignable = {
  [context.global]: actions.readBranch
    | actions.editBranch
    | actions.createBranch
    | actions.editBranchUserAccess
    | actions.editBranchView
    | actions.archiveBranch
    | actions.deleteArchivedBranch
    | actions.restoreArchivedBranch
    | actions.readViews
    | actions.editViews
    | actions.createViews
    | actions.deleteViews
    | actions.editLicense
    | actions.createLicense
    | actions.readUser,

  [context.root]: actions.readBranch
    | actions.editBranch
    | actions.createBranch
    | actions.editBranchUserAccess
    | actions.editBranchView
    | actions.archiveBranch
    | actions.deleteArchivedBranch
    | actions.restoreArchivedBranch,

  [context.leaf]: actions.readBranch
    | actions.editBranch
    | actions.createBranch
    | actions.editBranchUserAccess
    | actions.editBranchView
    | actions.archiveBranch
    | actions.deleteArchivedBranch
    | actions.restoreArchivedBranch
};
/**
 * maximum assignable permissions based on license type:
 */
exports.licenseAssignable = {
  [licenses.viewer]: actions.readBranch
    | actions.editBranchUserAccess
    | actions.readViews
    | actions.readUser,

  [licenses.editor]: actions.readBranch
    | actions.editBranch
    | actions.editBranchUserAccess
    | actions.editBranchView
    | actions.archiveBranch
    | actions.restoreArchivedBranch
    | actions.readViews
    | actions.editViews
    | actions.createViews
    | actions.readUser,

  [licenses.creator]: actions.readBranch
    | actions.editBranch
    | actions.editBranchUserAccess
    | actions.editBranchView
    | actions.createBranch
    | actions.archiveBranch
    | actions.restoreArchivedBranch
    | actions.readViews
    | actions.editViews
    | actions.createViews
    | actions.readUser,

  [licenses.admin]: actions.readBranch
    | actions.editBranch
    | actions.createBranch
    | actions.editBranchUserAccess
    | actions.editBranchView
    | actions.archiveBranch
    | actions.deleteArchivedBranch
    | actions.restoreArchivedBranch
    | actions.readViews
    | actions.editViews
    | actions.createViews
    | actions.readUser,

  [licenses.globalAdmin]: actions.readBranch
    | actions.editBranch
    | actions.createBranch
    | actions.editBranchUserAccess
    | actions.editBranchView
    | actions.archiveBranch
    | actions.deleteArchivedBranch
    | actions.restoreArchivedBranch
    | actions.readViews
    | actions.editViews
    | actions.createViews
    | actions.deleteViews
    | actions.editLicense
    | actions.createLicense
    | actions.readUser
};

/**
 * the permissions which a license implicitly has at 'base' level (i.e the level at which it is assigned - workspace or global)
 */
exports.licenseDefault = {
  [licenses.viewer]: actions.readViews
    | actions.readUser,

  [licenses.editor]: actions.readViews
    | actions.readUser,

  [licenses.creator]: actions.readViews
    | actions.readUser,

  [licenses.admin]: actions.readViews
    | actions.editViews
    | actions.createViews
    | actions.readUser,

  [licenses.globalAdmin]: actions.readBranch
    | actions.editBranch
    | actions.createBranch
    | actions.editBranchUserAccess
    | actions.editBranchView
    | actions.archiveBranch
    | actions.deleteArchivedBranch
    | actions.restoreArc
    | actions.readViews
    | actions.editViews
    | actions.createViews
    | actions.readUser
    | actions.readViews
    | actions.editViews
    | actions.createViews
    | actions.deleteViews
    | actions.editLicense
    | actions.createLicense
    | actions.readUser
};

/**
 * default permissions to assign to the author of a hierarchy node
 */
exports.leafAuthorDefault = actions.readBranch
  | actions.editBranch
  | actions.createBranch
  | actions.editBranchUserAccess
  | actions.editBranchView
  | actions.archiveBranch
  | actions.deleteArchivedBranch
  | actions.restoreArchivedBranch;

exports.globalAuthorDefault = exports.licenseAssignable[licenses.globalAdmin];
