<app-modal-window
  header="Session Timed Out"
  [(visible)]="visible"
  (closed)="onClose()"
  [actions]="actions"
>
  <div class="message">
    <div class="message__centered tp-header-3">Your session has timed out due to inactivity. Please log in again if you wish to continue</div>
  </div>
</app-modal-window>
