import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs'
import { NotificationsService } from '../../api/notifications.service';
import * as modalActions from '../../state/modal/actions';
import { Store } from '@ngrx/store';
import { State } from '../../state';
import { Notification } from '../../data-structs';
import { StateModal } from '../../state/structs';

@Component({
  selector: 'app-review-access-request',
  templateUrl: './review-access-request.component.html',
  styleUrls: ['./review-access-request.component.scss']
})
export class ReviewAccessRequestComponent {

  @Input() message: Notification;

  actions: ModalWindowAction[] = [
    {
      label: 'Close',
      type: 'neutral',
      cancel: true,
      escKey: true
    },
    {
      label: 'Review user access',
      type: 'positive',
      confirm: true,
      enterKey: true,
    }
  ];

  visible = true;

  nodeLabel: string;

  constructor(
    private store: Store<State>,
    private router: Router,
    private notificationsService: NotificationsService
  ) {}

  complete(action?: ModalWindowAction) {

    this.store.dispatch(
      modalActions.closeModal()
    );

    if (action?.confirm) {
      if (this.message?.meta?.id) {
        this.store.dispatch(modalActions.generateModal({
          modal: StateModal.shareAccess,
          inputs: {
            user: this.message.author,
            nodeId: this.message.meta.id
          }
        }));
      } else {
        this.router.navigate(['/accounts/permissions']);
      }
    }
  }

}
