import { Component, Input, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AllModules, RowGroupingDisplayType } from '@ag-grid-enterprise/all-modules';
import { Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { of, Subject } from 'rxjs';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';
import * as access from 'tp-common/access';
import { License, User, Invitation } from 'tp-traqplan-core/dist/data-structs';
import { ModalService } from '../../modals/modal.service';
import { AccountApiService } from '../../api/account-api.service';
import { UserApiService } from '../../api/user-api.service';
import { RequestError } from '../../api/structs';
import { State } from '../../state';
import * as accountActions from '../../state/account/actions';
import * as modalActions from '../../state/modal/actions';
import { State as AccountState } from '../../state/account/state';
import { ModalWindowAction } from 'tp-traqplan-core/dist/structs';

@Component({
  selector: 'app-edit-license',
  templateUrl: './edit-license.component.html',
  styleUrls: ['./edit-license.component.scss']
})
export class EditLicenseComponent implements OnInit, OnDestroy {

  @Input() existingLicense: License;
  @Input() user: User;
  @Input() completed: () => void;

  actions: ModalWindowAction[] = [
    {
      label: 'Cancel',
      type: 'neutral',
      cancel: true,
      escKey: true
    },
    {
      label: 'Request upgrade',
      type: 'positive',
      confirm: true,
      enterKey: true,
    }
  ];

  visible = true;

  form: FormGroup;
  licenseTypes: SelectItem[];
  hierarchyAccess: any = [];
  invite: Invitation;

  modules = AllModules;
  defaultColDef = {
    autoHeight: true,
    flex: 1,
    resizable: true,
    wrapText: true
  };
  hierarchyAccessColumnDefs = [
    {
      headerName: 'Node',
      showRowGroup: 'name',
      cellRenderer: 'agGroupCellRenderer',
      valueGetter: (params) => {
        const { data, node } = params || {};
        const { name } = data || {};

        if (name) {
          return name;
        } else if (Array.isArray(this.hierarchyPermissions)) {
          const data = this.hierarchyPermissions.find(({ id }) => id === node.key);

          if (data && data.name) {
            return data.name;
          }
        }

        return '';
      }
    },
    {
      headerName: 'Permission',
      field: 'permissionName',
      flex: 1
    }
  ];
  hierarchyAccessGroupDefaultExpanded = -1;
  hierarchyAccessGroupDisplayType: RowGroupingDisplayType = 'custom';

  private destroy$ = new Subject<void>();
  private hierarchyPermissions: any = [];

  get license() { return this.form.get('license') }

  constructor(
    private fb: FormBuilder, private store: Store<State>, private modalService: ModalService,
    private accountApi: AccountApiService, private userApi: UserApiService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.userApi.getLatestInvite(this.user.email).subscribe((latestInvite) => {
      this.invite = latestInvite;
    });
    this.store.dispatch(accountActions.requestHierarchyPermissions());

    this.form = this.fb.group({
      license: this.fb.control('', [
        Validators.required
      ])
    });
    this.licenseTypes = Object.entries(access.licenseDescription)
      .filter(([k]) => !isNaN(Number(k)))
      .map(([value, label]) => <SelectItem>{ label, value });
    this.licenseTypes.unshift(<SelectItem>{ label: 'Unassigned', value: '0' });

    if (this.user && this.existingLicense) {
      this.form.patchValue({ license: `${this.existingLicense.type}` });
    } else {
      this.form.patchValue({ license: '0' });
    }

    this.store.pipe(
      takeUntil(this.destroy$),
      map((state) => state?.account),
      filter((state: AccountState) => !!state.selected)
    ).subscribe((state) => {
      this.hierarchyPermissions = state.hierarchyPermissions;
      this.hierarchyAccess = this.groupPermissions(this.filterPermissions(this.hierarchyPermissions));
    });
  }

  onSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const { id } = this.user;
      const { license } = this.form.value;
      this.accountApi.assignLicense(id, license).pipe(
        catchError((error) => {
          this.displayFailureMessage(error);
          return of(null);
        }),
      ).subscribe(() => {
        this.completed?.();
      });

      this.store.dispatch(modalActions.closeModal());

    }
  }

  displayFailureMessage(error: RequestError) {
    return this.modalService.info({
      header: 'Unable to change license assignment',
      message: error.message,
      actions: [{
        label: 'Okay',
        type: 'neutral',
        confirm: true
      }]
    })
  }

  getRowId(params): string {
    return params.data.id;
  }

  getDataPath(data): string[] {
    return data.path;
  }

  onAccessSelection(params): void {
    const rows = params.api.getSelectedRows();
    const row = rows[0];

    if (row && row.id) {
      // this.store.dispatch(
      //   modalActions.generateModal({
      //     modal: StateModal.editAccess,
      //     inputs: {
      //       node: row,
      //       onSuccess: this.init
      //     }
      //   })
      // );
    }
  }

  onClosed() {
    this.store.dispatch(modalActions.closeModal());
    this.completed?.();
  }

  private filterPermissions(hierarchyPermissions: any, filterFunc?: any): any {
    if (!Array.isArray(hierarchyPermissions)) {
      return [];
    }

    return hierarchyPermissions.filter(typeof filterFunc === 'function' ? filterFunc : ({ id }) => id);
  }

  private groupPermissions(hierarchyPermissions: any): any {
    if (!Array.isArray(hierarchyPermissions)) {
      return [];
    }

    const permissionsMap: any = {};

    for (let i = 0; i < hierarchyPermissions.length; i++) {
      const { id, permission, userId } = hierarchyPermissions[i];
      const hierarchyPermission: any = Object.assign({}, permissionsMap[id] || hierarchyPermissions[i]);
      const userAccess = access.groupOrder.find((go) => permission === access.actionGroups[go]);
      let permissionName;

      if (this.user.id === userId) {
        if (userAccess === access.groupOrder[3]) {
          permissionName = 'Administrators';
        } else if (userAccess === access.groupOrder[2]) {
          permissionName = 'Creators';
        } else if (userAccess === access.groupOrder[1]) {
          permissionName = 'Editors';
        } else if (userAccess === access.groupOrder[0]) {
          permissionName = 'Viewers';
        }

        hierarchyPermission.permissionName = permissionName;
      } else {
        hierarchyPermission.userId = null;
        hierarchyPermission.userEmail = null;
        hierarchyPermission.userName = null;
        hierarchyPermission.userLicense = null;
        hierarchyPermission.permission = null;
        hierarchyPermission.permissionName = null;
      }

      permissionsMap[id] = hierarchyPermission;
    }

    return Object.values(permissionsMap).sort((a: any, b: any) => {
      return `${a.name}|${a.parentName}|${a.grandParentName}`.localeCompare(`${b.name}|${b.parentName}|${b.grandParentName}`);
    });
  }

}
