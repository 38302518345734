import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SubscriptionApiService } from '../../api/subscription-api.service';
import { RequestError } from '../../api/structs';
import * as subscriptionsActions from './actions';

@Injectable()
export class SubscriptionsEffects {

  constructor(
    private actions$: Actions,
    private subscriptionApi: SubscriptionApiService
  ) {}

  staffRequestSubscriptions$ = createEffect(() => this.actions$.pipe(
    ofType(subscriptionsActions.staffRequestSubscriptions),
    switchMap((): Observable<Action> => {
      return this.subscriptionApi.staffRequestSubscriptions().pipe(
        switchMap(({ subscriptions }: any) => [
          subscriptionsActions.staffRequestSubscriptionsSuccess(<any>{ subscriptions })
        ]),
        catchError((error: RequestError) => {
          return of(subscriptionsActions.staffRequestSubscriptionsFailure({ error }))
        })
      );
    })
  ));

  staffRequestSubscriptionById$ = createEffect(() => this.actions$.pipe(
    ofType(subscriptionsActions.staffRequestSubscriptionById),
    switchMap(({ id }): Observable<Action> => {
      return this.subscriptionApi.staffRequestSubscriptionById(id).pipe(
        switchMap((subscription) => [
          subscriptionsActions.staffRequestSubscriptionByIdSuccess(<any>{ subscription })
        ]),
        catchError((error: RequestError) => {
          return of(subscriptionsActions.staffRequestSubscriptionByIdFailure({ error }))
        })
      );
    })
  ));

}
